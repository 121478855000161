import classNames from 'classnames';
import Button from 'components/Button';
import SelectableTags from 'components/Detail/SelectableTags';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { DEFAULT_ACCENT } from 'utils/constants';
import messages from 'utils/messages';
import { LOGIN_FORM } from '../constants';
import validation from './validation';

const description = [
  'Developer, new to AI',
  'AI Developer',
  'Consultant',
  'Researcher',
  'Innovation Manager',
  'Other',
];

const computerVision = [
  'For my company',
  'For other companies',
  'Academia / Research',
  'Researcher',
  'For myself',
  'Other',
];


class WorkspaceForm2 extends Component {
  state={
    userExperiences1: [],
    userExperiences2: [],
  }

  onSubmit = (data) => {
    this.props.onSubmit(data,
      this.props.reset);
  }

  render() {
    const { color } = this.props;

    return (
      <div className="login__form">
        <div className="selectable-tags-wrapper">
          <div className="selectable-tags-title">
            What describes you best?
          </div>
          <SelectableTags
            tags={description}
            color="accent0"
            onSelect={this.props.onSelectTag}
            name="userExperiences1"
            activeSelection={this.props.userExperiences1}
          />
        </div>
        <div className="selectable-tags-wrapper">
          <div className="selectable-tags-title">
            Where do you need Computer Vision?
          </div>
          <SelectableTags
            color="accent0"
            tags={computerVision}
            onSelect={this.props.onSelectTag}
            name="userExperiences2"
            activeSelection={this.props.userExperiences2}
          />
        </div>
        <div className="login__actions">
          <Button
            type="submit"
            onClick={this.props.onSubmit}
            className={classNames(`button button_${color}`, {
            })}
          >
            <FormattedMessage {...messages.continue} />
          </Button>
        </div>
      </div>
    );
  }
}

WorkspaceForm2.propTypes = {
  isLoading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  currentPage: PropTypes.string,
  color: PropTypes.string,
};

WorkspaceForm2.defaultProps = {
  color: DEFAULT_ACCENT,
};

export default reduxForm({
  form: LOGIN_FORM,
  destroyOnUnmount: false,
  // forceUnregisterOnUnmount: true,
  validate: validation,
})(WorkspaceForm2);
