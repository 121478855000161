export const plans = {
  Features: [
    {
      id: 1, name: 'includedDevices', description: '', Basic: 10, 'Early Access Plan': 10, 'Early Access': 10, Professional: 10, Enterprise: 500,
    },
    {
      id: 2, name: 'devicesLimit', description: '', earlyAccess: '10\'000', Enterprise: 'unlimited', Basic: '10\'000', 'Early Access Plan': '10\'000', 'Early Access': '10\'000', Professional: '10\'000',
    },
    {
      id: 3, name: 'applications', description: 'Build AI vision applications using the Visual Editor. Use powerful code with Computer Vision and Deep Learning capabilities offered as drag-and-drop modules to build better applications faster.', earlyAccess: '5', Enterprise: 'unlimited', Basic: 1, 'Early Access Plan': 5, 'Early Access': 5, Professional: 5,
    },
    {
      id: 4, name: 'dashboards', description: 'Create custom dashboards to visualize your application metrics from all enrolled edge devices in the cloud. Visualize relevant business data in real-time.', earlyAccess: '5', Enterprise: 'unlimited', Basic: 1, 'Early Access Plan': 5, 'Early Access': 5, Professional: 5,
    },
    {
      id: 5, name: 'cloudStorage', description: '', earlyAccess: '10 GB', Enterprise: 'unlimited', Basic: '2 GB', 'Early Access Plan': '10 GB', 'Early Access': '10 GB', Professional: '10 GB',
    },
    {
      id: 6, name: 'users', description: '', earlyAccess: 'unlimited', Enterprise: 'unlimited',
    },
  ],
  'Computer Vision': [
    {
      id: 7, name: 'videoInputs', description: 'Modules to fetch the real-time video feed from any IP camera or USB camera connected to enrolled devices, or a video file (MP4).', earlyAccess: 'full', Enterprise: 'full',
    },
    {
      id: 8, name: 'objectDetection', description: 'Modules to detect different objects with pre-trained or custom deep learning models on GPU, CPU, VPU or TPU.', earlyAccess: 'full', Enterprise: 'full',
    },
    {
      id: 9, name: 'objectCounting', description: 'Modules to count objects with a wide range of different modes or cloud sync intervals.', earlyAccess: 'full', Enterprise: 'full',
    },
    {
      id: 10, name: 'poseEstimation', description: 'Modules to detect pose key points with pre-trained or custom deep learning models on GPU, CPU, TPU or VPU.', earlyAccess: 'full', Enterprise: 'full',
    },
    {
      id: 11, name: 'objectSegmentation', description: 'Modules to segment objects from images with pre-trained or custom deep learning models on GPU and CPU.', earlyAccess: 'full', Enterprise: 'full',
    },
    {
      id: 12, name: 'imageClassification', description: 'Modules to classify the image into categories with pre-trained or custom deep learning models on GPU, CPU, TPU or VPU.', earlyAccess: 'full', Enterprise: 'full',
    },
    {
      id: 13, name: 'regionOfInterest', description: 'Modules to configure one or multiple Regions of Interest and select between rectangle, polygon or sections.', earlyAccess: 'full', Enterprise: 'full',
    },
    {
      id: 14, name: 'customModels', description: 'Use custom trained computer vision and deep learning algorithms in combination with various function modules in the Visual Editor.', earlyAccess: 'full', Enterprise: 'full',
    },
  ],
  Build: [
    {
      id: 15, name: 'visualEditor', description: 'Low-code development editor to build computer vision applications. The editor allows intuitive and fast updating of complex AI vision and Deep Learning solutions. Wire together flows using modules and if-this-then-rules to process real-time video inputs.', earlyAccess: 'full', Enterprise: 'full',
    },
    {
      id: 16, name: 'versionControl', description: 'Track application changes seamlessly with an integrated version management. Update modules in your Module Library.', earlyAccess: 'full', Enterprise: 'full',
    },
    {
      id: 17, name: 'versionHistory', description: 'Commit changes made in the Visual Editor and leave a commit message to keep track of versions. Restore older application versions.', earlyAccess: '50', Enterprise: 'unlimited', Basic: '5', 'Early Access Plan': '50', 'Early Access': '50', Professional: '50',
    },
    {
      id: 18, name: 'moduleLibrary', description: 'Manage, sort and filter thousands of Modules in the Module Library. Use tags, version management and descriptions to keep all Modules up to date.', earlyAccess: 'full', Enterprise: 'full',
    },
    {
      id: 19, name: 'publicModules2500', description: 'Integrate to other systems like Slack, or build IFTTT logics with publicly available modules which are compatible with Viso Suite.', earlyAccess: 'full', Enterprise: 'full',
    },
    {
      id: 20, name: 'customModules', description: 'Create, add and use your own custom modules together with powerful Viso features. Link private or public docker images to your modules and use them to build your apps.', earlyAccess: 'full', Enterprise: 'full', Basic: 'none',
    },
  ],
  Deploy: [
    {
      id: 43, name: 'deviceEnrollment', description: 'Easy device enrollment to add multiple devices that are connected to cameras to your workspace. Enroll any computing devices based on the x86/x64 architectures, such as Intel NUC, PCs or servers. Flash the image to the device, power up, and your configured devices automatically appear in the workspace. Fast and simple.', earlyAccess: 'full', Enterprise: 'full',
    },
    {
      id: 21, name: 'deviceManagement', description: 'Manage all enrolled devices from one place. List, sort and filter thousands of devices. Use tags, custom names and descriptions to keep track of your devices.', earlyAccess: 'full', Enterprise: 'full',
    },
    {
      id: 22, name: 'deviceMonitoring', description: 'Remotely monitor the status of every enrolled device. <br/><br/>(1) <strong>Connectivity Status</strong>: Check the network status, the device availability as well as hardware performance metrics. <br/><br/>(2) <strong>Deployment Status</strong>: Monitor the deployment status of applications, deployed containers and detect errors quickly.<br/><br/> (3) <strong>Device Diagnostics</strong>: Monitor the hardware metrics of every device in a dashboard, including disk space, computing load, network traffic, and more.', earlyAccess: 'full', Enterprise: 'full',
    },
    {
      id: 45, name: 'deviceDataRetention', description: 'The device data retention time shows how long device monitoring data is stored.', earlyAccess: 'full', Enterprise: 'full',
    },
    {
      id: 23, name: 'debuggingTools', description: 'Enable shell access to specific devices to debug containers effectively.', earlyAccess: 'full', Enterprise: 'full',
    },
    {
      id: 24, name: 'automatedDeployment', description: 'Fast deployment of applications and versions with a single click to one or multiple devices.', earlyAccess: 'full', Enterprise: 'full',
    },
    {
      id: 25, name: 'monthlyDeployments', description: 'Number of application deployments to enrolled devices per month.', earlyAccess: '5,000', Enterprise: 'unlimited',
    },
  ],
  Monitor: [
    {
      id: 26, name: 'dataCollectors', description: 'Automated and fully integrated Edge-to-Cloud data collection. Gather application metrics in the cloud to visualize them in dashboards.', earlyAccess: 'full', Enterprise: 'full',
    },
    {
      id: 27, name: 'dashboardEditor', description: 'Create custom dashboards using pre-built charts to visualize data of your deployed computer vision applications. Use a flexible drag-and-drop dashboard editor.', earlyAccess: 'full', Enterprise: 'full',
    },
    {
      id: 28, name: 'visualDataExplorer', description: 'Monitor and explore your data in real-time dashboards using ad hoc queries, dynamic drill-down and multiple filters.', earlyAccess: 'full', Enterprise: 'full',
    },
    {
      id: 46, name: 'dataRetention', description: 'The data retention shows how long data from deployed applications is stored.', earlyAccess: 'full', Enterprise: 'full',
    },
  ],
  Manage: [
    {
      id: 29, name: 'userManagement', description: 'Invite new team members to join your workspace. Keep track of invited users and if they have already joined. Manage all users from one place. List, sort and filter all users of the workspace.', earlyAccess: 'full', Enterprise: 'full',
    },
    {
      id: 30, name: 'workspaceSettings', description: 'Update your workspace information such as workspace name or company information. Keep your invoice address and billing contact up to date. Manage your active subscriptions from one place.', earlyAccess: 'full', Enterprise: 'full',
    },
  ],
  Security: [
    {
      id: 31, name: 'encryptionTransit', description: 'Project roles allow you to assign roles to different users, for example administrator or end-user. You can assign different permissions to users based on their role. In the Free plan, every user is considered a project admin.', earlyAccess: 'full', Enterprise: 'full',
    },
    {
      id: 32, name: 'roleBasedAccess', description: 'Assign roles to different users, for example administrator or end-user, to manage access to data and services within the workspace.', earlyAccess: 'full', Enterprise: 'full',
    },
    {
      id: 33, name: 'advancedPermissions', description: 'Define user permissions with custom user roles to ensure privacy and security.', earlyAccess: 'full', Enterprise: 'full', Basic: 'none',
    },
    {
      id: 34, name: 'enterpriseLevelSecurity', description: 'Advanced, enterprise-grade security controls, including SAML single sign-on (SSO), Workspace Audit Log, Enforced 2FA, Session Management, and IP login restrictions.', earlyAccess: 'none', Enterprise: 'full', Basic: 'none', 'Early Access Plan': 'none', 'Early Access': 'none', Professional: 'none',
    },
  ],
  Support: [
    {
      id: 35, name: 'portalDocumentation', description: 'Find product documentation and tutorials to learn everything about how to use Viso Suite effectively.', earlyAccess: 'full', Enterprise: 'full',
    },
    {
      id: 36, name: 'communitySupport', description: 'Join our community, ask your questions, give feedback and get help from experienced Viso users.', earlyAccess: 'full', Enterprise: 'full',
    },
    {
      id: 37, name: 'ticketing', description: 'Submit support tickets and get help from the one\'s who built Viso Suite.', earlyAccess: 'full', Enterprise: 'full',
    },
    {
      id: 38, name: 'dedicatedAccountManager', description: 'Discuss your questions, priorities and updates with your dedicated Viso account manager.', earlyAccess: 'none', Enterprise: 'full',
    },
  ],
  Marketplace: [
    {
      id: 39, name: 'preBuiltApplications', description: 'Import ready-to-use Computer Vision and Deep Learning applications directly from the Viso Marketplace.', earlyAccess: 'full', Enterprise: 'full',
    },
    {
      id: 40, name: 'extensionsIntegrations', description: 'Import complete Modules with new extensions to the Workspace, adding new functionality to the visual editor to build powerful applications.', earlyAccess: 'full', Enterprise: 'full',
    },
    {
      id: 41, name: 'partnerPortal', description: 'Become a Viso Partner and share custom modules and applications you created with Viso Suite.', earlyAccess: 'full', Enterprise: 'full',
    },
  ],
  'Additional Services': [
    {
      id: 42, name: 'expertOnboarding', description: 'The Viso Team helps you to get started faster and introduces you to the possibilities of Viso Suite.', earlyAccess: 'Custom Pricing', Enterprise: 'Custom Pricing',
    },
    {
      id: 43, name: 'implementationServices', description: 'Work with AI specialists to review your container structure and make it Viso-ready and help you to build and deploy powerful AI vision applications.', earlyAccess: 'Custom Pricing', Enterprise: 'Custom Pricing',
    },
  ],
};
