import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import {
  getNotifications,
  updateNotificationSettings,
  getNotificationSettings,
  readNotification,
  readAllNotifications,
  unreadAllNotifications,
  unreadNotification,
} from 'api/notifications';
import {
  defaultNotifier,
} from 'functions/notificationHandler';
import {
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATION_SETTINGS_REQUEST,
  UPDATE_NOTIFICATION_SETTINGS_REQUEST,
  MARK_AS_READ_REQUEST,
  MARK_AS_UNREAD_REQUEST,
  MARK_AS_ALL_UNREAD_REQUEST,
  MARK_AS_ALL_READ_REQUEST,
} from './constants';
import {
  getNotificationListSuccess,
  markAsUneadSuccessNotification,
  markAsReadSuccessNotification,
  getNotificationSettingListSuccess,
  markAsAllReadSuccessNotification,
  markAsAllUnreadSuccessNotification,
} from './actions';

function* notificationListSaga(action) {
  try {
    if (localStorage.userData && JSON.parse(localStorage.userData).activeWorkspace) {
      const response = yield call(getNotifications, action.payload);

      if (response.errors) {
        defaultNotifier(response.errors);
      } else {
        yield put(getNotificationListSuccess({
          ...response.data.data.notifications,
          params: action.payload && action.payload.after,
          type: action.meta,
        }, action));
      }
    }
  } catch (err) {
    defaultNotifier(err);
  }
}

function* notificationSettingListSaga(action) {
  try {
    if (localStorage.userData && JSON.parse(localStorage.userData).activeWorkspace) {
      const response = yield call(getNotificationSettings, action.payload);

      if (response.errors) {
        defaultNotifier(response.errors);
      } else {
        yield put(getNotificationSettingListSuccess({
          ...response.data.data.notificationSettings,
        }));
      }
    }
  } catch (err) {
    defaultNotifier(err);
  }
}

function* markAsReadSaga(action) {
  try {
    const response = yield call(readNotification, action.payload);

    if (response.errors) {
      defaultNotifier(response.errors);
    } else {
      yield put(markAsReadSuccessNotification(action.payload.id));
    }
  } catch (err) {
    defaultNotifier(err);
  }
}

function* markAsUnreadSaga(action) {
  try {
    const response = yield call(unreadNotification, action.payload);

    if (response.errors) {
      defaultNotifier(response.errors);
    } else {
      yield put(markAsUneadSuccessNotification(action.payload.id));
    }
  } catch (err) {
    defaultNotifier(err);
  }
}

function* markAsAllReadSaga(action) {
  try {
    const response = yield call(readAllNotifications, action.payload);

    if (response.errors) {
      defaultNotifier(response.errors);
    } else {
      yield put(markAsAllReadSuccessNotification());
    }
  } catch (err) {
    defaultNotifier(err);
  }
}

function* markAsAllUnreadSaga(action) {
  try {
    const response = yield call(unreadAllNotifications, action.payload);

    if (response.errors) {
      defaultNotifier(response.errors);
    } else {
      yield put(markAsAllUnreadSuccessNotification());
    }
  } catch (err) {
    defaultNotifier(err);
  }
}

function* updateNotificationSettingListSaga(action) {
  try {
    const response = yield call(updateNotificationSettings, action.payload);

    if (response.errors) {
      defaultNotifier(response.errors);
    } else {
      yield put(getNotificationSettingListSuccess({
        ...response.data.data.updateNotificationSettings,
      }));
    }
  } catch (err) {
    defaultNotifier(err);
  }
}

export default function* notificationRootSaga() {
  yield takeLatest(FETCH_NOTIFICATIONS_REQUEST, notificationListSaga);
  yield takeLatest(MARK_AS_UNREAD_REQUEST, markAsUnreadSaga);
  yield takeLatest(MARK_AS_READ_REQUEST, markAsReadSaga);
  yield takeLatest(MARK_AS_ALL_UNREAD_REQUEST, markAsAllUnreadSaga);
  yield takeLatest(MARK_AS_ALL_READ_REQUEST, markAsAllReadSaga);
  yield takeLatest(FETCH_NOTIFICATION_SETTINGS_REQUEST, notificationSettingListSaga);
  yield takeLatest(UPDATE_NOTIFICATION_SETTINGS_REQUEST, updateNotificationSettingListSaga);
}
