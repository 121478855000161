import React from 'react';
import classNames from 'classnames';
import { RedEye } from 'images/common';

export default class CustomPassword extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  togglePassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  render() {
    const { postIcon } = this.props;

    const inputClass = classNames({
      'text-field__input': true,
      'text-field__input_filled': !!this.props.value,
    });

    const parentClass = classNames(this.props.parentClass || 'text-field text-field_hinted', {
      'text-field_invalid': this.props.isInvalid,
    });

    const eyeClass = classNames({
      'text-field__icon_crossed': this.state.showPassword,
      'text-field__icon': true,
      'text-field__password': true,
      'text-field__icon_clickable': true,
    });

    return (
      <div className={parentClass}>
        <input
          {...this.props.input}
          id={this.props.id}
          data-cy={this.props.dataCy}
          type={this.state.showPassword ? 'text' : 'password'}
          className={inputClass}
          autoComplete={this.props.autocomplete}
          readOnly={this.props.readOnly}
          onChange={this.props.onChange}
          name={this.props.name}
          value={this.props.value}
        />
        <label className="text-field__label">{this.props.label}</label>
        <div className="text-field__underscore" />
        {/* {this.props.value && */}
        <button
          type="button"
          onClick={this.togglePassword}
          className={eyeClass}
        >
          <RedEye />
        </button>
        {/* } */}
        { !this.props.hintText && this.props.customText }
        <div className={
          classNames(
            'text-field__hint text-field__hint_r',
            {
              'show-password': this.props.hintText,
            },
          )}
        >
          { this.props.hintText }
        </div>
        {postIcon && <div className="text-field__postIcon text-field__passwordIcon">{postIcon}</div>}

      </div>
    );
  }
}
