import { fromJS } from 'immutable';
import {
  LIST_SOLUTION_ERROR,
  LIST_SOLUTION_SUCCESS,
  LIST_SOLUTION_REQUEST,
  GET_SOLUTION_DETAILS_REQUEST,
  GET_SOLUTION_DETAILS_ERROR,
  GET_SOLUTION_DETAILS_SUCCESS,
  ADD_SOLUTION_FILE_ERROR,
  ADD_SOLUTION_FILE_REQUEST,
  ADD_SOLUTION_FILE_SUCCESS,
  ADD_SOLUTION_FILES_ERROR,
  ADD_SOLUTION_FILES_SUCCESS,
  ADD_SOLUTION_FILES_REQUEST,
  REMOVE_SOLUTION_FILE,
  REMOVE_SOLUTION_IMAGES,
  GET_VENDOR_SUCCESS,
  GET_VENDOR_REQUEST,
  GET_VENDOR_ERROR,
  CLEAR_VENDOR_REQUEST,
  LIST_DASHBOARD_REQUEST,
  LIST_DASHBOARD_SUCCESS,
  UPDATE_SOLUTION_REQUEST,
  UPDATE_SOLUTION_ERROR,
  UPDATE_SOLUTION_SUCCESS,
} from './constants';

export const initialState = fromJS({
  marketCategory: [],
  vendor: {
    isLoading: false,
    details: null,
  },
  file: {
    uploading: false,
    url: '',
  },
  dashboards: [],
  files: {
    uploading: false,
    url: [],
    logo: [],
  },
  list: {
    count: 0,
    data: [],
  },
  errors: null,
  details: null,
  isLoading: false,
});

function partnerReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SOLUTION_DETAILS_REQUEST:
      return state
      // .set('details', null)
        .set('isLoading', true)
        .set('files', {
          url: [],
        });
    case UPDATE_SOLUTION_REQUEST:
      return state
        .set('isLoading', true);
    case UPDATE_SOLUTION_ERROR:
    case UPDATE_SOLUTION_SUCCESS:
      return state
        .set('isLoading', false);
    case GET_SOLUTION_DETAILS_SUCCESS:
      return state
        .set('details', action.payload)
        .set('isLoading', false)
        .set('file', {
          url: action.payload.logo,
        });
    case CLEAR_VENDOR_REQUEST:
      return state
        .set('vendor', {
          details: {},
        });
    case GET_SOLUTION_DETAILS_ERROR:
      return state.set('isLoading', true);
    case LIST_SOLUTION_SUCCESS:
      return state.set('isLoading', false).set('list', {
        count: action.payload.length,
        data: action.payload,
      });
    case LIST_DASHBOARD_REQUEST:
      return state.merge({
        dashboards: [],
        loading: true,
      });
    case LIST_DASHBOARD_SUCCESS:
      return state.merge({
        dashboards: action.payload,
        loading: false,
      });
    case LIST_SOLUTION_REQUEST:
      return state.set('isLoading', true).set('list', {
        count: 0,
        data: [],
      });
    case LIST_SOLUTION_ERROR:
      return state.set('errors', action.errors).set('isLoading', false);
    case ADD_SOLUTION_FILE_ERROR:
      return state.set('file', {
        uploading: true,
        url: '',
      });
    case ADD_SOLUTION_FILE_REQUEST:
      return state.set('file', {
        uploading: true,
        url: '',
      });
    case ADD_SOLUTION_FILE_SUCCESS:
      return state.set('file', {
        uploading: false,
        url: action.payload.url,
        logo: action.payload.logo,
      });

    case ADD_SOLUTION_FILES_ERROR:
      return state.set('files', {
        uploading: false,
      });
    case ADD_SOLUTION_FILES_REQUEST:
      return state.set('files', {
        uploading: true,
        url: state.toJS().files.url,
        logo: state.toJS().files.logo || [],
      });
    case ADD_SOLUTION_FILES_SUCCESS:
      return state.set('files', {
        uploading: false,
        url: state.toJS().files.url.concat(action.payload.url),
        logo: state.toJS().files.logo.concat(action.payload.logo),
      });
    case REMOVE_SOLUTION_FILE:
      return state.set('file', {
        uploading: false,
        url: '',
      });
    case REMOVE_SOLUTION_IMAGES:
      return state.set('files', {
        uploading: false,
        url: state.toJS().files.url.filter(item => item !== action.payload),
        logo: state.toJS().files.logo.filter(item => item !== action.payload),
      });

    case GET_VENDOR_REQUEST:
      return state
        .set('vendor', {
          isLoading: true,
          details: {},
        });
    case GET_VENDOR_SUCCESS:
      return state
        .set('vendor', {
          isLoading: false,
          details: action.payload,
        });
    case GET_VENDOR_ERROR:
      return state
        .set('vendor', {
          isLoading: false,
          details: {},
        });
    default:
      return state;
  }
}

export default partnerReducer;
