import React from 'react';

export default () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.2613 11.3711C11.3478 12.496 9.01988 13.2692 6.86653 13.5691C6.74411 14.001 6.47074 14.3746 6.09619 14.6218C6.67282 15.8538 7.50285 16.9742 8.50519 17.9887C9.94897 18.061 11.3889 17.7838 12.7028 17.1806C14.0168 16.5775 15.1661 15.6661 16.0532 14.5238C16.0858 14.4809 16.1151 14.4336 16.1467 14.3896C15.8719 13.3594 15.4169 12.3788 14.8571 11.4094C14.7558 11.4387 14.6589 11.4613 14.5632 11.4793C14.4021 11.5244 14.2354 11.5559 14.0609 11.5559C13.7839 11.5544 13.5108 11.4912 13.2613 11.3711V11.3711Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.1856 6.18427C9.46574 5.66239 8.71172 5.18942 7.92862 4.76855C7.75788 5.17657 7.44911 5.51142 7.05642 5.71442C6.66372 5.91743 6.21217 5.97562 5.7809 5.87881C5.32252 7.60901 5.07813 9.4429 5.2054 11.1697C5.61897 11.1995 6.0111 11.3653 6.32072 11.6414C6.63034 11.9174 6.84007 12.2882 6.91726 12.6959C8.82622 12.3814 10.8253 11.7051 12.5112 10.7369C12.2939 10.4225 12.1773 10.0495 12.1768 9.66721C12.1768 9.20169 12.3524 8.78126 12.6306 8.45213C11.8941 7.62253 11.0663 6.82563 10.1856 6.18427V6.18427Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.4963 11.2528C4.34201 9.41104 4.61343 7.42949 5.10559 5.58094C4.85778 5.40716 4.65543 5.17621 4.51565 4.90762C4.37588 4.63902 4.3028 4.34069 4.30259 4.03785C4.30259 3.8158 4.34651 3.60277 4.41972 3.40439C3.68432 3.27087 2.94214 3.17792 2.19654 3.12598C2.09631 3.2432 1.98819 3.35592 1.89359 3.47991C0.969927 4.66858 0.359101 6.07024 0.11708 7.55646C-0.124941 9.04268 0.00958336 10.5659 0.508326 11.9866C1.37136 12.3544 2.27902 12.6067 3.2079 12.7373C3.26519 12.3951 3.41583 12.0754 3.64318 11.8134C3.87053 11.5515 4.16576 11.3575 4.4963 11.2528V11.2528Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M15.6775 10.915C15.6448 10.9567 15.6054 10.9905 15.5693 11.0277C16.057 11.8573 16.4692 12.6981 16.7553 13.5841C17.3619 12.554 17.7572 11.4131 17.9181 10.2283C18.079 9.04344 18.0022 7.83839 17.6923 6.68359C17.5909 6.83351 17.4941 6.98455 17.3871 7.12995C16.896 7.80738 16.4016 8.50171 15.8622 9.12728C15.9128 9.29861 15.9477 9.47783 15.9477 9.6672V9.67171C16.0424 10.0933 15.9624 10.5498 15.6775 10.915V10.915Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.05961 3.87892C8.95947 4.34556 9.84356 4.89111 10.6837 5.50204C11.6298 6.19073 12.5161 7.04963 13.3011 7.94122C13.5342 7.83752 13.7899 7.77778 14.0624 7.77778C14.6255 7.77778 15.1267 8.03139 15.4724 8.42252C15.9274 7.86795 16.3543 7.27056 16.78 6.68668C16.9985 6.3846 17.199 6.07463 17.3927 5.76241C16.7219 4.01007 15.519 2.51233 13.9533 1.47993C12.3876 0.447522 10.5379 -0.0675754 8.6644 0.00711204C8.53488 0.171678 8.40649 0.333989 8.28261 0.501937C7.83099 1.12413 7.45032 1.71927 7.11133 2.3471C7.15525 2.37979 7.18341 2.4215 7.22395 2.45982C7.69359 2.77092 8.00893 3.28716 8.05961 3.87892V3.87892Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M3.23364 13.4806C2.43946 13.3799 1.65807 13.1959 0.902344 12.9316C1.51916 14.2192 2.43434 15.3407 3.5717 16.2027C4.70907 17.0648 6.03571 17.6425 7.44123 17.8878C6.64086 16.9898 5.97575 15.9798 5.46695 14.8895C4.98452 14.9937 4.48051 14.9046 4.063 14.6412C3.64549 14.3778 3.34775 13.9611 3.23364 13.4806V13.4806Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.07467 2.52023C5.24225 2.33481 5.45383 2.19465 5.68984 2.1127C5.92585 2.03075 6.17869 2.00964 6.42501 2.05133C6.7764 1.39081 7.17283 0.762979 7.64247 0.110352C5.85024 0.384126 4.18278 1.19438 2.85938 2.43456C3.52385 2.50896 4.18382 2.59237 4.84266 2.71861C4.91362 2.64647 4.99245 2.58222 5.07467 2.52023Z" fill="currentColor" />
  </svg>
);
