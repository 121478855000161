import React from 'react'
import classNames from 'classnames'
import {withRouter} from 'withRouter';

const HeaderCard = ({customClass, img, name}) => {
  const headerCardClass = classNames('market-recomendations__item cursor', {
    [customClass]: customClass
  })

  return (
    <div className="grid-col">
      <div className={headerCardClass} style={{backgroundImage: `url(${img})`}}>
        <div className="market-recomendations__heading">{name}</div>
        <div className="market-recomendations__subHeading">Object detection to recognize people in a region.</div>
      </div>
      <div className='market-recomendations__item_footer'>
        <p className='market-recomendations__header'>The following items are added to your workspace</p>
        <p className='market-recomendations__desc'>
          <pre> •  Required modules to build the flow</pre>
          <pre> •  Application flow to count people in an area</pre>
          <pre> •  Sample video</pre>
          <pre> •  Dashboard</pre>
        </p>
      </div>
    </div>
  )
}

export default withRouter(HeaderCard)
