import React from 'react';

export default () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.0012 20H14.144V15.1429H19.0012V20ZM15.3583 18.7857H17.7869V16.3571H15.3583V18.7857Z" fill="currentColor" />
    <path d="M6.85715 7.85715H2V3H6.85715V7.85715ZM3.21429 6.64286H5.64286V4.21429H3.21429V6.64286Z" fill="currentColor" />
    <path d="M12.9286 18.1787H5.64286C4.67671 18.1787 3.75014 17.7949 3.06697 17.1117C2.3838 16.4285 2 15.502 2 14.5358C2 13.5697 2.3838 12.6431 3.06697 11.9599C3.75014 11.2767 4.67671 10.8929 5.64286 10.8929V12.1072C4.99876 12.1072 4.38105 12.3631 3.9256 12.8185C3.47015 13.274 3.21429 13.8917 3.21429 14.5358C3.21429 15.1799 3.47015 15.7976 3.9256 16.2531C4.38105 16.7085 4.99876 16.9644 5.64286 16.9644H12.9286V18.1787Z" fill="currentColor" />
    <path d="M15.3568 12.1072V10.8929C16.0009 10.8929 16.6186 10.637 17.074 10.1816C17.5295 9.72615 17.7853 9.10843 17.7853 8.46433C17.7853 7.82024 17.5295 7.20252 17.074 6.74707C16.6186 6.29163 16.0009 6.03576 15.3568 6.03576H8.07104V4.82147H15.3568C16.3229 4.82147 17.2495 5.20527 17.9327 5.88844C18.6158 6.57161 18.9996 7.49819 18.9996 8.46433C18.9996 9.43048 18.6158 10.3571 17.9327 11.0402C17.2495 11.7234 16.3229 12.1072 15.3568 12.1072V12.1072Z" fill="currentColor" />
    <path d="M12.3202 8.46442H8.67739L6.85596 10.893L10.4988 14.5359L14.1417 10.893L12.3202 8.46442Z" fill="currentColor" />
  </svg>
);
