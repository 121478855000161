import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { socket } from "socket";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { FormattedMessage } from "react-intl";
import localForage from "localforage";
import {withRouter } from 'withRouter'
import { ChevronRight, PlayCircle } from "react-feather";
import CardTable from "components/Detail/SingleCard";
import { ModuleIcon, DashboardIcon } from "images/common";
import ProcessPage from "components/ProcessPage";
import injectSaga from "utils/injectSaga";
import injectReducer from "utils/injectReducer";
import Button from "components/Button";
import classNames from "classnames";
import { getLibraryModulesList } from "containers/Library/selectors";
import { libraryModulesListRequest } from "containers/Library/actions";
import LoadingIndicator from "components/LoadingIndicator";
import slugify from "react-slugify";
import TermsLink from "components/TermsLink";
import { intl } from "containers/LanguageProvider/intlProvider";

import reducer from "containers/Library/reducer";
import saga from "containers/Library/saga";
import { makeSelectUser } from "containers/App/selectors";
import { routeMapping } from "routes";
import appMessages from "utils/messages";
import {
  makeSelectUserWorkspace,
  makeSelectLoading,
  makeSelectFetchWorkspace,
} from "containers/Settings/Workspace/selectors";
import { getStatus } from "utils/common";
import { purchaseSolution, getMarketplaceSolution } from "api/marketplace";
import {
  defaultNotifier,
  marketplaceNotifier,
  successNotifier,
} from "functions/notificationHandler";

const currentStatus = {
  installed: "active",
  "not-installed": "inactive",
};

const marketplaceMessages = {
  module_install_success: "isNowInstalled",
  module_update_success: "moduleUpdated",
  module_install_fail: "marketplaceInstallFailed",
  module_update_fail: "moduleUpdateFailed",
  module_remove_success: "marketplaceRemoved",
  module_remove_fail: "failedToRemoveModule",
};

class RequiredModules extends Component {
  state = {
    showWarning: false,
    showOnlyOwner: false,
  };

  async componentDidMount() {
    const { workspace } = this.props;
    this.props.libraryModulesListRequest({
      includeDemoData: this.props.workspace.demoModeEnabled
    });

    const result = await localForage.getItem("activePartnerId");

    this.setState({ result });

    if(workspace) {
      socket
      .off(`notification-${workspace.id}`)
      .on(`notification-${workspace.id}`, (data) => {
        if (data.isMarketplace) {
          if (data.isMarketplace) {
            this.props.updateInfraBanner(false);
            return successNotifier(
              `${intl.formatMessage({ ...messages.module })} 
            ${data.moduleName} ${intl.formatMessage({
                ...messages[marketplaceMessages[data.message]],
              })}`,
              `/${workspace && slugify(workspace.name)}/modules/${
                data.moduleId
              }`,
              true
            );
          }
        }
      });
    }
  }

  onClose = async () => {
    const { workspace } = this.props;

    const wsPath = workspace && slugify(workspace.name);

    this.props.onClose();
    await localForage.setItem("activePartnerId", null);
    this.props.router.navigate(`${wsPath}${routeMapping.SETTINGS.path}`);
  };

  onNavigateToMP = async (status, id) => {
    if (status !== "installed") {
      this.props.onClose();
      this.props.router.navigate(`/marketplace/${id}`);
      await localForage.setItem("activePartnerId", null);
    }
  };

  renderContent = () => {
    const { requiredModules = [], modules } = this.props;

    return (
      <>
        {requiredModules &&
          requiredModules.map((module) => {
            const activeModule =
              (modules.data &&
                modules.data.find(
                  (item) => item.moduleID === module.moduleID
                )) ||
              {};

            const status =
              (activeModule && currentStatus[activeModule.installStatus]) ||
              "inactive";

            if (module.type === "public") {
              return (
                <a
                  href={module.link}
                  disabled={activeModule.installStatus === "installed"}
                  // onClick={() =>
                  //   this.onNavigateToMP(activeModule.installStatus, module.link)
                  // }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-link"
                >
                  <div className="container-item">
                    <div className="container-item_col">
                      <div className="container-item_name">
                        <div className="container-item_icon">
                          <div
                            className={`${module && module.logo && "no-border"
                              }`}
                          >
                            {module && module.logo ? (
                              <img
                                src={module.logo}
                                alt="Module logo"
                                height="45"
                                width="45"
                              />
                            ) : (
                              <ModuleIcon />
                            )}
                          </div>
                        </div>
                        <h4>{module.name}</h4>
                      </div>
                    </div>
                    <h4 className="container-item_col">
                      {getStatus(
                        status,
                        (activeModule && activeModule.installStatus) ||
                        "Not installed"
                      )}
                    </h4>
                  </div>
                </a>
              );
            }
            return (
              <div
                className={classNames("", {
                  "disabled-link": activeModule.installStatus === "installed",
                })}
              // onClick={() =>
              //   this.onNavigateToMP(activeModule.installStatus, module.link)
              // }
              >
                <div className="container-item">
                  <div className="container-item_col">
                    <div className="container-item_name">
                      <div className="container-item_icon">
                        <div
                          className={`${module && module.logo && "no-border"}`}
                        >
                          {module && module.logo ? (
                            <img
                              src={module.logo}
                              alt="Module logo"
                              height="42"
                              width="42"
                            />
                          ) : (
                            <ModuleIcon />
                          )}
                        </div>
                      </div>
                      <h4 className="text-color">{module.name}</h4>
                    </div>
                  </div>
                  <h4 className="container-item_col">
                    {getStatus(
                      status,
                      (activeModule && activeModule.installStatus) ||
                      "Not installed"
                    )}
                  </h4>
                  <div className="container-item_chevron">
                    {activeModule &&
                      activeModule.installStatus === "installed" ? (
                      ""
                    ) : <div />}
                  </div>
                </div>
              </div>
            );
          })}
      </>
    );
  };

  renderContentModule = () => {
    const { solution } = this.props;

    return (
      <>
        <div
          className={classNames("", {
          })}
        >
          <div className="container-item">
            <div className="container-item_col">
              <div className="container-item_name">
                <div className="container-item_icon">
                  <div
                    className={`${solution && solution.logo && "no-border"}`}
                  >
                    {solution && solution.logo ? (
                      <img
                        src={solution.logo}
                        alt="Module logo"
                        height="42"
                        width="42"
                      />
                    ) : (
                      <ModuleIcon />
                    )}
                  </div>
                </div>
                <h4 className="text-color">{solution && solution.name}</h4>
              </div>
            </div>
            <h4 />
            <h4 className="container-item_col">
              {getStatus(
                status,
                "Not installed"
              )}
            </h4>
          </div>
        </div>
      </>
    );
  };

  renderContentVideos = () => {
    const { solution } = this.props;

    return (
      <div>
        {solution &&
          solution.videos &&
          solution.videos.map((video) => (
            <div>
              <div className="container-item">
                <div className="container-item_col">
                  <div className="container-item_name">
                    <div className="container-item_icon">
                      <div className="checkbox__image">
                        <PlayCircle />
                      </div>
                    </div>
                    <h4 className="text-color">{video.name}</h4>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  };

  renderContentDashboards = () => {
    const { solution } = this.props;

    return (
      <div>
        {solution &&
          solution.dashboards &&
          solution.dashboards.map((dashboard) => (
            <div>
              <div className="container-item">
                <div className="container-item_col">
                  <div className="container-item_name">
                    <div className="container-item_icon">
                      <div className="checkbox__image">
                        <DashboardIcon />
                      </div>
                    </div>
                    <h4 className="text-color">{dashboard.title}</h4>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  };

  getPrice = async () => {
    const { requiredModules } = this.props;
    const { activeModule } = this.state;

    requiredModules.forEach(async module => {
      const { data } = await getMarketplaceSolution({
        id: module.link,
      });

      if (!data.errors && data.data && data.data.solution) {
        const payload = {
          id: data.data.solution.id,
          priceId: data.data.solution.monthlyPrice.id,
        };

        purchaseSolution(payload);
      }
    })

  };

  onSubmit = async () => {
    const { workspace, solution, requiredModules } = this.props;
    this.setState({ loading: true });
    const workspacePath = workspace && slugify(workspace.name);

    const isApplication = solution && solution.type === "application";

    if (isApplication) {
      const payload = {
        id: this.state.result.id,
        priceId: this.state.result.priceId.id,
      };
      this.getPrice();

      const { data } = await purchaseSolution(payload);
      this.setState({ loading: false });

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      marketplaceNotifier(
        `${intl.formatMessage({ ...appMessages.theNewApplication })} ${solution.name
        }
       ${intl.formatMessage({ ...appMessages.addedAsTemplate })}`,
        `/${workspacePath}${routeMapping.NEW_LIBRARY_APPLICATION_TEMPLATE.path}`,
        true
      );

      await localForage.setItem("activePartnerId", null);

      this.props.onSubmit()
      return this.props.router.navigate(
        `/${workspacePath}${routeMapping.SETTINGS.path}?purchase=true`
      );
    }


    if (!isApplication) {
      const payload = {
        id: this.state.result.id,
        priceId: this.state.result.priceId.id,
      };

      const { data } = await purchaseSolution(payload);
      this.setState({ loading: false });

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      defaultNotifier(
        `${intl.formatMessage({ ...appMessages.module })} ${solution.name
        }
       ${intl.formatMessage({ ...appMessages.isInstalling })}`
      );

      await localForage.setItem("activePartnerId", null);
      this.props.onSubmit()

      return this.props.router.navigate(
        `/${workspacePath}${routeMapping.SETTINGS.path}?purchase=true`
      );
    }
  };

  render() {
    const { modules, solution } = this.props;

    if (modules.loading) {
      return <LoadingIndicator />;
    }

    const isApplication = solution && solution.type === "application";

    return (
      <ProcessPage onCancel={this.onClose}>
        {() => (
          <div>
            <div className="headline flow-modules">
              <div className="flow-modules-icon">
                <img
                  src={solution && solution.logo}
                  width={85}
                  height={85}
                />
              </div>
              <h1 className="headline__title">
                Install {solution && solution.name}{" "}
                {isApplication
                  ? "Application"
                  : "Module"}
              </h1>
              <h4 className="headline__subtitle">
                {isApplication
                  ? "The item requires the following assets to be installed."
                  : "Continue to install this module to your workspace."}
              </h4>
            </div>
            <>
              {isApplication && (
                <CardTable
                  color={this.props.color}
                  title={
                    <FormattedMessage {...appMessages.applicationTemplate} />
                  }
                  subTitle={
                    <FormattedMessage
                      {...appMessages.applicationTemplateDesc}
                    />
                  }
                  className="single-card flow-modules"
                  content={
                    <div>
                      <div>
                        <div className="container-item">
                          <div className="container-item_col">
                            <div className="container-item_name">
                              <div className="container-item_icon">
                                <div className="checkbox__image">
                                  <ModuleIcon />
                                </div>
                              </div>
                              <h4 className="text-color">
                                {solution && solution.name}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                />
              )}
              {isApplication &&
                <CardTable
                  color={this.props.color}
                  title={<FormattedMessage {...appMessages.requiredModules} />}
                  subTitle={
                    <FormattedMessage
                      {...appMessages.currentApplicationRequiresTheFollowingModules}
                    />
                  }
                  className="single-card flow-modules"
                  onEdit={this.props.onEdit}
                  content={this.renderContent()}
                />
              }
              {solution && solution.type === "module" &&
                <CardTable
                  color={this.props.color}
                  title={'Module'}
                  subTitle={'The module will be installed to your workspace library.'}
                  className="single-card flow-modules"
                  onEdit={this.props.onEdit}
                  content={this.renderContentModule()}
                />
              }
              {solution &&
                solution.videos &&
                !!solution.videos.length && (
                  <CardTable
                    color={this.props.color}
                    title={'Video data'}
                    className="single-card flow-modules"
                    onEdit={this.props.onEdit}
                    content={this.renderContentVideos()}
                  />
                )}
              {solution &&
                solution.dashboards &&
                !!solution.dashboards.length && (
                  <CardTable
                    color={this.props.color}
                    title={
                      <FormattedMessage {...appMessages.dashboard} />
                    }
                    className="single-card flow-modules"
                    onEdit={this.props.onEdit}
                    content={this.renderContentDashboards()}
                  />
                )}
            </>
            <div className="center">
              <div className="flow-modules__footer">
                By clicking “Install now” you agree to the applications{' '}
                <TermsLink /> and the viso.ai <TermsLink />.
              </div>
              <br />
              <Button
                className={classNames(`button button_${this.props.color}`, {})}
                onClick={this.onSubmit}
              >
                <FormattedMessage {...appMessages.installNow} />
              </Button>
            </div>
          </div>
        )}
      </ProcessPage>
    );
  }
}

RequiredModules.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  workspaces: PropTypes.any,
  selectWorkspaceRequest: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  modules: getLibraryModulesList(),
  workspace: makeSelectFetchWorkspace(),
  isLoading: makeSelectLoading(),
  workspaces: makeSelectUserWorkspace(),
});

const withConnect = connect(mapStateToProps, { libraryModulesListRequest });

const withReducer = injectReducer({ key: "library", reducer });
const withSaga = injectSaga({ key: "library", saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(RequiredModules);
