/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icon3 = ({ className }) => {
  return (
    <svg
      className={`icon-3 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 15 16"
      width="15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M0.292893 0.892899C0.683417 0.502375 1.31658 0.502375 1.70711 0.892899L7.5 6.68579L13.2929 0.892899C13.6834 0.502375 14.3166 0.502375 14.7071 0.892899C15.0976 1.28342 15.0976 1.91659 14.7071 2.30711L8.91421 8.10001L14.7071 13.8929C15.0976 14.2834 15.0976 14.9166 14.7071 15.3071C14.3166 15.6976 13.6834 15.6976 13.2929 15.3071L7.5 9.51422L1.70711 15.3071C1.31658 15.6976 0.683417 15.6976 0.292893 15.3071C-0.0976311 14.9166 -0.0976311 14.2834 0.292893 13.8929L6.08579 8.10001L0.292893 2.30711C-0.0976311 1.91659 -0.0976311 1.28342 0.292893 0.892899Z"
        fill="#8B959E"
        fillRule="evenodd"
      />
    </svg>
  );
};
