import classNames from 'classnames';
import AHref from 'components/AHref';
import Button from 'components/Button';
import { FeatureCheck, FeatureNone } from 'images/common';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';
import { getMonthlyMRR, getYearlyMRR, isFreePlan } from 'utils/common';
import { EXTERNAL_URL } from 'utils/constants';
import messages from 'utils/messages';
import PlanDescription from './PlanDescription';
import { plans } from './plans';


const planTitle = {
  Starter: 'Evaluate Viso Suite for your projects',
  Evaluation: 'Evaluate Viso Suite for your projects',
  Personal: 'Evaluate Viso Suite for your projects',
  Business: 'Ideal for small to mid sized organizations',
  Standard: 'Ideal for small to mid sized organizations',
  Professional: 'Ideal for unlimited apps with dedicated support',
  Production: 'Ideal for unlimited apps with dedicated support',
};


const limits = {
  devicesLimit: 'deviceLimit',
  applications: 'applicationLimit',
  versionHistory: 'applicationVersionLimit',
  dashboardEditor: 'dashboardLimit',
  dashboards: 'dashboardLimit',
  users: 'userLimit',
  moduleLibrary: 'moduleLimit',
  cloudStorage: 'influxStorageLimit',
  monthlyDeployments: 'deploymentLimit',
  deviceDataRetention: 'deviceMetricsRetentionDays',
  dataRetention: 'appDataRetentionDays',
};

const PlanPrice = ({
  onUpdateIndex,
  activeIndex,
  endpoints,
  isEnterprise,
  plan,
  onSelectPlan,
  showFeature,
  intervalType,
  selectedPlan,
  currentPlan,
  isValidForCustomer,
  showAllFeatures,
}) => {
  // const inputNumber = isNaN(endpoints) ? endpoints && endpoints.replace(/,/g, '') : endpoints;
  // const minEndpoint = plan.monthlyPrice.minEndpoints;
  // const minEndpoints =
  //   intervalType === 'year'
  //     ? plan.yearlyPrice && plan.yearlyPrice.minEndpoints
  //     : plan.monthlyPrice && plan.monthlyPrice.minEndpoints;

  const minEndpoints = plan.minEndpoints

  const activeEndpoint =
    minEndpoints <= parseInt(endpoints, 10) ? endpoints : minEndpoints;

  const totalPrice =
    intervalType === 'year'
      ? getYearlyMRR(activeEndpoint, plan)
      : getMonthlyMRR(activeEndpoint, plan) || 0;

  let currentPricePerInterval = totalPrice

  if (!endpoints) {
    currentPricePerInterval = 0.0;
  }

  let trialPeriods =
  plan[intervalType === 'year' ? 'yearlyPrice' : 'monthlyPrice'] &&
  plan[intervalType === 'year' ? 'yearlyPrice' : 'monthlyPrice'].trialPeriodDays;

  if (currentPlan) {
    trialPeriods = '';
  }

  const getFieldValue = (item, planName) => {
    if (item.name === 'includedDevices') {
      return minEndpoints;
    }

    return item[planName] || item.earlyAccess;
  };

  const getPlanTrial = (item, features) => {
    if (features === 'Features') {
      const isFree = isFreePlan(item, intervalType);

      if (isEnterprise) {
        return '';
      }

      if (isFree) {
        return <h4 className="plan-info-trial">Free forever</h4>;
      }
      // let trialPeriod =
      // item[intervalType === 'year' ? 'yearlyPrice' : 'monthlyPrice'] &&
      // item[intervalType === 'year' ? 'yearlyPrice' : 'monthlyPrice'].trialPeriodDays;

      const durationNumber = item.phases[0].duration.number >= 0 ? item.phases[0].duration.number : '';
      let trialPeriod = `${durationNumber} ${item.phases[0].duration.unit}`

      if (trialPeriod) {
        return <h4 className="plan-info-trial">{trialPeriod} trial</h4>
      }
    }

    return '';
  };

  const getDynamicFieldValue = (item, planItem) => {
    const val = planItem && planItem.features[limits[item.name]];

    if (val === 0 && item.name === 'dashboardEditor') {
      return <FeatureNone />;
    }

    if (item.name === 'dashboardEditor') {
      return <FeatureCheck />;
    }

    if (val && item.name === 'cloudStorage') {
      return (
        <>
          <NumberFormat
            value={val}
            thousandSeparator="’"
            displayType="text"
            renderText={value => value}
          />
          {' '}
          GB
        </>
      );
    }

    if (
      val
      && (item.name === 'deviceDataRetention' || item.name === 'dataRetention')
    ) {
      return (
        <>
          <NumberFormat
            value={val}
            thousandSeparator="’"
            displayType="text"
            renderText={value => value}
          />
          {' '}
          days
        </>
      );
    }

    if (!val && val !== 0) {
      return 'Unlimited';
    }

    return (
      <NumberFormat
        value={val}
        thousandSeparator="’"
        displayType="text"
        renderText={value => value}
      />
    );
  };

  const isFree = isFreePlan(plan, intervalType);

  const trialFee =
  plan[intervalType === 'year' ? 'yearlyPrice' : 'monthlyPrice'] &&
  plan[intervalType === 'year' ? 'yearlyPrice' : 'monthlyPrice'].trialFee;

  return (
    <div
      className={classNames('plan-info-wrapper', {
        active: selectedPlan === plan.id,
      })}
    >
      <div>
        {isEnterprise ? (
          <>
            <div className="plan-info-container">
              <div className="plan-info-header">{plan.name}</div>
              <div className="plan-info-enterprise">
                <FormattedMessage {...messages.enterprisePlanPricing} />
              </div>
              <AHref href={EXTERNAL_URL.contact} target="_blank">
                <Button
                  type="button"
                  className="button button_xxm button_accent5 button_plan"
                >
                  <FormattedMessage {...messages.contactUsBtn} />
                </Button>
              </AHref>
            </div>
          </>
        ) : (
          <>
            <div className="plan-info-container">
              <div className="plan-info-header">{plan.name}</div>
              <div className="plan-info-price">
                <>
                  {isFree && (
                    <div>
                      <span className="plan-info-interval" />
                      <FormattedMessage {...messages.free} />
                    </div>
                  )}

                  {!isFree && (
                  <>
                    <div>
                      {isValidForCustomer ? (
                        <NumberFormat
                          value={currentPricePerInterval}
                          displayType="text"
                          thousandSeparator="’"
                          prefix="$"
                          renderText={value => (
                            <>
                              {value}
                              <span className="plan-info-interval">/mo</span>
                            </>
                          )}
                        />
                      ) : (
                        <div>
                          <span className="plan-info-interval" />
                          N/a
                        </div>
                      )}
                    </div>
                  </>
                  )
                  }
                </>
              </div>
              {isFree
                ? (
                  <div className="plan-info-price-desc">
                    start and test all
                    {' '}
                    <br />
                    features for free
                  </div>
                )
                : (
                  <div className="plan-info-price-desc">
                    {/* <FormattedMessage {...messages.aMonth} /> */}
                    <div>
                      {/* {isValidForCustomer ? (
                      <NumberFormat
                        value={currentPrice}
                        prefix={'$'}
                        thousandSeparator="’"
                        displayType={'text'}
                        renderText={value => {
                          return (
                            <>{value}{' '}
                              <FormattedMessage {...messages.perDeviceAverage} />
                            </>
                          );
                        }}
                      />
                    ) : (
                      'N/A'
                    )} */}
                      {planTitle[plan.name] || 'Ideal for small to mid sized organizations'}
                    </div>
                  </div>
                )
              }
              {currentPlan === plan.id ? (
                <Button
                  type="button"
                  className="button button_xxm button_accent5 button_plan button_plan_current"
                >
                  <FormattedMessage {...messages.currentPlan} />
                </Button>
              ) : (
                <Button
                  type="button"
                  onClick={() => {
                    if (isValidForCustomer) {
                      onSelectPlan(
                        plan,
                        minEndpoints,
                        currentPricePerInterval,
                        intervalType,
                      );
                    }
                  }}
                  className={classNames(
                    'button button_xxm button_accent5 button_plan',
                    {
                      button_plan_current: !isValidForCustomer,
                    },
                  )}
                >
                  {isFree || (trialPeriods && !trialFee)
                    ? 'Start for free'
                    : (
                      <>
                        {(trialPeriods && trialFee) ? (
                          <NumberFormat
                            value={trialFee / 100}
                            displayType="text"
                            thousandSeparator="’"
                            prefix="$"
                            renderText={value => (
                              <>
                                <FormattedMessage
                                  {...messages.startTrail}
                                  values={{ days: value }}
                                />
                              </>
                            )}
                          />
                        ) : (
                          <FormattedMessage {...messages.getStarted} />
                        )}
                      </>
                    )
                  }
                </Button>
              )}
            </div>
          </>
        )}
      </div>
      {Object.keys(plans)
        .filter((item, index) => {
          if (!showAllFeatures) {
            return index === 0;
          }

          return true;
        })
        .map((planItem, ind) => {
          const planTrailInfo = getPlanTrial(plan, planItem);

          return (
            <div key={ind}>
              <div className="plan-info-feature">{planTrailInfo}</div>
              {plans[planItem].map((item, index) => {
                const planDynamicValue = getDynamicFieldValue(
                  item,
                  plan,
                  limits,
                );
                const planValue = getFieldValue(item, plan.name);

                return (
                  <div
                    key={index}
                    className={classNames('plan-info-feature__item', {
                      'plan-active-index': activeIndex === item.id,
                      'no-cursor': !item.description,
                    })}
                    onClick={() => showFeature(item.description, item.name)}
                    onMouseEnter={() => onUpdateIndex(item.id)}
                    onMouseLeave={() => onUpdateIndex()}
                  >
                    <PlanDescription
                      description={
                        limits[item.name] ? planDynamicValue : planValue
                      }
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      {' '}
    </div>
  );
};

PlanPrice.propTypes = {
  loginRequest: PropTypes.func,
};

export default PlanPrice;
