import classNames from 'classnames';
import VerticalButtonMenu from 'components/VerticalButtonMenu';
import { logout, updateSidebarDisplay } from 'containers/App/actions';
import reducer from 'containers/App/reducer';
import { getSidebarStatus } from 'containers/App/selectors';
import React from 'react';
import { ChevronLeft, Menu } from 'react-feather';
import { connect } from 'react-redux';
import {withRouter } from 'withRouter'
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from 'utils/injectReducer';

class TopNav extends React.Component {
  render() {
    const {
      title,
      header,
      children,
      onBack,
      addSpacing,
    } = this.props;
    const topWrapperClass = classNames('top-nav', {
      'xt-spacing': addSpacing,
    });
    return (
      <div className={topWrapperClass}>
        {!onBack && (
          <Menu
            strokeWidth={1}
            onClick={() => this.props.updateSidebarDisplay(false)}
          />
        )}
        {onBack && <ChevronLeft onClick={onBack} />}
        <h3 className="top-nav-title">{title}</h3>
        <div className="top-nav-header">{header}</div>
        <div className="mobile-nav-item mobile-nav-settings">
          {children || (
            <div className="actions-nav actions-nav-mobile text-color">
              <VerticalButtonMenu />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  sidebarStatus: getSidebarStatus(),
});

const withConnect = connect(
  mapStateToProps,
  {
    logout,
    updateSidebarDisplay,
  },
);

const withReducer = injectReducer({ key: 'global', reducer });

export default withRouter(
  compose(
    withReducer,
    withConnect,
  )(TopNav),
);
