import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import slugify from 'react-slugify';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import messages from 'utils/messages';
import ProcessPage from 'components/ProcessPage';
import { makeSelectFetchWorkspace } from 'containers/Settings/Workspace/selectors';

import { dateFormatter, truncate } from 'utils/common';
import { routeMapping } from 'routes';
import EstimateItem from './billEstimate/EstimateItem';

class BillingSummary extends Component {
  getAddressInfo = (billing) => {
    let address = '';

    if (!billing) {
      return '';
    }

    if (billing.address1) {
      address = `${billing.address1}`;
    }

    if (billing.address2) {
      address = address ? `${address}, ${billing.address2}` : ` ${billing.address2}`;
    }

    if (billing.city) {
      address = address ? `${address}, ${billing.city}` : ` ${billing.city}`;
    }

    if (billing.postCode) {
      address = address ? `${address}, ${billing.postCode}` : ` ${billing.postCode}`;
    }

    if (billing.country) {
      address = address ? `${address}, ${billing.country}` : ` ${billing.country}`;
    }

    return truncate(address, 100);
  }

  render() {
    const {
      billing, contact, upcomingInvoices, invoices, workspace,
    } = this.props;
    const startDate = upcomingInvoices.period_start && dateFormatter(
      upcomingInvoices.period_start * 1000,
    );
    const endDate = upcomingInvoices.period_end && dateFormatter(
      upcomingInvoices.period_end * 1000,
    );

    const nameList = upcomingInvoices && upcomingInvoices.lines.data && upcomingInvoices.lines.data[0].description;
    const description = nameList && nameList.split('(');
    const address = this.getAddressInfo(billing);

    let endpoint = 0;

    upcomingInvoices
      && upcomingInvoices.lines
      && upcomingInvoices.lines.data
      && upcomingInvoices.lines.data.forEach((item) => { endpoint += +item.quantity; });

    const wsPath = workspace && slugify(workspace.name);

    return (
      <ProcessPage onCancel={this.props.onClose}>
        {() => (
          <>
            <div>
              <div className="billing_summary__container">
                <div className="billing_summary__header">
                  <FormattedMessage {...messages.summary} />
                </div>
                <hr />
                <div className="billing_summary__wrapper">
                  <div className="grid-row">
                    <div className="grid-col grid-col_6">
                      <div className="grid-row">
                        <div className="grid-col grid-col_6">
                          <div className="billing_summary__title">
                            <FormattedMessage {...messages.billTo} />
                          </div>
                        </div>
                        <div className="grid-col grid-col_6">
                          <div className="billing_summary__description">
                            <Link
                              to={`/${wsPath}${routeMapping.SETTINGS_USER.path}/${contact.id}`}
                            >
                              {contact && contact.userMeta && contact.userMeta.email}
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="grid-row">
                        <div className="grid-col grid-col_6">
                          <div className="billing_summary__title">
                            <FormattedMessage {...messages.name} />
                          </div>
                        </div>
                        <div className="grid-col grid-col_6">
                          <div className="billing_summary__description">
                            {contact.firstName}
                            {' '}
                            {contact.lastName}
                          </div>
                        </div>
                      </div>

                      <div className="grid-row">
                        <div className="grid-col grid-col_6">
                          <div className="billing_summary__title">
                            <FormattedMessage {...messages.address} />
                          </div>
                        </div>
                        <div className="grid-col grid-col_6">
                          <div className="billing_summary__description">
                            {address}
                          </div>
                        </div>
                      </div>

                      <div className="grid-row">
                        <div className="grid-col grid-col_6">
                          <div className="billing_summary__title">
                            <FormattedMessage {...messages.phoneNumber} />
                          </div>
                        </div>
                        <div className="grid-col grid-col_6">
                          <div className="billing_summary__title">
                            No phone number
                          </div>
                        </div>
                      </div>

                      <div className="grid-row">
                        <div className="grid-col grid-col_6">
                          <div className="billing_summary__title">
                            <FormattedMessage {...messages.currency} />
                          </div>
                        </div>
                        <div className="grid-col grid-col_6">
                          <div className="billing_summary__description">
                            {upcomingInvoices.currency && upcomingInvoices.currency.toUpperCase()}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="grid-col grid-col_6">
                      <div className="grid-row">
                        <div className="grid-col grid-col_6">
                          <div className="billing_summary__title">
                            <FormattedMessage {...messages.invoiceNumber} />
                          </div>
                        </div>
                        <div className="grid-col grid-col_6">
                          <div className="billing_summary__description">
                            {invoices.data && invoices.data[0].id}
                          </div>
                        </div>
                      </div>
                      <div className="grid-row">
                        <div className="grid-col grid-col_6">
                          <div className="billing_summary__title">
                            <FormattedMessage {...messages.billingMethod} />
                          </div>
                        </div>
                        <div className="grid-col grid-col_6">
                          <div className="billing_summary__description">
                            {upcomingInvoices.collection_method}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="data-table billing_table__summary">
                <table className="data-table__table">
                  <thead className="data-table__head">
                    <tr>
                      <th className="data-table__th data-table__th_1" />
                      <th className="data-table__th data-table__td_dark fw500 ">
                        <FormattedMessage {...messages.description} />
                      </th>
                      <th className="data-table__th pull-right data-table__td_dark fw500">
                        <FormattedMessage {...messages.endpoints} />
                      </th>
                      <th className="data-table__th pull-right data-table__td_dark fw500">
                        <FormattedMessage {...messages.unitPrice} />
                      </th>
                      <th className="data-table__th pull-right data-table__td_dark fw500">
                        <FormattedMessage {...messages.amount} />
                      </th>
                      <th className="data-table__th data-table__th_1" />
                    </tr>
                  </thead>

                  <tbody className="data-table__body">
                    <tr className="data-table__row data-table__border table__row_noclick">
                      <th className="data-table__td data-table__td_1" />
                      <td className="data-table__td data-table__date">
                        {startDate}
                        {' '}
                        -
                        {endDate}
                      </td>
                      <td className="data-table__td" />
                      <td className="data-table__td" />
                      <td className="data-table__td" />
                      <th className="data-table__td data-table__td_1" />
                    </tr>
                    <EstimateItem
                      description={description[0]}
                      endpoints={+endpoint}
                      hasBorder
                      isBold
                    />
                    {upcomingInvoices.lines
                    && upcomingInvoices.lines.data
                    && upcomingInvoices.lines.data.map((item, index) => {
                      const tableborder = classNames('data-table__row', {
                        'data-table__border': index === upcomingInvoices.lines.data.length - 1,
                      });

                      return (
                        <tr className={tableborder} key={index}>
                          <th className="data-table__td" />
                          <td className="data-table__td">
                            <span className="data-sub__td data-table__td_dark">
                              {item.description}
                            </span>
                          </td>
                          <td className="data-table__td pull-right data-table__td_dark">{item.quantity}</td>
                          <td className="data-table__td pull-right data-table__td_dark">{(item.amount / 100) / item.quantity}</td>
                          <NumberFormat
                            value={item.amount && parseFloat(item.amount / 100).toFixed(2)}
                            displayType="text"
                            thousandSeparator
                            renderText={value => (
                              <td className="data-table__td pull-right data-table__td_dark">
                                {item.currency && item.currency.toUpperCase()}
                                {' '}
                                {value}
                              </td>
                            )}
                          />
                          <th className="data-table__td data-table__td_1" />
                        </tr>
                      );
                    })}

                    <tr className="data-table__row">
                      <th className="data-table__td data-table__td_1" />
                      <td className="data-table__td" />
                      <td className="data-table__td" />
                      <td className="data-table__td data-table__td_dark fw500 pull-right">
                        <FormattedMessage {...messages.subTotal} />
                      </td>
                      <NumberFormat
                        value={upcomingInvoices.tax && parseFloat(upcomingInvoices.tax / 100).toFixed(2)}
                        displayType="text"
                        thousandSeparator
                        renderText={value => (
                          <td className="data-table__td data-table__td_dark fw500 pull-right">
                            {upcomingInvoices.currency
                        && upcomingInvoices.currency.toUpperCase()}
                            {' '}
                            {value || 0.00}
                          </td>
                        )}
                      />
                      <th className="data-table__td data-table__td_1" />
                    </tr>
                    <tr className="data-table__row">
                      <th className="data-table__td data-table__td_1" />
                      <td className="data-table__td" />
                      <td className="data-table__td" />
                      <td className="data-table__td data-table__td_dark fw500 pull-right">
                        <FormattedMessage {...messages.total} />
                      </td>
                      <NumberFormat
                        value={upcomingInvoices.total && parseFloat(upcomingInvoices.total / 100).toFixed(2)}
                        displayType="text"
                        thousandSeparator
                        renderText={value => (
                          <td className="data-table__td data-table__td_dark fw500 pull-right">
                            {upcomingInvoices.currency
                        && upcomingInvoices.currency.toUpperCase()}
                            {' '}
                            {value}
                          </td>
                        )}
                      />
                      <th className="data-table__td data-table__td_1" />
                    </tr>
                    <tr className="data-table__row">
                      <th className="data-table__td data-table__td_1" />
                      <td className="data-table__td" />
                      <td className="data-table__td" />
                      <td className="data-table__td data-table__td_dark fw500 pull-right">
                        <FormattedMessage {...messages.amountDue} />
                      </td>
                      <NumberFormat
                        value={upcomingInvoices.amount_due && parseFloat(upcomingInvoices.amount_due / 100).toFixed(2)}
                        displayType="text"
                        thousandSeparator
                        renderText={value => (
                          <td className="data-table__td data-table__td_dark fw500 pull-right">
                            {upcomingInvoices.currency
                        && upcomingInvoices.currency.toUpperCase()}
                            {' '}
                            {value}
                          </td>
                        )}
                      />
                      <th className="data-table__td data-table__td_1" />
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </ProcessPage>
    );
  }
}

BillingSummary.propTypes = {
  user: PropTypes.object,
};


const mapStateToProps = createStructuredSelector({
  workspace: makeSelectFetchWorkspace(),
});

const withConnect = connect(
  mapStateToProps, null,
);

export default withConnect(BillingSummary);
