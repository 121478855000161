import classNames from 'classnames';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';


class SmallCard extends Component {
  state = {
    showSingleMenu: false,
    settingSelected: null,
  };

  isActive = (id) => {
    const { selectedItems = [] } = this.props;

    return selectedItems.includes(id);
  };

  isDisabled = (id) => {
    const { disabledIds = [] } = this.props;

    return disabledIds.includes(id);
  };

  isSelected = (id) => {
    const { selectedItems = [] } = this.props;

    return selectedItems.indexOf(id) > -1;
  };

  onCloseSingleMenu = () => {
    this.setState({ showSingleMenu: false, settingSelected: null });
  };

  onToogleMenu = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      showSingleMenu: true,
      settingSelected: this.props.id,
    });
  };

  toggleSelect = (id, e) => {
    const {
      onSelect,
      selectedItems = [],
      inputType,
      disabled,
      allowOnlySingleSelect,
    } = this.props;

    if (this.isDisabled(this.props.id)) {
      return false;
    }

    if (disabled) {
      return;
    }

    if (allowOnlySingleSelect) {
      return onSelect(id, e);
    }

    if (inputType === 'radio') {
      return onSelect(id);
    }

    if (this.isSelected(id)) {
      onSelect(selectedItems.filter(item => item !== id), e);
    } else {
      onSelect(selectedItems.concat(id), e);
    }
  };

  render() {
    const {
      initials, statusIcon, preventRedirectOnClick, customClass, 
      parentClass, disabled, demoData
    } = this.props;


    return (
      <>
        <div
          className={classNames('data-card', {
            [parentClass]: this.props.parentClass,
            'demo-data-event': this.props.demoData,
            'data-card__loading': this.props.isLoading,
          })}
          onClick={(e) => {
            if(this.props.demoData) {
              return 
            }

            if (preventRedirectOnClick) {
              return this.toggleSelect(this.props.id, e);
            }
            if (!disabled) {
              this.props.onCardClick && this.props.onCardClick(this.props.id, e);
            }
          }}
        >
          <div
            className={classNames('data-cards__item', {
              [customClass]: this.props.customClass,
              'disabled-event': disabled,
              isActive: this.props.id && (this.state.settingSelected === this.props.id),
              [`data-cards__item_${this.props.colorClassName}`]: this.props
                .colorClassName,
              'data-cards__item_selected': this.isActive(this.props.id),
              'data-cards__item_disabled': this.isDisabled(this.props.id) || disabled,
            })}
          >
            {this.props.title && this.props.title.length > 24 && <p className="app-tooltip">{this.props.title}</p>}
            <div className="grid-row grid-row_aic">
              <div className="grid-col grid-col_auto">
                <div
                  className={classNames('checkbox', {
                    [`checkbox_${this.props.colorClassName}`]: this.props
                      .colorClassName,
                  })}
                >
                  {statusIcon}
                  <input
                    type="checkbox"
                    className="checkbox__input"
                    checked={this.isActive(this.props.id)}
                    onClick={e => this.toggleSelect(this.props.id, e)}
                    readOnly
                  />
                  {initials && (
                    <div className="checkbox__image">{initials}</div>
                  )}
                  {!initials && typeof this.props.CheckboxImage === 'function' && (
                    <div className="checkbox__image">
                      <this.props.CheckboxImage />
                    </div>
                  )}
                  {!initials
                    && typeof this.props.CheckboxImage !== 'function' && (
                    <div className="checkbox__image">
                      {this.props.CheckboxImage}
                    </div>
                  )}
                </div>
              </div>
              <div className="grid-col">
                <div className="data-cards__heading h4">
                  {this.props.heading}
                </div>
                <div className="data-cards__subheading h5">
                  {this.props.subheading}
                </div>
              </div>
              {!this.props.hideSingleMenu && (
                <OutsideClickHandler onOutsideClick={this.onCloseSingleMenu}>
                  <div className="data-cards__actions actions">
                    <Button
                      type="button"
                      className={classNames('actions__button', {
                        [this.props.colorClassName]: this.props.colorClassName,
                      })}
                      onClick={this.onToogleMenu}
                    />
                    {this.state.showSingleMenu
                      && React.Children.map(this.props.children, child => React.cloneElement(child, {
                        closeMenu: this.onCloseSingleMenu,
                        onToogleMenu: this.onToogleMenu,
                        showMenu: this.state.showSingleMenu,
                      }))}
                  </div>
                </OutsideClickHandler>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

SmallCard.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subheading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  colorClassName: PropTypes.string,
  selectedItems: PropTypes.array,
  id: PropTypes.string,
  onCardClick: PropTypes.func,
  onSelect: PropTypes.func,
  CheckboxImage: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default SmallCard;
