import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectLanguage = state => state.language || initialState;

export const makeSelectLocale = () => createSelector(selectLanguage, localeState => localeState.get('locale'));

export const makeSelectWorkspaceLocale = () => createSelector(selectLanguage, localeState => localeState.get('locale_workspace'));

export { selectLanguage };
