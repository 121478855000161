import {
  ADD_ROLE_REQUEST,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_TO_USERS_REQUEST,
  ADD_ROLE_TO_USERS_SUCCESS,
  ADD_ROLE_ERROR,
  LIST_ROLE_REQUEST,
  LIST_ROLE_SUCCESS,
  LIST_ROLE_ERROR,
  DETAIL_ROLE_REQUEST,
  DETAIL_ROLE_SUCCESS,
  DETAIL_ROLE_ERROR,
  LIST_PERMISSION_REQUEST,
  LIST_PERMISSION_SUCCESS,
  LIST_PERMISSION_ERROR,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_ERROR,
  UPDATE_ROLE_REQUEST,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_ERROR,
  INDIVIDUAL_ROLE_REQUEST,
  INDIVIDUAL_ROLE_SUCCESS,
  INDIVIDUAL_ROLE_ERROR,
  ADD_ROLE_TO_USER_REQUEST,
  ADD_ROLE_TO_USER_SUCCESS,
} from './constants';

export function listRoleRequest(payload) {
  return {
    type: LIST_ROLE_REQUEST,
    payload,
  };
}

export function listRoleSuccess(payload) {
  return {
    type: LIST_ROLE_SUCCESS,
    payload,
  };
}

export function addRoleToUserRequest(payload, onCallback) {
  return {
    type: ADD_ROLE_TO_USER_REQUEST,
    payload,
    meta: { onCallback },
  };
}

export function addRoleToUserSuccess(payload) {
  return {
    type: ADD_ROLE_TO_USER_SUCCESS,
    payload,
  };
}


export function addRoleToUsersRequest(payload, onCallback) {
  return {
    type: ADD_ROLE_TO_USERS_REQUEST,
    payload,
    meta: { onCallback },
  };
}

export function addRoleToUsersSuccess(payload) {
  return {
    type: ADD_ROLE_TO_USERS_SUCCESS,
    payload,
  };
}

export function listRoleError(errors) {
  return {
    type: LIST_ROLE_ERROR,
    errors,
  };
}

export function detailRoleRequest(payload) {
  return {
    type: DETAIL_ROLE_REQUEST,
    payload,
  };
}

export function individualRoleRequest(payload) {
  return {
    type: INDIVIDUAL_ROLE_REQUEST,
    payload,
  };
}

export function individualRoleSuccess(payload) {
  return {
    type: INDIVIDUAL_ROLE_SUCCESS,
    payload,
  };
}

export function individualRoleError(payload) {
  return {
    type: INDIVIDUAL_ROLE_ERROR,
    payload,
  };
}

export function detailRoleSuccess(payload) {
  return {
    type: DETAIL_ROLE_SUCCESS,
    payload,
  };
}

export function detailRoleError(errors) {
  return {
    type: DETAIL_ROLE_ERROR,
    errors,
  };
}

export function addRoleRequest(payload, userIds, onCallback) {
  return {
    type: ADD_ROLE_REQUEST,
    payload,
    meta: { userIds, onCallback },
  };
}

export function addRoleSuccess(payload) {
  return {
    type: ADD_ROLE_SUCCESS,
    payload,
  };
}

export function addRoleError(errors) {
  return {
    type: ADD_ROLE_ERROR,
    errors,
  };
}

export function deleteRoleRequest(payload) {
  return {
    type: DELETE_ROLE_REQUEST,
    payload,
  };
}

export function deleteRoleSuccess(payload) {
  return {
    type: DELETE_ROLE_SUCCESS,
    payload,
  };
}

export function deleteRoleError(errors) {
  return {
    type: DELETE_ROLE_ERROR,
    errors,
  };
}

export function updateRoleRequest(payload, meta) {
  return {
    type: UPDATE_ROLE_REQUEST,
    payload,
    meta,
  };
}

export function updateRoleSuccess(payload) {
  return {
    type: UPDATE_ROLE_SUCCESS,
    payload,
  };
}

export function updateRoleError(errors) {
  return {
    type: UPDATE_ROLE_ERROR,
    errors,
  };
}

export function listPermissionRequest(payload) {
  return {
    type: LIST_PERMISSION_REQUEST,
    payload,
  };
}

export function listPermissionSuccess(payload) {
  return {
    type: LIST_PERMISSION_SUCCESS,
    payload,
  };
}

export function listPermissionError(errors) {
  return {
    type: LIST_PERMISSION_ERROR,
    errors,
  };
}
