import { AUTH_BASE } from 'api/constants';
import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import axiosObj from './config';

const ROLE_RETURN_VALUES = {
  id: true,
  name: true,
  description: true,
  tags: true,
  createdAt: true,
  type: true,
  updatedAt: true,
  usersCount: true,
  createdBy: {
    id: true,
    name: true,
  },
  updatedBy: {
    id: true,
    name: true,
  },
  level: true,
  permissionUsers: true,
  permissionDevices: true,
  permissionGroups: true,
  permissionProfiles: true,
  permissionModules: true,
  permissionApplication: true,
  permissionRoles: true,
  permissionWorkspace: true,
  permissionBilling: true,
  permissionScopes: true,
  permissionDashboard: true,
  permissionDataCollection: true,
  permissionImageAnnotation: true,
  permissionModelTraining: true,
  permissionAIModel: true,
  permissionDataset: true,
  permissionTerminalAccessManage: true, // will be used for terminal access
  permissionVideoViewerManage: true, // will be used for video viewer
  permissionWorkspaceHistory: true
};

const ROLES_RETURN_VALUES = {
  id: true,
  name: true,
  type: true,
  level: true,
  createdAt: true,
  updatedAt: true,
  usersCount: true,
  tags: true,
  demoData: true,
};

const BULK_ACTION = {
  message: true,
  skippedCount: true,
};

export function getAllRoles(param) {
  const { data } = param;

  // if (data) {
  //   delete param.data;
  // }

  const query = {
    query: {
      roles: {
        __args: param,
        ...ROLES_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getAllRole(param) {
  const { data } = param;

  if (data) {
    delete param.data;
  }

  const query = {
    query: {
      role: {
        __args: Object.keys(param).length ? param : undefined,
        ...ROLE_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function addRole(param) {
  const query = {
    mutation: {
      createRole: {
        __args: param,
        ...ROLE_RETURN_VALUES,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}


export function assignRoleToUser(param) {
  const query = {
    mutation: {
      assignRoleToUser: {
        __args: param,
        ...ROLE_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function assignRoleToUsers(param) {
  const query = {
    mutation: {
      assignRoleToUsers: {
        __args: param,
        ...BULK_ACTION,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function deleteRole(param) {
  const query = {
    mutation: {
      deleteRole: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function updateRole(param) {
  const query = {
    mutation: {
      updateRole: {
        __args: param,
        ...ROLE_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getAllPermissions() {
  const query = {
    query: {
      permissions: true,
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}
