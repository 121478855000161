import { createSelector } from 'reselect';

const notifications = state => state.notifications;

export const getNotifications = () => createSelector(notifications, state => (state.get('notifications') ? state.get('notifications').toJS() : null));

export const getNotificationSettings = () => createSelector(notifications, state => (state.get('settings') ? state.get('settings').toJS() : null));


export const getNotificationLoading = () => createSelector(notifications, state => state.get('loading'));

export const getSettingLoading = () => createSelector(notifications, state => state.get('loadingSettings'));
