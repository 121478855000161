import React from 'react';

import { ModelIcon } from 'images/common';

import SmallCard from 'components/List/SmallCard';

class Card extends React.Component {
  render() {
    const {
      color,
    } = this.props;

    return (
      <div className="grid-row">
        {[1, 2, 3, 4, 5, 6].map((item) => {
          return (
            <SmallCard
              key={item}
              heading={'Testing'}
              title={'Heading'}
              id={item}
              isLoading
              hideSingleMenu
              allowOnlySingleSelect
              onCardClick={() => null}
              colorClassName={color}
              subheading={'testing'}
              onSelect={() => null}
              CheckboxImage={ModelIcon}
            >
            </SmallCard>
          );
        })}
      </div>
    );
  }
}

export default Card;
