import classNames from 'classnames';
import Button from 'components/Button';
import Input from 'components/Form/Input';
import InputPassword from 'components/Form/InputPassword';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { event } from 'react-ga';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Field, Form, reduxForm } from 'redux-form';
import messages from 'utils/messages';
import { routeMapping } from '../../../api/constants';
import { SIGNUP_FORM } from '../constants';
import validation from './validation';

class RegisterForm extends Component {
  state = {
    isChanged: '',
    hideLabel: false,
  }

  componentDidMount() {
    event({
      action: 'Register page',
      category: 'Register page',
    });
  }

  onChangePassword = () => {
    this.setState({ isChanged: true });
  }

  render() {
    const { valid, isLoading } = this.props;

    return (
      <div>
        <Form onSubmit={this.props.handleSubmit} className="login__form">
          <Field
            name="email"
            autoFocus={!this.props.autoFocus}
            component={Input}
            label={<FormattedMessage {...messages.email} />}
          />
          <Field
            name="fullname"
            component={Input}
            autoFocus={this.props.autoFocus}
            label={<FormattedMessage {...messages.fullName} />}
          />
          <Field
            name="password"
            onFocus={() => this.setState({ hideLabel: true })}
            onBlur={this.onChangePassword}
            component={InputPassword}
            label="Password"
          />
          <div className="login__actions register__actions">

            <Button
              type="submit"
              className={classNames('button login__submit', {
                'round-button_disabled': !valid || isLoading,
              })}
              disabled={!valid || isLoading}
            >
              <FormattedMessage {...messages.createAccount} />
            </Button>
            <div className="login__switch">
              <FormattedMessage {...messages.alreadyHaveAnAccount} />
              {' '}
              <Link to={routeMapping.LOGIN.path} className="accent0">
                <FormattedMessage {...messages.signIn} />
              </Link>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

RegisterForm.propTypes = {
  isLoading: PropTypes.bool,
  onContinue: PropTypes.func,
  valid: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: SIGNUP_FORM,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  validate: validation,
})(RegisterForm);
