import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';

import Button from 'components/Button';

class VerticalButtonMenu extends Component {
  state = {
    showDropdown: false,
  };

  toggleAction = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (this.props.onClickMenu) {
      this.props.onClickMenu();
    }

    this.setState({ showDropdown: true });
  };

  closeMenu = () => {
    this.setState({ showDropdown: false });
  };

  onActionOutsideClick = () => {
    if (this.props.onClearSelection) {
      this.props.onClearSelection();
    }
    this.setState({ showDropdown: false });
  };

  // eslint-disable-next-line
  componentWillReceiveProps(nextProps) {
    const { allowAutoClose } = nextProps;

    if (allowAutoClose) {
      this.setState({ showDropdown: false });
    }
  }

  render() {
    const { color, disabled } = this.props;

    const themeClass = classNames('actions__button', {
      actions__show: this.state.showDropdown && this.props.children,
      [color]: color,
      'disabled-event': disabled,
      'visibility-hidden': !this.props.children

    });

    return (
      <span className="actions">
        <OutsideClickHandler onOutsideClick={this.onActionOutsideClick}>
          <Button
            type="button"
            id={this.props.id}
            disableHoverEffect
            className={themeClass}
            onClick={e => this.toggleAction(e)}
          />
          {this.props.children && (
            <div
              className={classNames('actions__dropdown', {
                actions__dropdown_visible: this.state.showDropdown,
              })}
            >
              {this.state.showDropdown
                && React.Children.map(this.props.children, (child) => {
                  if (child) {
                    return React.cloneElement(child, {
                      closeMenu: this.closeMenu,
                      onToogleMenu: this.toggleAction,
                    });
                  }
                })}
            </div>
          )}
        </OutsideClickHandler>
      </span>
    );
  }
}

VerticalButtonMenu.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
};

export default VerticalButtonMenu;
