import React from 'react';

export default () => (
  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_24616_75098)">
      <path d="M6.50049 0.5C2.90599 0.5 0.000488281 3.4055 0.000488281 7C0.000488281 10.5945 2.90599 13.5 6.50049 13.5C10.095 13.5 13.0005 10.5945 13.0005 7C13.0005 3.4055 10.095 0.5 6.50049 0.5ZM9.29549 9.795C9.23535 9.85526 9.16393 9.90306 9.08529 9.93568C9.00666 9.9683 8.92237 9.98509 8.83724 9.98509C8.75211 9.98509 8.66782 9.9683 8.58918 9.93568C8.51055 9.90306 8.43912 9.85526 8.37899 9.795L6.50049 7.9165L4.62199 9.795C4.50045 9.91653 4.33562 9.98481 4.16374 9.98481C3.99186 9.98481 3.82702 9.91653 3.70549 9.795C3.58395 9.67346 3.51567 9.50863 3.51567 9.33675C3.51567 9.25164 3.53244 9.16737 3.56501 9.08875C3.59757 9.01012 3.64531 8.93868 3.70549 8.8785L5.58399 7L3.70549 5.1215C3.58395 4.99996 3.51567 4.83513 3.51567 4.66325C3.51567 4.49137 3.58395 4.32654 3.70549 4.205C3.82702 4.08346 3.99186 4.01519 4.16374 4.01519C4.33562 4.01519 4.50045 4.08346 4.62199 4.205L6.50049 6.0835L8.37899 4.205C8.43917 4.14482 8.51061 4.09709 8.58924 4.06452C8.66786 4.03195 8.75213 4.01519 8.83724 4.01519C8.92234 4.01519 9.00661 4.03195 9.08524 4.06452C9.16387 4.09709 9.23531 4.14482 9.29549 4.205C9.35567 4.26518 9.4034 4.33662 9.43597 4.41525C9.46854 4.49387 9.4853 4.57814 9.4853 4.66325C9.4853 4.74835 9.46854 4.83263 9.43597 4.91125C9.4034 4.98988 9.35567 5.06132 9.29549 5.1215L7.41699 7L9.29549 8.8785C9.54249 9.1255 9.54249 9.5415 9.29549 9.795Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_24616_75098">
        <rect width="13" height="13" fill="currentColor" transform="translate(0.000488281 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
