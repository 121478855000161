import classNames from 'classnames';
import Button from 'components/Button';
import Input from 'components/Form/Input';
import ProcessPage from 'components/ProcessPage';
import ConfirmationPage from 'containers/Auth/Register/ConfirmationPage';
import { resetPasswordRequest } from 'containers/Login/actions';
import reducer from 'containers/Login/reducer';
import saga from 'containers/Login/saga';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { Field, Form, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import messages from 'utils/messages';
import validation from '../Login/validation';


class ResetPassword extends Component {
  state = {
    isLoading: false,
    hideLabel: false,
  };

  onSetPassword = async (payload) => {
    const { valid } = this.props;

    if (valid) {
      const { email } = payload;
      this.setState({ isSubmitting: true });
      this.props.resetPasswordRequest({ email },
        () => this.setState({ email, showConfirmationPage: true, isSubmitting: false }));
    }
  };

  render() {
    const { valid, onClose } = this.props;
    const { isSubmitting } = this.state;

    if (this.state.showConfirmationPage) {
      return (
        <ConfirmationPage
          email={this.state.email}
          onClose={() => this.setState({ showConfirmationPage: false })}
          isResetPassword
        />
      );
    }

    return (
      <ProcessPage onClose={onClose}>
        {() => (
          <div>
            <div className="headline">
              <h1 className="headline__title center headline_title__register">
                Viso Suite
              </h1>
              {/* Update when api is ready */}
              <h3 className="headline__subtitle">
                Can’t log in? We’ll send you a recovery link.
              </h3>
            </div>
            <div className="login">
              <Form
                onSubmit={this.props.handleSubmit(this.onSetPassword)}
                className="login__form"
              >
                <Field
                  name="email"
                  component={Input}
                  label="Email"
                />
                <div className="login__actions register__actions">
                  <Button
                    type="submit"
                    className={classNames('button', {
                      'round-button_disabled': !valid || isSubmitting,
                    })}
                    disabled={!valid || isSubmitting}
                  >
                    {isSubmitting ? (
                      <FormattedMessage {...messages.loading} />
                    ) : (
                      <div>Send recovery link</div>
                    )}
                  </Button>
                  <div className="login_account">
                    <Link to="/login">
                      Return to log in
                    </Link>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        )}
      </ProcessPage>
    );
  }
}


const mapStateToProps = createStructuredSelector({
  // isLoading: makeSelectLoading()
});

const withConnect = connect(
  mapStateToProps,
  { resetPasswordRequest },
);

const withReducer = injectReducer({ key: 'login', reducer });
const withSaga = injectSaga({ key: 'login', saga });

export default compose(
  reduxForm({
    form: 'RESET_FORM',
    validate: validation,
  }),
  withReducer,
  withSaga,
  withConnect,
)(ResetPassword);
