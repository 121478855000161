import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import slugify from 'react-slugify';

import {UsersIcon,
  PersonalUserIcon,
  RoleIcon,
  ScopeIcon,
  WorkspaceIcon,
  IntegrationIcon,
  CopyIcon,
  BillingIcon,
  PartnerIcon,
  VideoAccessIcon,
  TerminalSettingIcon,
  MarketplaceIcon,
  SettingIcon} from 'images/common'

const SettingIcons = {
  roles: <RoleIcon/>,
  users: <UsersIcon/>,
  billing: <BillingIcon/>,
  partner: <PartnerIcon/>,
  scopes: <ScopeIcon/>,
  groups: <CopyIcon/>,
  licenses: <WorkspaceIcon/>,
  systemLog: <WorkspaceIcon/>,
  workspace: <SettingIcon/>,
  myWorkspace: <WorkspaceIcon/>,
  bulkActions: <WorkspaceIcon/>,
  integrations: <IntegrationIcon/>,
  personalUser: <PersonalUserIcon/>,
  terminalAccess: <TerminalSettingIcon/>,
  videoAccess: <VideoAccessIcon/>,
  marketplace: <MarketplaceIcon/>
}

const SettingsBox  = ({name, description, link, img, customClass, subDomain, action, dataCy}) => {
  const primaryClass = classNames('controls__item', {
    [customClass]: customClass
  })

  if (action) {
    return (
      <div className="">
        <div onClick={action} className={primaryClass}>
          <div className="grid-row">
            <div className="grid-col grid-col_auto">
              <div className="controls__icon">
                {SettingIcons[img]}
              </div>
            </div>
            <div className="grid-col">
              <div className="controls__info">
                <h4 className="controls__heading">{name}</h4>
                <div className="controls__subheading">{description}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="">
      {link ?
        <Link to={`/${slugify(subDomain)}${link}`} className={primaryClass} data-cy={dataCy}>
          <div className="grid-row">
            <div className="grid-col grid-col_auto">
              <div className="controls__icon">
                {SettingIcons[img]}
              </div>
            </div>
            <div className="grid-col">
              <div className="controls__info">
                <h4 className="controls__heading">{name}</h4>
                <div className="controls__subheading">{description}</div>
              </div>
            </div>
          </div>
        </Link>
        :
        <div className={primaryClass}>
          <div className="grid-row">
            <div className="grid-col grid-col_auto">
              <div className="controls__icon">
                {SettingIcons[img]}
              </div>
            </div>
            <div className="grid-col">
              <div className="controls__info">
                <h4 className="controls__heading">{name}</h4>
                <div className="controls__subheading">{description}</div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

SettingsBox.propTypes = {
  img: PropTypes.string,
  name: PropTypes.object,
  link: PropTypes.string,
  customClass: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

export default SettingsBox
