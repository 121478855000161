import { setPaymentMethod } from 'api/billing';
import { changePlan } from 'api/plans';
import Modal from 'components/Modal';
import {
  changeSubscriptionRequest,
  getSubscriptionDetailsRequest
} from 'containers/Billing/actions';
import ConfirmOrder from 'containers/Billing/components/details/ConfirmOrder';
import reducer from 'containers/Billing/reducer';
import saga from 'containers/Billing/saga';
import {
  getBillingDetails
} from 'containers/Billing/selectors';
import { intl } from 'containers/LanguageProvider/intlProvider';
import Plan from 'containers/Plans/PlanSelector';
import { fetchWorkspaceDetailRequest } from 'containers/Settings/Workspace/actions';
import { makeSelectFetchWorkspace } from 'containers/Settings/Workspace/selectors';
import { defaultNotifier } from 'functions/notificationHandler';
import { isNull } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {withRouter } from 'withRouter'
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { SOLUTION_ACCENT } from 'utils/constants';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import messages from 'utils/messages';
import {
  getBillingDetailsRequest, updateBillingRequest
} from '../../../actions';
import {makePlanData} from 'containers/Login/selectors'


const puralItems = {
  device: 'devices',
  module: 'modules',
  application: 'applications',
  video: 'videos',
  deployment: 'deployments',
  dashboard: 'dashboards',
  user: 'users',
  profile: 'profiles',
}

const singleItem = {
  device: 'Device',
  module: 'Module',
  application: 'Application',
  video: 'Video',
  deployment: 'Deployment',
  dashboard: 'Dashboard',
  user: 'User',
  profile: 'Profile',
}


class ChangePlan extends React.Component {
  state = {
    showPlanPage: false,
    planId: null,
    subscription: {},
    interval: 'year',
    endpoint: 0,
    price: 0,
    updateCreditCard: false,
    selectedSolution: null,
    showExceedModal: false,
    showConfirmOrderPage: false,
    showCard: false
  };

  componentDidMount() {
    this.props.getBillingDetailsRequest();
    this.props.fetchWorkspaceDetailRequest();
  }

  onSelectPlan = async (data, endpoint, price, interval) => {
    const { billing, workspace } = this.props;
    const planInfo = workspace && workspace.plan && workspace.plan;

    if (data.id === planInfo.id) {
      return this.onClose();
    }

    if (workspace && data.features &&
      (!isNull(data.features.deviceLimit) &&
      workspace.currentDeviceCount > data.features.deviceLimit)
    ) {
      return this.setState({ showExceedModal: true, errorLimit: 'device' });
    }

    if (workspace && data.features &&
      (!isNull(data.features.dashboardLimit) &&
      workspace.currentDashboardCount > data.features.dashboardLimit)
    ) {
      return this.setState({ showExceedModal: true, errorLimit: 'dashboard' });
    }

    if (workspace && data.features &&
      (!isNull(data.features.applicationLimit) &&
      workspace.currentApplicationCount > data.features.applicationLimit)
    ) {
      return this.setState({ showExceedModal: true, errorLimit: 'application' });
    }

    if (workspace && data.features &&
      (!isNull(data.features.deploymentLimit) &&
      workspace.currentDeploymentCount > data.features.deploymentLimit)
    ) {
      return this.setState({ showExceedModal: true, errorLimit: 'deployment' });
    }

    if (workspace && data.features &&
      (!isNull(data.features.moduleLimit) &&
      workspace.currentModuleCount > data.features.moduleLimit)
    ) {
      return this.setState({ showExceedModal: true, errorLimit: 'module' });
    }

    if (workspace && data.features &&
      (!isNull(data.features.profileLimit) &&
      workspace.currentProfileCount > data.features.profileLimit)
    ) {
      return this.setState({ showExceedModal: true, errorLimit: 'profile' });
    }

    if (workspace && data.features &&
      (!isNull(data.features.videoLimit) &&
      workspace.currentVideoCount > data.features.videoLimit)
    ) {
      return this.setState({ showExceedModal: true, errorLimit: 'video' });
    }

    if (workspace && data.features &&
      (!isNull(data.features.userLimit) &&
      workspace.userCounts > data.features.userLimit)
    ) {
      return this.setState({ showExceedModal: true, errorLimit: 'user' });
    }

    this.setState({
      showConfirmOrderPage: true,
      showPlanPage: false,
      planId: data.id,
      subscription: data,
      endpoint,
      interval,
      price: price,
      firstName: billing.billingInformation.firstName,
      lastName: billing.billingInformation.lastName,
      country: billing.billingInformation.country,
      address1: billing.billingInformation.address1,
      address2: billing.billingInformation.address2,
      city: billing.billingInformation.city,
      postCode: billing.billingInformation.postCode,
      company: billing.billingInformation.company || '',
      vat: billing.billingInformation.vat || ''
    });
  };

  onClose = () => {
    this.props.router.navigate(-1);
  };

  onShowPlanPage = id => {
    this.setState({ showConfirmOrderPage: false, selectedPlan: id });
  };

  onShowConfirmationPage = () => {
    this.setState({ showConfirmOrderPage: true });
  };

  onHideSelectPlan = () => {
    this.setState({ showPlanPage: false });
  };

  onHideConfirmationPage = () => {
    this.setState({ showConfirmOrderPage: false });
  };

  onGetPaymentInfo = data => {
    if (data.paymentMethod) {
      return this.setState({ paymentMethodId: data.paymentMethod.id });
    }

    return this.setState({ paymentMethodId: '' });
  };

  onSubmit = async () => {
    const {
      firstName,
      lastName,
      country,
      address1,
      address2,
      city,
      postCode,
      company,
      vat,
      isLoading
    } = this.state;
    const {workspace} = this.props;
    this.setState({ isLoading: true });

    if (!isLoading) {
      if (!workspace.hasPaymentSource) {
        await setPaymentMethod({paymentMethodId: this.state.paymentMethodId})
      }

      const { data } = await changePlan({
        planId: this.state.planId,
        priceId: this.state.priceId
      });
      this.setState({ isLoading: false });

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      if (!this.state.showCard) {
        this.props.updateBillingRequest({
          firstName,
          lastName,
          country,
          address1,
          address2,
          city,
          postCode: postCode || '',
          company: company || '',
          vat: vat || ''
        });
      }
      defaultNotifier(intl.formatMessage({ ...messages.planChanged }));

      this.props.getSubscriptionDetailsRequest();
      this.props.fetchWorkspaceDetailRequest();

      if (this.state.paymentMethodId && (this.state.updateCreditCard || !this.props.billing.paymentMethod) &&
      workspace.hasPaymentSource) {
        const response = await setPaymentMethod({paymentMethodId: this.state.paymentMethodId})
        if (response.data.errors) {
          defaultNotifier(response.data.errors)
        }
      }


      this.onClose();
    }
  };

  onChangeCard = payload => {
    this.setState({ showCard: payload });
  };

  onUpdatePlanInterval = (priceId, interval) => {
    this.setState({ priceId, interval });
  };

  onChangePayment = (field, value) => {
    this.setState({ [field]: value });
  };

  renderModal = () => {
    const buttons = [
      <button className="modal__cancel" key="cancel" onClick={() => this.setState({showExceedModal: false})}>
        <FormattedMessage {...messages.close} />
      </button>
    ];

    return (
      <Modal buttons={buttons}
        title={<FormattedMessage {...messages.itemLimitReached} values={{value: singleItem[this.state.errorLimit]}}/>}
        body={<FormattedMessage {...messages.itemLimitErrors}
          values={{item: this.state.errorLimit, items: puralItems[this.state.errorLimit]}}/>}
        onClose={() => this.setState({showExceedModal: false})} />
    );
  };

  render() {
    const { showConfirmOrderPage, subscription, price } = this.state;
    const { workspace, billing } = this.props;
    const subscriptionId = workspace.subscriptionId;
    const planInfo = workspace.plan;

    if (showConfirmOrderPage) {
      const {
        firstName,
        lastName,
        country,
        paymentMethodId
      } = this.state;

      let isDisabled = false;

      isDisabled = this.state.updateCreditCard || !billing.paymentMethod ?
        !(firstName && lastName && country && paymentMethodId) :
        !(firstName && lastName && country)

      return (
        <ConfirmOrder
          {...this.state}
          onClose={this.onClose}
          plan={subscription}
          price={price}
          plans={this.props.plans}
          onChangeCard={this.onChangeCard}
          isDisabled={isDisabled}
          card={billing.paymentMethod && billing.paymentMethod.card}
          name={
            billing.paymentMethod && billing.paymentMethod.billing_details.name
          }
          updateCreditCard={this.state.updateCreditCard}
          onUpdateCreditToggle={() => this.setState({updateCreditCard: !this.state.updateCreditCard})}
          onShowPlanPage={() => this.onShowPlanPage(this.state.selectedPlan)}
          billing={billing}
          onChangePayment={this.onChangePayment}
          onUpdateInterval={this.onUpdatePlanInterval}
          workspaceName={workspace && workspace.name}
          workspace={workspace}
          subscriptionId={subscriptionId}
          interval={this.state.interval}
          onGetPaymentInfo={this.onGetPaymentInfo}
          endpoint={this.state.endpoint}
          isLoading={this.state.isLoading}
          onBack={this.onHideConfirmationPage}
          onSubmit={this.onSubmit}
        />
      );
    }

    return (
      <>
        {this.state.showExceedModal && this.renderModal()}
        <Plan
          onClose={this.props.onClose}
          onSelectPlan={this.onSelectPlan}
          selectedPlan={planInfo.id}
        />
      </>
    );
  }
}

ChangePlan.propTypes = {
  color: PropTypes.string
};

ChangePlan.defaultProps = {
  color: SOLUTION_ACCENT
};

const mapStateToProps = createStructuredSelector({
  billing: getBillingDetails(),
  plans: makePlanData(),
  workspace: makeSelectFetchWorkspace()
});

const withReducer = injectReducer({ key: 'billing', reducer });
const withSaga = injectSaga({ key: 'billing', saga });

const withConnect = connect(
  mapStateToProps,
  {
    changeSubscriptionRequest,
    updateBillingRequest,
    getSubscriptionDetailsRequest,
    fetchWorkspaceDetailRequest,
    getBillingDetailsRequest
  }
);

export default compose(
  withReducer,
  withSaga,
  withConnect,
  withRouter
)(ChangePlan);
