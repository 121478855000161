import React from 'react';

export default () => (
  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0H2.625V2.625H0V0Z" fill="currentColor" />
    <path d="M0 4.375H2.625V7H0V4.375Z" fill="currentColor" />
    <path d="M0 8.75H2.625V11.375H0V8.75Z" fill="currentColor" />
    <path d="M4.375 0H14V2.625H4.375V0Z" fill="currentColor" />
    <path d="M4.375 4.375H14V7H4.375V4.375Z" fill="currentColor" />
    <path d="M4.375 8.75H14V11.375H4.375V8.75Z" fill="currentColor" />
  </svg>
);
