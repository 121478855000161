import { createSelector } from 'reselect';

const library = state => state.library;

export const getLoadingParams = () => createSelector(library, state => state.get('loadingParams').toJS());

export const getModule = () => createSelector(library, state => state.get('module').toJS());

export const getPrivateDocker = () => createSelector(library, state => state.get('privateDockerImage').toJS());

export const getVideos = () => createSelector(library, state => state.get('videos').toJS());

export const getVideo = () => createSelector(library, state => state.get('video').toJS());

export const getNodeRedModules = () => createSelector(library, state => state.get('noderedModules').toJS());

export const getLibraryFlowsList = () => createSelector(library, state => (state && state.get('flows') ? state.get('flows').toJS() : null));

export const getLibraryModelList = () => createSelector(library, state => (state && state.get('models') ? state.get('models').toJS() : null));

export const getLibraryEnviornmentList = () => createSelector(library, state => (state && state.get('imageAnnotation') ? state.get('imageAnnotation').toJS() : null));
export const getLibraryJobTrainingList = () => createSelector(library, state => (state && state.get('jobTraining') ? state.get('jobTraining').toJS() : null));
export const getLibraryExperimentList = () => createSelector(library, state => (state && state.get('experiments') ? state.get('experiments').toJS() : null));
export const getLibraryAnnotationTaskList = () => createSelector(library, state => (state && state.get('annotationTask') ? state.get('annotationTask').toJS() : null));
export const getLibraryAnnotationJobList = () => createSelector(library, state => (state && state.get('annotationJob') ? state.get('annotationJob').toJS() : null));

export const getTemplateList = () => createSelector(library, state => (state && state.get('template') ? state.get('template').toJS() : null));

export const getLibraryApplicationsLoading = () => createSelector(library, state => state.get('loading'));

export const getLibraryFlowsError = () => createSelector(library, state => state.get('errors'));

export const getLibraryModulesList = () => createSelector(library, state => state.get('modules') && state.get('modules').toJS());

export const getLibraryModulesLoading = () => createSelector(library, state => state.get('loading'));

export const getLibraryModulesError = () => createSelector(library, state => state.get('errors'));

export const getSSHKeys = () => createSelector(library, state => ((state && state.get('sshKeys')) ? state.get('sshKeys').toJS() : null));

export const getAwsAccessKeys = () => createSelector(library, state => ((state && state.get('awsAccessKey')) ? state.get('awsAccessKey').toJS() : null));

export const getFlow = () => createSelector(library, state => ((state && state.get('flow')) ? state.get('flow').toJS() : null));

export const getFlowLoading = () => createSelector(library, state => state.get('loading').toJS());

export const getFlowError = () => createSelector(library, state => (state.get('error') ? state.get('error').toJS() : null));

export const getFileData = () => createSelector(library, state => state.get('file').toJS());

export const getFilesData = () => createSelector(library, state => state.get('files').toJS());

export const getModuleVersion = () => createSelector(library, state => state.get('moduleVersions').toJS());

export const getModelTrainingList = () => createSelector(library, state => (state && state.get('modelTraining') ? state.get('modelTraining').toJS() : null));

export const getLibraryDatasetList = () => createSelector(library, state => (state && state.get('dataset') ? state.get('dataset').toJS() : null));
