import classNames from 'classnames';
import AHref from 'components/AHref';
import Button from 'components/Button';
import Card from 'components/Detail/Card';
import TermsLink from 'components/TermsLink';
import getSymbolFromCurrency from 'currency-symbol-map';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';
import {
  dateFormatter2,
  dateFormatter4,
  getMonthlyMRR,
  getYearlyMRR,
} from 'utils/common';
import { AVAILABLE_COUNTRIES } from 'utils/constants';
import appMessages from 'utils/messages';


const Title = ({ isFreeTrial, isCreatePage }) => {
  if (isFreeTrial) {
    return <span>Your Trial</span>;
  }

  if (isCreatePage) {
    return <span>Developer access</span>;
  }

  return <span>Billing</span>;
};

const ButtonFooter = ({ isFreeTrial, trialPeriods, isCreatePage }) => {
  if (isFreeTrial) {
    return (
      <span>
        Start your free
        {trialPeriods}
        {' '}
        day trial
      </span>
    );
  }

  if (isCreatePage) {
    return <span>Get developer access now</span>;
  }

  return <span>Agree and subscribe</span>;
};

class Estimate extends Component {
  state = {
    openModal: false,
  };

  onAddTags = (tags) => {
    this.setState({ tags });
  };

  openEditModal = () => {
    this.setState({ openModal: true });
  };

  closeEditModal = () => {
    this.setState({ openModal: false });
  };

  getEstimatedPrice = (yearlyPrice, monthlyPrice, trialPeriods) => {
    const { interval } = this.props;

    if (trialPeriods) {
      const totalFee = this.props.subscriptionDetails.trialFee;
      return (totalFee && (totalFee / 100).toFixed(2)) || '0.00';
    }

    if (interval === 'year') {
      return yearlyPrice;
    }

    if (interval === 'month') {
      return monthlyPrice;
    }
  };

  renderContent = () => {
    const {
      subscriptionDetails,
      interval,
      plans,
      isDisabled,
      isCreatePage,
      isFreeTrial,
      trialPeriods,
      billingInfo,
      isUpdatePlan,
      organizationSize,
    } = this.props;

    const isBiggerCompany = organizationSize && (organizationSize !== '1' && organizationSize !== '2-9');

    const endDate = trialPeriods
      ? moment(new Date()).add(trialPeriods, 'day')
      : moment(new Date()).add(1, interval);

    const quantity =
      subscriptionDetails.minEndpoints;

    // let currency =
    //   subscriptionDetails.currency;
    // currency = currency.toUpperCase();

    let currency = 'USD';

    const selectedYearlyPlan = plans && plans.find(plan =>
      plan.product === subscriptionDetails.product
      && plan.billingPeriod === 'ANNUAL');

    const selectedMonthlyPlan = plans && plans.find(plan =>
      plan.product === subscriptionDetails.product
        && plan.billingPeriod === 'MONTHLY');

    const estimatedYearlyPrice = getYearlyMRR(quantity, selectedYearlyPlan)

    const totalMonthlyPrice = parseFloat(
      getMonthlyMRR(quantity, selectedMonthlyPlan),
      10
    ).toFixed(2);

    const estimatedPrice = this.getEstimatedPrice(
      estimatedYearlyPrice,
      totalMonthlyPrice,
      trialPeriods,
    );


    let appliedBalance = '';

    appliedBalance = billingInfo && billingInfo.total >= 0
      ? billingInfo.amount_due - billingInfo.total
      : '';

    const subTotal = billingInfo && (billingInfo.subtotal / 100).toFixed(2);
    const amountDue = billingInfo && (billingInfo.amount_due / 100).toFixed(2);

    return (
      <>
        <div className="estimated-revenue__divider" />

        {!isUpdatePlan && (
          <>
            <NumberFormat
              value={estimatedPrice}
              displayType="text"
              thousandSeparator
              renderText={value => (
                <div className="grid-row estimated-revenue__item">
                  <div className="grid-col">
                    <div className="estimated-revenue__value">
                      {subscriptionDetails.name}
                      {' '}
                      {trialPeriods && 'Trial'}
                    </div>
                    <div className="estimated-revenue__value">
                      {quantity}
                      {' '}
                      <FormattedMessage {...appMessages.devices} />
                    </div>
                  </div>
                  <div className="grid-col grid-col_auto">
                    <div className="estimated-revenue__value">
                      {getSymbolFromCurrency(currency)}
                      {' '}
                      {value}
                    </div>
                  </div>
                </div>
              )}
            />
          </>
        )}
        {billingInfo
          && billingInfo.lines
          && billingInfo.lines.data
            .filter(item => item.proration)
            .map(line => (
              <>
                <NumberFormat
                  value={isUpdatePlan ? line.amount / 100 : estimatedPrice}
                  displayType="text"
                  thousandSeparator
                  renderText={value => (
                    <div className="grid-row estimated-revenue__item">
                      <div className="grid-col">
                        <div className="estimated-revenue__value">
                          {isUpdatePlan
                            ? line && line.description
                            : subscriptionDetails.name}
                          {' '}
                          {trialPeriods && 'Trial'}
                        </div>
                        <div className="estimated-revenue__value">
                          {isUpdatePlan ? line && line.quantity : quantity}
                          {' '}
                          <FormattedMessage {...appMessages.devices} />
                        </div>
                      </div>
                      <div className="grid-col grid-col_auto">
                        <div className="estimated-revenue__value">
                          {getSymbolFromCurrency(currency)}
                          {' '}
                          {value}
                        </div>
                      </div>
                    </div>
                  )}
                />
              </>
            ))}
        {billingInfo
          && billingInfo.lines
          && billingInfo.lines.data
            .filter(item => !item.proration)
            .filter((item, index) => index < 1)
            .map((item) => {
              const data = item.description && item.description.split(' × ');
              const name = data[1] && data[1].split('(');

              const totalAmount = billingInfo.lines.data
                .filter(line => !line.proration)
                .map(line => line.amount)
                .reduce((sum, line) => sum + line);

              const totalQuantity = billingInfo.lines.data
                .filter(line => !line.proration)
                .map(line => line.quantity)
                .reduce((sum, line) => sum + line);

              return (
                <>
                  <NumberFormat
                    value={isUpdatePlan ? (totalAmount / 100).toFixed(2) : estimatedPrice}
                    displayType="text"
                    thousandSeparator
                    renderText={value => (
                      <div className="grid-row estimated-revenue__item">
                        <div className="grid-col">
                          <div className="estimated-revenue__value">
                            {isUpdatePlan ? name && name[0] : subscriptionDetails.name}
                            {' '}
                            {trialPeriods && 'Trial'}
                          </div>
                          <div className="estimated-revenue__value">
                            {isUpdatePlan ? totalQuantity : quantity}
                            {' '}
                            <FormattedMessage {...appMessages.devices} />
                          </div>
                        </div>
                        <div className="grid-col grid-col_auto">
                          <div className="estimated-revenue__value">
                            {getSymbolFromCurrency(currency)}
                            {' '}
                            {value}
                          </div>
                        </div>
                      </div>
                    )}
                  />
                </>
              );
            })}
        <div className="estimated-revenue__divider" />

        <div className="grid-row grid-row_aic estimated-revenue__row_half">
          <div className="grid-col">
            <div className="estimated-revenue__label">
              <FormattedMessage {...appMessages.subTotal} />
            </div>
          </div>
          <div className="grid-col grid-col_auto">
            <div className="estimated-revenue__value c-label">
              <NumberFormat
                value={isUpdatePlan ? subTotal : estimatedPrice}
                displayType="text"
                thousandSeparator
                renderText={value => (
                  <span>
                    {`${getSymbolFromCurrency(currency)}${(value)}`
                  || '$0.00'}
                  </span>
                )}
              />
            </div>
          </div>
        </div>

        <div className="grid-row grid-row_aic estimated-revenue__row_half">
          <div className="grid-col">
            <div className="estimated-revenue__label">
              <FormattedMessage {...appMessages.tax} />
            </div>
          </div>
          <div className="grid-col grid-col_auto">
            <div className="estimated-revenue__value c-label">-</div>
          </div>
        </div>

        {/* <div className="grid-row grid-row_aic estimated-revenue__row_half">
          <div className="grid-col">
            <div className="estimated-revenue__label">
              <FormattedMessage {...appMessages.total} />
            </div>
          </div>
          <div className="grid-col grid-col_auto">
            <div className="estimated-revenue__value c-label">
              {getSymbolFromCurrency(currency)}{' '}
              {isUpdatePlan ? total : estimatedPrice}
            </div>
          </div>
        </div> */}

        {isUpdatePlan && !!appliedBalance && (
          <div className="grid-row grid-row_aic estimated-revenue__row_half">
            <div className="grid-col">
              <div className="estimated-revenue__label">
                <FormattedMessage {...appMessages.appliedBalance} />
              </div>
            </div>
            <div className="grid-col grid-col_auto">
              <div className="estimated-revenue__value c-label">
                {getSymbolFromCurrency(currency)}
                {' '}
                {appliedBalance && parseFloat(appliedBalance / 100).toFixed(2)}
              </div>
            </div>
          </div>
        )}
        <div className="grid-row grid-row_aic estimated-revenue__row">
          <div className="grid-col">
            <h3 className="estimated-revenue__value">
              <FormattedMessage {...appMessages.dueToday} />
            </h3>
          </div>
          <div className="grid-col grid-col_auto">
            <div className="estimated-revenue__value estimated-revenue__value_total">
              <NumberFormat
                value={isUpdatePlan ? amountDue : estimatedPrice}
                displayType="text"
                thousandSeparator
                renderText={value => (
                  <span>
                    {currency}
                    {' '}
                    {value}
                  </span>
                )}
              />
            </div>
          </div>
        </div>
        <div className="estimated-revenue__footer">
          {!isFreeTrial && !isCreatePage && (
            <span>
              By clicking “Agree and subscribe”, you agree to our
              {' '}
              <TermsLink />
              .
              You will be
              {' '}
              <b>automatically charged</b>
              {' '}
              for your subscription.
              The subscription will
              {' '}
              <b>automatically renew</b>
              {' '}
              until you cancel.
            </span>
          )}
        </div>
        <div className="estimated-revenue__divider1" />
        <Button
          className={`button button_${
            this.props.color
          } estimated-revenue__button`}
          disabled={isDisabled}
          onClick={this.props.onSubmit}
        >
          <h3>
            <ButtonFooter
              isFreeTrial={isFreeTrial}
              isCreatePage={isCreatePage}
              trialPeriods={trialPeriods}
            />
          </h3>
        </Button>
        {this.props.isFreePrice
        && isBiggerCompany
         && (this.props.selectedCountry && AVAILABLE_COUNTRIES.find(item => item.value === this.props.selectedCountry))
        && <h4 className="button-action__link center"><AHref onClick={this.props.onSubmitWithoutCard}>I’ll add the payment details later</AHref></h4>
        }
        {isCreatePage && (
          <div className="estimated-revenue__info">
            <div>
              <span className="tick-icon">✓ </span>
              {' '}
              You won’t be billed today
            </div>
            <div>
              <span className="tick-icon">✓ </span>
              {' '}
              Exclusive access free of
              charge
            </div>
          </div>
        )}
        {isFreeTrial && (
          <div className="estimated-revenue__info">
            <div>
              <span className="tick-icon">✓ </span>
              {' '}
              You won’t be billed today
            </div>
            <div>
              <span className="tick-icon">✓ </span>
              {' '}
              Cancel any time before
              {' '}
              {dateFormatter4(endDate)}
            </div>
          </div>
        )}
      </>
    );
  };

  render() {
    const {
      subscriptionDetails,
      interval,
      billingLoading,
      isUpdatePlan,
      isFreeTrial,
      isCreatePage
    } = this.props;

    const nextBillingDate = moment(new Date());

    let trialPeriods = subscriptionDetails.ype === 'TRIAL' && (subscriptionDetails.ype === 'TRIAL').duration.number;

    if (isUpdatePlan) {
      trialPeriods = '';
    }

    const endDate = trialPeriods
      ? moment(new Date()).add(trialPeriods, 'day')
      : moment(new Date()).add(1, interval);

    return (
      <Card
        noGrid
        action={this.openEditModal}
        color={this.props.color}
        title={<Title isCreatePage={isCreatePage} isFreeTrial={isFreeTrial} />}
        subTitle={
          <span className="estimated-revenue__subtitle">{`${dateFormatter2(
            nextBillingDate
          )} - ${dateFormatter2(endDate)}`}</span>
        }
        className={classNames('estimated-revenue', {
          'estimated-revenue_loading': billingLoading,
        })}
        content={this.renderContent()}
      />
    );
  }
}

Estimate.propTypes = {
  user: PropTypes.object,
};

export default Estimate;
