import { fromJS } from 'immutable';
import { LOGOUT } from 'containers/App/constants';
import { SELECT_WORKSPACE_SUCCESS } from 'containers/Login/constants';
import {
  GET_PROFILES_REQUEST,
  GET_PROFILES_SUCCESS,
  GET_PROFILES_ERROR,
  GET_DEVICES_REQUEST,
  GET_DEVICES_SUCCESS,
  GET_DEVICES_ERROR,
  ASSIGN_DEVICE_ERROR,
  GET_PENDING_DEVICE_REQUEST,
  GET_PENDING_DEVICE_SUCCESS,
  GET_PENDING_DEVICE_ERROR,
} from './constants';

export const initialState = fromJS({
  profiles: {
    errors: null,
    loading: false,
    count: 0,
    data: [],
  },
  devices: {
    errors: null,
    loading: false,
    count: 0,
    data: [],
  },
  pendingDevices: {
    errors: null,
    loading: false,
    count: 0,
    data: [],
  },
  currentDeploymentCount: 0,
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT:
    case SELECT_WORKSPACE_SUCCESS:
      return initialState;
    // profiles
    case GET_PROFILES_REQUEST:
      return state.merge({
        profiles: {
          errors: null,
          loading: true,
          data: state.toJS().profiles.data,
          count: state.toJS().profiles.count,
        },
      });
    case GET_PROFILES_SUCCESS:
      return state.merge({
        profiles: {
          errors: null,
          loading: false,
          data: action.payload || [],
          count: action.payload.length,
        },
      });
    case GET_PROFILES_ERROR:
      return state.set({
        profiles: {
          errors: null,
          loading: false,
          data: [],
          count: 0,
        },
      });

    // devices
    case GET_DEVICES_REQUEST:
      return state.merge({
        devices: {
          data: state.toJS().devices.data,
          count: state.toJS().devices.count,
          loading: action.meta && !action.meta.preventLoading,
          errors: null,
          dataFetched: false,
        },
      });
    case GET_PENDING_DEVICE_REQUEST:
      return state.merge({
        pendingDevices: {
          data: [],
          count: 0,
          loading:  true,
          errors: null,
          dataFetched: false,
        },
      });
    case GET_PENDING_DEVICE_SUCCESS:
      
      return state.merge({
        pendingDevices: {
          errors: null,
          loading: false,
          dataFetched: true,
          data: action.payload,
          count: action.payload.length,
        },
      });
    case GET_DEVICES_SUCCESS:
      // if (action.payload.offset === 0) {
      //   return state.merge({
      //     devices: {
      //       errors: null,
      //       loading: false,
      //       dataFetched: true,
      //       data: action.payload.data,
      //       count: action.payload.count,
      //       hasPending: action.payload.hasPending,

      //     }
      //   })
      // }
      return state.merge({
        devices: {
          errors: null,
          loading: false,
          dataFetched: true,
          data: action.payload.data,
          count: action.payload.count,
          hasPending: action.payload.hasPending,
        },
      });
    case GET_DEVICES_ERROR:
      return state.set({
        devices: {
          errors: null,
          data: [],
          dataFetched: false,
          loading: false,
          count: 0,
        },
      });
    case ASSIGN_DEVICE_ERROR:
      return state.set({
        devices: {
          errors: action.errors,
          loading: false,
          data: [],
          count: 0,
        },
      });

    default:
      return state;
  }
}

export default reducer;
