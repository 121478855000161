import axios from 'axios';
import { jsonToGraphQLQuery } from 'json-to-graphql-query';

import axiosObj from './config';

const UTILITY_API_URL = `${process.env.API_URL}/utility/graphql`;

const PROJECT_DETAIL_RETURN_VALLUES = {
  name: true,
  dependencies: true,
  description: true,
  files: true,
  git: true,
  summary: true,
  settings: true,
  data: true,
  createdAt: true,
  updatedAt: true,
  version: true,
  repository: true,
  visibility: true,
  type: true,
  modules: true,
  tags: true,
  profiles: {
    name: true,
    version: true,
  },
};

export function createProject(args) {
  const query = {
    mutation: {
      createNewProject: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: UTILITY_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function editProject(args) {
  const query = {
    mutation: {
      updateProject: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: UTILITY_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function cloneProject(args) {
  const query = {
    mutation: {
      cloneProject: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: UTILITY_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getApplications() {
  const query = {
    query: {
      projects: {
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: UTILITY_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getApplication(name) {
  const query = {
    query: {
      project: {
        __args: {
          name,
        },
        ...PROJECT_DETAIL_RETURN_VALLUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: UTILITY_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getSSHKeyList() {
  const query = {
    query: {
      keys: {
        name: true,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: UTILITY_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function installDependency(args) {
  const query = {
    mutation: {
      installDependency: {
        __args: args,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: UTILITY_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function updateDependency(args) {
  const query = {
    mutation: {
      updateProject: {
        __args: args,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: UTILITY_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export async function getToken(id, workspaceId) {
  const result = await axios.get(`http://beta.viso.ai/portal-dashboards/login?orgId=${id}&username=${workspaceId}`);

  return result;
}
