import { fromJS } from 'immutable'
import {
  UPDATE_BILLING_REQUEST,
  UPDATE_BILLING_SUCCESS,
  GET_INVOICES_ERROR,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_REQUEST,
  GET_BILLING_DETAILS_REQUEST,
  GET_BILLING_DETAILS_ERROR,
  GET_BILLING_DETAILS_SUCCESS,
  GET_SUBSCRIPTION_DETAILS_REQUEST,
  GET_SUBSCRIPTION_DETAILS_SUCCESS,
  GET_UPCOMING_INVOICES_SUCCESS,
  GET_UPCOMING_INVOICES_ERROR,
  CLEAR_UPCOMING_INVOICES_REQUEST,
  ADD_PLAN_DETAILS_SUBSCRIPTION,
  ADD_PLAN_DETAILS_SUBSCRIPTION_PENDING
} from './constants'
import {LOGOUT} from 'containers/App/constants'
import {SELECT_WORKSPACE_SUCCESS} from 'containers/Login/constants'

export const initialState = fromJS({
  billingDetails: {},
  invoices: [],
  upcomingInvoices: {},
  subscriptionDetails: {},
  loading: false,
  invoiceLoading: false,
  subscriptionLoader: false
})

function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT:
    case SELECT_WORKSPACE_SUCCESS:
      return initialState
    case GET_BILLING_DETAILS_REQUEST:
      return state.merge({
        billingDetails: {},
        loading: true
      })
    case CLEAR_UPCOMING_INVOICES_REQUEST:
      return state.merge({
        upcomingInvoices: {},
        loading: true
      })
    case GET_UPCOMING_INVOICES_SUCCESS:
      return state.merge({
        upcomingInvoices: action.payload,
        loading: false
      })
    case GET_UPCOMING_INVOICES_ERROR:
      return state.merge({
        upcomingInvoices: {},
        loading: false
      })
    case GET_INVOICES_SUCCESS:
      return state.merge({
        invoices: {
          data: action.payload,
          loading: false
        },
      })
    case GET_INVOICES_REQUEST:
      return state.merge({
        invoices: {
          data: [],
          loading: true
        },
      })
    case GET_INVOICES_ERROR:
      return state.merge({
        invoices: {
          data: [],
          loading: false
        },
      })
    case UPDATE_BILLING_REQUEST:
      return state.merge({
        loading: true
      })
    case UPDATE_BILLING_SUCCESS:
      return state.merge({
        billingDetails: {
          ...state.toJS().billingDetails,
          billingInformation: action.payload
        },
        loading: false
      })
    case GET_BILLING_DETAILS_SUCCESS:
      return state.merge({
        billingDetails: action.payload,
        loading: false
      })
    case GET_BILLING_DETAILS_ERROR:
      return state.set({
        billingDetails: {},
        loading: false
      })
    case GET_SUBSCRIPTION_DETAILS_REQUEST:
      return state.merge({
        loading: true,
        subscriptionLoader: true,
        subscriptionDetails: {}
      })
    case GET_SUBSCRIPTION_DETAILS_SUCCESS:
      return state.merge({
        subscriptionLoader: false,
        subscriptionDetails: action.payload,
        loading: false
      })
    case ADD_PLAN_DETAILS_SUBSCRIPTION_PENDING:
      return state.merge({
      })
    case ADD_PLAN_DETAILS_SUBSCRIPTION:
      return state.merge({
        subscriptionDetails: action.payload,
      })
    default:
      return state
  }
}

export default reducer
