import classnames from 'classnames';
import AHref from 'components/AHref';
import Link from 'components/Link';
import PropTypes from 'prop-types';
import React from 'react';

const ActionIcon = ({
  to, icon, onClick, text, color, type, disabled, className, id,
}) => {
  if (type === 'link') {
    return (
      <Link
        to={to}
        id={id}
        onClick={onClick}
        className={classnames('actions__option', {
          [color]: color,
          [className]: className,
          actions__option_disabled: disabled,
        })}
      >
        <span className="action-icon">
          {icon && <div className="action_icon_item">{icon}</div>}
          <div className="action-icon-text">{text}</div>
        </span>
      </Link>
    );
  }

  return (
    <AHref
      onClick={onClick}
      id={id}
      className={classnames('actions__option', {
        [color]: color,
        [className]: className,
        actions__option_disabled: disabled,
      })}
    >
      <span className="action-icon">
        {icon && <div className="action_icon_item">{icon}</div>}
        <div className="action-icon-text">{text}</div>
      </span>
    </AHref>
  );
};

ActionIcon.propTypes = {
  color: PropTypes.string,
};

ActionIcon.defaultProps = {
  color: 'accent0',
};

export default ActionIcon;
