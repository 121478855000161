import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Range, Handle } from 'rc-slider';
import classNames from 'classnames';
import { DragIcon } from 'images/common';
import { maxBy } from 'lodash';

import messages from 'utils/messages';
import { FILTER_TYPE } from '../constants';

class Search extends React.Component {
  state = {
    filterValue: [],
  }

  onApply = () => {
    const { filterValue } = this.state;
    const { name, onFilterApply } = this.props;

    onFilterApply(name, filterValue, FILTER_TYPE.SEARCH);
  }

  componentDidMount() {
    const { columns } = this.props;

    const getMaxRange = maxBy(columns, o => o.label) || { label: 0 };

    this.setState({ maxValue: getMaxRange.label, filterValue: [getMaxRange.label] });
    this.setState({ value1: 0, value2: getMaxRange.label });

    this.setState({ filterValue: [0, getMaxRange.label] });
    document.addEventListener('keydown', this.onEnter, false);
  }

  onEnter = (event) => {
    if (event.key === 'Enter') {
      // on Esc button click it should close popup
      this.onApply();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEnter, false);
  }

  onChange = (name, value) => {
    this.setState({ value1: value[0], value2: value[1] });
    this.setState({ filterValue: value });
  }

  onChangeInput1 = (e) => {
    this.setState({ value1: e.target.value });
  }

  onChangeInput2 = (e) => {
    this.setState({ value2: e.target.value });
  }

  render() {
    const {
      title, customClass, color, disabled, columns,
    } = this.props;
    const customInputClass = `range-slider text-field text-field_nolabel text-field_${customClass}`;
    const getMaxRange = maxBy(columns, o => o.label) || { label: 0 };

    const inputClass = classNames({
      'text-field__range': true,
      'text-field__input': true,
      'text-field__input_filled': true,
    });

    const handleSliderClass = classNames('slider-icon', {
      [color]: color,
      disabled,
    });

    return (
      <div className="text-field__range_container">
        <div className="filters-bar__heading">{title}</div>
        <div className="filters-bar__range">
          <input
            type="text"
            maxLength={4}
            value={this.state.value1}
            className={inputClass}
            onChange={this.onChangeInput1}
          />
          <div>-</div>
          <input
            type="text"
            maxLength={4}
            value={this.state.value2}
            className={inputClass}
            onChange={this.onChangeInput2}
          />
        </div>
        <div className={customInputClass}>
          <Range
            min={0}
            max={getMaxRange.label}
            handle={handleProps => (
              <Handle {...handleProps}>
                <div className={handleSliderClass}><DragIcon /></div>
              </Handle>
            )}
            onChange={val => this.onChange(name, val)}
            value={[this.state.value1, this.state.value2]}
            defaultValue={[this.state.value1, this.state.value2]}
            tipFormatter={value => `$ ${value}`}
          />
        </div>
        <hr className="mt_100" />
        <button type="button" className={`filters-bar__apply ${customClass}`} onClick={this.onApply}>
          <FormattedMessage {...messages.apply} />
        </button>
      </div>
    );
  }
}

Search.propTypes = {
  name: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onFilterApply: PropTypes.func,
  customClass: PropTypes.string,
};

export default Search;
