import classNames from 'classnames';
import AHref from 'components/AHref';
import Switch from 'components/Input/Switch';
import Modal from 'components/Modal';
import ProcessPage from 'components/ProcessPage';
import { loggedInUserDataRequest } from 'containers/App/actions';
import reducer from 'containers/App/reducer';
import saga from 'containers/App/saga';
import { makeSelectUser } from 'containers/App/selectors';
import { fetchPlansRequest } from 'containers/Login/actions';
import { makePlanData, makeSelectLoading } from 'containers/Login/selectors';
import userReducer from 'containers/Settings/User/reducer';
import userSaga from 'containers/Settings/User/saga';
import { makeSelectMe } from 'containers/Settings/User/selectors';
import { HelpIcon } from 'images/common';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { ChevronDown, ChevronUp } from 'react-feather';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {withRouter } from 'withRouter'
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { getMonthlyMRR, getYearlyMRR } from 'utils/common';
import { EXTERNAL_URL } from 'utils/constants';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import messages from 'utils/messages';
import Accordion from '../../components/Accordion';
import PlanInfo from './PlanInfo';


class ChoosePlan extends Component {
  state = {
    activeIndex: -1,
    endpoints: 1,
    isUpdated: false,
    isYearly: false,
    showHelp: false,
    showAllFeatures: false,
  };

  componentDidMount() {
    this.props.loggedInUserDataRequest();
    this.props.fetchPlansRequest();
    window.addEventListener('scroll', this.handleScroll, true);
  }

  // static getDerivedStateFromProps(nextProps, val) {
  //   const {selectedPlan} = nextProps;

  //   if (selectedPlan && !val.isUpdated) {
  //     const currentPlanIndex =
  //     nextProps.plans &&
  //     nextProps.plans.findIndex(plan => plan.id === selectedPlan)
  //     const nextPlan = currentPlanIndex && nextProps.plans[currentPlanIndex + 1];

  //     if (nextPlan) {
  //       const minEndpoints = val.isYearly
  //         ? nextPlan.yearlyPrice && nextPlan.yearlyPrice.minEndpoints
  //         : nextPlan.monthlyPrice && nextPlan.monthlyPrice.minEndpoints;

  //       return {
  //         endpoints: minEndpoints,
  //         isUpdated: true
  //       };
  //     }
  //   }
  //   return null;
  // }

  componentWillUnmount() {
    this.setState({ currentHeight: 0 });
    window.removeEventListener('scroll', this.handleScroll, true);
  }

  handleScroll = (event) => {
    const { scrollTop } = event.srcElement;

    if (scrollTop > 400) {
      this.setState({ activatePriceTab: true });
    } else {
      this.setState({ activatePriceTab: false });
    }
    this.setState({
      currentHeight: scrollTop,
    });
  };

  getAccent = (index) => {
    if (index % 3 === 0) return 'accent0';

    if (index % 3 === 1) {
      return 'accent1';
    }

    if (index % 3 === 2) {
      return 'accent2';
    }
  };

  onClose = () => {
    const { userData } = this.props;

    if (userData && userData.id) {
      return this.props.router.navigate(-1);
    }

    return this.props.onClose();
  };

  onUpdateIndex = (activeIndex) => {
    this.setState({ activeIndex });
  };

  onBlur = (e) => {
    // const basicPlan =
    //   this.props.plans && this.props.plans.find(plan => plan.name === 'Basic');

    // if (basicPlan) {
    //   const minEndpoints = this.state.isYearly
    //     ? basicPlan.yearlyPrice && basicPlan.yearlyPrice.minEndpoints
    //     : basicPlan.monthlyPrice && basicPlan.monthlyPrice.minEndpoints;

    //   if (e.target.value < minEndpoints) {
    //     return this.setState({ endpoints: minEndpoints });
    //   }
    // }
    if (!e.target.value) {
      return this.setState({ endpoints: 1 });
    }
  };

  onUpdateEndpoints = (e) => {
    if (e.target.value === '0') {
      return this.setState({ endpoints: 1 });
    }

    this.setState({ endpoints: e.target.value.replace(/\D/, '') || 1 });
  };

  onShowHelp = () => {
    this.setState({ showHelp: !this.state.showHelp });
  };

  renderFeatureModal = () => {
    const buttons = [
      <button
        className="modal__cancel modal__cancel_accent0"
        key="cancel"
        onClick={this.onCloseModal}
      >
        <FormattedMessage {...messages.close} />
      </button>,
    ];

    return (
      <Modal
        buttons={buttons}
        title={
          this.state.featureName && (
            <FormattedMessage {...messages[this.state.featureName]} />
          )
        }
        body={(
          <p className="feature-description">
            <div dangerouslySetInnerHTML={{ __html: this.state.description }} />
          </p>
        )}
        // secondaryBody={
        //   <div className="feature-image">
        //     <img src={FeatureBox} />
        //   </div>
        // }
        onClose={this.onCloseModal}
      />
    );
  };

  onShowFeature = (description, featureName) => {
    if (description) {
      this.setState({ showFeature: true, description, featureName });
    }
  };

  onCloseModal = () => {
    this.setState({ showFeature: false });
  };

  onPriceSelector = (name, value) => {
    this.setState({ [name]: value });
  };

  onUpdateFeatureView = () => {
    this.setState({ showAllFeatures: !this.state.showAllFeatures });
  };

  scrollTopWithAnimation = (scrollbars, e) => {
    e.preventDefault();
    e.stopPropagation();
    scrollbars.scroll({
      top: 0,
      behavior: 'smooth',
    });
  };

  getSelectedPlan = (endpoints, plans) => {
    let plan = plans.find(planItem => planItem.features.deviceLimit >= endpoints
      && endpoints <= 100
      && plans[0].id !== planItem.id);

    plan = (plans.find(planItem => planItem.name === 'Professional' && endpoints > 100 && endpoints <= 500)) || plan;

    plan = plans.find(planItem => planItem.name === 'Enterprise' && endpoints > 500) || plan;

    return plan;
  }

  render() {
    const { activeIndex, endpoints, showHelp } = this.state;
    const {
      onSelectPlan, isLoading, selectedPlan, plans,
    } = this.props;
    let saveCount = '';

    let plan = plans.find(planItem => planItem.features.deviceLimit >= endpoints
    && plans[0].id !== planItem.id);

    if (plan && plan[0]) {
      plan = plans[0];
    }

    // if (selectedPlan) {
    //   plan = plans && plans.find(planItem => planItem.id === selectedPlan);
    // }

    if (plan) {
      const totalYearlyPrice = plan.yearlyPrice
        && parseFloat(getYearlyMRR(endpoints, plan) / 12, 10).toFixed(2);

      const totalMonthlyPrice = plan.monthlyPrice
        && parseFloat(getMonthlyMRR(endpoints, plan), 10).toFixed(2);

      saveCount = 100 - parseInt((totalYearlyPrice / totalMonthlyPrice) * 100, 10) || 0;
    }


    return (
      <ProcessPage onCancel={this.onClose} secondaryPage scrollClass="popup-scroll-wrap2">
        {scrollRef => (
          <>
            {this.state.showFeature && this.renderFeatureModal()}
            <div className="headline">
              <h1 className="headline__title center">
                <FormattedMessage {...messages.choosePlan} />
              </h1>
              <h3 className="headline__subtitle text-color">
                {selectedPlan
                  ? <FormattedMessage {...messages.changePlanWs} />
                  : <FormattedMessage {...messages.freeToLearn} />
                }
              </h3>
            </div>
            <h3 className="plan__info_play">
              <div>
                <span className="heading3-active">
                  <FormattedMessage {...messages.payAsYouGo} />
                  :
                </span>
                {' '}
                <FormattedMessage {...messages.howManyDevices} />
                <span className="plan__subtitle">
                  <input
                    type="number"
                    className="plan-input"
                    onBlur={this.onBlur}
                    value={endpoints || 1}
                    onChange={this.onUpdateEndpoints}
                  />
                  <div
                    className={classNames('plan-help', { accent0: showHelp })}
                    onMouseEnter={this.onShowHelp}
                    onMouseLeave={() => this.setState({ showHelp: false })}
                  >
                    <HelpIcon />
                  </div>
                  <p className={classNames('custom-tooltip plan-tooltip', {
                    'show-plan-tooltip': showHelp,
                  })}
                  >
                    <FormattedMessage
                      {...messages.visoSuiteDeviceEndpoint}
                    />
                  </p>
                </span>
              </div>
              <div className="plan__switcher">
                <span className="heading3-active mx-r-6">
                  <FormattedMessage {...messages.billingCycle} />
                  :
                  {' '}
                </span>
                {' '}
                <h4
                  onClick={() => this.onPriceSelector('isYearly', false)}
                  className={classNames('plan__interval_title cursor', {
                    accent0: !this.state.isYearly,
                  })}
                >
                  <FormattedMessage {...messages.sMonthly} />
                </h4>
                &nbsp;&nbsp;
                <Switch
                  noLabel
                  toogleWithLabel
                  name="isYearly"
                  isAlwaysTrue
                  value={this.state.isYearly}
                  onChange={this.onPriceSelector}
                />
                &nbsp;&nbsp;
                <h4
                  onClick={() => this.onPriceSelector('isYearly', true)}
                  className={classNames('plan__interval_title cursor', {
                    accent0: this.state.isYearly,
                  })}
                >
                  <FormattedMessage {...messages.annually} />
                </h4>
                &nbsp;&nbsp;
                {!!saveCount && (
                  <div
                    className={classNames('button_info badge_tag_accent0', {})}
                  >
                    save
                    {' '}
                    {saveCount}
                    %
                  </div>
                )}
              </div>
            </h3>

            <div>
              <Scrollbars
                autoHeight
                universal
                className="plan-scrollbars"
                autoHide
                autoHideDuration={1}
                autoHeightMax={3612}
                renderTrackVertical={props => <div {...props} className="track-vertical" style={{ display: 'none' }} />}
                renderThumbVertical={props => <div {...props} className="thumb-vertical" style={{ display: 'none' }} />}
              >
                <div className="plan-info">
                  <PlanInfo
                    setActivePlan={this.setActivePlan}
                    activatePriceTab={this.state.activatePriceTab}
                    selectedPlan={selectedPlan}
                    intervalType={this.state.isYearly ? 'year' : 'month'}
                    showFeature={this.onShowFeature}
                    onSelectPlan={onSelectPlan}
                    plans={this.props.plans}
                    showAllFeatures={this.state.showAllFeatures}
                    isLoading={isLoading}
                    onUpdateIndex={this.onUpdateIndex}
                    activeIndex={activeIndex}
                    endpoints={endpoints}
                  />
                </div>
              </Scrollbars>
            </div>
            <h3 className="plan-view-all">
              {!this.state.showAllFeatures && (
                <div onClick={this.onUpdateFeatureView}>
                  View all Features
                  <ChevronDown size="16" />
                </div>
              )}
              {this.state.showAllFeatures && (
                <div onClick={this.onUpdateFeatureView}>
                  Collapse Features
                  <ChevronUp size="16" />
                </div>
              )}
            </h3>
            <div className="plan-price-wrapper">
              <h2 className="plan-price-title">Pricing FAQ</h2>
              <Accordion
                title="What will my Viso Workspace subscription cost?"
                description={(
                  <p>
                    Pricing is simple and cost-effective. You pay per enrolled
                    device. Use the
                    {' '}
                    <AHref onClick={e => this.scrollTopWithAnimation(scrollRef, e)}>pricing calculator</AHref>
                    {' '}
                    to determine your exact
                    price.
                    <div className="plan-price-title-spacing" />
                    Viso Suite provides tiered discounts on devices. This
                    enables you to decrease your costs based on the number of
                    devices enrolled to your workspace. Hence, you save on costs
                    as your usage scales.
                  </p>
                )}
              />
              <Accordion
                title="What is a device in Viso Suite?"
                description={(
                  <div>
                    A Viso Suite device is any physical or virtual device that
                    is enrolled to your workspace and exists in Device
                    Management.
                    <div className="plan-price-title-spacing" />
                    In Viso Suite, you can remotely access or monitor the device
                    and deploy applications to run AI inference on the edge.
                  </div>
                )}
              />
              <Accordion
                title="How do I add more devices to my workspace?"
                description="To add devices to your workspace, you can simply enroll them to your account, and we will automatically bill you for additional devices."
              />
              <Accordion
                title="What payment methods do you offer?"
                description="Viso accepts international payments from major credit cards, including Visa, Mastercard, and American Express. If you make a credit card your primary payment method, we'll charge your card for your automatic payments."
              />
              <Accordion
                title="How can I switch to annual billing?"
                description={(
                  <div>
                    In your workspace’s Billing Center, you can always switch to
                    annual billing. Annual subscriptions offer a discount
                    depending on the number of devices purchased.
                    <div className="plan-price-title-spacing" />
                    Use our
                    {' '}
                    <AHref onClick={e => this.scrollTopWithAnimation(scrollRef, e)}>pricing calculator</AHref>
                    {' '}
                    {' '}
                    to
                    determine your exact price.
                  </div>
                )}
              />
              <Accordion
                title="How do  I cancel my Viso Suite subscription or trial?"
                description="If you're not satisfied with any Viso Suite product or service, you can cancel your plan before the next renewal cycle. When your subscription expires, you will lose access to Viso Products."
              />
              <Accordion
                title="What happens if I exceed a plan’s limit?"
                description={(
                  <div>
                    When you exceed a plan’s limit, you can seamlessly upgrade
                    your plan. If you need higher limits, please
                    <AHref
                      href={EXTERNAL_URL.contact}
                      className="text-link"
                      rel="noreferrer"
                      target="_blank"
                    >
                      {' '}
                      contact us
                      {' '}
                    </AHref>
                    .
                  </div>
                )}
              />
              <div className="plan-footer">Other questions?</div>
              <div className="plan-footer-desc">
                If you have more questions on our pricing and plans
                <AHref
                  href={EXTERNAL_URL.contact}
                  className="text-link"
                  rel="noreferrer"
                  target="_blank"
                >
                  {' '}
                  contact us
                  {' '}
                </AHref>
                so we can help.
              </div>
            </div>
          </>
        )}
      </ProcessPage>
    );
  }
}

ChoosePlan.propTypes = {
  loginRequest: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  plans: makePlanData(),
  isLoading: makeSelectLoading(),
  me: makeSelectMe(),
  userData: makeSelectUser(),
});

const withUserSaga = injectSaga({ key: 'user', saga: userSaga });
const withUserReducer = injectReducer({ key: 'user', reducer: userReducer });

const withReducer = injectReducer({ key: 'global', reducer });
const withSaga = injectSaga({ key: 'global', saga });

const withConnect = connect(
  mapStateToProps,
  { fetchPlansRequest, loggedInUserDataRequest },
);

export default compose(
  withRouter,
  withUserSaga,
  withReducer,
  withSaga,
  withUserReducer,
  withConnect,
)(ChoosePlan);
