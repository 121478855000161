export const FETCH_NOTIFICATIONS_REQUEST = 'TK_PORTAL/FETCH_NOTIFICATIONS_REQUEST';
export const FETCH_NOTIFICATIONS_SUCCESS = 'TK_PORTAL/APP/FETCH_NOTIFICATIONS_SUCCESS';

export const MARK_AS_READ_REQUEST = 'TK_PORTAL/MARK_AS_READ_REQUEST';
export const MARK_AS_READ_SUCCESS = 'TK_PORTAL/APP/MARK_AS_READ_SUCCESS';

export const MARK_AS_ALL_READ_REQUEST = 'TK_PORTAL/MARK_AS_ALL_READ_REQUEST';
export const MARK_AS_ALL_READ_SUCCESS = 'TK_PORTAL/APP/MARK_AS_ALL_READ_SUCCESS';

export const MARK_AS_UNREAD_REQUEST = 'TK_PORTAL/MARK_AS_UNREAD_REQUEST';
export const MARK_AS_UNREAD_SUCCESS = 'TK_PORTAL/APP/MARK_AS_UNREAD_SUCCESS';

export const MARK_AS_ALL_UNREAD_REQUEST = 'TK_PORTAL/MARK_AS_ALL_UNREAD_REQUEST';
export const MARK_AS_ALL_UNREAD_SUCCESS = 'TK_PORTAL/APP/MARK_AS_ALL_UNREAD_SUCCESS';

export const FETCH_NOTIFICATION_SETTINGS_REQUEST = 'TK_PORTAL/FETCH_NOTIFICATION_SETTINGS_REQUEST';
export const FETCH_NOTIFICATION_SETTINGS_SUCCESS = 'TK_PORTAL/APP/FETCH_NOTIFICATION_SETTINGS_SUCCESS';

export const UPDATE_NOTIFICATION_SETTINGS_REQUEST = 'TK_PORTAL/UPDATE_NOTIFICATION_SETTINGS_REQUEST';
export const UPDATE_NOTIFICATION_SETTINGS_SUCCESS = 'TK_PORTAL/APP/UPDATE_NOTIFICATION_SETTINGS_SUCCESS';

export const NOTIFICATION_VIEW = 'notification';
export const SETTING_VIEW = 'setting';
