import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  INVITATION_SIGNUP_REQUEST,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  INVITE_USER_ERROR,
  INVITE_USER_REQUEST,
  INVITE_USER_SUCCESS,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  SELECT_WORKSPACE_REQUEST,
  SELECT_WORKSPACE_SUCCESS,
  INVITE_USER_RESET_PASSWORD_ERROR,
  INVITE_USER_RESET_PASSWORD_REQUEST,
  INVITE_USER_RESET_PASSWORD_SUCCESS,
  REGISTER_ERROR,
  GET_PLANS_PENDING,
  GET_PLANS_SUCCESS,
  GET_PLANS_REJECTED,
  GET_HOMEPAGE_REQUEST,
  GET_HOMEPAGE_SUCCESS,
  GET_HOMEPAGE_ERROR,
  GET_PLAN_INFO_ERROR,
  GET_PLAN_INFO_REQUEST,
  GET_PLAN_INFO_SUCCESS
} from './constants';

export function fetchPlansRequest(params) {
  return {
    type: GET_PLANS_PENDING,
    payload: params
  }
}

export function fetchPlanSuccess(payload) {
  return {
    type: GET_PLANS_SUCCESS,
    payload
  }
}

export function fetchPlanReject() {
  return {
    type: GET_PLANS_REJECTED,
  }
}

export function resetPasswordRequest(params, onCallback) {
  return {
    type: RESET_PASSWORD_REQUEST,
    payload: params,
    meta: {onCallback}
  }
}

export function resetPasswordSuccess(payload) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload
  }
}

export function resetPasswordError(errors) {
  return {
    type: RESET_PASSWORD_ERROR,
    errors
  }
}

export function inviteUserRequest(params) {
  return {
    type: INVITE_USER_REQUEST,
    payload: params
  }
}

export function inviteUserSuccess(payload) {
  return {
    type: INVITE_USER_SUCCESS,
    payload
  }
}

export function inviteUserError(errors) {
  return {
    type: INVITE_USER_ERROR,
    errors
  }
}

export function inviteUserResetPasswordRequest(params) {
  return {
    type: INVITE_USER_RESET_PASSWORD_REQUEST,
    payload: params
  }
}

export function inviteUserResetPasswordSuccess(payload) {
  return {
    type: INVITE_USER_RESET_PASSWORD_SUCCESS,
    payload
  }
}

export function inviteUserResetPasswordError(errors) {
  return {
    type: INVITE_USER_RESET_PASSWORD_ERROR,
    errors
  }
}

export function loginRequest(params, onCallback, onCallbackSingleWs, validateModal) {
  return {
    type: LOGIN_REQUEST,
    payload: params,
    meta: { onCallback, onCallbackSingleWs, validateModal },
  };
}

export function selectWorkspaceRequest(params, action) {

  return {
    type: SELECT_WORKSPACE_REQUEST,
    payload: params,
    meta: {
      stayLoggedIn: action && action.stayLoggedIn,
      redirectTo: action && action.redirectTo,
      onCallback: action && action.onCallback,
      push: action && action.push
    }
  }
}

export function selectWorkspaceSuccess(params) {
  return {
    type: SELECT_WORKSPACE_SUCCESS,
    payload: params
  }
}

export function loginSuccess(payload) {
  return {
    type: LOGIN_SUCCESS,
    payload
  }
}

export function loginError(errors) {
  return {
    type: LOGIN_ERROR,
    errors
  }
}

export function registerRequest(userArgs, onCallback) {
  return {
    type: REGISTER_REQUEST,
    payload: {userArgs, onCallback}
  }
}

export function registerSuccess() {
  return {
    type: REGISTER_SUCCESS
  }
}

export function registerError() {
  return {
    type: REGISTER_ERROR
  }
}

export function invitationSignupRequest(userArgs, workspace, meta) {
  return {
    type: INVITATION_SIGNUP_REQUEST,
    payload: {userArgs, workspace},
    meta
  }
}

export function invitationSignupSuccess() {
  return {
    type: REGISTER_SUCCESS
  }
}

export function forgotPasswordRequest(params) {
  return {
    type: FORGOT_PASSWORD_REQUEST,
    payload: params
  }
}

export function forgotPasswordSuccess(payload) {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload
  }
}

export function forgotPasswordError(errors) {
  return {
    type: FORGOT_PASSWORD_ERROR,
    errors
  }
}


export function getHomepageData(payload, meta) {
  return {
    type: GET_HOMEPAGE_REQUEST,
    payload,
    meta,
  };
}

export function getHomepageDataSuccess(payload) {
  return {
    type: GET_HOMEPAGE_SUCCESS,
    payload,
  };
}

export function getHomepageDataError(errors) {
  return {
    type: GET_HOMEPAGE_ERROR,
    errors,
  };
}

export function getPlanInfoData(payload, meta) {
  return {
    type: GET_PLAN_INFO_REQUEST,
    payload,
    meta,
  };
}

export function getPlanInfoDataSuccess(payload) {
  return {
    type: GET_PLAN_INFO_SUCCESS,
    payload,
  };
}

export function getPlanInfoDataError(errors) {
  return {
    type: GET_PLAN_INFO_ERROR,
    errors,
  };
}
