import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import ProcessPage from 'components/ProcessPage';
import Button from 'components/Button';
import { twoFactorRecoveryKeys } from 'api/auth';
import { defaultNotifier } from 'functions/notificationHandler';
import { ImportIcon } from 'images/common';

import appMessages from 'utils/messages';

class RecoveryKey extends Component {
  state = {
    recoveryKeys: [],
    disabled: false,
  }

  async componentDidMount() {
    const { data } = await twoFactorRecoveryKeys();
    this.setState({ disabled: this.props.disabled });
    if (data.errors) {
      return defaultNotifier(data.errors);
    }
    this.setState({ recoveryKeys: data.data.twoFactorRecoveryKeys });
  }

  download = () => {
    const { downloadInfo } = this.props;

    const text = this.state.recoveryKeys
    && this.state.recoveryKeys.length
    && this.state.recoveryKeys.reduce((sum, item) => `${sum}\n\n${item}`);

    if (text) {
      const element = document.createElement('a');
      element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
      element.setAttribute('download', `${downloadInfo}-recovery.txt`);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
      this.setState({ disabled: false });
    }
  }

  onDownload = () => {
    if (this.state.disabled) {
      this.props.onClose();
      return this.download();
    }

    this.props.onClose();
  }

  render() {
    return (
      <ProcessPage>
        {() => (
          <>
            <div className="headline">
              <h1 className="headline__title">Recovery Keys</h1>
              <h4 className="headline__subtitle">
                The recovery keys are used to access your account in the event you cannot
                receive two-factor authentication codes.
                <br />
                Make sure to store them safely and treat them with a
                high level of attention as you would your password.
              </h4>
            </div>

            <div className={classNames('content-box content-box-recovery-box', this.props.className)}>
              <Button
                className={classNames('overview__edit', `overview__edit_${this.props.color}`, { [this.props.color]: this.props.color })}
                onClick={this.download}
              >
                <ImportIcon />
                {' '}
                Download
              </Button>
              <div className="recovery-title">
                Your Recovery Key
              </div>
              {this.state.recoveryKeys.map(item => (
                <div className="content-recovery-label">
                  <span className="text-color">{item}</span>
                </div>
              ))}
            </div>

            <div className="login__actions">
              <Button
                onClick={this.onDownload}
                className="button"
              >
                {this.state.disabled
                  ? <FormattedMessage {...appMessages.downloadAndContinue} />
                  : <FormattedMessage {...appMessages.continue} />
                }
              </Button>
            </div>
          </>
        )}
      </ProcessPage>
    );
  }
}

RecoveryKey.propTypes = {
  color: PropTypes.string,
  image: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RecoveryKey;
