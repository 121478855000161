import {
  takeLatest,
  call,
  put,
  all,
} from 'redux-saga/effects'
import {sortBy} from 'lodash'
import {
  invoices,
  getBillingDetail,
  changeSubscription,
  upcomingInvoice,
  getSubscriptionDetails,
  updateBillingInformation,
  getSubscriptionSolution
} from 'api/billing'
import {
  GET_BILLING_DETAILS_REQUEST,
  GET_INVOICES_REQUEST,
  UPDATE_BILLING_REQUEST,
  GET_SUBSCRIPTION_DETAILS_REQUEST,
  CHANGE_SUBSCRIPTION_REQUEST,
  GET_UPCOMING_INVOICES_REQUEST,
  ADD_PLAN_DETAILS_SUBSCRIPTION_PENDING
} from './constants'
import {
  getBillingDetailsError,
  getSubscriptionDetailsError,
  getSubscriptionDetailsSuccess,
  addPlanDetailInSubscriptionSuccess,
  updateBillingSuccess,
  getBillingDetailsSuccess,
  getUpcomingInvoicesSuccess,
  getUpcomingInvoicesError,
  getInvoicesError,
  getInvoicesSuccess,
} from './actions'
import {
  defaultNotifier
} from 'functions/notificationHandler'
import {routeMapping} from 'routes'

export const getSubscriptionDetailsState = (state) => state.subscriptionDetails


function* getBillingInfoSaga() {
  try {
    const response = yield call(getBillingDetail)

    if (response.errors) {
      yield put(getBillingDetailsError(response.data.errors))
      defaultNotifier(response.errors)
    } else {
      yield put(getBillingDetailsSuccess(response.data.data.billingDetail))
    }
  } catch (err) {
    defaultNotifier(err)
  }
}

function* getPlanForSubscription(action) {
  try {
    const response = yield call(getSubscriptionSolution, {id: action.payload.id})

    if (response.errors) {
      return false
    }

    const subscriptions =  action.payload.subscriptionDetails
      .planSubscription.subscriptions.map(item => {
        if (item.id === action.payload.subscriptionId) {
          return {
            ...item,
            planDetails: response.data.data.solution
          }
        }
        return item
      })

    const payload = {
      upcomingInvoices: action.payload.subscriptionDetails.upcomingInvoices,
      planSubscription: {
        ...action.payload.subscriptionDetails.planSubscription,
        subscriptions: subscriptions
      },
    }

    yield put(addPlanDetailInSubscriptionSuccess(payload))

  } catch (err) {
    defaultNotifier(err)
  }
}


function* getInvoicesListSaga(action) {
  try {
    const response = yield call(invoices, action.payload)

    if (response.errors) {
      yield put(getInvoicesError(response.data.errors))
      defaultNotifier(response.errors)
    } else {
      const list = sortBy(response.data.data.invoices.map(item => ({...item, invoiceNo: parseInt(item.invoiceNumber)})), [
        'invoiceNo',
      ]).reverse();
    
      
      yield put(getInvoicesSuccess(list))
    }
  } catch (err) {
    defaultNotifier(err)
  }
}

function* getUpcomingInvoicesSaga() {
  try {
    const response = yield call(upcomingInvoice)

    if (response.errors) {
      defaultNotifier(response.errors)
      yield put(getUpcomingInvoicesError(response.errors))
    } else {
      yield put(getUpcomingInvoicesSuccess(response.data.data.upcomingInvoice))
    }
  } catch (err) {
    defaultNotifier(err)
  }
}

function* getSubscriptionDetailsSaga() {
  try {
    const response = yield call(getSubscriptionDetails);


    if (response.data.errors) {
      yield put(getSubscriptionDetailsError(response.data.errors))
      defaultNotifier(response.errors)
    } else {

      const response2 = yield call(upcomingInvoice)

      // const response3 = yield all(
      //   response.data.data.subscriptions.subscriptions.map(action => {
      //     if (action.productCategory === 'ADD_ON') {
      //       return call(getSubscriptionSolution, {id: action.subscriptionId})
      //     }
      //     return null
      //   }))

      // const subscriptions = response.data.data.subscriptions.subscriptions.map((item, index) => {
      //   return {
      //     ...item,
      //     planDetails: response3[index] && response3[index].data.data.solution
      //   }
      // })

      yield put(getSubscriptionDetailsSuccess({
        planSubscription: {
          ...response.data.data.subscriptions,
        },
        upcomingInvoices: response2.data.data.upcomingInvoice
      }))
    }
  } catch (err) {
    defaultNotifier(err)
  }
}

function* updateBillingSaga(action) {
  try {
    const response = yield call(updateBillingInformation, action.payload)

    if (response.errors) {
      yield put(getBillingDetailsError(response.data.errors))
      defaultNotifier(response.errors)
    } else {
      defaultNotifier('Billing information updated')
      yield put(updateBillingSuccess(response.data.data.updateBillingInformation))

    }

  } catch (err) {
    defaultNotifier(err)
  }
}

function* changeSubscriptionSaga(action) {
  try {
    const {data} = yield call(changeSubscription, action.payload)

    if (data.errors) {
      return defaultNotifier(data.errors)
    }

    return action.meta(routeMapping.BILLING.path);
  } catch (err) {
    defaultNotifier(err)
  }
}

export default function* libraryRootSaga() {
  yield takeLatest(GET_UPCOMING_INVOICES_REQUEST, getUpcomingInvoicesSaga)
  yield takeLatest(ADD_PLAN_DETAILS_SUBSCRIPTION_PENDING, getPlanForSubscription)
  yield takeLatest(GET_BILLING_DETAILS_REQUEST, getBillingInfoSaga)
  yield takeLatest(UPDATE_BILLING_REQUEST, updateBillingSaga)
  yield takeLatest(GET_SUBSCRIPTION_DETAILS_REQUEST, getSubscriptionDetailsSaga)
  yield takeLatest(CHANGE_SUBSCRIPTION_REQUEST, changeSubscriptionSaga)
  yield takeLatest(GET_INVOICES_REQUEST, getInvoicesListSaga)
}
