import { jsonToGraphQLQuery } from 'json-to-graphql-query';

import axiosObj from './config';

export const MARKETPLACE_API_URL = `${process.env.API_URL}/marketplace/graphql`;
export const SOLUTION_API_URL = `${process.env.API_URL}/solution/graphql`;

const MARKET_RETURN_VALUES = {
  id: true,
  name: true,
  description: true,
  slug: true,
  // workspace: {
  //   name: true,
  //   subDomain: true,
  //   logo: true
  // },
  parents: {
    id: true,
    name: true
  }
};

const SOLUTION_RETURN_VALUES = {
  id: true,
  name: true,
  type: true,
  logo: true,
  tagLine: true,
  marketplaceCategories: {
    id: true,
    name: true,
    description: true,
    slug: true
  },
  images: true,
  description: true,
  keywords: true,
  userGuide: true,
  privacyPolicy: true,
  email: true,
  phone: true,
  website: true,
  createdAt: true,
  license: true,
  updatedAt: true,
  monthlyPrice: true,
  yearlyPrice: true,
  workspace: {
    name: true,
    logo: true,
    subDomain: true
  },
  installedVersion: true,
  installedId: true,
  installStatus: true,
  activeVersion: true,
  purchased: true,
  requiredModules: {
    name: true,
    moduleID: true,
    version: true,
    type: true,
    logo: true,
    link: true
  },
  videos: {
    id: true,
    name: true,
    size: true
  },
  dashboards: {
    uid: true,
    title: true
  }
};

const SOLUTION_DETAILS_RETURN_VALUES = {
  id: true,
  name: true,
  type: true,
  logo: true,
  tagLine: true,
  images: true,
  createdAt: true,
  updatedAt: true,
  workspace: {
    name: true,
    logo: true,
    subDomain: true
  },
};

const GET_SOLUTION_BY_CATEGORIES_RETURN = {
  category: {
    id: true,
    name: true,
    description: true,
    slug: true,
    parents: {
      id: true,
      name: true,
      description: true,
      slug: true
    }
  },
  solutions: {
    id: true,
    name: true,
    type: true,
    logo: true,
    tagLine: true,
    createdAt: true,
    updatedAt: true,
    workspace: {
      name: true,
      subDomain: true,
      logo: true
    }
  }
};

export function getMarketCategory() {
  const query = {
    query: {
      marketplaceCategories: {
        ...MARKET_RETURN_VALUES
      }
    }
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: MARKETPLACE_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  });
}

export function getSolutionsByCategories() {
  const query = {
    query: {
      solutionsByCategories: {
        ...GET_SOLUTION_BY_CATEGORIES_RETURN
      }
    }
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: MARKETPLACE_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  });
}

export function getMarketplaceSolutions(arg) {
  const query = {
    query: {
      solutions: {
        __args: arg,
        count: true,
        data: {
          id: true,
          name: true,
          type: true,
          logo: true,
          tagLine: true,
          createdAt: true,
          updatedAt: true,
          workspace: {
            name: true,
            logo: true,
            subDomain: true
          },
        }
      }
    }
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: MARKETPLACE_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  });
}

export function getMarketplaceSolution(arg) {
  const query = {
    query: {
      solution: {
        __args: arg,
        ...SOLUTION_RETURN_VALUES
      }
    }
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: MARKETPLACE_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  });
}

export function purchaseSolution(arg) {
  const query = {
    mutation: {
      purchaseSolution: {
        __args: arg
      }
    }
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: MARKETPLACE_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  });
}


export function upgradeMarketplaceModule(arg) {
  const query = {
    mutation: {
      upgradeMarketplaceModule: {
        __args: arg
      }
    }
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  });
}


export function upgradeMarketplaceApplication(arg) {
  const query = {
    mutation: {
      upgradeMarketplaceApplication: {
        __args: arg
      }
    }
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: MARKETPLACE_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  });
}

export function preInstallableSolutions(arg) {
  const query = {
    query: {
      preInstallableSolutions: {
        __args: arg,
        ...SOLUTION_DETAILS_RETURN_VALUES
      }
    }
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: MARKETPLACE_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  });
}