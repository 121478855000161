import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withRouter } from 'withRouter'
import { FormattedMessage } from 'react-intl';
import { Users } from 'react-feather';

import appMessages from 'utils/messages';
import Table from 'components/Table';
import { ProfileIcon } from 'images/common';
import UserRow from './Row';


const columnList = [{
  label: <FormattedMessage {...appMessages.userName} />,
  sortKey: 'firstName',
  isSortable: true,
  isLocked: true,
}, {
  label: <FormattedMessage {...appMessages.email} />,
  sortKey: 'email',
  isSortable: true,
}, {
  label: <FormattedMessage {...appMessages.userRole} />,
  sortKey: 'role',
  isSortable: true,
}, {
  label: <FormattedMessage {...appMessages.status2FA} />,
  sortKey: 'twoFactorEnabled',
  isSortable: true,
}, {
  label: <FormattedMessage {...appMessages.status} />,
  sortKey: 'status',
  isSortable: true,
}, {
  label: <FormattedMessage {...appMessages.modified} />,
  sortKey: 'updatedAt',
  isSortable: true,
}, {
  label: <FormattedMessage {...appMessages.lastSignIn} />,
  sortKey: 'lastLoginAt',
  isSortable: true,
}, {
  label: <FormattedMessage {...appMessages.tags} />,
  sortKey: 'tags',
  isSortable: true,
  isOptional: true,
},

];

class UsersTable extends Component {
  render() {
    const { color, filteredColumns } = this.props;

    const initialColumns = columnList.map((column) => {
      if (filteredColumns && filteredColumns.includes(column.sortKey)) {
        return { ...column, isVisible: true, isOptional: false };
      }

      return column;
    });

    return (
      <Table
        allowOnlySingleSelect={this.props.allowOnlySingleSelect}
        inputType={this.props.inputType}
        columns={initialColumns}
        RowComponent={UserRow}
        notFoundIcon={<Users size={42} />}
        rows={this.props.users || []}
        isUserSetting
        CheckboxImage={ProfileIcon}
        isSelectable
        multipleSelect
        isLoading={this.props.isLoading}
        workspace={this.props.workspace}
        loggedUserId={this.props.loggedUserId}
        colorClassName={color}
        hideSingleMenu={this.props.hideSingleMenu}
        withColumnSelect
        onDelete={this.props.onDelete}
        isAssignedPage={this.props.isAssignedPage}
        preventRedirectOnClick={this.props.preventRedirectOnClick}
        onAssignRole={this.props.onAssignRole}
        disabledIds={this.props.disabledIds}
        onUpdate={this.props.onUpdate}
        onArchive={this.props.onArchive}
        onDeactivate={this.props.onDeactivate}
        onActivate={this.props.onActivate}
        selectedItems={this.props.selectedItems}
        onSelect={this.props.onSelect}
        currentSortKey={this.props.sort && this.props.sort.key}
        currentSortDir={this.props.sort && this.props.sort.direction}
        onUpdateSort={this.props.onSortChange}
        rowClick={this.props.onRowClick}
      />
    );
  }
}

UsersTable.propTypes = {
  users: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedItems: PropTypes.array,
  onSortChange: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(UsersTable);
