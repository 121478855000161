import {
  LIST_USER_GROUP_REQUEST,
  LIST_USER_GROUP_SUCCESS,
  LIST_USER_GROUP_ERROR,
  ADD_USER_GROUP_REQUEST,
  ADD_USER_GROUP_SUCCESS,
  ADD_USER_GROUP_ERROR,
  UPDATE_USER_GROUP_REQUEST,
  UPDATE_USER_GROUP_SUCCESS,
  UPDATE_USER_GROUP_ERROR,
  REMOVE_USER_GROUP_REQUEST,
  REMOVE_USER_GROUP_SUCCESS,
  REMOVE_USER_GROUP_ERROR,
  USER_GROUP_ERROR,
  USER_GROUP_SUCCESS,
  USER_GROUP_REQUEST,
} from './constants';

export function userGroupListRequest(payload) {
  return {
    type: LIST_USER_GROUP_REQUEST,
    payload,
  };
}

export function userGroupListSuccess(payload) {
  return {
    type: LIST_USER_GROUP_SUCCESS,
    payload,
  };
}

export function userGroupListError(errors) {
  return {
    type: LIST_USER_GROUP_ERROR,
    errors,
  };
}

export function getUserGroupRequest(payload) {
  return {
    type: USER_GROUP_REQUEST,
    payload,
  };
}

export function getUserGroupSuccess(payload) {
  return {
    type: USER_GROUP_SUCCESS,
    payload,
  };
}

export function getUserGroupError(errors) {
  return {
    type: USER_GROUP_ERROR,
    errors,
  };
}

export function userGroupAddRequest(payload) {
  return {
    type: ADD_USER_GROUP_REQUEST,
    payload,
  };
}

export function userGroupAddSuccess(payload) {
  return {
    type: ADD_USER_GROUP_SUCCESS,
    payload,
  };
}

export function userGroupAddError(errors) {
  return {
    type: ADD_USER_GROUP_ERROR,
    errors,
  };
}

export function userGroupUpdateRequest(payload) {
  return {
    type: UPDATE_USER_GROUP_REQUEST,
    payload,
  };
}

export function userGroupUpdateSuccess(payload) {
  return {
    type: UPDATE_USER_GROUP_SUCCESS,
    payload,
  };
}

export function userGroupUpdateError(errors) {
  return {
    type: UPDATE_USER_GROUP_ERROR,
    errors,
  };
}

export function userGroupRemoveRequest(payload) {
  return {
    type: REMOVE_USER_GROUP_REQUEST,
    payload,
  };
}

export function userGroupRemoveSuccess(payload) {
  return {
    type: REMOVE_USER_GROUP_SUCCESS,
    payload,
  };
}

export function userGroupRemoveError(errors) {
  return {
    type: REMOVE_USER_GROUP_ERROR,
    errors,
  };
}
