import {
  FETCH_WORKSPACE_HISTORY_REQUEST,
  FETCH_WORKSPACE_HISTORY_SUCCESS,
} from './constants';

export function fetchWorkspaceHistoryRequest(payload) {
  return {
    type: FETCH_WORKSPACE_HISTORY_REQUEST,
    payload,
  };
}

export function fetchWorkspaceHistorySuccess(payload) {
  return {
    type: FETCH_WORKSPACE_HISTORY_SUCCESS,
    payload,
  };
}
