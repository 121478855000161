import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import classNames from 'classnames';

import DetailScroll from 'components/Detail/Scroll';
import { getSidebarStatus } from 'containers/App/selectors';

const LoadingIndicator = ({
  sidebarStatus, color = 'accent0', customClass, isProcessPage, title, description
}) => {
  const mainClass = classNames('loading_icon', {
    loader_expand: sidebarStatus,
    [color]: color,
    [customClass]: customClass,
    'full-page': isProcessPage,
  });

  return (
    <DetailScroll isProcessPage hideBanner>
      <div className={mainClass}>
        <div className="boxes">
          <div className="box">
            <div />
            <div />
            <div />
            <div />
          </div>
          <div className="box">
            <div />
            <div />
            <div />
            <div />
          </div>
          <div className="box">
            <div />
            <div />
            <div />
            <div />
          </div>
          <div className="box">
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      </div>

      <div className={mainClass}>
        {title && <div className='loading-container'>
          <div className='loading-title'>{title}</div>
          <div className='loading-desc'>{description}</div>
        </div>
        }
      </div>

    </DetailScroll>
  );
};


const mapStateToProps = createStructuredSelector({
  sidebarStatus: getSidebarStatus(),
});

const withConnect = connect(
  mapStateToProps,
);

export default withConnect(LoadingIndicator);
