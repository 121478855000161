import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import messages from 'utils/messages';
import Card from 'components/Detail/Card';
import UpdateInformation from 'containers/Auth/Register/Billing';
import { getInitials, truncate } from 'utils/common';

class PartnerInformation extends Component {
  state = {
    showEditModal: false,
  }

  onAddTags = (tags) => {
    this.setState({ tags });
  }

  openEditModal = () => {
    this.setState({ showEditModal: true });
  }

  closeEditModal = () => {
    this.setState({ showEditModal: false });
  }

  onEditRole = (data) => {
    const { tags } = this.state;
    const { user } = this.props;

    const payload = {
      ...data,
      tags,
      id: user.id,
    };

    this.props.onEdit(payload, this.closeEditModal);
  }

  onSubmitBilling = (data) => {
    const firstName = data.name.split(' ')[0] || '';
    const lastName = data.name.split(' ')[1] || '';

    const payload = {
      address1: data.address1.trim(),
      address2: data.address2.trim(),
      city: data.city.trim(),
      country: data.country,
      firstName,
      lastName,
      postCode: data.postCode,
    };

    this.props.onUpdate(payload);
    this.setState({ showBillingPage: false, showPaymentPage: true });
    this.setState({ showEditModal: false });
    this.props.onClose()
  }

  getAddressInfo = (billing) => {
    let address = '';

    if (!billing) {
      return '';
    }

    if (billing.address1) {
      address = `${billing.address1.trim()}`;
    }

    if (billing.address2) {
      address = address ? `${address}, ${billing.address2.trim()}` : ` ${billing.address2.trim()}`;
    }

    if (billing.city) {
      address = address ? `${address}, ${billing.city.trim()}` : ` ${billing.city.trim()}`;
    }

    if (billing.postCode) {
      address = address ? `${address}, ${billing.postCode.trim()}` : ` ${billing.postCode.trim()}`;
    }

    if (billing.country) {
      address = address ? `${address}, ${billing.country.trim()}` : ` ${billing.country.trim()}`;
    }

    return truncate(address, 100);
  }

  renderContent = () => {
    const { showEditModal } = this.state
    const { color, billing: {billingInformation} } = this.props

    const address = this.getAddressInfo(billingInformation)

    return (
      <>
      <div className="flow-modules__data-table data-table">
        <table className="data-table__table">
          <tbody className="data-table__body">
            <tr className="data-table__row" onClick={this.openEditModal}>
              <td className="data-table__td data-table__td_1">
                <div className={`checkbox checkbox_${color}`}>
                  <div className="checkbox__image">
                    { billingInformation && getInitials(billingInformation.firstName, billingInformation.lastName)}
                  </div>
                </div>
              </td>
              <td className={`data-table__td ${color} data-long_text`}>
                {billingInformation && `${billingInformation.firstName} ${billingInformation.lastName}`}
              </td>
              <td className="data-table__td data-long_text">{address}</td>
            </tr>
          </tbody>
        </table>
      </div>
      </>
    )
  }

  render() {
    const { color, billing: {billingInformation}, billing, isOpen } = this.props

    if (isOpen) {
      return <UpdateInformation
        workspaceName={this.props.workspaceName}
        initialValues={{
          country: billingInformation.country,
          name: `${billingInformation.firstName} ${billingInformation.lastName}`,
          address1: billingInformation.address1,
          address2: billingInformation.address2,
          postCode: billingInformation.postCode,
          city: billingInformation.city,
        }}
        onSubmit={this.onSubmitBilling}
        onClose={() => this.props.onClose()} color={color}/>
    }

    return (
      <Card
        noGrid
        color={this.props.color}
        isLoading={this.props.isLoading}
        title={<FormattedMessage {...messages.billingInformation} />}
        subTitle={<FormattedMessage {...messages.billingInformationDesc} />}
        className="flow-modules"
        customText={<FormattedMessage {...messages.edit} />}
        action={billing.billingInformation && this.props.onOpen}
        content={this.renderContent()}
      />
    );
  }
}

PartnerInformation.propTypes = {
  user: PropTypes.object,
  color: PropTypes.string,
};

export default PartnerInformation;
