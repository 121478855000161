import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ButtonInfo = ({
  children,
  onClick = () => null,
  color = 'accent0', className,
}) => (
  <div
    onClick={onClick}
    className={classNames('badge_tag', {
      [` badge_tag_${color}`]: color,
      [className]: className,
    })}
  >
    {children}
  </div>
);

ButtonInfo.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};


export default ButtonInfo;
