import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import {withRouter } from 'withRouter'

import Modal from "components/Modal";
import LoginForm from "containers/Auth/Login/Form";
import { routeMapping } from "routes";
import ProcessPage from "components/ProcessPage";
import appMessages from "utils/messages";
import BookSchedule from "components/Calendar";
import { intl } from "containers/LanguageProvider/intlProvider";

class SuspendedWorkspace extends Component {
  state = {
    showScheduleModal: false,
    showModal: true,
  };

  onContact = () => {
    this.setState({ showScheduleModal: true, showModal: false });
  };

  onClose = () => {
    this.props.router.navigate(routeMapping.LOGIN.path);
  };

  renderModal = () => {
    const { onClose } = this;

    const buttons = [
      <button
        className="modal__cancel success"
        key="cancel"
        onClick={this.onContact}
      >
        Contact Now
      </button>,
    ];

    return (
      <Modal
        buttons={buttons}
        title="Workspace Suspended"
        body={
          "Your Workspace has been suspended and can not be accessed. Please get in touch with Viso Support."
        }
        onClose={onClose}
      />
    );
  };

  render() {
    const { onClose } = this.props;

    if (this.state.showScheduleModal) {
      return <ProcessPage>
        {() => (
          <div className="customize-calendly-wrapper">
            <BookSchedule
              workspace={this.props.workspace}
              title={"Contact Support"}
              desc={
                "Schedule a call with the Viso Support team. Please proceed by selecting a date below."
              }
              onClose={() => {
                onClose && onClose();
                this.setState({ showScheduleModal: false });
              }}
            />
          </div>
        )}
      </ProcessPage>;
    }

    return (
      <>
        <ProcessPage>
          {() => (
            <div>
              <div className="headline">
                <h1 className="headline__title center headline_title__register">
                  Viso Suite
                </h1>
                <h3 className="headline__subtitle">
                  <FormattedMessage {...appMessages.loginAccount} />
                </h3>
              </div>
              <div className="login">
                <LoginForm onSubmit={this.onLogin} />
              </div>
            </div>
          )}
        </ProcessPage>
        {this.state.showModal && this.renderModal()}
      </>
    );
  }
}

SuspendedWorkspace.propTypes = {};

export default withRouter(SuspendedWorkspace);
