import React from 'react';

export default () => (
  <svg
    width="7"
    height="2"
    viewBox="0 0 7 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line y1="1.25" x2="7" y2="1.25" stroke="currentColor" strokeWidth="1.5" />
  </svg>
);
