import React from 'react';

export default () => (
  <svg
    width="44"
    height="45"
    viewBox="0 0 44 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.44125 40.059C3.37585 36.4674 1.5 31.8007 1.5 26.6993C1.5 21.2389 3.66914 16.0021 7.53025 12.141C11.3914 8.2799 16.6281 6.11076 22.0886 6.11076V1L29.7035 7.52093L22.0886 12.141V9"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.0954 27.8436C29.0954 29.5487 28.1277 31.145 26.8452 32.8537C26.6276 33.1436 26.3954 33.4439 26.1607 33.7476C25.7442 34.2863 25.3195 34.8356 24.9535 35.3561C24.3706 36.185 23.8328 37.0784 23.5623 38.0194C23.5337 38.1189 23.4793 38.2004 23.428 38.247C23.4044 38.2684 23.3873 38.2771 23.3803 38.2801C23.378 38.281 23.3766 38.2815 23.3759 38.2817H20.8015C20.8008 38.2815 20.7994 38.281 20.7971 38.2801C20.79 38.2771 20.773 38.2684 20.7494 38.247C20.6981 38.2004 20.6437 38.1189 20.6151 38.0194C20.3445 37.0784 19.8067 36.185 19.2239 35.3561C18.8579 34.8356 18.4332 34.2863 18.0167 33.7476C17.782 33.4439 17.5498 33.1436 17.3322 32.8537C16.0496 31.145 15.082 29.5487 15.082 27.8436C15.082 23.9808 18.2259 20.8369 22.0887 20.8369C25.9515 20.8369 29.0954 23.9808 29.0954 27.8436Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M20.8008 42.5684L23.376 42.5684L23.376 43.9998L20.8008 43.9998L20.8008 42.5684Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M37.7368 40.0597C39.4958 38.007 40.8322 35.6272 41.6694 33.0568C42.5066 30.4865 42.8282 27.7762 42.6157 25.0813C42.4032 22.3864 41.6607 19.7599 40.4309 17.3526C39.2011 14.9453 37.5082 12.8044 35.4492 11.0527"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
