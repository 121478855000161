import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Field, reduxForm, Form, formValueSelector,
} from 'redux-form';
import { Link } from 'react-router-dom';
import {withRouter} from 'withRouter';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Input from 'components/Form/Input';
import InputPassword from 'components/Form/InputPassword';
import { resetPasswordRequest } from 'containers/Login/actions';
import validation from './validation';
import ResetPassword from '../ForgotPassword/ResetPassword';
import Button from 'components/Button';

class LoginForm extends Component {
  state = {
    showConfirmationPage: false,
    showResetPage: false,
    resetEmail: '',
  }

  redirectToForgetPassword = () => {
    this.props.router.navigate('/forgot-password');
  }

  onForgetPassword = () => {
    this.setState({ showResetPage: true });
  }

  render() {
    const { isLoading, password } = this.props;

    if (this.state.showResetPage) {
      return (
        <ResetPassword
          onCallback={email => this.setState({ showConfirmationPage: true, resetEmail: email })}
          onClose={() => this.setState({ showResetPage: false })}
        />
      );
    }

    return (
      <Form onSubmit={this.props.handleSubmit} className="login__form">
        <Field
          name="email"
          id="email"
          dataCy="email"
          component={Input}
          label="Email"
        />
        <Field
          name="password"
          id="password"
          dataCy="password"
          component={InputPassword}
          label="Password"
        />
        <div className="text-input-action">
          <Link to="/login/resetpassword" className="label-link">
            Forgot password
          </Link>
        </div>
        <div className="login__actions register__actions">
          <Button
            type="submit"
            disabled={!this.props.valid || isLoading || !password}
            isLoading={isLoading}
            className={classNames('login__submit button', { 'round-button_disabled': !this.props.valid || isLoading || !password })}
          >
            Sign in
          </Button>
        </div>
        <div className="login__switch">
          Don&apos;t have an account?{' '}
          <a href='https://viso.ai/get-a-demo'
            target="_blank" rel="noopener noreferrer"
            className="accent0">
            Sign up
          </a></div>
      </Form>
    );
  }
}

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
};

const selector = formValueSelector('LOGIN_FORM');

function mapStateToProps(state) {
  return {
    email: selector(state, 'email'),
    password: selector(state, 'password'),
  };
}

const withConnect = connect(mapStateToProps, { resetPasswordRequest });

export default compose(
  reduxForm({
    form: 'LOGIN_FORM',
    validate: validation,
  }),
  withRouter,
  withConnect,
)(LoginForm);
