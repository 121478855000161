import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CustomPassword from 'components/Input/CustomPassword';

class InputPassword extends Component {
  getHintText = () => {
    if (this.checkIsInvalid()) {
      return this.props.meta.error;
    }
  }

  checkIsInvalid = () => {
    const { error, visited } = this.props.meta;

    return error && visited;
  }

  render() {
    return (
      <CustomPassword
        {...this.props}
        onChange={this.props.input.onChange}
        value={this.props.input.value}
        isInvalid={this.checkIsInvalid()}
        hintText={this.getHintText()}
      />
    );
  }
}

InputPassword.propTypes = {
  input: PropTypes.object,
};

export default InputPassword;
