import React from 'react';

export default () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.49988 0.00100007C5.03348 -0.0101531 4.56956 0.072078 4.13541 0.242856C3.70125 0.413634 3.30563 0.669511 2.97182 0.995434C2.63801 1.32136 2.37275 1.71075 2.19164 2.14069C2.01053 2.57064 1.91724 3.03246 1.91724 3.499C1.91724 3.96554 2.01053 4.42736 2.19164 4.85731C2.37275 5.28725 2.63801 5.67664 2.97182 6.00257C3.30563 6.32849 3.70125 6.58437 4.13541 6.75514C4.56956 6.92592 5.03348 7.00815 5.49988 6.997C5.96629 7.00815 6.43021 6.92592 6.86436 6.75514C7.29852 6.58437 7.69414 6.32849 8.02795 6.00257C8.36176 5.67664 8.62702 5.28725 8.80813 4.85731C8.98923 4.42736 9.08253 3.96554 9.08253 3.499C9.08253 3.03246 8.98923 2.57064 8.80813 2.14069C8.62702 1.71075 8.36176 1.32136 8.02795 0.995434C7.69414 0.669511 7.29852 0.413634 6.86436 0.242856C6.43021 0.072078 5.96629 -0.0101531 5.49988 0.00100007V0.00100007Z" fill="currentColor" />
    <path d="M3.5 8.995C2.57174 8.995 1.6815 9.36375 1.02513 10.0201C0.368749 10.6765 0 11.5667 0 12.495L0 14.992H11V12.495C11 11.5667 10.6313 10.6765 9.97487 10.0201C9.3185 9.36375 8.42826 8.995 7.5 8.995H3.5Z" fill="currentColor" />
    <path d="M12.5 10.001H12V15.001H15V12.501C15 11.838 14.7366 11.2021 14.2678 10.7332C13.7989 10.2644 13.163 10.001 12.5 10.001Z" fill="currentColor" />
    <path d="M11.5 4.001C10.837 4.001 10.2011 4.26439 9.73223 4.73323C9.26339 5.20207 9 5.83796 9 6.501C9 7.16404 9.26339 7.79993 9.73223 8.26877C10.2011 8.73761 10.837 9.001 11.5 9.001C12.163 9.001 12.7989 8.73761 13.2678 8.26877C13.7366 7.79993 14 7.16404 14 6.501C14 5.83796 13.7366 5.20207 13.2678 4.73323C12.7989 4.26439 12.163 4.001 11.5 4.001V4.001Z" fill="currentColor" />
  </svg>
);
