import React from 'react';

export default () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 21 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.40179 8.5L12.1718 0.240025C10.6968 -0.0893731 9.16628 -0.0796152 7.69561 0.268563C6.22495 0.616741 4.85252 1.29425 3.6818 2.25002L7.34179 8.6L7.40179 8.5ZM19.5418 7.00001C18.6218 4.08002 16.3918 1.74002 13.5418 0.660024L9.88178 7.00001H19.5418ZM19.8018 8.00001H12.3118L12.6018 8.5L17.3618 16.75C19.0625 14.914 20.0056 12.5026 20.0018 10C20.0018 9.31 19.9318 8.65 19.8018 8.00001ZM6.54179 10L2.6418 3.25002C1.57031 4.41046 0.790224 5.80915 0.365963 7.33058C-0.0582983 8.852 -0.114565 10.4525 0.20181 12H7.69179L6.54179 10V10ZM0.461809 13C1.38181 15.92 3.6118 18.26 6.46179 19.34L10.1218 13H0.461809V13ZM11.7318 13L7.83179 19.76C9.30677 20.0894 10.8373 20.0796 12.308 19.7314C13.7786 19.3833 15.151 18.7057 16.3218 17.75L12.6618 11.4L11.7318 13V13Z"
      fill="currentColor"
    />
  </svg>
);
