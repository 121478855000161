import React from 'react';

export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
    <g clip-path="url(#clip0_32074_46047)">
        <path d="M7.93333 1.16943C3.57 1.16943 0 4.73943 0 9.10277C0 13.4661 3.57 17.0361 7.93333 17.0361C12.2967 17.0361 15.8667 13.4661 15.8667 9.10277C15.8667 4.73943 12.2967 1.16943 7.93333 1.16943ZM7.93333 3.15277C9.24233 3.15277 10.4323 3.56927 11.4042 4.26343L3.094 12.5736C2.39983 11.6018 1.98333 10.4118 1.98333 9.10277C1.98333 5.81043 4.641 3.15277 7.93333 3.15277ZM12.7727 5.63193C13.4668 6.60377 13.8833 7.79377 13.8833 9.10277C13.8833 12.3951 11.2257 15.0528 7.93333 15.0528C6.62433 15.0528 5.43433 14.6363 4.4625 13.9421L12.7727 5.63193Z" fill="currentColor"/>
    </g>
    <defs>
        <clipPath id="clip0_32074_46047">
        <rect width="17" height="17" fill="white" transform="translate(0 0.0361328)"/>
        </clipPath>
    </defs>
    </svg>
);
