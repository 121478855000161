import { put, takeEvery } from 'redux-saga/effects';
import {
  loggedInUserDataSuccess,
} from 'containers/App/actions';
import { LOGGED_IN_USER_DATA_REQUEST } from './constants';

function* getUserSaga() {
  const userData = JSON.parse(localStorage.getItem('userData'));

  yield put(loggedInUserDataSuccess(userData));
}

export default function* homePageSaga() {
  yield takeEvery(LOGGED_IN_USER_DATA_REQUEST, getUserSaga);
}
