import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Switch extends React.Component {
  state = {
    currentValue: false,
  };

  componentDidMount() {
    this.setState({ currentValue: this.props.value });
  }

  static getDerivedStateFromProps(nextProps, val) {
    if ((nextProps.value !== val) && nextProps.toogleWithLabel) {
      return {
        currentValue: nextProps.value,
      };
    }

    return '';
  }

  onChange = (e) => {
    const { name } = this.props;

    this.setState({ currentValue: e.target.checked });

    if (this.props.onChange) {
      this.props.onChange(name, e.target.checked);
    }
  };

  render() {
    const {
      text, color, noLabel, id,
    } = this.props;
    const { currentValue } = this.state;

    return (
      <div className={classNames('switch-container', {
        'no-label': noLabel,
        [this.props.className]: this.props.className
      })}
      >
        <label
          className={classNames('switch', {
          })}
        >
          <input type="checkbox" onChange={this.onChange} checked={currentValue} id={id} />
          <span
            className={classNames('slider round', {
              [color]: color,
              'slider-active': this.props.isAlwaysTrue,
            })}
          />
        </label>
        {text && (
          <h4 className={classNames('switch-label', {
            'switch-label-text': this.state.currentValue,
          })}
          >
            {text}
          </h4>
        )
        }
      </div>
    );
  }
}

Switch.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.any,
  parentClass: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  hintText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isInvalid: PropTypes.any,
};
