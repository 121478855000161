import React from 'react';
import { Icon404 } from 'images/common';

export const Status404Page = ({
  parentClass,
}) => (
  <main className={`not-found__wrapper ${parentClass}`}>
    <div className="not-found__img"><Icon404 /></div>
    <div>
      <div className="not-found__text">Page Not Found.</div>
      <p className="not-found__description">The page you are looking for does not exist or has been moved to a new location.</p>
    </div>
  </main>
);

export default Status404Page;
