import React, { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import {withRouter } from 'withRouter'
import { createStructuredSelector } from "reselect";
import { FormattedMessage } from "react-intl";
import slugify from "react-slugify";
import ListScroll from "components/List/ListScroll";

import Button from "components/Button";
import messages from "utils/messages";
import ProcessPage from "components/ProcessPage";
import { dateFormatter } from "utils/common";
import { routeMapping } from "routes";
import { makeSelectUser } from "containers/App/selectors";
import { recoverWorkspaceRequest, fetchWorkspaceRequest } from "./actions";
import ActivateWorkspace from "./ActivateWorkspace";
import BookSchedule from "components/Calendar";
import { intl } from "containers/LanguageProvider/intlProvider";

class RemovedWorkspace extends Component {
  state = {
    showSelectPlanPage: false,
    showScheduleModal: false,
  };

  onShowPlanPage = () => {
    this.setState({ showSelectPlanPage: true });
  };

  onShowActivePage = () => {
    this.setState({ showSelectPlanPage: false });
  };

  getWorkspace = async () => {
    const { loggedInUser } = this.props;
    const workspace = loggedInUser.activeWorkspace;

    if (workspace) {
      await this.props.fetchWorkspaceRequest({ subDomain: workspace });
      this.props.router.navigate(
        `/${workspace && slugify(workspace.name)}${
          routeMapping.MANAGE_SUBSCRIPTION.path
        }`
      );
    }
  };

  onContact = () => {};

  onSubmitPayment = () => {
    this.setState({ showScheduleModal: true });
    // const { workspace } = this.props;
    // const wsPath = workspace && slugify(workspace.name);

    // this.props.recoverWorkspaceRequest(null, () => {
    //   this.props.router.navigate(`/${wsPath}?activated=true`);
    //   this.getWorkspace();
    // });
  };

  render() {
    const { workspace } = this.props;

    if (this.state.showSelectPlanPage) {
      return (
        <ActivateWorkspace
          onClose={this.onShowActivePage}
          getWorkspace={this.getWorkspace}
        />
      );
    }

    if (this.state.showScheduleModal) {
      return (
        <ProcessPage>
          {() => (
            <div className="customize-calendly-wrapper">
              <BookSchedule
                workspace={this.props.workspace}
                title={"Contact Support"}
                desc={
                  "Schedule a call with the Viso Support team. Please proceed by selecting a date below."
                }
                onClose={() => {
                  this.setState({ showScheduleModal: false });
                }}
              />
            </div>
          )}
        </ProcessPage>
      );
    }

    return (
      <ProcessPage onCancel={this.props.onClose}>
        {() => (
          <div className="popup__cont">
            <div className="login">
              <div className="headline">
                <h1 className="headline__title">
                  <FormattedMessage {...messages.removedWorkspace} />
                </h1>
                <h3 className="headline__subtitle">
                  {workspace && (workspace.name || workspace.subDomain)}
                </h3>
                <div className="login__form pull-left">
                  <div className="title mx-b_10 fw500">
                    <FormattedMessage {...messages.recoverYourWorkspace} />
                  </div>
                  <p className="description">
                    <FormattedMessage {...messages.workspaceHasBeenDeleted} />{" "}
                    <span className="text-color">
                      {dateFormatter(workspace.deleteDate, true)}.
                    </span>
                  </p>
                </div>
                <div className="login__actions">
                  <Button
                    onClick={this.onSubmitPayment}
                    className={classNames(
                      "button",
                      `button_${this.props.color}`
                    )}
                    disabled={this.props.disabled}
                  >
                    <FormattedMessage {...messages.recover} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </ProcessPage>
    );
  }
}

RemovedWorkspace.propTypes = {};

const mapStateToProps = createStructuredSelector({
  loggedInUser: makeSelectUser(),
});

const withConnect = connect(mapStateToProps, {
  recoverWorkspaceRequest,
  fetchWorkspaceRequest,
});

export default withRouter(withConnect(RemovedWorkspace));
