import React from 'react';
import { Icon403 } from 'images/common';

export const Status403Page = ({
  parentClass,
}) => (
  <main className={`not-found__wrapper ${parentClass}`}>
    <div className="not-found__img"><Icon403 /></div>
    <div>
      <div className="not-found__text">Access Denied.</div>
      <p className="not-found__description">You do not have permission to view this resource.</p>
    </div>
  </main>
);

export default Status403Page;
