import classNames from 'classnames'
import AHref from 'components/AHref'
import Button from 'components/Button'
import HeaderCard from 'containers/Marketplace/SingleSelectCard'
import FaceDetection from 'images/face-detection.png'
import PeopleCounting from 'images/people-counting.png'
import PersonDetection from 'images/person-detection.png'
import VehicleCounting from 'images/vehicle-counting.png'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { reduxForm } from 'redux-form'
import { DEFAULT_ACCENT } from 'utils/constants'
import messages from 'utils/messages'
import { LOGIN_FORM } from '../constants'
import validation from './validation'

class PreApplicationSelection extends Component {

  getImage = {
    'People Counting': PeopleCounting,
    'Person Detection': PeopleCounting,
    'Face Detection': FaceDetection,
    'Vehicle Counting': VehicleCounting
  }

  render() {
    const { color } = this.props

    const peopleDetection = this.props.applications.find(item => item.name === 'Person Detection');

    if (!peopleDetection) {
      return <div/>
    }

    return (
      <div className="pre-application-wrapper">
        <div className="grid-row">
          <HeaderCard
            customClass="market-recomendations__item_accent3"
            description={peopleDetection.tagLine}
            id={peopleDetection.id}
            key={peopleDetection.id}
            name={peopleDetection.name}
            img={this.getImage[peopleDetection.name] || FaceDetection}
            alt="Market recommendations"
          />
        </div>
        <div className='login__actions'>
          <Button
            type='submit'
            onClick={() => {
              this.props.onSubmit()
            }}
            className={classNames(`button button_${color}`, {
            })}
          >
            <FormattedMessage {...messages.continue}/>
          </Button>
          {!this.props.hideSkip &&
          <div className="login__switch button_secondary_container">
            <AHref
              onClick={this.props.onSkip}
            >
          Skip demo import
            </AHref>
          </div>
          }
        </div>
      </div>
    )
  }
}

PreApplicationSelection.propTypes = {
  isLoading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  currentPage: PropTypes.string,
  color: PropTypes.string
}

PreApplicationSelection.defaultProps = {
  color: DEFAULT_ACCENT
}

export default reduxForm({
  form: LOGIN_FORM,
  destroyOnUnmount: false,
  // forceUnregisterOnUnmount: true,
  validate: validation
})(PreApplicationSelection)
