import { createSelector } from 'reselect';

const selectUser = state => state.user;

export const makeSelectUser = () => createSelector(selectUser, userState => userState.get('user'));

export const makeSelectMe = () => createSelector(selectUser, userState => userState && userState.get('me'));

export const makeSelectFetchUser = () => createSelector(selectUser, userState => userState.get('fetchUsers'));

export const makeSelectFetchInvitedByUser = () => createSelector(selectUser, userState => userState.get('fetchInvitedByUser'));

export const makeSelectInviteUser = () => createSelector(selectUser, userState => userState.get('inviteUsers'));

export const makeSelectInvitedUser = () => createSelector(selectUser, userState => userState.get('fetchInvitedByUser'));

export const makeSelectUpdateUser = () => createSelector(selectUser, userState => userState.get('updateUser'));

export const makeSelectError = () => createSelector(selectUser, userState => userState.get('errors'));

export const makeSelectLoading = () => createSelector(selectUser, userState => userState.get('loading'));

export const makeUserSelectLoading = () => createSelector(selectUser, userState => userState.get('userLoading'));

export const makeSelectInvitedLoading = () => createSelector(selectUser, userState => userState.get('invitedLoading'));

export const makeSelectChangePasswordData = () => createSelector(selectUser, userState => userState.get('changePasswordData'));

export const makeSelectUsers = () => createSelector(selectUser, userState => userState.getIn(['userData', 'users']));

export { selectUser };
