import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class SettingTypes extends React.Component {
  state = {
    expandCard: false,
  }

  expandNotification = () => {
    this.setState({ expandCard: true });
  }

  collapseNotification = (e) => {
    e.stopPropagation();
    e.preventDefault();

    this.setState({ expandCard: false });
  }

  render() {
    const {
      title, parentClass, desc, onChange, name,
    } = this.props;
    const containerClass = classNames('toggle toogle_container', {
      [parentClass]: parentClass,
      card_loading: this.props.isLoading,
    });

    return (
      <>
        <div className={containerClass}>
          <div className="grid-row">
            <div className="grid-col grid-col_auto">
              <input
                type="checkbox"
                className="toggle__input"
                checked={this.props.value}
                onChange={e => onChange(name, e.target.checked)}
              />
              <span className="toggle__icon" />
            </div>
            <div className="grid-col">
              <div className="toogle__title">{title}</div>
              <span className="toogle__desc">{desc}</span>
            </div>
          </div>
        </div>
      </>
    );
  }
}

SettingTypes.propTypes = {
  title: PropTypes.string,
  settings: PropTypes.array,
  showUpdateChange: PropTypes.bool,
  showStatusChange: PropTypes.bool,
  parentClass: PropTypes.string,
};

export default SettingTypes;
