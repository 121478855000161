import React from 'react';
import { FormattedMessage } from 'react-intl';

import appMessages from 'utils/messages';

const filterColumns = [{
  id: 1,
  label: <FormattedMessage {...appMessages.status} />,
  field: 'status',
  type: 'radio',
},
{
  id: 2,
  label: <FormattedMessage {...appMessages.userName} />,
  field: 'username',
  type: 'search',
},
{
  id: 4,
  label: <FormattedMessage {...appMessages.email} />,
  field: 'email',
  type: 'search',
},
{
  id: 5,
  label: <FormattedMessage {...appMessages.userRole} />,
  field: 'role',
  type: 'checkbox',
  filterBy: 'id',
},
{
  id: 6,
  label: <FormattedMessage {...appMessages.modifiedAt} />,
  field: 'updatedAt',
  type: 'date',
},
{
  id: 7,
  label: <FormattedMessage {...appMessages.lastSignIn} />,
  field: 'lastLoginAt',
  type: 'date',
},
{
  id: 8,
  label: <FormattedMessage {...appMessages.tags} />,
  field: 'tags',
  type: 'tags',
}];

export default filterColumns;
