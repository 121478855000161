import React from 'react';

export default () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_31442_28568)">
      <path d="M9.8019 1.51488C9.68759 1.2584 9.50669 1.0372 9.278 0.874277C9.0493 0.711349 8.78115 0.612631 8.5014 0.588379C8.22314 0.614087 7.95677 0.713477 7.72968 0.876321C7.5026 1.03916 7.32301 1.25958 7.2094 1.51488L0.307396 13.4659C-0.406604 14.6984 0.179896 15.7099 1.5994 15.7099H15.4034C16.8229 15.7099 17.4094 14.6984 16.6954 13.4659L9.8019 1.51488ZM9.3514 13.1599H7.6514V11.4599H9.3514V13.1599ZM9.3514 9.75988H7.6514V4.65988H9.3514V9.75988Z" fill="#444444" />
    </g>
    <defs>
      <clipPath id="clip0_31442_28568">
        <rect width="17" height="16" fill="white" transform="translate(0 0.544189)" />
      </clipPath>
    </defs>
  </svg>
);
