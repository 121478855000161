/**
 *
 * Private Route
 *
 */

import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import AuthHelper from 'utils/authHelper';
import { routeMapping } from 'routes';

const PrivateRoute = ({ element: Component, isAuthenticated, ...rest }) => {
  return isAuthenticated ? (
    <Component />
  ) : (
    <Navigate
      to={{ pathname: '/login'}}
    />
  )
  };

const mapStateToProps = () => ({
  isAuthenticated: AuthHelper.isAuthenticated(),
});

export default connect(
  mapStateToProps,
  null,
)(PrivateRoute);
