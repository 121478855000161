import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import OutsideClickHandler from 'react-outside-click-handler';

import appMessages from 'utils/messages';

class AddFilter extends React.Component {
  state = {
    isOpen: false,
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onEscClick, false);
  }

  onEscClick = (event) => {
    if (!this.props.ignoreCloseOnEsc && event.keyCode === 27) {
      // on Esc button click it should close popup
      this.setState({ isOpen: false });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEscClick, false);
  }

  onOpenDropdown = () => {
    this.setState({ isOpen: true });
  }

  onCloseDropdown = () => {
    this.setState({ isOpen: false });
  }

  onAddFilterType = (selectedHeader) => {
    this.props.onAdd(selectedHeader);

    this.onCloseDropdown();
  }

  render() {
    const { filters, disabled } = this.props;
    const dropdownClass = classNames('filters-bar__dropdown filters-bar__dropdown_xs', {
      'filters-bar__dropdown_visible': this.state.isOpen,
    });

    return (
      <OutsideClickHandler
        display="inline-block"
        onOutsideClick={this.onCloseDropdown}
      >
        <>
          <div className="filters-bar__item">
            <button type="button"
              className={classNames('filters-bar__button filters-bar__button_add', {
                'filters-bar__button_add_disabled': disabled
              })} onClick={this.onOpenDropdown} disabled={disabled}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" fill="currentcolor"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
              < FormattedMessage {...appMessages.addFilter} />
            </button>
            <div className={dropdownClass}>
              <div className="filters-bar__header"><FormattedMessage {...appMessages.addAFilter} /></div>
              {filters
                .filter(column => !(this.props.defaultFilter && this.props.defaultFilter
                  .map(item => item.field).includes(column.field)))
                .filter(column => column && column.label)
                .map((column, index) => (
                  <div className="filters-bar__option" onClick={() => this.onAddFilterType(column)} key={index}>{column.label}</div>
                ))}
            </div>
          </div>
        </>
      </OutsideClickHandler>
    );
  }
}

AddFilter.propTypes = {
  data: PropTypes.array,
  onAdd: PropTypes.func,
  filters: PropTypes.array,
  onRemove: PropTypes.func,
  onFilter: PropTypes.func,
  isDefault: PropTypes.bool,
  disabled: PropTypes.bool,
  onSelectFilter: PropTypes.func,
};

export default AddFilter;
