import {
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATION_SETTINGS_SUCCESS,
  FETCH_NOTIFICATION_SETTINGS_REQUEST,
  UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
  UPDATE_NOTIFICATION_SETTINGS_REQUEST,
  MARK_AS_UNREAD_SUCCESS,
  MARK_AS_UNREAD_REQUEST,
  MARK_AS_READ_SUCCESS,
  MARK_AS_READ_REQUEST,
  MARK_AS_ALL_UNREAD_SUCCESS,
  MARK_AS_ALL_UNREAD_REQUEST,
  MARK_AS_ALL_READ_REQUEST,
  MARK_AS_ALL_READ_SUCCESS,
} from './constants';

export function getNotificationList(payload, type) {
  return {
    type: FETCH_NOTIFICATIONS_REQUEST,
    payload,
    meta: type,
  };
}

export function getNotificationListSuccess(payload) {
  return {
    type: FETCH_NOTIFICATIONS_SUCCESS,
    payload,
  };
}

export function markAsReadNotification(payload) {
  return {
    type: MARK_AS_READ_REQUEST,
    payload,
  };
}

export function markAsReadSuccessNotification(payload) {
  return {
    type: MARK_AS_READ_SUCCESS,
    payload,
  };
}

export function markAsAllReadNotification(payload) {
  return {
    type: MARK_AS_ALL_READ_REQUEST,
    payload,
  };
}

export function markAsAllReadSuccessNotification(payload) {
  return {
    type: MARK_AS_ALL_READ_SUCCESS,
    payload,
  };
}

export function markAsAllUnreadNotification(payload) {
  return {
    type: MARK_AS_ALL_UNREAD_REQUEST,
    payload,
  };
}

export function markAsAllUnreadSuccessNotification(payload) {
  return {
    type: MARK_AS_ALL_UNREAD_SUCCESS,
    payload,
  };
}

export function markAsUnreadNotification(payload) {
  return {
    type: MARK_AS_UNREAD_REQUEST,
    payload,
  };
}

export function markAsUneadSuccessNotification(payload) {
  return {
    type: MARK_AS_UNREAD_SUCCESS,
    payload,
  };
}

export function getNotificationSettingList(payload) {
  return {
    type: FETCH_NOTIFICATION_SETTINGS_REQUEST,
    payload,
  };
}

export function getNotificationSettingListSuccess(payload) {
  return {
    type: FETCH_NOTIFICATION_SETTINGS_SUCCESS,
    payload,
  };
}

export function updateNotificationSetting(payload) {
  return {
    type: UPDATE_NOTIFICATION_SETTINGS_REQUEST,
    payload,
  };
}

export function updateNotificationSettingSuccess(payload) {
  return {
    type: UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
    payload,
  };
}
