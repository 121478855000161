import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withRouter } from 'withRouter'
import OutsideClickHandler from 'react-outside-click-handler';

import injectReducer from 'utils/injectReducer';
import ActionIcon from 'components/ActionIcon';
import { getDetailRole } from 'containers/Settings/AccessRole/selectors';
import { Scrollbars } from 'react-custom-scrollbars';

import { createStructuredSelector } from 'reselect';
import { getNotificationState } from 'containers/App/selectors';
import { updateNotificationDisplay } from 'containers/App/actions';
import VerticalButtonMenu from 'components/VerticalButtonMenu';
import reducer from 'containers/App/reducer';
import { CloseIcon, CheckAllIcon } from 'images/common';
import { FormattedMessage } from 'react-intl';
import { deleteNotification, deleteAllNotifications } from 'api/notifications';
import {
  getNotificationList,
  updateNotificationSetting,
  getNotificationSettingList,
  markAsReadNotification,
  markAsUnreadNotification,
  markAsAllReadNotification,
  markAsAllUnreadNotification,
} from 'containers/Notification/actions';
import {
  getNotificationSettings,
  getNotificationLoading,
  getSettingLoading,
} from 'containers/Notification/selectors';
import { defaultNotifier } from 'functions/notificationHandler';
import { makeSelectFetchWorkspace } from 'containers/Settings/Workspace/selectors';
import messages from '../../utils/messages';
import { NOTIFICATION_VIEW, SETTING_VIEW } from './constants';
import Settings from './Settings';
import Notifications from './Notifications';
import Menu from './PageMenu';

class Notification extends React.Component {
  state = {
    activeState: NOTIFICATION_VIEW,
  };

  closeNotification = () => {
    this.props.updateNotificationDisplay(false);
  };

  componentWillUnmount() {
    this.setState({ activeState: NOTIFICATION_VIEW });
    this.props.updateNotificationDisplay(false);
  }

  updateActiveView = (payload) => {
    this.setState({ activeState: payload });

    if (payload === NOTIFICATION_VIEW) {
      return this.props.getNotificationList();
    }
  };

  markAllAsRead = async () => {
    this.props.markAsAllReadNotification();
  };

  markAllAsUnRead = async () => {
    this.props.markAsAllUnreadNotification();
  };

  onDeleteAll = async () => {
    const { data } = await deleteAllNotifications();

    if (data.errors) {
      return defaultNotifier(data.errors);
    }

    this.props.getNotificationList('', 'delete');
  };

  handleScrollbarsMount = (scrollbars) => {
    const viewElem = scrollbars && scrollbars.view;

    this.contentRef = viewElem;
    // hack to update ref and pass down
    this.setState({});
  };

  render() {
    const visibleClass = classNames('notifications-bar', {
      'notifications-bar_visible': this.props.showNotification,
    });

    const visibleWrapperClass = classNames('notifications-bar-container', {
      'notifications-bar-container_visible': this.props.showNotification,
    });

    const settingsTagClass = classNames('secondary-nav__link', {
      'secondary-nav__link_active': this.state.activeState === SETTING_VIEW,
    });

    const notificationTagClass = classNames('secondary-nav__link', {
      'secondary-nav__link_active': this.state.activeState === NOTIFICATION_VIEW,
    });

    return (
      <>
        <OutsideClickHandler onOutsideClick={this.closeNotification}>
          <div className={visibleWrapperClass}>
            <Scrollbars
              universal
              style={{ width: '100%' }}
              autoHide
              autoHideDuration={1}
              ref={this.handleScrollbarsMount}
              renderThumbVertical={allProps => <div {...allProps} className="thumb-vertical" />}
            >
              <div className={visibleClass}>
                <>
                  <button
                    onClick={this.closeNotification}
                    type="button"
                    className="notifications-bar__close"
                  >
                    <CloseIcon />
                  </button>
                  <div className="secondary-nav secondary-nav_accent3">
                    <a
                      href="javascript:void(0)"
                      className={notificationTagClass}
                      onClick={() => this.updateActiveView(NOTIFICATION_VIEW)}
                    >
                      <FormattedMessage {...messages.notifications} />
                    </a>
                    <a
                      href="javascript:void(0)"
                      data-cy="notification-settings"
                      className={settingsTagClass}
                      onClick={() => this.updateActiveView(SETTING_VIEW)}
                    >
                      <FormattedMessage {...messages.settings} />
                    </a>
                  </div>
                  <div className="actions-nav">
                    <VerticalButtonMenu color="accent3">
                      <Menu
                        notificationCount={
                          this.props.notifications
                          && this.props.notifications.notificationList.length
                        }
                        markAllAsUnRead={this.markAllAsUnRead}
                        markAllAsRead={this.markAllAsRead}
                        onDeleteAll={this.onDeleteAll}
                        unreadCount={
                          this.props.notifications
                          && this.props.notifications.unreadCount
                        }
                      />
                    </VerticalButtonMenu>
                    {this.props.notifications
                      && !!this.props.notifications.unreadCount && (
                      <ActionIcon
                        customClass="notification-link"
                        onClick={this.markAllAsRead}
                        icon={<CheckAllIcon />}
                        text=""
                      />
                    )}
                  </div>
                  <>
                    {this.state.activeState === NOTIFICATION_VIEW && (
                      <Notifications
                        notifications={this.props.notifications}
                        isLoading={this.props.isLoading}
                        scrollRef={this.contentRef}
                        onClose={this.closeNotification}
                        history={this.props.router.navigate}
                        workspace={this.props.workspace}
                        getNotificationList={this.props.getNotificationList}
                        readNotification={this.props.markAsReadNotification}
                        deleteNotification={deleteNotification}
                        unreadNotification={this.props.markAsUnreadNotification}
                        activeState={this.state.activeState}
                      />
                    )}
                    {this.state.activeState === SETTING_VIEW && (
                      <Settings
                        settings={this.props.notificationSettings}
                        detailRole={this.props.detailRole}
                        isLoading={this.props.isLoadingSettings}
                        updateNotificationSetting={
                          this.props.updateNotificationSetting
                        }
                        activeState={this.state.activeState}
                      />
                    )}
                  </>
                </>
              </div>
            </Scrollbars>
          </div>
        </OutsideClickHandler>
      </>
    );
  }
}

Notification.propTypes = {
  showNotification: PropTypes.bool,
  updateNotificationDisplay: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  showNotification: getNotificationState(),
  workspace: makeSelectFetchWorkspace(),
  detailRole: getDetailRole(),
  isLoading: getNotificationLoading(),
  isLoadingSettings: getSettingLoading(),
  notificationSettings: getNotificationSettings(),
});

const withConnect = connect(
  mapStateToProps,
  {
    markAsReadNotification,
    markAsUnreadNotification,
    updateNotificationDisplay,
    updateNotificationSetting,
    getNotificationList,
    markAsAllReadNotification,
    markAsAllUnreadNotification,
    getNotificationSettingList,
  },
);

const withReducer = injectReducer({ key: 'global', reducer });

export default compose(
  withRouter,
  withReducer,
  withConnect,
)(Notification);
