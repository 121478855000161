export const LOGIN_ERROR = 'LOGIN/LOGIN_ERROR';
export const LOGIN_REQUEST = 'LOGIN/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN/LOGIN_SUCCESS';

export const SELECT_WORKSPACE_REQUEST = 'SELECT_WORKSPACE_REQUEST';
export const SELECT_WORKSPACE_SUCCESS = 'SELECT_WORKSPACE_SUCCESS';

export const FORGOT_PASSWORD_ERROR = 'LOGIN/FORGOT_PASSWORD_ERROR';
export const FORGOT_PASSWORD_REQUEST = 'LOGIN/FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'LOGIN/FORGOT_PASSWORD_SUCCESS';

export const REGISTER_REQUEST = 'REGISTER/REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER/REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER/REGISTER_ERROR';

export const INVITATION_SIGNUP_REQUEST = 'REGISTER/INVITATION_SIGNUP_REQUEST';
export const INVITATION_SIGNUP_SUCCESS = 'REGISTER/INVITATION_SIGNUP_SUCCESS';

export const RESET_PASSWORD_ERROR = 'LOGIN/RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_REQUEST = 'LOGIN/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'LOGIN/RESET_PASSWORD_SUCCESS';

export const RESET_PAGE = 'ACCEPT_INVITE/RESET_PAGE';
export const REGISTER_PAGE = 'ACCEPT_INVITE/REGISTER_PAGE';

export const INVITE_USER_ERROR = 'LOGIN/INVITE_USER_ERROR';
export const INVITE_USER_REQUEST = 'LOGIN/INVITE_USER_REQUEST';
export const INVITE_USER_SUCCESS = 'LOGIN/INVITE_USER_SUCCESS';

export const INVITE_USER_RESET_PASSWORD_ERROR = 'LOGIN/INVITE_USER_RESET_PASSWORD_ERROR';
export const INVITE_USER_RESET_PASSWORD_REQUEST = 'LOGIN/INVITE_USER_RESET_PASSWORD_REQUEST';
export const INVITE_USER_RESET_PASSWORD_SUCCESS = 'LOGIN/INVITE_USER_RESET_PASSWORD_SUCCESS';

export const GET_PLANS_PENDING = 'GET_PLANS_PENDING';
export const GET_PLANS_SUCCESS = 'GET_PLANS_SUCCESS';
export const GET_PLANS_REJECTED = 'GET_PLANS_REJECTED';

export const GET_HOMEPAGE_REQUEST = 'HOMEPAGE/GET_HOMEPAGE_REQUEST';
export const GET_HOMEPAGE_SUCCESS = 'HOMEPAGE/GET_HOMEPAGE_SUCCESS';
export const GET_HOMEPAGE_ERROR = 'HOMEPAGE/GET_HOMEPAGE_ERROR';

export const GET_PLAN_INFO_REQUEST = 'PLAN_INFO/GET_PLAN_INFO_REQUEST';
export const GET_PLAN_INFO_SUCCESS = 'PLAN_INFO/GET_PLAN_INFO_SUCCESS';
export const GET_PLAN_INFO_ERROR = 'PLAN_INFO/GET_PLAN_INFO_ERROR';
