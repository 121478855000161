import classnames from 'classnames';
import Ahref from 'components/AHref';
import React, { Component } from 'react';

class Box extends Component {
  render() {
    const { logo, to, onClickBtn } = this.props;

    return (
      <Ahref href={to || 'javascript:void(0)'} onClick={onClickBtn || null}>
        <div className={classnames('box-wrapper', this.props.className)}>
          {logo}
          <h3 className="box-wrapper__title">
            { this.props.title }
          </h3>

          {this.props.desc
          && (
            <div className="box-wrapper__subtitle">
              { this.props.desc }
            </div>
          )
          }
        </div>
      </Ahref>
    );
  }
}

Box.defaultProps = {
  color: 'accent0',
  className: 'flow-modules',
};

export default Box;
