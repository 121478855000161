import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import appMessages from 'utils/messages';
import Card from 'components/Detail/Card';

class Overview extends Component {
  renderContent = () => (
    <>
      <div className="grid-col">
        <div className="overview__label">
          <FormattedMessage {...appMessages.profileId} />
        </div>
        <div className="overview__value" />
        <div className="overview__label">
          <FormattedMessage {...appMessages.createdBy} />
        </div>
        <div className="overview__value" />
        <div className="overview__label">
          <FormattedMessage {...appMessages.createdBy} />
        </div>
        <div className="overview__value" />
      </div>
      <div className="grid-col">
        <div className="overview__label">
          <FormattedMessage {...appMessages.createdAt} />
        </div>
        <div className="overview__value" />
        <div className="overview__label">
          <FormattedMessage {...appMessages.status} />
        </div>
        <div className="overview__value" />
        <div className="overview__label">
          <FormattedMessage {...appMessages.createdBy} />
        </div>
        <div className="overview__value" />
      </div>
    </>
  )

  render() {
    return (
      <Card
        color={this.props.color}
        title="Overview"
        className="overview"
        isLoading
        content={this.renderContent()}
      />
    );
  }
}

Overview.propTypes = {
  color: PropTypes.string,
};

export default Overview;
