import {
  CHANGE_LOCALE,
  SET_LOCALE_LOCALLY,
  SET_WORKSPACE_LOCALE_LOCALLY,
} from './constants';

export function changeLocale(languageLocale) {
  return {
    type: CHANGE_LOCALE,
    locale: languageLocale,
  };
}

export function setLocaleLocally(languageLocale) {
  return {
    type: SET_LOCALE_LOCALLY,
    payload: languageLocale,
  };
}

export function setWorkspaceLocaleLocally(languageLocale) {
  return {
    type: SET_WORKSPACE_LOCALE_LOCALLY,
    payload: languageLocale,
  };
}
