import { createSelector } from 'reselect';

const billing = state => state.billing;

export const getBillingDetails = () => createSelector(billing, state => (state.get('billingDetails')
  ? state.get('billingDetails').toJS()
  : null));

export const getInvoices = () => createSelector(billing, state => (state.get('invoices')
  ? state.get('invoices').toJS()
  : null));

export const getSubscriptionDetails = () => createSelector(billing, state => (state.get('subscriptionDetails')
  ? state.get('subscriptionDetails').toJS()
  : null));

export const getBillingLoadingState = () => createSelector(billing, state => state.get('loading'));

export const getSubscriptionLoadingState = () => createSelector(billing, state => state.get('subscriptionLoader'));
