import React from 'react';
import PropTypes from 'prop-types';
import slugify from 'react-slugify';
import classNames from 'classnames';
import {
  ModuleIcon,
  FlowIcon,
  UserIcon,
  ProfileIcon,
  ModelIcon,
  PlayerIcon,
  DeviceIcon,
  BillingIcon,
  PartnerIcon,
  DeviceSingleIcon,
} from 'images/notifications';
import { CarbonForIbmProduct } from "@carbon/react/icons";

import { DeleteNotificationIcon, CheckedCheckbox } from 'images/common';
import { defaultNotifier } from 'functions/notificationHandler';
import { getDuration, snakeCaseToTitleCase } from 'utils/common';
import {withRouter} from 'withRouter';

const getIcon = {
  module: <ModuleIcon />,
  model_training: <CarbonForIbmProduct className='notification__icon'/>,
  application: <FlowIcon />,
  user: <UserIcon />,
  video: <PlayerIcon />,
  profile: <ProfileIcon />,
  device_image: <DeviceIcon />,
  device: <DeviceSingleIcon />,
  ai_model: <ModelIcon />,
  billing: <BillingIcon />,
  partner: <PartnerIcon />,
};

class NotificationType extends React.Component {
  state = {
    expandCard: false,
    hideNotification: false,
  };

  getType = {
    module: '/modules',
    module: '/model-training',
    model_training: '/experiments',
    application: '/applications',
    profile: '/profiles',
    device_image: '/device-images',
    device: '/device-images',
    ai_model: '/models',
    user: '/settings/user',
    role: '/settings/role',
    billing: '/settings/billing',
    video: '/video',
    dataset: '/dataset',
    partner: '/settings/partner',
  }

  expandNotification = () => {
    const { workspace, history, unread, id, item, readNotification } = this.props;

    if(unread) {
      readNotification({ id });
    }

    if (this.getType[item.category] && ['device_image', 'partner', 'billing',].includes(item.category)) {
      this.props.onClose();

      if (item.message.includes('The new subscription for')) {
        return this.props.router.navigate(`/${slugify(workspace.name)}/settings/billing/manage`);
      }

      return this.props.router.navigate(`/${slugify(workspace.name)}${this.getType[item.category]}`);
    }

    if (item.resourceId && this.getType[item.category]) {
      this.props.onClose();
      this.props.router.navigate(`/${slugify(workspace.name)}${this.getType[item.category]}/${item.resourceId}`);
    }
  };

  updateStatus = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const {
      unread, readNotification, unreadNotification, id,
    } = this.props;

    if (unread) {
      return readNotification({ id });
    }

    return unreadNotification({ id });
  }

  collapseNotification = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { id } = this.props;

    const { data } = await this.props.deleteNotification({ id });

    if (data.errors) {
      return defaultNotifier(data.errors);
    }

    return this.props.getNotificationList('', 'delete');
  };

  getMessage = (item) => {
    if(item.actor && item.category === 'dataset' && (item.message.includes('uniqueness') || item.message.includes('similarity'))) {
      return `${item.message.replace('.', '')} performed by ${item.actor.firstName} ${item.actor.lastName}`
    }

    if(item.actor) {
      return `${item.actor.firstName} ${item.actor.lastName} ${item.message}`
    }

    return item.message
  }

  render() {
    const { type, unread, item, workspace } = this.props;
    const parentClass = classNames('notification', {
      notification_expanded: this.state.expandCard,
      'notification-no-hover': !unread
    });

    if (this.state.hideNotification) {
      return null;
    }

    return (
      <div key={item.id}>
        <div className={parentClass} onClick={this.expandNotification}>
          <div className="notification__header">
            {getIcon[type || 'module']}
            <div className="notification__meta">
              <h5>{snakeCaseToTitleCase(type)}
              {' '}
&nbsp;•&nbsp;
              {getDuration(item.createdAt)}
              </h5>
            </div>
            <div className="notification__icons">
              {unread && <div className="notification__indicator" />}
              <div className="notification__actions">
                <span onClick={this.collapseNotification} title="Delete">
                  <DeleteNotificationIcon />
                </span>
            &nbsp;&nbsp;&nbsp;
                <span
                  className={classNames({
                    [workspace && workspace.contrast]: workspace && workspace.contrast,
                    notification__actions_selected: !unread,
                    notification__actions_unselected: unread,
                  })}
                  onClick={e => this.updateStatus(e)}
                  title={unread ? 'Mark as read' : 'Mark as unread'}
                >
                  <CheckedCheckbox />
                </span>
              </div>
            </div>
          </div>
          <div className="notification__content">
            <>
              <div className={classNames('notification__text', {
                'text-color': unread,
                'label-color': !unread,
              })}
              >
                {this.getMessage(item)}
              </div>
            </>
          </div>
        </div>
        <div className="notifications-bar__hr" />
      </div>
    );
  }
}

NotificationType.propTypes = {
  type: PropTypes.string,
  unread: PropTypes.bool,
};

export default withRouter(NotificationType);
