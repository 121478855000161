import React from 'react';
import PropTypes from 'prop-types';
import slugify from 'react-slugify';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { makeSelectFetchWorkspace } from 'containers/Settings/Workspace/selectors';

const LinkComponent = ({
  children, to, onClick = () => null, workspace = { contrast: 'light' }, className, disableHoverEffect, id, title
}) => (
  <Link
    id={id}
    title={title}
    to={`/${workspace && slugify(workspace.name)}${to}`}
    className={classNames({
      [className]: className,
      [workspace && workspace.contrast]: !disableHoverEffect,
    })}
    onClick={onClick}
  >
    {children}
  </Link>
);

LinkComponent.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  workspace: makeSelectFetchWorkspace(),
});

export default connect(mapStateToProps)(LinkComponent);
