import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { makeSelectFetchWorkspace } from 'containers/Settings/Workspace/selectors';

const Ahref = ({
  children,
  disabled,
  disableHoverEffect,
  onClick = () => null,
  workspace = { contrast: 'light' },
  className,
  target,
  rel,
  id,
  href,
  download,
}) => (
  <a
    href={href || 'javascript:void(0)'}
    disabled={disabled}
    rel={rel}
    id={id}
    download={download}
    target={target}
    className={classNames({
      [className]: className,
      [workspace && workspace.contrast]: !disableHoverEffect,
    })}
    onClick={onClick}
  >
    {children}
  </a>
);

Ahref.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  workspace: makeSelectFetchWorkspace(),
});

export default connect(mapStateToProps)(Ahref);
