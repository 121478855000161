import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { makeSelectFetchWorkspace } from 'containers/Settings/Workspace/selectors';

const Button = ({
  children, disabled,
  onClick = () => null,
  id,
  isLoading,
  dataCy,
  isActive,
  disableHoverEffect,
  color = 'accent0', workspace = { contrast: 'light' }, className, type = 'button',
}) => (
  <button
    id={id}
    onClick={onClick}
    type={type}
    data-cy={dataCy}
    className={classNames('', {
      [`button_${color}`]: color,
      'loading': isLoading,
      'isActive': isActive,
      [className]: className,
      [workspace && workspace.contrast]: !disableHoverEffect,
      button_disabled: disabled || isLoading,
    })}
  >
    {children}
  </button>
);

Button.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  workspace: makeSelectFetchWorkspace(),
});

export default connect(mapStateToProps)(Button);
