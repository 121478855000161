import ProcessPage from 'components/ProcessPage';
import { makeSelectUser } from 'containers/App/selectors';
import ConfirmOrder from 'containers/Billing/components/details/ConfirmOrder';
import LanguageProvider from 'containers/LanguageProvider';
import { registerRequest, selectWorkspaceRequest } from 'containers/Login/actions';
import reducer from 'containers/Login/reducer';
import saga from 'containers/Login/saga';
import { makeSelectLoading } from 'containers/Login/selectors';
import Plan from 'containers/Plans/PlanSelector';
import { makeSelectMe } from 'containers/Settings/User/selectors';
import { createWorkspaceRequest } from 'containers/Settings/Workspace/actions';
import { makeSelectFetchWorkspace, makeSelectLoading as workspaceLoadingSelect } from 'containers/Settings/Workspace/selectors';
import { translationMessages } from 'i18n';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {withRouter } from 'withRouter'
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import messages from 'utils/messages';
import ConfirmationPage from '../../Auth/Register/ConfirmationPage';
import { CONFIRMATION_PAGE, REGISTER_PAGE } from '../../Auth/Register/constants';
import PaymentInfo from '../../Auth/Register/PaymentInfo';
import {
  recoverWorkspaceRequest,
} from './actions';


class Register extends Component {
  state = {
    endpoint: '' || 0,
    isLoading: false,
    workspaceName: '',
    subscription: {},
    planId: null,
    currentPage: REGISTER_PAGE,
    showBillingPage: false,
    showPaymentPage: false,
    showConfirmPage: false,
    showPlanSelectPage: true,
  };

  onUpdatePage = (page) => {
    this.setState({ currentPage: page });
  };

  onClose = () => {
    this.props.onClose();
  };

  onNextWorkspace = () => {
    this.setState({ showBillingPage: true, showPlanSelectPage: false });
  };

  onUpdateInterval = (id, interval) => {
    this.setState({ priceId: id, interval });
  };

  onChangePayment = (field, value) => {
    this.setState({ [field]: value });
  };

  onSelectPlan = (data, endpoint, price) => {
    this.setState({
      planId: data.id,
      subscription: data,
      monthlyPrice: data.monthlyPrice.id,
      showPaymentPage: true,
      showPlanSelectPage: false,
      price,
    });
  }

  onSubmitPayment = async () => {
    const { workspace } = this.props;
    // if (this.state.paymentMethodId && this.state.subscription) {
    // this.setState({isLoading: true})
    // await setPaymentMethod({paymentMethodId: this.state.paymentMethodId})
    // this.setState({isLoading: false})
    const ws = workspace.subDomain;

    this.props.recoverWorkspaceRequest(null, () => {
      this.props.getWorkspace();
      this.props.router.navigate(`/${ws}?activated=true`);
    });
    // }
  }

  onGetPaymentInfo = (data) => {
    if (data.paymentMethod) {
      return this.setState({ paymentMethodId: data.paymentMethod.id });
    }

    return this.setState({ paymentMethodId: '' });
  };

  render() {
    const {
      endpoint,
      price,
      name,
      currentPage,
      showPlanSelectPage,
      showConfirmPage,
      subscription,
      card,
      showPaymentPage,
      workspaceName,
    } = this.state;
    const { user, userData } = this.props;

    if (showConfirmPage) {
      return (
        <ConfirmOrder
          onClose={this.onClose}
          plan={subscription}
          price={price}
          name={name}
          card={card}
          workspace={this.props.workspace}
          workspaceName={workspaceName}
          endpoint={endpoint}
          isLoading={this.state.isLoading}
          data={subscription}
          onBack={this.navigateToPaymentSetting}
          onSubmit={this.onSubmitSubscription}
        />
      );
    }

    // if (this.props.wsLoading) {
    //   return <LoadingIndicator/>
    // }

    if (currentPage === CONFIRMATION_PAGE) {
      return (
        <ConfirmationPage
          onClose={this.onClose}
        />
      );
    }

    if (showPlanSelectPage) {
      return (
        <>
          <Plan onClose={this.onClose} onSelectPlan={this.onSelectPlan} />
        </>
      );
    }

    if (showPaymentPage) {
      return (
        <LanguageProvider messages={translationMessages}>
          <PaymentInfo
            {...this.state}
            recoverWs
            onUpdateInterval={this.onUpdateInterval}
            interval={this.state.interval}
            onChangePayment={this.onChangePayment}
            onGetPaymentInfo={this.onGetPaymentInfo}
            onBack={() => this.setState({
              showPlanSelectPage: true,
              showBillingPage: false,
            })
            }
            subscription={this.state.subscription}
            isLoading={this.state.isLoading}
            workspaceName={workspaceName}
            workspace={this.props.workspace}
            onClose={this.onClose}
            onSubmit={this.onSubmitPayment}
          />
        </LanguageProvider>
      );
    }

    return (
      <ProcessPage onCancel={() => this.onClose()}>
        {() => (
          <div>
            <div className="headline">
              <>
                <h1 className="headline__title center headline_title__register">
                  <FormattedMessage {...messages.workspaceInformation} />
                </h1>
                <h3 className="headline__subtitle">
                  <FormattedMessage {...messages.welcome} />
                  {' '}
                  {user && user.email || userData && userData.email}
                </h3>
              </>
            </div>
          </div>
        )}
      </ProcessPage>
    );
  }
}

Register.propTypes = {
  history: PropTypes.object,
  isLoading: PropTypes.bool,
  isCreateWorkspace: PropTypes.bool,
  createWorkspaceRequest: PropTypes.func,
  registerRequest: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectMe(),
  userData: makeSelectUser(),
  wsLoading: workspaceLoadingSelect(),
  isLoading: makeSelectLoading(),
  workspace: makeSelectFetchWorkspace(),
});

const withConnect = connect(
  mapStateToProps,
  {
    registerRequest, createWorkspaceRequest, selectWorkspaceRequest, recoverWorkspaceRequest,
  },
);

const withReducer = injectReducer({ key: 'login', reducer });
const withSaga = injectSaga({ key: 'login', saga });

export default compose(
  withReducer,
  withSaga,
  withRouter,
  withConnect,
)(Register);
