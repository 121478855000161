import React from 'react';
import PropTypes from 'prop-types';

const Card2 = ({ icon, title, description }) => (
  <div className="card2-image-item device-image-item">
    <div className="card2-image-icon"><img src={icon} width="150" height="115" alt={name} /></div>
    <div className="card2-image-title">{title}</div>
    <div className="card2-image-desc">{description}</div>
  </div>
);

Card2.propTypes = {
  icon: PropTypes.node,
  color: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Card2;
