import { fromJS } from 'immutable';
import { LOGOUT } from 'containers/App/constants';
import { LOGIN_SUCCESS } from 'containers/Login/constants';
import {
  FETCH_WORKSPACE_ERROR,
  FETCH_WORKSPACE_REQUEST,
  FETCH_WORKSPACE_SUCCESS,
  UPLOAD_WORKSPACE_REQUEST,
  UPLOAD_WORKSPACE_SUCCESS,
  UPLOAD_WORKSPACE_ERROR,
  FETCH_USER_WORKSPACE_REQUEST,
  FETCH_USER_WORKSPACE_SUCCESS,
  UPDATE_WORKSPACE_SUCCESS,
  CREATE_WORKSPACE_SUCCESS,
  RECOVER_WORKSPACE_REQUEST,
  RECOVER_WORKSPACE_SUCCESS,
  RECOVER_WORKSPACE_ERROR,
  REMOVE_WORKSPACE_LOGO_REQUEST,
  REMOVE_WORKSPACE_LOGO_SUCCESS,
  GET_SETUP_TRACKER_REQUEST,
  GET_SETUP_TRACKER_SUCCESS,
  GET_SUBSCRIPTION_DETAILS_WS_REQUEST,
  GET_SUBSCRIPTION_DETAILS_WS_SUCCESS,
  UPDATE_SETUP_TRACKER,
} from './constants';

export const initialState = fromJS({
  workspace: null,
  workspaces: [],
  errors: null,
  loading: false,
  loadingTracker: false,
  tracker: null,
  uploading: false,
  subscription: null,
});

function workspaceReducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT:
    case LOGIN_SUCCESS:
      return initialState;
    case UPLOAD_WORKSPACE_REQUEST:
      return state
        .set('hasChanged', false)
        .set('uploading', true);
    case UPLOAD_WORKSPACE_SUCCESS: {
      const workspaces = state.toJS().workspaces
        && state.toJS().workspaces.map((workspace) => {
          if (workspace.id === action.payload.id) {
            return action.payload;
          }
          return workspace;
        });
      return state
        .set('loading', false)
        .set('uploading', false)
        .set('hasChanged', true)
        .set('workspace', action.payload)
        .set('workspaces', workspaces);
    }
    case UPLOAD_WORKSPACE_ERROR: {
      const { workspace } = state.toJS();

      return state
        .set('loading', false)
        .set('uploading', false)
        .set('hasChanged', true)
        .set('workspace', workspace);
    }
    case REMOVE_WORKSPACE_LOGO_REQUEST: {
      return state.set('uploading', true);
    }

    case RECOVER_WORKSPACE_REQUEST: {
      return state
        .set('loading', true);
    }
    case GET_SUBSCRIPTION_DETAILS_WS_REQUEST:
      return state.set('subscription', null);

    case GET_SUBSCRIPTION_DETAILS_WS_SUCCESS:
      return state.set('subscription', action.payload);

    case RECOVER_WORKSPACE_SUCCESS:
    case RECOVER_WORKSPACE_ERROR: {
      return state
        .set('loading', false)
        .set('hasChanged', false);
    }
    case REMOVE_WORKSPACE_LOGO_SUCCESS: {
      const workspaces = state.toJS().workspaces
        && state.toJS().workspaces.map((workspace) => {
          if (workspace.id === action.payload) {
            return Object.assign({}, workspace, { logo: '' });
          }

          return workspace;
        });

      const workspace = Object.assign({}, state.toJS().workspace, { logo: '' });

      return state
        .set('loading', false)
        .set('uploading', false)
        .set('hasChanged', true)
        .set('workspace', workspace)
        .set('workspaces', workspaces);
    }

    case CREATE_WORKSPACE_SUCCESS: {
      const workspaces = [...state.toJS().workspaces, action.payload];

      return state
        .set('workspaces', workspaces)
        .set('workspace', action.payload);
    }
    case FETCH_WORKSPACE_REQUEST:
    case FETCH_USER_WORKSPACE_REQUEST:
      return state
        .set('loading', true)
        .set('hasChanged', false);
    case FETCH_USER_WORKSPACE_SUCCESS:
      return state
        .set('workspaces', action.payload)
        .set('loading', false);
    case FETCH_WORKSPACE_SUCCESS:
      return state
        .set('workspace', action.payload)
        .set('loading', false);

    case UPDATE_SETUP_TRACKER:
    case GET_SETUP_TRACKER_REQUEST:
      return state
        .set('tracker', action.payload)
        .set('loadingTracker', true);

    case GET_SETUP_TRACKER_SUCCESS:
      return state
        .set('tracker', action.payload)
        .set('loadingTracker', false);
    case FETCH_WORKSPACE_ERROR:
      return state
        .set('errors', action.errors)
        .set('loading', false);
    case UPDATE_WORKSPACE_SUCCESS:
      return state.set('workspace', action.payload);
    default:
      return state;
  }
}

export default workspaceReducer;
