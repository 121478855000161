import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import DescendingIcon from 'images/common/sort-desc';
import AscendingIcon from 'images/common/sort-asc';

export default class SortableTh extends Component {
  clickHandler = () => {
    const { sortKey, currentSortKey, currentSortDir } = this.props;
    if (currentSortKey === sortKey) {
      const direction = currentSortDir === 'asc' ? 'desc' : 'asc';

      this.props.onUpdateSort({ key: sortKey, direction });
    } else {
      this.props.onUpdateSort({ key: sortKey, direction: 'asc' });
    }
  }

  render() {
    const {
      currentSortDir, currentSortKey, label, sortKey,
    } = this.props;
    const isActive = currentSortKey === sortKey;

    const icon = currentSortDir === 'desc' ? <DescendingIcon /> : <AscendingIcon />;
    const sortClass = classNames('data-table__heading', { 'data-table__heading_sorted': isActive });
    return (
      <th onClick={this.clickHandler} className="data-table__th">
        <div className={sortClass}>
          <span>{label}</span>
          {' '}
          {icon}
        </div>
      </th>
    );
  }
}

SortableTh.propTypes = {
  currentSortKey: PropTypes.string,
  currentSortDir: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  sortKey: PropTypes.string,
  onUpdateSort: PropTypes.func.isRequired,
};
