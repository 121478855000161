import { createSelector } from 'reselect';

const workspaceSelector = state => state.workspace;

export const makeSelectFetchWorkspace = () => createSelector(
  workspaceSelector,
  state => state && state.get('workspace'),
);

export const makeSelectFetchSubscription = () => createSelector(
  workspaceSelector,
  state => state && state.get('subscription'),
);

export const getTrackerInfo = () => createSelector(
  workspaceSelector,
  state => state && state.get('tracker'),
);

export const makeSelectError = () => createSelector(
  workspaceSelector,
  state => state.get('errors'),
);

export const makeSelectLoading = () => createSelector(
  workspaceSelector,
  state => state.get('loading'),
);

export const makeSelectLoadingTracker = () => createSelector(
  workspaceSelector,
  state => state.get('loadingTracker'),
);

export const makeSelectUpdateWorkspace = () => createSelector(
  workspaceSelector,
  state => state.get('updateWorkspace'),
);

export const makeSelectUserWorkspace = () => createSelector(
  workspaceSelector,
  state => state && state.get('workspaces'),
);

export const makeUploaderWorkspace = () => createSelector(
  workspaceSelector,
  state => state.get('uploading'),
);

export const makeHasChangedWorkspace = () => createSelector(
  workspaceSelector,
  state => state.get('hasChanged'),
);
