export const template = `<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8" />
    <title>{{text.invoiceTitle}}</title>
    <style>
      /*!
         * https://www.sparksuite.com/open-source/invoice.html
         * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
         */
      @media only screen and (max-width: 600px) {
        .invoice-box table tr.top table td {
          width: 100%;
          display: block;
          text-align: center;
        }
        .invoice-box table tr.information table td {
          width: 100%;
          display: block;
          text-align: center;
        }
      }
      .rtl {
        direction: rtl;
        font-family: Tahoma, "Helvetica Neue", Helvetica, Helvetica, Arial,
          sans-serif;
      }
      .rtl table {
        text-align: right;
      }
      .rtl table tr td:nth-child(3) {
        text-align: left;
      }
    </style>
  </head>
  <body>
  <div class='invoice-box-wrapper'>
    <div class="invoice-box" id='invoice-box'>
      <table cellpadding="0" cellspacing="0">
        <tr class="top">
          <td colspan="3">
            <table>
              <tr>
                <td class="title">
                <img src={{text.logo}} />
                </td>
                <td></td>
                <td class='header'>
                <span class='invoice-type'>ESTIMATION</span>
                <span class='invoice-br'></span>
                <span class='invoice-br1'>{{text.invoiceCreated}}{{invoice.formattedInvoiceCreatedDate}}</span>
                <span class='invoice-br1'>{{text.invoiceDue}}{{invoice.formattedInvoiceDueDate}}</span>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr class="information">
          <td colspan="3">
            <table>
              <tr>
                <td class='header'>
                  <span class='b-header invoice-br1'>
                    Bill to
                  </span>
                  <span class='invoice-br1'>{{text.companyName}}</span>
                  <span class='invoice-br1'>{{text.companyAddress}}</span>
                  <span class='invoice-br1'>{{#text.companyAddress1}}{{text.companyAddress1}}<br />{{/text.companyAddress1}}</span>
                  <span class='invoice-br1'>{{text.companyCountry}}</span>
                </td>
                <td></td>
                <td class='header'>
                  <span class='b-header invoice-br1'>
                    Contact person
                  </span>
                  <span class='invoice-br1'>{{account.contactName}}</span>
                  <span class='invoice-br1'>{{account.contactEmail}}</span>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr class="heading">
          <td class="b-header">{{text.invoiceCreationPaymentMethod}}</td>
          <td></td>
          <td></td>
        </tr>
        <tr class="spacer-15"></tr>

        <tr class="details">
          <td>{{text.invoiceCreationAdditionalMessage}}</td>
          <td></td>
          <td></td>
        </tr>
        <tr class="details">
        <td >{{text.invoiceCreationTermsMessage}}</td>
        <td></td>
        <td></td>
      </tr>
      <tr class="spacer-15"></tr>
        <tr class="heading">
          <td class="b-header">Product</td>
          <td class="b-header w-100">Quantity</td>
          <td class="b-header">Total</td>
        </tr>
        {{#invoice.items}}
        <tr class="item last">
        <td>
        <div class="b-header">{{name}}</div>
        <div>{{description}}</div>
        Period: {{startDate}}{{#endDate}} - {{endDate}}{{/endDate}}
        <td>{{quantity}}</td>
        </td>
          <td>{{amount}}</td>
        </tr>
        {{/invoice.items}}
        <tr class="spacer-15"></tr>
        <tr class="total mx-t-10">
          <td></td>
          <td></td>
          <td>{{text.invoiceAmount}}{{invoice.formattedChargedAmount}}</td>
        </tr>
        <tr class="total">
          <td></td>
          <td></td>
          <td class='invoice-td-max-width'>{{text.invoiceAmountPaid}}{{invoice.formattedPaidAmount}}</td>
        </tr>
        <tr class="total">
          <td></td>
          <td></td>
          <td class='invoice-td-max-width b-header'>{{text.invoiceBalance}}{{invoice.formattedBalance}}</td>
        </tr>
      </table>
      <div class='billing-viso'>
        <table>
        <tr class="information">
            <td colspan="3">
              <table>
                <tr>
                  <td class='header'>
                  <span class='b-header'>viso.ai AG</span><br/>
                    Industriestrasse 2<br />
                    8207 Schaffhausen<br />
                    Switzerland
                  </td>
                  <td class='empty'></td>
                  <td class='header'>
                  <span class='b-header'>Payment details</span><br/>
                  <span>Name: viso.ai</span><br/>
                  <span>Bank: Clientis BS Bank Schaffhausen</span><br/>
                  <span>IBAN: CH11 0685 8565 2695 1368 0</span><br/>
                  <span>BIC: RBABCH22858</span><br/>
                  <span>VAT: CHE-349.676.186 MWST</span><br/>
                  <span>Reference: {{invoice.invoiceNumber}}</span><br/>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </div>
    </div>
  </body>
</html>
`;
