import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {withRouter } from 'withRouter'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import classNames from 'classnames';
import TableTags from 'components/List/TableTags';
import slugify from 'react-slugify';
import { FormattedMessage } from 'react-intl';

import AHref from 'components/AHref';
import { dateFormatter, isRoleHigherThan } from 'utils/common';
import SortableRow from 'components/Table/SortableRow';
import { routeMapping } from 'routes';
import { isPermitted, getPermission } from 'utils/permissions';
import { getDetailRole } from 'containers/Settings/AccessRole/selectors';
import messages from 'utils/messages';
import {
  KeyIcon,
  DeactivateIcon,
} from 'images/common';
import Menu from './SingleMenu';
import { getInitials, isArrayEqual } from 'utils/common';

class UserRow extends PureComponent {
  onRedirectToRole = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    const { workspace } = this.props;

    this.props.router.navigate(`/${workspace && slugify(workspace.name)}${routeMapping.SETTINGS_ROLE.path}/${id}`);
  };

  getRoleValue = () => {
    const { role, colorClassName, preventRedirectOnClick } = this.props;
    const isRoleView = isPermitted({ id: 'role' }, this.props.detailRole);

    if (preventRedirectOnClick || !isRoleView) {
      return <span>{this.props.role.name}</span>;
    }

    return (
      <AHref onClick={e => this.onRedirectToRole(e, role.id)} className={`text-link ${colorClassName}`}>
        {this.props.role.name}
      </AHref>
    );
  }

  get2FAStatus = (payload) => {
    const { currentSortKey } = this.props;
    const className = classNames('data-table__status type__value text-captilize',
      {
        'data-table__td_dark': currentSortKey === 'twoFactorEnabled',
        'data-table__status_pos': payload,
        'data-table__status_neg': !payload,
      });

    return (
      <div className={className}>{payload ? 'Enabled' : 'Disabled'}</div>
    );
  }

  getRowRender = () => {
    const {
      firstName,
      lastName,
      updatedAt,
      email,
      demoData,
      lastLoginAt, id, workspace, currentSortKey, tags, colorClassName,
    } = this.props;
    const isPrimaryOwner = workspace && workspace.createdBy && (id === workspace.createdBy.id);

    const fullName = `${firstName || ''} ${lastName || ''}`;

    const firstNameClass = classNames({
      'data-table__td_dark': currentSortKey === 'firstName',
    });

    const statusClass = classNames('type__value text-capitalize', {
      'data-table__td_dark': currentSortKey === 'status',
    });

    const emailAddressClass = classNames('no-capitalize', {
      'data-table__td_dark': currentSortKey === 'email',
    });

    const lastLoginAtClass = classNames({
      'data-table__td_dark': currentSortKey === 'lastLoginAt',
    });

    const updatedAtClass = classNames({
      'data-table__td_dark': currentSortKey === 'updatedAt',
    });

    return {
      email: () => <div className={emailAddressClass}>{ email }</div>,
      firstName: () => (
        <div className={firstNameClass} title={fullName}>
          {fullName} 
          {' '}
          {isPrimaryOwner && (
            <span className="workspace_owner_tag">
              <FormattedMessage {...messages.cPrimaryOwner} />
            </span>
          )}
        </div>
      ),
      status: () => <span className={statusClass}>{this.props.status && this.props.status.toLowerCase() || '-'}</span>,
      role: () => this.getRoleValue(),
      lastLoginAt: () => (lastLoginAt ? <div className={lastLoginAtClass}>{dateFormatter(lastLoginAt, true)}</div> : '-'),
      twoFactorEnabled: () => this.get2FAStatus(),
      updatedAt: () => <div className={updatedAtClass}>{dateFormatter(updatedAt, true)}</div>,
      tags: () => tags && (
        <TableTags
          tags={tags}
          colorClassName={colorClassName}
        />
      ),
    };
  }

  render() {
    const {
      colorClassName, workspace, id, detailRole, role,
    } = this.props;
    const isPrimaryOwner = workspace && workspace.createdBy && (id === workspace.createdBy.id);
    const isRoleView = isPermitted({ id: 'role' }, this.props.detailRole);
    const permission = getPermission('permissionUsers', this.props.detailRole.role);
    const hasAccess = detailRole && role && isRoleHigherThan(detailRole.role, role);

    return (
      <>
        <SortableRow
          {...this.props}
          disabledSelection
          colorClassName={colorClassName}
          columns={this.props.columns}
          customRender={this.getRowRender()}
          appendToSetting={(
            <div
              className="actions actions_update actions_icon"
            >
              <span
                className={classNames('actions_icon_item', {
                  [`actions_icon_item_${colorClassName}`]: colorClassName,
                  actions_icon_disabled: this.props.status !== 'active' || id === this.props.loggedUserId || isPrimaryOwner || !permission.includes('delete') || !hasAccess,
                })}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (!(this.props.status !== 'active' || id === this.props.loggedUserId || isPrimaryOwner || !hasAccess)) {
                    if (permission.includes('delete')) {
                      this.props.onDeactivate(id, e);
                    }
                  }
                }}
              >
                <p className="custom-tooltip setting-tooltip">
                  <FormattedMessage {...messages.deactivateUser} />
                </p>
                <span>
                  <DeactivateIcon />
                </span>
              </span>
              <span
                className={classNames('actions_icon_item', {
                  [`actions_icon_item_${colorClassName}`]: colorClassName,
                  actions_icon_disabled: isPrimaryOwner || !isRoleView || !hasAccess,
                })}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (!isPrimaryOwner && isRoleView && hasAccess) {
                    this.props.onAssignRole(id, e);
                  }
                }}
              >
                <p className="custom-tooltip setting-tooltip">
                  <FormattedMessage {...messages.assignUserRole} />
                </p>
                <span>
                  <KeyIcon />
                </span>
              </span>
            </div>
          )}
        >
          <Menu
            id={this.props.id}
            hasAccess={hasAccess}
            isPrimaryOwner={isPrimaryOwner}
            loggedUserId={this.props.loggedUserId}
            onAssignRole={this.props.onAssignRole}
            status={this.props.status}
            hasPermissionToUpdate
            onDelete={this.props.onDelete}
            detailRole={this.props.detailRole.role}
            onUpdate={this.props.onUpdate}
            onArchive={this.props.onArchive}
            onDeactivate={this.props.onDeactivate}
            onActivate={this.props.onActivate}
          />
        </SortableRow>
      </>
    );
  }
}

UserRow.propTypes = {
  inputType: PropTypes.string,
  history: PropTypes.object.isRequired,
  columns: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
  detailRole: getDetailRole(),
});

const withConnect = connect(
  mapStateToProps, null,
);

export default withConnect(withRouter(UserRow));
