import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {withRouter } from 'withRouter'

import Modal from 'components/Modal';
import LoginForm from 'containers/Auth/Login/Form';
import { routeMapping } from 'routes';
import ProcessPage from 'components/ProcessPage';
import appMessages from 'utils/messages';

class DeactivatedWorkspace extends Component {
  onClose = () => {
    this.props.router.navigate(routeMapping.LOGIN.path);
  }

  renderModal = () => {
    const { onClose } = this;

    const buttons = [
      <button className="modal__cancel" key="cancel" onClick={onClose}>
        <FormattedMessage {...appMessages.cancel} />
      </button>,
    ];

    return (
      <Modal
        buttons={buttons}
        title={<FormattedMessage {...appMessages.workspaceAccessDenied} />}
        body={<FormattedMessage {...appMessages.cantAccessWorkspace} />}
        onClose={onClose}
      />
    );
  };

  render() {
    return (
      <>
        <ProcessPage>
          {() => (
            <div>
              <div className="headline">
                <h1 className="headline__title center headline_title__register">Viso Suite</h1>
                <h3 className="headline__subtitle"><FormattedMessage {...appMessages.loginAccount} /></h3>
              </div>
              <div className="login">
                <LoginForm onSubmit={this.onLogin} />
              </div>
            </div>
          )}
        </ProcessPage>
        {this.renderModal()}
      </>
    );
  }
}

DeactivatedWorkspace.propTypes = {};

export default withRouter(DeactivatedWorkspace);
