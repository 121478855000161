import React from 'react';

export default () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M13.0105 11.1311L16.8421 4.50376V16.0322H0V0.874291H1.68421V11.4343L6.31579 3.40061L11.7895 6.58376L15.36 0.411133L16.8168 1.25324L12.4126 8.87429L6.93053 5.7164L1.94526 14.348H3.84842L7.54526 7.98166L13.0105 11.1311V11.1311Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="16.8421"
          height="16"
          fill="white"
          transform="translate(0 0.0322266)"
        />
      </clipPath>
    </defs>
  </svg>
);
