import classNames from 'classnames';
import AHref from 'components/AHref';
import Link from 'components/Link';
import PropTypes from 'prop-types';
import React from 'react';

const ActionIcon = ({
  id = 'menu', to, icon, onClick, text, color, type, customClass, disabled, isLoading
}) => {
  if (type === 'link') {
    return (
      <Link
        to={to}
        disabled={disabled}
        id={id}
        disableHoverEffect
        className={classNames('actions-nav__link actions-nav__target', {
          actions__option_disabled: disabled || isLoading,
        'loading' : isLoading,
          [color]: color,
        })}
      >
        <span
          className="action-icon"
          id={id}
        >
          <div className="action_icon_item">
            {icon}
          </div>
          <div className="action-icon-text">
            {text}
          </div>
        </span>
      </Link>
    );
  }

  return (
    <AHref
      id={id}
      onClick={onClick}
      disableHoverEffect
      className={classNames('actions-nav__link label-color', {
        [color]: color,
        'loading' : isLoading,
        actions__option_disabled: disabled || isLoading,
        [customClass]: customClass,
      })}
    >
      <span className="action-icon">
        <div className="action_icon_item">
          {icon}
        </div>
        <div className="action-icon-text">
          {text}
        </div>
      </span>
    </AHref>
  );
};

ActionIcon.propTypes = {
  color: PropTypes.string,
};

ActionIcon.defaultProps = {
  color: 'accent0',
};

export default ActionIcon;
