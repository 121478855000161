import classNames from 'classnames';
import AHref from 'components/AHref';
import { makeSelectFetchWorkspace } from 'containers/Settings/Workspace/selectors';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  CloseIcon,
} from 'images/common';

const Banner = ({
  icon, title, desc, link, action, color, onClose, isPrimary,
}) => (

  <div className={classNames('banner-wrapper', {
    [`banner-wrapper_${color}`]: color,
    'banner-primary': isPrimary
  })}
  >
    <div className="banner-container">
      {icon && <span className="banner-wrapper_icon">{icon}</span>}
      {title && (
        <span className="banner-wrapper_title">
          {title}
&nbsp;
        </span>
      )}
      <span className="banner-wrapper_desc">
        {desc}
        {' '}
        {link && (
          <AHref
            onClick={action}
            className={classNames('banner-wrapper_action_link', {
              [color]: color,
            })}
          >
            {link}
          </AHref>
        )
        }
      </span>
      {onClose && (
        <div className="close-icon" onClick={onClose}>
          <CloseIcon />
        </div>
      )
      }
    </div>
  </div>
);

Banner.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  workspace: makeSelectFetchWorkspace(),
});

export default connect(mapStateToProps)(Banner);
