import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';

const MAX_TAGS_COUNT = 2;
const DISPLAY_TAG_LENGHT = 15;
const DISPLAY_TAG_ICON_LENGTH = 15;

class TableTags extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validTags: [],
      restTags: [],
    };

    this.tagsRef = React.createRef();
  }

  // eslint-disable-next-line
  componentWillReceiveProps(nextProps) {
    const { icon } = nextProps;
    const validTags = [];
    const restTags = [];
    let allWords = '';

    nextProps.tags.forEach((tag) => {
      allWords = allWords.concat(tag);
      if (allWords.length >= (icon ? DISPLAY_TAG_ICON_LENGTH : DISPLAY_TAG_LENGHT)) {
        restTags.push(tag);
      }

      if (allWords.length < (icon ? DISPLAY_TAG_ICON_LENGTH : DISPLAY_TAG_LENGHT)) {
        validTags.push(tag);
      }

      if (tag.length < (icon ? DISPLAY_TAG_ICON_LENGTH : DISPLAY_TAG_LENGHT) && !validTags.length) {
        validTags.push(tag);
      }
    });

    this.setState({ validTags, restTags });
  }

  componentDidMount() {
    const { icon } = this.props;
    const validTags = [];
    const restTags = [];
    let allWords = '';

    this.props.tags.forEach((tag) => {
      allWords = allWords.concat(tag);
      if (allWords.length >= (icon ? DISPLAY_TAG_ICON_LENGTH : DISPLAY_TAG_LENGHT)) {
        restTags.push(tag);
      }

      if (allWords.length < (icon ? DISPLAY_TAG_ICON_LENGTH : DISPLAY_TAG_LENGHT)) {
        validTags.push(tag);
      }

      if (tag.length < (icon ? DISPLAY_TAG_ICON_LENGTH : DISPLAY_TAG_LENGHT) && !validTags.length) {
        validTags.push(tag);
      }
    });

    this.setState({ validTags, restTags });
  }

  getExtraTagsLength = () => this.getExtraTags().length

  getExtraTags = () => this.props.tags.slice(MAX_TAGS_COUNT)

  renderExtraTags = restTags =>
  // const extraTagsLength = this.getExtraTagsLength()
  // const extraTags = this.getExtraTags()

    (
      <>
        <div className="tags__count" ref={this.tagsRef}>
          +
          {restTags.length}
        </div>
        <UncontrolledPopover trigger="hover" target={this.tagsRef} className="tags-popover">
          <PopoverBody>
            { restTags.map(extraTag => (
              <div className="tag-popover" key={extraTag}>{ extraTag }</div>
            ))}
          </PopoverBody>
        </UncontrolledPopover>
      </>
    )


  render() {
    const { icon } = this.props;
    const { validTags, restTags } = this.state;

    return (
      <div className={classNames('tags ellipsis', { [`tags_${this.props.colorClassName}`]: this.props.colorClassName })}>
        { validTags.map((tag) => {
          if (icon) {
            return (
              <div key={tag} className="tags__item">
                {icon}
                {' '}
                { tag }
              </div>
            );
          }
          return <div key={tag} className="tags__item">{ tag }</div>;
        })}
        { restTags.length > 0 && this.renderExtraTags(restTags) }
      </div>
    );
  }
}

TableTags.propTypes = {
  tags: PropTypes.array,
  icon: PropTypes.object,
  colorClassName: PropTypes.string,
};

TableTags.deaultProps = {
  tags: [],
};

export default TableTags;
