export const FETCH_WORKSPACE_REQUEST = 'WORKSPACE/FETCH_WORKSPACE_REQUEST';
export const FETCH_WORKSPACE_SUCCESS = 'WORKSPACE/FETCH_WORKSPACE_SUCCESS';
export const FETCH_WORKSPACE_ERROR = 'WORKSPACE/FETCH_WORKSPACE_ERROR';

export const GET_SETUP_TRACKER_REQUEST = 'WORKSPACE/GET_SETUP_TRACKER_REQUEST';
export const GET_SETUP_TRACKER_SUCCESS = 'WORKSPACE/GET_SETUP_TRACKER_SUCCESS';
export const GET_SETUP_TRACKER_ERROR = 'WORKSPACE/GET_SETUP_TRACKER_ERROR';

export const UPDATE_SETUP_TRACKER_REQUEST = 'WORKSPACE/UPDATE_SETUP_TRACKER_REQUEST';

export const FETCH_WORKSPACE_DETAIL_REQUEST = 'WORKSPACE/FETCH_WORKSPACE_DETAIL_REQUEST';

export const FETCH_USER_WORKSPACE_REQUEST = 'WORKSPACE/FETCH_USER_WORKSPACE_REQUEST';
export const FETCH_USER_WORKSPACE_SUCCESS = 'WORKSPACE/FETCH_USER_WORKSPACE_SUCCESS';

export const CREATE_WORKSPACE_REQUEST = 'WORKSPACE/CREATE_WORKSPACE_REQUEST';
export const CREATE_WORKSPACE_SUCCESS = 'WORKSPACE/CREATE_WORKSPACE_SUCCESS';

export const UPDATE_WORKSPACE_REQUEST = 'WORKSPACE/UPDATE_WORKSPACE_REQUEST';
export const UPDATE_WORKSPACE_SUCCESS = 'WORKSPACE/UPDATE_WORKSPACE_SUCCESS';
export const UPDATE_WORKSPACE_ERROR = 'WORKSPACE/UPDATE_WORKSPACE_ERROR';

export const MANAGE_WORKSPACE_REQUEST = 'WORKSPACE/MANAGE_WORKSPACE_REQUEST';
export const MANAGE_WORKSPACE_SUCCESS = 'WORKSPACE/MANAGE_WORKSPACE_SUCCESS';
export const MANAGE_WORKSPACE_ERROR = 'WORKSPACE/MANAGE_WORKSPACE_ERROR';

export const EDIT_WORKSPACE_INFORMATION_FORM = 'EDIT_WORKSPACE_INFORMATION_FORM';
export const EDIT_WORKSPACE_SETTINGS_FORM = 'EDIT_WORKSPACE_SETTINGS_FORM';
export const EDIT_WORKSPACE_CUSTOMIZATION_FORM = 'EDIT_WORKSPACE_CUSTOMIZATION_FORM';

export const UPLOAD_WORKSPACE_REQUEST = 'WORKSPACE/UPLOAD_WORKSPACE_REQUEST';
export const UPLOAD_WORKSPACE_SUCCESS = 'WORKSPACE/UPLOAD_WORKSPACE_SUCCESS';
export const UPLOAD_WORKSPACE_ERROR = 'WORKSPACE/UPLOAD_WORKSPACE_ERROR';

export const REMOVE_WORKSPACE_LOGO_REQUEST = 'WORKSPACE/REMOVE_WORKSPACE_LOGO_REQUEST';
export const REMOVE_WORKSPACE_LOGO_SUCCESS = 'WORKSPACE/REMOVE_WORKSPACE_LOGO_SUCCESS';
export const REMOVE_WORKSPACE_LOGO_ERROR = 'WORKSPACE/REMOVE_WORKSPACE_LOGO_ERROR';

export const SCHEDULE_WORKSPACE_REQUEST = 'WORKSPACE/SCHEDULE_WORKSPACE_REQUEST';
export const SCHEDULE_WORKSPACE_SUCCESS = 'WORKSPACE/SCHEDULE_WORKSPACE_SUCCESS';
export const SCHEDULE_WORKSPACE_ERROR = 'WORKSPACE/SCHEDULE_WORKSPACE_ERROR';

export const RECOVER_WORKSPACE_REQUEST = 'WORKSPACE/RECOVER_WORKSPACE_REQUEST';
export const RECOVER_WORKSPACE_SUCCESS = 'WORKSPACE/RECOVER_WORKSPACE_SUCCESS';
export const RECOVER_WORKSPACE_ERROR = 'WORKSPACE/RECOVER_WORKSPACE_ERROR';

export const GET_SUBSCRIPTION_DETAILS_WS_REQUEST = 'GET_SUBSCRIPTION_DETAILS_WS_REQUEST';
export const GET_SUBSCRIPTION_DETAILS_WS_ERROR = 'GET_SUBSCRIPTION_DETAILS_WS_ERROR';
export const GET_SUBSCRIPTION_DETAILS_WS_SUCCESS = 'GET_SUBSCRIPTION_DETAILS_WS_SUCCESS';

export const UPDATE_SETUP_TRACKER = 'UPDATE_SETUP_TRACKER';

export const CONTRAST_LIST = [{ value: 'light', label: 'Light' }, { value: 'dark', label: 'Dark' }];

export const SIZE_LIST = [
  { value: '1', label: '1' },
  { value: '2-9', label: '2-9' },
  { value: '10-19', label: '10-19' },
  { value: '20-49', label: '20-49' },
  { value: '59-99', label: '59-99' },
  { value: '100-199', label: '100-199' },
  { value: '200-349', label: '200-349' },
  { value: '350-999', label: '350-999' },
  { value: '1000-2999', label: '1000-2999' },
  { value: '3000+', label: '3000+' },
];

export const INDUSTRIES_LIST = [
  { value: 'Agriculture', label: 'Agriculture' },
  { value: 'Education', label: 'Education' },
  { value: 'Energy & Utilities', label: 'Energy & Utilities' },
  { value: 'Financial Services', label: 'Financial Services' },
  { value: 'Government', label: 'Government' },
  { value: 'Healthcare & Life Sciences', label: 'Healthcare & Life Sciences' },
  { value: 'Manufacturing', label: 'Manufacturing' },
  { value: 'Media & Entertainment', label: 'Media & Entertainment' },
  { value: 'Non-profit', label: 'Non-profit' },
  { value: 'Professional & Business Services', label: 'Professional & Business Services' },
  { value: 'Retail & Wholesale', label: 'Retail & Wholesale' },
  { value: 'Technology', label: 'Technology' },
  { value: 'Transportation & Logistics', label: 'Transportation & Logistics' },
  { value: 'Other', label: 'Other' },
];

export const THEME_COLOR_LIST = [
  { value: 'viso', label: 'Viso Default' },
  { value: 'earth', label: 'Earth' },
  { value: 'vivid', label: 'Vivid' },
  { value: 'clean', label: 'Clean' },
  { value: 'ocean', label: 'Ocean' },
  { value: 'forrest', label: 'Forrest' },
];
