import { sortBy } from 'lodash';

import {
  addRole, assignRoleToUser,
  assignRoleToUsers, deleteRole, getAllPermissions, getAllRole, getAllRoles, updateRole,
} from 'api/roles';
import { intl } from 'containers/LanguageProvider/intlProvider';
import { makeSelectFetchWorkspace } from 'containers/Settings/Workspace/selectors';
import { defaultNotifier, successNotifier, updateNotifier } from 'functions/notificationHandler';
import slugify from 'react-slugify';
import {
  call,
  put,
  select, takeLatest,
} from 'redux-saga/effects';
import messages from 'utils/messages';
import {
  addRoleError, addRoleSuccess, addRoleToUsersRequest, deleteRoleError, deleteRoleSuccess,
  detailRoleError, detailRoleSuccess, individualRoleError,
  individualRoleSuccess, listPermissionError, listPermissionSuccess,
  listRoleError, listRoleSuccess, updateRoleError, updateRoleSuccess,
} from './actions';
import {
  ADD_ROLE_REQUEST, ADD_ROLE_TO_USERS_REQUEST,
  ADD_ROLE_TO_USER_REQUEST, DELETE_ROLE_REQUEST,
  DETAIL_ROLE_REQUEST, INDIVIDUAL_ROLE_REQUEST,
  LIST_PERMISSION_REQUEST, LIST_ROLE_REQUEST, UPDATE_ROLE_REQUEST,
} from './constants';


function* listRoleSaga(action) {
  try {
    const { payload } = action;

    const response = yield call(getAllRoles, payload);
    if (response.data.errors) {
      yield put(listRoleError(response.data.errors));
      defaultNotifier(response.data.errors);
    } else {
      let list = response.data.data.roles.map((role) => {
        if (role.name === 'Viso Support' || role.name === 'Owner') {
          return { ...role, enabled: true };
        }

        return role;
      });

      list = sortBy(list, ['enabled']);

      yield put(listRoleSuccess(list));
    }
  } catch (err) {
    yield put(listRoleError(err));
  }
}

function* detailRoleSaga(action) {
  try {
    const { payload } = action;
    const response = yield call(getAllRole, payload);

    if (response.data.errors) {
      yield put(detailRoleError(response.data.errors));
      defaultNotifier(response.data.errors);
    } else {
      yield put(detailRoleSuccess(response.data.data.role));
    }
  } catch (err) {
    yield put(detailRoleError(err));
  }
}


function* individualRoleSaga(action) {
  try {
    const { payload } = action;
    const response = yield call(getAllRole, payload);

    if (response.data.errors) {
      yield put(individualRoleError(response.data.errors));
      defaultNotifier(response.data.errors);
    } else {
      yield put(individualRoleSuccess(response.data.data.role));
    }
  } catch (err) {
    defaultNotifier('Error fetching the role detail.');
  }
}

function* addRoleSaga(action) {
  try {
    const { payload, meta } = action;
    const response = yield call(addRole, payload);
    if (response.data.errors) {
      yield put(addRoleError(response.data.errors));
      defaultNotifier(response.data.errors);
    } else {
      yield put(addRoleSuccess(response.data.data.createRole));

      const workspace = yield select(makeSelectFetchWorkspace());

      if (meta.userIds && meta.userIds.length) {
        yield put(addRoleToUsersRequest({ userIds: meta.userIds, roleId: response.data.data.createRole.id }));
      }

      if (meta.onCallback) {
        meta.onCallback();
      }

      successNotifier('New role added successfully', `/${workspace && slugify(workspace.name)}/settings/role/${response.data.data.createRole.id}`);
    }
  } catch (err) {
    yield put(addRoleError(err));
  }
}

function* assignRoleToUserSaga(action) {
  try {
    const { payload, meta } = action;
    const response = yield call(assignRoleToUser, payload);

    if (response.data.errors) {
      yield put(addRoleError(response.data.errors));

      return defaultNotifier(response.data.errors);
    }
    yield put(addRoleSuccess(response.data.data.assignRoleToUser));

    if (meta.onCallback) {
      meta.onCallback();
    }
    defaultNotifier(intl.formatMessage({ ...messages.roleChangesSaved }));
  } catch (err) {
    defaultNotifier('Server error while assigning the user.');
  }
}

function* assignRoleToUsersSaga(action) {
  try {
    const { payload, meta } = action;
    const response = yield call(assignRoleToUsers, payload);

    if (response.data.errors) {
      yield put(addRoleError(response.data.errors));
      return defaultNotifier(response.data.errors);
    }
    yield put(addRoleSuccess(response.data.data.assignRoleToUsers));
    if (meta.onCallback) {
      meta.onCallback();
    }

    if (response.data.data.assignRoleToUsers.skippedCount) {
      if (response.data.data.assignRoleToUsers.skippedCount === 1 && payload.userIds.length === 1) {
        return defaultNotifier(`${response.data.data.assignRoleToUsers.skippedCount}
         ${intl.formatMessage({ ...messages.usersWereSkipped })}`);
      }
      return defaultNotifier(`${intl.formatMessage({ ...messages.roleAssignedSuccessfully })} 
      ${response.data.data.assignRoleToUsers.skippedCount} ${intl.formatMessage({ ...messages.usersWereSkipped })}`);
    }
    return defaultNotifier(intl.formatMessage({ ...messages.roleAssignedSuccessfully }));
  } catch (err) {
    defaultNotifier('Server error while assigning the users.');
  }
}

function* deleteRoleSaga(action) {
  try {
    const { payload } = action;
    const response = yield call(deleteRole, payload);
    if (response.data.errors) {
      yield put(deleteRoleError(response.data.errors));
      defaultNotifier(response.data.errors);
    } else {
      defaultNotifier(intl.formatMessage({ ...messages.roleDeletedSuccessfully }));
      yield put(deleteRoleSuccess(response.data.data.deleteRole));
    }
  } catch (err) {
    yield put(deleteRoleError(err));
  }
}

function* updateRoleSaga(action) {
  try {
    const { payload, meta } = action;
    const response = yield call(updateRole, payload);

    if (response.data.errors) {
      yield put(updateRoleError(response.data.errors));
      defaultNotifier(response.data.errors);
    } else {
      yield put(updateRoleSuccess(response.data.data.updateRole));
      updateNotifier(meta || intl.formatMessage({ ...messages.roleChangesSaved }));
    }
  } catch (err) {
    yield put(updateRoleError(err));
  }
}

function* listPermissionsSaga(action) {
  try {
    const { payload } = action;
    const response = yield call(getAllPermissions, payload);
    if (response.data.errors) {
      yield put(listPermissionError(response.data.errors));
      defaultNotifier(response.data.errors);
    } else {
      yield put(listPermissionSuccess(response.data.data.permissions));
    }
  } catch (err) {
    yield put(listPermissionError(err));
  }
}

export default function* roleRootSaga() {
  yield takeLatest(LIST_ROLE_REQUEST, listRoleSaga);
  yield takeLatest(DETAIL_ROLE_REQUEST, detailRoleSaga);
  yield takeLatest(INDIVIDUAL_ROLE_REQUEST, individualRoleSaga);
  yield takeLatest(ADD_ROLE_REQUEST, addRoleSaga);
  yield takeLatest(DELETE_ROLE_REQUEST, deleteRoleSaga);
  yield takeLatest(UPDATE_ROLE_REQUEST, updateRoleSaga);
  yield takeLatest(ADD_ROLE_TO_USER_REQUEST, assignRoleToUserSaga);
  yield takeLatest(ADD_ROLE_TO_USERS_REQUEST, assignRoleToUsersSaga);
  yield takeLatest(LIST_PERMISSION_REQUEST, listPermissionsSaga);
}
