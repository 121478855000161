import React from 'react';

export default () => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.27572 7.29994H0.9C0.661305 7.29994 0.432387 7.39476 0.263604 7.56355C0.0948212 7.73233 0 7.96125 0 8.19994C0 8.43864 0.0948212 8.66756 0.263604 8.83634C0.432387 9.00512 0.661305 9.09994 0.9 9.09994H2.27572C2.45485 9.54311 2.76228 9.92267 3.15859 10.1899C3.55489 10.4572 4.022 10.6 4.5 10.6C4.978 10.6 5.44511 10.4572 5.84141 10.1899C6.23772 9.92267 6.54515 9.54311 6.72428 9.09994H14.1C14.3387 9.09994 14.5676 9.00512 14.7364 8.83634C14.9052 8.66756 15 8.43864 15 8.19994C15 7.96125 14.9052 7.73233 14.7364 7.56355C14.5676 7.39476 14.3387 7.29994 14.1 7.29994L6.72428 7.29994C6.54515 6.85677 6.23772 6.47721 5.84141 6.20995C5.44511 5.94268 4.978 5.79989 4.5 5.79989C4.022 5.79989 3.55489 5.94268 3.15859 6.20995C2.76228 6.47721 2.45485 6.85677 2.27572 7.29994V7.29994Z"
      fill="currentColor"
    />
    <path
      d="M10.5 0.400092C10.0221 0.40066 9.55528 0.543671 9.15909 0.810856C8.7629 1.07804 8.45534 1.45727 8.27573 1.90009L0.9 1.89994C0.78181 1.89994 0.664778 1.92322 0.555585 1.96845C0.446392 2.01368 0.347177 2.07997 0.263604 2.16355C0.180031 2.24712 0.113738 2.34633 0.0685083 2.45553C0.0232791 2.56472 0 2.68175 0 2.79994C0 2.91813 0.0232791 3.03516 0.0685083 3.14436C0.113738 3.25355 0.180031 3.35276 0.263604 3.43634C0.347177 3.51991 0.446392 3.5862 0.555585 3.63143C0.664778 3.67666 0.78181 3.69994 0.9 3.69994L8.27573 3.69994C8.45485 4.14311 8.76228 4.52267 9.15859 4.78994C9.55489 5.05721 10.022 5.2 10.5 5.2C10.978 5.2 11.4451 5.05721 11.8414 4.78994C12.2377 4.52267 12.5451 4.14311 12.7243 3.69994H14.1C14.3387 3.69994 14.5676 3.60512 14.7364 3.43634C14.9052 3.26755 15 3.03864 15 2.79994C15 2.56125 14.9052 2.33233 14.7364 2.16355C14.5676 1.99476 14.3387 1.89994 14.1 1.89994H12.7242C12.5446 1.45712 12.2371 1.0779 11.8409 0.810714C11.4447 0.543529 10.9779 0.400515 10.5 0.399942V0.400092Z"
      fill="currentColor"
    />
    <path
      d="M5.87573 12.7H0.9C0.661305 12.7 0.432387 12.7949 0.263604 12.9636C0.0948212 13.1324 0 13.3613 0 13.6C0 13.8387 0.0948212 14.0677 0.263604 14.2364C0.432387 14.4052 0.661305 14.5 0.9 14.5H5.87573C6.05487 14.9432 6.3623 15.3227 6.75859 15.59C7.15488 15.8572 7.62198 16 8.09996 16C8.57795 16 9.04504 15.8572 9.44133 15.59C9.83763 15.3227 10.1451 14.9432 10.3242 14.5H14.1C14.3387 14.5 14.5676 14.4052 14.7364 14.2364C14.9052 14.0677 15 13.8387 15 13.6C15 13.3613 14.9052 13.1324 14.7364 12.9636C14.5676 12.7949 14.3387 12.7 14.1 12.7H10.3243C10.1451 12.2569 9.83772 11.8773 9.44141 11.61C9.04511 11.3428 8.578 11.2 8.1 11.2C7.622 11.2 7.15489 11.3428 6.75859 11.61C6.36228 11.8773 6.05485 12.2569 5.87573 12.7V12.7Z"
      fill="currentColor"
    />
  </svg>
);
