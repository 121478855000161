import React from 'react';

export default () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C4.47709 0 0 4.47709 0 10C0 15.5229 4.47709 20 10 20C15.5229 20 20 15.5229 20 10C20 4.47709 15.5229 0 10 0ZM10.8635 15.4218V16.8302H9.51631V15.4376C7.30563 15.1349 6.33755 13.3184 6.33755 13.3184L7.71364 12.1673C7.71364 12.1673 8.59227 13.6968 10.1816 13.6968C11.0596 13.6968 11.7256 13.2269 11.7256 12.424C11.7256 10.547 6.6685 10.7747 6.6685 7.29255C6.6685 5.77886 7.86569 4.68832 9.51562 4.4303V3.02326H10.8628V4.4303C12.0132 4.58167 13.3755 5.18715 13.3755 6.48961V7.48865H11.5894V7.00427C11.5894 6.50475 10.9529 6.17174 10.2408 6.17174C9.3326 6.17174 8.66726 6.62584 8.66726 7.26159C8.66726 9.18398 13.7244 8.71474 13.7244 12.3634C13.7244 13.8634 12.6042 15.1638 10.8635 15.4218Z"
      fill="currentColor"
    />
  </svg>
);
