import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { setPaymentMethod } from 'api/billing';
import classNames from 'classnames';
import localForage from 'localforage';

import Banner from 'components/Banner';
import Modal from 'components/Modal';
import { getInfraBanner, getSidebarStatus } from 'containers/App/selectors';
import CardForm from 'containers/Billing/components/CardForm';
import { getDetailRole } from 'containers/Settings/AccessRole/selectors';
import { makeSelectMe } from 'containers/Settings/User/selectors';
import { fetchWorkspaceDetailRequest } from 'containers/Settings/Workspace/actions';
import { makeSelectFetchSubscription, makeSelectFetchWorkspace } from 'containers/Settings/Workspace/selectors';
import { defaultNotifier } from 'functions/notificationHandler';
import { AlertTriangleIcon, InfoCircle, PaymentIcon } from 'images/common';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {withRouter } from 'withRouter'
import { createStructuredSelector } from 'reselect';
import messages from 'utils/messages';
import { getPermission } from 'utils/permissions';

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

class DetailScroll extends Component {
  constructor(props) {
    super(props);

    this.contentRef = React.createRef();
  }

  state = {
    paymentMethodId: '',
  }

  async componentDidMount() {
    const isNew = await localForage.getItem('isNewVersion');

    this.setState({ isNew });
  }

  onGetPaymentInfo = (data) => {
    if (data.paymentMethod) {
      return this.setState({ paymentMethodId: data.paymentMethod.id });
    }

    return this.setState({ paymentMethodId: '' });
  };

  handleScrollbarsMount = (scrollbars) => {
    const viewElem = scrollbars && scrollbars.view;

    this.contentRef = viewElem;
    // hack to update ref and pass down
    this.setState({});
  }

  onSubmitPayment = async () => {
    if (this.state.paymentMethodId) {
      this.setState({ isLoading: true });
      try {
        const { data } = await setPaymentMethod({ paymentMethodId: this.state.paymentMethodId });
        this.setState({ isLoading: false });

        if (data.errors) {
          return defaultNotifier(data.errors);
        }

        this.setState({ showModal: false });
        this.props.fetchWorkspaceDetailRequest();
        defaultNotifier('Payment settings updated.');
      } catch (err) {
        defaultNotifier(err);
      }
    }
  }

  onCloseModal = () => {
    this.setState({ showModal: false });
  }

  onCloseBanner = async () => {
    this.setState({ isNew: false });
    await localForage.setItem('isNewVersion', false);
  }

  renderModal = () => {
    const { workspace } = this.props;

    const buttons = [
      <button
        className="modal__confirm button-success button button_xs button-no-shadow"
        key="confirm"
        disabled={!this.state.paymentMethodId || this.state.isLoading}
        onClick={this.onSubmitPayment}
      >
        <FormattedMessage {...messages.confirm} />
      </button>,
    ];

    return (
      <Modal
        classNames="modal__frame4"
        buttons={buttons}
        title="Confirm payment method"
        body={(
          <>
            <div className="billing-modal-info billing-info-accent0 mt_125">
              <span className="billing-info-explore">
                Please add a valid payment method to your workspace to continue
                using Viso Suite.
              </span>
            </div>
            <div className="payment_stripe_input ">
              <div className="description" />
              <div className="billing_payment_icon">
                <PaymentIcon />
                Secured by Stripe
              </div>
              <Elements stripe={stripePromise}>
                <CardForm
                  theme={
                    workspace && workspace.contrast
                  }
                  color="accent0"
                  onGetPaymentInfo={this.onGetPaymentInfo}
                  isLoading={false}
                  interval="monthly"
                />
              </Elements>
            </div>
          </>
        )}
        onClose={this.onCloseModal}
      />
    );
  };

  render() {
    const {
      sidebarStatus,
      customClass,
      isProcessPage,
      detailRole,
      workspace,
      me,
      subscription,
      showInfraBanner,
      hideBanner,
    } = this.props;

    const mainClass = classNames('content', {
      collapsed: sidebarStatus,
      'no-background': isProcessPage,
      [customClass]: customClass,
    });

    const permissionBilling = getPermission('permissionBilling', detailRole.role);


    const hasModal = workspace && permissionBilling.includes('read')
    && !isProcessPage
    && !workspace.hasPaymentSource
    && (showInfraBanner && !isProcessPage);

    const days = (subscription
     && subscription.trial_end && moment(new Date(subscription.trial_end * 1000)).diff(moment(new Date()), 'days')) || 0;

    let message = '';

    message = `Please provide a valid payment method to avoid any interruption to your Viso Suite service.
    You have ${days} day(s) left.`;

    if (days < 0) {
      message = `Your trial has ended and your workspace will be deleted in 
      ${3 + days} days if no valid payment method is provided`;
    }

    return (
      <Scrollbars
        style={{ width: '100%', height: '100vh' }}
        renderView={props => <main {...props} className={mainClass} />}
        universal
        autoHide
        autoHideDuration={1}
        ref={this.handleScrollbarsMount}
        renderThumbVertical={props => (
          <div {...props} className="thumb-vertical" />
        )}
      >
        <div className="content-wrapper">
          <div className="content-body">
            {/* {me && me.isOwner && this.state.showModal && this.renderModal()} */}
            <div>
              {/* {this.state.isNew && !hideBanner && <Banner
                title=''
                color='accent0'
                onClose={this.onCloseBanner}
                desc={<div>We’ve just released an optimized workspace navigation.
                  {' '}<a href='https://viso.ai/product/workspace-navigation' target='_blank' className='banner-href' rel='noreferrer'>Learn more</a></div>}
              />
              } */}
              {/* {!hideBanner && workspace && me && me.isOwner && permissionBilling.includes('read')
              && !isProcessPage
              && !workspace.hasPaymentSource && (
                <Banner
                  icon={<AlertTriangleIcon />}
                  title="Add payment method."
                  color="accent4"
                  desc={message}
                  link="Update payment settings"
                  action={() => this.setState({ showModal: true })}
                />
              )} */}
              {/* {!hideBanner && workspace && !workspace.builderInstalled && !isProcessPage && (
                <Banner
                  icon={<InfoCircle />}
                  title="Your infrastructure."
                  color="accent0"
                  desc="We are setting up your infrastructure in the background. The Viso Builder will be ready in a few minutes."
                />
              )} */}
            </div>
            <div
              className={classNames({
                'content-body-padding': hasModal,
              })}
            >
              {(typeof (this.props.children) === 'function')
                ? this.contentRef.current !== null && this.props.children(this.contentRef)
                : this.props.children
              }
            </div>
          </div>
        </div>
      </Scrollbars>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  sidebarStatus: getSidebarStatus(),
  detailRole: getDetailRole(),
  me: makeSelectMe(),
  subscription: makeSelectFetchSubscription(),
  workspace: makeSelectFetchWorkspace(),
  showInfraBanner: getInfraBanner(),
});

const withConnect = connect(mapStateToProps, { fetchWorkspaceDetailRequest });

DetailScroll.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.object,
    PropTypes.func,
  ]),
};

export default withRouter(withConnect(DetailScroll));
