import React from 'react';
import {FormattedMessage} from 'react-intl';

import appMessages from 'utils/messages'

const filterColumns = [
  {
    id: 2,
    label: <FormattedMessage {...appMessages.status}/>,
    field: 'status',
    type: 'radio'
  }
]

export default filterColumns
