import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import axiosObj from './config';


const RELEASE_API_URL = `${process.env.API_URL}/release/graphql`;

const BULK_ACTION = {
  message: true,
  skippedCount: true,
};

const RELEASE_RETURN_VALUES = {
  id: true,
  name: true,
  createdBy: {
    id: true,
    name: true,
  },
  profile: {
    id: true,
    name: true,
  },
  workspaceId: true,
  releaseBuiild: {
    status: true,
    currentPhase: true,
    id: true,
    phases: {
      durationInSeconds: true,
      phaseType: true,
      phaseStatus: true,
    },

  },
  createdAt: true,
  updatedAt: true,
};

const PROFILE_RETURN_VALUES = {
  id: true,
  tags: true,
  description: true,
  application: {
    id: true,
    name: true,
  },
  applicationVersion: true,
  deviceCount: true,
  deployedDeviceCount: true,
  assignedDeviceCount: true,
  errorDeviceCount: true,
  name: true,
  createdBy: {
    id: true,
    name: true,
  },
  createdAt: true,
  updatedAt: true,
  archived: true,
};

const PROFILES_RETURN_VALUES = {
  id: true,
  tags: true,
  description: true,
  deviceCount: true,
  application: {
    id: true,
    name: true,
  },
  applicationVersion: true,
  name: true,
  createdAt: true,
  createdBy: {
    id: true,
    name: true,
  },
  updatedAt: true,
  archived: true,
  demoData: true,
};

const PROFILE_VERSION_RETURN_VALUES = {
  id: true,
  tags: true,
  description: true,
  version: true,
  name: true,
  createdBy: {
    id: true,
    name: true,
  },
  updatedBy: {
    id: true,
    name: true,
  },
  updatedAt: true,
};

export function assignProfile(param) {
  let query = '';
  // if (param.devices.length > 1) {
  query = {
    mutation: {
      assignProfileToDevices: {
        __args: {
          devices: param.devices,
          profileId: param.profileId,
        },
        ...BULK_ACTION,
      },
    },
  };
  // } else {
  //   query = {
  //     mutation: {
  //       assignProfileToDevice: {
  //         __args: {
  //           device: param.devices[0],
  //           profileId: param.profileId
  //         }
  //       }
  //     }
  //   }
  // }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: RELEASE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}


export function unassignProfile(param) {
  const query = {
    mutation: {
      unassignProfile: {
        __args: param,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: RELEASE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function unassignProfiles(param) {
  const query = {
    mutation: {
      unassignProfiles: {
        __args: param,
        ...BULK_ACTION,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: RELEASE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getReleaseQuery(args) {
  const query = {
    query: {
      releases: {
        __args: args,
        count: true,
        data: RELEASE_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: RELEASE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function releaseProfile(payload) {
  const query = {
    mutation: {
      createRelease: {
        __args: {
          ...payload,
        },
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: RELEASE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function requestSnapshot(payload) {
  const query = {
    mutation: {
      requestUploadSnapshot: {
        __args: {
          ...payload,
        },
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: RELEASE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getImageUrl(payload) {
  const query = {
    query: {
      snapshotImageUrl: {
        __args: {
          ...payload,
        },
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: RELEASE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function pushDeviceUpdate(payload) {
  const query = {
    mutation: {
      pushUpdate: {
        __args: {
          ...payload,
        },
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: RELEASE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getProfile(id) {
  const query = {
    query: {
      profileDetail: {
        __args: {
          id,
        },
        ...PROFILE_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: RELEASE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function createProfile(param) {
  const query = {
    mutation: {
      createProfile: {
        __args: param,
        ...PROFILES_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: RELEASE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getProfiles(params) {
  const query = {
    query: {
      profiles: {
        __args: params,
        ...PROFILES_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: RELEASE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getProfileVersions(param) {
  const query = {
    query: {
      profileHistories: {
        __args: param,
        count: true,
        data: PROFILE_VERSION_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: RELEASE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getProfileVersion(data) {
  const query = {
    query: {
      profileHistories: {
        __args: data,
        count: true,
        data: PROFILE_VERSION_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: RELEASE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}


export function archiveProfile(param) {
  const query = {
    mutation: {
      archiveProfile: {
        __args: param,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: RELEASE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function archiveProfiles(param) {
  const query = {
    mutation: {
      archiveProfiles: {
        __args: param,
        ...BULK_ACTION,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: RELEASE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function activateProfiles(param) {
  const query = {
    mutation: {
      unarchiveProfiles: {
        __args: param,
        ...BULK_ACTION,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: RELEASE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function unarchiveProfile(param) {
  const query = {
    mutation: {
      unarchiveProfile: {
        __args: param,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: RELEASE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function deleteProfile(param) {
  const query = {
    mutation: {
      deleteProfile: {
        __args: param,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: RELEASE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function deleteProfiles(param) {
  const query = {
    mutation: {
      deleteProfiles: {
        __args: param,
        ...BULK_ACTION,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: RELEASE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function updateProfile(param) {
  const query = {
    mutation: {
      updateProfile: {
        __args: param,
        ...PROFILES_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: RELEASE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function rescanDeviceHealth(param) {
  const query = {
    mutation: {
      rescanDeviceHealth: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: RELEASE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getCurrentDeploymentCount(args) {
  const query = {
    query: {
      currentDeploymentCount: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: RELEASE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}
