import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CustomSelect from 'components/Input/CustomSelect';

class SingleSelect extends Component {
  render() {
    return (
      <CustomSelect
        {...this.props}
        onChange={this.props.input.onChange}
        value={this.props.input.value}
      />
    );
  }
}

SingleSelect.propTypes = {
  input: PropTypes.object,
};

export default SingleSelect;
