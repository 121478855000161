import classNames from 'classnames';
import ProcessPage from 'components/ProcessPage';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import AHref from 'components/AHref';
import Verification from 'components/Verification';
import { makeSelectUser } from 'containers/App/selectors';
import { selectWorkspaceRequest } from 'containers/Login/actions';
import reducer from 'containers/Login/reducer';
import saga from 'containers/Login/saga';
import { makeworkspaceSetupInvited  } from 'containers/Login/selectors';
import { fetchUserWorkspaceRequest } from 'containers/Settings/Workspace/actions';
import DeactivatedWorkspace from 'containers/Settings/Workspace/DeactivatedWorkspace';
import {
  makeSelectLoading, makeSelectUserWorkspace,
} from 'containers/Settings/Workspace/selectors';
import { ErrorIcon, PlusXsIcon, WorkspaceIcon } from 'images/common';
import localForage from 'localforage';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { routeMapping } from 'routes';
import appMessages from 'utils/messages';
import Modal from 'components/Modal';
import SuspendedWorkspace from './SuspendedWorkspace';
import {withRouter} from 'withRouter';

class SelectWorkspace extends Component {
  state = {
    showWarning: false,
    activeWs: '',
    showOnlyOwner: false,
    codes: [],
    activeIndex: 0,
    type: 'code',
  };

  onChange = (e, index) => {
    const value = e.key || e.currentTarget.value;

    this.state.codes[index] = value;
    this.setState({ activeIndex: parseInt(index, 10) + 1 });
    this.setState({ codes: this.state.codes });
  }

  onUpdate = value => this.setState({ codes: value })

  async componentDidMount() {
    const result = await localForage.getItem('activePartnerId');

    if (result && result.id) {
      this.setState({ showOnlyOwner: true });
    }

    if (this.props.user && this.props.user.passwordResetRequired) {
      return this.props.router.navigate(routeMapping.CHANGE_PASSWORD.path);
    }
  }

  onClose = () => {
    const {
      user: { activeWorkspace },
    } = this.props;

    if (activeWorkspace) {
      return this.props.router.navigate(-1);
    }

    localStorage.removeItem('accessToken');
    localStorage.removeItem('userData');

    this.props.router.navigate(routeMapping.LOGIN.path);
  };

  onCloseModal = () => {
    this.setState({ showAuthenticationModal: false });
  }

  onUpdateOption = () => {
    const activeType = this.state.type;

    if (activeType === 'code') {
      return this.setState({ type: 'recoveryKey' });
    }
    return this.setState({ type: 'code' });
  }

  renderModal = () => {
    const { codes } = this.state;
    const code = codes.length && codes.reduce((sum, item) => `${sum}${item}`);

    const buttons = [
      <button
        disabled={code.length !== 6}
        className="modal__confirm label-color button_xs button_no_padding"
        key="confirm"
        onClick={() => {
          if (code.length === 6) {
            this.onSubmitCode(this.state.codes, this.state.type);
          }
        }}
      >
        Verify
      </button>,
    ];

    return (
      <Modal
        buttons={buttons}
        title={this.state.type === 'code' ? 'Verification Code' : 'Recovery Key'}
        body={(
          <div>
            Please enter the 6-digit security code from your mobile phone app to continue. Or use the
            {' '}
            <AHref onClick={this.onUpdateOption}>{this.state.type === 'code' ? 'recovery key' : 'verification code'}</AHref>
            .
            <br />
            <br />
            <Verification
              codes={this.state.codes}
              onUpdate={this.onUpdate}
              activeIndex={this.state.activeIndex}
              onChange={this.onChange}
            />
          </div>
        )}
        onClose={this.onCloseModal}
      />
    );
  };

  onCreate = () => {
    this.props.router.navigate(routeMapping.NEW_WORKSPACE.path);
  };

  onCreateDev = () => {
    this.props.router.navigate(
      `${routeMapping.NEW_WORKSPACE.path}?isCreatePage=true`
    );
  };

  onSubmitCode = (codes, type) => {
    const code = codes.reduce((sum, item) => `${sum}${item}`);

    let payload = this.state.wsData;

    if (type === 'code') {
      payload = {
        ...this.state.wsData,
        twoFactorCode: code,
      };
    }

    if (type === 'recoveryKey') {
      payload = {
        ...this.state.wsData,
        twoFactorRecoveryKey: code,
      };
    }

    this.setState({ showVerificationCode: false });
    return this.props.selectWorkspaceRequest(payload, {
      push: this.props.router.navigate,
      onCallBack: () => this.onCloseModal(),
    });
  }

  getWorkspaceStatus = (status) => {
    if(status === 'locked') {
      return 'Locked'
    }

    if(status === 'inactive') {
      return 'Suspended'
    }

    return status
  }

  onSelectWorkspace = (data) => {
    const { workspaces = [] } = this.props.user;

    const args = {
      subDomain: data,
    };
    const workspace = workspaces.find(item => item.subDomain === data);

    if (workspace && workspace.status === 'inactive' && workspace.userRole !== 'Owner') {
      return this.setState({ selectedWs: workspace, showWarning: true });
    }

    if (workspace && workspace.status === 'inactive' && workspace.userRole === 'Owner') {
      return this.setState({ selectedWs: workspace, isSuspended: true });
    }

    if (
      (workspace && workspace.userRole === 'Owner')
      || (workspace && workspace.status === 'active')
    ) {
      if (workspace.twoFactorEnabled) {
        this.setState({ showAuthenticationModal: true });
        return this.setState({ wsData: args });
      }
      this.setState({ showWarning: false });
      return this.props.selectWorkspaceRequest(args, {
        push: this.props.router.navigate,
      });
    }

    this.setState({ selectedWs: workspace, showWarning: true });


    
  };

  onTouchWs = (ws) => {
    this.setState({ activeWs: ws });
  };

  render() {
    const { showOnlyOwner } = this.state;
    const { workspaces = [], user } = this.props;

    const workspaceList = (!!workspaces.length && workspaces) || user.workspaces || [];

    if (this.state.showWarning) {
      return (
        <DeactivatedWorkspace
          workspace={this.state.selectedWs}
          onClose={this.onClose}
        />
      );
    }

    if (this.state.isSuspended) {
      return (
        <SuspendedWorkspace
          workspace={this.state.selectedWs}
          onClose={this.onClose}
        />
      );
    }

    return (
      <ProcessPage onCancel={this.onClose}>
        {() => (
          <div>
            {this.state.showAuthenticationModal && this.renderModal()}
            <div className="headline">
              <h1 className="headline__title headline_title__register">
                Viso Suite
              </h1>
              <h3 className="headline__subtitle">
                <FormattedMessage {...appMessages.selectWorkspace} />
              </h3>
            </div>
            <div className="workspace-select">
              {showOnlyOwner
                && workspaceList && workspaceList
              // .filter(workspace => workspace.userRole === 'Owner')
                .map((workspace, index) => {
                  const workspaceStatus = classNames('workspace_status_tag', {
                    delete: (workspace && workspace.status) === 'deleted',
                  });

                  if (workspace) {
                    return (
                      <a
                        href="javascript:void(0)"
                        className={classNames(`workspace-select__item workspace-${index}`, {
                          'is-not-owner': (workspace && workspace.userRole) !== 'Owner',
                        })}
                        onClick={() => workspace.userRole === 'Owner'
                            && this.onSelectWorkspace(workspace.subDomain)
                        }
                        onMouseEnter={() => this.onTouchWs(workspace.subDomain)}
                        onMouseLeave={() => this.onTouchWs()}
                        key={workspace.name}
                      >
                        <div className="checkbox">
                          {workspace && workspace.isNew && (
                            <div className="card__new">
                              <FormattedMessage {...appMessages.cNew} />
                            </div>
                          )}
                          <div
                            className={`${workspace
                                && workspace.logo
                                && 'no-border'} checkbox__image`}
                          >
                            {workspace && workspace.logo ? (
                              <img
                                src={workspace.logo}
                                alt=""
                                height="52"
                                width="52"
                              />
                            ) : (
                              <WorkspaceIcon />
                            )}
                          </div>
                          {(workspace && workspace.userRole) !== 'Owner' && (
                            <span
                              className={`data-cards__selected ${
                                this.props.colorClassName
                              } failure`}
                            >
                              {this.state.activeWs === workspace.subDomain && (
                                <p className="custom-tooltip ws-tooltip">
                                  You currently dont have the user role Owner
                                  for this workspace.
                                </p>
                              )}
                              <ErrorIcon />
                            </span>
                          )}
                        </div>
                        <div className="workspace-select__label h3">
                          {workspace.name
                              || workspace.subDomain
                              || workspace.organizationName}
                        </div>
                        {workspace && workspace.status && workspace.status !== 'active' && (
                          <span className={workspaceStatus}>
                            {this.getWorkspaceStatus(workspace.status)}
                          </span>
                        )}
                      </a>
                    );
                  }

                  return <span />;
                })}
              {!showOnlyOwner
                && workspaceList && workspaceList.map((workspace, index) => {
                const workspaceStatus = classNames('workspace_status_tag', {
                  delete: (workspace && workspace.status) === 'deleted',
                });

                if (workspace) {
                  return (
                    <a
                      href="javascript:void(0)"
                      className={classNames(`workspace-select__item workspace-${index}`, {})}
                      onClick={() => this.onSelectWorkspace(workspace.subDomain)
                      }
                      key={workspace.subDomain}
                    >
                      <div className="checkbox relative">
                        {workspace.isNew && (
                          <div className="card__new">
                            <FormattedMessage {...appMessages.cNew} />
                          </div>
                        )}
                        <div
                          className={`${workspace
                              && workspace.logo
                              && 'no-border'} checkbox__image`}
                        >
                          {workspace && workspace.logo ? (
                            <img
                              src={workspace.logo}
                              alt=""
                              height="52"
                              width="52"
                            />
                          ) : (
                            <WorkspaceIcon />
                          )}
                        </div>
                      </div>
                      <div className="workspace-select__label h3">
                        {workspace.name
                            || workspace.subDomain
                            || workspace.organizationName}
                      </div>
                      {workspace && workspace.status && workspace.status !== 'active' && (
                        <span className={workspaceStatus}>
                          {this.getWorkspaceStatus(workspace.status)}
                        </span>
                      )}
                    </a>
                  );
                }

                return <span />;
              })}
              {/* <a
                href="javascript:void(0)"
                className="workspace-select__item"
                onClick={this.onCreate}
              >
                <div className="checkbox">
                  <div className="checkbox__image">
                    <PlusXsIcon />
                  </div>
                </div>
                <div className="workspace-select__label h3">
                  <FormattedMessage {...appMessages.createOrJoinWorkspace} />
                </div>
              </a> */}
              {user.workspaceSetupInvited  && (
                <>
                  <a
                    href="javascript:void(0)"
                    className="workspace-select__item"
                    onClick={this.onCreateDev}
                  >
                    <div className="checkbox relative">
                      <div className="card__new">
                        <FormattedMessage {...appMessages.cNew} />
                      </div>
                      <div className="checkbox__image">
                        <PlusXsIcon />
                      </div>
                    </div>
                    <div className="workspace-select__label h3">
                    Set up your workspace
                    </div>
                  </a>
                </>
              )}
            </div>
          </div>
        )}
      </ProcessPage>
    );
  }
}

SelectWorkspace.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  workspaces: PropTypes.any,
  selectWorkspaceRequest: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  isLoading: makeSelectLoading(),
  workspaceSetupInvited : makeworkspaceSetupInvited (),
  workspaces: makeSelectUserWorkspace(),
  userData: makeSelectUser(),
});

const withConnect = connect(
  mapStateToProps,
  { selectWorkspaceRequest, fetchUserWorkspaceRequest },
);

const withReducer = injectReducer({ key: 'login', reducer });
const withSaga = injectSaga({ key: 'login', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect,
)(SelectWorkspace);
