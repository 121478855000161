import PropTypes from 'prop-types';
import { X } from 'react-feather';
import React from 'react';

class Calendly extends React.Component {
  state = {
    showDiv: false,
  }

  componentDidMount() {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    head.appendChild(script);
    // setTimeout(() => {
    this.setState({ showDiv: true });
    // }, 1000)
  }

  componentWillUnmount() {
    // whatever you need to cleanup the widgets code
  }

  render() {
    const { title, desc, workspace } = this.props;

    const backgroundColor = workspace && workspace.contrast === 'dark' ? '323232' : 'fff';
    const textColor = workspace && workspace.contrast === 'dark' ? 'ffffff' : '444';

    return (
      <div>
        <div className="overlay overlay_visible" />
        <div className="modal modal_visible" />
        <div id="calendly-wrapper">
          {this.state.showDiv && (
            <div className="custom-text-wrapper">
              <div className="custom-title">{title}</div>
              <button type="button" className="modal__close" onClick={this.props.onClose}>
                <X />
              </button>
              <p className="custom-desc">{desc}</p>
            </div>
          )
          }

          <div
            className="calendly-inline-widget"
            data-url={`https://calendly.com/viso-ai/30-minutes?hide_event_type_details=1&hide_gdpr_banner=1
            &primary_color=2184f9&background_color=${backgroundColor}&text_color=${textColor}`}
            style={{ minWidth: '320px', height: '630px', backgroundColor: `#${backgroundColor}` }}
          />
        </div>
      </div>

    );
  }
}

Calendly.propTypes = {
  color: PropTypes.string,
};

Calendly.defaultProps = {
  color: 'accent0',
};

export default Calendly;
