import { fromJS } from 'immutable';
import {
  ADD_USER_GROUP_REQUEST,
  ADD_USER_GROUP_SUCCESS,
  ADD_USER_GROUP_ERROR,
  UPDATE_USER_GROUP_REQUEST,
  UPDATE_USER_GROUP_SUCCESS,
  UPDATE_USER_GROUP_ERROR,
  LIST_USER_GROUP_REQUEST,
  LIST_USER_GROUP_SUCCESS,
  LIST_USER_GROUP_ERROR,
  REMOVE_USER_GROUP_REQUEST,
  REMOVE_USER_GROUP_SUCCESS,
  REMOVE_USER_GROUP_ERROR,
  USER_GROUP_ERROR,
  USER_GROUP_REQUEST,
  USER_GROUP_SUCCESS,
} from './constants';

export const initialState = fromJS({
  userGroupList: {
    data: [],
    count: 0,
  },
  details: null,
  errors: null,
  loading: false,
  needRefresh: false,
  modalLoading: false,
  modalErrors: null,
  modalUserGroup: null,
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case LIST_USER_GROUP_REQUEST:
      return state.set('loading', true);
    case LIST_USER_GROUP_SUCCESS:
      if (action.payload.offset === 0) {
        return state
          .set('loading', false)
          .set('userGroupList', {
            errors: null,
            loading: false,
            data: action.payload.data,
            count: action.payload.count,
          });
      }
      return state
        .set('loading', false)
        .set('userGroupList', {
          errors: null,
          loading: false,
          data: [...state.toJS().userGroupList.data, ...action.payload.data],
          count: action.payload.count,
        });
    case LIST_USER_GROUP_ERROR:
      return state.set('errors', action.errors)
        .set('loading', false);
    case USER_GROUP_REQUEST:
      return state
        .set('loading', true)
        .set('details', null);
    case USER_GROUP_SUCCESS:
      return state.set('details', action.payload)
        .set('loading', false);
    case USER_GROUP_ERROR:
      return state.set('errors', action.errors)
        .set('loading', false);
    case ADD_USER_GROUP_SUCCESS:
      return state.set('userGroupList', { count: state.toJS().count + 1, data: [...state.toJS().userGroupList.data, action.payload] })
        .set('loading', false);
    case UPDATE_USER_GROUP_SUCCESS:
      return state
        .set('details', action.payload)
        .set('modalUserGroup', action.payload);
    case REMOVE_USER_GROUP_SUCCESS:
      return state.set('modalUserGroup', action.payload)
        .set('modalLoading', false);
    case REMOVE_USER_GROUP_ERROR:
    case UPDATE_USER_GROUP_ERROR:
    case ADD_USER_GROUP_ERROR: {
      return state.set('modalErrors', action.errors)
        .set('modalLoading', false);
    }
    case REMOVE_USER_GROUP_REQUEST:
    case UPDATE_USER_GROUP_REQUEST:
    case ADD_USER_GROUP_REQUEST:
      return state.set('modalUserGroup', null)
        .set('modalLoading', true)
        .set('modalErrors', null);
    default:
      return state;
  }
}

export default reducer;
