import React from 'react';

export default () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.8578 11.8573C32.3569 11.8573 33.5721 10.6421 33.5721 9.143C33.5721 7.64394 32.3569 6.42871 30.8578 6.42871C29.3588 6.42871 28.1436 7.64394 28.1436 9.143C28.1436 10.6421 29.3588 11.8573 30.8578 11.8573Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 22.7143L22.7143 1H39V17.2857L17.2857 39L1 22.7143Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
