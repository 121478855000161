import { createSelector } from 'reselect';

const solutions = state => state.solutions;

export const getVendorDetailsState = () => createSelector(
  solutions,
  state => (state.get('vendor')),
);

export const getSolutionDetailsState = () => createSelector(
  solutions,
  state => (state.get('details')),
);

export const geMarketCategoryListState = () => createSelector(
  solutions,
  state => (state.get('marketCategory')),
);

export const getSolutionBasicInfoState = () => createSelector(
  solutions,
  state => (state.get('details')),
);

export const getSolutionListState = () => createSelector(
  solutions,
  state => state.get('list'),
);

export const getSolutionLoadingState = () => createSelector(
  solutions,
  state => state.get('isLoading'),
);

export const getSolutionFileState = () => createSelector(
  solutions,
  state => state.get('file'),
);

export const getSolutionFilesState = () => createSelector(
  solutions,
  state => state.get('files'),
);


export const getDashboard = () => createSelector(
  solutions,
  state => state.get('dashboards').toJS(),
);
