import React from 'react';

export default () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.28571 4.28571H0V0H4.28571V4.28571ZM1.07143 3.21429H3.21429V1.07143H1.07143V3.21429Z" fill="currentColor" />
    <path d="M15.0001 15H10.7144V10.7143H15.0001V15ZM11.7858 13.9286H13.9286V11.7857H11.7858V13.9286Z" fill="currentColor" />
    <path d="M9.64286 13.3929H3.21429C2.3618 13.3929 1.54424 13.0542 0.941443 12.4514C0.338647 11.8486 0 11.0311 0 10.1786C0 9.3261 0.338647 8.50853 0.941443 7.90574C1.54424 7.30294 2.3618 6.96429 3.21429 6.96429V8.03572C2.64596 8.03572 2.10092 8.26149 1.69906 8.66335C1.29719 9.06522 1.07143 9.61026 1.07143 10.1786C1.07143 10.7469 1.29719 11.2919 1.69906 11.6938C2.10092 12.0957 2.64596 12.3214 3.21429 12.3214H9.64286V13.3929Z" fill="currentColor" />
    <path d="M11.7857 8.03572V6.96429C12.3541 6.96429 12.8991 6.73853 13.301 6.33666C13.7028 5.9348 13.9286 5.38975 13.9286 4.82143C13.9286 4.25311 13.7028 3.70807 13.301 3.3062C12.8991 2.90434 12.3541 2.67858 11.7857 2.67858H5.35718V1.60715H11.7857C12.6382 1.60715 13.4558 1.94579 14.0586 2.54859C14.6614 3.15139 15 3.96895 15 4.82143C15 5.67391 14.6614 6.49148 14.0586 7.09428C13.4558 7.69707 12.6382 8.03572 11.7857 8.03572V8.03572Z" fill="currentColor" />
    <path d="M9.10707 4.82144H5.89279L4.28564 6.9643L7.49993 10.1786L10.7142 6.9643L9.10707 4.82144Z" fill="currentColor" />
  </svg>
);
