import { takeLatest, call, put } from 'redux-saga/effects';
import {
  getWorkspace,
  getWorkspaceDetail,
  getWorkspaceLogo,
  uploadWorkspaceLogo,
  getUserWorkspaces,
  updateWorkspace,
  updateWorkspaceLogo,
  createWorkspace,
  deleteWorkspaceLogo,
  workspaceSetupTrackerDetail,
  updateWorkspaceSetupTracker,
} from 'api/workspace';
import slugify from 'react-slugify';
import { push } from 'react-router-redux';
import { intl } from 'containers/LanguageProvider/intlProvider';
import messages from 'utils/messages';
import {getSubscriptionDetails, cancelPlan, reactivateWorkspace} from 'api/billing'

import { selectWorkspaceRequest } from 'containers/Login/actions';
import {
  defaultNotifier,
  successNotifier,
} from 'functions/notificationHandler';
import {
  FETCH_WORKSPACE_REQUEST,
  UPDATE_WORKSPACE_REQUEST,
  MANAGE_WORKSPACE_REQUEST,
  CREATE_WORKSPACE_REQUEST,
  UPLOAD_WORKSPACE_REQUEST,
  FETCH_USER_WORKSPACE_REQUEST,
  FETCH_WORKSPACE_DETAIL_REQUEST,
  REMOVE_WORKSPACE_LOGO_REQUEST,
  SCHEDULE_WORKSPACE_REQUEST,
  RECOVER_WORKSPACE_REQUEST,
  GET_SETUP_TRACKER_REQUEST,
  UPDATE_SETUP_TRACKER_REQUEST,
  GET_SUBSCRIPTION_DETAILS_WS_REQUEST,

} from './constants';
import {
  fetchWorkspaceError,
  uploadWorkspaceSuccess,
  fetchUserWorkspaceRequest,
  fetchUserWorkspaceSuccess,
  createWorkspaceSuccess,
  fetchWorkspaceSuccess,
  updateWorkspaceError,
  updateWorkspaceSuccess,
  removeWorkspaceLogoSuccess,
  getStartedTrackerSuccess,
  uploadWorkspaceError,
  getSubscriptionBillingSuccess,
} from './actions';

function* fetchWorkspaceSaga(action) {
  try {
    const { payload } = action;

    if (!payload.subDomain) {
      return;
    }

    const response = yield call(getWorkspace, payload);
    if (response.data.errors) {
      yield put(fetchWorkspaceError(response.data.errors));
      defaultNotifier(response.data.errors);
    } else {
      yield put(fetchWorkspaceSuccess(response.data.data.workspace));

      if (window.location.pathname.split('/').length <= 2) {
        yield put(push(`/${slugify(response.data.data.workspace.name)}`));
      }
    }
  } catch (err) {
    yield put(fetchWorkspaceError(err));

    defaultNotifier(err);
  }
}

function* fetchWorkspaceDetailSaga() {
  try {
    const response = yield call(getWorkspaceDetail);
    if (response.data.errors) {
      yield put(fetchWorkspaceError(response.data.errors));
      defaultNotifier(response.data.errors);
    } else {
      yield put(fetchWorkspaceSuccess(response.data.data.workspaceDetail));
    }
  } catch (err) {
    yield put(fetchWorkspaceError(err));
    defaultNotifier(err);
  }
}

function* fetchUserWorkspaceSaga(action) {
  try {
    const { payload } = action;
    const response = yield call(getUserWorkspaces, payload);

    if (response.data.errors) {
      return false;
    }

    yield put(fetchUserWorkspaceSuccess(response.data.data.userWorkspaces));

    if (payload) {
      action.payload();
    }
  } catch (err) {
    defaultNotifier(err);
  }
}

function* updateWorkspaceSaga(action) {
  try {
    const { payload } = action;
    const response = yield call(updateWorkspace, payload);

    if (response.data.errors) {
      defaultNotifier(response.data.errors);
      updateWorkspaceError(response.data.errors);
    } else {
      yield put(updateWorkspaceSuccess(response.data.data.updateWorkspace));

      defaultNotifier(intl.formatMessage({ ...messages.workspaceChangesSaved }));
    }
  } catch (err) {
    updateWorkspaceError(err);
    defaultNotifier(err);
  }
}

function* manageWorkspaceSaga(action) {
  try {
    const { payload } = action;
    const response = yield call(updateWorkspace, payload);

    if (response.data.errors) {
      defaultNotifier(response.data.errors);
      updateWorkspaceError(response.data.errors);
    } else {
      yield put(fetchUserWorkspaceRequest());
      defaultNotifier(intl.formatMessage({ ...messages.workspaceChangesSaved }));
    }
  } catch (err) {
    updateWorkspaceError(err);
    defaultNotifier(err);
  }
}

function* uploadWorkspaceSaga(action) {
  try {
    const { file } = action.payload;

    const response = yield call(uploadWorkspaceLogo, { filename: file.name }, action.meta);

    if (response.data.errors) {
      defaultNotifier(response.data.errors);
    } else {
      const { fields, url } = response.data.data.uploadWorkspaceLogo;

      yield call(getWorkspaceLogo, { url, fields, file }, action.meta);
      const result = yield call(updateWorkspaceLogo, {
        s3Key: fields.key,
      });

      if (result.data.errors) {
        yield put(uploadWorkspaceError());
        return defaultNotifier(result.data.errors);
      }

      yield put(uploadWorkspaceSuccess(result.data.data.updateWorkspaceLogo));
      successNotifier(intl.formatMessage({ ...messages.logoUploadedSuccessfully }));
    }
  } catch (err) {
    defaultNotifier(err);
  }
}

function* removeWorkspaceLogoSaga(action) {
  try {
    const response = yield call(deleteWorkspaceLogo);

    if (response.data.errors) {
      defaultNotifier(response.data.errors);
    } else {
      yield put(removeWorkspaceLogoSuccess(action.payload));
      successNotifier(intl.formatMessage({ ...messages.logoRemovedSuccessfully }));
    }
  } catch (err) {
    defaultNotifier(err);
  }
}

function* createWorkspaceSaga(action) {
  try {
    const { payload, meta } = action;

    const response = yield call(createWorkspace, payload);

    if (response.data.errors) {
      yield put(updateWorkspaceError(response.data.errors));

      defaultNotifier(response.data.errors);
      meta.onFailure();
    } else {
      yield put(createWorkspaceSuccess(response.data.data.createWorkspace));
      meta.onSuccess();
      localStorage.setItem(
        'newSubDomain',
        response.data.data.createWorkspace.subDomain,
      );
      yield put(
        selectWorkspaceRequest(
          {
            subDomain: response.data.data.createWorkspace.subDomain,
          },
          { stayLoggedIn: false },
        ),
      );
    }
  } catch (err) {
    action.meta && action.meta.onFailure();
    defaultNotifier(err);
  }
}

function* recoverWorkspaceSaga(action) {
  try {
    const { meta } = action;

    const response = yield call(reactivateWorkspace);

    if (response.data.errors) {
      return defaultNotifier(response.data.errors);
    }

    if (meta.onCallback) {
      meta.onCallback();
    }
    defaultNotifier(intl.formatMessage({ ...messages.workspaeRecoveredSuccessfully }));
  } catch (err) {
    defaultNotifier(err);
  }
}

function* scheduleWorkspaceDeleteSaga(action) {
  try {
    const response = yield call(cancelPlan);
    if (response.data.errors) {
      return defaultNotifier(response.data.errors);
    }
    defaultNotifier(intl.formatMessage({ ...messages.workspaceSubscriptionCancelled }));

    if(action.payload) {
      action.payload('/login');
    }

    if(action.meta) {
      action.meta('/login');
    }
  } catch (err) {
    defaultNotifier(err);
  }
}

function* getStartedSaga() {
  try {
    const response = yield call(workspaceSetupTrackerDetail);

    if (response.data.errors) {
      defaultNotifier(response.data.errors);
    }

    yield put(getStartedTrackerSuccess(response.data.data.workspaceSetupTrackerDetail));
  } catch (err) {
    defaultNotifier(err);
  }
}

function* updateGetStartedActionSaga(action) {
  try {
    const response = yield call(updateWorkspaceSetupTracker, action.payload);

    if (response.data.errors) {
      defaultNotifier(response.data.errors);
    }

    yield put(getStartedTrackerSuccess(response.data.data.updateWorkspaceSetupTracker));
  } catch (err) {
    defaultNotifier(err);
  }
}

function* getSubscriptionWsSaga() {
  try {
    const response = yield call(getSubscriptionDetails);


    if (response.data.errors) {
      defaultNotifier(response.errors);
    } else {
      const wsData = response.data.data.subscriptions
      && response.data.data.subscriptions.subscriptions
      && response.data.data.subscriptions.subscriptions.length
      && response.data.data.subscriptions.subscriptions.find(item => item.metadata.type === 'workspace');

      yield put(getSubscriptionBillingSuccess(wsData));
    }
  } catch (err) {
    defaultNotifier(err);
  }
}

export default function* workspaceRootSaga() {
  yield takeLatest(FETCH_WORKSPACE_REQUEST, fetchWorkspaceSaga);
  yield takeLatest(FETCH_WORKSPACE_DETAIL_REQUEST, fetchWorkspaceDetailSaga);
  yield takeLatest(FETCH_USER_WORKSPACE_REQUEST, fetchUserWorkspaceSaga);
  yield takeLatest(UPDATE_WORKSPACE_REQUEST, updateWorkspaceSaga);
  yield takeLatest(MANAGE_WORKSPACE_REQUEST, manageWorkspaceSaga);
  yield takeLatest(CREATE_WORKSPACE_REQUEST, createWorkspaceSaga);
  yield takeLatest(UPLOAD_WORKSPACE_REQUEST, uploadWorkspaceSaga);
  yield takeLatest(REMOVE_WORKSPACE_LOGO_REQUEST, removeWorkspaceLogoSaga);
  yield takeLatest(SCHEDULE_WORKSPACE_REQUEST, scheduleWorkspaceDeleteSaga);
  yield takeLatest(RECOVER_WORKSPACE_REQUEST, recoverWorkspaceSaga);
  yield takeLatest(GET_SETUP_TRACKER_REQUEST, getStartedSaga);
  yield takeLatest(UPDATE_SETUP_TRACKER_REQUEST, updateGetStartedActionSaga);
  yield takeLatest(GET_SUBSCRIPTION_DETAILS_WS_REQUEST, getSubscriptionWsSaga);
}
