import { compose } from 'redux';
import ProcessPage from 'components/ProcessPage';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Modal from 'components/Modal';
import { FormattedMessage } from 'react-intl';
import Slider, { Handle } from 'rc-slider';
import { DragIcon } from 'images/common';
import LoadingIndicator from 'components/LoadingIndicator';
import classNames from 'classnames';
import React, { Component } from 'react';
import {withRouter } from 'withRouter'
import slugify from 'react-slugify';
import { routeMapping } from 'routes';
import { DEFAULT_ACCENT } from 'utils/constants';
import messages from 'utils/messages';
import { createStructuredSelector } from 'reselect';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import ChangePlan from '../details/manageSubscription/ChangePlan';
import {
  products,
  purchaseDevHours,
  previewUpcomingInvoice
} from 'api/billing';
import { defaultNotifier } from 'functions/notificationHandler';
import EstimateItem from './EstimateItem';
import {
  getBillingDetailsRequest, getInvoicesRequest, getSubscriptionDetailsRequest,
  getUpcomingInvoicesRequest, updateBillingRequest
} from '../../actions';
import {fetchWorkspaceDetailRequest} from 'containers/Settings/Workspace/actions'
import { makeSelectFetchWorkspace } from 'containers/Settings/Workspace/selectors';
import reducer from '../../reducer';
import saga from '../../saga';
import {
  getBillingDetails, getSubscriptionDetails, getSubscriptionLoadingState
} from '../../selectors';
import { connect } from 'react-redux';
import {makePlanData} from 'containers/Login/selectors'

class PlanLimitPage extends Component {
  state = {
    showPlanPage: false,
    products: [],
    quantity: 1
  };

  async componentDidMount() {
    try {
      this.setState({ isLoading: true });
      this.props.fetchWorkspaceDetailRequest();
      const { data } = await products();

      this.setState({ isLoading: false });
      if (data.errors) {
        return defaultNotifier(data.errors);
      }
      this.setState({ products: data.data.products }, () => {
        this.onAfterChange(this.state.quantity);
      });
    } catch (err) {
      this.setState({ isLoading: false });
      defaultNotifier(err);
    }
  }

  onAfterChange = async value => {
    const { productDevInfo } = this.props;

    if (productDevInfo && productDevInfo.quantity) {
      const devHours = this.state.products.find(
        item => item.uniqueName === 'dev_hours'
      );

      this.setState({ isUpcomingLoading: true });
      const { data } = await previewUpcomingInvoice({
        subscriptionId: productDevInfo.id,
        priceId: devHours['monthlyPrice'].id,
        subscriptionQuantity: (+value) + productDevInfo.quantity
      });
      this.setState({ isUpcomingLoading: false });
      this.setState({ billingInfo: data.data.previewUpcomingInvoice });
    }
  };

  onClose = () => {
    this.props.router.navigate(routeMapping.LOGIN.path);
  };

  onShowPlanPage = () => {
    const { workspace } = this.props;

    const wsPath = workspace && slugify(workspace.name);

    this.props.router.navigate(`/${wsPath}/change-plan`);
  };

  onChange = async value => {
    this.setState({ quantity: +value });
    if (value > 20) {
      this.setState({ quantity: 20 });
    }
  };

  onBlur = async value => {
    this.onChange(value || 1);
    this.onAfterChange(+value || 1)

    if (!value) {
      this.setState({ quantity: 1 });
    }
  }

  onCloseModal = () => {
    this.setState({ showModal: false, quantity: 1 });
  };

  onShowModal = () => {
    this.setState({ showModal: true });
    this.onAfterChange(this.state.quantity);
  };

  onPurchase = async () => {
    const { productDevInfo, getSubscription, getBilling } = this.props;

    let totalQuantity = this.state.quantity;
    this.onCloseModal();

    if (productDevInfo && productDevInfo.quantity) {
      totalQuantity += productDevInfo.quantity;
    }

    this.setState({ isLoading: true });
    const { data } = await purchaseDevHours({ quantity: totalQuantity });

    if (data.errors) {
      this.setState({ isLoading: false });
      return defaultNotifier(data.errors);
    }
    await getSubscription();
    await getBilling()
    this.setState({ isLoading: false });

    defaultNotifier('Additional hours added successfully.');
  };

  renderModal = () => {
    const { billing } = this.props;
    const { productDevInfo } = this.props;
    const { quantity, billingInfo } = this.state;
    let subTotal = '';
    let amountDue = '';
    let totalAmount = '';
    let appliedBalance = '';

    const devHours = this.state.products.find(
      item => item.uniqueName === 'dev_hours'
    );

    if (productDevInfo && productDevInfo.quantity) {
      totalAmount =
        billingInfo &&
        billingInfo.lines &&
        billingInfo.lines.data
          .map(line => line.amount)
          .reduce((sum, line) => sum + line);

      subTotal = totalAmount;

      const balance = (billing && billing.balance) || 0;
      amountDue = totalAmount - balance > 0 ? totalAmount - balance : 0;

      appliedBalance = '';

      const currentAppliedBalance = amountDue - totalAmount;
      if (currentAppliedBalance !== 0) {
        appliedBalance = currentAppliedBalance;
      }

      appliedBalance =
        totalAmount >= 0 ? amountDue - totalAmount : '';
    }

    if (!productDevInfo) {
      totalAmount = devHours.monthlyPrice.unit_amount * this.state.quantity;
      subTotal = totalAmount;

      const balance = (billing && billing.balance) || 0;
      amountDue = totalAmount - balance > 0 ? totalAmount - balance : 0;

      appliedBalance = '';

      const currentAppliedBalance = amountDue - totalAmount;

      if (currentAppliedBalance !== 0) {
        appliedBalance = currentAppliedBalance;
      }

      appliedBalance =
        totalAmount >= 0 ? amountDue - totalAmount : '';
    }

    let availableCredits = '';

    if (totalAmount && amountDue) {
      availableCredits =
        totalAmount < 0 && totalAmount + amountDue < 0
          ? totalAmount + amountDue
          : '';
    }

    const buttons = [
      <button
        className="modal__confirm button-success button button_xs button-no-shadow"
        key="confirm"
        disabled={this.state.isLoading}
        onClick={() => {
          this.onPurchase();
        }}
      >
        {this.state.isLoading ? (
          <FormattedMessage {...messages.loading} />
        ) : (
          <FormattedMessage {...messages.payNow} />
        )}
      </button>
    ];

    return (
      <Modal
        classNames="modal__frame4 modal__product"
        buttons={buttons}
        title={devHours.name}
        body={
          <>
            <div className="billing-modal-title">
              <div className="billing-modal-header">
                <h4 className="plan_duration">
                  <FormattedMessage {...messages.add} />
                  {''}
                  <NumberFormat
                    value={this.state.quantity}
                    displayType={'text'}
                    thousandSeparator
                    renderText={value => (
                      <input
                        type="number"
                        max='20'
                        min='1'
                        onBlur={e => this.onBlur(e.target.value)}
                        onChange={e => this.onChange(e.target.value)}
                        className="billing_input_endpoint"
                        value={value}
                      />
                    )}
                  />
                  {''}
                  <span>
                    <FormattedMessage {...messages.hours} />
                  </span>
                </h4>
              </div>
              <div className="slider-card">
                <Slider
                  min={1}
                  max={20}
                  value={quantity}
                  onAfterChange={this.onAfterChange}
                  onChange={this.onChange}
                  handle={handleProps => {
                    return (
                      <Handle {...handleProps}>
                        <div className="billing_slider">
                          <DragIcon />
                        </div>
                      </Handle>
                    );
                  }}
                />
              </div>
            </div>

            {availableCredits && (
              <div className="billing-modal-info billing-info-accent0">
                <span className="billing-info-explore">
                  <span className="">
                    <FormattedMessage
                      {...messages.forThePaidPeriod}
                      values={{
                        amount: (
                          <span className="billing-change-info">
                            ${Math.abs(availableCredits / 100).toFixed(2)}
                          </span>
                        )
                      }}
                    />
                  </span>
                </span>
              </div>
            )}

            <div
              className={classNames('billing-modal-desc', {
                card_loading: this.state.isUpcomingLoading
              })}
            >
              <div className="general-title mb_100">Monthly</div>

              {billingInfo &&
                billingInfo.lines &&
                billingInfo.lines.data &&
                billingInfo.lines.data.map((item, index) => (
                  <NumberFormat
                    key={index}
                    value={
                      item.amount / 100 &&
                      (item.amount / 100).toFixed(2)
                    }
                    displayType={'text'}
                    thousandSeparator
                    renderText={value => (
                      <EstimateItem
                        name={item.description}
                        amount={<span>${value}</span>}
                        endpoints={`${item.quantity} Hours`}
                      />
                    )}
                  />
                ))}
              <div className="estimated-revenue__divider" />

              <div className="grid-row grid-row_aic estimated-revenue__row_half">
                <div className="grid-col">
                  <div className="estimated-revenue__label">
                    <FormattedMessage {...messages.subTotal} />
                  </div>
                </div>
                <div className="grid-col grid-col_auto">
                  <div className="estimated-revenue__value c-label">
                    <NumberFormat
                      value={
                        (subTotal && parseFloat(subTotal / 100).toFixed(2)) ||
                        '0.00'
                      }
                      displayType={'text'}
                      thousandSeparator
                      renderText={value => <span>${value}</span>}
                    />
                  </div>
                </div>
              </div>

              <div className="grid-row grid-row_aic estimated-revenue__row_half">
                <div className="grid-col">
                  <div className="estimated-revenue__label">
                    <FormattedMessage {...messages.tax} />
                  </div>
                </div>
                <div className="grid-col grid-col_auto">
                  <div className="estimated-revenue__value c-label">$0.00</div>
                </div>
              </div>

              <div className="grid-row grid-row_aic estimated-revenue__row_half">
                <div className="grid-col">
                  <div className="estimated-revenue__label">
                    <FormattedMessage {...messages.total} />
                  </div>
                </div>
                <div className="grid-col grid-col_auto">
                  <div className="estimated-revenue__value c-label">
                    <NumberFormat
                      value={
                        totalAmount && parseFloat(totalAmount / 100).toFixed(2)
                      }
                      displayType={'text'}
                      thousandSeparator
                      renderText={value => <span>${value}</span>}
                    />
                  </div>
                </div>
              </div>

              {!!appliedBalance && (
                <div className="grid-row grid-row_aic estimated-revenue__row_half">
                  <div className="grid-col">
                    <div className="estimated-revenue__accent3">
                      <FormattedMessage {...messages.appliedBalance} />
                    </div>
                  </div>
                  <div className="grid-col grid-col_auto">
                    <div className="estimated-revenue__accent3">
                      <NumberFormat
                        value={
                          appliedBalance &&
                          parseFloat(appliedBalance / 100).toFixed(2)
                        }
                        displayType={'text'}
                        thousandSeparator
                        renderText={value => <span>${value}</span>}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="grid-row grid-row_aic estimated-revenue__row">
                <div className="grid-col">
                  <h3 className="estimated-revenue__text">
                    <FormattedMessage {...messages.dueToday} />
                  </h3>
                </div>
                <div className="grid-col grid-col_auto">
                  <div className="estimated-revenue__value_total">
                    <NumberFormat
                      value={
                        amountDue && parseFloat(amountDue / 100).toFixed(2)
                      }
                      displayType={'text'}
                      thousandSeparator
                      renderText={value => <span>USD {value}</span>}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        }
        onClose={this.onCloseModal}
      />
    );
  };

  render() {
    const { workspace, billing, subscription, plans } = this.props;
    const { showPlanPage, showModal } = this.state;

    const planInfo = workspace && workspace.plan && workspace.plan;
    const plan = workspace && workspace.plan && workspace.plan.features;

    const subscriptionId = subscription &&
    subscription.planSubscription &&
    subscription.planSubscription.subscriptions &&
    subscription.planSubscription.subscriptions.length &&
    subscription.planSubscription.subscriptions.find(item => item.productCategory === 'BASE') &&
    subscription.planSubscription.subscriptions.find(item => item.productCategory === 'BASE').subscriptionId;

    const addOnPlans = subscription &&
    subscription.planSubscription &&
    subscription.planSubscription.subscriptions &&
    subscription.planSubscription.subscriptions.length &&
    subscription.planSubscription.subscriptions.filter(item => item.productCategory === 'ADD_ON');

    if (showPlanPage) {
      return (
        <ChangePlan
          billing={billing}
          subscriptionId={subscriptionId}
          onClose={() => this.setState({ showPlanPage: false })}
          selectedPlan={planInfo.id}
        />
      );
    }

    const deviceCount =
      (workspace && workspace.currentDeviceCount * 100) / plan.currentDeviceCount;
    const deploymentCount =
      (workspace && workspace.currentDeploymentCount * 100) /
      plan.deploymentLimit;
    const profileCount =
      (workspace && workspace.currentProfileCount * 100) / plan.profileLimit;
    const dashboardCount =
      (workspace && workspace.currentDashboardCount * 100) /
      plan.dashboardLimit;
    const moduleCount =
      (workspace && workspace.currentModuleCount * 100) / plan.moduleLimit;
    const videoCount =
      (workspace && workspace.currentVideoCount * 100) / plan.videoLimit;
    const userCount =
      (workspace && workspace.userCounts * 100) / plan.userLimit;
    const applicationCount =
      (workspace && workspace.currentApplicationCount * 100) /
      plan.applicationLimit;

    if (this.state.isLoading) {
      return <LoadingIndicator color={this.props.color} />;
    }

    return (
      <ProcessPage
        onCancel={this.props.onClose || this.onClose}
        customClass="popup_grey"
      >
        {() => (
          <div>
            {showModal && this.renderModal()}
            <div className="headline2">
              <h1 className="headline__title">
                <FormattedMessage {...messages.workspaceLimitsAndUsage} />
              </h1>
              <h3 className="headline__subtitle">
                <FormattedMessage
                  {...messages.yourCurrentUsageDesc}
                  values={{
                    value: <span className="fw500">{planInfo.name}</span>
                  }}
                />
              </h3>
            </div>
            <div className='billing-limit-wrapper'>
              <div className='grid-col'>
                <div className="billing-limit-card">
                  <div className="billing-limit-section">
                    <div className="billing-limit-title">Workspace Usage</div>
                    {/* <div className="billing-limit-info">
                      <div className="billing-limit-info-first">
                        <div className="billing-limit-info-1">
                          <FormattedMessage {...messages.devices} />
                        </div>
                        <div className="billing-limit-info-2">
                          {plan.deviceLimit ? (
                            `${workspace && workspace.currentDeviceCount || 0} of ${
                              plan.deviceLimit
                            }`
                          ) : (
                            <FormattedMessage {...messages.unlimited} />
                          )}
                        </div>
                      </div>
                      <div className="billing-limit-info-second">
                        <div className="billing-limit-info-3">
                          <div className="billing-limit-progress-container">
                            <div
                              className="billing-limit-progress"
                              style={{
                                width: `${deviceCount <= 100 ? deviceCount : 0}%`
                              }}
                            />
                          </div>
                        </div>

                      </div>
                    </div> */}
                    <div className="billing-limit-info">
                      <div className="billing-limit-info-first">
                        <div className="billing-limit-info-1">
                          <FormattedMessage {...messages.profiles} />
                        </div>
                        <div className="billing-limit-info-2">
                          {plan.profileLimit ? (
                            `${workspace && workspace.currentProfileCount} of ${
                              plan.profileLimit
                            }`
                          ) : (
                            <FormattedMessage {...messages.unlimited} />
                          )}
                        </div>
                      </div>
                      <div className="billing-limit-info-second">
                        <div className="billing-limit-info-3">
                          <div className="billing-limit-progress-container">
                            <div
                              className="billing-limit-progress"
                              style={{
                                width: `${
                                  profileCount <= 100 ? profileCount : 0
                                }%`
                              }}
                            />
                          </div>
                        </div>
                        <div className="billing-limit-info-4" />
                      </div>
                    </div>
                    <div className="billing-limit-info">
                      <div className="billing-limit-info-first">
                        <div className="billing-limit-info-1">
                      Monthly Deployments
                        </div>
                        <div className="billing-limit-info-2">
                          {plan.profileLimit ? (
                            `${workspace && workspace.currentDeploymentCount} of ${
                              plan.deploymentLimit
                            }`
                          ) : (
                            <FormattedMessage {...messages.unlimited} />
                          )}
                        </div>
                      </div>
                      <div className="billing-limit-info-second">
                        <div className="billing-limit-info-3">
                          <div className="billing-limit-progress-container">
                            <div
                              className="billing-limit-progress"
                              style={{
                                width: `${
                                  deploymentCount <= 100 ? deploymentCount : 0
                                }%`
                              }}
                            />
                          </div>
                        </div>
                        <div className="billing-limit-info-4" />
                      </div>
                    </div>
                    <div className="billing-limit-info">
                      <div className="billing-limit-info-first">
                        <div className="billing-limit-info-1">
                          <FormattedMessage {...messages.modules} />
                        </div>
                        <div className="billing-limit-info-2">
                          {plan.moduleLimit ? (
                            `${workspace && workspace.currentModuleCount} of ${
                              plan.moduleLimit
                            }`
                          ) : (
                            <FormattedMessage {...messages.unlimited} />
                          )}
                        </div>
                      </div>
                      <div className="billing-limit-info-second">
                        <div className="billing-limit-info-3">
                          <div className="billing-limit-progress-container">
                            <div
                              className="billing-limit-progress"
                              style={{
                                width: `${moduleCount <= 100 ? moduleCount : 0}%`
                              }}
                            />
                          </div>
                        </div>
                        <div className="billing-limit-info-4" />
                      </div>
                    </div>
                    <div className="billing-limit-info">
                      <div className="billing-limit-info-first">
                        <div className="billing-limit-info-1">
                          <FormattedMessage {...messages.applications} />
                        </div>
                        <div className="billing-limit-info-2">
                          {plan.applicationLimit ? (
                            `${workspace && workspace.currentApplicationCount} of ${
                              plan.applicationLimit
                            }`
                          ) : (
                            <FormattedMessage {...messages.unlimited} />
                          )}
                        </div>
                      </div>
                      <div className="billing-limit-info-second">
                        <div className="billing-limit-info-3">
                          <div className="billing-limit-progress-container">
                            <div
                              className="billing-limit-progress"
                              style={{
                                width: `${
                                  applicationCount <= 100 ? applicationCount : 0
                                }%`
                              }}
                            />
                          </div>
                        </div>
                        <div className="billing-limit-info-4" />
                      </div>
                    </div>
                    <div className="billing-limit-info">
                      <div className="billing-limit-info-first">
                        <div className="billing-limit-info-1">
                          <FormattedMessage {...messages.versionsPerApplication} />
                        </div>
                        <div className="billing-limit-info-2">
                          {plan.applicationVersionLimit ? (
                        <>
                          <FormattedMessage {...messages.upto} />{' '}
                          {plan.applicationVersionLimit}
                        </>
                          ) : (
                            <FormattedMessage {...messages.unlimited} />
                          )}
                        </div>
                      </div>
                      <div className="billing-limit-info-second">
                        <div className="billing-limit-info-3" />
                        <div className="billing-limit-info-4" />
                      </div>
                    </div>
                    <div className="billing-limit-info">
                      <div className="billing-limit-info-first">
                        <div className="billing-limit-info-1">
                          <FormattedMessage {...messages.videos} />
                        </div>
                        <div className="billing-limit-info-2">
                          {plan.videoLimit ? (
                            `${workspace && workspace.currentVideoCount} of ${
                              plan.videoLimit
                            }`
                          ) : (
                            <FormattedMessage {...messages.unlimited} />
                          )}
                        </div>
                      </div>
                      <div className="billing-limit-info-second">
                        <div className="billing-limit-info-3">
                          <div className="billing-limit-progress-container">
                            <div
                              className="billing-limit-progress"
                              style={{
                                width: `${videoCount <= 100 ? videoCount : 0}%`
                              }}
                            />
                          </div>
                        </div>
                        <div className="billing-limit-info-4" />
                      </div>
                    </div>
                    <div className="billing-limit-info">
                      <div className="billing-limit-info-first">
                        <div className="billing-limit-info-1">
                          <FormattedMessage {...messages.dashboards} />
                        </div>
                        <div className="billing-limit-info-2">
                          {plan.dashboardLimit ? (
                            `${workspace && workspace.currentDashboardCount} of ${
                              plan.dashboardLimit
                            }`
                          ) : (
                            <FormattedMessage {...messages.unlimited} />
                          )}
                        </div>
                      </div>
                      <div className="billing-limit-info-second">
                        <div className="billing-limit-info-3">
                          <div className="billing-limit-progress-container">
                            <div
                              className="billing-limit-progress"
                              style={{
                                width: `${
                                  dashboardCount <= 100 ? dashboardCount : 0
                                }%`
                              }}
                            />
                          </div>
                        </div>
                        <div className="billing-limit-info-4" />
                      </div>
                    </div>
                    <div className="billing-limit-info">
                      <div className="billing-limit-info-first">
                        <div className="billing-limit-info-1">
                          <FormattedMessage {...messages.users} />
                        </div>
                        <div className="billing-limit-info-2">
                          {plan.userLimit ? (
                            `${workspace.userCounts} of ${plan.userLimit}`
                          ) : (
                            <FormattedMessage {...messages.unlimited} />
                          )}
                        </div>
                      </div>
                      <div className="billing-limit-info-second">
                        <div className="billing-limit-info-3">
                          <div className="billing-limit-progress-container">
                            <div
                              className="billing-limit-progress"
                              style={{
                                width: `${userCount <= 100 ? userCount : 0}%`
                              }}
                            />
                          </div>
                          <div className="billing-limit-info-4" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="billing-limit-section">
                    <div className="billing-limit-title">Data retention</div>
                    <div className="billing-limit-info">
                      <div className="billing-limit-info-first">
                        <div className="billing-limit-info-1">
                          <FormattedMessage {...messages.applicationData} />
                        </div>
                        <div className="billing-limit-info-2">
                          {plan.appDataRetentionDays ? (
                            `${plan.appDataRetentionDays} days`
                          ) : (
                            <FormattedMessage {...messages.unlimited} />
                          )}
                        </div>
                      </div>
                      <div className="billing-limit-info-second">
                        <div className="billing-limit-info-3">
                          <div />
                        </div>
                        <div className="billing-limit-info-4" />
                      </div>
                    </div>
                    <div className="billing-limit-info">
                      <div className="billing-limit-info-first">
                        <div className="billing-limit-info-1">
                          <FormattedMessage {...messages.deviceMetrics} />
                        </div>
                        <div className="billing-limit-info-2">
                          {plan.deviceMetricsRetentionDays ? (
                            `${plan.deviceMetricsRetentionDays} days`
                          ) : (
                            <FormattedMessage {...messages.unlimited} />
                          )}
                        </div>
                      </div>
                      <div className="billing-limit-info-second">
                        <div className="billing-limit-info-3">
                          <div />
                        </div>
                        <div className="billing-limit-info-4" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='grid-col'>
                <div className="billing-limit-card">
                  <div className="billing-limit-section">
                    <div className="billing-limit-title">Add-on Plans</div>
                  </div>
                  <div className="billing-limit-section">
                    {addOnPlans.map(item => {

                      const selectedPlan = plans.find(addPlan =>
                        (addPlan.name === item.planName) || (addPlan.product === item.productName));

                      return (
                        <div className="billing-limit-info">
                          <div className="billing-limit-info-first">
                            <div className="billing-limit-info-1">
                              {selectedPlan.prettyName}
                            </div>
                            <div className="billing-limit-info-2">
                              {selectedPlan.quantity}
                            </div>
                          </div>
                          <div className="billing-limit-info-second">
                            <div className="billing-limit-info-3">
                              <div className="billing-limit-progress-container">
                                <div
                                  className="billing-limit-progress"
                                  // style={{
                                  //   width: `${deviceCount <= 100 ? deviceCount : 0}%`
                                  // }}
                                />
                              </div>
                            </div>

                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </ProcessPage>
    );
  }
}

PlanLimitPage.propTypes = {
  color: PropTypes.string
};

PlanLimitPage.defaultProps = {
  color: DEFAULT_ACCENT
};

const withReducer = injectReducer({ key: 'billing', reducer });
const withSaga = injectSaga({ key: 'billing', saga });

const mapStateToProps = createStructuredSelector({
  billing: getBillingDetails(),
  plans: makePlanData(),
  subscriptionLoading: getSubscriptionLoadingState(),
  subscription: getSubscriptionDetails(),
  workspace: makeSelectFetchWorkspace()
});


const withConnect = connect(
  mapStateToProps,
  {
    getBilling: getBillingDetailsRequest,
    fetchWorkspaceDetailRequest,
    updateBillingRequest,
    getInvoicesRequest,
    getSubscription: getSubscriptionDetailsRequest,
    getUpcomingInvoicesRequest
  }
);


export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect,
  withConnect
)(PlanLimitPage);
