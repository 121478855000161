import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import React, { Component } from 'react';
import { DEFAULT_ACCENT } from 'utils/constants';
import messages from 'utils/messages';
import ListScroll from 'components/List/ListScroll';
import TopNav from 'containers/TopNav';


class PlanLimitPage extends Component {
  state = {
    showPlanPage: false,
    products: [],
    quantity: 1,
  };

  render() {
    return (
      <>
        <TopNav
          addSpacing
          onBack={() => null}
          title={<FormattedMessage {...messages.workspaceLimitsAndUsage} />}
          hideNotification
        />
        <div className="mobile-only mx-b-20" />
        <ListScroll>
          {() => (
            <div>
              <div className="headline2">
                <h1 className="headline__title heading__loading">
                  <FormattedMessage {...messages.workspaceLimitsAndUsage} />
                </h1>
                <h3 className="headline__subtitle heading__loading">
                  <FormattedMessage
                    {...messages.yourCurrentUsageDesc}
                    values={{
                      value: <span className="fw500">Testing</span>,
                    }}
                  />
                </h3>
              </div>
              <div className='billing-limit-wrapper card_loading'>
                <div className='grid-col'>
                  <div className="billing-limit-card">
                    <div className="billing-limit-section">
                      <div className="billing-limit-title">Workspace Usage</div>
                     
                      <div className="billing-limit-info">
                        <div className="billing-limit-info-first">
                          <div className="billing-limit-info-1">
                            <FormattedMessage {...messages.profiles} />
                          </div>
                          <div className="billing-limit-info-2">
                            50
                          </div>
                        </div>
                        <div className="billing-limit-info-second">
                          <div className="billing-limit-info-3">
                            <div className="billing-limit-progress-container">
                              <div
                                className="billing-limit-progress"
                                style={{
                                  width: '100%'
                                }}
                              />
                            </div>
                          </div>
                          <div className="billing-limit-info-4" />
                        </div>
                      </div>
                      <div className="billing-limit-info">
                        <div className="billing-limit-info-first">
                          <div className="billing-limit-info-1">
                      Monthly Deployments
                          </div>
                          <div className="billing-limit-info-2">
                            50
                          </div>
                        </div>
                        <div className="billing-limit-info-second">
                          <div className="billing-limit-info-3">
                            <div className="billing-limit-progress-container">
                              <div
                                className="billing-limit-progress"
                                style={{
                                  width: '100%'
                                }}
                              />
                            </div>
                          </div>
                          <div className="billing-limit-info-4" />
                        </div>
                      </div>
                      <div className="billing-limit-info">
                        <div className="billing-limit-info-first">
                          <div className="billing-limit-info-1">
                            <FormattedMessage {...messages.modules} />
                          </div>
                          <div className="billing-limit-info-2">
                            50
                          </div>
                        </div>
                        <div className="billing-limit-info-second">
                          <div className="billing-limit-info-3">
                            <div className="billing-limit-progress-container">
                              <div
                                className="billing-limit-progress"
                                style={{
                                  width: '100%'
                                }}
                              />
                            </div>
                          </div>
                          <div className="billing-limit-info-4" />
                        </div>
                      </div>
                      <div className="billing-limit-info">
                        <div className="billing-limit-info-first">
                          <div className="billing-limit-info-1">
                            <FormattedMessage {...messages.applications} />
                          </div>
                          <div className="billing-limit-info-2">
                              50
                          </div>
                        </div>
                        <div className="billing-limit-info-second">
                          <div className="billing-limit-info-3">
                            <div className="billing-limit-progress-container">
                              <div
                                className="billing-limit-progress"
                                style={{
                                  width: '100%'
                                }}
                              />
                            </div>
                          </div>
                          <div className="billing-limit-info-4" />
                        </div>
                      </div>
                      <div className="billing-limit-info">
                        <div className="billing-limit-info-first">
                          <div className="billing-limit-info-1">
                            <FormattedMessage {...messages.versionsPerApplication} />
                          </div>
                          <div className="billing-limit-info-2">
                            50
                          </div>
                        </div>
                        <div className="billing-limit-info-second">
                          <div className="billing-limit-info-3" />
                          <div className="billing-limit-info-4" />
                        </div>
                      </div>
                      <div className="billing-limit-info">
                        <div className="billing-limit-info-first">
                          <div className="billing-limit-info-1">
                            <FormattedMessage {...messages.videos} />
                          </div>
                          <div className="billing-limit-info-2">
                            50
                          </div>
                        </div>
                        <div className="billing-limit-info-second">
                          <div className="billing-limit-info-3">
                            <div className="billing-limit-progress-container">
                              <div
                                className="billing-limit-progress"
                                style={{
                                  width: '100%'
                                }}
                              />
                            </div>
                          </div>
                          <div className="billing-limit-info-4" />
                        </div>
                      </div>
                      <div className="billing-limit-info">
                        <div className="billing-limit-info-first">
                          <div className="billing-limit-info-1">
                            <FormattedMessage {...messages.dashboards} />
                          </div>
                          <div className="billing-limit-info-2">
                            50
                          </div>
                        </div>
                        <div className="billing-limit-info-second">
                          <div className="billing-limit-info-3">
                            <div className="billing-limit-progress-container">
                              <div
                                className="billing-limit-progress"
                                style={{
                                  width: '100%'
                                }}
                              />
                            </div>
                          </div>
                          <div className="billing-limit-info-4" />
                        </div>
                      </div>
                      <div className="billing-limit-info">
                        <div className="billing-limit-info-first">
                          <div className="billing-limit-info-1">
                            <FormattedMessage {...messages.users} />
                          </div>
                          <div className="billing-limit-info-2">
                            50
                          </div>
                        </div>
                        <div className="billing-limit-info-second">
                          <div className="billing-limit-info-3">
                            <div className="billing-limit-progress-container">
                              <div
                                className="billing-limit-progress"
                                style={{
                                  width: '100%'
                                }}
                              />
                            </div>
                            <div className="billing-limit-info-4" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="billing-limit-section">
                      <div className="billing-limit-title">Data retention</div>
                      <div className="billing-limit-info">
                        <div className="billing-limit-info-first">
                          <div className="billing-limit-info-1">
                            <FormattedMessage {...messages.applicationData} />
                          </div>
                          <div className="billing-limit-info-2">
                            50
                          </div>
                        </div>
                        <div className="billing-limit-info-second">
                          <div className="billing-limit-info-3">
                            <div />
                          </div>
                          <div className="billing-limit-info-4" />
                        </div>
                      </div>
                      <div className="billing-limit-info">
                        <div className="billing-limit-info-first">
                          <div className="billing-limit-info-1">
                            <FormattedMessage {...messages.deviceMetrics} />
                          </div>
                          <div className="billing-limit-info-2">
                            50
                          </div>
                        </div>
                        <div className="billing-limit-info-second">
                          <div className="billing-limit-info-3">
                            <div />
                          </div>
                          <div className="billing-limit-info-4" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='grid-col'>
                  <div className="billing-limit-card card_loading">
                    <div className="billing-limit-section">
                      <div className="billing-limit-title">Add-on Plans</div>
                    </div>
                    <div className="billing-limit-section">
                      {[1, 2, 3, 4, 5].map(() => {

                        return (
                          <div className="billing-limit-info">
                            <div className="billing-limit-info-first">
                              <div className="billing-limit-info-1">
                                Testing
                              </div>
                              <div className="billing-limit-info-2">
                                100
                              </div>
                            </div>
                            <div className="billing-limit-info-second">
                              <div className="billing-limit-info-3">
                                <div className="billing-limit-progress-container">
                                  <div
                                    className="billing-limit-progress"
                                    // style={{
                                    //   width: `${deviceCount <= 100 ? deviceCount : 0}%`
                                    // }}
                                  />
                                </div>
                              </div>

                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </ListScroll>
      </>
    );
  }
}

PlanLimitPage.propTypes = {
  color: PropTypes.string,
};

PlanLimitPage.defaultProps = {
  color: DEFAULT_ACCENT,
};

export default PlanLimitPage;
