import React from 'react';
import { routes } from 'routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import slugify from 'react-slugify';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { makeSelectFetchWorkspace } from 'containers/Settings/Workspace/selectors';

import messages from 'utils/messages';

const Breadcrumb = ({
  children, customColor, workspace, className,
}) => (
  <div className={classNames(`breadcrumbs breadcrumbs_${customColor}`, {
    [className]: className,
  })}
  >
    {React.Children.map(children, (child, index) => React.cloneElement(child, {
      workspace,
      isLast: index === children.length - 1,
    }))}
  </div>
);

export const BreadcrumbItem = ({
  route,
  isLast,
  action,
  workspace,
  ignoreSlug,
  customLink,
}) => {
  const selectedRoute = routes.find(routeDetail => routeDetail.name === route) || route;

  let pathName = workspace
    && selectedRoute
    && selectedRoute.path
    && selectedRoute.path.replace('/:slug', slugify(workspace.name));

  if (customLink) {
    return (
      <>
        <Link className="breadcrumbs__link" to={`${customLink}`}>
          <FormattedMessage {...messages[selectedRoute.id]} />
        </Link>
        <div className="breadcrumbs__arrow" />
      </>
    );
  }

  if (ignoreSlug) {
    pathName = selectedRoute && selectedRoute.path;
  }

  if (!selectedRoute && !isLast) {
    return <span />;
  }

  return isLast ? (
    <a href="javascript:void(0)" onClick={action} className="breadcrumbs__link">
      {route}
    </a>
  ) : (
    <>
      <Link className="breadcrumbs__link" to={`/${pathName}`}>
        <FormattedMessage {...messages[selectedRoute.id]} />
      </Link>
      <div className="breadcrumbs__arrow" />
    </>
  );
};

BreadcrumbItem.propTypes = {
  route: PropTypes.string,
  isLast: PropTypes.bool,
};

Breadcrumb.propTypes = {
  children: PropTypes.node,
  customColor: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  workspace: makeSelectFetchWorkspace(),
});

const withConnect = connect(
  mapStateToProps,
  null,
);

export default withConnect(Breadcrumb);
