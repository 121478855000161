import { routeMapping } from "api/constants";
import { getMarketplaceSolution, purchaseSolution } from "api/marketplace";
import Button from "components/Button";
import ProcessPage from "components/ProcessPage";
import SubscriptionCard from "components/SubscriptionCard";
import SubscriptionLoadingCard from "components/SubscriptionLoadingCard";
import TermsFooter from "components/TermsFooter";
import { updateInfraBanner } from "containers/App/actions";
import { intl } from "containers/LanguageProvider/intlProvider";
import { makeSelectFetchWorkspace } from "containers/Settings/Workspace/selectors";
import {
  defaultNotifier,
  marketplaceNotifier,
  successNotifier,
} from "functions/notificationHandler";
import {
  AmericanExpressIcon,
  CreditCardIcon,
  JcbIcon,
  MasterCardIcon,
  VisaIcon,
} from "images/common";
import localForage from "localforage";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import {withRouter } from 'withRouter'
import slugify from "react-slugify";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { socket } from "socket";
import { DEFAULT_ACCENT } from "utils/constants";
import injectReducer from "utils/injectReducer";
import injectSaga from "utils/injectSaga";
import messages from "utils/messages";
import { getBillingDetailsRequest } from "../../Billing/actions";
import reducer from "../../Billing/reducer";
import saga from "../../Billing/saga";
import { getBillingDetails } from "../../Billing/selectors";
import TermsLink from "components/TermsLink";

const marketplaceMessages = {
  module_install_success: "isNowInstalled",
  module_update_success: "moduleUpdated",
  module_install_fail: "marketplaceInstallFailed",
  module_update_fail: "moduleUpdateFailed",
  module_remove_success: "marketplaceRemoved",
  module_remove_fail: "failedToRemoveModule",
};

class ConfirmMarketplaceOrder extends Component {
  state = {
    payload: {},
    activeModule: 0,
    interval: "year",
  };

  getIcon = {
    visa: <VisaIcon />,
    amex: <AmericanExpressIcon />,
    jcb: <JcbIcon />,
    mastercard: <MasterCardIcon />,
  };

  getPrice = async () => {
    const { modules } = this.props;
    const { activeModule } = this.state;

    this.setState({ price: "", provider: "" });

    if (!modules || activeModule >= modules.length) {
      return false;
    }

    this.setState({ fetching: true });
    const { data } = await getMarketplaceSolution({
      id: modules[activeModule].link,
    });
    this.setState({ fetching: false });

    if (data.errors) {
      return defaultNotifier(data.errors);
    }

    this.setState({
      moduleSolution: data.data.solution,
      provider: data.data.solution.workspace.name,
    });
  };

  async componentDidMount() {
    const result = await localForage.getItem("activePartnerId");
    const { workspace } = this.props;

    if (result && result.id) {
      this.setState({ payload: result });
    }

    this.props.getBillingDetailsRequest();
    this.getPrice();

    socket
      .off(`notification-${workspace.id}`)
      .on(`notification-${workspace.id}`, (data) => {
        if (data.isMarketplace) {
          if (data.isMarketplace) {
            this.props.updateInfraBanner(false);
            return successNotifier(
              `${intl.formatMessage({ ...messages.module })} 
            ${data.moduleName} ${intl.formatMessage({
                ...messages[marketplaceMessages[data.message]],
              })}`,
              `/${workspace && slugify(workspace.name)}/modules/${
                data.moduleId
              }`,
              true
            );
          }
        }
      });
  }

  componentWillUnmount() {
    localForage.setItem("activePartnerId", null);
  }

  onClose = async () => {
    const { workspace } = this.props;
    const workspacePath = workspace && slugify(workspace.name);
    await localForage.setItem("activePartnerId", null);

    return this.props.router.navigate(
      `/${workspacePath}${routeMapping.SETTINGS.path}`
    );
  };

  onBack = () => {
    this.props.router.navigate(-1);
  };

  onSubmit = async (isApplication) => {
    const { workspace, modules } = this.props;
    const { activeModule } = this.state;
    this.setState({ loading: true });
    const workspacePath = workspace && slugify(workspace.name);

    if (isApplication) {
      const payload = {
        id: this.state.payload.id,
        priceId: this.state.payload.priceId.id,
        // subscriptionQuantity: this.state.payload.subscriptionQuantity || 0
      };

      const { data } = await purchaseSolution(payload);
      this.setState({ loading: false });

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      marketplaceNotifier(
        `${intl.formatMessage({ ...messages.theNewApplication })} ${
          this.state.payload.solution.name
        }
       ${intl.formatMessage({ ...messages.addedAsTemplate })}`,
        `/${workspacePath}${routeMapping.NEW_LIBRARY_APPLICATION_TEMPLATE.path}`,
        true
      );

      await localForage.setItem("activePartnerId", null);

      return this.props.router.navigate(
        `/${workspacePath}${routeMapping.SETTINGS.path}?purchase=true`
      );
    }

    if (!modules) {
      const payload = {
        id: this.state.payload.id,
        priceId: this.state.payload.priceId.id,
        // subscriptionQuantity: this.state.payload.subscriptionQuantity || 0
      };

      const { data } = await purchaseSolution(payload);
      this.setState({ loading: false });

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      defaultNotifier(
        `${intl.formatMessage({ ...messages.module })} ${
          this.state.payload.solution.name
        }
       ${intl.formatMessage({ ...messages.isInstalling })}`
      );

      await localForage.setItem("activePartnerId", null);

      return this.props.router.navigate(
        `/${workspacePath}${routeMapping.SETTINGS.path}?purchase=true`
      );
    }

    if (activeModule >= modules.length) {
      const payload = {
        id: this.state.payload.id,
        priceId: this.state.priceId,
      };

      const { data } = await purchaseSolution(payload);
      this.setState({ loading: false });

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      defaultNotifier(
        `${intl.formatMessage({ ...messages.module })} ${
          this.state.payload.solution.name
        }
       ${intl.formatMessage({ ...messages.isInstalling })}`
      );

      await localForage.setItem("activePartnerId", null);

      return this.props.router.navigate(
        `/${workspacePath}${routeMapping.SETTINGS.path}`
      );
    }

    this.setState(
      {
        loading: false,
        activeModule: this.state.activeModule + 1,
      },
      async () => {
        this.getPrice();
        const { data } = await purchaseSolution({
          id: modules[this.state.activeModule - 1].link,
          priceId: this.state.priceId,
          // subscriptionQuantity: 1
        });

        if (data.errors) {
          return defaultNotifier(data.errors);
        }
      }
    );
  };

  getInterval = () => {
    if (this.state.interval === "year") {
      return <FormattedMessage {...messages.yearly} />;
    }
    return <FormattedMessage {...messages.monthly} />;
  };

  render() {
    const { color, isLoading, billing, workspace, modules } = this.props;
    const {
      payload,
      activeModule,
      loading,
      price,
      interval,
      provider,
      fetching,
      moduleSolution,
    } = this.state;
    const installRequiredModule = modules && modules[activeModule];

    const card = billing.paymentMethod && billing.paymentMethod.card;
    const expiryData = card && `${card.exp_month}/${card.exp_year}`;
    const trails = installRequiredModule
      ? price && price.recurring && price.recurring.trial_period_days
      : payload &&
        payload.solution &&
        payload.solution[
          interval === "year" ? "yearlyPrice" : "monthlyPrice"
        ] &&
        payload.solution[interval === "year" ? "yearlyPrice" : "monthlyPrice"]
          .recurring &&
        payload.solution[interval === "year" ? "yearlyPrice" : "monthlyPrice"]
          .recurring.trial_period_days;

    const isApplicationType =
      !installRequiredModule &&
      payload.solution &&
      payload.solution.type === "application";

    return (
      <ProcessPage onCancel={this.onClose}>
        {() => (
          <div className="payment-layout">
            <div>
              <div className="headline flow-modules">
                <div className="flow-modules-icon">
                  <img src={payload.solution && payload.solution.logo} width={85} height={85} />
                </div>
                <h1 className="headline__title">
                  Install {payload.solution && payload.solution.name}{" "}
                  {payload.solution && payload.solution.type === "application"
                    ? "Application"
                    : "Module"}
                </h1>
                <h4 className="headline__subtitle">
                  {payload.solution && payload.solution.type === "application"
                    ? "The item requires the following assets to be installed."
                    : "Continue to install this module to your workspace."}
                </h4>
              </div>

              <div className="subscriptions">
                {payload.solution && !installRequiredModule && (
                  <SubscriptionCard
                    subscriptionDetails={
                      installRequiredModule && modules[activeModule]
                        ? this.state.solution
                        : payload.solution
                    }
                    // payload.solution}
                    item={payload.solution}
                    color={color}
                    onBack={this.props.onBack}
                    interval={this.state.interval}
                    isApplicationType={isApplicationType}
                    minEndpoints={
                      payload.solution &&
                      payload.solution[
                        interval === "year" ? "yearlyPrice" : "monthlyPrice"
                      ] &&
                      payload.solution[
                        interval === "year" ? "yearlyPrice" : "monthlyPrice"
                      ].metadata.minEndpoints
                    }
                    provider={
                      installRequiredModule
                        ? provider
                        : payload.solution &&
                          payload.solution.workspace &&
                          payload.solution.workspace.name
                    }
                    onRemove={this.onRemove}
                    onShowPlanPage={this.onShowPlanPage}
                    onShowConfirmationPage={this.onShowConfirmationPage}
                    isMPPage
                    trialPeriods={trails}
                    onUpdateInterval={(priceId, int) =>
                      this.setState({ priceId, interval: int })
                    }
                    logo={
                      (installRequiredModule && modules[activeModule].logo) ||
                      payload.solution.logo ||
                      "http://placehold.it/42x42"
                    }
                  />
                )}
                {this.state.fetching && <SubscriptionLoadingCard />}
                {installRequiredModule &&
                  !this.state.fetching &&
                  moduleSolution && (
                    <SubscriptionCard
                      subscriptionDetails={moduleSolution}
                      item={moduleSolution}
                      color={color}
                      onBack={this.props.onBack}
                      interval={this.state.interval}
                      isApplicationType={isApplicationType}
                      minEndpoints={
                        moduleSolution &&
                        moduleSolution[
                          interval === "year" ? "yearlyPrice" : "monthlyPrice"
                        ] &&
                        moduleSolution[
                          interval === "year" ? "yearlyPrice" : "monthlyPrice"
                        ].metadata.minEndpoints
                      }
                      provider={provider}
                      onRemove={this.onRemove}
                      onShowPlanPage={this.onShowPlanPage}
                      onShowConfirmationPage={this.onShowConfirmationPage}
                      isMPPage
                      trialPeriods={trails}
                      onUpdateInterval={(priceId, int) =>
                        this.setState({ priceId, interval: int })
                      }
                      logo={
                        (installRequiredModule && modules[activeModule].logo) ||
                        "http://placehold.it/42x42"
                      }
                    />
                  )}
              </div>
              {/* <div className="billing-content-box billing-content-box_dark">
                <div className="overview__title text-capitalize">
                  <FormattedMessage {...messages.payment} />
                </div>
                <div className="grid-row">
                  <div className="grid-col grid-col_5">
                    <div className="overview__label">
                      <FormattedMessage {...messages.billingPeriod} />
                    </div>
                    <div className="overview__value text-capitalize">
                      {payload &&
                      !installRequiredModule &&
                      payload.solution &&
                      payload.solution.type === 'application' ? (
                          <FormattedMessage {...messages.oneTime} />
                        ) : (
                          this.getInterval()
                        )}
                    </div>
                  </div>

                  <div className="grid-col">
                    <div className="overview__payment h4">
                      <div className="grid-row grid-row_aic">
                        <div className="grid-col grid-col_auto">
                          {this.getIcon[card && card.brand] || (
                            <CreditCardIcon />
                          )}
                        </div>
                        <div className="grid-col">
                          <h4 className="overview__card_item">
                            •••• •••• •••• {card && card.last4}
                          </h4>
                          <h4 className="overview__card_item c-label">
                            {billing.paymentMethod &&
                              billing.paymentMethod.billing_details.name}
                          </h4>
                          <h4 className="overview__card_item c-label">
                            <FormattedMessage {...messages.expires} />{' '}
                            {expiryData}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>

            <div className="center">
              <h4 className="billing__agree mx-b_25">
                By clicking “Install now” you agree to the modules <TermsLink />{" "}
                and the viso.ai <TermsLink />.
              </h4>
              <Button
                type="submit"
                className={`button button_${color}`}
                onClick={() => this.onSubmit(isApplicationType)}
                disabled={isLoading || loading || fetching}
              >
                {isLoading || loading || fetching ? (
                  <FormattedMessage {...messages.loading} />
                ) : (
                  <FormattedMessage {...messages.confirm} />
                )}
              </Button>
            </div>
          </div>
        )}
      </ProcessPage>
    );
  }
}

ConfirmMarketplaceOrder.propTypes = {
  user: PropTypes.object,
  color: PropTypes.string,
};

ConfirmMarketplaceOrder.defaultProps = {
  color: DEFAULT_ACCENT,
};

const withReducer = injectReducer({ key: "billing", reducer });
const withSaga = injectSaga({ key: "billing", saga });

const mapStateToProps = createStructuredSelector({
  workspace: makeSelectFetchWorkspace(),
  billing: getBillingDetails(),
});

export default compose(
  withReducer,
  withSaga,
  connect(mapStateToProps, { getBillingDetailsRequest, updateInfraBanner }),
  withRouter
)(ConfirmMarketplaceOrder);
