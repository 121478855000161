import classNames from 'classnames';
import AHref from 'components/AHref';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';
import { getMonthlyMRR, getYearlyMRR, isFreePlan } from 'utils/common';
import { EXTERNAL_URL } from 'utils/constants';
import messages from 'utils/messages';


const planTitle = {
  Starter: 'Evaluate Viso Suite for your projects',
  Evaluation: 'Evaluate Viso Suite for your projects',
  Personal: 'Evaluate Viso Suite for your projects',
  Business: 'Ideal for small to mid sized organizations',
  Standard: 'Ideal for small to mid sized organizations',
  Professional: 'Ideal for unlimited apps with dedicated support',
  Production: 'Ideal for unlimited apps with dedicated support',
};

const PlanPrice = ({
  endpoints,
  isEnterprise,
  plan,
  isValidForCustomer,
  onSelectPlan,
  intervalType,
  selectedPlan,
  currentPlan,
}) => {
  // const inputNumber = isNaN(endpoints) ? endpoints && endpoints.replace(/,/g, '') : endpoints;
  // const minEndpoint = plan.monthlyPrice.minEndpoints;
  const minEndpoints = plan.minEndpoints

  const activeEndpoint = minEndpoints <= parseInt(endpoints, 10) ? endpoints : minEndpoints;

  const totalPrice =
    intervalType === 'year'
      ? getYearlyMRR(activeEndpoint, plan)
      : getMonthlyMRR(activeEndpoint, plan) || 0;
  let currentPricePerInterval = totalPrice


  if (!activeEndpoint) {
    currentPricePerInterval = 0.0;
  }

  let trialPeriods =
  plan[intervalType === 'year' ? 'yearlyPrice' : 'monthlyPrice'] &&
  plan[intervalType === 'year' ? 'yearlyPrice' : 'monthlyPrice'].trialPeriodDays;

  if (currentPlan) {
    trialPeriods = '';
  }

  const isFree = isFreePlan(plan, intervalType);

  const trialFee =
    plan[intervalType === 'year' ? 'yearlyPrice' : 'monthlyPrice'] &&
    plan[intervalType === 'year' ? 'yearlyPrice' : 'monthlyPrice'].trialFee;

  return (
    <div
      className={classNames('plan-info-wrapper', {
        active: selectedPlan === plan.id,
      })}
    >
      <div>
        {isEnterprise ? (
          <>
            <div className="plan-info-container">
              <div className="plan-info-header">{plan.name}</div>
              <div className="plan-info-enterprise">
                <FormattedMessage {...messages.enterprisePlanPricing} />
              </div>
              <AHref href={EXTERNAL_URL.contact} target="_blank">
                <Button
                  type="button"
                  className="button button_xxm button_accent5 button_plan"
                >
                  <FormattedMessage {...messages.contactUsBtn} />
                </Button>
              </AHref>
            </div>
          </>
        ) : (
          <>
            <div className="plan-info-container">
              <div className="plan-info-header">{plan.name}</div>
              <div className="plan-info-price">
                <>
                  {isFree && (
                    <div>
                      <span className="plan-info-interval" />
                      <FormattedMessage {...messages.free} />
                    </div>
                  )}

                  {!isFree && (
                  <>
                    <div>
                      {isValidForCustomer ? (
                        <NumberFormat
                          value={currentPricePerInterval}
                          displayType="text"
                          thousandSeparator="’"
                          prefix="$"
                          renderText={value => (
                            <>
                              {value}
                              <span className="plan-info-interval">/mo</span>
                            </>
                          )}
                        />
                      ) : (
                        <div>
                          <span className="plan-info-interval" />
                          N/a
                        </div>
                      )}
                    </div>
                  </>
                  )
                  }
                </>
              </div>
              {isFree
                ? (
                  <div className="plan-info-price-desc">
                    start and test all
                    {' '}
                    <br />
                    features for free
                  </div>
                )
                : (
                  <div className="plan-info-price-desc">
                    {/* <FormattedMessage {...messages.aMonth} /> */}
                    <div>
                      {planTitle[plan.name] || 'Ideal for small to mid sized organizations'}
                    </div>
                  </div>
                )
              }
              {currentPlan === plan.id ? (
                <Button
                  type="button"
                  className="button button_xxm button_accent5 button_plan button_plan_current"
                >
                  <FormattedMessage {...messages.currentPlan} />
                </Button>
              ) : (
                <Button
                  type="button"
                  onClick={() => {
                    if (isValidForCustomer) {
                      onSelectPlan(
                        plan,
                        minEndpoints,
                        currentPricePerInterval,
                        intervalType,
                      );
                    }
                  }}
                  className={classNames(
                    'button button_xxm button_accent5 button_plan',
                    {
                      button_plan_current: !isValidForCustomer,
                    },
                  )}
                >
                  {isFree || (trialPeriods && !trialFee) ? (
                    'Start for free'
                  ) : (
                    <>
                      {trialPeriods && trialFee ? (
                        <NumberFormat
                          value={trialFee / 100}
                          displayType="text"
                          thousandSeparator="’"
                          prefix="$"
                          renderText={value => (
                            <>
                              <FormattedMessage
                                {...messages.startTrail}
                                values={{ days: value }}
                              />
                            </>
                          )}
                        />
                      ) : (
                        <FormattedMessage {...messages.getStarted} />
                      )}
                    </>
                  )}
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

PlanPrice.propTypes = {
  loginRequest: PropTypes.func,
};

export default PlanPrice;
