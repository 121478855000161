// Revalidate (i.e. React) Validators

import { createValidator } from 'revalidate';
import owaspPasswordChecker from 'owasp-password-strength-test';
import validator from 'validator';

// eslint-disable-next-line
export const EMAIL_REGEX =  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const PASSWORD_REGEX = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/i;

// eslint-disable-next-line
export const URL_REGEX = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
// eslint-disable-next-line
export const FULL_NAME_REGEX = /^([a-zA-Z\u00a1-\uffff]{2,}\s[a-zA-Z\u00a1-\uffff]{1,}'?-?[a-zA-Z\u00a1-\uffff]{2,}\s?([a-zA-Z\u00a1-\uffff]{1,})?)/i;


export const isValidEmail = createValidator(
  message => (value) => {
    if (value && !EMAIL_REGEX.test(value)) {
      return message;
    }
  },
  'Invalid email address',
);

export const isValidPhone = createValidator(
  message => (value) => {
    if (value && !validator.isMobilePhone(value)) {
      return message;
    }
  },
  'Invalid phone number',
);

export const isValidWebsite = createValidator(
  message => (value) => {
    if (value && !URL_REGEX.test(value)) {
      return message;
    }
  },
  'Invalid website address',
);

export const isValidFullname = createValidator(
  message => (value) => {
    if (value && !FULL_NAME_REGEX.test(value)) {
      return message;
    }
  },
  'Please add your full name',
);

export const isValidPassword = createValidator(
  () => (value) => {
    if (!value) {
      return 'Please set a secure password';
    }
    owaspPasswordChecker.config({
      allowPassphrases: false,
      maxLength: 32,
      minLength: 8,
      minOptionalTestsToPass: 3,
    });

    const result = value && owaspPasswordChecker.test(value);

    if (value && owaspPasswordChecker.test(value)) {
      return result.errors[0];
    }
  },
  'Password must be 8 characters, at least one letter, symbol and a number',
);

export const passwordsMatch = (value, allValues) => (value !== allValues.password ? 'Passwords don\'t match' : undefined);

export const oldPasswordsMatch = (value, allValues) => {
  if (allValues) {
    return value === (allValues && allValues.currentPassword) ? 'New password cannot be the same as old' : undefined;
  }
};

export const validateUrl = (value) => {
  // eslint-disable-next-line
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
  + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
  + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
  + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
  + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(value);
};
