import {
  takeEvery,
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import {
  listUserGroups,
  addUserGroup,
  getUserGroup,
  removeUserGroup,
  updateUserGroup,
} from 'api/users';
import {
  defaultNotifier, successNotifier,
} from 'functions/notificationHandler';
import {
  ADD_USER_GROUP_REQUEST,
  LIST_USER_GROUP_REQUEST,
  REMOVE_USER_GROUP_REQUEST,
  USER_GROUP_REQUEST,
  UPDATE_USER_GROUP_REQUEST,
} from './constants';
import {
  userGroupListSuccess,
  userGroupListError,
  getUserGroupSuccess,
  getUserGroupError,
  userGroupAddSuccess,
  userGroupAddError,
  userGroupRemoveSuccess,
  userGroupRemoveError,
  userGroupUpdateSuccess,
  userGroupUpdateError,
} from './actions';


function* userGroupListSaga(action) {
  try {
    const { payload } = action;
    const response = yield call(listUserGroups, payload);
    if (response.data.errors) {
      yield put(userGroupListError(response.data.errors));
      defaultNotifier(response.data.errors);
    } else {
      yield put(userGroupListSuccess(response.data.data.userGroups));
    }
  } catch (err) {
    yield put(userGroupListError(err));
    defaultNotifier(err);
  }
}

function* userGroupSaga(action) {
  try {
    const { payload } = action;
    const response = yield call(getUserGroup, payload);
    if (response.data.errors) {
      yield put(getUserGroupError(response.data.errors));
      defaultNotifier(response.data.errors);
    } else {
      yield put(getUserGroupSuccess(response.data.data.userGroup));
    }
  } catch (err) {
    yield put(getUserGroupError(err));
    defaultNotifier(err);
  }
}

function* userGroupAddSaga(action) {
  try {
    const { payload } = action;
    delete payload.onCallback;

    const response = yield call(addUserGroup, payload);
    if (response.data.errors) {
      yield put(userGroupAddError(response.data.errors));
      defaultNotifier(response.data.errors);
    } else {
      yield put(userGroupAddSuccess(response.data.data.createUserGroup));
      successNotifier('A new user group is added');
    }
  } catch (err) {
    yield put(userGroupAddError(err));
    defaultNotifier(err);
  }
}

function* userGroupUpdateSaga(action) {
  try {
    const { payload } = action;
    const response = yield call(updateUserGroup, payload);
    if (response.data.errors) {
      yield put(userGroupUpdateError(response.data.errors));
      defaultNotifier(response.data.errors);
    } else {
      yield put(userGroupUpdateSuccess(response.data.data.updateUserGroup));
      defaultNotifier('User group changes saved.');
    }
  } catch (err) {
    yield put(userGroupUpdateError(err));
    defaultNotifier(err);
  }
}

function* userGroupRemoveSaga(action) {
  try {
    const { payload } = action;
    const response = yield call(removeUserGroup, payload);
    if (response.data.errors) {
      yield put(userGroupRemoveError(response.data.errors));
      defaultNotifier(response.data.errors);
    } else {
      yield put(userGroupRemoveSuccess(response.data.data.deleteUserGroup));
    }
  } catch (err) {
    yield put(userGroupRemoveError(err));
    defaultNotifier(err);
  }
}

export default function* deviceRootSaga() {
  yield takeEvery(LIST_USER_GROUP_REQUEST, userGroupListSaga);
  yield takeLatest(ADD_USER_GROUP_REQUEST, userGroupAddSaga);
  yield takeLatest(REMOVE_USER_GROUP_REQUEST, userGroupRemoveSaga);
  yield takeLatest(UPDATE_USER_GROUP_REQUEST, userGroupUpdateSaga);
  yield takeLatest(USER_GROUP_REQUEST, userGroupSaga);
}
