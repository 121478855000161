import React from "react";

export default () => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_29103_86183)">
      <path
        d="M11.5417 0.875H1.54167C0.616667 0.875 -0.125 1.625 -0.125 2.54167V14.2083C-0.125 15.125 0.616667 15.875 1.54167 15.875H13.2083C14.125 15.875 14.875 15.125 14.875 14.2083V4.20833L11.5417 0.875ZM7.375 14.2083C5.99167 14.2083 4.875 13.0917 4.875 11.7083C4.875 10.325 5.99167 9.20833 7.375 9.20833C8.75833 9.20833 9.875 10.325 9.875 11.7083C9.875 13.0917 8.75833 14.2083 7.375 14.2083ZM9.875 5.875H1.54167V2.54167H9.875V5.875Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_29103_86183">
        <rect
          width="15"
          height="15"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
