export const GET_BILLING_DETAILS_REQUEST = 'GET_BILLING_DETAILS_REQUEST';
export const GET_BILLING_DETAILS_ERROR = 'GET_BILLING_DETAILS_ERROR';
export const GET_BILLING_DETAILS_SUCCESS = 'GET_BILLING_DETAILS_SUCCESS';

export const GET_INVOICES_REQUEST = 'GET_INVOICES_REQUEST';
export const GET_INVOICES_ERROR = 'GET_INVOICES_ERROR';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';

export const GET_PAYMENT_INFO_REQUEST = 'GET_PAYMENT_INFO_REQUEST';
export const GET_PAYMENT_INFO_ERROR = 'GET_PAYMENT_INFO_ERROR';
export const GET_PAYMENT_INFO_SUCCESS = 'GET_PAYMENT_INFO_SUCCESS';

export const GET_UPCOMING_INVOICES_REQUEST = 'GET_UPCOMING_INVOICES_REQUEST';
export const GET_UPCOMING_INVOICES_ERROR = 'GET_UPCOMING_INVOICES_ERROR';
export const GET_UPCOMING_INVOICES_SUCCESS = 'GET_UPCOMING_INVOICES_SUCCESS';

export const GET_UPCOMING_INVOICELIST_REQUEST = 'GET_UPCOMING_INVOICELIST_REQUEST';
export const GET_UPCOMING_INVOICELIST_ERROR = 'GET_UPCOMING_INVOICELIST_ERROR';
export const GET_UPCOMING_INVOICELIST_SUCCESS = 'GET_UPCOMING_INVOICELIST_SUCCESS';

export const UPDATE_BILLING_REQUEST = 'UPDATE_BILLING_REQUEST';
export const UPDATE_BILLING_ERROR = 'UPDATE_BILLING_ERROR';
export const UPDATE_BILLING_SUCCESS = 'UPDATE_BILLING_SUCCESS';

export const GET_BILLING_CONTACT_REQUEST = 'GET_BILLING_CONTACT_REQUEST';
export const GET_BILLING_CONTACT_ERROR = 'GET_BILLING_CONTACT_ERROR';
export const GET_BILLING_CONTACT_SUCCESS = 'GET_BILLING_CONTACT_SUCCESS';

export const CHANGE_SUBSCRIPTION_REQUEST = 'CHANGE_SUBSCRIPTION_REQUEST';
export const CHANGE_SUBSCRIPTION_ERROR = 'CHANGE_SUBSCRIPTION_ERROR';
export const CHANGE_SUBSCRIPTION_SUCCESS = 'CHANGE_SUBSCRIPTION_SUCCESS';

export const GET_SUBSCRIPTION_DETAILS_REQUEST = 'GET_SUBSCRIPTION_DETAILS_REQUEST';
export const GET_SUBSCRIPTION_DETAILS_ERROR = 'GET_SUBSCRIPTION_DETAILS_ERROR';
export const GET_SUBSCRIPTION_DETAILS_SUCCESS = 'GET_SUBSCRIPTION_DETAILS_SUCCESS';

export const CLEAR_UPCOMING_INVOICES_REQUEST = 'CLEAR_UPCOMING_INVOICES_REQUEST';

export const ADD_PLAN_DETAILS_SUBSCRIPTION = 'ADD_PLAN_DETAILS_SUBSCRIPTION';
export const ADD_PLAN_DETAILS_SUBSCRIPTION_PENDING = 'ADD_PLAN_DETAILS_SUBSCRIPTION_PENDING';
