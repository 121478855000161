import React from 'react';

export default () => (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.741 8.40151L10.4284 5.84991C10.3156 5.78306 10.1891 5.75 10.0626 5.75C9.93608 5.75 9.80957 5.78306 9.69673 5.85063L5.38416 8.40223C5.16566 8.53089 5.03125 8.76592 5.03125 9.02036V13.9798C5.03125 14.2343 5.16566 14.4693 5.38416 14.5979L9.69673 17.1496C9.80957 17.2171 9.93608 17.2502 10.0626 17.2502C10.1891 17.2502 10.3156 17.2171 10.4284 17.1496L14.741 14.5979C14.9595 14.4693 15.0939 14.2343 15.0939 13.9798V9.02036C15.0939 8.76592 14.9595 8.53089 14.741 8.40151ZM13.6564 13.5701L10.0626 15.6962L6.46877 13.5701V9.43006L10.0626 7.30396L13.6564 9.43006V13.5701Z" fill="currentColor" />
        <path d="M2.87504 13.7873V7.23648L7.33136 4.60294L6.60038 3.36523L1.79043 6.20793C1.57193 6.33731 1.43752 6.57235 1.43752 6.82679V13.7873C0.60304 14.0848 0 14.8755 0 15.8113C0 17.0001 0.967452 17.9676 2.15628 17.9676C3.34511 17.9676 4.31257 17.0001 4.31257 15.8113C4.31257 14.8755 3.70953 14.0848 2.87504 13.7873ZM2.15628 16.5301C1.76025 16.5301 1.43752 16.2081 1.43752 15.8113C1.43752 15.4145 1.76025 15.0925 2.15628 15.0925C2.55232 15.0925 2.87504 15.4145 2.87504 15.8113C2.87504 16.2081 2.55232 16.5301 2.15628 16.5301Z" fill="currentColor" />
        <path d="M17.9669 13.6562C16.778 13.6562 15.8106 14.6237 15.8106 15.8125C15.8106 16.0663 15.8623 16.3063 15.9435 16.5327L10.0605 20.0087L5.45754 17.2889L4.72656 18.5266L9.69464 21.462C9.80748 21.5296 9.93399 21.5626 10.0605 21.5626C10.187 21.5626 10.3135 21.5296 10.4263 21.4627L16.8664 17.6569C17.1899 17.8502 17.5636 17.9688 17.9669 17.9688C19.1557 17.9688 20.1231 17.0014 20.1231 15.8125C20.1231 14.6237 19.1557 13.6562 17.9669 13.6562ZM17.9669 16.5313C17.5708 16.5313 17.2481 16.2093 17.2481 15.8125C17.2481 15.4158 17.5708 15.0938 17.9669 15.0938C18.3629 15.0938 18.6856 15.4158 18.6856 15.8125C18.6856 16.2093 18.3629 16.5313 17.9669 16.5313Z" fill="currentColor" />
        <path d="M10.0625 4.31257C10.5879 4.31257 11.063 4.11634 11.4375 3.80368L17.2501 7.23864V12.2189H18.6877V6.82823C18.6877 6.57379 18.5533 6.33875 18.3348 6.20938L12.1771 2.57101C12.203 2.4366 12.2188 2.2986 12.2188 2.15628C12.2188 0.967452 11.2514 0 10.0625 0C8.8737 0 7.90625 0.967452 7.90625 2.15628C7.90625 3.34511 8.8737 4.31257 10.0625 4.31257ZM10.0625 1.43752C10.4586 1.43752 10.7813 1.75953 10.7813 2.15628C10.7813 2.55304 10.4586 2.87504 10.0625 2.87504C9.6665 2.87504 9.34377 2.55304 9.34377 2.15628C9.34377 1.75953 9.6665 1.43752 10.0625 1.43752Z" fill="currentColor" />
    </svg>
);
