import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'withRouter';
import { FormattedMessage } from 'react-intl'

import UserRow from './Row'
import messages from 'utils/messages'
import Table from 'components/Table'
import {HistoryIcon} from 'images/common'

const columnList = [{
  label: 'Invoice Number',
  sortKey: 'invoiceNo',
  isSortable: true,
}, {
  label: 'Invoice Date',
  sortKey: 'invoiceDate',
  isSortable: true,
  isLocked: true
}, {
  label: <FormattedMessage {...messages.status} />,
  sortKey: 'status',
  isSortable: true,
}, {
  label: 'Due date',
  sortKey: 'targetDate',
  isSortable: true,
}, {
  label: <FormattedMessage {...messages.amount} />,
  sortKey: 'amount',
  isSortable: true,
}, {
  label: 'Credit Amount',
  sortKey: 'creditAdj',
  isSortable: true,
},
{
  label: 'Balance',
  sortKey: 'balance',
  isSortable: true,
},
{
  label: 'Refund Amount',
  sortKey: 'refund',
  isSortable: false,
  isOptional: true,
}
]

class UsersTable extends Component {

  render() {
    const {color} = this.props

    return (
      <Table
        inputType={this.props.inputType}
        columns={columnList}
        RowComponent={UserRow}
        notFoundIcon={<HistoryIcon/>}
        allowOnlySingleSelect
        rows={this.props.users.map(item => ({...item, id: item.invoiceId}))}
        CheckboxImage={HistoryIcon}
        isSelectable
        isLoading={this.props.isLoading}
        hideSingleMenu
        colorClassName={color}
        withColumnSelect
        selectedItems={this.props.selectedItems}
        onSelect={this.props.onSelect}
        currentSortKey={this.props.sort && this.props.sort.key}
        currentSortDir={this.props.sort && this.props.sort.direction}
        onUpdateSort={this.props.onSortChange}
        rowClick={this.props.onRowClick}
      />
    )
  }
}

UsersTable.propTypes = {
  users: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedItems: PropTypes.array.isRequired,
  onSortChange: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default withRouter(UsersTable)
