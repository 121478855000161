import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'utils/messages';
import classNames from 'classnames';

class SubscriptionLoadingCard extends React.Component {
  render() {

    return (
      <>
        <div className="content-box subscriptions__item card_loading">
          <div className="grid-row grid-row_aic">
            <div className="grid-col">
              <div>
                <a
                  href="javascript:void(0)"
                  className="subscriptions__link"
                >
                  <FormattedMessage {...messages.changePlan} />
                </a>
              </div>
              <div className={classNames('billing_price__card', {
              })}>
                  <div className="no-border">
                  </div>
                <div className="billing_header">
                  <div className="title">
                    Loading...
                  </div>
                  <h4 className="description">by viso.ai</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="billing-switch">
            {/* <div className="radio radio_blocked radio_accent0 radio_billing">
              <input
                type="radio"
                className="radio__input accent4"
              />
              <div className="radio__check" />
              <label className="radio__label">
                <FormattedMessage {...messages.paidAnnually} /> – US${' '}
                0
              </label>
            </div>
            <div className="radio radio_blocked radio_accent0 radio_billing">
              <input
                type="radio"
                className="radio__input"
                id="2"
              />
              <div className="radio__check" />
              <label className="radio__label">
                <FormattedMessage {...messages.paidMonthly} /> – US${' '}
                0
              </label>
            </div> */}
          </div>
          <hr className="subscriptions__hr" />
            <div className="grid-row">
              <div className="grid-col grid-col_4">
                <div className="h5 subscriptions__label">
                  <FormattedMessage {...messages.seats} />
                </div>
                <div className={'h4 subscriptions__value'}>
                  <FormattedMessage {...messages.devices} />
                </div>
              </div>

              <div className="grid-col grid-col_4">
                <div className="h5 subscriptions__label">
                  <FormattedMessage {...messages.nextBillingDate} />
                </div>
                <div className="h4 subscriptions__value">
                  Loading
                </div>
              </div>

              <div className="grid-col grid-col_4">
                <div className="h5 subscriptions__label">
                    <FormattedMessage {...messages.priceAfterTrial} />
                </div>
                <div className="h4 subscriptions__value">
                  0
                </div>
              </div>
            </div>
        </div>
      </>
    );
  }
}


export default SubscriptionLoadingCard
