import React from 'react';
import { FeatureCheck, FeatureNone } from 'images/common';

const Description = ({ description }) => {
  if (description === 'full') {
    return <FeatureCheck />;
  }

  if (description === 'none') {
    return <FeatureNone />;
  }

  return <span>{description}</span>;
};

export default Description;
