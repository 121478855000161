import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, Form } from 'redux-form';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { countries } from 'utils/countries';

import Button from 'components/Button';
import messages from 'utils/messages';
import Input from 'components/Form/Input';
import SingleSelect from 'components/Form/SingleSelect';


import { SIZE_LIST, INDUSTRIES_LIST } from 'containers/Settings/Workspace/constants';
import { DEFAULT_ACCENT } from 'utils/constants';
import { LOGIN_FORM } from '../constants';
import validation from './validation';

const required = value => (value ? undefined : 'Required');

class WorkspaceForm extends Component {
  onSubmit = (data) => {
    this.props.onSubmit(data,
      this.props.reset);
  }

  render() {
    const { valid, isLoading, color } = this.props;

    return (
      <Form onSubmit={this.props.handleSubmit(this.onSubmit.bind(this))} className="login__form">
        <div className={`text-field text-field_${color} mx-b_60`}>
          <Field
            name="name"
            component={Input}
            label={<FormattedMessage {...messages.workspaceName} />}
            validate={[required]}
          />
        </div>
        <h3 className="workspace_belongs">This workspace belongs to</h3>
        {/* <div className="workspace_belongs"><FormattedMessage {...messages.companyInformation} /></div> */}
        <div className={`text-field text-field_${color}`}>
          <Field
            name="organizationName"
            component={Input}
            label={<FormattedMessage {...messages.companyName} />}
            validate={[required]}
          />
        </div>
        <div className={`select-field select-field_${color}`}>
          <Field
            parentClass={`select-field select-field_${color}`}
            name='organizationSize'
            validate={[required]}
            isInvalid='true'
            options={SIZE_LIST}
            component={SingleSelect}
            label={<FormattedMessage {...messages.companySize}/>}
          />
        </div>
        <div className={`select-field select-field_${color}`}>
          <Field
            parentClass={`select-field select-field_${color}`}
            name='organizationIndustry'
            options={INDUSTRIES_LIST}
            validate={[required]}
            component={SingleSelect}
            label={<FormattedMessage {...messages.companyIndustry}/>}

          />
        </div>
        <div className={`select-field select-field_${color}`}>
          <FormattedMessage {...messages.country}>
            {
              msg => (
                <Field
                  parentClass={`select-field select-field_${color}`}
                  name="country"
                  editable
                  validate={[required]}
                  options={countries}
                  component={SingleSelect}
                  label={msg}
                />
              )}
          </FormattedMessage>
        </div>
        <div className="login__actions">
          <Button
            type="submit"
            className={classNames(`button button_${color}`, {
              'round-button_disabled': !valid || isLoading,
            })}
            disabled={!valid || isLoading}
          >
            {isLoading ? <FormattedMessage {...messages.loading} /> : <FormattedMessage {...messages.continue} />}
          </Button>
        </div>
      </Form>
    );
  }
}

WorkspaceForm.propTypes = {
  isLoading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  currentPage: PropTypes.string,
  color: PropTypes.string,
};

WorkspaceForm.defaultProps = {
  color: DEFAULT_ACCENT,
};

export default reduxForm({
  form: LOGIN_FORM,
  destroyOnUnmount: false,
  // forceUnregisterOnUnmount: true,
  validate: validation,
})(WorkspaceForm);
