import React, { Component } from 'react';
import {IdleTimerProvider} from 'react-idle-timer';

class IdleScreenDetector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isIdle: false,
    };

    // Create a Broadcast Channel
    this.broadcastChannel = new BroadcastChannel('idleStatus');

    // Bind event handlers
    this.handleBroadcastMessage = this.handleBroadcastMessage.bind(this);
    this.onIdle = this.onIdle.bind(this);
    this.onActive = this.onActive.bind(this);
  }

  componentDidMount() {
    // Listen for messages
    this.broadcastChannel.addEventListener('message', this.handleBroadcastMessage);
  }

  componentWillUnmount() {
    // Cleanup: Close the Broadcast Channel and remove the event listener
    this.broadcastChannel.close();
    this.broadcastChannel.removeEventListener('message', this.handleBroadcastMessage);
  }

  handleBroadcastMessage(event) {
    if (event.data === 'active') {
      this.setState({ isIdle: false });
    } else if (event.data === 'idle') {
      this.setState({ isIdle: true });
    }
  }

  onIdle() {
    this.setState({ isIdle: true });

    // Broadcast idle status to other tabs
    this.broadcastChannel.postMessage('idle');
  }

  onActive() {
    this.setState({ isIdle: false });

    // Broadcast active status to other tabs
    this.broadcastChannel.postMessage('active');
  }

  render() {
    const { isIdle } = this.state;

    // Set a timeout for 30 minutes
    const timeout = setTimeout(() => {
      if (isIdle) {

        this.props.logout()
        // Optionally, trigger a logout action in your application
      }
    }, 60 * 60 * 1000); // 30 minutes in milliseconds

    return (
      <div>
        {/* Your React components go here */}
        <IdleTimerProvider
          timeout={60 * 60 * 1000} // 30 minutes in milliseconds
          onIdle={this.onIdle}
          onActive={this.onActive}
        />
      </div>
    );
  }
}

export default IdleScreenDetector;