import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import axiosObj from './config';


const SUBSCRIPTION_API_URL = `${process.env.API_URL}/subscription/graphql`;

const DEFAULT_SUBSCRIPTION_VALUE = {
  id: true,
  type: true,
  status: true,
  name: true,
  trial: true,
  tags: true,
  tiers: {
    up_to: true,
    unit_amount: true,
  },
  description: true,
  provider: true,
  priceId: true,
  productId: true,
  minEndpoints: true,
  updatedAt: true,
  createdAt: true,
};

export const DEFAULT_DEV_PLAN_VALUE = {
  id: true,
  name: true,
  description: true,
  tags: true,
  monthlyPrice: {
    id: true,
    currency: true,
    minEndpoints: true,
    tiers: {
      up_to: true,
      unit_amount: true,
    },
    trialPeriodDays: true,
    trialFee: true,
  },
  yearlyPrice: {
    id: true,
    currency: true,
    minEndpoints: true,
    tiers: {
      up_to: true,
      unit_amount: true,
    },
    trialPeriodDays: true,
    trialFee: true,
  },
  features: {
    applicationLimit: true,
    applicationVersionLimit: true,
    dashboardLimit: true,
    deploymentLimit: true,
    deviceLimit: true,
    moduleLimit: true,
    profileLimit: true,
    videoLimit: true,
    userLimit: true,
    influxStorageLimit: true,
    logStorageLimit: true,
    auditLogStatus: true,
    multiFactorAuthenticationStatus: true,
    appDataRetentionDays: true,
    deviceMetricsRetentionDays: true,
    terminalAccessManagementStatus: true,
    videoViewerAccessManagementStatus: true,
  },
};

export const DEFAULT_SUBSCRIPTIONS_VALUE = {
  id: true,
  name: true,
  description: true,
  tags: true,
  monthlyPrice: {
    id: true,
    currency: true,
    minEndpoints: true,
    tiers: {
      up_to: true,
      unit_amount: true,
    },
    trialPeriodDays: true,
    trialFee: true,
  },
  yearlyPrice: {
    id: true,
    currency: true,
    minEndpoints: true,
    tiers: {
      up_to: true,
      unit_amount: true,
    },
    trialPeriodDays: true,
    trialFee: true,
  },
  features: {
    applicationLimit: true,
    applicationVersionLimit: true,
    dashboardLimit: true,
    deploymentLimit: true,
    deviceLimit: true,
    moduleLimit: true,
    profileLimit: true,
    videoLimit: true,
    userLimit: true,
    influxStorageLimit: true,
    logStorageLimit: true,
    auditLogStatus: true,
    multiFactorAuthenticationStatus: true,
    appDataRetentionDays: true,
    deviceMetricsRetentionDays: true,
    terminalAccessManagementStatus: true,
    videoViewerAccessManagementStatus: true,
  },
};

export function getPlans() {
  const query = {
    query: {
      plans: {
        ...DEFAULT_SUBSCRIPTIONS_VALUE,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SUBSCRIPTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function changePlan(arg) {
  const query = {
    mutation: {
      changePlan: {
        __args: arg,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SUBSCRIPTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getPlan(payload) {
  const query = {
    query: {
      subscription: {
        __args: payload,
        ...DEFAULT_SUBSCRIPTION_VALUE,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SUBSCRIPTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function createSubscription(payload) {
  const query = {
    mutation: {
      createSubscription: {
        __args: {
          ...payload,
        },
        ...DEFAULT_SUBSCRIPTION_VALUE,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SUBSCRIPTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function devPlan(payload) {
  const query = {
    query: {
      devPlan: {
        __args: payload,
        ...DEFAULT_DEV_PLAN_VALUE,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SUBSCRIPTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}
