import React, { Component } from 'react';

import { EXTERNAL_URL } from 'utils/constants';
import { FormattedMessage } from 'react-intl';
import messages from 'utils/messages';
import AHref from 'components/AHref';

class PolicyLink extends Component {
  render() {
    return (
      <AHref href={EXTERNAL_URL.policy} className="text-link" target="_blank" rel="noreferrer">
        <FormattedMessage {...messages.privacyPolicy} />
      </AHref>
    );
  }
}

export default PolicyLink;
