import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'withRouter';
import classNames from 'classnames'
import SortableRow from 'components/Table/SortableRow'
import NumberFormat from 'react-number-format';
import {FormattedMessage} from 'react-intl'

import messages from 'utils/messages'
import {dateFormatter4, getInvoiceFormat, getPriceFormat} from 'utils/common'

class UserRow extends PureComponent {

  getStatus = (status) => {
    const className = classNames('data-table__status type__value text-captilize',
      {
        'data-table__status_pos': status === 'paid',
        'data-table__status_neg': status !== 'paid'
      })

    return (
      <div className={className}>{status}</div>
    )
  }

  getRowRender = () => {
    const {invoiceDate, amount, status, currency, invoiceNumber, targetDate, currentSortKey, creditAdj, balance, refundAdj} = this.props

    const balanceClass = classNames('type__value', {
      'data-table__td_dark': currentSortKey === 'balance',
    });

    const invoiceDateClass = classNames('type__value', {
      'data-table__td_dark': currentSortKey === 'invoiceDate',
    });

    const targetDateClass = classNames('type__value', {
      'data-table__td_dark': currentSortKey === 'targetDate',
    });

    const creditClass = classNames('type__value', {
      'data-table__td_dark': currentSortKey === 'creditAdj',
    });

    const amountClass = classNames('type__value', {
      'data-table__td_dark': currentSortKey === 'amount',
    });

    const invoiceNumberClass = classNames('type__value', {
      'data-table__td_dark': currentSortKey === 'invoiceNo',
    });

    const invoiceStatusClass = classNames('type__value', {
      'data-table__td_dark': currentSortKey === 'status',
    });

    const refundClass = classNames('type__value', {
      'data-table__td_dark': currentSortKey === 'refundAdj',
    });

    return {
      invoiceDate: () => <div className={invoiceDateClass}>{ dateFormatter4(invoiceDate)}</div>,
      invoiceNo: () => <div className={invoiceNumberClass}>{getInvoiceFormat(invoiceNumber)}</div>,
      status: () => <span className={invoiceStatusClass}>{this.getStatus(status)}</span>,
      targetDate: () => <span className={targetDateClass}>{dateFormatter4(targetDate)}</span>,
      amount: () =><span className={amountClass}><NumberFormat
        value={(amount && parseFloat(amount).toFixed(2)) || '0.00'}
        displayType={'text'}
        thousandSeparator renderText={value =>
          <span>
            {currency && currency.toUpperCase()} {value}
          </span>
        }/></span>,
      creditAdj: () => <span className={creditClass}>{getPriceFormat(creditAdj)}</span>,
      balance: () => <span className={balanceClass}>{getPriceFormat(balance)}</span>,
      refund: () => <span className={refundClass}>{getPriceFormat(refundAdj)}</span>,
      // actions: () => <a download href={invoice_pdf} target='_blank' rel="noopener noreferrer" className={`${colorClassName}`}>
      //   <FormattedMessage {...messages.download}/>
      // </a>,
    }
  }

  render() {
    const {colorClassName} = this.props

    return (
      <>
        <SortableRow
          { ...this.props }
          colorClassName={colorClassName}
          columns={this.props.columns}
          customRender={this.getRowRender()}
        />
        <td></td>
      </>
    )
  }
}

UserRow.propTypes = {
  inputType: PropTypes.string,
  history: PropTypes.object.isRequired,
  columns: PropTypes.array
}

export default withRouter(UserRow)
