import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import {
  getWorkspaceHistory
} from 'api/users';

import {
  defaultNotifier,
} from 'functions/notificationHandler';
import {
  FETCH_WORKSPACE_HISTORY_REQUEST
} from './constants';
import {
  fetchWorkspaceHistorySuccess
} from './actions';

function* workspaceHistorySaga(action) {
  try {
    const { payload } = action;
    const response = yield call(getWorkspaceHistory, payload);

    if (response.data.errors) {
      return defaultNotifier(response.data.errors);
    }

    if (response.data.data.workspaceHistory) {
      yield put(fetchWorkspaceHistorySuccess(response.data.data.workspaceHistory));
    }

  } catch (err) {
    defaultNotifier(err);
  }
}


export default function* userRootSaga() {
  yield takeLatest(FETCH_WORKSPACE_HISTORY_REQUEST, workspaceHistorySaga);
}
