export const LIST_USER_GROUP_REQUEST = 'USER_GROUP/LIST_REQUEST';
export const LIST_USER_GROUP_SUCCESS = 'USER_GROUP/LIST_SUCCESS';
export const LIST_USER_GROUP_ERROR = 'USER_GROUP/LIST_ERROR';
export const ADD_USER_GROUP_REQUEST = 'USER_GROUP/ADD_GROUP_REQUEST';
export const ADD_USER_GROUP_SUCCESS = 'USER_GROUP/ADD_GROUP_SUCCESS';
export const ADD_USER_GROUP_ERROR = 'USER_GROUP/ADD_GROUP_ERROR';
export const UPDATE_USER_GROUP_REQUEST = 'USER_GROUP/UPDATE_GROUP_REQUEST';
export const UPDATE_USER_GROUP_SUCCESS = 'USER_GROUP/UPDATE_GROUP_SUCCESS';
export const UPDATE_USER_GROUP_ERROR = 'USER_GROUP/UPDATE_GROUP_ERROR';
export const REMOVE_USER_GROUP_REQUEST = 'USER_GROUP/REMOVE_GROUP_REQUEST';
export const REMOVE_USER_GROUP_SUCCESS = 'USER_GROUP/REMOVE_GROUP_SUCCESS';
export const REMOVE_USER_GROUP_ERROR = 'USER_GROUP/REMOVE_GROUP_ERROR';

export const USER_GROUP_REQUEST = 'USER_GROUP/FETCH_REQUEST';
export const USER_GROUP_SUCCESS = 'USER_GROUP/FETCH_SUCCESS';
export const USER_GROUP_ERROR = 'USER_GROUP/FETCH_ERROR';

export const CARD_VIEW = 'card';
export const TABLE_VIEW = 'list';
