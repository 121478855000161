import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { LongArrowLeft } from 'images/common';
import messages from 'utils/messages';
import ProcessPage from 'components/ProcessPage';
import Viso1 from 'images/viso1.png';
import Viso2 from 'images/viso2.png';
import Viso3 from 'images/viso3.png';
import AuthenticationItem from 'components/AuthenticationItem';
import Button from 'components/Button';
import AHref from 'components/AHref';

class AuthenticationPage extends Component {
  state = {
    isLoading: false,
    downloadUrl: '',
  };

  render() {
    return (
      <ProcessPage
        onCancel={this.props.onClose}
      >
        {() => (
          <>
            <div className="headline">
              <h1 className="headline__title"><FormattedMessage {...messages.setUp2Factor} /></h1>
              <h4 className="headline__subtitle">
                <FormattedHTMLMessage {...messages.twoFactorAuthExtraLayer} />
              </h4>
            </div>
            <div className="device-image-wrapper">
              <AuthenticationItem
                icon={Viso1}
                name="step1"
                title="1. Viso Suite Login"
                description={<FormattedMessage {...messages.authenticationStep1} />}
              />
              <LongArrowLeft />
              <AuthenticationItem
                icon={Viso2}
                name="step2"
                title="2. Code from Mobile App"
                description={<FormattedMessage {...messages.authenticationStep2} />}
              />
              <LongArrowLeft />
              <AuthenticationItem
                icon={Viso3}
                name="step3"
                title="3. Confirm with Code"
                description={<FormattedMessage {...messages.authenticationStep3} />}
              />
            </div>
            <div className="authentication-footer">
              <FormattedMessage {...messages.useAnApplication} />
              <AHref href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US">
                {' '}
                Google Authenticator
              </AHref>
              ,&nbsp;
              <AHref href="https://authy.com/">Authy</AHref>
              {' '}
              or
              {' '}
              <AHref href="https://1password.com/">1Password</AHref>
              .
              {' '}
              <FormattedMessage {...messages.theseApplicationsSupport} />
            </div>

            <div className="login__actions_v2">
              <Button
                onClick={this.props.onSubmit}
                className="button button_sm"
              >
                <FormattedMessage {...messages.setUpNow} />
              </Button>
            </div>
          </>
        )}
      </ProcessPage>
    );
  }
}

AuthenticationPage.propTypes = {
  color: PropTypes.string,
  image: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AuthenticationPage;
