import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Range, Handle } from 'rc-slider';
import { DragIcon } from 'images/common';

import { maxBy } from 'lodash';
import messages from 'utils/messages';
import classNames from 'classnames';
import { FILTER_TYPE } from '../constants';

class Search extends React.Component {
  state = {
    filterValue: [0],
    maxValue: 0,
  }

  onApply = () => {
    const { filterValue } = this.state;
    const { name, onFilterApply } = this.props;

    onFilterApply(name, filterValue, FILTER_TYPE.SEARCH);
  }

  componentDidMount() {
    const { columns } = this.props;

    const getMaxRange = maxBy(columns, o => o.label) || { label: 0 };

    this.setState({ maxValue: getMaxRange.label, filterValue: [getMaxRange.label] });

    document.addEventListener('keydown', this.onEnter, false);
  }

  onEnter = (event) => {
    if (event.key === 'Enter') {
      // on Esc button click it should close popup
      this.onApply();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEnter, false);
  }

  onChange = (value) => {
    this.setState({ filterValue: [value] });
  }

  render() {
    const {
      title, customClass, color, disabled,
    } = this.props;

    const customInputClass = `range-slider text-field text-field_nolabel text-field_${customClass}`;

    const inputClass = classNames({
      'text-field__range': true,
      'text-field__input': true,
      'text-field__input_filled': true,
    });

    const handleSliderClass = classNames('slider-icon', {
      [color]: color,
      disabled,
    });

    return (
      <div className="text-field__range_container">
        <div className="filters-bar__heading">{title}</div>
        <div className="filters-bar__range">
          <input
            type="text"
            maxLength={4}
            value={this.state.filterValue}
            className={inputClass}
            onChange={e => this.onChange(e.target.value)}
          />
        </div>
        <div className={customInputClass}>
          <Range
            handle={handleProps => (
              <Handle {...handleProps}>
                <div className={handleSliderClass}><DragIcon /></div>
              </Handle>
            )}
            onChange={val => this.onChange(val)}
            min={0}
            max={this.state.maxValue}
            value={[this.state.filterValue]}
            defaultValue={[this.state.filterValue]}
          />
        </div>
        <hr className="mt_100" />
        <button type="button" className={`filters-bar__apply ${customClass}`} onClick={this.onApply}>
          <FormattedMessage {...messages.apply} />
        </button>
      </div>
    );
  }
}

Search.propTypes = {
  name: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onFilterApply: PropTypes.func,
  customClass: PropTypes.string,
};

export default Search;
