import classNames from 'classnames';
import { updateNotificationDisplay } from 'containers/App/actions';
import reducer from 'containers/App/reducer';
import {
  getNotificationState,
  getSidebarStatus,
} from 'containers/App/selectors';
import { getDetailRole } from 'containers/Settings/AccessRole/selectors';
import { makeSelectMe } from 'containers/Settings/User/selectors';
import {
  getStartedTrackerRequest, updateGetStartedTrackerRequest, updateStartedTrackerRequest,
} from 'containers/Settings/Workspace/actions';
import wsReducer from 'containers/Settings/Workspace/reducer';
import wsSaga from 'containers/Settings/Workspace/saga';
import {
  getTrackerInfo, makeSelectFetchWorkspace, makeSelectLoadingTracker,
} from 'containers/Settings/Workspace/selectors';
import { CloseIcon } from 'images/common';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Scrollbars } from 'react-custom-scrollbars';
import { Check, CheckSquare } from 'react-feather';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {withRouter } from 'withRouter'
import slugify from 'react-slugify';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { routeMapping } from 'routes';
import { getDuration, getInitials } from 'utils/common';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import appMessages from 'utils/messages';
import { isPermitted } from 'utils/permissions';
import GetStartedItem from './GetStartedItem';


const trackerList = [
  {
    id: 'inviteFirstTeamMember',
    title: 'inviteTeamMembers',
    updatedBy: 'inviteFirstTeamMemberUpdatedBy',
    updatedAt: 'inviteFirstTeamMemberUpdatedAt',
  },
  {
    id: 'enrolFirstDevice',
    title: 'enrollYourFirstDevice',
    updatedBy: 'enrolFirstDeviceUpdatedBy',
    updatedAt: 'enrolFirstDeviceUpdatedAt',
  },
  {
    id: 'createFirstApplication',
    title: 'createYourFirstApp',
    updatedBy: 'createFirstApplicationUpdatedBy',
    updatedAt: 'createFirstApplicationUpdatedAt',
  },
  {
    id: 'deployFirstProfile',
    title: 'deployAProfile',
    updatedBy: 'deployFirstProfileUpdatedBy',
    updatedAt: 'deployFirstProfileUpdatedAt',
  },
  {
    id: 'scheduleOnBoarding',
    title: 'scheduleOnboarding',
    updatedBy: 'scheduleOnBoardingUpdatedBy',
    updatedAt: 'scheduleOnBoardingUpdatedAt',
  },
  {
    id: 'knowLedgeCenter',
    title: 'knowledgeCenter',
    updatedBy: 'knowLedgeCenterUpdatedBy',
    updatedAt: 'knowLedgeCenterUpdatedAt',
  },
  {
    id: 'installDemoApplication',
    title: 'installDemoApplication',
    updatedBy: 'installDemoApplicationUpdatedBy',
    updatedAt: 'installDemoApplicationUpdatedAt',
  },
];

// const GET_POST = gql`
//   subscription($workspaceId: ID!) {
//     syncWorkspaceSetupTracker(workspaceId: $workspaceId) {
//       addFirstModule
//       addFirstModuleUpdatedAt
//       addFirstModuleUpdatedBy {
//         email
//         id
//         firstName
//         lastName
//       }
//       completed
//       completedCount
//       createFirstApplication
//       createFirstApplicationUpdatedAt
//       createFirstApplicationUpdatedBy {
//         email
//         id
//         firstName
//         lastName
//       }
//       deployFirstProfile
//       deployFirstProfileUpdatedAt
//       deployFirstProfileUpdatedBy {
//         email
//         id
//         firstName
//         lastName
//       }
//       enrolFirstDevice
//       enrolFirstDeviceUpdatedAt
//       enrolFirstDeviceUpdatedBy {
//         email
//         id
//         firstName
//         lastName
//       }
//       inviteFirstTeamMember
//       inviteFirstTeamMemberUpdatedAt
//       inviteFirstTeamMemberUpdatedBy {
//         email
//         id
//         firstName
//         lastName
//       }
//       taskCount
//     }
//   }
// `;

const GetStarted = (props) => {
  const [setActiveIndex] = useState(0);
  const [collpase, setCollpase] = useState(false);

  const fetchGetStartedTracker = async () => {
    await props.getStartedTrackerRequest();
  };

  const redirectToBookPage = async () => window.open('https://calendly.com/nico-visoai/30min?month=2021-09');

  useEffect(() => {
    fetchGetStartedTracker();
    setCollpase(props.sidebarStatus);
  }, []);

  useEffect(() => {
    setCollpase(props.sidebarStatus);
  }, [props.sidebarStatus]);

  const onRedirect = (url, e) => {
    e.preventDefault();
    e.stopPropagation();

    const { workspace } = props;

    props.router.navigate(`/${workspace && slugify(workspace.name)}${url}`);

    props.onClose();
  };

  const value = (props.tracker && props.tracker.completedCount) || 0;

  const wrapperClass = classNames('get-started-container', {
    collpase: collpase,
    'is-open': props.showGetStartedModal,
    'is-close': !props.showGetStartedModal,
  });

  const updatedTracker = props.tracker
    ? trackerList.map(item => ({
      id: props.tracker[item.id],
      title: item.title,
      updatedBy: props.tracker[item.updatedBy],
      updatedAt: props.tracker[item.updatedAt],
    }))
      .filter(item => item.title !== 'addAModule')
    : [];

  // const { data, error } = useSubscription(GET_POST, {
  //   variables: {
  //     workspaceId: props.workspaceId,
  //     token: `${localStorage.getItem('accessToken')}`
  //   }
  // });

  // if (error) {
  //   // eslint-disable-next-line
  //   console.log("Error ws", error);
  // }

  // if (data) {
  //   const payload = data.syncWorkspaceSetupTracker;
  //   delete payload['__typename'];

  //   props.updateGetStartedTrackerRequest(payload);
  // }

  const isCompleted = (props.tracker && props.tracker.taskCount)
    === (props.tracker && props.tracker.completedCount);

  return (
    <div id="notifications-bar" className={wrapperClass}>
      <Scrollbars
        autoHeight
        className="custom-scrollbars"
        universal
        autoHide
        autoHideDuration={1}
        width={100}
        autoHeightMax="100vh"
        renderThumbVertical={allProps => <div {...allProps} className="thumb-vertical" />}
        renderTrackHorizontal={allProps => <div {...allProps} style={{ display: 'none' }} className="track-horizontal" />}
      >
        <div className="get-started-container-wrapper">
          <button
            onClick={props.onClose}
            type="button"
            className="get-started-container__close"
          >
            <CloseIcon />
          </button>

          <div className="get-started-header">
            <div className="get-started-charts">
              <CircularProgressbar
                strokeWidth={13}
                value={isCompleted ? 0 : value}
                maxValue={props.tracker && props.tracker.taskCount}
                text={
                  props.tracker
                  && props.tracker.taskCount - props.tracker.completedCount
                }
              />
            </div>
            {(!props.loadingTracker
              && props.tracker
              && props.tracker.taskCount)
              === (props.tracker && props.tracker.completedCount) && (
              <div className="get-started-cover-success">
                <Check />
              </div>
            )}
            <div className="get-started-info">
              <h2 className="text-color h2">
                <FormattedMessage {...appMessages.setupVisoSuite} />
              </h2>
              <div className="label-color desc">
                <FormattedMessage {...appMessages.completeTheseTask} />
              </div>
            </div>
          </div>
          {props.tracker && !props.tracker.completed && (
            <>
              <div className="get-started-topic">
                <FormattedMessage {...appMessages.completeNow} />
              </div>
              <div className="notifications-bar__hr1" />
            </>
          )}
          <div className="get-started-wrapper">
            {props.tracker
              && props.tracker.scheduleOnBoarding === 'open'
            && (
              <GetStartedItem
                showTopBorder
                updateFocusIndex={setActiveIndex}
                index={1}
                hideMenu
                title="Schedule onboarding"
                description={(
                  <span>
                    Book your Viso Suite onboarding call. Learn about the
                    important features and how to deliver your application
                    successfully. Get all your questions answered.
                  </span>
                )}
                onRedirect={() => {
                  props.onClose();
                  redirectToBookPage();
                }}
                skipBtn={<FormattedMessage {...appMessages.complete} />}
                action="Book now"
                actionLink={redirectToBookPage}
                onSkip={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.updateStartedTrackerRequest({
                    task: 'scheduleOnBoarding',
                  });
                }}
              />
            )}
            {props.tracker
              && props.tracker.knowLedgeCenter === 'open'
            && (
              <GetStartedItem
                showTopBorder
                updateFocusIndex={setActiveIndex}
                index={1}
                hideMenu
                title="Knowledge center"
                skipBtn={<FormattedMessage {...appMessages.complete} />}
                description={(
                  <span>
                    Explore step-by-step guides and tutorials to get started
                    with Viso Suite. Find and search the documentation.
                  </span>
                )}
                onRedirect={() => {
                  props.onShowModal();
                  props.onClose();
                }}
                onSkip={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.updateStartedTrackerRequest({
                    task: 'knowLedgeCenter',
                  });
                }}
                action="View now"
                actionLink={props.onShowModal}
              />
            )}
            {props.tracker
              && props.tracker.installDemoApplication === 'open'
            && (
              <GetStartedItem
                showTopBorder
                updateFocusIndex={setActiveIndex}
                index={1}
                hideMenu
                onClose={props.onClose}
                title="Install demo application"
                description={(
                  <span>
                    Import a pre-built demo application to your workspace. The
                    required modules are automatically installed to the library.
                  </span>
                )}
                action="Install now"
                actionLink={props.onShowDemoApp}
                onRedirect={() => {
                  props.onShowDemoApp();
                  props.onClose();
                }}
                onSkip={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.updateStartedTrackerRequest({
                    task: 'installDemoApplication',
                    skipped: true,
                  });
                }}
              />
            )}
            {props.tracker
              && props.tracker.inviteFirstTeamMember === 'open'
              && isPermitted({ id: 'users' }, props.detailRole) && (
              <GetStartedItem
                updateFocusIndex={setActiveIndex}
                index={1}
                onClose={props.onClose}
                title={
                  <FormattedMessage {...appMessages.inviteTeamMembers} />
                }
                description={(
                  <FormattedMessage
                    {...appMessages.sendYourTeamMemberAnInvitation}
                  />
                )}
                onRedirect={e => onRedirect(routeMapping.USER_INVITATION_LIST.path, e)
                }
                onSkip={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.updateStartedTrackerRequest({
                    task: 'inviteFirstTeamMember',
                    skipped: true,
                  });
                }}
                action={<FormattedMessage {...appMessages.inviteNow} />}
                actionLink={routeMapping.USER_INVITATION_LIST.path}
              />
            )}
            {props.tracker
              && props.tracker.enrolFirstDevice === 'open'
              && isPermitted({ id: 'users' }, props.detailRole) && (
              <GetStartedItem
                updateFocusIndex={setActiveIndex}
                index={2}
                onClose={props.onClose}
                title={
                  <FormattedMessage {...appMessages.enrollYourFirstDevice} />
                }
                onRedirect={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onRedirect(routeMapping.DEPLOYMENT_DEVICES.path, e);
                }}
                actionLink={routeMapping.DEPLOYMENT_DEVICES.path}
                onSkip={() => props.updateStartedTrackerRequest({
                  task: 'enrolFirstDevice',
                  skipped: true,
                })
                }
                description={(
                  <FormattedMessage
                    {...appMessages.selectTheHardwarePlatform}
                  />
                )}
              />
            )}
            {/* {props.tracker &&
              props.tracker.addFirstModule === 'open' &&
              isPermitted({ id: 'users' }, props.detailRole) && (
              <GetStartedItem
                updateFocusIndex={setActiveIndex}
                index={3}
                title={<FormattedMessage {...appMessages.addAModule} />}
                onClose={props.onClose}
                onRedirect={e =>
                  onRedirect(routeMapping.LIBRARY_MODULES.path, e)
                }
                onSkip={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.updateStartedTrackerRequest({
                    task: 'addFirstModule'
                  });
                }}
                actionLink={routeMapping.LIBRARY_MODULES.path}
                description={
                  <FormattedMessage {...appMessages.addNewModuleNInstall} />
                }
              />
            )} */}
            {/* {props.tracker &&
              props.tracker.createFirstApplication === 'open' &&
              isPermitted({ id: 'users' }, props.detailRole) && (
              <GetStartedItem
                updateFocusIndex={setActiveIndex}
                index={4}
                onRedirect={e =>
                  onRedirect(routeMapping.LIBRARY_APPLICATIONS.path, e)
                }
                onSkip={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.updateStartedTrackerRequest({
                    task: 'createFirstApplication'
                  });
                }}
                onClose={props.onClose}
                actionLink={routeMapping.LIBRARY_APPLICATIONS.path}
                title={
                  <FormattedMessage {...appMessages.createYourFirstApp} />
                }
                description={
                  <FormattedMessage {...appMessages.createDemoApplication} />
                }
              />
            )} */}
            {props.tracker
              && props.tracker.deployFirstProfile === 'open'
              && isPermitted({ id: 'users' }, props.detailRole) && (
              <GetStartedItem
                updateFocusIndex={setActiveIndex}
                index={5}
                onRedirect={e => onRedirect(routeMapping.DEPLOYMENT_PROFILES.path, e)
                }
                onSkip={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.updateStartedTrackerRequest({
                    task: 'deployFirstProfile',
                    skipped: true,
                  });
                }}
                hideLine
                title={<FormattedMessage {...appMessages.deployAProfile} />}
                onClose={props.onClose}
                actionLink={routeMapping.DEPLOYMENT_PROFILES.path}
                description={(
                  <FormattedMessage
                    {...appMessages.createANewProfileToDeploy}
                  />
                )}
              />
            )}
          </div>

          <div className="completed-task-container">
            <div className="text-color title">
              <FormattedMessage {...appMessages.completeTasks} />
            </div>
            <div className="notifications-bar__hr1" />
            {sortBy(updatedTracker, ['updatedAt']).map(
              (item, index) => (item.id === 'skipped' || item.id === 'completed') && (
                <div className="completed-container" key={index}>
                  <div className="completed-name">
                    <span className="mx-r-10">
                      <CheckSquare size="18" strokeWidth={2} />
                    </span>
                    <div className="completed-name__title">
                      <FormattedMessage {...appMessages[item.title]} />
                    </div>
                  </div>
                  <div className="completed-info">
                    <h5 className="label-color">
                      {item.updatedAt && getDuration(item.updatedAt)}
                    </h5>
                    {item.updatedBy && (
                      <div className="checkbox mx-l-16">
                        {item.updatedBy && (
                          <p className="get-started-tooltip custom-tooltip">
                            {item.updatedBy && item.updatedBy.email}
                          </p>
                        )}
                        <div className="checkbox__image">
                          {item.updatedBy
                              && getInitials(
                                item.updatedBy.firstName,
                                item.updatedBy.lastName,
                              )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ),
            )}
          </div>
        </div>
      </Scrollbars>
    </div>
  );
};

GetStarted.propTypes = {
  showNotification: PropTypes.bool,
  updateNotificationDisplay: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  showNotification: getNotificationState(),
  sidebarStatus: getSidebarStatus(),
  detailRole: getDetailRole(),
  workspace: makeSelectFetchWorkspace(),
  loadingTracker: makeSelectLoadingTracker(),
  tracker: getTrackerInfo(),
  me: makeSelectMe(),
});

const withConnect = connect(
  mapStateToProps,
  {
    updateNotificationDisplay,
    getStartedTrackerRequest,
    updateGetStartedTrackerRequest,
    updateStartedTrackerRequest,
  },
);

const withReducer = injectReducer({ key: 'global', reducer });
const withWsReducer = injectReducer({ key: 'workspace', reducer: wsReducer });
const withWsSaga = injectSaga({ key: 'workspace', saga: wsSaga });

export default compose(
  withReducer,
  withWsSaga,
  withRouter,
  withWsReducer,
  withConnect,
)(GetStarted);
