import React from 'react';

export default () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.84001 0.0463619C7.90684 0.015773 7.9788 0 8.05151 0C8.12423 0 8.19619 0.015773 8.26302 0.0463619L15.5669 3.38898L8.05151 6.82789L0.536133 3.38898L7.84001 0.0463619Z"
      fill="currentColor"
    />
    <path
      d="M0 4.39062V13.1828C0 13.412 0.128835 13.6184 0.325309 13.7101L7.51538 17V7.82953L0 4.39062Z"
      fill="currentColor"
    />
    <path
      d="M8.58887 7.82953L16.1042 4.39062V13.1828C16.1043 13.295 16.0736 13.4048 16.0157 13.4986C15.9579 13.5923 15.8756 13.6658 15.7789 13.7101L8.58887 17V7.82953Z"
      fill="currentColor"
    />
  </svg>
);
