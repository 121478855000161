import classNames from 'classnames';
import Card from 'components/Detail/Card';
import getSymbolFromCurrency from 'currency-symbol-map';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';
import { dateFormatter3, isValidUpcomingInvoice, dateFormatter4 } from 'utils/common';
import appMessages from 'utils/messages';
import FullBillEstimate from './billEstimate';
import EstimateItem from './EstimateItem';

class Estimate extends Component {
  state = {
    openModal: false,
  };

  onAddTags = (tags) => {
    this.setState({ tags });
  };

  openEditModal = () => {
    this.setState({ openModal: true });
  };

  closeEditModal = () => {
    this.setState({ openModal: false });
  };

  renderContent = () => {
    const {
      color,
      upcomingInvoices,
      isBillingPage,
    } = this.props;

    if (!upcomingInvoices) {
      return null;
    }

    const totalPrice = upcomingInvoices.amount;
    const refundAdj = upcomingInvoices.refundAdj;
    const creditAdj = upcomingInvoices.creditAdj;
    const balance = upcomingInvoices.balance || 0;

    const invoiceLength = upcomingInvoices.items.filter((item) =>
      isValidUpcomingInvoice(
        new Date(item.startDate),
        new Date(item.endDate),
        item.endDate
      )
    );

    return (
      <>
        <div className="estimated-revenue__divider" />

        {upcomingInvoices &&
          upcomingInvoices.items
            .filter((item) =>
              isValidUpcomingInvoice(
                new Date(item.startDate),
                new Date(item.endDate),
                item.endDate
              )
            )
            .reverse()
            .map((invoice, index) => {

              const data =
                this.props.plans &&
                this.props.plans.find(
                  (plan) => plan.name === invoice.planName
                ) &&
                this.props.plans.find((plan) => plan.name === invoice.planName)
                  .prettyName;
              const name = data || invoice.description;
              const totalAmount = invoice.amount;

              const totalQuantity = invoice.quantity;

              return (
                <NumberFormat
                  key={index}
                  value={(totalAmount && totalAmount.toFixed(2)) || '0.00'}
                  displayType={'text'}
                  thousandSeparator
                  renderText={(value) => (
                    <EstimateItem
                      name={name}
                      // description={desc && desc[0]}
                      amount={
                        invoice.currency &&
                        `${getSymbolFromCurrency(invoice.currency)}${value}`
                      }
                      endpoints={totalQuantity}
                    />
                  )}
                />
              );
            })}
        {!invoiceLength.length && (
          <h3 className="label-color">
            <FormattedMessage {...appMessages.noInvoicesPlanned} />
          </h3>
        )}
        <div className="estimated-revenue__divider" />

        {upcomingInvoices && (
          <div
            className={classNames(
              'grid-row grid-row_aic estimated-revenue__row_half',
              {
                'estimated-revenue__row_half_billing': isBillingPage,
              }
            )}
          >
            <div className="grid-col">
              <div className="estimated-revenue__label">Subtotal</div>
            </div>
            <div className="grid-col grid-col_auto">
              <div className={classNames('estimated-revenue__value c-label')}>
                <NumberFormat
                  value={
                    (totalPrice && parseFloat(totalPrice).toFixed(2)) || '0.00'
                  }
                  displayType={'text'}
                  thousandSeparator
                  renderText={(value) => (
                    <span>
                      {upcomingInvoices &&
                        upcomingInvoices.currency &&
                        getSymbolFromCurrency(upcomingInvoices.currency)}
                      {value}
                    </span>
                  )}
                />
              </div>
            </div>
          </div>
        )}

        {upcomingInvoices && (
          <div
            className={classNames(
              'grid-row grid-row_aic estimated-revenue__row_half',
              {
                'estimated-revenue__row_half_billing': isBillingPage,
              }
            )}
          >
            <div className="grid-col">
              <div className={`estimated-revenue__${color}`}>
                Applied balance
              </div>
            </div>
            <div className="grid-col grid-col_auto">
              <div className={`estimated-revenue__${color}`}>
                <NumberFormat
                  value={
                    (totalPrice && parseFloat(creditAdj).toFixed(2)) || '0.00'
                  }
                  displayType={'text'}
                  thousandSeparator
                  renderText={(value) => (
                    <span>
                      {upcomingInvoices &&
                        upcomingInvoices.currency &&
                        getSymbolFromCurrency(upcomingInvoices.currency)}
                      {value}
                    </span>
                  )}
                />
              </div>
            </div>
          </div>
        )}

        {upcomingInvoices && !!refundAdj && (
          <div
            className={classNames(
              'grid-row grid-row_aic estimated-revenue__row_half',
              {
                'estimated-revenue__row_half_billing': isBillingPage,
              }
            )}
          >
            <div className="grid-col">
              <div className="estimated-revenue__label">Refunded</div>
            </div>
            <div className="grid-col grid-col_auto">
              <div className={classNames('estimated-revenue__value c-label')}>
                <NumberFormat
                  value={
                    (refundAdj && parseFloat(refundAdj).toFixed(2)) || '0.00'
                  }
                  displayType={'text'}
                  thousandSeparator
                  renderText={(value) => {
                    return <span>${value || '0.00'}</span>;
                  }}
                />
              </div>
            </div>
          </div>
        )}

        {upcomingInvoices && (
          <div className="grid-row grid-row_aic estimated-revenue__row">
            <div className="grid-col">
              <h3 className="estimated-revenue__value_total">Amount due</h3>
            </div>
            <div className="grid-col grid-col_auto">
              <div className="estimated-revenue__value_total">
                <NumberFormat
                  value={
                    (totalPrice && parseFloat(balance).toFixed(2)) || '0.00'
                  }
                  displayType={'text'}
                  thousandSeparator
                  renderText={(value) => (
                    <span>
                      {upcomingInvoices &&
                        upcomingInvoices.currency &&
                        upcomingInvoices.currency.toUpperCase()}{' '}
                      {value}
                    </span>
                  )}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  render() {
    // let endDate = dateFormatter2(moment().add(1, 'month').endOf('month'));

    const {
      color,
      upcomingInvoices,
      subscriptionDetails,
      billing,
      plans,
      isOpen
    } = this.props;
    let startDate = dateFormatter3(moment().add(1, 'month').startOf('month'));

    if (isOpen) {
      return (
        <FullBillEstimate
          onClose={this.props.onClose}
          color={color}
          month={startDate}
          billing={billing}
          plans={plans}
          workspace={this.props.workspace}
          subscriptionDetails={subscriptionDetails}
          upcomingInvoices={upcomingInvoices.items}
          upcomingInvoice={upcomingInvoices}
        />
      );
    }

    return (
      <Card
        noGrid
        loading={this.props.isLoading}
        action={this.props.onOpen}
        color={this.props.color}
        title={
          <div className="label-color">
            <FormattedMessage {...appMessages.upcomingInvoice} />
          </div>
        }
        subTitle={
          <span className="estimated-revenue__subtitle">{`Due ${subscriptionDetails &&
            subscriptionDetails.upcomingInvoices &&
            dateFormatter4(subscriptionDetails.upcomingInvoices.targetDate)}`}</span>
        }
        className={classNames('estimated-revenue', {
          'estimated-revenue_loading': this.props.isLoading,
          'billing-revenue': this.props.isBillingPage,
        })}
        customText={
          upcomingInvoices && <FormattedMessage {...appMessages.viewFull} />
        }
        content={this.renderContent()}
      />
    );
  }
}

Estimate.propTypes = {
  user: PropTypes.object,
};

export default Estimate;
