import { CloseIcon } from 'images/common';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import appMessages from 'utils/messages';


class GetStartedItem extends React.Component {
  render() {
    const {
      title,
      description,
      action,
      index,
      actionLink,
      onRedirect,
      onClose,
      hideLine,
    } = this.props;

    return (
      <>
        <div onClick={onRedirect} key={index}>
          <div className="notification get-started-item">
            <div className="notification__header">
              <div className="notification__meta">
                <span className="text-color wt-500">{title}</span>
              </div>
              <div className="notification__indicator" />
              {onClose
              && (
                <span
                  onClick={this.props.onSkip}
                  className="notification__close_indicator"
                >
                  <CloseIcon />
                </span>
              )
              }
            </div>
            <div className="notification__content text-color">
              <div className="grid-row grid-row_aic">
                <div className="grid-col">
                  <div className="notification__text">{description}</div>
                </div>
              </div>
            </div>
            <div className="notification__take-action">
              <Link
                to={actionLink}
                className="notification__action text-link success"
                onClick={onClose}
              >
                {action || <FormattedMessage {...appMessages.setup} />}
              </Link>
              <span className="notification__action label-link" onClick={this.props.onSkip}>
                {this.props.skipBtn || <FormattedMessage {...appMessages.skipTask} />}
              </span>
            </div>
          </div>
          {!hideLine && <div className="notifications-bar__hr1" />}
        </div>
      </>
    );
  }
}

GetStartedItem.propTypes = {};

export default GetStartedItem;
