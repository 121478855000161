import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();

        const [refreshKey, setRefreshKey] = useState(0);
        const prevLocationRef = useRef(location.pathname);  // Track the previous location

        useEffect(() => {
            // Only trigger re-render if the user navigates to the same path
            if (location.pathname === prevLocationRef.current) {
                setRefreshKey((prevKey) => prevKey + 1);
            }
            // Update the reference for the previous location
            prevLocationRef.current = location.pathname;
        }, [location]);

        const forceRefresh = () => {
            // Manually trigger a re-render
            setRefreshKey((prevKey) => prevKey + 1);
        };

        return (
            <Component {...props} router={{ location, navigate, params, forceRefresh }} key={refreshKey} />
        );
    }

    return ComponentWithRouterProp;
}
