import { fromJS } from 'immutable';
import {
  ADD_ROLE_REQUEST,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_ERROR,
  LIST_ROLE_REQUEST,
  LIST_ROLE_SUCCESS,
  LIST_ROLE_ERROR,
  DETAIL_ROLE_REQUEST,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_ERROR,
  UPDATE_ROLE_REQUEST,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_ERROR,
  DETAIL_ROLE_SUCCESS,
  LIST_PERMISSION_REQUEST,
  LIST_PERMISSION_SUCCESS,
  LIST_PERMISSION_ERROR,
  ADD_ROLE_TO_USERS_SUCCESS,
  ADD_ROLE_TO_USERS_REQUEST,
  INDIVIDUAL_ROLE_REQUEST,
  INDIVIDUAL_ROLE_SUCCESS,
} from './constants';

export const initialState = fromJS({
  loading: false,
  roleList: null,
  errors: null,
  role: null,
  detailLoading: false,
  detailErrors: null,
  detailRole: null,
  detailRequest: null,
});

function roleReducer(state = initialState, action) {
  switch (action.type) {
    case INDIVIDUAL_ROLE_REQUEST:
      return state
        .set('role', null)
        .set('loading', true);
    case INDIVIDUAL_ROLE_SUCCESS:
      return state
        .set('role', action.payload)
        .set('loading', false);
    case DETAIL_ROLE_REQUEST:
      return state
        .set('detailRole', null)
        .set('loading', true);
    case DETAIL_ROLE_SUCCESS:
      return state
        .set('detailRole', action.payload)
        .set('loading', false);
    case ADD_ROLE_TO_USERS_REQUEST:
      return state
        .set('loading', true);
    case ADD_ROLE_TO_USERS_SUCCESS:
      return state
        .set('detailRole', action.payload)
        .set('loading', false);
    case LIST_ROLE_SUCCESS:
      return state
        .set('loading', false)
        .set('roleList', {
          count: action.payload.length,
          data: action.payload,
        });
    case LIST_ROLE_REQUEST:
      return state
        .set('loading', true);
    case LIST_ROLE_ERROR:
      return state
        .set('errors', action.errors)
        .set('loading', false);
    case DELETE_ROLE_REQUEST:
    case ADD_ROLE_REQUEST:
    case UPDATE_ROLE_REQUEST:
      return state
        .set('detailRole', null)
        .set('role', null)
        .set('detailLoading', true)
        .set('detailRequest', null);
    case ADD_ROLE_SUCCESS:
      return state
        .set('detailRole', action.payload)
        .set('detailLoading', false)
        .set('detailRequest', 'create');
    case DELETE_ROLE_SUCCESS:
      return state
        .set('detailRole', action.payload)
        .set('detailLoading', false)
        .set('detailRequest', 'remove');
    case UPDATE_ROLE_SUCCESS:
      return state
        .set('role', action.payload)
        .set('detailRole', action.payload)
        .set('detailLoading', false)
        .set('detailRequest', 'update');
    case ADD_ROLE_ERROR:
      return state
        .set('detailErrors', action.errors)
        .set('loading', false)
        .set('detailLoading', false)
        .set('detailRequest', 'create');
    case DELETE_ROLE_ERROR:
      return state
        .set('detailErrors', action.errors)
        .set('detailLoading', false)
        .set('detailRequest', 'remove');
    case UPDATE_ROLE_ERROR:
      return state
        .set('detailErrors', action.errors)
        .set('detailLoading', false)
        .set('detailRequest', 'update');
    case LIST_PERMISSION_REQUEST:
      return state.set('permissionLoading', true);
    case LIST_PERMISSION_SUCCESS:
      return state
        .set('permissionList', action.payload)
        .set('permissionLoading', false);
    case LIST_PERMISSION_ERROR:
      return state
        .set('permissionErrors', action.errors)
        .set('permissionLoading', false);
    default:
      return state;
  }
}

export default roleReducer;
