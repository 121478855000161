import ActionMenu from 'components/ActionMenu';
import { MarkedRead, CheckAllIcon, CloseXIcon } from 'images/common';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'utils/messages';


const Menu = ({
  onDeleteAll, markAllAsUnRead, markAllAsRead, closeMenu, unreadCount, notificationCount,
}) => (
  <>
    <ActionMenu
      id="notification-mark-read"
      icon={<CheckAllIcon />}
      onClick={() => {
        if (unreadCount && notificationCount) {
          markAllAsRead();
          closeMenu();
        }
      }
      }
      disabled={!unreadCount || !notificationCount}
      text={<FormattedMessage {...messages.markAllAsRead} />}
    />
    <ActionMenu
      icon={<MarkedRead />}
      id="notification-mark-unread"
      onClick={() => {
        if (notificationCount && !unreadCount) {
          markAllAsUnRead();
          closeMenu();
        }
      }}
      disabled={!notificationCount || unreadCount}
      text={<FormattedMessage {...messages.markAllAsUnread} />}
    />
    <hr />
    <ActionMenu
      id="notification-clear"
      icon={<CloseXIcon />}
      onClick={() => {
        if (notificationCount) {
          onDeleteAll();
          closeMenu();
        }
      }}
      disabled={!notificationCount}
      text={<FormattedMessage {...messages.clearAll} />}
    />
  </>
);

export default Menu;
