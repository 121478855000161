import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import Button from 'components/Button';
import messages from 'utils/messages';
import EditPencil from 'images/common/edit-pencil';

class Card extends Component {
  render() {
    return (
      <div className={classnames('content-box', this.props.className)}>
        { this.props.onEdit
          && (
            <Button
              className={classnames('overview__edit', `overview__edit_${this.props.color}`)}
              onClick={this.props.onEdit}
            >
              <EditPencil />
              <FormattedMessage {...messages.edit} />
            </Button>
          )
        }

        <div className="flow-modules__title">
          { this.props.title }
        </div>

        { this.props.subTitle
          && (
            <div className="flow-modules__subtitle">
              { this.props.subTitle }
            </div>
          )
        }

        <div className="data-table">
          <table className="data-table__table">
            <tbody className="data-table__body">
              { this.props.content }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

Card.propTypes = {
  color: PropTypes.string,
  onEdit: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  subTitle: PropTypes.any,
  content: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Card.defaultProps = {
  color: 'accent0',
  className: 'flow-modules',
};

export default Card;
