import { fromJS } from 'immutable';
import { LOGOUT } from 'containers/App/constants';
import { SELECT_WORKSPACE_SUCCESS } from 'containers/Login/constants';
import {
  FETCH_WORKSPACE_HISTORY_REQUEST,
  FETCH_WORKSPACE_HISTORY_SUCCESS,
} from './constants';

export const initialState = fromJS({
  data: [],
  isLoading: false,
});

function userReducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT:
    case SELECT_WORKSPACE_SUCCESS:
      return initialState;
    case FETCH_WORKSPACE_HISTORY_REQUEST:
      return state.set('data', []).set('isLoading', true);
    case FETCH_WORKSPACE_HISTORY_SUCCESS:
      return state.set('data', action.payload).set('isLoading', false);
    default:
      return state;
  }
}

export default userReducer;
