import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import classNames from 'classnames';
import CardTable from 'components/Detail/CardTable';
import messages from 'utils/messages'
import Button from 'components/Button';
import AHref from 'components/AHref';
import AuthenticationPage from './AuthenticationPage'
import RecoveryKey from './RecoveryKey'
import BarcodeReader from './BarcodeReader';
import { defaultNotifier } from 'functions/notificationHandler';
import {initTwoFactorSetup, disableTwoFactor} from 'api/auth';
import {
  makeSelectMe
} from '../../selectors';
import {
  updateUserRequest,
  fetchMeRequest
} from '../../actions';
import { makeSelectFetchWorkspace } from 'containers/Settings/Workspace/selectors';
import {LockIcon} from 'images/common'
import OverLimitModal from 'components/OverLimitModal';
import { intl } from 'containers/LanguageProvider/intlProvider';
import ListScroll from 'components/List/ListScroll';

import saga from '../../saga';
import reducer from '../../reducer';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';

class Authentication extends Component {
  state = {
    showDropdown: false,
    qrCodeString: '',
    showAutheticationPage: false,
    showQRCodeWrapper: false,
    showScheduleModal: false
  }

  async componentDidMount() {
    const {activeState} = this.props;

    if (activeState) {
      this.setState({showAutheticationPage: activeState});
    }
  }

  onShowModal = () => {
    this.props.onShowCalendar();
    this.setState({showScheduleModal: true});
  }

  onShowAutheticationPage = async () => {
    const {user} = this.props;

    if (user.twoFactorEnabled) {
      const {data} = await disableTwoFactor();

      if (data.errors) {
        return defaultNotifier(data.errors)
      }

      return this.props.fetchMeRequest()
    }

    this.setState({showAutheticationPage: true})
  }

  onClose = () => {
    this.setState({showAutheticationPage: false, showQRCodeWrapper: false, showRecoveryPage: false})
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  onCloseAllPage = () => {
    this.setState({showAutheticationPage: false, showQRCodeWrapper: false, showRecoveryPage: false})
  }

  onSubmitClose = () => {
    this.setState({showAutheticationPage: false, showQRCodeWrapper: false, showRecoveryPage: false})
  }

  onShowRecoveryCode = () => {
    this.setState({showRecoveryPage: true})
  }

  onToggleDropdown = () => {
    this.setState({showDropdown: !this.state.showDropdown})
  }

  renderContent2 = () => {
    const {user} = this.props;

    return (
      <>
          {this.state.showQuotaModal && (
            <OverLimitModal
              disabled={this.props.hasNoWsPermission}
              title={intl.formatMessage({...messages.workspaceWide})}
              body={intl.formatMessage({...messages.toEnableFeatureUpgrade})}
              onCancel={() => this.setState({ showQuotaModal: false })}
            />
          )}
        <tr className="grid-row">
          <td className="grid-col">
            <div className="overview__desc">
              <FormattedMessage {...messages.twoFactorAuth}/>
            </div>
            {this.props.disabled
              ?
              <Button
                onClick={this.onShowModal}
                color='button button_rect button_label'><LockIcon/><FormattedMessage {...messages.enableNow}/></Button>
              :
              <Button
                onClick={this.onShowAutheticationPage}
                color='button button_rect'>{user.twoFactorEnabled ? <FormattedMessage {...messages.disable}/> : <FormattedMessage {...messages.enableNow}/>}</Button>
            }
          </td>
        </tr>
      </>
    )
  }

  onSetup = async () => {
    this.setState({showQRCodeWrapper: true})
    const {data} = await initTwoFactorSetup();
    if (data.errors) {
      return defaultNotifier(data.errors)
    }

    this.setState({qrCodeString: data.data.initTwoFactorSetup})
  }

  render() {
    const {user, disabled, workspace, me} = this.props;

    if (this.state.showQRCodeWrapper) {
      return <BarcodeReader
        onClose={this.onClose}
        onCloseAllPage={this.onCloseAllPage}
        me={this.props.me}
        qrCodeString={this.state.qrCodeString}
        workspace={this.props.workspace}
        onSubmitClose={this.onSubmitClose}
        fetchMe={this.props.fetchMeRequest}
        value={this.state.qrCodeString && this.state.qrCodeString.qrCodeString}/>
    }

    if (this.state.showRecoveryPage) {
      return <RecoveryKey
        downloadInfo={`${workspace && workspace.name}:${me && me.email}`}
        onClose={this.onClose}/>
    }

    if (this.state.showAutheticationPage) {
      return <AuthenticationPage
        onClose={this.onClose}
        onSubmit={this.onSetup}/>
    }

    return (
      <CardTable
        color={this.props.color}
        manageEditModal={
          <div>
            {!this.props.disabled &&
            <AHref
              href="javascript:void(0)"
              className="overview__edit overview__edit_accent4 relative"
              onClick={this.onToggleDropdown}
            >
              Manage
              <div className={classNames('actions__dropdown actions__dropdown_xm', {
                'actions__dropdown_visible': this.state.showDropdown
              })}>
                <span
                  onClick={this.onShowRecoveryCode}
                  disabled={!user.twoFactorEnabled}
                  className={classNames('actions__option cursor h4', {
                    actions__option_disabled: !user.twoFactorEnabled
                  })}
                >
                  <FormattedMessage
                    {...messages.recoveryKey}
                  />{' '}
                </span>
                {disabled
                  ?
                  <span
                    onClick={this.onShowAutheticationPage}
                    className="actions__option cursor h4"
                  >
                    <FormattedMessage
                      {...messages.enableNow}
                    />
                  </span>
                  :
                  <span
                    onClick={this.onShowAutheticationPage}
                    className="actions__option cursor h4"
                  >
                    {user.twoFactorEnabled ?
                      <FormattedMessage
                        {...messages.disable}
                      />
                      :
                      <FormattedMessage
                        {...messages.enableNow}
                      />
                    }
                    {' '}
                  </span>
                }
              </div>
            </AHref>
            }
          </div>
        }
        title={<FormattedMessage {...messages.twoFactorAuthentication}/>}
        className="overview"
        content={this.renderContent2()}
      />
    )
  }
}

Authentication.propTypes = {
  me: PropTypes.object
}

const mapStateToProps = createStructuredSelector({
  me: makeSelectMe(),
  workspace: makeSelectFetchWorkspace(),
});

const withConnect = connect(
  mapStateToProps,
  {
    updateUserRequest,
    fetchMeRequest
  }
);

const withReducer = injectReducer({ key: 'user', reducer });
const withSaga = injectSaga({ key: 'user', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect
)(Authentication);
