import React, { Component } from 'react'
import PropTypes from 'prop-types'

class EstimateItem extends Component {
  render() {
    const {name, amount, endpoints, description, currency} = this.props
    return (
      <div className="grid-row estimated-revenue__item">
        <div className="grid-col">
          <div className="estimated-revenue__value">{name}</div>
          {description && <div className="estimated-revenue__value">{description}</div>}
          <div className="estimated-revenue__value">{endpoints}</div>
        </div>
        <div className="grid-col grid-col_auto">
          <div className="estimated-revenue__value">{amount}</div>
        </div>
      </div>
    )
  }
}

EstimateItem.propTypes = {
  name: PropTypes.string,
  amount: PropTypes.string
}

export default EstimateItem
