import React from 'react';
import { DateRangePicker } from 'react-date-range';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import messages from 'utils/messages';
import { FILTER_TYPE } from '../constants';

class DatePicker extends React.Component {
  state = {
    dateInfo: {
      startDate: new Date(),
      endDate: new Date(),
      key: 'dateInfo',
    },
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onEnter, false);
  }

  onEnter = (event) => {
    if (event.key === 'Enter') {
      // on Esc button click it should close popup
      this.onApply();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEnter, false);
  }

  onApply = () => {
    const { onFilterApply, name } = this.props;

    onFilterApply(name, this.state.dateInfo, FILTER_TYPE.DATE);
  }

  onUpdate = (item) => {
    this.setState({ dateInfo: item.dateInfo });
  }

  render() {
    const { customClass, title } = this.props;

    return (
      <>
        <div className="filters-bar__heading">{title}</div>
        <DateRangePicker
          color={customClass}
          weekStartsOn={1}
          showPreview={false}
          dateDisplayFormat="d MMM yyyy"
          onChange={item => this.onUpdate(item)}
          ranges={[this.state.dateInfo]}
          rangeColors={[customClass]}
        />
        <button type="button" className={classNames('filters-bar__apply', { [customClass]: customClass })} onClick={this.onApply}>
          <FormattedMessage {...messages.apply} />
        </button>
      </>
    );
  }
}

export default DatePicker;
