import { previewUpcomingInvoice } from 'api/billing';
import PaymentInfo from 'containers/Auth/Register/PaymentInfo';
import {
  AmericanExpressIcon,
  JcbIcon,
  MasterCardIcon, VisaIcon,
} from 'images/common';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm } from 'redux-form';

class ConfirmOrder extends Component {
  state = {
    billingInfo: '',
  }

  getIcon = {
    visa: <VisaIcon />,
    amex: <AmericanExpressIcon />,
    jcb: <JcbIcon />,
    mastercard: <MasterCardIcon />,
  };

  async componentDidMount() {
    const {plan, subscriptionId} = this.props;
    this.setState({isLoading: true})
    const {data} = await previewUpcomingInvoice({
      subscriptionId: subscriptionId,
      priceId: plan.id
    })
    this.setState({isLoading: false})
    this.setState({billingInfo: data.data.previewUpcomingInvoice})
  }

  onUpdateInterval = async (priceId, interval) => {
    const { subscriptionId } = this.props;
    this.setState({ isLoading: true });

    const { data } = await previewUpcomingInvoice({
      subscriptionId,
      priceId,
    });
    this.setState({ isLoading: false });
    this.setState({ billingInfo: data.data.previewUpcomingInvoice });

    this.props.onUpdateInterval(priceId, interval);
  }

  render() {
    const {
      plan,
      isDisabled,
      isLoading,
      workspace,
      interval,
    } = this.props;

    return (
      <PaymentInfo
        {...this.props}
        onUpdateInterval={this.onUpdateInterval}
        isUpdatePlan
        plans={this.props.plans}
        interval={interval}
        showCreditCard={!this.props.billing.paymentMethod}
        onChangeCard={this.props.onChangeCard}
        billing={this.props.billing}
        onChangePayment={this.props.onChangePayment}
        isDisabled={isDisabled}
        isValidZipcode
        updateCreditCard={this.props.updateCreditCard}
        onUpdateCreditToggle={this.props.onUpdateCreditToggle}
        billingInfo={this.state.billingInfo}
        onBack={() => this.props.onShowPlanPage()
        }
        subscription={plan}
        isLoading={isLoading}
        billingLoading={this.state.isLoading}
        workspaceName={
          workspace.name
        || (this.props.workspace && this.props.workspace.subDomain)
        }
        workspace={this.props.workspace}
        onClose={this.props.onClose}
        onSubmit={this.props.onSubmit}
      />
    );
  }
}

ConfirmOrder.propTypes = {
  user: PropTypes.object,
};

export default reduxForm({
  form: 'BILLING_INFORMATION',
})(ConfirmOrder);
