import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { X } from 'react-feather';
import OutsideClickHandler from 'react-outside-click-handler'
import classnames from 'classnames';

class Modal extends Component {
  state = {
    showPopup: false
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);

    setTimeout(() => {
      this.setState({showPopup: true})
    }, 100)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  escFunction = (event) => {
    // event.preventDefault()
    // event.stopPropagation()

    if (event.keyCode === 27) {
      this.onCancel()
    }
  }

  onCancel = () => {
    const {onClose} = this.props
    this.setState({showPopup: false}, () => {
      setTimeout(()=> {
        onClose()
      }, 100)
    })
  }

  render() {
    const { secondaryBody, classNames } = this.props;

    const wrapperClass = classnames('modal', {
      'modal_visible': this.state.showPopup
    });

    return (
    <>
      <div className="overlay overlay_visible" />
      <div className={wrapperClass}>
        <OutsideClickHandler
          onOutsideClick={this.onCancel}
        >
          <div className={`${classNames} modal__frame`}>
            <div className="modal__title">{this.props.title}</div>
            <div className="modal__cont">{this.props.body}</div>
            {secondaryBody && <div className="modal__cont">{secondaryBody}</div>}
            <div className="modal__foot">{this.props.buttons}</div>
            {this.props.onClose && <button type="button" className="modal__close" onClick={this.onCancel}>
              <X />
            </button>
            }
          </div>
        </OutsideClickHandler>
      </div>
    </>
    )
  }
}

Modal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  body: PropTypes.node,
  buttons: PropTypes.any.isRequired,
  onClose: PropTypes.func,
};

export default Modal;
