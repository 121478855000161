import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import messages from 'utils/messages';
import { plans } from './plans';

const PlanHeader = ({
  onUpdateIndex, activeIndex, showFeature, showAllFeatures, customName,
}) => (
  <div className={classNames('plan-info-wrapper', {
    [customName]: customName,
  })}
  >
    <div className="plan-info-container">
      <div className="plan-info-header" />
    </div>
    {/* <div className="plan-info-footer" /> */}
    {Object.keys(plans)
      .filter((item, index) => {
        if (!showAllFeatures) {
          return index === 0;
        }

        return true;
      })
      .map((plan, ind) => (
        <div key={ind}>
          <div className="plan-info-feature">{plan}</div>
          {plans[plan]
            .map((item, index) => (
              <div
                key={index}
                className={classNames('plan-info-feature__item', {
                  'plan-active-index': activeIndex === item.id,
                  'no-cursor': !item.description,
                })}
                onClick={() => showFeature(item.description, item.name)}
                onMouseEnter={() => onUpdateIndex(item.id)}
                onMouseLeave={() => onUpdateIndex()}
              >
                <span
                  className={classNames({
                    'has-description': item.description,
                  })}
                >
                  <FormattedMessage {...messages[item.name]} />
                </span>
              </div>
            ))}
        </div>
      ))}
  </div>
);

PlanHeader.propTypes = {
  loginRequest: PropTypes.func,
};

export default PlanHeader;
