import { fromJS } from 'immutable';
import { SELECT_WORKSPACE_SUCCESS } from 'containers/Login/constants';
import {
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATION_SETTINGS_REQUEST,
  MARK_AS_READ_SUCCESS,
  MARK_AS_UNREAD_SUCCESS,
  FETCH_NOTIFICATION_SETTINGS_SUCCESS,
  MARK_AS_ALL_READ_SUCCESS,
  MARK_AS_ALL_UNREAD_SUCCESS,
} from './constants';

export const initialState = fromJS({
  notifications: null,
  errors: null,
  loading: false,
  loadingSettings: false,
  settings: {
    module: false,
    application: false,
    video: false,
    model: false,
    profile: false,
    device: false,
    billing: false,
    dashboard: false,
    user: false,
  },
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_REQUEST:
      return state.merge({
        errors: null,
        // loading: true,
        notifications: state.toJS().notifications,
      });
    case SELECT_WORKSPACE_SUCCESS:
      return state.merge({
        errors: null,
        loading: false,
        notifications: null,
      });
    case FETCH_NOTIFICATIONS_SUCCESS: {
      const type = action.payload && action.payload.type;

      if (type === 'delete') {
        return state.merge({
          errors: null,
          loading: false,
          notifications: action.payload,
        });
      }

      if (state.toJS()
      && state.toJS().notifications
      && (state.toJS().notifications.notificationList[0].id) === action.payload.notificationList[0].id
      && state.toJS().notifications.unreadCount === action.payload.unreadCount) {
        return state.merge({
          errors: null,
          loading: false,
          notifications: {
            unreadCount: action.payload.notifications.unreadCount,
            notificationList: state.toJS().notifications.notificationList,
          },
        });
      }

      if (state.toJS()
      && state.toJS().notifications
      && action.payload.params) {
        return state.merge({
          errors: null,
          loading: false,
          notifications: {
            unreadCount: action.payload.unreadCount,
            notificationList: state.toJS().notifications.notificationList.concat(action.payload.notificationList),
          },
        });
      }

      return state.merge({
        errors: null,
        loading: false,
        notifications: action.payload,
      });
    }
    case MARK_AS_READ_SUCCESS: {
      const payload = state.toJS().notifications.notificationList.map((item) => {
        if (item.id === action.payload) {
          return {
            ...item,
            readState: true,
          };
        }

        return item;
      });

      return state.merge({
        errors: null,
        loading: false,
        notifications: {
          unreadCount: state.toJS().notifications.unreadCount - 1,
          notificationList: payload,
        },
      });
    }
    case MARK_AS_UNREAD_SUCCESS: {
      const payload = state.toJS().notifications.notificationList.map((item) => {
        if (item.id === action.payload) {
          return {
            ...item,
            readState: false,
          };
        }

        return item;
      });

      return state.merge({
        errors: null,
        loading: false,
        notifications: {
          unreadCount: state.toJS().notifications.unreadCount + 1,
          notificationList: payload,
        },
      });
    }

    case MARK_AS_ALL_READ_SUCCESS: {
      const payload = state.toJS().notifications.notificationList.map(item => ({
        ...item,
        readState: true,
      }));

      return state.merge({
        errors: null,
        loading: false,
        notifications: {
          unreadCount: 0,
          notificationList: payload,
        },
      });
    }

    case MARK_AS_ALL_UNREAD_SUCCESS: {
      const payload = state.toJS().notifications.notificationList.map(item => ({
        ...item,
        readState: false,
      }));

      return state.merge({
        errors: null,
        loading: false,
        notifications: {
          unreadCount: payload.length,
          notificationList: payload,
        },
      });
    }

    case FETCH_NOTIFICATION_SETTINGS_REQUEST:
      return state.merge({
        errors: null,
        loadingSettings: true,
        settings: {
          ...state.settings,
        },
      });
    case FETCH_NOTIFICATION_SETTINGS_SUCCESS:
      return state.merge({
        errors: null,
        loadingSettings: false,
        settings: {
          ...action.payload,
        },
      });
    default:
      return state;
  }
}

export default reducer;
