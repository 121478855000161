import { createSelector } from 'reselect';

const selectRole = state => state.role;
const selectGlobal = state => state.global;

export const roleListSelector = () => createSelector(selectRole, userState => (userState ? userState.get('roleList') : null));

export const loadingSelector = () => createSelector(selectRole, userState => userState.get('loading'));

export const listErrorSelector = () => createSelector(selectRole, userState => userState.get('errors'));

export const getDetailRole = () => createSelector(selectGlobal, globalState => globalState.get('userData'));

export const getIndividualRole = () => createSelector(selectRole, state => state.get('role'));

export const getDetailLoading = () => createSelector(selectRole, state => state.get('detailLoading'));

export const getDetailErrors = () => createSelector(selectRole, state => state.get('detailErrors'));

export const getDetailType = () => createSelector(selectRole, state => state.get('detailRequest'));

export const permissionListSelector = () => createSelector(selectRole, userState => userState.get('permissionList'));

export const permissionLoadingSelector = () => createSelector(selectRole, userState => userState.get('permissionLoading'));

export const permissionErrorSelector = () => createSelector(selectRole, userState => userState.get('permissionErrors'));

export { selectRole };
