import {
  GET_PROFILES_REQUEST,
  GET_PROFILES_SUCCESS,
  GET_PROFILES_ERROR,
  GET_PENDING_DEVICE_REQUEST,
  GET_PENDING_DEVICE_SUCCESS,
  GET_PENDING_DEVICE_ERROR,
  GET_DEVICES_REQUEST,
  GET_DEVICES_SUCCESS,
  GET_DEVICES_ERROR,
  ASSIGN_DEVICES_SUCCESS,
  ASSIGN_DEVICES_ERROR,
  ASSIGN_DEVICES_REQUEST,
  ASSIGN_DEVICE_SUCCESS,
  ASSIGN_DEVICE_ERROR
} from './constants'

export function getProfilesRequest(payload) {
  return {
    type: GET_PROFILES_REQUEST,
    payload
  }
}

export function getPendingDeviceRequest(payload) {
  return {
    type: GET_PENDING_DEVICE_REQUEST,
    payload,
  };
}

export function getPendingDeviceSuccess(payload) {
  return {
    type: GET_PENDING_DEVICE_SUCCESS,
    payload,
  };
}

export function getProfilesSuccess(payload) {
  return {
    type: GET_PROFILES_SUCCESS,
    payload
  }
}

export function getProfilesError(errors) {
  return {
    type: GET_PROFILES_ERROR,
    errors
  }
}

export function getDevicesRequest(payload, meta) {
  return {
    type: GET_DEVICES_REQUEST,
    payload,
    meta
  }
}

export function getDevicesSuccess(payload) {
  return {
    type: GET_DEVICES_SUCCESS,
    payload
  }
}

export function getDevicesError(errors) {
  return {
    type: GET_DEVICES_ERROR,
    errors
  }
}

export function assignDevicesRequest(payload, onCallback) {
  return {
    type: ASSIGN_DEVICES_REQUEST,
    payload,
    meta: {onCallback}
  }
}

export function assignDevicesSuccess(payload) {
  return {
    type: ASSIGN_DEVICES_SUCCESS,
    payload
  }
}

export function assignDevicesError(errors) {
  return {
    type: ASSIGN_DEVICES_ERROR,
    errors
  }
}

export function assignDeviceSuccess(payload) {
  return {
    type: ASSIGN_DEVICE_SUCCESS,
    payload
  }
}

export function assignDeviceError(errors) {
  return {
    type: ASSIGN_DEVICE_ERROR,
    errors,
  };
}
