import { defineMessages } from 'react-intl';

export default defineMessages({
  save: {
    id: 'save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'Cancel',
  },
  back: {
    id: 'back',
    defaultMessage: 'Back',
  },
  type: {
    id: 'type',
    defaultMessage: 'Type',
  },
  version: {
    id: 'version',
    defaultMessage: 'Version',
  },
  settings: {
    id: 'settings',
    defaultMessage: 'Settings',
  },
  selected: {
    id: 'selected',
    defaultMessage: 'Selected',
  },
  search: {
    id: 'search',
    defaultMessage: 'Search',
  },
  createJoin: {
    id: 'createJoin',
    defaultMessage: 'Create Workspace',
  },
  logout: {
    id: 'logout',
    defaultMessage: 'Sign out',
  },
  dashboard: {
    id: 'dashboard',
    defaultMessage: 'Dashboard',
  },
  created: {
    id: 'created',
    defaultMessage: 'Created',
  },
  library: {
    id: 'library',
    defaultMessage: 'Library',
  },
  overview: {
    id: 'overview',
    defaultMessage: 'Overview',
  },
  information: {
    id: 'information',
    defaultMessage: 'Information',
  },
  tags: {
    id: 'tags',
    defaultMessage: 'Tags',
  },
  edit: {
    id: 'edit',
    defaultMessage: 'Edit',
  },
  lastUpdated: {
    id: 'lastUpdated',
    defaultMessage: 'Last updated',
  },
  lastUpdatedBy: {
    id: 'lastUpdatedBy',
    defaultMessage: 'Last updated by',
  },
  description: {
    id: 'description',
    defaultMessage: 'Description',
  },
  continue: {
    id: 'continue',
    defaultMessage: 'Continue',
  },
  create: {
    id: 'create',
    defaultMessage: 'Create',
  },
  profiles: {
    id: 'profiles',
    defaultMessage: 'Profiles',
  },
  profile: {
    id: 'profile',
    defaultMessage: 'Profile',
  },
  devices: {
    id: 'devices',
    defaultMessage: 'Devices',
  },
  applications: {
    id: 'applications',
    defaultMessage: 'Applications',
  },
  modules: {
    id: 'modules',
    defaultMessage: 'Modules',
  },
  application: {
    id: 'application',
    defaultMessage: 'Application',
  },
  module: {
    id: 'module',
    defaultMessage: 'Module',
  },
  notifications: {
    id: 'notifications',
    defaultMessage: 'Notifications',
  },
  marketplace: {
    id: 'marketplace',
    defaultMessage: 'Marketplace',
  },
  collapse: {
    id: 'collapse',
    defaultMessage: 'Collapse',
  },
  createdBy: {
    id: 'createBy',
    defaultMessage: 'Created by',
  },
  createdAt: {
    id: 'createAt',
    defaultMessage: 'Created at',
  },
  deployment: {
    id: 'deployment',
    defaultMessage: 'Deployment',
  },
  lastModified: {
    id: 'lastModified',
    defaultMessage: 'Last Modified',
  },
  modifiedBy: {
    id: 'modifiedBy',
    defaultMessage: 'Modified By',
  },
  user: {
    id: 'user',
    defaultMessage: 'User',
  },
  access: {
    id: 'access',
    defaultMessage: 'Access',
  },
  account: {
    id: 'account',
    defaultMessage: 'Account',
  },
  actions: {
    id: 'actions',
    defaultMessage: 'Actions',
  },
  roles: {
    id: 'roles',
    defaultMessage: 'Roles',
  },
  download: {
    id: 'download',
    defaultMessage: 'Download',
  },
  scopes: {
    id: 'scopes',
    defaultMessage: 'Scopes',
  },
  workspace: {
    id: 'workspace',
    defaultMessage: 'Workspace',
  },
  groups: {
    id: 'groups',
    defaultMessage: 'Groups',
  },
  licenses: {
    id: 'licenses',
    defaultMessage: 'Licenses',
  },
  personalProfile: {
    id: 'personalProfile',
    defaultMessage: 'Personal Profile',
  },
  confirm: {
    id: 'confirm',
    defaultMessage: 'Confirm',
  },
  noGroupAssigned: {
    id: 'noGroupAssigned',
    defaultMessage: 'No Group assigned',
  },
  noScopeAssigned: {
    id: 'noScopeAssigned',
    defaultMessage: 'No Scope assigned',
  },
  workspaceLogo: {
    id: 'workspaceLogo',
    defaultMessage: 'Workspace Logo',
  },
  colorTheme: {
    id: 'colorTheme',
    defaultMessage: 'Color Theme',
  },
  secondaryColors: {
    id: 'secondaryColors',
    defaultMessage: 'Secondary Colors',
  },
  noData: {
    id: 'noData',
    defaultMessage: 'No Data.',
  },
  // WOrkspace
  manageWorkspaces: {
    id: 'manageWorkspaces',
    defaultMessage: 'Manage workspaces',
  },
  workspaceID: {
    id: 'workspaceID',
    defaultMessage: 'Workspace ID',
  },
  workspaceName: {
    id: 'workspaceName',
    defaultMessage: 'Workspace Name',
  },
  yourWorkspaces: {
    id: 'yourWorkspaces',
    defaultMessage: 'Your workspaces',
  },
  newWorkspace: {
    id: 'newWorkspace',
    defaultMessage: 'New workspace',
  },
  manageWorkspace: {
    id: 'manageWorkspace',
    defaultMessage:
      'You can be owner or member of multiple workspaces at the same time. You are able to switch between them whenever you like. Every workspace is fully independent and billed based on its subscriptions.',
  },
  remove: {
    id: 'remove',
    defaultMessage: 'Remove',
  },
  otherWorkspaces: {
    id: 'otherWorkspaces',
    defaultMessage: 'Other workspaces',
  },
  otherWorkspaceDesc: {
    id: 'otherWorkspaceDesc',
    defaultMessage:
      'There are other workspaces you are eligible to join due to your email address.',
  },
  addWorkspace: {
    id: 'addWorkspace',
    defaultMessage: 'New Workspace',
  },
  selectWorkspace: {
    id: 'selectWorkspace',
    defaultMessage: 'Select your workspace.',
  },
  createOrJoinWorkspace: {
    id: 'createOrJoinWorkspace',
    defaultMessage: 'Create Workspace',
  },
  addedBy: {
    id: 'addedBy',
    defaultMessage: 'Added by',
  },
  deviceType: {
    id: 'deviceType',
    defaultMessage: 'Device Type',
  },
  deviceID: {
    id: 'deviceID',
    defaultMessage: 'Device ID',
  },
  osVersion: {
    id: 'osVersion',
    defaultMessage: 'OS Version',
  },
  ipAddress: {
    id: 'ipAddress',
    defaultMessage: 'IP Address',
  },
  addedAt: {
    id: 'addedAt',
    defaultMessage: 'Added at',
  },
  device: {
    id: 'device',
    defaultMessage: 'Device',
  },
  close: {
    id: 'close',
    defaultMessage: 'Close',
  },
  deviceDetails: {
    id: 'deviceDetails',
    defaultMessage: 'Device Details',
  },
  userName: {
    id: 'userName',
    defaultMessage: 'User Name',
  },
  userEmail: {
    id: 'userEmail',
    defaultMessage: 'User Email',
  },
  userRole: {
    id: 'userRole',
    defaultMessage: 'User Role',
  },
  roleType: {
    id: 'roleType',
    defaultMessage: 'Role Type',
  },
  status: {
    id: 'status',
    defaultMessage: 'Status',
  },
  modified: {
    id: 'modified',
    defaultMessage: 'Modified',
  },
  modifiedAt: {
    id: 'modifiedAt',
    defaultMessage: 'Modified at',
  },
  lastSignIn: {
    id: 'lastSignIn',
    defaultMessage: 'Last sign-in',
  },
  date: {
    id: 'date',
    defaultMessage: 'Date',
  },
  invoiceNo: {
    id: 'invoiceNo',
    defaultMessage: 'Invoice number',
  },
  billingPeriod: {
    id: 'billingPeriod',
    defaultMessage: 'Billing Period',
  },
  amount: {
    id: 'amount',
    defaultMessage: 'Amount',
  },
  earningsStatement: {
    id: 'earningsStatement',
    defaultMessage: 'Earnings Statement',
  },
  earningPeriod: {
    id: 'earningPeriod',
    defaultMessage: 'Earning Period',
  },
  createListing: {
    id: 'createListing',
    defaultMessage: 'Create new listing',
  },
  solutionName: {
    id: 'solutionName',
    defaultMessage: 'Solution Name',
  },
  solutionId: {
    id: 'solutionId',
    defaultMessage: 'Solution ID',
  },
  moduleName: {
    id: 'moduleName',
    defaultMessage: 'Module Name',
  },
  category: {
    id: 'category',
    defaultMessage: 'Category',
  },
  assign: {
    id: 'assign',
    defaultMessage: 'Assign',
  },
  assigned: {
    id: 'assigned',
    defaultMessage: 'Assigned',
  },
  permissions: {
    id: 'permissions',
    defaultMessage: 'Permissions',
  },
  noAccess: {
    id: 'noAccess',
    defaultMessage: 'No Access',
  },
  limitedAccess: {
    id: 'limitedAccess',
    defaultMessage: 'Limited Access',
  },
  fullAccess: {
    id: 'fullAccess',
    defaultMessage: 'Full Access',
  },
  discardChanges: {
    id: 'discardChanges',
    defaultMessage: 'Discard Changes',
  },
  cancelMessage: {
    id: 'cancelMessage',
    defaultMessage: 'Confirm to proceed without saving the changes.',
  },
  license: {
    id: 'license',
    defaultMessage: 'License',
  },
  provider: {
    id: 'provider',
    defaultMessage: 'Provider',
  },
  repository: {
    id: 'repository',
    defaultMessage: 'Repository',
  },
  visibility: {
    id: 'visibility',
    defaultMessage: 'Visibility',
  },
  encryptionKey: {
    id: 'encryptionKey',
    defaultMessage: 'Encryption key',
  },
  createApplication: {
    id: 'createApplication',
    defaultMessage: 'Create application',
  },
  editApplication: {
    id: 'editApplication',
    defaultMessage: 'Application Information',
  },
  cloneAnApplication: {
    id: 'cloneAnApplication',
    defaultMessage: 'Clone an Application',
  },
  cloneApplication: {
    id: 'cloneApplication',
    defaultMessage: 'Clone Application',
  },
  passphrase: {
    id: 'passphrase',
    defaultMessage: 'Passphrase',
  },
  password: {
    id: 'password',
    defaultMessage: 'Password',
  },
  username: {
    id: 'username',
    defaultMessage: 'Username',
  },
  sshKey: {
    id: 'sshKey',
    defaultMessage: 'SSH Key',
  },
  gitRepoUrl: {
    id: 'gitRepoUrl',
    defaultMessage: 'Git repository URL',
  },
  yourChanges: {
    id: 'yourChanges',
    defaultMessage: 'Your Changes',
  },
  commitChanges: {
    id: 'commitChanges',
    defaultMessage: 'Commit Changes',
  },
  saveChanges: {
    id: 'saveChanges',
    defaultMessage: 'Save Changes',
  },
  comment: {
    id: 'comment',
    defaultMessage: 'Comment',
  },
  commitedBy: {
    id: 'commitedBy',
    defaultMessage: 'Commited by',
  },
  versionTag: {
    id: 'versionTag',
    defaultMessage: 'Version Tag',
  },
  branch: {
    id: 'branch',
    defaultMessage: 'Branch',
  },
  refresh: {
    id: 'refresh',
    defaultMessage: 'Refresh',
  },
  commit: {
    id: 'commit',
    defaultMessage: 'Commit',
  },
  action: {
    id: 'action',
    defaultMessage: 'Action',
  },
  assignedApplication: {
    id: 'assignedApplication',
    defaultMessage: 'Assigned Application',
  },
  updated: {
    id: 'updated',
    defaultMessage: 'Updated',
  },
  size: {
    id: 'size',
    defaultMessage: 'Size',
  },
  encryption: {
    id: 'encryption',
    defaultMessage: 'Encryption',
  },
  encryptionLabel: {
    id: 'encryption',
    defaultMessage: 'Encryption',
  },
  addRemote: {
    id: 'addRemote',
    defaultMessage: 'Add remote',
  },
  addVersion: {
    id: 'addVersion',
    defaultMessage: 'Add Version',
  },
  remoteName: {
    id: 'remoteName',
    defaultMessage: 'Remote name',
  },
  url: {
    id: 'url',
    defaultMessage: 'URL',
  },
  addGitRemote: {
    id: 'addGitRemote',
    defaultMessage: 'Add Git remote',
  },
  currentKey: {
    id: 'currentKey',
    defaultMessage: 'Current key',
  },
  newKey: {
    id: 'newKey',
    defaultMessage: 'New key',
  },
  readMe: {
    id: 'readMe',
    defaultMessage: 'Readme',
  },
  name: {
    id: 'name',
    defaultMessage: 'Name',
  },
  commitDescription: {
    id: 'commitDescription',
    defaultMessage: 'Commit Description',
  },
  applicationDescription: {
    id: 'applicationDescription',
    defaultMessage: 'Application Description',
  },
  addAModule: {
    id: 'addAModule',
    defaultMessage: 'Add a module',
  },
  importModule: {
    id: 'importModule',
    defaultMessage: 'Import Module',
  },
  addModuleDescription: {
    id: 'addModuleDescription',
    defaultMessage: 'To begin, you module needs a name and a short description',
  },
  moduleDescription: {
    id: 'moduleDescription',
    defaultMessage: 'Module Description',
  },
  moduleId: {
    id: 'moduleId',
    defaultMessage: 'Module ID',
  },
  cloneModule: {
    id: 'cloneModule',
    defaultMessage: 'Clone Module',
  },
  importAModule: {
    id: 'importAModule',
    defaultMessage: 'Import a module',
  },
  applicationName: {
    id: 'applicationName',
    defaultMessage: 'Application Name',
  },
  usernameOptional: {
    id: 'usernameOptional',
    defaultMessage: 'Username (optional)',
  },
  passwordOptional: {
    id: 'passwordOptional',
    defaultMessage: 'Password (optional)',
  },
  dockerImage: {
    id: 'dockerImage',
    defaultMessage: 'Docker Image',
  },
  noApplicationAssigned: {
    id: 'noApplicationAssigned',
    defaultMessage: 'No application assigned',
  },
  moduleNodes: {
    id: 'moduleNodes',
    defaultMessage: 'Module Nodes',
  },
  myViso: {
    id: 'myViso',
    defaultMessage: 'My Viso',
  },
  manage: {
    id: 'manage',
    defaultMessage: 'Manage',
  },
  partner: {
    id: 'partner',
    defaultMessage: 'Partner',
  },
  updatedAt: {
    id: 'updatedAt',
    defaultMessage: 'Updated At',
  },
  updatedBy: {
    id: 'updatedBy',
    defaultMessage: 'Updated By',
  },
  total: {
    id: 'total',
    defaultMessage: 'Total',
  },
  deployed: {
    id: 'deployed',
    defaultMessage: 'Deployed',
  },
  error: {
    id: 'error',
    defaultMessage: 'Error',
  },
  companyName: {
    id: 'companyName',
    defaultMessage: 'Company Name',
  },
  profileDeployedAt: {
    id: 'profileDeployedAt',
    defaultMessage: 'Profile Deployed At',
  },
  lastSeen: {
    id: 'lastSeen',
    defaultMessage: 'Last seen',
  },
  configuredAt: {
    id: 'configuredAt',
    defaultMessage: 'Configured at',
  },
  menu: {
    id: 'menu',
    defaultMessage: 'Menu',
  },
  visoMarketplace: {
    id: 'visoMarketplace',
    defaultMessage: 'Viso Marketplace',
  },
  importApplicationDesc: {
    id: 'importApplicationDesc',
    defaultMessage: 'Import complete AI vision applications. Ready to use.',
  },
  types: {
    id: 'types',
    defaultMessage: 'Types',
  },
  categories: {
    id: 'categories',
    defaultMessage: 'Categories',
  },
  filters: {
    id: 'filter',
    defaultMessage: 'Filters',
  },
  searchApplicationDesc: {
    id: 'searchApplicationDesc',
    defaultMessage: 'Search for application or business need',
  },
  categoryDesc: {
    id: 'categoryDesc',
    defaultMessage:
      'Some short category description that provides information about the category.',
  },
  mostInstalled: {
    id: 'mostInstalled',
    defaultMessage: 'Most installed',
  },
  newest: {
    id: 'newest',
    defaultMessage: 'Newest',
  },
  recommendedForYou: {
    id: 'recommendedForYou',
    defaultMessage: 'Recommended for you',
  },
  featuredApplications: {
    id: 'featuredApplications',
    defaultMessage: 'Featured applications',
  },
  popularModules: {
    id: 'popularModules',
    defaultMessage: 'Popular Modules',
  },
  seeAll: {
    id: 'seeAll',
    defaultMessage: 'See all',
  },
  recentlyAdded: {
    id: 'recentlyAdded',
    defaultMessage: 'Recently added',
  },
  sortBy: {
    id: 'sortBy',
    defaultMessage: 'Sort by',
  },
  filteredBy: {
    id: 'filteredBy',
    defaultMessage: 'filtered by',
  },
  resultFor: {
    id: 'resultForm',
    defaultMessage: 'result for',
  },
  seeUserLists: {
    id: 'seeUserLists',
    defaultMessage: 'See user lists',
  },
  seeUserPages: {
    id: 'seeUserPages',
    defaultMessage: 'See user pages',
  },
  updateUsers: {
    id: 'updateUsers',
    defaultMessage: 'Update users',
  },
  userActions: {
    id: 'userActions',
    defaultMessage: 'User actions',
  },
  addNewUsers: {
    id: 'addNewUsers',
    defaultMessage: 'Add new users',
  },
  archiveUsers: {
    id: 'archiveUsers',
    defaultMessage: 'Archive user(s)',
  },
  archiveUser: {
    id: 'archiveUser',
    defaultMessage: 'Archive user',
  },
  deleteUsers: {
    id: 'deleteUsers',
    defaultMessage: 'Delete User'
  },
  seeDashboard: {
    id: 'seeDashboard',
    defaultMessage: 'See dashboard',
  },
  updateDashboard: {
    id: 'updateDashboard',
    defaultMessage: 'Update dashboard',
  },
  dashboardActions: {
    id: 'dashboardActions',
    defaultMessage: 'Dashboard actions',
  },
  addNewDashboard: {
    id: 'addNewDashboard',
    defaultMessage: 'Add new dashboard',
  },
  removeDashboard: {
    id: 'removeDashboard',
    defaultMessage: 'Remove dashboard',
  },
  seeDeviceLists: {
    id: 'seeDeviceLists',
    defaultMessage: 'See device lists',
  },
  seeDevices: {
    id: 'seeDevices',
    defaultMessage: 'See Devices',
  },
  seeDevicePages: {
    id: 'seeDevicePages',
    defaultMessage: 'See device pages',
  },
  updateDevices: {
    id: 'updateDevices',
    defaultMessage: 'Update devices',
  },
  deviceActions: {
    id: 'deviceActions',
    defaultMessage: 'Device actions',
  },
  addNewDevices: {
    id: 'addNewDevices',
    defaultMessage: 'Add new devices',
  },
  archiveDevices: {
    id: 'archiveDevices',
    defaultMessage: 'Archive devices',
  },
  deleteDevices: {
    id: 'deleteDevices',
    defaultMessage: 'Delete devices',
  },
  seeFlowLists: {
    id: 'seeFlowLists',
    defaultMessage: 'See flow lists',
  },
  seeFlowPages: {
    id: 'seeFLowPages',
    defaultMessage: 'See flow pages',
  },
  updateFlows: {
    id: 'updateFlow',
    defaultMessage: 'Update flows',
  },
  addNewFlows: {
    id: 'addNewFlows',
    defaultMessage: 'Add new flows',
  },
  archiveFlows: {
    id: 'archiveFlows',
    defaultMessage: 'Archive flows',
  },
  deleteFlows: {
    id: 'deleteFlows',
    defaultMessage: 'Delete flows',
  },
  flowActions: {
    id: 'flowActions',
    defaultMessage: 'Flow actions',
  },
  seeGroupLists: {
    id: 'seeGroupLists',
    defaultMessage: 'See group lists',
  },
  seeGroupPages: {
    id: 'seeGroupPages',
    defaultMessage: 'See group pages',
  },
  updateGroups: {
    id: 'updateGroups',
    defaultMessage: 'Update groups',
  },
  groupActions: {
    id: 'groupActions',
    defaultMessage: 'Group actions',
  },
  addNewGroups: {
    id: 'addNewGroups',
    defaultMessage: 'Add new groups',
  },
  archiveGroups: {
    id: 'archiveGroups',
    defaultMessage: 'Archive groups',
  },
  deleteGroups: {
    id: 'deleteGroups',
    defaultMessage: 'Delete groups',
  },
  seeModuleLists: {
    id: 'seeModuleLists',
    defaultMessage: 'See module lists',
  },
  seeModulePages: {
    id: 'seeModulePages',
    defaultMessage: 'See module pages',
  },
  updateModules: {
    id: 'updateModules',
    defaultMessage: 'Update modules',
  },
  moduleActions: {
    id: 'moduleActions',
    defaultMessage: 'Module actions',
  },
  deleteModules: {
    id: 'deleteModules',
    defaultMessage: 'Delete Modules',
  },
  addNewModules: {
    id: 'addNewModules',
    defaultMessage: 'Add new modules',
  },
  archiveModules: {
    id: 'archiveModules',
    defaultMessage: 'Archive modules',
  },
  seeProfileLists: {
    id: 'seeProfileLists',
    defaultMessage: 'See profile lists',
  },
  seeProfilePages: {
    id: 'seeProfilePages',
    defaultMessage: 'See profile pages',
  },
  updateProfiles: {
    id: 'updateProfiles',
    defaultMessage: 'Update profiles',
  },
  profileActions: {
    id: 'profileActions',
    defaultMessage: 'Profile actions',
  },
  addNewProfiles: {
    id: 'addNewProfiles',
    defaultMessage: 'Add new profiles',
  },
  archiveProfiles: {
    id: 'archiveProfiles',
    defaultMessage: 'Archive profile(s)',
  },
  deleteProfiles: {
    id: 'deleteProfiles',
    defaultMessage: 'Remove profile(s)',
  },
  seeRoleList: {
    id: 'seeRoleList',
    defaultMessage: 'See role list',
  },
  seeRolesPages: {
    id: 'seeRolesPages',
    defaultMessage: 'See role pages',
  },
  updateRoles: {
    id: 'updateRoles',
    defaultMessage: 'Update roles',
  },
  roleActions: {
    id: 'roleActions',
    defaultMessage: 'Role actions',
  },
  addNewRoles: {
    id: 'addNewRoles',
    defaultMessage: 'Add new roles',
  },
  archiveRole: {
    id: 'archiveRole',
    defaultMessage: 'Archive roles',
  },
  deleteRoles: {
    id: 'deleteRoles',
    defaultMessage: 'Delete roles',
  },
  seeScopeList: {
    id: 'seeScopeList',
    defaultMessage: 'See scope lists',
  },
  seeScopePages: {
    id: 'seeScopePages',
    defaultMessage: 'See scope pages',
  },
  updateScopes: {
    id: 'updateScopes',
    defaultMessage: 'Update scopes',
  },
  scopeActions: {
    id: 'scopeActions',
    defaultMessage: 'Scope actions',
  },
  addNewScopes: {
    id: 'addNewScopes',
    defaultMessage: 'Add new scopes',
  },
  archiveScopes: {
    id: 'archiveScopes',
    defaultMessage: 'Archive scopes',
  },
  deleteScopes: {
    id: 'deleteScopes',
    defaultMessage: 'Delete scopes',
  },
  specialRolePermission: {
    id: 'specialRolePermission',
    defaultMessage: 'Special Role Permissions',
  },
  loadPreset: {
    id: 'loadPreset',
    defaultMessage: 'Load preset',
  },
  view: {
    id: 'view',
    defaultMessage: 'View',
  },
  addAFilter: {
    id: 'addAFilter',
    defaultMessage: 'Add a filter',
  },
  noDataAvailable: {
    id: 'noDataAvailable',
    defaultMessage: 'No data available',
  },
  resources: {
    id: 'resources',
    defaultMessage: 'Resources',
  },
  usersAssigned: {
    id: 'usersAssigned',
    defaultMessage: 'users assigned',
  },
  apply: {
    id: 'apply',
    defaultMessage: 'Apply',
  },
  addNewColumns: {
    id: 'addNewColumns',
    defaultMessage: 'Add new Columns',
  },
  manageColumns: {
    id: 'manageColumns',
    defaultMessage: 'Manage Columns',
  },
  noResultsFound: {
    id: 'noResultsFound',
    defaultMessage: 'No results found',
  },
  new: {
    id: 'new',
    defaultMessage: 'NEW',
  },
  tryAdjustingSearch: {
    id: 'tryAdjustingSearch',
    defaultMessage:
      'Try adjusting your search or filter to find what you are looking for.',
  },
  removeInstalledApplications: {
    id: 'removeInstalledApplications',
    defaultMessage: 'You are about to remove installed applications from',
  },
  dragFileHereOr: {
    id: 'dragFileHereOr',
    defaultMessage: 'Drag file here or',
  },
  browse: {
    id: 'browse',
    defaultMessage: 'browse',
  },
  reset: {
    id: 'reset',
    defaultMessage: 'Reset',
  },
  smallSelected: {
    id: 'smallSelected',
    defaultMessage: 'selected',
  },
  skip: {
    id: 'skip',
    defaultMessage: 'Skip',
  },
  loading: {
    id: 'loading',
    defaultMessage: 'Loading',
  },
  createVisoAccount: {
    id: 'createVisoAccount',
    defaultMessage: 'Create your personal Viso Account',
  },
  setUpAccount: {
    id: 'setUpAccount',
    defaultMessage: 'Set up your personal account with ',
  },
  passwordValidation: {
    id: 'passwordValidation',
    defaultMessage:
      'Password must be 8 characters, at least one letter, symbol and a number',
  },
  agreeAccount: {
    id: 'agreeAccount',
    defaultMessage: 'By creating an account, you agree to our',
  },
  termsOfService: {
    id: 'termsOfService',
    defaultMessage: 'Terms of Service',
  },
  earlyAccessTerms: {
    id: 'earlyAccessTerms',
    defaultMessage: 'Early Access Terms of Use',
  },
  and: {
    id: 'and',
    defaultMessage: 'and',
  },
  privacyPolicy: {
    id: 'privacyPolicy',
    defaultMessage: 'Privacy Policy',
  },
  sPrivacyPolicy: {
    id: 'sPrivacyPolicy',
    defaultMessage: 'privacy policy',
  },
  occasionallyEmails: {
    id: 'occasionallyEmails',
    defaultMessage: 'We’ll send you account-related emails',
  },
  createAccount: {
    id: 'createAccount',
    defaultMessage: 'Create Account',
  },
  creating: {
    id: 'creating',
    defaultMessage: 'Creating',
  },
  inviteLinkNotActive: {
    id: 'inviteLinkNotActive',
    defaultMessage: 'The invite link is no longer active',
  },
  invitationLinkExpire: {
    id: 'invitationLinkExpire',
    defaultMessage: 'The invitation link has expired.',
  },
  pleaseCheckLinkAvailable: {
    id: 'pleaseCheckLinkAvailable',
    defaultMessage:
      'Please check with the person who shared it with you to see if there’s a new link available.',
  },
  keepSeeingNotification: {
    id: 'keepSeeingNotification',
    defaultMessage: 'Keep seeing this notification?',
  },
  contactUs: {
    id: 'contactUs',
    defaultMessage: 'Contact us.',
  },
  notActivatedYourAccount: {
    id: 'notActivatedYourAccount',
    defaultMessage: 'Looks like you have not activated your account.',
  },
  didntReceiveEmail: {
    id: 'didntReceiveEmail',
    defaultMessage: 'Didnt receive the email? Click here to',
  },
  resendEmail: {
    id: 'resendEmail',
    defaultMessage: 'Resend Email',
  },
  didntReceiveEmailFromUs: {
    id: 'didntReceiveEmailFromUs',
    defaultMessage: 'Didn’t receive an email from us?',
  },
  emailConfirmationToEnsureData: {
    id: 'emailConfirmationToEnsureData',
    defaultMessage:
      'Email confirmation helps us to ensure your data will always be safe.',
  },
  sendPasswordReset: {
    id: 'sendPasswordReset',
    defaultMessage: 'Send password reset',
  },
  setNewPassword: {
    id: 'setNewPassword',
    defaultMessage: 'Set a new Password',
  },
  passwordTiedToAccount: {
    id: 'passwordTiedToAccount',
    defaultMessage: 'The password is tied to the account',
  },
  wantToUseAnotherAccount: {
    id: 'wantToUseAnotherAccount',
    defaultMessage: 'Want to use another account?',
  },
  signIn: {
    id: 'signIn',
    defaultMessage: 'Sign in',
  },
  setAccount: {
    id: 'setAccount',
    defaultMessage: 'Set Account',
  },
  forgotPassword: {
    id: 'forgotPassword',
    defaultMessage: 'Forgot password?',
  },
  dontHaveAccount: {
    id: 'dontHaveAccount',
    defaultMessage: 'Don\'t have an account?',
  },
  signUp: {
    id: 'signUp',
    defaultMessage: 'Sign up',
  },
  logIn: {
    id: 'logIn',
    defaultMessage: 'Log in',
  },
  loginWithViso: {
    id: 'loginWithViso',
    defaultMessage: 'Access your Viso Account',
  },
  billingInformation: {
    id: 'billingInformation',
    defaultMessage: 'Billing Information',
  },
  bussinessName: {
    id: 'bussinessName',
    defaultMessage: 'Business name',
  },
  country: {
    id: 'country',
    defaultMessage: 'Country',
  },
  address1: {
    id: 'addres1',
    defaultMessage: 'Address 1',
  },
  address2: {
    id: 'address2',
    defaultMessage: 'Address 2',
  },
  city: {
    id: 'city',
    defaultMessage: 'City',
  },
  postCode: {
    id: 'postCode',
    defaultMessage: 'Postcode',
  },
  openGmail: {
    id: 'openGmail',
    defaultMessage: 'Open Gmail',
  },
  openOutlook: {
    id: 'openOutlook',
    defaultMessage: 'Open Outlook',
  },
  openYahoo: {
    id: 'openYahoo',
    defaultMessage: 'Open Yahoo!',
  },
  checkYourInbox: {
    id: 'checkYourInbox',
    defaultMessage: 'Great, please check your inbox',
  },
  sentEmailTo: {
    id: 'sentEmailTo',
    defaultMessage: 'We sent password reset to',
  },
  pleaseVerifyEmail: {
    id: 'pleaseVerifyEmail',
    defaultMessage: 'Please verify your email',
  },
  pleaseCheckEmail: {
    id: 'pleaseCheckEmail',
    defaultMessage: 'Please check your email',
  },
  alreadyHaveAnAccount: {
    id: 'alreadyHaveAnAccount',
    defaultMessage: 'Already have an account?',
  },
  addUser: {
    id: 'addUser',
    defaultMessage: 'Add a user',
  },
  sUsers: {
    id: 'sUsers',
    defaultMessage: 'users',
  },
  users: {
    id: 'users',
    defaultMessage: 'Users',
  },
  signUpViso: {
    id: 'signUpViso',
    defaultMessage: 'Create a Viso account',
  },
  createYourPersonalAccount: {
    id: 'createYourPersonalAccount',
    defaultMessage: 'Register with your email',
  },
  workspaceInformation: {
    id: 'workspaceInformation',
    defaultMessage: 'Workspace Information',
  },
  welcome: {
    id: 'welcome',
    defaultMessage: 'Welcome',
  },
  workspaceBelongsTo: {
    id: 'workspaceBelongsTo',
    defaultMessage: 'This workspace belongs to',
  },
  upcomingInvoice: {
    id: 'upcomingInvoice',
    defaultMessage: 'Upcoming Invoice',
  },
  endpoints: {
    id: 'endpoints',
    defaultMessage: 'Endpoints',
  },
  unitPrice: {
    id: 'unitPrice',
    defaultMessage: 'Unit Price',
  },
  currentConfiguration: {
    id: 'currentConfiguration',
    defaultMessage:
      'Based on your current configuration, you are being automatically charged',
  },
  changeProductSubscriptions: {
    id: 'changeProductSubscriptions',
    defaultMessage: 'To make changes to your product subscriptions, go to',
  },
  manageSubscriptions: {
    id: 'manageSubscriptions',
    defaultMessage: 'Manage Subscriptions',
  },
  amountDue: {
    id: 'amountDue',
    defaultMessage: 'Amount due',
  },
  changePlan: {
    id: 'changePlan',
    defaultMessage: 'Change plan',
  },
  changeSubscription: {
    id: 'changeSubscription',
    defaultMessage: 'Change Subscription',
  },
  change: {
    id: 'change',
    defaultMessage: 'Change',
  },
  by: {
    id: 'by',
    defaultMessage: 'by',
  },
  seats: {
    id: 'seats',
    defaultMessage: 'Seats',
  },
  cancelSubscription: {
    id: 'cancelSubscription',
    defaultMessage: 'Cancel subscription',
  },
  priceEstimate: {
    id: 'priceEstimate',
    defaultMessage: 'Price estimate',
  },
  nextBillingDate: {
    id: 'nextBillingDate',
    defaultMessage: 'Next billing date',
  },
  billTo: {
    id: 'billTo',
    defaultMessage: 'Bill to',
  },
  summary: {
    id: 'summary',
    defaultMessage: 'Summary',
  },
  address: {
    id: 'address',
    defaultMessage: 'Address',
  },
  phoneNumber: {
    id: 'phoneNumber',
    defaultMessage: 'Phone Number',
  },
  currency: {
    id: 'currency',
    defaultMessage: 'Currency',
  },
  invoiceNumber: {
    id: 'invoiceNumber',
    defaultMessage: 'Invoice number',
  },
  billingMethod: {
    id: 'billingMethod',
    defaultMessage: 'Billing method',
  },
  subTotal: {
    id: 'subTotal',
    defaultMessage: 'Subtotal',
  },
  getStartedWith: {
    id: 'getStartedWith',
    defaultMessage: 'Get started with',
  },
  confirmYourOrder: {
    id: 'confirmYourOrder',
    defaultMessage: 'Confirm your Order',
  },
  getStartedImmediately: {
    id: 'getStartedImmediately',
    defaultMessage:
      'Get started immediately and access all services offered by the selected plan. After the trial period ends, you will be charged automatically based on the payment details and interval you provide us with. You can change your settings anytime in the Billing Center of your Workspace.',
  },
  payment: {
    id: 'payment',
    defaultMessage: 'Payment',
  },
  expires: {
    id: 'expires',
    defaultMessage: 'Expires',
  },
  monthly: {
    id: 'monthly',
    defaultMessage: 'Monthly',
  },
  yearly: {
    id: 'yearly',
    defaultMessage: 'Yearly',
  },
  byClickingConfirm: {
    id: 'byClickingConfirm',
    defaultMessage: 'By clicking "Confirm" you agree to our',
  },
  billingInformationDesc: {
    id: 'billingInformationDesc',
    defaultMessage: 'The business that this workspace belongs to.',
  },
  contactInInvoice: {
    id: 'contactInInvoice',
    defaultMessage: 'The contact who will be reflected in the invoice',
  },
  recentCommitsToApplication: {
    id: 'recentCommitsToApplication',
    defaultMessage: 'Recent Commits that have been made to the Application.',
  },
  billing: {
    id: 'billing',
    defaultMessage: 'Billing',
  },
  history: {
    id: 'history',
    defaultMessage: 'History',
  },
  billHistory: {
    id: 'billHistory',
    defaultMessage: 'Bill History',
  },
  paymentSettings: {
    id: 'paymentSettings',
    defaultMessage: 'Payment Settings',
  },
  paymentInformation: {
    id: 'paymentInformation',
    defaultMessage: 'Payment Information',
  },
  interval: {
    id: 'interval',
    defaultMessage: 'Interval',
  },
  nameOnCard: {
    id: 'nameOnCard',
    defaultMessage: 'Name on Card',
  },
  invoicesList: {
    id: 'invoicesList',
    defaultMessage: 'Invoices List',
  },
  billingStatementDisplay: {
    id: 'billingStatementDisplay',
    defaultMessage:
      'Your billing statement will display viso.ai AG, located in Schaffhausen, Switzerland. Your credit card issuer may charge foreign transaction or cross-border fees in addition to the total price.',
  },
  billingContact: {
    id: 'billingContact',
    defaultMessage: 'Billing Contact',
  },
  viewFull: {
    id: 'viewFull',
    defaultMessage: 'View Full',
  },
  tax: {
    id: 'tax',
    defaultMessage: 'Tax',
  },
  profileInformation: {
    id: 'profileInformation',
    defaultMessage: 'Profile Information',
  },
  profileName: {
    id: 'profileName',
    defaultMessage: 'Profile name',
  },
  profileDescription: {
    id: 'profileDescription',
    defaultMessage: 'Profile Description',
  },
  profileId: {
    id: 'profileId',
    defaultMessage: 'Profile ID',
  },
  assignedApplicationDescription: {
    id: 'assignedApplicationDescription',
    defaultMessage:
      'The profile contains a specific version of an Application from your Library.',
  },
  licenceName: {
    id: 'licenceName',
    defaultMessage: 'Licence name',
  },
  availableSeat: {
    id: 'availableSeat',
    defaultMessage: 'Available seat',
  },
  totalSeats: {
    id: 'totalSeats',
    defaultMessage: 'Total seats',
  },
  activationEnd: {
    id: 'activationEnd',
    defaultMessage: 'Activation end',
  },
  confirmationMessage: {
    id: 'confirmationMessage',
    defaultMessage: 'Are you sure you want update profile?',
  },
  updateProfile: {
    id: 'updateProfile',
    defaultMessage: 'Update Profile',
  },
  addProfile: {
    id: 'addProfile',
    defaultMessage: 'Add information about the new profile.',
  },
  assignAFlow: {
    id: 'assignAFlow',
    defaultMessage: 'Assign an Application',
  },
  selectFlow: {
    id: 'selectFlow',
    defaultMessage: 'Add an application to the profile.',
  },
  releaseDate: {
    id: 'releaseDate',
    defaultMessage: 'Release Date',
  },
  stages: {
    id: 'stages',
    defaultMessage: 'Stages',
  },
  deployedBy: {
    id: 'deployedBy',
    defaultMessage: 'Deployed by',
  },
  runtime: {
    id: 'runtime',
    defaultMessage: 'Runtime',
  },
  releasePipeline: {
    id: 'releasePipeline',
    defaultMessage: 'Release Pipeline',
  },
  releasePipelineDescription: {
    id: 'releasePipelineDescription',
    defaultMessage: 'See and monitor all your deployment releases.',
  },
  noFlowAssigned: {
    id: 'noFlowAssigned',
    defaultMessage: 'No Flow assigned',
  },
  createDeviceImage: {
    id: 'createDeviceImage',
    defaultMessage: 'Create a Device Image',
  },
  createDeviceImageDesc: {
    id: 'createDeviceImageDesc',
    defaultMessage:
      'Select the device type that you want to add to your device images.',
  },
  createImage: {
    id: 'createImage',
    defaultMessage: 'Create Image',
  },
  enrollDevices: {
    id: 'enrollDevices',
    defaultMessage: 'Enroll devices',
  },
  enrollDeviceDescription: {
    id: 'enrollDeviceDescription',
    defaultMessage:
      'Note: Download the image below to flash it with a device of the device type. When online, the devices will be automatically enrolled to your workspace and are ready to be set up.',
  },
  noProfiles: {
    id: 'noProfiles',
    defaultMessage: 'Currently no profile available.',
  },
  noProfilesAssigned: {
    id: 'noProfilesAssigned',
    defaultMessage: 'No Profile Assigned',
  },
  deviceName: {
    id: 'deviceName',
    defaultMessage: 'Device Name',
  },
  selectLicense: {
    id: 'selectLicense',
    defaultMessage: 'Select license',
  },
  selectLicenseDescription: {
    id: 'selectLicenseDescription',
    defaultMessage: 'Select license to use to assign profile',
  },
  pushProfile: {
    id: 'pushProfile',
    defaultMessage: 'Are you sure to assign the profile ',
  },
  pushNow: {
    id: 'pushNow',
    defaultMessage: 'Push now',
  },
  selectProfile: {
    id: 'selectProfile',
    defaultMessage: 'Select profile',
  },
  selectDevices: {
    id: 'selectDevices',
    defaultMessage: 'Select devices',
  },
  unassignedDeviceList: {
    id: 'unassignedDeviceList',
    defaultMessage:
      ' Unassigned devices are listed below by default. <br /> Modify the Status column filter to find more devices.',
  },
  selectDevicesToAssignTo: {
    id: 'selectDevicesToAssignTo',
    defaultMessage: 'Select devices to assign to',
  },
  updateDevicesDesc: {
    id: 'updateDevicesDesc',
    defaultMessage:
      'Roll-out the selected profile to either all or specific devices.',
  },
  pushAll: {
    id: 'pushAll',
    defaultMessage: 'Push to all associated devices',
  },
  pushSelected: {
    id: 'pushSelected',
    defaultMessage: 'Push to specific associated devices',
  },
  pushUpdateDeviceDialog: {
    id: 'pushUpdateDeviceDialog',
    defaultMessage: 'Are you sure to push the profile ',
  },
  pushUpdateDeviceDialog2: {
    id: 'pushUpdateDeviceDialog2',
    defaultMessage: 'to all associated devices? ',
  },
  pushUpdateDeviceTitle: {
    id: 'pushUpdateDeviceTitle',
    defaultMessage: 'Push profile update to devices',
  },
  deployNewApplication: {
    id: 'deployNewApplication',
    defaultMessage: 'You are about to deploy a new application to',
  },
  sDevice: {
    id: 'sDevice',
    defaultMessage: 'Device(s)',
  },
  continueProcess: {
    id: 'continueProcess',
    defaultMessage: 'Do you want to continue?',
  },
  confirmAndProceed: {
    id: 'confirmAndProceed',
    defaultMessage: 'I confirm and want to proceed',
  },
  applicationOverride: {
    id: 'applicationOverride',
    defaultMessage:
      'The installed application will be removed. Do you want to continue?',
  },
  aboutToUpdate: {
    id: 'aboutToUpdate',
    defaultMessage: 'You are about to update',
  },
  toAnotherVersionApplication: {
    id: 'toAnotherVersionApplication',
    defaultMessage: 'to another version of the currently installed application.',
  },
  keepCompatibleSettings: {
    id: 'keepCompatibleSettings',
    defaultMessage: 'Keep compatible configuration settings',
  },
  resetConfigurationSettings: {
    id: 'resetConfigurationSettings',
    defaultMessage: 'Reset configuration settings to default',
  },
  deployApplication: {
    id: 'deployApplication',
    defaultMessage: 'Deploy new Application',
  },
  replaceApplication: {
    id: 'replaceApplication',
    defaultMessage: 'Replace existing Application',
  },
  updateApplication: {
    id: 'updateApplication',
    defaultMessage: 'Update Application',
  },
  deleteEndpoint: {
    id: 'deleteEndpoint',
    defaultMessage:
      'Deleting the endpoint will lead to data loss. Please confirm to proceed.',
  },
  removeDevice: {
    id: 'removeDevice',
    defaultMessage: 'Remove Device',
  },
  sRemoveDevice: {
    id: 'sRemoveDevice',
    defaultMessage: 'Remove Device(s)',
  },
  addDevices: {
    id: 'addDevices',
    defaultMessage: 'Add Devices to your Workspace',
  },
  downloadDeviceImage: {
    id: 'downloadDeviceImage',
    defaultMessage: 'Download the Device Image and flash it to multiple devices to add them automatically. Don’t have a computer available yet?',
  },
  assignProfile: {
    id: 'assignProfile',
    defaultMessage: 'Assign profile',
  },
  unassignProfile: {
    id: 'unassignProfile',
    defaultMessage: 'Unassign profile',
  },
  deviceImageList: {
    id: 'deviceImageList',
    defaultMessage: 'Device Image List',
  },
  sDeleteDevices: {
    id: 'sDeleteDevices',
    defaultMessage: 'Delete Device(s)',
  },
  rebootDevice: {
    id: 'rebootDevice',
    defaultMessage: 'Reboot device',
  },
  selectProfileDesc: {
    id: 'selectProfileDesc',
    defaultMessage: 'Select profile to be assigned to devices.',
  },
  activateThis: {
    id: 'activateThis',
    defaultMessage: 'Are you sure to activate the',
  },
  confirmArchiveProfiles: {
    id: 'confirmArchiveProfiles',
    defaultMessage: 'Confirm Archive Profiles',
  },
  archiveThis: {
    id: 'archiveThis',
    defaultMessage: 'Are you sure to archive the',
  },
  archive: {
    id: 'archive',
    defaultMessage: 'Archive',
  },
  confirmActivateProfiles: {
    id: 'confirmActivateProfiles',
    defaultMessage: 'Confirm Activate Profiles',
  },
  activate: {
    id: 'activate',
    defaultMessage: 'Activate',
  },
  confirmRemoveProfiles: {
    id: 'confirmRemoveProfiles',
    defaultMessage: 'Confirm Remove Profiles',
  },
  removeThis: {
    id: 'removeThis',
    defaultMessage: 'Are you sure to remove the',
  },
  assignProfileToDevices: {
    id: 'assignProfileToDevices',
    defaultMessage: 'Assign profile to device(s)',
  },
  removeProfile: {
    id: 'removeProfile',
    defaultMessage: 'Remove profile',
  },
  createProfile: {
    id: 'createProfile',
    defaultMessage: 'New profile',
  },
  partnerPortal: {
    id: 'partnerPortal',
    defaultMessage: 'Partner Portal',
  },
  findAndShareSolution: {
    id: 'findAndShareSolution',
    defaultMessage: 'Find and share solutions to build great things.',
  },
  createNewSolution: {
    id: 'createNewSolution',
    defaultMessage: 'Create new solution',
  },
  unpublishSolution: {
    id: 'unpublishSolution',
    defaultMessage: 'Unpublish solution',
  },
  publishSolution: {
    id: 'publishSolution',
    defaultMessage: 'Publish solution',
  },
  cancelSubmission: {
    id: 'cancelSubmission',
    defaultMessage: 'Cancel submission',
  },
  deleteSolution: {
    id: 'deleteSolution',
    defaultMessage: 'Delete solution',
  },
  archiveSolution: {
    id: 'archiveSolution',
    defaultMessage: 'Archive solution',
  },
  showArchivedSolutions: {
    id: 'showArchivedSolutions',
    defaultMessage: 'Show archived solutions',
  },
  createNewListing: {
    id: 'createNewListing',
    defaultMessage: 'Create new listing',
  },
  solutions: {
    id: 'solutions',
    defaultMessage: 'Solutions',
  },
  addNewModuleVersion: {
    id: 'addNewModuleVersion',
    defaultMessage: 'Add a new Module Version',
  },
  importCustomModule: {
    id: 'importCustomModule',
    defaultMessage: 'Import a Custom Module',
  },
  versionUpload: {
    id: 'versionUpload',
    defaultMessage: 'Version Upload',
  },
  zipFile: {
    id: 'zipFile',
    defaultMessage: 'Zip File',
  },
  uploadAModule: {
    id: 'uploadAModule',
    defaultMessage:
      'Upload a Zip-File with the new module version. Make sure the version in package.json is updated correctly',
  },
  createNewVersion: {
    id: 'createNewVersion',
    defaultMessage: 'Create new version',
  },
  addModuleDesc: {
    id: 'addModuleDesc',
    defaultMessage:
      'To begin, your module needs a name and a short description.',
  },
  unarchiveProfile: {
    id: 'unarchiveProfile',
    defaultMessage: 'Unarchive profile',
  },
  archiveProfile: {
    id: 'archiveProfile',
    defaultMessage: 'Archive profile',
  },
  selectApplicationVersion: {
    id: 'selectApplicationVersion',
    defaultMessage: 'Select the Application Version',
  },
  required: {
    id: 'required',
    defaultMessage: 'Required',
  },
  activateProfiles: {
    id: 'activateProfiles',
    defaultMessage: 'Unarchive profile(s)',
  },
  changedTo: {
    id: 'changedTo',
    defaultMessage: 'Changed to',
  },
  modification: {
    id: 'modification',
    defaultMessage: 'Modification',
  },
  exportVersionLogCSV: {
    id: 'exportVersionLogCSV',
    defaultMessage: 'Export Version Log as CSV',
  },
  deleteSelectedVersions: {
    id: 'deleteSelectedVersions',
    defaultMessage: 'Delete Selected Version(s)',
  },
  open: {
    id: 'open',
    defaultMessage: 'Open',
  },
  noReportVersions: {
    id: 'noReportVersions',
    defaultMessage: 'No Report Versions...',
  },
  noDescription: {
    id: 'noDescription',
    defaultMessage: 'No description available',
  },
  reportId: {
    id: 'reportId',
    defaultMessage: 'Report ID',
  },
  creationDate: {
    id: 'creationDate',
    defaultMessage: 'Creation Date',
  },
  activity: {
    id: 'activity',
    defaultMessage: 'Last Activity',
  },
  relatedDevice: {
    id: 'relatedDevice',
    defaultMessage: 'Related Device',
  },
  versions: {
    id: 'versions',
    defaultMessage: 'Versions',
  },
  details: {
    id: 'details',
    defaultMessage: 'Details',
  },
  architecture: {
    id: 'architecture',
    defaultMessage: 'Architecture',
  },
  confirmRemoveImages: {
    id: 'confirmRemoveImages',
    defaultMessage: 'Confirm Remove Image(s)',
  },
  selectedImages: {
    id: 'selectedImages?',
    defaultMessage: 'selected images(s)?',
  },
  deviceImages: {
    id: 'deviceImages',
    defaultMessage: 'Device images',
  },
  manageAndDownloadYourDevice: {
    id: 'manageAndDownloadYourDevice',
    defaultMessage: 'Manage and download your device images.',
  },
  addDeviceType: {
    id: 'addDeviceType',
    defaultMessage: 'Add Device Type',
  },
  removeImages: {
    id: 'removeImages',
    defaultMessage: 'Remove Images',
  },
  createNew: {
    id: 'createNew',
    defaultMessage: 'Create new',
  },
  addNew: {
    id: 'addNew',
    defaultMessage: 'Add new',
  },
  createYourFirstDeviceImage: {
    id: 'createYourFirstDeviceImage',
    defaultMessage: 'Create your first Device Image',
  },
  selectTheDeviceTypeToCreate: {
    id: 'selectTheDeviceTypeToCreate',
    defaultMessage:
      'Select the Device Type to create the image, download the image file and and flash it to your devices.',
  },
  selectDeviceType: {
    id: 'selectDeviceType',
    defaultMessage: 'Select Device Type',
  },
  deviceInformation: {
    id: 'deviceInformation',
    defaultMessage: 'Device Information',
  },
  licencePlate: {
    id: 'licencePlate',
    defaultMessage: 'Licence Plate',
  },
  base: {
    id: 'base',
    defaultMessage: 'Base',
  },
  vehicleNumber: {
    id: 'vehicleNumber',
    defaultMessage: 'Vehicle Number',
  },
  deviceGroups: {
    id: 'deviceGroups',
    defaultMessage: 'Device Groups',
  },
  deviceGroupsSubtitle: {
    id: 'deviceGroupsSubtitle',
    defaultMessage:
      'Manage the groups to which the current device is assigned to.',
  },
  deviceId: {
    id: 'deviceId',
    defaultMessage: 'Device ID',
  },
  noProfileAssigned: {
    id: 'noProfileAssigned',
    defaultMessage: 'No Profile assigned',
  },
  configureDeployedNodes: {
    id: 'configureDeployedNodes',
    defaultMessage: 'Configure deployed Nodes',
  },
  configure: {
    id: 'configure',
    defaultMessage: 'Configure',
  },
  configuredBy: {
    id: 'configuredBy',
    defaultMessage: 'Configured by',
  },
  configStatus: {
    id: 'configStatus',
    defaultMessage: 'Config Status',
  },
  deployedAt: {
    id: 'deployedAt',
    defaultMessage: 'Deployed at',
  },
  configureAt: {
    id: 'configureAt',
    defaultMessage: 'Configured at',
  },
  deployedApplication: {
    id: 'deployedApplication',
    defaultMessage: 'Deployed Application',
  },
  deviceHealth: {
    id: 'deviceHealth',
    defaultMessage: 'Device Health',
  },
  seeMetrics: {
    id: 'seeMetrics',
    defaultMessage: 'See metrics and analytics about this device',
  },
  show: {
    id: 'show',
    defaultMessage: 'Show',
  },
  deviceDescription: {
    id: 'deviceDescription',
    defaultMessage: 'Device Description',
  },
  nameIsRequired: {
    id: 'nameIsRequired',
    defaultMessage: 'Name is required',
  },
  invalidDeviceName: {
    id: 'invalidDeviceName',
    defaultMessage: 'Avoid spaces or special characters',
  },
  descriptionIsRequired: {
    id: 'descriptionIsRequired',
    defaultMessage: 'Description is required',
  },
  usernameIsRequired: {
    id: 'usernameIsRequired',
    defaultMessage: 'Username is required',
  },
  createWorkspace: {
    id: 'createWorkspace',
    defaultMessage: 'Create Workspace',
  },
  flows: {
    id: 'flows',
    defaultMessage: 'Flows',
  },
  reporting: {
    id: 'reporting',
    defaultMessage: 'Reporting',
  },
  reports: {
    id: 'reports',
    defaultMessage: 'Reports',
  },
  createYourFirstApplication: {
    id: 'createYourFirstApplication',
    defaultMessage: 'Create your first AI Vision Application',
  },
  createYourFirstVideo: {
    id: 'createYourFirstVideo',
    defaultMessage: 'Add your first Video',
  },
  useTheEditorToCreate: {
    id: 'useTheEditorToCreate',
    defaultMessage:
      'Use the Editor to create Applications with your Modules. Configure, update and maintain the Application.',
  },
  createFirstApplication: {
    id: 'createFirstApplication',
    defaultMessage: 'Create first Application',
  },
  createNewApplication: {
    id: 'createNewApplication',
    defaultMessage: 'Create new application',
  },
  importFromFile: {
    id: 'importFromFile',
    defaultMessage: 'Import from file',
  },
  manageTemplates: {
    id: 'manageTemplates',
    defaultMessage: 'Manage templates',
  },
  results: {
    id: 'results',
    defaultMessage: 'results',
  },
  removeApplication: {
    id: 'removeApplication',
    defaultMessage: 'Remove Application',
  },
  assignTheProfileToDevices: {
    id: 'assignTheProfileToDevices',
    defaultMessage:
      'Assign the profile to Devices, it gets deployed as soon as the device is online.',
  },
  archived: {
    id: 'archived',
    defaultMessage: 'Archived',
  },
  active: {
    id: 'active',
    defaultMessage: 'Active',
  },
  pleaseConfirm: {
    id: 'pleaseConfirm',
    defaultMessage: 'Please Confirm',
  },
  selectedProfiles: {
    id: 'selectedProfiles',
    defaultMessage: 'selected profile(s)?',
  },
  createNewProfile: {
    id: 'createNewProfile',
    defaultMessage: 'Create a new profile',
  },
  startDeployingApps: {
    id: 'startDeployingApps',
    defaultMessage: 'Start deploying your Apps',
  },
  createProfilesToManageDeployments: {
    id: 'createProfilesToManageDeployments',
    defaultMessage:
      'Using deployment center, you’ll be able to deploy your applications to devices. Create profiles to manage deployments.',
  },
  createFirstProfile: {
    id: 'createFirstProfile',
    defaultMessage: 'Create first profile',
  },
  syncDevices: {
    id: 'syncDevices',
    defaultMessage: 'Sync Devices',
  },
  devicesByGroup: {
    id: 'devicesByGroup',
    defaultMessage: 'Devices By Group',
  },
  deviceList: {
    id: 'deviceList',
    defaultMessage: 'Device List',
  },
  deviceGroup: {
    id: 'deviceGroup',
    defaultMessage: 'Device Group',
  },
  showFilter: {
    id: 'showFilter',
    defaultMessage: 'Show Filter',
  },
  groupsAssigned: {
    id: 'groupsAssigned',
    defaultMessage: 'Groups assigned',
  },
  assignedRelease: {
    id: 'assignedRelease',
    defaultMessage: 'Assigned Release',
  },
  updateDevice: {
    id: 'updateDevice',
    defaultMessage: 'Update Device',
  },
  archiveDevice: {
    id: 'archiveDevice',
    defaultMessage: 'Archive Device',
  },
  macAddress: {
    id: 'macAddress',
    defaultMessage: 'MAC Address',
  },
  addedOn: {
    id: 'addedOn',
    defaultMessage: 'Added',
  },
  software: {
    id: 'software',
    defaultMessage: 'Software',
  },
  softwareRelease: {
    id: 'softwareRelease',
    defaultMessage: 'SW Release',
  },
  /* List & Detail Page */
  all: {
    id: 'all',
    defaultMessage: 'All',
  },
  online: {
    id: 'online',
    defaultMessage: 'online',
  },
  offline: {
    id: 'offline',
    defaultMessage: 'Offline',
  },
  allGroups: {
    id: 'allGroups',
    defaultMessage: 'All Groups',
  },
  rowsPerPage: {
    id: 'rowsPerPage',
    defaultMessage: 'Rows Per Page',
  },
  deviceOverview: {
    id: 'deviceOverview',
    defaultMessage: 'Device Overview',
  },
  id: {
    id: 'id',
    defaultMessage: 'ID',
  },
  selectGroup: {
    id: 'selectGroup',
    defaultMessage: 'Select Group',
  },
  add: {
    id: 'add',
    defaultMessage: 'Add',
  },
  addComment: {
    id: 'addComment',
    defaultMessage: 'Add Comment',
  },
  confirmation: {
    id: 'confirmation',
    defaultMessage: 'Confirmation',
  },
  removeDeviceconfirmationMessage: {
    id: 'removeDeviceconfirmationMessage',
    defaultMessage: 'Are you sure to remove this device?',
  },
  ok: {
    id: 'ok',
    defaultMessage: 'OK',
  },
  DRSSystem: {
    id: 'DRSSystem',
    defaultMessage: 'DRS-System',
  },
  reportEntries: {
    id: 'reportEntries',
    defaultMessage: 'Report Entries',
  },
  importBlueprint: {
    id: 'importBlueprint',
    defaultMessage: 'Import a Blueprint',
  },
  addInformation: {
    id: 'addInformation',
    defaultMessage: 'Create a new application',
  },
  addModule: {
    id: 'addModule',
    defaultMessage: 'Add module',
  },
  noApplication: {
    id: 'noApplication',
    defaultMessage: 'Currently no application available.',
  },
  noModule: {
    id: 'noModule',
    defaultMessage: 'Currently no module available.',
  },
  moduleCategory: {
    id: 'category',
    defaultMessage: 'Category',
  },
  moduleType: {
    id: 'type',
    defaultMessage: 'Type',
  },
  moduleModified: {
    id: 'modified',
    defaultMessage: 'Modified',
  },
  moduleInformation: {
    id: 'moduleInformation',
    defaultMessage: 'Module Information',
  },
  applicationId: {
    id: 'applicationId',
    defaultMessage: 'Application ID',
  },
  moduleAddInformation: {
    id: 'moduleAddInformation',
    defaultMessage: 'Please add information about the module to be created.',
  },
  moduleProvideInformation: {
    id: 'moduleProvideInformation',
    defaultMessage: 'Please provide information to import the module.',
  },
  removeFromLibrary: {
    id: 'removeFromLibrary',
    defaultMessage: 'Remove from library',
  },
  used: {
    id: 'used',
    defaultMessage: 'Used',
  },
  notInstalled: {
    id: 'notInstalled',
    defaultMessage: 'Not installed',
  },
  requiredModules: {
    id: 'requiredModules',
    defaultMessage: 'Required Modules',
  },
  applicationDependents: {
    id: 'applicationDependents',
    defaultMessage: 'Application Dependents',
  },
  dependents: {
    id: 'dependents',
    defaultMessage: 'Dependents',
  },
  currentApplicationProfiles: {
    id: 'currentApplicationProfiles',
    defaultMessage: 'The current application contains the following profiles',
  },
  applicationCommitHistory: {
    id: 'applicationCommitHistory',
    defaultMessage: 'Application Commit History',
  },
  applicationVersions: {
    id: 'applicationVersions',
    defaultMessage: 'Application Versions',
  },
  applicationVersion: {
    id: 'applicationVersion',
    defaultMessage: 'Application Version',
  },
  applicationHasVersion: {
    id: 'applicationHasVersion',
    defaultMessage:
      'The application has versions that can be assigned to a profile to deploy.',
  },
  manageApplicationFlow: {
    id: 'manageApplicationFlow',
    defaultMessage: 'Manage the application flow in the Viso Builder',
  },
  manageEncryptionOfApplication: {
    id: 'manageEncryptionOfApplication',
    defaultMessage: 'Manage the encryption of the application.',
  },
  latestVersion: {
    id: 'latestVersion',
    defaultMessage: 'Latest Version',
  },
  createFromTemplate: {
    id: 'createFromTemplate',
    defaultMessage: 'Create from Template',
  },
  deleteTemplate: {
    id: 'deleteTemplate',
    defaultMessage: 'Delete Template',
  },
  templateName: {
    id: 'templateName',
    defaultMessage: 'Template Name',
  },
  createdDate: {
    id: 'createdDate',
    defaultMessage: 'Created Date',
  },
  createNewApplicationStarting: {
    id: 'createNewApplicationStarting',
    defaultMessage:
      'Create a new application starting from scratch or with a template.',
  },
  buildApplicationFromScratch: {
    id: 'buildApplicationFromScratch',
    defaultMessage:
      'Build your application from scratch, start fresh with an empty canvas.',
  },
  fromTemplate: {
    id: 'fromTemplate',
    defaultMessage: 'From Template',
  },
  createFromTemplateDesc: {
    id: 'createFromTemplateDesc',
    defaultMessage:
      'Create your own application starting with a pre-made template.',
  },
  importFile: {
    id: 'importFile',
    defaultMessage: 'Import File',
  },
  importApplicationExported: {
    id: 'importApplicationExported',
    defaultMessage:
      'Import an Application that was exported from a Viso Workspace.',
  },
  importApplicationFile: {
    id: 'importApplicationFile',
    defaultMessage: 'Import an Application File',
  },
  importApplicationExportedFromWorkspace: {
    id: 'importApplicationExportedFromWorkspace',
    defaultMessage:
      'Import an Application that was previously exported from a Viso Workspace.',
  },
  importApplication: {
    id: 'importApplication',
    defaultMessage: 'Import Application',
  },
  applicationFile: {
    id: 'applicationFile',
    defaultMessage: 'Application File',
  },
  createAnApplicationFromScratch: {
    id: 'createAnApplicationFromScratch',
    defaultMessage: 'Create an Application from Scratch',
  },
  createAnApplicationFromScratchDesc: {
    id: 'createAnApplicationFromScratchDesc',
    defaultMessage:
      'To begin, your application needs a name and a short description.',
  },
  applicationTemplates: {
    id: 'applicationTemplates',
    defaultMessage: 'Application Templates',
  },
  yourApplicationTemplates: {
    id: 'yourApplicationTemplates',
    defaultMessage: 'Your Application Templates',
  },
  savingWillCreateANewApplicationVersion: {
    id: 'savingWillCreateANewApplicationVersion',
    defaultMessage:
      'Saving will create a new Application Version that can be assigned to a Profile for Deployment. Discard if you want to proceed without saving.',
  },
  applicationInformation: {
    id: 'applicationInformation',
    defaultMessage: 'Application Information',
  },
  applicationConfiguration: {
    id: 'applicationConfiguration',
    defaultMessage: 'Configuration',
  },
  modulesContained: {
    id: 'modulesContained',
    defaultMessage: 'Modules contained',
  },
  modulesDescription: {
    id: 'modulesDescription',
    defaultMessage:
      'Select Device Groups that are elements of the current scope in order to manage access and visibility.',
  },
  removeLibrary: {
    id: 'removeLibrary',
    defaultMessage: 'Remove from library',
  },
  newApplicationDescription: {
    id: 'newApplicationDescription',
    defaultMessage: 'Provide Information about the new Application.',
  },
  applicationConfigurationDes: {
    id: 'applicationConfigurationDes',
    defaultMessage: 'Build and Configure the new Application in the Editor.',
  },
  newApplication: {
    id: 'newApplication',
    defaultMessage: 'Application Information',
  },
  next: {
    id: 'next',
    defaultMessage: 'Next',
  },
  currentKeyIsRequired: {
    id: 'currentKeyIsRequired',
    defaultMessage: 'Current key is required',
  },
  newKeyIsRequired: {
    id: 'newKeyIsRequired',
    defaultMessage: 'New key is required',
  },
  saveApplicationAsTemplate: {
    id: 'saveApplicationAsTemplate',
    defaultMessage: 'Save Application as Template',
  },
  createApplicationAndReuse: {
    id: 'createApplicationAndReuse',
    defaultMessage: 'Create an Application Template that you can re-use',
  },
  createTemplate: {
    id: 'createTemplate',
    defaultMessage: 'Create Template',
  },
  importing: {
    id: 'importing',
    defaultMessage: 'Importing',
  },
  exportToFile: {
    id: 'exportToFile',
    defaultMessage: 'Export to File',
  },
  saveAsTemplate: {
    id: 'saveAsTemplate',
    defaultMessage: 'Save as template',
  },
  exportVersionLogAsCSV: {
    id: 'exportVersionLogAsCSV',
    defaultMessage: 'Export Version Log as CSV',
  },
  importPublicModule: {
    id: 'importPublicModule',
    defaultMessage: 'Import a Public Module',
  },
  findPublicModuleToImport: {
    id: 'findPublicModuleToImport',
    defaultMessage: 'Find a public module to import to your workspace library.',
  },
  install: {
    id: 'install',
    defaultMessage: 'Install',
  },
  addNewModule: {
    id: 'addNewModule',
    defaultMessage: 'Add a new Module',
  },
  addModuelToLibrary: {
    id: 'addModuelToLibrary',
    defaultMessage:
      'Add modules to your library to use them building applications.',
  },
  publicModule: {
    id: 'publicModule',
    defaultMessage: 'Public Module',
  },
  whatAreYouLooking: {
    id: 'whatAreYouLooking',
    defaultMessage: 'What are you looking for?',
  },
  phraseToSecureYourCredentials: {
    id: 'phraseToSecureYourCredentials',
    defaultMessage: 'A phrase to secure your credentials with',
  },
  linkedNode: {
    id: 'linkedNode',
    defaultMessage: 'Linked Node',
  },
  dockerImageURI: {
    id: 'dockerImageURI',
    defaultMessage: 'Docker Image URI',
  },
  currentModuleContainsDockerImages: {
    id: 'currentModuleContainsDockerImages',
    defaultMessage: 'The current module contains the following docker images.',
  },
  dependencies: {
    id: 'dependencies',
    defaultMessage: 'Dependencies',
  },
  moduleDependents: {
    id: 'moduleDependents',
    defaultMessage: 'Module Dependents',
  },
  currentApplicationProfile: {
    id: 'currentApplicationProfile',
    defaultMessage: 'The current application is part of the following profiles.',
  },
  nodesOfModule: {
    id: 'nodesOfModule',
    defaultMessage: 'Nodes of the Module',
  },
  nodeName: {
    id: 'nodeName',
    defaultMessage: 'Node Name',
  },
  nodesContainedModule: {
    id: 'nodesContainedModule',
    defaultMessage:
      'Nodes contained in the Module, building blocks in the Viso Builder',
  },
  nameOfContainer: {
    id: 'nameOfContainer',
    defaultMessage: 'Name of the container',
  },
  containerType: {
    id: 'containerType',
    defaultMessage: 'Container Type',
  },
  secretAccessKey: {
    id: 'secretAccessKey',
    defaultMessage: 'Secret Access Key',
  },
  accessKeyID: {
    id: 'accessKeyID',
    defaultMessage: 'Access Key ID',
  },
  copy: {
    id: 'copy',
    defaultMessage: 'Copy',
  },
  pushCommandsFor: {
    id: 'pushCommandsFor',
    defaultMessage: 'Push commands for',
  },
  moduleVersions: {
    id: 'moduleVersions',
    defaultMessage: 'Module Versions',
  },
  update: {
    id: 'update',
    defaultMessage: 'Update',
  },
  currentApplicationModules: {
    id: 'currentApplicationModules',
    defaultMessage: 'The current application contains the following modules',
  },
  cNew: {
    id: 'cNew',
    defaultMessage: 'NEW',
  },
  manageTheEncryptionApplication: {
    id: 'manageTheEncryptionApplication',
    defaultMessage: 'Manage the encryption of the application.',
  },
  currentVersion: {
    id: 'currentVersion',
    defaultMessage: 'Current Version',
  },
  moduleSettings: {
    id: 'moduleSettings',
    defaultMessage: 'Module Settings',
  },
  removeModule: {
    id: 'removeModule',
    defaultMessage: 'Remove Module',
  },
  whenYouRemoveTheModule: {
    id: 'whenYouRemoveTheModule',
    defaultMessage:
      'When you remove the module from your Library, you will not be able to use it in the Viso Builder or deploy Applications that have this Module as a Dependency.',
  },
  updateAvailable: {
    id: 'updateAvailable',
    defaultMessage: 'Update available',
  },
  yourApplicationNeedsAName: {
    id: 'yourApplicationNeedsAName',
    defaultMessage:
      'To begin, your application needs a name and a short description.',
  },
  removeModuleFromLibrary: {
    id: 'removeModuleFromLibrary',
    defaultMessage:
      'When you remove the module from your Library, you will not be able to use it in the Viso Builder or deploy Applications that have this Module as a Dependency.',
  },
  superchargeYourEditor: {
    id: 'superchargeYourEditor',
    defaultMessage: 'Supercharge your Editor',
  },
  moduleToBuildApplications: {
    id: 'moduleToBuildApplications',
    defaultMessage:
      'Building Blocks can be used in the Editor to build Applications. Search available Building Blocks or create own Building Blocks.',
  },
  addNewVersion: {
    id: 'addNewVersion',
    defaultMessage: 'Add new version',
  },
  workspaceLogin: {
    id: 'workspaceLogin',
    defaultMessage: 'Workspace Login',
  },
  signInToYourWorkspace: {
    id: 'signInToYourWorkspace',
    defaultMessage: 'Sign in to your workspace',
  },
  email: {
    id: 'email',
    defaultMessage: 'Email',
  },
  login: {
    id: 'login',
    defaultMessage: 'Login',
  },
  refreshFrame: {
    id: 'refreshFrame',
    defaultMessage: 'Refresh Frame',
  },
  urlNotFound: {
    id: 'urlNotFound',
    defaultMessage: 'URL not found...',
  },
  localConfiguration: {
    id: 'localConfiguration',
    defaultMessage: 'Local configuration',
  },
  keepRatio: {
    id: 'keepRatio',
    defaultMessage: 'Keep Ratio',
  },
  vendorContact: {
    id: 'vendorContact',
    defaultMessage: 'Vendor Contact',
  },
  primaryPersonContacted: {
    id: 'primaryPersonContacted',
    defaultMessage: 'The primary person to be contacted.',
  },
  salesHistory: {
    id: 'salesHistory',
    defaultMessage: 'Sales History',
  },
  estimatedRevenue: {
    id: 'estimatedRevenue',
    defaultMessage: 'Estimated Revenue',
  },
  faq: {
    id: 'faq',
    defaultMessage: 'FAQ',
  },
  lastMonth: {
    id: 'lastMonth',
    defaultMessage: 'Last month',
  },
  currentBalance: {
    id: 'currentBalance',
    defaultMessage: 'Current balance',
  },
  paymentsAndReports: {
    id: 'paymentsAndReports',
    defaultMessage: 'Payments and reports',
  },
  vendorInformation: {
    id: 'vendorInformation',
    defaultMessage: 'Vendor Information',
  },
  byClickingSave: {
    id: 'byClickingSave',
    defaultMessage: 'By clicking Save you accept the',
  },
  customerAgreement: {
    id: 'customerAgreement',
    defaultMessage: 'Customer Agreement',
  },
  andAgreeToPayFees: {
    id: 'andAgreeToPayFees',
    defaultMessage: 'and agree to pay fees charged in accordance with our agreement.',
  },
  pricing: {
    id: 'pricing',
    defaultMessage: 'Pricing',
  },
  complete: {
    id: 'complete',
    defaultMessage: 'Complete',
  },
  documentationAndSupport: {
    id: 'documentationAndSupport',
    defaultMessage: 'Documentation',
  },
  manageResourcesAndContactInformation: {
    id: 'manageResourcesAndContactInformation',
    defaultMessage: 'Information displayed to the user in the Viso Hub.',
  },
  informationDisplayedToTheUser: {
    id: 'informationDisplayedToTheUser',
    defaultMessage: 'Information displayed to the user in the',
  },
  screenshotGallery: {
    id: 'screenshotGallery',
    defaultMessage: 'Screenshot Gallery',
  },
  tagLine: {
    id: 'tagLine',
    defaultMessage: 'Tagline',
  },
  listingId: {
    id: 'listingId',
    defaultMessage: 'Listing ID',
  },
  startingAt: {
    id: 'startingAt',
    defaultMessage: 'Starting at',
  },
  chooseYour: {
    id: 'chooseYour',
    defaultMessage: 'Choose your',
  },
  edition: {
    id: 'edition',
    defaultMessage: 'Edition',
  },
  selectThePlan: {
    id: 'selectThePlan',
    defaultMessage: 'Select the plan for your',
  },
  payBasedOnUsage: {
    id: 'payBasedOnUsage',
    defaultMessage: 'Pay based on usage.',
  },
  reviewYourOrder: {
    id: 'reviewYourOrder',
    defaultMessage: 'Review your order',
  },
  tryCancelAtAnyTime: {
    id: 'tryCancelAtAnyTime',
    defaultMessage: 'for free for 30 days. cancel at any time',
  },
  try: {
    id: 'try',
    defaultMessage: 'Try',
  },
  paymentInfo: {
    id: 'paymentInfo',
    defaultMessage: 'Payment Info',
  },
  yourCreditCardChargedMonthly: {
    id: 'yourCreditCardChargedMonthly',
    defaultMessage:
      'Your credit card will be charged monthly and your fees may vary based on how we bill.',
  },
  addNewRole: {
    id: 'addNewRole',
    defaultMessage: 'Create new role',
  },
  createRole: {
    id: 'createRole',
    defaultMessage: 'Create Role',
  },
  updateRole: {
    id: 'updateRole',
    defaultMessage: 'Update Role',
  },
  roleName: {
    id: 'roleName',
    defaultMessage: 'User Role',
  },
  userRoleName: {
    id: 'userRoleName',
    defaultMessage: 'Role name',
  },
  roleId: {
    id: 'roleId',
    defaultMessage: 'Role ID',
  },
  userRoles: {
    id: 'userRoles',
    defaultMessage: 'User Roles',
  },
  modalDeviceInfo: {
    id: 'modalDeviceInfo',
    defaultMessage:
      'Basic Information about the Edge Device such as name, type and custom fields.',
  },
  modalSolutionsInfo: {
    id: 'modalSolutionsInfo',
    defaultMessage:
      'Basic Information about the Edge Device such as name, type and custom fields.',
  },
  modalReportsInfo: {
    id: 'modalReportsInfo',
    defaultMessage:
      'Basic Information about the Edge Device such as name, type and custom fields.',
  },
  modalUsersAndRolesInfo: {
    id: 'modalUsersAndRolesInfo',
    defaultMessage:
      'Basic Information about the Edge Device such as name, type and custom fields.',
  },
  drs: {
    id: 'drs',
    defaultMessage: 'DRS',
  },
  drsInfo: {
    id: 'drsInfo',
    defaultMessage: 'Basic Information about the DRS.',
  },
  manageAccessDRS: {
    id: 'manageAccessDRS',
    defaultMessage: 'Manage access to DRS',
  },
  other: {
    id: 'other',
    defaultMessage: 'Other',
  },
  otherPermissions: {
    id: 'otherPermissions',
    defaultMessage: 'Other Permissions',
  },
  manageAccessOther: {
    id: 'manageAccessOther',
    defaultMessage: 'Manage Other',
  },
  manageAccessDevices: {
    id: 'manageAccessDevices',
    defaultMessage: 'Manage access to Devices',
  },
  manageAccessSolutions: {
    id: 'manageAccessSolutions',
    defaultMessage: 'Manage access to Solutions',
  },
  manageAccessReports: {
    id: 'manageAccessReports',
    defaultMessage: 'Manage access to Reports',
  },
  manageAccessUserAndRoles: {
    id: 'manageAccessUserAndRoles',
    defaultMessage: 'Manage access to User and Roles',
  },
  createNewUserRole: {
    id: 'createNewUserRole',
    defaultMessage: 'Role Information',
  },
  roleDescription: {
    id: 'roleDescription',
    defaultMessage: 'Role description',
  },
  releases: {
    id: 'releases',
    defaultMessage: 'Releases',
  },
  userGroups: {
    id: 'userGroups',
    defaultMessage: 'User Groups',
  },
  setUpAdvancedPermissions: {
    id: 'setUpAdvancedPermissions',
    defaultMessage: 'Set up advanced permissions',
  },
  setUpBasicPermissions: {
    id: 'setUpBasicPermissions',
    defaultMessage: 'Set up basic permissions',
  },
  updateBasicPermissions: {
    id: 'updateBasicPermissions',
    defaultMessage: 'Update the permissions',
  },
  manageAccessToDevices: {
    id: 'manageAccessToDevices',
    defaultMessage: 'Manage access to devices',
  },
  setupCustomGroup: {
    id: 'setupCustomGroup',
    defaultMessage: 'Set up a custom group.',
  },
  enableDisableIndividualPermissions: {
    id: 'enableDisableIndividualPermissions',
    defaultMessage:
      'Enable and disable portal modules and individual permissions.',
  },
  settingsManageUsersAccess: {
    id: 'settingsManageUsersAccess',
    defaultMessage: 'Settings to manage users access to resources.',
  },
  finish: {
    id: 'finish',
    defaultMessage: 'Finish',
  },
  advancedPermissions: {
    id: 'advancedPermissions',
    defaultMessage: 'Advanced Permissions',
  },
  groupUsers: {
    id: 'groupUsers',
    defaultMessage: 'Group Users',
  },
  managePermissions: {
    id: 'managePermissions',
    defaultMessage: 'Manage permissions',
  },
  userInformation: {
    id: 'userInformation',
    defaultMessage: 'Role Information',
  },
  lastEdited: {
    id: 'lastEdited',
    defaultMessage: 'Last edited',
  },
  userAssigned: {
    id: 'userAssigned',
    defaultMessage: 'Users',
  },
  userRolesTitle: {
    id: 'userRolesTitle',
    defaultMessage: 'User Roles',
  },
  usersOf: {
    id: 'usersOf',
    defaultMessage: 'Users of',
  },
  specialRolePermissions: {
    id: 'specialRolePermissions',
    defaultMessage: 'Special Role Permissions',
  },
  assignUserRole: {
    id: 'assignUserRole',
    defaultMessage: 'Assign user role',
  },
  areYourSureToAssignTheRole: {
    id: 'areYourSureToAssignTheRole',
    defaultMessage:
      'Are you sure to assign the role {name} to the {count} selected user(s)?',
  },
  assignUsersToRole: {
    id: 'assignUsersToRole',
    defaultMessage: 'Assign Users to Role',
  },
  manageUsersThatHaveTheCurrentRole: {
    id: 'manageUsersThatHaveTheCurrentRole',
    defaultMessage: 'Manage users that have the current role assigned',
  },
  theRoleWillBeDeleted: {
    id: 'theRoleWillBeDeleted',
    defaultMessage: 'The role will be deleted',
  },
  info: {
    id: 'info',
    defaultMessage: 'Info',
  },
  lockedBecauseItsUse: {
    id: 'lockedBecauseItsUse',
    defaultMessage: 'Locked because it is in use',
  },
  provideBasicInfo: {
    id: 'provideBasicInfo',
    defaultMessage: 'Provide some basic information',
  },
  nameAlreadyInUse: {
    id: 'nameAlreadyInUse',
    defaultMessage: 'Name is already in use',
  },
  confirmDeleteRole: {
    id: 'confirmDeleteRole',
    defaultMessage: 'Confirm delete role',
  },
  assignRoleToUser: {
    id: 'assignRoleToUser',
    defaultMessage: 'Assign user role',
  },
  assignRoleToUsers: {
    id: 'assignRoleToUsers',
    defaultMessage: 'Assign role to user(s)',
  },
  removeRole: {
    id: 'removeRole',
    defaultMessage: 'Remove Role',
  },
  personalSettings: {
    id: 'personalSettings',
    defaultMessage: 'Personal Settings',
  },
  userProfile: {
    id: 'userProfile',
    defaultMessage: 'User Profile',
  },
  workspaceSettings: {
    id: 'workspaceSettings',
    defaultMessage: 'Workspace Settings',
  },
  addNewUser: {
    id: 'addNewUser',
    defaultMessage: 'Create new user',
  },
  updateUser: {
    id: 'updateUser',
    defaultMessage: 'Update User',
  },
  userId: {
    id: 'userId',
    defaultMessage: 'User ID',
  },
  defaultLanguage: {
    id: 'defaultLanguage',
    defaultMessage: 'Default Language',
  },
  userLanguage: {
    id: 'userLanguage',
    defaultMessage: 'User Language',
  },
  inviteNewUsersWorkspace: {
    id: 'inviteNewUsersWorkspace',
    defaultMessage: 'Invite users to Workspace',
  },
  addNewUsersBySendingInvitations: {
    id: 'addNewUsersBySendingInvitations',
    defaultMessage: 'Users will receive an email inviting them to the workspace',
  },
  userEmails: {
    id: 'emails',
    defaultMessage: 'User Emails',
  },
  role: {
    id: 'role',
    defaultMessage: 'Role',
  },
  firstName: {
    id: 'firstName',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'lastName',
    defaultMessage: 'Last Name',
  },
  internalId: {
    id: 'internalId',
    defaultMessage: 'Internal ID',
  },
  changePassword: {
    id: 'changePassword',
    defaultMessage: 'Change password',
  },
  setPassword: {
    id: 'setPassword',
    defaultMessage: 'Set password',
  },
  userStatus: {
    id: 'userStatus',
    defaultMessage: 'User Status',
  },
  userGroup: {
    id: 'userGroup',
    defaultMessage: 'User Group',
  },
  addAnother: {
    id: 'addAnother',
    defaultMessage: 'Add another',
  },
  assignToUserGroup: {
    id: 'assignToUserGroup',
    defaultMessage: 'Assign to user groups',
  },
  assignRole: {
    id: 'assignRole',
    defaultMessage: 'Assign Role',
  },
  assignScope: {
    id: 'assignScope',
    defaultMessage: 'Assign Scope',
  },
  inviteUsers: {
    id: 'inviteUsers',
    defaultMessage: 'Invite users',
  },
  changeYourPassword: {
    id: 'changeYourPassword',
    defaultMessage: 'Change your password',
  },
  setYourPassword: {
    id: 'setYourPassword',
    defaultMessage: 'Set your password',
  },
  pleaseSetNewPassword: {
    id: 'pleaseSetNewPassword',
    defaultMessage: 'Please set a new password.',
  },
  currentPassword: {
    id: 'currentPassword',
    defaultMessage: 'Current password',
  },
  newPassword: {
    id: 'newPassword',
    defaultMessage: 'New password',
  },
  confirmPassword: {
    id: 'confirmPassword',
    defaultMessage: 'Confirm password',
  },
  invitedBy: {
    id: 'invitedBy',
    defaultMessage: 'Invited by',
  },
  showReportEntriesOf: {
    id: 'showReportEntriesOf',
    defaultMessage: 'Show Report Entries of',
  },
  userGroupId: {
    id: 'userGroupId',
    defaultMessage: 'User Group ID',
  },
  letsSetUpYourProfile: {
    id: 'letsSetUpYourProfile',
    defaultMessage: 'Let’s set up your profile.',
  },
  phone: {
    id: 'phone',
    defaultMessage: 'Phone Number',
  },
  userGroupDescription: {
    id: 'userGroupDescription',
    defaultMessage:
      'Manage the groups to which the current user is assigned to.',
  },
  lastLogin: {
    id: 'lastLogin',
    defaultMessage: 'Last Login',
  },
  lastActivity: {
    id: 'lastActivity',
    defaultMessage: 'Last activity',
  },
  roleDescriptionPermission: {
    id: 'roleDescriptionPermission',
    defaultMessage: 'Role of the current user to manage permissions.',
  },
  userScope: {
    id: 'userScope',
    defaultMessage: 'User Scope',
  },
  userScopeDescription: {
    id: 'userScopeDescription',
    defaultMessage: 'Scope of the current user to manage access to resources.',
  },
  deleteUser: {
    id: 'deleteUser',
    defaultMessage: 'Delete selected user(s)',
  },
  getStarted: {
    id: 'getStarted',
    defaultMessage: 'Get Started',
  },
  userSettings: {
    id: 'userSettings',
    defaultMessage: 'User Settings',
  },
  noRoleAssigned: {
    id: 'noRoleAssigned',
    defaultMessage: 'No Role assigned',
  },
  joinedAt: {
    id: 'joinedAt',
    defaultMessage: 'Joined At',
  },
  sentOut: {
    id: 'sentOut',
    defaultMessage: 'Sent out',
  },
  expiresAt: {
    id: 'expiresAt',
    defaultMessage: 'Expires at',
  },
  resendInvitations: {
    id: 'resendInvitations',
    defaultMessage: 'Resend Invitations',
  },
  resendInvitation: {
    id: 'resendInvitation',
    defaultMessage: 'Resend Invitation',
  },
  cancelInvitations: {
    id: 'cancelInvitations',
    defaultMessage: 'Cancel Invitations',
  },
  cancelInvitation: {
    id: 'cancelInvitation',
    defaultMessage: 'Cancel Invitation',
  },
  userInvitations: {
    id: 'userInvitations',
    defaultMessage: 'User Invitations',
  },
  invitations: {
    id: 'invitations',
    defaultMessage: 'Invitations',
  },
  viewUser: {
    id: 'viewUser',
    defaultMessage: 'View User',
  },
  configureNewUsers: {
    id: 'configureNewUsers',
    defaultMessage: 'Configure new users',
  },
  addNewUsersToYourWorkspace: {
    id: 'addNewUsersToYourWorkspace',
    defaultMessage: 'Add new users to your workspace',
  },
  assignAUserRoleToUsers: {
    id: 'assignAUserRoleToUsers',
    defaultMessage:
      'Assign a User Role to the new users to manage their permissions.',
  },
  inviteNow: {
    id: 'inviteNow',
    defaultMessage: 'Invite Now',
  },
  assignToNewUsers: {
    id: 'assignToNewUsers',
    defaultMessage: 'Assign to new users',
  },
  confirmActivateUsers: {
    id: 'confirmActivateUsers',
    defaultMessage: 'Confirm Activate Users',
  },
  confirmActivateUser: {
    id: 'confirmActivateUser',
    defaultMessage: 'Confirm Activate User',
  },
  confirmDeactivateUser: {
    id: 'confirmDeactivateUser',
    defaultMessage: 'Confirm Deactivate User',
  },
  confirmDeactivateUsers: {
    id: 'confirmDeactivateUsers',
    defaultMessage: 'Confirm Deactivate Users',
  },
  confirmDeleteUser: {
    id: 'conficonfirmDelete',
    defaultMessage: 'Confirm Delete User',
  },
  confirmDeleteUser: {
    id: 'conficonfirmDelete',
    defaultMessage: 'Confirm Delete User'
  },
  confirmDeleteTemplate: {
    id: 'confirmDeleteTemplate',
    defaultMessage: 'Confirm Delete Template',
  },
  confirmDeleteTemplateDesc: {
    id: 'confirmDeleteTemplateDesc',
    defaultMessage: 'Are you sure you want to delete the template?',
  },
  deactivate: {
    id: 'deactivate',
    defaultMessage: 'Deactivate',
  },
  confirmArchiveUsers: {
    id: 'confirmArchiveUsers',
    defaultMessage: 'Confirm Archive Users',
  },
  confirmArchiveUser: {
    id: 'confirmArchiveUser',
    defaultMessage: 'Confirm Archive User',
  },
  inviteNewUsers: {
    id: 'inviteNewUsers',
    defaultMessage: 'Invite new users',
  },
  invitationList: {
    id: 'invitationList',
    defaultMessage: 'Invitation List',
  },
  activateUsers: {
    id: 'activateUsers',
    defaultMessage: 'Activate user(s)',
  },
  activateUser: {
    id: 'activateUser',
    defaultMessage: 'Activate user',
  },
  deactivateUsers: {
    id: 'deactivateUsers',
    defaultMessage: 'Deactivate user(s)',
  },
  deactivateUser: {
    id: 'deactivateUser',
    defaultMessage: 'Deactivate user',
  },
  myProfile: {
    id: 'myProfile',
    defaultMessage: 'My Profile',
  },
  manageYourInformationAndSettings: {
    id: 'manageYourInformationAndSettings',
    defaultMessage: 'Manage your information and personal settings',
  },
  myWorkspaces: {
    id: 'myWorkspaces',
    defaultMessage: 'My Workspaces',
  },
  manageYourWorkspacesOrCreate: {
    id: 'manageYourWorkspacesOrCreate',
    defaultMessage: 'Manage your workspaces or create a new one',
  },
  createAndManageTheUsers: {
    id: 'createAndManageTheUsers',
    defaultMessage: 'Create and manage the users and user profiles',
  },
  rolesAndAccess: {
    id: 'rolesAndAccess',
    defaultMessage: 'Roles and Access',
  },
  manageUserRoles: {
    id: 'manageUserRoles',
    defaultMessage: 'Manage user roles and the related permissions',
  },
  manageWorkspaceInformation: {
    id: 'manageWorkspaceInformation',
    defaultMessage: 'Manage workspace information and settings',
  },
  billingAndSubscriptions: {
    id: 'billingAndSubscriptions',
    defaultMessage: 'Billing & Subscriptions',
  },
  manageYourPaymentInformation: {
    id: 'manageYourPaymentInformation',
    defaultMessage: 'Manage your payment information',
  },
  manageYourMarketplaceListings: {
    id: 'manageYourMarketplaceListings',
    defaultMessage: 'Manage your Marketplace listings and earnings',
  },
  archiveWorkspace: {
    id: 'archiveWorkspace',
    defaultMessage: 'Archive Workspace',
  },
  deleteWorkspace: {
    id: 'deleteWorkspace',
    defaultMessage: 'Delete Workspace',
  },
  editWorkspace: {
    id: 'editWorkspace',
    defaultMessage: 'Manage Workspace',
  },
  english: {
    id: 'english',
    defaultMessage: 'English',
  },
  heading: {
    id: 'workspace',
    defaultMessage: 'Workspace',
  },
  organizationName: {
    id: 'organizationName',
    defaultMessage: 'Organization Name',
  },
  organizationAddress: {
    id: 'organizationAddress',
    defaultMessage: 'Organization Address',
  },
  defaultUserRole: {
    id: 'defaultUserRole',
    defaultMessage: 'Default User Role',
  },
  administrator: {
    id: 'administrator',
    defaultMessage: 'Administrator',
  },
  customer: {
    id: 'customer',
    defaultMessage: 'Customer',
  },
  workspaceDomain: {
    id: 'workspaceDomain',
    defaultMessage: 'Workspace Domain',
  },
  workspaceOwner: {
    id: 'workspaceOwner',
    defaultMessage: 'Workspace Owner',
  },
  appearance: {
    id: 'appearance',
    defaultMessage: 'Appearance',
  },
  baseColor: {
    id: 'baseColor',
    defaultMessage: 'Base Color',
  },
  page: {
    id: 'page',
    defaultMessage: 'Page',
  },
  sidebar: {
    id: 'sidebar',
    defaultMessage: 'Sidebar',
  },
  customizeWorkspace: {
    id: 'customizeWorkspace',
    defaultMessage: 'Customize Workspace',
  },
  ownerOfTheWorkspace: {
    id: 'ownerOfTheWorkspace',
    defaultMessage:
      'Users with the role Owner can manage billing and subscriptions.',
  },
  workspaceAccessDenied: {
    id: 'workspaceAccessDenied',
    defaultMessage: 'Workspace Access Denied',
  },
  cantAccessWorkspace: {
    id: 'cantAccessWorkspace',
    defaultMessage:
      'You can\'t access the current Workspace. Please contact a Workspace Administrator.',
  },
  loginAccount: {
    id: 'loginAccount',
    defaultMessage: 'Log in with your Viso Account.',
  },
  areYouFacingFinancialDifficulties: {
    id: 'areYouFacingFinancialDifficulties',
    defaultMessage: 'Are you facing financial difficulties?',
  },
  getInTouchNowToDiscuss: {
    id: 'getInTouchNowToDiscuss',
    defaultMessage:
      'We’re here to help. Get in touch now to discuss your situation.',
  },
  weareSadToSee: {
    id: 'weareSadToSee',
    defaultMessage: 'We are sad to see you go',
  },
  onDeletingYourWorkspaceDesc: {
    id: 'onDeletingYourWorkspaceDesc',
    defaultMessage:
      'On deleting your workspace, you lose access to Viso workspace services, and we permanently delete your data. You can cancel the deletion for 14 days.',
  },
  typeDelete: {
    id: 'typeDelete',
    defaultMessage: 'Type “DELETE”',
  },
  manageUsers: {
    id: 'manageUsers',
    defaultMessage: 'Manage users',
  },
  manageAccess: {
    id: 'manageAccess',
    defaultMessage: 'Manage Access',
  },
  billingCenter: {
    id: 'billingCenter',
    defaultMessage: 'Billing Center',
  },
  accessWorkspace: {
    id: 'accessWorkspace',
    defaultMessage: 'Access Workspace',
  },
  manageMyProfile: {
    id: 'manageMyProfile',
    defaultMessage: 'Manage my Profile',
  },
  removedWorkspace: {
    id: 'removedWorkspace',
    defaultMessage: 'Removed Workspace',
  },
  recoverYourWorkspace: {
    id: 'recoverYourWorkspace',
    defaultMessage: 'Recover your workspace',
  },
  workspaceHasBeenDeleted: {
    id: 'workspaceHasBeenDeleted',
    defaultMessage:
      'Your workspace has been deleted. We will permanently delete your personal data. To cancel the deletion, you can recover the workspace until',
  },
  recover: {
    id: 'recover',
    defaultMessage: 'Recover',
  },
  successfullyUpdated: {
    id: 'successfullyUpdated',
    defaultMessage: 'Successfully Updated',
  },
  unsuccessfullyUpdated: {
    id: 'unsuccessfully',
    defaultMessage: 'Unsuccessfully Updated',
  },
  successfullyDeleted: {
    id: 'successfullyDeleted',
    defaultMessage: 'Successfully Deleted',
  },
  unsuccessfullyDeleted: {
    id: 'unsuccessfullyDeleted',
    defaultMessage: 'Unsuccessfully Deleted',
  },
  en: {
    id: 'en',
    defaultMessage: 'en',
  },
  de: {
    id: 'de',
    defaultMessage: 'de',
  },
  fr: {
    id: 'fr',
    defaultMessage: 'fr',
  },
  it: {
    id: 'it',
    defaultMessage: 'it',
  },
  addFilter: {
    id: 'addFilter',
    defaultMessage: 'Add a filter',
  },
  activeSeats: {
    id: 'activeSeats',
    defaultMessage: 'Active seats',
  },
  availableSeats: {
    id: 'availableSeats',
    defaultMessage: 'Available seats',
  },
  activationEndDate: {
    id: 'activationEndDate',
    defaultMessage: 'Activation end',
  },
  getNewLicense: {
    id: 'getNewLicense',
    defaultMessage: 'Get a new License',
  },
  activeUntil: {
    id: 'activeUntil',
    defaultMessage: 'Active until',
  },
  noLicense: {
    id: 'noLicense',
    defaultMessage: 'No License',
  },
  licenseInformation: {
    id: 'licenseInformation',
    defaultMessage: 'License Information',
  },
  licenseKey: {
    id: 'licenseKey',
    defaultMessage: 'License Key',
  },
  licenseType: {
    id: 'licenseType',
    defaultMessage: 'License Type',
  },
  activationStart: {
    id: 'activationStart',
    defaultMessage: 'Activation Start Date',
  },
  planName: {
    id: 'planName',
    defaultMessage: 'Plan Name',
  },
  licenseId: {
    id: 'licenseId',
    defaultMessage: 'License ID',
  },
  owner: {
    id: 'owner',
    defaultMessage: 'Owner',
  },
  expiration: {
    id: 'expiration',
    defaultMessage: 'Expiration',
  },
  licenseOverview: {
    id: 'licenseOverview',
    defaultMessage: 'Overview',
  },
  seatPurchased: {
    id: 'seatPurchased',
    defaultMessage: 'Seats purchased',
  },
  seatAssigned: {
    id: 'seatAssigned',
    defaultMessage: 'Seats assigned',
  },
  seatUnassigned: {
    id: 'seatUnassigned',
    defaultMessage: 'Seats unassigned',
  },
  report: {
    id: 'report',
    defaultMessage: 'Report',
  },
  entries: {
    id: 'entries',
    defaultMessage: 'Entries',
  },
  default: {
    id: 'default',
    defaultMessage: 'Default',
  },
  submittedDate: {
    id: 'submittedDate',
    defaultMessage: 'Submitted Date',
  },
  group: {
    id: 'group',
    defaultMessage: 'Group',
  },
  reportsByGroup: {
    id: 'reportsByGroup',
    defaultMessage: 'Reports By Group',
  },
  allDevices: {
    id: 'allDevices',
    defaultMessage: 'All Devices',
  },
  reportName: {
    id: 'reportName',
    defaultMessage: 'Report Name',
  },
  reportMedia: {
    id: 'reportMedia',
    defaultMessage: 'Report Media',
  },
  reportDetails: {
    id: 'reportDetails',
    defaultMessage: 'Report Details',
  },
  reportType: {
    id: 'reportType',
    defaultMessage: 'Report Type',
  },
  submittedBy: {
    id: 'submittedBy',
    defaultMessage: 'Submitted by',
  },
  filesAttached: {
    id: 'filesAttached',
    defaultMessage: 'Files Attached',
  },
  manageReport: {
    id: 'manageReport',
    defaultMessage: 'Manage Report',
  },
  exportCSV: {
    id: 'exportCSV',
    defaultMessage: 'Export CSV',
  },
  setupReport: {
    id: 'setupReport',
    defaultMessage: 'Set up new Report',
  },
  preview: {
    id: 'preview',
    defaultMessage: 'Preview',
  },
  permission: {
    id: 'permission',
    defaultMessage: 'Permission',
  },
  listing: {
    id: 'listing',
    defaultMessage: 'Listing',
  },
  areYouSureToDeactivateThe1User: {
    id: 'areYouSureToDeactivateThe1User',
    defaultMessage: 'Are you sure to deactivate the 1 selected user?',
  },
  areYouSureToActivateThe1User: {
    id: 'areYouSureToActivateThe1User',
    defaultMessage: 'Are you sure to activate the 1 selected user?',
  },
  areYouSureToArchiveThe1User: {
    id: 'areYouSureToArchiveThe1User',
    defaultMessage: 'Are you sure to archive the 1 selected user?',
  },
  areYouSureToDeleteThe1User: {
    id: 'areYouSureToDeleteThe1User',
    defaultMessage: 'Are you sure to delete the 1 selected user?',
  },
  areYouSureToDeleteThe1User: {
    id: 'areYouSureToDeleteThe1User',
    defaultMessage: 'Are you sure to delete the 1 selected user?'
  },
  redirectingToLoginScreen: {
    id: 'redirectingToLoginScreen',
    defaultMessage: 'Redirecting to login screen',
  },
  invitationSentSuccessfully: {
    id: 'invitationSentSuccessfully',
    defaultMessage: 'Invitation sent successful',
  },
  invitationCancelledSuccessfully: {
    id: 'invitationCancelledSuccessfully',
    defaultMessage: 'Invitation cancelled successfully',
  },
  invitationsCancelledSuccessfully: {
    id: 'invitationsCancelledSuccessfully',
    defaultMessage: 'Invitations cancelled successfully',
  },
  invitationResendSuccessfully: {
    id: 'invitationResendSuccessfully',
    defaultMessage: 'Invitation resend successful',
  },
  invitationsResendSuccessfully: {
    id: 'invitationsResendSuccessfully',
    defaultMessage: 'Invitations resend successful',
  },
  userStatusUpdated: {
    id: 'userStatusUpdated',
    defaultMessage: 'User status updated.',
  },
  userDeactivatedSuccessfully: {
    id: 'userDeactivatedSuccessfully',
    defaultMessage: 'User deactivated successfully',
  },
  usersDeactivatedSuccessfully: {
    id: 'usersDeactivatedSuccessfully',
    defaultMessage: 'Users deactivated successfully',
  },
  userActivatedSuccessfully: {
    id: 'userActivatedSuccessfully',
    defaultMessage: 'User activated successfully',
  },
  usersActivatedSuccessfully: {
    id: 'usersActivatedSuccessfully',
    defaultMessage: 'Users activated successfully',
  },
  userArchivedSuccessfully: {
    id: 'userArchivedSuccessfully',
    defaultMessage: 'User archived successfully.',
  },
  usersArchivedSuccessfully: {
    id: 'usersArchivedSuccessfully',
    defaultMessage: 'Users archived successfully.',
  },
  usersWereSkipped: {
    id: 'usersWereSkipped',
    defaultMessage: 'users were skipped',
  },
  devicesWereSkipped: {
    id: 'devicesWereSkipped',
    defaultMessage: 'devices were skipped',
  },
  roleChangesSaved: {
    id: 'roleChangesSaved',
    defaultMessage: 'Role changes saved',
  },
  roleAssignedSuccessfully: {
    id: 'roleAssignedSuccessfully',
    defaultMessage: 'Role assigned successfully.',
  },
  roleDeletedSuccessfully: {
    id: 'roleDeletedSuccessfully',
    defaultMessage: 'Role deleted successfully.',
  },
  profileChangesSaved: {
    id: 'profileChangesSaved',
    defaultMessage: 'Profile Changes Saved',
  },
  sDeviceUpdated: {
    id: 'sDeviceUpdated',
    defaultMessage: 'Device(s) Updated',
  },
  moduleChangesSaved: {
    id: 'moduleChangesSaved',
    defaultMessage: 'Module changes saved.',
  },
  applicationChangesSaved: {
    id: 'applicationChangesSaved',
    defaultMessage: 'Application changes saved.',
  },
  deviceRebootFailed: {
    id: 'deviceRebootFailed',
    defaultMessage: 'Device reboot failed!',
  },
  devicesRebootFailed: {
    id: 'devicesRebootFailed',
    defaultMessage: 'Device(s) reboot failed!',
  },
  deviceDeleteFailed: {
    id: 'deviceDeleteFailed',
    defaultMessage: 'Device delete failed!',
  },
  rebooting: {
    id: 'rebooting',
    defaultMessage: 'Rebooting....',
  },
  deviceRebootSuccessfully: {
    id: 'deviceRebootSuccessfully',
    defaultMessage: 'Device rebooted successfully',
  },
  deviceDeletedSuccessfully: {
    id: 'deviceDeletedSuccessfully',
    defaultMessage: 'Device deleted successfully!',
  },
  removing: {
    id: 'removing',
    defaultMessage: 'Removing....',
  },
  devicesDeleteSuccessfully: {
    id: 'devicesDeleteSuccessfully',
    defaultMessage: 'Device(s) deleted successfully',
  },
  devicesDeleteFailed: {
    id: 'devicesDeleteFailed',
    defaultMessage: 'Device(s) delete failed!',
  },
  successfullyCreated: {
    id: 'successfullyCreated',
    defaultMessage: 'Successfully created',
  },
  profileDeletedSuccessfully: {
    id: 'profileDeletedSuccessfully',
    defaultMessage: 'Profile deleted successfully',
  },
  profileArchivedSuccessfully: {
    id: 'profileArchivedSuccessfully',
    defaultMessage: 'Profile archived successfully',
  },
  profileUnarchivedSuccessfully: {
    id: 'profileUnarchivedSuccessfully',
    defaultMessage: 'Profile unarchived successfully',
  },
  profileActivatedSuccessfully: {
    id: 'profileActivatedSuccessfully',
    defaultMessage: 'Profile activated successfully',
  },
  successfullyProfileDuplicated: {
    id: 'successfullyProfileDuplicated',
    defaultMessage: 'Successfully profile duplicated',
  },
  successfullyCreatedRelease: {
    id: 'successfullyCreatedRelease',
    defaultMessage: 'Successfully created a release',
  },
  profilesWereSkipped: {
    id: 'profilesWereSkipped',
    defaultMessage: 'profiles were skipped',
  },
  toViewHistoryicalData: {
    id: 'toViewHistoryicalData',
    defaultMessage:
      'To view historical data the existing dashboard should be updated or a new dashboard created using the old device names.',
  },
  excludeArea: {
    id: 'excludeArea',
    defaultMessage: 'Exclude Area',
  },
  rectangleSetting: {
    id: 'rectangleSetting',
    defaultMessage: 'Rectangle Setting',
  },
  excludeROI: {
    id: 'excludeROI',
    defaultMessage: 'Exclude ROI',
  },
  angle: {
    id: 'angle',
    defaultMessage: 'Angle',
  },
  crossLine: {
    id: 'crossLine',
    defaultMessage: 'Cross Line',
  },
  regionType: {
    id: 'regionType',
    defaultMessage: 'Region Type',
  },
  areaCounts: {
    id: 'areaCounts',
    defaultMessage: 'Area counts',
  },
  sectionSetting: {
    id: 'sectionSetting',
    defaultMessage: 'Section Setting',
  },
  gridRows: {
    id: 'gridRows',
    defaultMessage: 'Grid Rows',
  },
  gridCols: {
    id: 'gridCols',
    defaultMessage: 'Grid Cols',
  },
  areaSetting: {
    id: 'areaSetting',
    defaultMessage: 'Area Setting',
  },
  areaNumber: {
    id: 'areaNumber',
    defaultMessage: 'Area Number',
  },
  color: {
    id: 'color',
    defaultMessage: 'Color',
  },
  areaName: {
    id: 'areaName',
    defaultMessage: 'Area Name',
  },
  members: {
    id: 'members',
    defaultMessage: 'Members',
  },
  support: {
    id: 'support',
    defaultMessage: 'Support',
  },
  switchWorkspace: {
    id: 'switchWorkspace',
    defaultMessage: 'Switch Workspace',
  },
  deviceStatus: {
    id: 'deviceStatus',
    defaultMessage: 'Device Status',
  },
  pageNotFound: {
    id: 'pageNotFound',
    defaultMessage: 'Page Not Found',
  },
  outdatedLink: {
    id: 'outdatedLink',
    defaultMessage: 'Looks like the link is outdated or broken.',
  },
  backToHome: {
    id: 'backToHome',
    defaultMessage: 'Back to Home',
  },
  skipTask: {
    id: 'skipTask',
    defaultMessage: 'Skip task',
  },
  setup: {
    id: 'setup',
    defaultMessage: 'Setup',
  },
  setupVisoSuite: {
    id: 'setupVisoSuite',
    defaultMessage: 'Setup Viso Suite',
  },
  completeTheseTask: {
    id: 'completeTheseTask',
    defaultMessage:
      'Complete these tasks to take full advantage of Viso Suite in your project.',
  },
  completeNow: {
    id: 'completeNow',
    defaultMessage: 'Complete now',
  },
  inviteTeamMembers: {
    id: 'inviteTeamMembers',
    defaultMessage: 'Invite team members',
  },
  sendYourTeamMemberAnInvitation: {
    id: 'sendYourTeamMemberAnInvitation',
    defaultMessage:
      'Send your team members an invitation to join the workspace and collaborate. Check the invitation list to see and manage pending invitations.',
  },
  enrollYourFirstDevice: {
    id: 'enrollYourFirstDevice',
    defaultMessage: 'Enroll your first device',
  },
  selectTheHardwarePlatform: {
    id: 'selectTheHardwarePlatform',
    defaultMessage:
      'Add a device to the workspace to which you can deploy the application. Enroll a physical computer by installing an image file in three simple steps. Or use a virtual device.',
  },
  addNewModuleNInstall: {
    id: 'addNewModuleNInstall',
    defaultMessage:
      'In your library, add a new module to your workspace. Install the modules required to create a demo application.',
  },
  createYourFirstApp: {
    id: 'createYourFirstApp',
    defaultMessage: 'Create your first application',
  },
  createDemoApplication: {
    id: 'createDemoApplication',
    defaultMessage:
      'Create a demo application in the Viso Builder with the installed modules.',
  },
  deployAProfile: {
    id: 'deployAProfile',
    defaultMessage: 'Deploy an application',
  },
  createANewProfileToDeploy: {
    id: 'createANewProfileToDeploy',
    defaultMessage:
      'Create a new profile with an application. Assign the profile to a device and it will be automatically deployed.',
  },
  setUpADashboard: {
    id: 'setUpADashboard',
    defaultMessage: 'Set up a dashboard',
  },
  deployedApplicationPeriodically: {
    id: 'deployedApplicationPeriodically',
    defaultMessage:
      'Deployed applications periodically sends data to your workspace. Set up the queries and configure charts to visualize timeseries data in your dashboard.',
  },
  completeTasks: {
    id: 'completeTasks',
    defaultMessage: 'Completed tasks',
  },
  primaryOwner: {
    id: 'primaryOwner',
    defaultMessage: 'Primary Owner',
  },
  getEtcher: {
    id: 'getEtcher',
    defaultMessage: 'Get Etcher',
  },
  downloadImage: {
    id: 'downloadImage',
    defaultMessage: 'Download image',
  },
  downloadImageFileAndSave: {
    id: 'downloadImageFileAndSave',
    defaultMessage:
      'First, download the image file and save it to your computer. It can be used to enroll multiple devices.',
  },
  bootableDrive: {
    id: 'bootableDrive',
    defaultMessage: 'Bootable USB drive',
  },
  createABootableUSB: {
    id: 'createABootableUSB',
    defaultMessage:
      'Second, create a bootable USB drive with the downloaded image file. Use a tool like Etcher.',
  },
  installToDevice: {
    id: 'installToDevice',
    defaultMessage: 'Install to device',
  },
  installTheOSToDevice: {
    id: 'installTheOSToDevice',
    defaultMessage:
      'Finally, Install the OS to the device. Establish internet connection and find it in devices after 10 minutes.',
  },
  emailIsInvalid: {
    id: 'emailIsInvalid',
    defaultMessage: 'Email is Invalid!',
  },
  showArchivedProfiles: {
    id: 'showArchivedProfiles',
    defaultMessage: 'Show archived profiles',
  },
  showAllProfiles: {
    id: 'showAllProfiles',
    defaultMessage: 'Show all profiles',
  },
  delete: {
    id: 'delete',
    defaultMessage: 'Delete',
  },
  videoFiles: {
    id: 'videoFiles',
    defaultMessage: 'Video Files',
  },
  uploadVideoFile: {
    id: 'uploadVideoFile',
    defaultMessage: 'Upload Video File',
  },
  videoName: {
    id: 'videoName',
    defaultMessage: 'Video Name',
  },
  videoFilemp4: {
    id: 'videoFilemp4',
    defaultMessage: 'Video file (mp4)',
  },
  fileDescription: {
    id: 'fileDescription',
    defaultMessage: 'File Description',
  },
  importVideoFile: {
    id: 'importVideoFile',
    defaultMessage: 'Import Video File',
  },
  videoFileInformation: {
    id: 'videoFileInformation',
    defaultMessage: 'Video File Information',
  },
  importVidoeDescription: {
    id: 'importVidoeDescription',
    defaultMessage:
      'Import a Video File to the gallery to use it as visual input in applications',
  },
  userManagement: {
    id: 'userManagement',
    defaultMessage: 'User Management',
  },
  cCancel: {
    id: 'cCancel',
    defaultMessage: 'Cancel',
  },
  deleteSubscription: {
    id: 'deleteSubscription',
    defaultMessage:
      'If you proceed, your subscription will be cancelled and related contents will be removed with immediate effect. This action is irreversible.',
  },
  important: {
    id: 'important',
    defaultMessage: 'Important',
  },
  typeCancel: {
    id: 'typeCancel',
    defaultMessage: 'Type “CANCEL”',
  },
  solutionDetails: {
    id: 'solutionDetails',
    defaultMessage: 'Solution Details',
  },
  enterYourSolutionDetails: {
    id: 'enterYourSolutionDetails',
    defaultMessage: 'Enter your solution details',
  },
  createNewSolutionSelector: {
    id: 'createNewSolutionSelector',
    defaultMessage: 'List a Solution to the Marketplace',
  },
  createNewSolutionSelectorDesc: {
    id: 'createNewSolutionSelectorDesc',
    defaultMessage:
      'Publish your Solutions to the Marketplace',
  },
  listModule: {
    id: 'listModule',
    defaultMessage: 'List Module',
  },
  listApplication: {
    id: 'listApplication',
    defaultMessage: 'List Application',
  },
  listModuleDesc: {
    id: 'listModuleDesc',
    defaultMessage:
      'Publish a custom Module from your Library as a Marketplace Solution.',
  },
  listApplicationDesc: {
    id: 'listApplicationDesc',
    defaultMessage:
      'Publish a custom Application from your Library as a Marketplace Solution.',
  },
  userGuide: {
    id: 'userGuide',
    defaultMessage: 'User Guide',
  },
  marketplacePage: {
    id: 'marketplacePage',
    defaultMessage: 'Marketplace Page ',
  },
  openInBuilder: {
    id: 'openInBuilder',
    defaultMessage: 'Open in Builder',
  },
  listNewModule: {
    id: 'listNewModule',
    defaultMessage: 'List new Module',
  },
  listNewApplication: {
    id: 'listNewApplication',
    defaultMessage: 'List new Application',
  },
  fileName: {
    id: 'fileName',
    defaultMessage: 'Filename',
  },
  fileURL: {
    id: 'fileURL',
    defaultMessage: 'File URL',
  },
  healthCheck: {
    id: 'healthCheck',
    defaultMessage: 'Health check',
  },
  lastScan: {
    id: 'lastScan',
    defaultMessage: 'Last Scan',
  },
  scanAgain: {
    id: 'scanAgain',
    defaultMessage: 'Scan again',
  },
  deviceHealthCheck: {
    id: 'deviceHealthCheck',
    defaultMessage: 'Device Health Check',
  },
  deviceAnalytics: {
    id: 'deviceAnalytics',
    defaultMessage: 'Device analytics',
  },
  editVideoInformation: {
    id: 'editVideoInformation',
    defaultMessage: 'Edit Video Information',
  },
  endpointsMinimum: {
    id: 'endpointsMinimum',
    defaultMessage: 'Endpoints Minimum',
  },
  planMustBeSelected: {
    id: 'planMustBeSelected',
    defaultMessage: 'Plan must be selected before creating the workspace.',
  },
  errorWhileSubmittingBilling: {
    id: 'errorWhileSubmittingBilling',
    defaultMessage: 'Error while submitting the billing',
  },
  failedToCreateAnImage: {
    id: 'failedToCreateAnImage',
    defaultMessage: 'Failed to create an image.',
  },
  deviceImageUnderCreation: {
    id: 'deviceImageUnderCreation',
    defaultMessage: 'The Device Image is under creation and will be ready soon.',
  },
  failedToFetchUrl: {
    id: 'failedToFetchUrl',
    defaultMessage: 'Failed to fetch the downloadable url',
  },
  devicesAssigned: {
    id: 'devicesAssigned',
    defaultMessage: 'Devices assigned',
  },
  errorCreatingRelease: {
    id: 'errorCreatingRelease',
    defaultMessage: 'Error creating a release',
  },
  passed: {
    id: 'passed',
    defaultMessage: 'Passed',
  },
  failed: {
    id: 'failed',
    defaultMessage: 'Failed',
  },
  devicesSync: {
    id: 'devicesSync',
    defaultMessage: 'Devices synced failed!',
  },
  failedToDeleteProfile: {
    id: 'failedToDeleteProfile',
    defaultMessage: 'Failed to delete the profile',
  },
  failedToArchiveProfile: {
    id: 'failedToArchiveProfile',
    defaultMessage: 'Failed to archive the profile',
  },
  failedToUnarchiveProfile: {
    id: 'failedToUnarchiveProfile',
    defaultMessage: 'Failed to unarchive the profile',
  },
  failedToDuplicateProfile: {
    id: 'failedToDuplicateProfile',
    defaultMessage: 'Failed to duplicate a profile',
  },
  newProfileAdded: {
    id: 'newProfileAdded',
    defaultMessage: 'A new profile is added',
  },
  failedToActivateProfile: {
    id: 'failedToActivateProfile',
    defaultMessage: 'Failed to activate the profile',
  },
  failedToRemoveImages: {
    id: 'failedToRemoveImages',
    defaultMessage: 'Failed to remove the image(s)',
  },
  available: {
    id: 'available',
    defaultMessage: 'Available',
  },
  terminal: {
    id: 'terminal',
    defaultMessage: 'Terminal',
  },
  activated: {
    id: 'activated',
    defaultMessage: 'Activated',
  },
  supportedVisoOSDetected: {
    id: 'supportedVisoOSDetected',
    defaultMessage: 'Supported visoOS version detected.',
  },
  noUnderVoltage: {
    id: 'noUnderVoltage',
    defaultMessage: 'No under voltage events have been detected.',
  },
  overMemoryAvailable: {
    id: 'overMemoryAvailable',
    defaultMessage: 'Over 75% of total memory is currently available.',
  },
  noAbnormalTemperature: {
    id: 'noAbnormalTemperature',
    defaultMessage: 'No abnormal CPU temperature detected.',
  },
  allContainersRunning: {
    id: 'allContainersRunning',
    defaultMessage: 'All containers are running as expected',
  },
  supervisorRunning: {
    id: 'supervisorRunning',
    defaultMessage: 'Supervisor is running and healthy.',
  },
  noNetworkIssues: {
    id: 'noNetworkIssues',
    defaultMessage: 'No network issues detected.',
  },
  diskspaceAvailable: {
    id: 'diskspaceAvailable',
    defaultMessage: 'More than 30% diskspace is available.',
  },
  allServicesRunning: {
    id: 'allServicesRunning',
    defaultMessage: 'All services are running and healthy.',
  },
  deviceTimeSynchronized: {
    id: 'deviceTimeSynchronized',
    defaultMessage: 'Device time is synchronized with the NTP server.',
  },
  noCameraIssues: {
    id: 'noCameraIssues',
    defaultMessage: 'No camera issues have been detected.',
  },
  operatingSystem: {
    id: 'operatingSystem',
    defaultMessage: 'Operating System',
  },
  voltage: {
    id: 'voltage',
    defaultMessage: 'Voltage',
  },
  memory: {
    id: 'memory',
    defaultMessage: 'Memory',
  },
  temperature: {
    id: 'temperature',
    defaultMessage: 'Temperature',
  },
  containerEngine: {
    id: 'containerEngine',
    defaultMessage: 'Container Engine',
  },
  supervisor: {
    id: 'supervisor',
    defaultMessage: 'Supervisor',
  },
  networking: {
    id: 'networking',
    defaultMessage: 'Networking',
  },
  diskSpace: {
    id: 'diskSpace',
    defaultMessage: 'Disk Space',
  },
  restartingService: {
    id: 'restartingService',
    defaultMessage: 'Restarting Service',
  },
  timeSync: {
    id: 'timeSync',
    defaultMessage: 'Time Sync',
  },
  cameraHealth: {
    id: 'cameraHealth',
    defaultMessage: 'Camera Health',
  },
  containerStatus: {
    id: 'containerStatus',
    defaultMessage: 'Container Status',
  },
  scanCompletedSuccessfully: {
    id: 'scanCompletedSuccessfully',
    defaultMessage: 'Health scan started',
  },
  failedToUnassignProfile: {
    id: 'failedToUnassignProfile',
    defaultMessage: 'Failed to unassign profile',
  },
  applicationRemovedSuccessfully: {
    id: 'applicationRemovedSuccessfully',
    defaultMessage: 'Application removed successfully',
  },
  debugging: {
    id: 'debugging',
    defaultMessage: 'Debugging',
  },
  toolsToDebug: {
    id: 'toolsToDebug',
    defaultMessage: 'Tools to debug this device.',
  },
  failedToFetchTheProfiles: {
    id: 'failedToFetchTheProfiles',
    defaultMessage: 'Failed to fetch the profiles',
  },
  upgradeModuleVersion: {
    id: 'upgradeModuleVersion',
    defaultMessage: 'Upgrade module version',
  },
  invalidFileType: {
    id: 'invalidFileType',
    defaultMessage: 'Invalid file type. Supports only mp4 videos.',
  },
  maximumFileSize: {
    id: 'maximumFileSize',
    defaultMessage: 'Maximum file size exceeded. Video size larger than 300 mb.',
  },
  mustContainOnly: {
    id: 'mustContainOnly',
    defaultMessage: 'Must contain only A-Z 0-9 _-',
  },
  unableToDeleteImage: {
    id: 'unableToDeleteImage',
    defaultMessage: 'Unable to delete the image',
  },
  linkNodeToContainer: {
    id: 'linkNodeToContainer',
    defaultMessage: 'Link a node to a container',
  },
  moduleInstalled: {
    id: 'moduleInstalled',
    defaultMessage: 'Module installed successfully.',
  },
  moduleUpdated: {
    id: 'moduleUpdated',
    defaultMessage: 'Module updated successfully.',
  },
  moduleInstallFailed: {
    id: 'moduleInstallFailed',
    defaultMessage: 'Module install failed.',
  },
  moduleUpdateFailed: {
    id: 'moduleUpdateFailed',
    defaultMessage: 'Module update failed.',
  },
  moduleRemoved: {
    id: 'moduleRemoved',
    defaultMessage: 'Module removed successfully.',
  },
  failedToRemoveModule: {
    id: 'failedToRemoveModule',
    defaultMessage: 'Failed to remove the module.',
  },
  setPricingSolution: {
    id: 'setPricingSolution',
    defaultMessage: 'Set the Pricing of your Solution',
  },
  makeSolutionFree: {
    id: 'makeSolutionFree',
    defaultMessage: 'Make your solution available for Free',
  },
  clickToCreatePrice: {
    id: 'clickToCreatePrice',
    defaultMessage: 'Click to create a Price',
  },
  monthlyPrice: {
    id: 'monthlyPrice',
    defaultMessage: 'Monthly Price',
  },
  setFeeMonthly: {
    id: 'setFeeMonthly',
    defaultMessage: 'Set the fee that applies on monthly payment.',
  },
  seeFeeYearly: {
    id: 'seeFeeYearly',
    defaultMessage: 'Set the fee that applies on yearly payment.',
  },
  yearlyPrice: {
    id: 'yearlyPrice',
    defaultMessage: 'Yearly Price',
  },
  viewUserGuide: {
    id: 'viewUserGuide',
    defaultMessage: 'View user guide',
  },
  signInInstall: {
    id: 'signInInstall',
    defaultMessage: 'Sign in to install',
  },
  reviews: {
    id: 'reviews',
    defaultMessage: 'Reviews',
  },
  moduleOverview: {
    id: 'moduleOverview',
    defaultMessage: 'Module Overview',
  },
  contact: {
    id: 'contact',
    defaultMessage: 'Contact',
  },
  emailSupport: {
    id: 'emailSupport',
    defaultMessage: 'Email support',
  },
  companyWebsite: {
    id: 'companyWebsite',
    defaultMessage: 'Company website',
  },
  supportWebsite: {
    id: 'supportWebsite',
    defaultMessage: 'Support website',
  },
  integrationRequires: {
    id: 'integrationRequires',
    defaultMessage: 'is provided by a third-party and is governed by separate',
  },
  moreModulesIn: {
    id: 'moreModulesIn',
    defaultMessage: 'More Modules',
  },
  confirmDelete: {
    id: 'confirmDelete',
    defaultMessage: 'Confirm Delete',
  },
  solutionInReview: {
    id: 'solutionInReview',
    defaultMessage: 'Solution is in review',
  },
  applicationInReview: {
    id: 'applicationInReview',
    defaultMessage: 'Your application is being reviewed. Please try back later.',
  },
  nameOfSolution: {
    id: 'nameOfSolution',
    defaultMessage: 'Name of Solution',
  },
  addCategory: {
    id: 'addCategory',
    defaultMessage: 'Add a category',
  },
  solutionLogo: {
    id: 'solutionLogo',
    defaultMessage: 'Solution Logo',
  },
  completeListing: {
    id: 'completeListing',
    defaultMessage: 'Complete Listing',
  },
  confirmRequirements: {
    id: 'confirmRequirements',
    defaultMessage: 'Confirm requirements',
  },
  submitSolutionDesc: {
    id: 'submitSolutionDesc',
    defaultMessage: 'Submit the solution to be publicly listed to the marketplace. Please make sure the submission is in line with the guidelines and fulfils the requirements. After your solution was listed, you can manage it in the your partner portal.',
  },
  byClicking: {
    id: 'byClicking',
    defaultMessage: 'By clicking',
  },
  youAgreeTo: {
    id: 'youAgreeTo',
    defaultMessage: 'you agree to',
  },
  submitForReview: {
    id: 'submitForReview',
    defaultMessage: 'Submit for Review',
  },
  saveAsDraft: {
    id: 'saveAsDraft',
    defaultMessage: 'Save as draft',
  },
  ourVendorPolicyAnd: {
    id: 'ourVendorPolicyAnd',
    defaultMessage: 'our Vendor Policy and',
  },
  solutionPricing: {
    id: 'solutionPricing',
    defaultMessage: 'Solution Pricing',
  },
  everyTierIsFilled: {
    id: 'everyTierIsFilled',
    defaultMessage: 'Every tier is filled up at its pricing before the next tier is reached.',
  },
  addAnotherTier: {
    id: 'addAnotherTier',
    defaultMessage: 'Add another tier',
  },
  pricingSettings: {
    id: 'pricingSettings',
    defaultMessage: 'Pricing Settings',
  },
  free: {
    id: 'free',
    defaultMessage: 'Free',
  },
  cancelSubmissionDesc: {
    id: 'cancelSubmissionDesc',
    defaultMessage: 'Are you sure you want to cancel the submission?',
  },
  archiveSolutionDesc: {
    id: 'archiveSolutionDesc',
    defaultMessage: 'Are you sure you want to archive the solution?',
  },
  unpublishSolutionDesc: {
    id: 'unpublishSolutionDesc',
    defaultMessage: 'Are you sure you want to unpublish the solution?',
  },
  deleteSolutionDesc: {
    id: 'deleteSolutionDesc',
    defaultMessage: 'Are you sure you want to delete the solution?',
  },
  addAVersion: {
    id: 'addAVersion',
    defaultMessage: 'Add a Version',
  },
  selectFromLibrary: {
    id: 'selectFromLibrary',
    defaultMessage: 'Select from Library',
  },
  uninstallingTheModule: {
    id: 'uninstallingTheModule',
    defaultMessage: 'Uninstalling the Module',
  },
  workEmail: {
    id: 'workEmail',
    defaultMessage: 'Work Email',
  },
  marketplaceInstalled: {
    id: 'marketplaceInstalled',
    defaultMessage: 'Marketplace installed successfully',
  },
  marketplaceInstallFailed: {
    id: 'marketplaceInstallFailed',
    defaultMessage: 'Marketplace installed failed',
  },
  marketplaceRemoved: {
    id: 'marketplaceRemoved',
    defaultMessage: 'Marketplace removed successfully',
  },
  passwordResetSuccessfully: {
    id: 'passwordResetSuccessfully',
    defaultMessage: 'Password changed successfully',
  },
  installingMarketplaceToWorkspace: {
    id: 'installingMarketplaceToWorkspace',
    defaultMessage: 'to your workspace',
  },
  errorOpeningTheNodeEditor: {
    id: 'errorOpeningTheNodeEditor',
    defaultMessage: 'Viso builder is not available now. Please try again later.',
  },
  solutionUpdatedSuccessfully: {
    id: 'solutionUpdatedSuccessfully',
    defaultMessage: 'Solution updated successfully',
  },
  solutionLogoUploaded: {
    id: 'solutionLogoUploaded',
    defaultMessage: 'Solution logo uploaded.',
  },
  solutionSubmittedSuccessfully: {
    id: 'solutionSubmittedSuccessfully',
    defaultMessage: 'Solution submitted successfully',
  },
  partnerApplicationSubmitted: {
    id: 'partnerApplicationSubmitted',
    defaultMessage: 'Partner application submitted.',
  },
  priceSetAsDefault: {
    id: 'priceSetAsDefault',
    defaultMessage: 'Price set as default.',
  },
  solutionDeletedSuccessfully: {
    id: 'solutionDeletedSuccessfully',
    defaultMessage: 'Solution deleted successfully',
  },
  submissionCancelledSuccessfully: {
    id: 'submissionCancelledSuccessfully',
    defaultMessage: 'Submission cancelled successfully',
  },
  failedToFetchTHeSSHKeys: {
    id: 'failedToFetchTHeSSHKeys',
    defaultMessage: 'Failed to fetch the SSH Keys',
  },
  successfullyCreatedAPublicContainer: {
    id: 'successfullyCreatedAPublicContainer',
    defaultMessage: 'Successfully created a public docker container.',
  },
  successfullyCreatedAPrivateDockerContainer: {
    id: 'successfullyCreatedAPrivateDockerContainer',
    defaultMessage: 'Successfully created a private docker container.',
  },
  applicationImported: {
    id: 'applicationImported',
    defaultMessage: 'Application imported successfully.',
  },
  commitPushedSuccessfully: {
    id: 'commitPushedSuccessfully',
    defaultMessage: 'Commit pushed successfully.',
  },
  videoImported: {
    id: 'videoImported',
    defaultMessage: 'Video imported successfully.',
  },
  updatedSuccessfully: {
    id: 'updatedSuccessfully',
    defaultMessage: 'Updated successfully',
  },
  newApplicationCreated: {
    id: 'newApplicationCreated',
    defaultMessage: 'Application created successfully',
  },
  newVideoCreated: {
    id: 'newVideoCreated',
    defaultMessage: 'New video created successfully.',
  },
  videoUpdated: {
    id: 'videoUpdated',
    defaultMessage: 'Video updated successfully.',
  },
  videoDeleted: {
    id: 'videoDeleted',
    defaultMessage: 'Video deleted successfully.',
  },
  packageAdded: {
    id: 'packageAdded',
    defaultMessage: 'Package added successfully.',
  },
  videosAdded: {
    id: 'videosAdded',
    defaultMessage: 'Video added successfully.',
  },
  dashboardAdded: {
    id: 'dashboardAdded',
    defaultMessage: 'Dashboard added successfully.',
  },
  moduleDependencies: {
    id: 'moduleDependencies',
    defaultMessage: 'Module Dependencies',
  },
  showInstruction: {
    id: 'showInstruction',
    defaultMessage: 'Show instruction',
  },
  linkContainerToNode: {
    id: 'linkContainerToNode',
    defaultMessage: 'Link a container to a node',
  },
  pricesToWorkspaceSubscribe: {
    id: 'pricesToWorkspaceSubscribe',
    defaultMessage: 'Prices to which workspaces can subscribe to.',
  },
  demoVideos: {
    id: 'demoVideos',
    defaultMessage: 'Demo Videos',
  },
  videoFilesThatWillBeImported: {
    id: 'videoFilesThatWillBeImported',
    defaultMessage: 'Video files that will be imported to the workspace library.',
  },
  demoVideoFiles: {
    id: 'demoVideoFiles',
    defaultMessage: 'Demo Video Files',
  },
  setAsDefault: {
    id: 'setAsDefault',
    defaultMessage: 'Set as Default',
  },
  setAPrice: {
    id: 'setAPrice',
    defaultMessage: 'Set a price',
  },
  setFee: {
    id: 'setFee',
    defaultMessage: 'Set the fee that applies on payment.',
  },
  developer: {
    id: 'developer',
    defaultMessage: 'Developer',
  },
  perUnit: {
    id: 'perUnit',
    defaultMessage: 'One-time payment',
  },
  perEndpoint: {
    id: 'perEndpoint',
    defaultMessage: 'per endpoint',
  },
  deleteDevice: {
    id: 'deleteDevice',
    defaultMessage: 'Remove device',
  },
  supportInformation: {
    id: 'supportInformation',
    defaultMessage: 'Support Information',
  },
  startSession: {
    id: 'startSession',
    defaultMessage: 'Start Session',
  },
  workspaceCustomization: {
    id: 'workspaceCustomization',
    defaultMessage: 'Workspace Customization',
  },
  addFirstDevices: {
    id: 'addFirstDevices',
    defaultMessage: 'Add First Device',
  },
  inviteNewTeamMembers: {
    id: 'inviteNewTeamMembers',
    defaultMessage: 'Invite new team members',
  },
  inviteOthersToJoin: {
    id: 'inviteOthersToJoin',
    defaultMessage: 'Invite others to join the workspace and collaborate. People you invite will receive an email to join.',
  },
  invitePeople: {
    id: 'invitePeople',
    defaultMessage: 'Invite People',
  },
  devicesSyncedSuccessfully: {
    id: 'devicesSyncedSuccessfully',
    defaultMessage: 'Devices synced successfully!',
  },
  devicesSyncedFailed: {
    id: 'devicesSyncedFailed',
    defaultMessage: 'Devices synced failed!',
  },
  profileRemovedSuccessfully: {
    id: 'profileRemovedSuccessfully',
    defaultMessage: 'Profile removed successfully.',
  },
  failedCreatingARelease: {
    id: 'failedCreatingARelease',
    defaultMessage: 'Failed creating a release',
  },
  confirmRemoveProfile: {
    id: 'confirmRemoveProfile',
    defaultMessage: 'Confirm Remove Profile',
  },
  cWorkspace: {
    id: 'cWorkspace',
    defaultMessage: 'Workspace',
  },
  keepPlan: {
    id: 'keepPlan',
    defaultMessage: 'Keep plan',
  },
  sEndpoints: {
    id: 'sEndpoints',
    defaultMessage: 'endpoints',
  },
  for: {
    id: 'for',
    defaultMessage: 'for',
  },
  monthlyPerEndpoint: {
    id: 'monthlyPerEndpoint',
    defaultMessage: 'monthly per endpoint',
  },
  isTheMinimumAmount: {
    id: 'isTheMinimumAmount',
    defaultMessage: 'is the minimum amount',
  },
  companySize: {
    id: 'companySize',
    defaultMessage: 'Company Size',
  },
  companyIndustry: {
    id: 'companyIndustry',
    defaultMessage: 'Industry',
  },
  viewRequiredModules: {
    id: 'viewRequiredModules',
    defaultMessage: 'View required Modules',
  },
  proceed: {
    id: 'proceed',
    defaultMessage: 'Proceed',
  },
  loggingOutToPurchase: {
    id: 'loggingOutToPurchase',
    defaultMessage: 'Please confirm your login to proceed.',
  },
  intallingFromMarketplaceCompleted: {
    id: 'intallingFromMarketplaceCompleted',
    defaultMessage: 'Installing from Marketplace completed',
  },
  congratulationsListingInstalled: {
    id: 'congratulationsListingInstalled',
    defaultMessage: 'Congratulations! The item was successfully installed with your workspace and is now ready to use.',
  },
  viewInSubscriptions: {
    id: 'viewInSubscriptions',
    defaultMessage: 'View in Subscriptions',
  },
  currentApplicationRequiresTheFollowingModules: {
    id: 'currentApplicationRequiresTheFollowingModules',
    defaultMessage: 'The application requires the following modules.',
  },
  contrast: {
    id: 'contrast',
    defaultMessage: 'Contrast',
  },
  selectedUser: {
    id: 'selectedUser',
    defaultMessage: 'selected user(s)?',
  },
  sureActivate: {
    id: 'sureActivate',
    defaultMessage: 'Are you sure to activate the',
  },
  sureDeactivate: {
    id: 'sureDeactivate',
    defaultMessage: 'Are you sure to deactivate the',
  },
  sureDelete: {
    id: 'sureDelete',
    defaultMessage: 'Are you sure to delete the',
  },
  sureDelete: {
    id: 'sureDelete',
    defaultMessage: 'Are you sure to delete the'
  },
  sureArchive: {
    id: 'sureArchive',
    defaultMessage: 'Are you sure to archive the',
  },
  sureAssignRole: {
    id: 'sureAssignRole',
    defaultMessage: 'Are you sure to assign the role',
  },
  toThe: {
    id: 'toThe',
    defaultMessage: 'to the',
  },
  cPrimaryOwner: {
    id: 'cPrimaryOwner',
    defaultMessage: 'PRIMARY OWNER',
  },
  confirmCancelInvitations: {
    id: 'confirmCancelInvitations',
    defaultMessage: 'Confirm Cancel Invitations',
  },
  sureCancelInvitation: {
    id: 'sureCancelInvitation',
    defaultMessage: 'Are you sure to cancel the invitation to the',
  },
  sureResendInvitation: {
    id: 'sureResendInvitation',
    defaultMessage: 'Are you sure to resend the invitation to the',
  },
  confirmResendInvitations: {
    id: 'confirmResendInvitations',
    defaultMessage: 'Confirm Resend Invitations',
  },
  workspaceInvitations: {
    id: 'workspaceInvitations',
    defaultMessage: 'Workspace Invitations',
  },
  rolePermissions: {
    id: 'rolePermissions',
    defaultMessage: 'Role Permissions',
  },
  permissionsSavedSuccessfully: {
    id: 'permissionsSavedSuccessfully',
    defaultMessage: 'Permissions saved successfully',
  },
  roleInformation: {
    id: 'roleInformation',
    defaultMessage: 'Role Information',
  },
  solutionPrices: {
    id: 'solutionPrices',
    defaultMessage: 'Solution Prices',
  },
  noDescriptionAdded: {
    id: 'noDescriptionAdded',
    defaultMessage: 'No description added',
  },
  applicationOverview: {
    id: 'applicationOverview',
    defaultMessage: 'Application Overview',
  },
  moreApplicationsIn: {
    id: 'moreApplicationsIn',
    defaultMessage: 'More Applications',
  },
  marketingInformation: {
    id: 'marketingInformation',
    defaultMessage: 'Marketing Information',
  },
  keywords: {
    id: 'keywords',
    defaultMessage: 'Keywords',
  },
  addKeyword: {
    id: 'addKeyword',
    defaultMessage: 'Add keyword',
  },
  filetype: {
    id: 'filetype',
    defaultMessage: 'Filetype',
  },
  repeatVideo: {
    id: 'repeatVideo',
    defaultMessage: 'Repeat Video',
  },
  deleteAll: {
    id: 'deleteAll',
    defaultMessage: 'Delete all',
  },
  remainingTasks: {
    id: 'remainingTasks',
    defaultMessage: 'Remaining tasks',
  },
  congratulationsSetupComplete: {
    id: 'congratulationsSetupComplete',
    defaultMessage: 'Congratulations, the Setup is now complete',
  },
  clickToRemove: {
    id: 'clickToRemove',
    defaultMessage: 'Click to remove',
  },
  timezone: {
    id: 'timezone',
    defaultMessage: 'Timezone',
  },
  preferences: {
    id: 'preferences',
    defaultMessage: 'Preferences',
  },
  workspaceChangesSaved: {
    id: 'workspaceChangesSaved',
    defaultMessage: 'Workspace changes saved.',
  },
  logoUploadedSuccessfully: {
    id: 'logoUploadedSuccessfully',
    defaultMessage: 'Logo uploaded successfully.',
  },
  logoRemovedSuccessfully: {
    id: 'logoRemovedSuccessfully',
    defaultMessage: 'Logo removed successfully.',
  },
  workspaeRecoveredSuccessfully: {
    id: 'workspaeRecoveredSuccessfully',
    defaultMessage: 'Workspace recovered successfully.',
  },
  workspaceSubscriptionCancelled: {
    id: 'workspaceSubscriptionCancelled',
    defaultMessage: 'Workspace subscription cancelled. Logging out..',
  },
  multipleUser: {
    id: 'multipleUser',
    defaultMessage: 'Multiple User',
  },
  createNewPassword: {
    id: 'createNewPassword',
    defaultMessage: 'Create a new password for your account',
  },
  getStartedImmediatelySubscription: {
    id: 'getStartedImmediatelySubscription',
    defaultMessage:
      'Get started immediately and access all features offered by this subscription. You’ll be automatically charged the monthly cost. Manage the subscription directly in the Billing Center of your Workspace.',
  },
  fullName: {
    id: 'fullName',
    defaultMessage: 'Full name',
  },
  createYourFirstVideoDesc: {
    id: 'createYourFirstVideoDesc',
    defaultMessage: 'Add your first Video here to be used as a video source in your Applications',
  },
  downloadingTheDeviceImage: {
    id: 'downloadingTheDeviceImage',
    defaultMessage: 'Downloading the device image...',
  },
  interfaceLanguage: {
    id: 'interfaceLanguage',
    defaultMessage: 'Interface Language',
  },
  vatNumber: {
    id: 'vatNumber',
    defaultMessage: 'VAT Number',
  },
  yourPartnerApplicationUnderReview: {
    id: 'yourPartnerApplicationUnderReview',
    defaultMessage: 'Your partner application is under review',
  },
  currentlyReviewing: {
    id: 'currentlyReviewing',
    defaultMessage: 'The Viso Team is currently reviewing your application. We will notify you.',
  },
  feelFreeToReachOut: {
    id: 'feelFreeToReachOut',
    defaultMessage: 'If you have any questions, feel free to reach out.',
  },
  serviceCurrentlyDeactivated: {
    id: 'serviceCurrentlyDeactivated',
    defaultMessage: 'This service is currently deactivated.',
  },
  adminDeactivatedService: {
    id: 'adminDeactivatedService',
    defaultMessage: 'Admin deactivated this service.',
  },
  signUpToBecomePartner: {
    id: 'signUpToBecomePartner',
    defaultMessage: 'Sign up to become a Viso Partner',
  },
  applicationRejected: {
    id: 'applicationRejected',
    defaultMessage: 'Your application has been rejected. Please re-send again',
  },
  shareAndSellModuleAndApplications: {
    id: 'shareAndSellModuleAndApplications',
    defaultMessage: 'Share and sell modules and applications from your library to the Marketplace.',
  },
  applyNow: {
    id: 'applyNow',
    defaultMessage: 'Apply now',
  },
  readThe: {
    id: 'readThe',
    defaultMessage: 'Read the',
  },
  andAgreeToThe: {
    id: 'andAgreeToThe',
    defaultMessage: 'and agree to the',
  },
  partnerRequirements: {
    id: 'partnerRequirements',
    defaultMessage: 'By clicking “apply now” you agree to the',
  },
  partnerAgreement: {
    id: 'partnerAgreement',
    defaultMessage: 'Partner Agreement',
  },
  companyAddress: {
    id: 'companyAddress',
    defaultMessage: 'Company Address',
  },
  logo: {
    id: 'logo',
    defaultMessage: 'Logo',
  },
  dragFileHere: {
    id: 'dragFileHere',
    defaultMessage: 'Drag file here',
  },
  oneTimeFee: {
    id: 'oneTimeFee',
    defaultMessage: 'One Time Fee',
  },
  one_TimeFee: {
    id: 'one_TimeFee',
    defaultMessage: 'One-Time Fee',
  },
  setFeeChargedOnPurchase: {
    id: 'setFeeChargedOnPurchase',
    defaultMessage: 'Set the fee that will be charged on purchase.',
  },
  failedToAddPrice: {
    id: 'failedToAddPrice',
    defaultMessage: 'Failed to add the price.',
  },
  defineMonthlyAndYearlyPrice: {
    id: 'defineMonthlyAndYearlyPrice',
    defaultMessage: 'Define the monthly and yearly marketplace prices of the module.',
  },
  moduleLogo: {
    id: 'moduleLogo',
    defaultMessage: 'Module Logo',
  },
  applicationLogo: {
    id: 'applicationLogo',
    defaultMessage: 'Application Logo',
  },
  oneTime: {
    id: 'oneTime',
    defaultMessage: 'One Time',
  },
  createYourOwnPrice: {
    id: 'createYourOwnPrice',
    defaultMessage: 'Create your own price',
  },
  addDynamicPrices: {
    id: 'addDynamicPrices',
    defaultMessage: 'Add dynamic prices for your solution',
  },
  createFirstPrice: {
    id: 'createFirstPrice',
    defaultMessage: 'Create first price',
  },
  addNewPrice: {
    id: 'addNewPrice',
    defaultMessage: 'Add new price',
  },
  setPricingModule: {
    id: 'setPricingModule',
    defaultMessage: 'Set the Pricing of your Module',
  },
  setPricingApplication: {
    id: 'setPricingApplication',
    defaultMessage: 'Set the Pricing of your Application',
  },
  defineMarketplacePriceApplication: {
    id: 'defineMarketplacePriceApplication',
    defaultMessage: 'Define the marketplace price of the application you are about to list.',
  },
  solutionDescription: {
    id: 'solutionDescription',
    defaultMessage: 'Solution Description',
  },
  makeModuleFree: {
    id: 'makeModuleFree',
    defaultMessage: 'Make your module available for Free',
  },
  makeApplicationFree: {
    id: 'makeApplicationFree',
    defaultMessage: 'Make your application available for Free',
  },
  pricingTiers: {
    id: 'pricingTiers',
    defaultMessage: 'Pricing Tiers',
  },
  pleaseProvideInformationAboutOrg: {
    id: 'pleaseProvideInformationAboutOrg',
    defaultMessage: 'Please provide information about your organization.',
  },
  sPricing: {
    id: 'sPricing',
    defaultMessage: 'pricing',
  },
  partnerApplication: {
    id: 'partnerApplication',
    defaultMessage: 'Partner Application',
  },
  vendorInformationComplete: {
    id: 'vendorInformationComplete',
    defaultMessage: 'Vendor Information Complete',
  },
  allInformationRequiredIsNowComplete: {
    id: 'allInformationRequiredIsNowComplete',
    defaultMessage: 'All Information required is now complete. Please accept the terms',
  },
  toSubmitApplication: {
    id: 'toSubmitApplication',
    defaultMessage: 'to submit the application.',
  },
  iAcceptTheViso: {
    id: 'iAcceptTheViso',
    defaultMessage: 'I accept the viso.ai',
  },
  vendorAgreement: {
    id: 'vendorAgreement',
    defaultMessage: 'Vendor Agreement',
  },
  submit: {
    id: 'submit',
    defaultMessage: 'Submit',
  },
  deviceUpdated: {
    id: 'deviceUpdated',
    defaultMessage: 'Device Updated',
  },
  unassigned: {
    id: 'unassigned',
    defaultMessage: 'Unassigned',
  },
  currentModuleApplications: {
    id: 'currentModuleApplications',
    defaultMessage: 'The current module is part of the following applications.',
  },
  vendorPolicy: {
    id: 'vendorPolicy',
    defaultMessage: 'Vendor Policy',
  },
  marketplacePartnerAgreement: {
    id: 'marketplacePartnerAgreement',
    defaultMessage: 'Marketplace Partner Agreement',
  },
  freePriceTierAlreadyAdded: {
    id: 'freePriceTierAlreadyAdded',
    defaultMessage: 'Free price tier already added',
  },
  dashboardId: {
    id: 'dashboardId',
    defaultMessage: 'Dashboard Id',
  },
  setAsLatestVersion: {
    id: 'setAsLatestVersion',
    defaultMessage: 'Set as latest version',
  },
  newVersionAdded: {
    id: 'newVersionAdded',
    defaultMessage: 'Successfully set as active version',
  },
  onCancelSubscriptionDesc: {
    id: 'onCancelSubscriptionDesc',
    defaultMessage: 'If you proceed, your subscription will be cancelled and related contents will be removed with immediate effect. This action is irreversible.',
  },
  cancellingSubscription: {
    id: 'cancellingSubscription',
    defaultMessage: 'Cancelling the subscription',
  },
  copyFileURL: {
    id: 'copyFileURL',
    defaultMessage: 'Copy File URL',
  },
  editInformation: {
    id: 'editInformation',
    defaultMessage: 'Edit Information',
  },
  removeFile: {
    id: 'removeFile',
    defaultMessage: 'Remove File',
  },
  exitFullscreen: {
    id: 'exitFullscreen',
    defaultMessage: 'Exit Fullscreen',
  },
  fullScreen: {
    id: 'fullScreen',
    defaultMessage: 'Full Screen',
  },
  importType: {
    id: 'importType',
    defaultMessage: 'Import Type',
  },
  sourceURL: {
    id: 'sourceURL',
    defaultMessage: 'Source URL',
  },
  invalidURL: {
    id: 'invalidURL',
    defaultMessage: 'Invalid URL',
  },
  installing: {
    id: 'installing',
    defaultMessage: 'Installing',
  },
  installed: {
    id: 'installed',
    defaultMessage: 'Installed',
  },
  removeApplicationFromLibrary: {
    id: 'removeApplicationFromLibraryy',
    defaultMessage:
      'Are you sure you want to remove the application?',
  },
  installedAll: {
    id: 'installedAll',
    defaultMessage: 'Install all',
  },
  installModule: {
    id: 'installModule',
    defaultMessage: 'Install Module',
  },
  installApplication: {
    id: 'installApplication',
    defaultMessage: 'Install Application',
  },
  toYourWorkspace: {
    id: 'toYourWorkspace',
    defaultMessage: 'to your Workspace',
  },
  dashboardDesc: {
    id: 'dashboardDesc',
    defaultMessage: 'Attached dashboards that will be imported to the workspace.',
  },
  price: {
    id: 'price',
    defaultMessage: 'Price',
  },
  getStartedApplicationSubscription: {
    id: 'getStartedApplicationSubscription',
    defaultMessage: 'Get started immediately and use the Application as a template from your workspace library. Build your application using this application template.',
  },
  confirmYourSubscription: {
    id: 'confirmYourSubscription',
    defaultMessage: 'Confirm your Subscription',
  },
  removePrice: {
    id: 'removePrice',
    defaultMessage: 'Remove Price',
  },
  pleaseConfirmToProceed: {
    id: 'pleaseConfirmToProceed',
    defaultMessage: 'Please confirm to proceed',
  },
  setMonthlyDefault: {
    id: 'setMonthlyDefault',
    defaultMessage: 'Set as Monthly Default',
  },
  setYearlyDefault: {
    id: 'setYearlyDefault',
    defaultMessage: 'Set as Yearly Default',
  },
  deletePrice: {
    id: 'deletePrice',
    defaultMessage: 'Delete price',
  },
  successfullyVideoDelete: {
    id: 'successfullyVideoDelete',
    defaultMessage: 'Successfully video deleted.',
  },
  confirmToRemove: {
    id: 'confirmToRemove',
    defaultMessage: 'Confirm to remove',
  },
  forcefullyOpenEditor: {
    id: 'forcefullyOpenEditor',
    defaultMessage: 'Open node editor anyways',
  },
  solutionSuccessfullyArchived: {
    id: 'solutionSuccessfullyArchived',
    defaultMessage: 'Solution archived successfully',
  },
  unpublishSolutionSuccess: {
    id: 'unpublishSolutionSuccess',
    defaultMessage: 'Solution unpublished',
  },
  visoBuilderIsLocked: {
    id: 'visoBuilderIsLocked',
    defaultMessage: 'The Viso Builder is locked',
  },
  currentlyEditingApplications: {
    id: 'currentlyEditingApplications',
    defaultMessage: 'is currently editing applications. Do you want to take over? Unsaved changes will be lost.',
  },
  takeOver: {
    id: 'takeOver',
    defaultMessage: 'Take over',
  },
  youAreAboutToMakeChanges: {
    id: 'youAreAboutToMakeChanges',
    defaultMessage: 'You are about to make changes to the solution. Please confirm to proceed and save your changes.',
  },
  confirmChanges: {
    id: 'confirmChanges',
    defaultMessage: 'Confirm Changes',
  },
  cropRoi: {
    id: 'cropRoi',
    defaultMessage: 'Crop ROI',
  },
  viewInLibrary: {
    id: 'viewInLibrary',
    defaultMessage: 'View in Library',
  },
  installNow: {
    id: 'installNow',
    defaultMessage: 'Install now',
  },
  planPrices: {
    id: 'planPrices',
    defaultMessage: 'Plan Prices',
  },
  manageMarketplacePrices: {
    id: 'manageMarketplacePrices',
    defaultMessage: 'Manage the marketplace prices. Set default prices for new subscriptions.',
  },
  package: {
    id: 'package',
    defaultMessage: 'Package',
  },
  customModuleFromLibrary: {
    id: 'customModuleFromLibrary',
    defaultMessage: 'Custom module from your library.',
  },
  monthlyFor: {
    id: 'monthlyFor',
    defaultMessage: 'monthly for',
  },
  isInstalled: {
    id: 'isInstalled',
    defaultMessage: 'is now installed.',
  },
  newModuleBeingInstalled: {
    id: 'newModuleBeingInstalled',
    defaultMessage: 'New module is being installed.',
  },
  confirmURI: {
    id: 'confirmURI',
    defaultMessage: 'Confirm URI',
  },
  areYourSureURICorrect: {
    id: 'areYourSureURICorrect',
    defaultMessage: 'Are you sure the URI is correct?',
  },
  plansAndPricing: {
    id: 'plansAndPricing',
    defaultMessage: 'Plans and Pricing',
  },
  howManyDevices: {
    id: 'howManyDevices',
    defaultMessage: 'How many devices will you need?',
  },
  examplePrices: {
    id: 'examplePrices',
    defaultMessage: 'Example prices',
  },
  depreciateSolution: {
    id: 'depreciateSolution',
    defaultMessage: 'Depreciate Solution',
  },
  depreciatedSolutionDesc: {
    id: 'depreciatedSolutionDesc',
    defaultMessage: 'If you proceed, you will depreciate the entire solution with all its versions. The solution will be scheduled to be permanently removed after 60 days.<br/><br/> The solution will be removed from the marketplace immediately and cannot be installed to workspaces anymore. Current installations within workspaces will not be affected until the scheduled date is reached. <br/><br/>On removal, the subscriptions will be cancelled.',
  },
  typeConfirm: {
    id: 'typeConfirm',
    defaultMessage: 'Type “CONFIRM”',
  },
  depreciatedSolution: {
    id: 'depreciatedSolution',
    defaultMessage: 'Solution depreciated successfully',
  },
  byClickingCreateWorkspace: {
    id: 'byClickingCreateWorkspace',
    defaultMessage: 'By clicking “Create Workspace” you agree to our',
  },
  linkExternalDoc: {
    id: 'linkExternalDoc',
    defaultMessage: 'Link external documentation',
  },
  externalDocs: {
    id: 'externalDocs',
    defaultMessage: 'External Docs',
  },
  termsOfUse: {
    id: 'termsOfUse',
    defaultMessage: 'Terms of Service',
  },
  sTermsOfUse: {
    id: 'sTermsOfUse',
    defaultMessage: 'terms of service',
  },
  theNewApplication: {
    id: 'theNewApplication',
    defaultMessage: 'The new application',
  },
  addedAsTemplate: {
    id: 'addedAsTemplate',
    defaultMessage: 'has been added as a template.',
  },
  depreciateVersionDesc: {
    id: 'depreciateVersionDesc',
    defaultMessage: 'If you proceed, the version will be set to depreciate and scheduled for permanent removal after 60 days. The version cannot be installed in workspaces anymore. Current installations of this version will not be affected. <br/><br/>To depreciate the version of an item, there must be at least one more active version available.',
  },
  depreciateVersion: {
    id: 'depreciateVersion',
    defaultMessage: 'Depreciate Version',
  },
  depreciatesVersion: {
    id: 'depreciatesVersion',
    defaultMessage: 'Depreciate version',
  },
  listDefaultVersion: {
    id: 'listDefaultVersion',
    defaultMessage: 'List as default version',
  },
  exploreMore: {
    id: 'exploreMore',
    defaultMessage: 'Explore More',
  },
  createNewProfileSetting: {
    id: 'createNewProfileSetting',
    defaultMessage: 'Create new profile',
  },
  assignedDevices: {
    id: 'assignedDevices',
    defaultMessage: 'Assigned devices',
  },
  addFromMarketplace: {
    id: 'addFromMarketplace',
    defaultMessage: 'Add from marketplace',
  },
  addPublicModule: {
    id: 'addPublicModule',
    defaultMessage: 'Add public module',
  },
  addCustomModule: {
    id: 'addCustomModule',
    defaultMessage: 'Add custom module',
  },
  moduleDetails: {
    id: 'moduleDetails',
    defaultMessage: 'Module details',
  },
  appsWithModule: {
    id: 'appsWithModule',
    defaultMessage: 'Apps with this module',
  },
  manageVersions: {
    id: 'manageVersions',
    defaultMessage: 'Manage versions',
  },
  uninstallModule: {
    id: 'uninstallModule',
    defaultMessage: 'Uninstall module',
  },
  copyToNew: {
    id: 'copyToNew',
    defaultMessage: 'Copy to new',
  },
  editVisoBuilder: {
    id: 'editVisoBuilder',
    defaultMessage: 'Edit in Viso Builder',
  },
  manageInvitations: {
    id: 'manageInvitations',
    defaultMessage: 'Manage invitations',
  },
  viewAssignedDevices: {
    id: 'viewAssignedDevices',
    defaultMessage: 'View assigned devices',
  },
  assignARole: {
    id: 'assignARole',
    defaultMessage: 'Assign a role',
  },
  applicationDesc: {
    id: 'applicationDesc',
    defaultMessage: 'Import applications to your workspace and modify them using the Viso Builder.',
  },
  moduleDesc: {
    id: 'moduleDesc',
    defaultMessage: 'Install modules to your workspace to extend the Visual Builder with new nodes for application building.',
  },
  viewAssignedUsers: {
    id: 'viewAssignedUsers',
    defaultMessage: 'View assigned users',
  },
  updateNow: {
    id: 'updateNow',
    defaultMessage: 'Update Now',
  },
  versionUpdated: {
    id: 'versionUpdated',
    defaultMessage: 'Updating version',
  },
  moduleVersion: {
    id: 'moduleVersion',
    defaultMessage: 'The version history of the current module.',
  },
  switchToAnnual: {
    id: 'switchToAnnual',
    defaultMessage: 'Switch to annual payment',
  },
  switchToAnnualDesc: {
    id:"switchToAnnualDesc",
    defaultMessage:"Change from monthly payment to annual payment and <span class='billing-switch-desc'>save 20%</span> on all your purchases."
  },
  twoFactorAuthentication: {
    id: 'twoFactorAuthentication',
    defaultMessage: 'Two Factor Authentication',
  },
  recoveryKey: {
    id: 'recoveryKey',
    defaultMessage: 'Recovery Key',
  },
  disable: {
    id: 'disable',
    defaultMessage: 'Disable',
  },
  authenticationStep1: {
    id: 'authenticationStep1',
    defaultMessage: 'When you sign in to Viso Suite you’ll enter your username and password, like always.',
  },
  authenticationStep2: {
    id: 'authenticationStep2',
    defaultMessage: 'When logging in from a new browser, you’ll need to enter an additional code from your phone.',
  },
  authenticationStep3: {
    id: 'authenticationStep3',
    defaultMessage: 'Once you enter the code on the website, you’ll be logged into the Viso Suite Workspace.',
  },
  forEnterprisesWithScale: {
    id: 'forEnterprisesWithScale',
    defaultMessage: 'For enterprises with scale, security, and customization needs.',
  },
  forOrganizationsToTest: {
    id: 'forOrganizationsToTest',
    defaultMessage: 'For organizations to test the basics of Viso Suite',
  },
  forBusinessesThatNeed: {
    id: 'forBusinessesThatNeed',
    defaultMessage: 'For businesses that need to build and deploy AI vision solutions.',
  },
  enterprisePlanPricing: {
    id: 'enterprisePlanPricing',
    defaultMessage: 'Build mission-critical computer vision systems',
  },
  orMoreDevices: {
    id: 'orMoreDevices',
    defaultMessage: 'or more devices',
  },
  perDeviceAverage: {
    id: 'perDeviceAverage',
    defaultMessage: 'per device',
  },
  aMonth: {
    id: 'aMonth',
    defaultMessage: 'a month',
  },
  aYear: {
    id: 'aYear',
    defaultMessage: 'a year',
  },
  visoSuiteDeviceEndpoint: {
    id: 'visoSuiteDeviceEndpoint',
    defaultMessage: 'A device is an endpoint to which you deploy applications. You only pay for effective usage. Use the calculator to estimate prices as you grow.',
  },
  devicesMinimum: {
    id: 'devicesMinimum',
    defaultMessage: 'Devices Minimum',
  },
  devicesLimit: {
    id: 'devicesLimit',
    defaultMessage: 'Devices Limit',
  },
  dashboards: {
    id: 'dashboards',
    defaultMessage: 'Dashboards',
  },
  cloudStorage: {
    id: 'cloudStorage',
    defaultMessage: 'Cloud Storage',
  },
  videoInputs: {
    id: 'videoInputs',
    defaultMessage: 'Video Inputs',
  },
  objectDetection: {
    id: 'objectDetection',
    defaultMessage: 'Object Detection',
  },
  objectCounting: {
    id: 'objectCounting',
    defaultMessage: 'Object Counting',
  },
  poseEstimation: {
    id: 'poseEstimation',
    defaultMessage: 'Pose Estimation',
  },
  objectSegmentation: {
    id: 'objectSegmentation',
    defaultMessage: 'Object Segmentation',
  },
  imageClassification: {
    id: 'imageClassification',
    defaultMessage: 'Image Classification',
  },
  regionOfInterest: {
    id: 'regionOfInterest',
    defaultMessage: 'Region of Interest',
  },
  customModels: {
    id: 'customModels',
    defaultMessage: 'Custom Models',
  },
  visualEditor: {
    id: 'visualEditor',
    defaultMessage: 'Visual Editor',
  },
  versionControl: {
    id: 'versionControl',
    defaultMessage: 'Version Control',
  },
  versionHistory: {
    id: 'versionHistory',
    defaultMessage: 'Version History',
  },
  moduleLibrary: {
    id: 'moduleLibrary',
    defaultMessage: 'Module Library',
  },
  publicModules2500: {
    id: 'publicModules2500',
    defaultMessage: '2500+ Public Modules',
  },
  customModules: {
    id: 'customModules',
    defaultMessage: 'Custom Modules',
  },
  deviceEnrollment: {
    id: 'deviceEnrollment',
    defaultMessage: 'Device Enrollment',
  },
  deviceManagement: {
    id: 'deviceManagement',
    defaultMessage: 'Device Management',
  },
  deviceMonitoring: {
    id: 'deviceMonitoring',
    defaultMessage: 'Device Monitoring',
  },
  debuggingTools: {
    id: 'debuggingTools',
    defaultMessage: 'Debugging Tools',
  },
  automatedDeployment: {
    id: 'automatedDeployment',
    defaultMessage: 'Automated Deployment',
  },
  monthlyDeployments: {
    id: 'monthlyDeployments',
    defaultMessage: 'Monthly Deployments',
  },
  dataCollectors: {
    id: 'dataCollectors',
    defaultMessage: 'Data collectors',
  },
  dashboardEditor: {
    id: 'dashboardEditor',
    defaultMessage: 'Dashboard Editor',
  },
  visualDataExplorer: {
    id: 'visualDataExplorer',
    defaultMessage: 'Visual Data Explorer',
  },
  encryptionTransit: {
    id: 'encryptionTransit',
    defaultMessage: 'Encryption in transit & at rest',
  },
  roleBasedAccess: {
    id: 'roleBasedAccess',
    defaultMessage: 'Role based access control',
  },
  enterpriseLevelSecurity: {
    id: 'enterpriseLevelSecurity',
    defaultMessage: 'Enterprise-level Security',
  },
  portalDocumentation: {
    id: 'portalDocumentation',
    defaultMessage: 'Portal documentation',
  },
  communitySupport: {
    id: 'communitySupport',
    defaultMessage: 'Community Support',
  },
  ticketing: {
    id: 'ticketing',
    defaultMessage: 'Ticketing',
  },
  dedicatedAccountManager: {
    id: 'dedicatedAccountManager',
    defaultMessage: 'Dedicated Account Manager',
  },
  preBuiltApplications: {
    id: 'preBuiltApplications',
    defaultMessage: 'Pre-built Applications',
  },
  extensionsIntegrations: {
    id: 'extensionsIntegrations',
    defaultMessage: 'Extensions & Integrations',
  },
  expertOnboarding: {
    id: 'expertOnboarding',
    defaultMessage: 'Expert Onboarding',
  },
  implementationServices: {
    id: 'implementationServices',
    defaultMessage: 'Implementation Services',
  },
  avgPriceDevice: {
    id: 'avgPriceDevice',
    defaultMessage: 'Avg. price/device',
  },
  unit: {
    id: 'unit',
    defaultMessage: 'Unit',
  },
  perDeployment: {
    id: 'perDeployment',
    defaultMessage: 'per deployment',
  },
  connectThisItemWithWs: {
    id: 'connectThisItemWithWs',
    defaultMessage: 'Connect this item with your workspace',
  },
  installItForFree: {
    id: 'installItForFree',
    defaultMessage: 'Install it for free',
  },
  getItNow: {
    id: 'getItFree',
    defaultMessage: 'Get it now',
  },
  viewFullPricing: {
    id: 'viewFullPricing',
    defaultMessage: 'View full pricing',
  },
  getApplicationAsTemplate: {
    id: 'getApplicationAsTemplate',
    defaultMessage: 'to get this application <br/>as template',
  },
  tryFreeFor: {
    id: 'tryFreeFor',
    defaultMessage: 'Try free for',
  },
  days: {
    id: 'days',
    defaultMessage: 'days',
  },
  isNowInstalled: {
    id: 'isNowInstalled',
    defaultMessage: 'is now installed.',
  },
  isInstalling: {
    id: 'isInstalling',
    defaultMessage: 'is installing.',
  },
  changeBillingCycle: {
    id: 'changeBillingCycle',
    defaultMessage: 'Change billing cycle',
  },
  changeToMonthlyPayment: {
    id: 'changeToMonthlyPayment',
    defaultMessage: "You are changing to <span class='billing-switch-desc'>{interval}</span> billing. Your plan will start now and renew on <span class='billing-switch-desc'>{date}</span>.",
  },
  changeToYearlyPayment: {
    id: 'changeToYearlyPayment',
    defaultMessage: "You are changing to <span class='billing-switch-desc'>{interval}</span> billing. Your plan will start now and renew on <span class='billing-switch-desc'>{date}</span>.",
  },
  freeTrail: {
    id: 'freeTrail',
    defaultMessage: 'Trial',
  },
  paidAnnually: {
    id: 'paidAnnually',
    defaultMessage: 'Paid annually',
  },
  paidMonthly: {
    id: 'paidMonthly',
    defaultMessage: 'Paid monthly',
  },
  newVersionInstalling: {
    id: 'newVersionInstalling',
    defaultMessage: 'Installing new version',
  },
  viewBillingHistory: {
    id: 'viewBillingHistory',
    defaultMessage: 'View billing history',
  },
  limitProfiles: {
    id: 'limitProfiles',
    defaultMessage: 'Limit reached for profiles',
  },
  limitProfilesBody: {
    id: 'limitProfilesBody',
    defaultMessage: 'You’ve reached the limit of profiles for your plan. To add more profiles, upgrade your workspace plan.',
  },
  limitDevices: {
    id: 'limitDevices',
    defaultMessage: 'Limit reached for devices',
  },
  limitDevicesBody: {
    id: 'limitDevicesBody',
    defaultMessage: 'You’ve reached the limit of devices.',
  },
  limitApplications: {
    id: 'limitApplications',
    defaultMessage: 'Limit reached for applications',
  },
  limitApplicationsBody: {
    id: 'limitApplicationsBody',
    defaultMessage: 'You’ve reached the applications limit for your plan. To add more applications, upgrade your workspace plan.',
  },
  limitModule: {
    id: 'limitModule',
    defaultMessage: 'Limit reached for modules',
  },
  limitModuleBody: {
    id: 'limitModuleBody',
    defaultMessage: 'You’ve reached the module limit for your plan. To add more modules, upgrade your workspace plan.',
  },
  limitVideos: {
    id: 'limitVideos',
    defaultMessage: 'Limit reached for videos',
  },
  limitVideosBody: {
    id: 'limitVideosBody',
    defaultMessage: 'You’ve reached the limit of video files for your plan. To add more video files, upgrade your workspace plan.',
  },
  limitUser: {
    id: 'limitUser',
    defaultMessage: 'Limit reached for users',
  },
  limitUserBody: {
    id: 'limitUserBody',
    defaultMessage: 'You’ve reached the limit of users for your plan. To add more users to your workspace, upgrade your workspace plan.',
  },
  limitAppVersion: {
    id: 'limitAppVersion',
    defaultMessage: 'Limit reached for application versions',
  },
  limitAppVersionBody: {
    id: 'limitAppVersionBody',
    defaultMessage: 'You’ve reached the limit for your plan. To add more application versions, upgrade your workspace plan.',
  },
  bussinessAddress: {
    id: 'bussinessAddress',
    defaultMessage: 'Billing address',
  },
  zipCode: {
    id: 'zipCode',
    defaultMessage: 'Zip code',
  },
  companyOptional: {
    id: 'companyOptional',
    defaultMessage: 'Company (optional)',
  },
  vatOptional: {
    id: 'vatOptional',
    defaultMessage: 'VAT/GST (optional)',
  },
  perSeat: {
    id: 'perSeat',
    defaultMessage: 'per seat',
  },
  dueToday: {
    id: 'dueToday',
    defaultMessage: 'Due today',
  },
  workspaceSubscription: {
    id: 'workspaceSubscription',
    defaultMessage: 'Workspace Subscription',
  },
  getStartedWithWs: {
    id: 'getStartedWithWs',
    defaultMessage: 'Get started with your Viso Suite Workspace.',
  },
  pickAPlanToTrail: {
    id: 'pickAPlanToTrail',
    defaultMessage: 'Pick a plan to trial all tools and features of Viso Suite for {trialPeriods} days',
  },
  billingInfo: {
    id: 'billingInfo',
    defaultMessage: 'Billing Info',
  },
  priceAfterTrial: {
    id: 'priceAfterTrial',
    defaultMessage: 'Price after trial',
  },
  chooseToPay: {
    id: 'chooseToPay',
    defaultMessage: 'Choose to pay annually and save 20% on every plan.',
  },
  paymentDetails: {
    id: 'paymentDetails',
    defaultMessage: 'Payment details',
  },
  ifYouDecidePlan: {
    id: 'ifYouDecidePlan',
    defaultMessage: 'If you decide a plan isnt’t for you, canceling is easy.',
  },
  welcomeToYourWorkspace: {
    id: 'welcomeToYourWorkspace',
    defaultMessage: 'Welcome to your workspace!',
  },
  congratulationsYourWsInstalled: {
    id: 'congratulationsYourWsInstalled',
    defaultMessage: 'Congratulations! Your workspace was successfully initiated. You can already access your workspace while we are setting up your infrastructure in the background.',
  },
  getStartedNow: {
    id: 'getStartedNow',
    defaultMessage: 'Get started now',
  },
  thereAreDevicesWaiting: {
    id: 'thereAreDevicesWaiting',
    defaultMessage: 'There are devices waiting to connect to the workspace.',
  },
  upgradeYourWorkspacePlan: {
    id: 'upgradeYourWorkspacePlan',
    defaultMessage: 'Upgrade your workspace plan to add more devices.',
  },
  pricingCalculator: {
    id: 'pricingCalculator',
    defaultMessage: 'Pricing Calculator',
  },
  startTrail: {
    id: 'startTrail',
    defaultMessage: 'Start trial for {days}',
  },
  contactUsBtn: {
    id: 'contactUsBtn',
    defaultMessage: 'Contact us',
  },
  currentPlan: {
    id: 'currentPlan',
    defaultMessage: 'Current Plan',
  },
  proceedToChange: {
    id: 'proceedToChange',
    defaultMessage: 'Proceed to change from the plan {current} to {next}',
  },
  paymentMethod: {
    id: 'paymentMethod',
    defaultMessage: 'Payment method',
  },
  pleaseConfirmChange: {
    id: 'pleaseConfirmChange',
    defaultMessage: 'Please confirm your changes to the plan.',
  },
  exploreTheMP: {
    id: 'exploreTheMP',
    defaultMessage: 'Explore the Marketplace to get started even faster.',
  },
  planChanged: {
    id: 'planChanged',
    defaultMessage: 'Plan changed successfully',
  },
  switchedPlan: {
    id: 'switchedPlan',
    defaultMessage: 'Interval switched successfully',
  },
  upcomingMonth: {
    id: 'upcomingMonth',
    defaultMessage: 'Upcoming Month',
  },
  versionComment: {
    id: 'versionComment',
    defaultMessage: 'Version Comment',
  },
  discard: {
    id: 'discard',
    defaultMessage: 'Discard',
  },
  showMe: {
    id: 'showMe',
    defaultMessage: 'Show me',
  },
  balacenWillDecrease: {
    id: 'balacenWillDecrease',
    defaultMessage: 'Balance will decrease the amount due on the next invoices.',
  },
  creditBalance: {
    id: 'creditBalance',
    defaultMessage: 'Credit Balance',
  },
  appliedBalance: {
    id: 'appliedBalance',
    defaultMessage: 'Applied balance',
  },
  forThePaidPeriod: {
    id: 'forThePaidPeriod',
    defaultMessage: 'For the paid period remaining, you ll receive a credit of {amount}',
  },
  setVersionAsLatest: {
    id: 'setVersionAsLatest',
    defaultMessage: 'Set version as latest',
  },
  restorePreviousVersion: {
    id: 'restorePreviousVersion',
    defaultMessage: 'Restore a previous version and set it as the new application default that can be edited in the Builder. Confirm to proceed.',
  },
  latest: {
    id: 'latest',
    defaultMessage: 'Latest',
  },
  balacenWillIncrease: {
    id: 'balacenWillIncrease',
    defaultMessage: 'Balance will increase the amount due on the next invoices.',
  },
  yourCurrentUsage: {
    id: 'yourCurrentUsage',
    defaultMessage: 'Your current usage and limits for your plan {value}.',
  },
  planLimitsUsage: {
    id: 'planLimitsUsage',
    defaultMessage: 'Plan Limits and Usage',
  },
  videos: {
    id: 'videos',
    defaultMessage: 'Videos',
  },
  unlimited: {
    id: 'unlimited',
    defaultMessage: 'Unlimited',
  },
  deployments: {
    id: 'deployments',
    defaultMessage: 'Deployments',
  },
  yourCurrentUsageDesc: {
    id: 'yourCurrentUsageDesc',
    defaultMessage: 'Your current usage and limits for your plan {value}. Upgrade the plan to increase limits.',
  },
  upgradePlan: {
    id: 'upgradePlan',
    defaultMessage: 'Upgrade plan',
  },
  numberOfApplicationVersions: {
    id: 'numberOfApplicationVersions',
    defaultMessage: 'Number of Application Versions that can be created per Application',
  },
  versionsPerApplication: {
    id: 'versionsPerApplication',
    defaultMessage: 'Versions per Application',
  },
  upto: {
    id: 'upto',
    defaultMessage: 'Up to',
  },
  upgradeNow: {
    id: 'upgradeNow',
    defaultMessage: 'Upgrade now',
  },
  deviceLimitReached: {
    id: 'deviceLimitReached',
    defaultMessage: 'Device limit reached for this plan',
  },
  deviceLimitErrors: {
    id: 'deviceLimitError',
    defaultMessage: 'You’ve too many devices in your workspace to select this plan. Please remove devices or choose a plan with a higher device limit.',
  },
  payAsYouGo: {
    id: 'payAsYouGo',
    defaultMessage: 'Pay as you grow',
  },
  choosePlan: {
    id: 'choosePlan',
    defaultMessage: 'Select your workspace edition',
  },
  pickPlanToTrailAllTools: {
    id: 'pickPlanToTrailAllTools',
    defaultMessage: 'Pick a plan to trial all tools and features of Viso Suite for {day} days.',
  },
  billingCycle: {
    id: 'billingCycle',
    defaultMessage: 'Billing cycle',
  },
  annually: {
    id: 'annually',
    defaultMessage: 'annually',
  },
  sMonthly: {
    id: 'sMonthly',
    defaultMessage: 'monthly',
  },
  changePlanWs: {
    id: 'changePlanWs',
    defaultMessage: 'Change the plan of your Viso Suite Workspace.',
  },
  popularApplication: {
    id: 'popularApplication',
    defaultMessage: 'Popular applications',
  },
  exploreTheMost: {
    id: 'exploreTheMost',
    defaultMessage: 'Explore the most installed applications in the Viso Marketplace.',
  },
  exploreModules: {
    id: 'exploreModules',
    defaultMessage: 'Explore modules you can leverage within the Viso Suite Builder.',
  },
  startingFrom: {
    id: 'startingFrom',
    defaultMessage: 'Starting from',
  },
  limitDeployment: {
    id: 'limitDeployment',
    defaultMessage: 'Limit reached for deployment',
  },
  limitDeploymentBody: {
    id: 'limitDeploymentBody',
    defaultMessage: 'You’ve reached the limit of monthly deployments for your plan. To increase the deployment limit, upgrade your workspace plan.',
  },
  includedDevices: {
    id: 'includedDevices',
    defaultMessage: 'Included Devices',
  },
  itemLimitReached: {
    id: 'itemLimitReached',
    defaultMessage: '{value} limit reached for this plan',
  },
  itemLimitErrors: {
    id: 'itemLimitErrors',
    defaultMessage: 'You’ve too many {items} in your workspace to select this plan. Please remove {items} or choose a plan with a higher {item} limit.',
  },
  applicationData: {
    id: 'applicationData',
    defaultMessage: 'Application data',
  },
  deviceMetrics: {
    id: 'deviceMetrics',
    defaultMessage: 'Device metrics',
  },
  deviceDataRetention: {
    id: 'deviceDataRetention',
    defaultMessage: 'Device Data Retention',
  },
  dataRetention: {
    id: 'dataRetention',
    defaultMessage: 'Data Retention',
  },
  freeToLearn: {
    id: 'freeToLearn',
    defaultMessage: 'Try Viso Suite for free. We help you getting started. Get access to integrated tools and powerful infrastructure.',
  },
  chooseTheFreePlan: {
    id: 'chooseTheFreePlan',
    defaultMessage: 'Choose the free plan to test all features. Upgrade any time to a paid plan.',
  },
  registerWithEmail: {
    id: 'registerWithEmail',
    defaultMessage: 'Register with the email ',
  },
  welcomeBack: {
    id: 'welcomeBack',
    defaultMessage: 'Welcome back!',
  },
  happyToHaveBack: {
    id: 'happyToHaveBack',
    defaultMessage: 'We are happy to have you back! Your workspace was successfully restored and is now ready to use.',
  },
  updateCreditCard: {
    id: 'updateCreditCard',
    defaultMessage: 'Update Credit Card',
  },
  developerAccessBilling: {
    id: 'developerAccessBilling',
    defaultMessage: 'Please provide a billing method to set up your Developer Access',
  },
  setUpNewWs: {
    id: 'setUpNewWs',
    defaultMessage: 'Set up a new Workspace',
  },
  noInvoicesPlanned: {
    id: 'noInvoicesPlanned',
    defaultMessage: 'No invoices are planned for next month',
  },
  goBack: {
    id: 'goBack',
    defaultMessage: 'Go back',
  },
  iKnowWhatDoing: {
    id: 'iKnowWhatDoing',
    defaultMessage: 'I know what I’m doing',
  },
  deleteYourWs: {
    id: 'deleteYourWs',
    defaultMessage: 'Are you sure you want to delete your workspace? By canceling the workspace subscription, you’ll lose access to all services and all data will be deleted.',
  },
  requestVirtualDevice: {
    id: 'requestVirtualDevice',
    defaultMessage: 'Request a virtual device.',
  },
  addPhysicalDevices: {
    id: 'addPhysicalDevices',
    defaultMessage: 'Add physical devices',
  },
  giveYourWorkspaceName: {
    id: 'giveYourWorkspaceName',
    defaultMessage: 'Give your Workspace a name',
  },
  companyInformation: {
    id: 'companyInformation',
    defaultMessage: 'Company Information',
  },
  later: {
    id: 'later',
    defaultMessage: 'Later',
  },
  models: {
    id: 'models',
    defaultMessage: 'Models',
  },
  modelDetails: {
    id: 'modelDetails',
    defaultMessage: 'Model Details',
  },
  dataset: {
    id: 'dataset',
    defaultMessage: 'Dataset',
  },
  modelSize: {
    id: 'modelSize',
    defaultMessage: 'Model size',
  },
  imageSize: {
    id: 'imageSize',
    defaultMessage: 'Image size',
  },
  source: {
    id: 'source',
    defaultMessage: 'Source',
  },
  modelType: {
    id: 'modelType',
    defaultMessage: 'Model type',
  },
  framework: {
    id: 'framework',
    defaultMessage: 'Framework',
  },
  deviceTypes: {
    id: 'deviceTypes',
    defaultMessage: 'Device types',
  },
  associatedModules: {
    id: 'associatedModules',
    defaultMessage: 'Associated Modules',
  },
  modulesAIUsed: {
    id: 'modulesAIUsed',
    defaultMessage: 'Modules with which the AI model can be used.',
  },
  newVersion: {
    id: 'newVersion',
    defaultMessage: 'New version',
  },
  aiModels: {
    id: 'aiModels',
    defaultMessage: 'AI Models',
  },
  imageSizeEg: {
    id: 'imageSizeEg',
    defaultMessage: 'Image size (e.g. 200x200)',
  },
  modelImported: {
    id: 'modelImported',
    defaultMessage: 'Model created successfully.',
  },
  scheduleOnboarding: {
    id: 'scheduleOnboarding',
    defaultMessage: 'Schedule onboarding',
  },
  knowledgeCenter: {
    id: 'knowledgeCenter',
    defaultMessage: 'Knowledge center',
  },
  installDemoApplication: {
    id: 'installDemoApplication',
    defaultMessage: 'Install demo application',
  },
  videoFeed: {
    id: 'videoFeed',
    defaultMessage: 'Video viewer',
  },
  English: {
    id: 'English',
    defaultMessage: 'English',
  },
  associatedNodes: {
    id: 'associatedNodes',
    defaultMessage: 'Associated Nodes',
  },
  associatedNodesDesc: {
    id: 'associatedNodesDesc',
    defaultMessage: 'Nodes of Modules with which the AI model can be used.',
  },
  removeYourModel: {
    id: 'removeYourModel',
    defaultMessage: 'Remove your model',
  },
  removeYourModelDesc: {
    id: 'removeYourModelDesc',
    defaultMessage: 'Are you sure you want to remove your AI model {name}? The model will be permanently deleted.',
  },
  noModel: {
    id: 'noModel',
    defaultMessage: 'Currently no model available.',
  },
  payNow: {
    id: 'payNow',
    defaultMessage: 'Pay now',
  },
  hours: {
    id: 'hours',
    defaultMessage: 'Hours',
  },
  subscriptionRemoved: {
    id: 'subscriptionRemoved',
    defaultMessage: 'Subscription removed successfully.',
  },
  processingSetting: {
    id: 'processingSetting',
    defaultMessage: 'Processing Setting',
  },
  scaleFactor: {
    id: 'scaleFactor',
    defaultMessage: 'Scale Factor',
  },
  lineSetting: {
    id: 'lineSetting',
    defaultMessage: 'Line Setting',
  },
  lineCounts: {
    id: 'lineCounts',
    defaultMessage: 'Line Counts',
  },
  lineNumber: {
    id: 'lineNumber',
    defaultMessage: 'Line Number',
  },
  lineName: {
    id: 'lineName',
    defaultMessage: 'Line Name',
  },
  direction: {
    id: 'direction',
    defaultMessage: 'Direction',
  },
  markAllAsRead: {
    id: 'markAllAsRead',
    defaultMessage: 'Mark all as read',
  },
  markAllAsUnread: {
    id: 'markAllAsUnread',
    defaultMessage: 'Mark all as unread',
  },
  clearAll: {
    id: 'clearAll',
    defaultMessage: 'Clear all',
  },
  createApplicationSuccessful: {
    id: 'createApplicationSuccessful',
    defaultMessage: 'New application created successfully',
  },
  cvEnvironments: {
    id: 'cvEnvironments',
    defaultMessage: 'CV Environments',
  },
  screenResolution: {
    id: 'screenResolution',
    defaultMessage: 'Screen resolution',
  },
  dataDiskSize: {
    id: 'dataDiskSize',
    defaultMessage: 'Data disk size',
  },
  userDiskSize: {
    id: 'userDiskSize',
    defaultMessage: 'User disk size',
  },
  terminate: {
    id: 'terminate',
    defaultMessage: 'Terminate',
  },
  pause: {
    id: 'pause',
    defaultMessage: 'Pause',
  },
  play: {
    id: 'play',
    defaultMessage: 'Play',
  },
  removeYourEnv: {
    id: 'removeYourEnv',
    defaultMessage: 'Remove your CV environment',
  },
  removeYourEnvDesc: {
    id: 'removeYourEnvDesc',
    defaultMessage: 'Are you sure you want to remove your CV Environment {name}? The environment will be permanently deleted.',
  },
  authentication: {
    id: 'authentication',
    defaultMessage: 'Authentication',
  },
  adjustWorkspaceWide: {
    id: 'adjustWorkspaceWide',
    defaultMessage: 'Adjust workspace-wide authentication settings and preferences.',
  },
  workspaceWide: {
    id: 'workspaceWide',
    defaultMessage: 'Workspace-wide Two Factor Authentication',
  },
  addAdditionalLayer: {
    id: 'addAdditionalLayer',
    defaultMessage: 'Add an additional layer of security to the workspace and require each user to configure and use Two Factor Authentification for signing in.',
  },
  enableNow: {
    id: 'enableNow',
    defaultMessage: 'Enable Now',
  },
  enabled: {
    id: 'enabled',
    defaultMessage: 'Enabled',
  },
  manageWorkspaceWide: {
    id: 'manageWorkspaceWide',
    defaultMessage: 'Manage workspace-wide user authentication',
  },
  allUserWithoutDesc: {
    id: 'allUserWithoutDesc',
    defaultMessage: 'All users without enabled Two Factor Authentication will be instantly signed out, and they will be required to set up Two Factor Authentication for their account.',
  },
  disableWorkspaceAuth: {
    id: 'disableWorkspaceAuth',
    defaultMessage: 'Disable workspace-wide Two Factor Authentication',
  },
  disableWorkspaceAuthDesc: {
    id: 'disableWorkspaceAuthDesc',
    defaultMessage: 'Are you sure you want to stop requiring all users to sign in using Two Factor Authentication?',
  },
  downloadAndContinue: {
    id: 'downloadAndContinue',
    defaultMessage: 'Download and Continue',
  },
  pleaseUpgradeWsPlan: {
    id: 'pleaseUpgradeWsPlan',
    defaultMessage: 'Please upgrade your workspace plan',
  },
  invalidInput: {
    id: 'invalidInput',
    defaultMessage: 'Invalid Input',
  },
  sorryThePage: {
    id: 'sorryThePage',
    defaultMessage: 'You don\'t have permission to access this resource',
  },
  noAuthorized: {
    id: 'noAuthorized',
    defaultMessage: 'Not authorized',
  },
  viewAll: {
    id: 'viewAll',
    defaultMessage: 'View all',
  },
  modelLabels: {
    id: 'modelLabels',
    defaultMessage: 'Model Labels',
  },
  labelsModelHandle: {
    id: 'labelsModelHandle',
    defaultMessage: 'Labels that the model is able to handle.',
  },
  unassign: {
    id: 'unassign',
    defaultMessage: 'Unassign',
  },
  addACustomModel: {
    id: 'addACustomModel',
    defaultMessage: 'Add a custom AI model',
  },
  uploadYourCustomDesc: {
    id: 'uploadYourCustomDesc',
    defaultMessage: 'Upload your custom AI model to your Viso Workspace. The model will appear in your model library.',
  },
  addModel: {
    id: 'addModel',
    defaultMessage: 'Add model',
  },
  modelFileTar: {
    id: 'modelFileTar',
    defaultMessage: 'Model file (tar.xz/tar.gz)',
  },
  modelClassesTxt: {
    id: 'modelClassesTxt',
    defaultMessage: 'Model classes (.txt)',
  },
  nodeSuccessfullyUnassigned: {
    id: 'nodeSuccessfullyUnassigned',
    defaultMessage: 'Node successfully unassigned.',
  },
  createCvEnv: {
    id: 'createCvEnv',
    defaultMessage: 'Create CV Environment',
  },
  createANewEnvWs: {
    id: 'createANewEnvWs',
    defaultMessage: 'Create a new CV Environment for this Workspace.',
  },
  template: {
    id: 'template',
    defaultMessage: 'Template',
  },
  environmentVariables: {
    id: 'environmentVariables',
    defaultMessage: 'Environment variables',
  },
  anyEnvironmentVariableWs: {
    id: 'anyEnvironmentVariableWs',
    defaultMessage: 'Any environment variables you have created will be automatically mounted to this Workspace',
  },
  addManageEnv: {
    id: 'addManageEnv',
    defaultMessage: 'Add or manage environment variables',
  },
  createAndRun: {
    id: 'createAndRun',
    defaultMessage: 'Create and run',
  },
  successfullyPaused: {
    id: 'successfullyPaused',
    defaultMessage: 'Successfully paused',
  },
  successfullyResumed: {
    id: 'successfullyResumed',
    defaultMessage: 'Successfully resumed',
  },
  addCvEnv: {
    id: 'addCvEnv',
    defaultMessage: 'Add an environment',
  },
  createYourFirstCv: {
    id: 'createYourFirstCv',
    defaultMessage: 'Create your first CV Environment',
  },
  noNewNotification: {
    id: 'noNewNotification',
    defaultMessage: 'No new notifications.',
  },
  twoFactorAuth: {
    id: 'twoFactorAuth',
    defaultMessage: 'Two-factor authentication is an additional layer of security to your account by requiring more than just a password to log in.',
  },
  toEnableFeatureUpgrade: {
    id: 'toEnableFeatureUpgrade',
    defaultMessage: 'To enable this feature, please upgrade your current workspace plan.',
  },
  setUp2Factor: {
    id: 'setUp2Factor',
    defaultMessage: 'Set up two factor authentication',
  },
  twoFactorAuthExtraLayer: {
    id: 'twoFactorAuthExtraLayer',
    defaultMessage: 'Two-factor authentication adds an extra layer of security to your account. In addition to your username and password, you’ll<br/> need to enter a code that we send you via an app on your phone.',
  },
  useAnApplication: {
    id: 'useAnApplication',
    defaultMessage: 'Use an application such as',
  },
  theseApplicationsSupport: {
    id: 'theseApplicationsSupport',
    defaultMessage: 'These applications support secure backup and can be restored if you lose access to your device.',
  },
  setUpNow: {
    id: 'setUpNow',
    defaultMessage: 'Set up now',
  },
  nodePool: {
    id: 'nodePool',
    defaultMessage: 'Node Pool',
  },
  dataCollection: {
    id: 'dataCollection',
    defaultMessage: 'Data Collection',
  },
  imageAnnotation: {
    id: 'imageAnnotation',
    defaultMessage: 'Image Annotation',
  },
  modelTraining: {
    id: 'modelTraining',
    defaultMessage: 'Model Training',
  },
  buildingBlocks: {
    id: 'buildingBlocks',
    defaultMessage: 'Building Blocks',
  },
  noCodeEditor: {
    id: 'noCodeEditor',
    defaultMessage: 'Visual Editor',
  },
  deploymentCenter: {
    id: 'deploymentCenter',
    defaultMessage: 'Deployment Center',
  },
  monitoring: {
    id: 'monitoring',
    defaultMessage: 'Monitoring',
  },
  userRolesAccessManagement: {
    id: 'userRolesAccessManagement',
    defaultMessage: 'User Roles and Access Management',
  },
  userRolesAccessManagementDesc: {
    id: 'userRolesAccessManagementDesc',
    defaultMessage: 'Create, manage and assign different user roles. Define granular permissions for each user role. Manage who can access and change your data and systems.',
  },
  upgradeWorkspace: {
    id: 'upgradeWorkspace',
    defaultMessage: 'Upgrade Workspace',
  },
  addAnnotationEnv: {
    id: 'addAnnotationEnv',
    defaultMessage: 'Add Annotation Environment',
  },
  createTrainingEnv: {
    id: 'createTrainingEnv',
    defaultMessage: 'Create Training Environment',
  },
  addAnnotationEnvDesc: {
    id: 'addAnnotationEnvDesc',
    defaultMessage: 'Create a new Image Annotation Environment for this Workspace.',
  },
  createTrainingEnvDesc: {
    id: 'createTrainingEnvDesc',
    defaultMessage: 'Create a new Training Environment for this Workspace.',
  },
  container: {
    id: 'contiainer',
    defaultMessage: 'Container',
  },
  containerId: {
    id: 'containerId',
    defaultMessage: 'Container ID',
  },
  weight: {
    id: 'weight',
    defaultMessage: 'Weight',
  },
  changeWeight: {
    id: 'changeWeight',
    defaultMessage: 'Change weight',
  },
  restartContainer: {
    id: 'restartContainer',
    defaultMessage: 'Restart Container',
  },
  upgradeContainerDesc: {
    id: 'upgradeContainerDesc',
    defaultMessage: "Set the weight of the container <span class='text-color'>{name}</span> to optimize the application load balancing.",
  },
  updateContainerWeight: {
    id: 'updateContainerWeight',
    defaultMessage: 'Update container weight',
  },
  containerManager: {
    id: 'containerManager',
    defaultMessage: 'Container Manager',
  },
  nodeId: {
    id: 'nodeId',
    defaultMessage: 'Node ID',
  },
  editApplicationName: {
    id: 'editApplicationName',
    defaultMessage: 'Edit Application',
  },
  seeModelLists: {
    id: 'seeModelLists',
    defaultMessage: 'View AI model list',
  },
  seeDataCollectionLists: {
    id: 'seeDataCollectionLists',
    defaultMessage: 'View videos',
  },
  seeImageAnnotationLists: {
    id: 'seeImageAnnotationLists',
    defaultMessage: 'See Image Annotation lists',
  },
  seeModelTrainingLists: {
    id: 'seeModelTrainingLists',
    defaultMessage: 'See Model Training lists',
  },
  seeTerminalAccess: {
    id: 'seeTerminalAccess',
    defaultMessage: 'See Terminal Access Manage',
  },
  seeVideoViewer: {
    id: 'seeVideoViewer',
    defaultMessage: 'See Video Viewer Manage',
  },
  viewAIModelPages: {
    id: 'viewAIModelPages',
    defaultMessage: 'View AI model pages',
  },
  seeDataCollectionPages: {
    id: 'seeDataCollectionPages',
    defaultMessage: 'See Data Collection pages',
  },
  seeImageAnnotationPages: {
    id: 'seeImageAnnotationPages',
    defaultMessage: 'View environments',
  },
  seeModelTrainingPages: {
    id: 'seeModelTrainingPages',
    defaultMessage: 'View environments',
  },
  seeTerminalAccessPages: {
    id: 'seeTerminalAccessPages',
    defaultMessage: 'See Terminal Access Manage pages',
  },
  seeVideoViewerPages: {
    id: 'seeVideoViewerPages',
    defaultMessage: 'See Video Viewer Manage pages',
  },
  updateModel: {
    id: 'updateModel',
    defaultMessage: 'Update AI models',
  },
  aiModelAction: {
    id: 'aiModelAction',
    defaultMessage: 'AI model actions',
  },
  updateDataCollection: {
    id: 'updateDataCollection',
    defaultMessage: 'Update videos',
  },
  videoLibraryActions: {
    id: 'videoLibraryActions',
    defaultMessage: 'Video library actions',
  },
  updateImageAnnotation: {
    id: 'updateImageAnnotation',
    defaultMessage: 'Modify environments',
  },
  updateModelTraining: {
    id: 'updateModelTraining',
    defaultMessage: 'Modify environments',
  },
  updateTerminalAccess: {
    id: 'updateTerminalAccess',
    defaultMessage: 'Update Terminal Access',
  },
  updateVideoViewer: {
    id: 'updateVideoViewer',
    defaultMessage: 'Update Video Viewer',
  },
  addNewModel: {
    id: 'addNewModel',
    defaultMessage: 'Add new AI model',
  },
  addNewDataCollection: {
    id: 'addNewDataCollection',
    defaultMessage: 'Add new videos',
  },
  addNewImageAnnotation: {
    id: 'addNewImageAnnotation',
    defaultMessage: 'Create environments',
  },
  addNewModelTraining: {
    id: 'addNewModelTraining',
    defaultMessage: 'Create environments',
  },
  addNewTerminalAccess: {
    id: 'addNewTerminalAccess',
    defaultMessage: 'Add new Terminal Access',
  },
  addNewVideoViewer: {
    id: 'addNewVideoViewer',
    defaultMessage: 'Add new Video Viewer',
  },
  deleteModel: {
    id: 'deleteModel',
    defaultMessage: 'Remove AI models',
  },
  deleteDataCollection: {
    id: 'deleteDataCollection',
    defaultMessage: 'Remove videos',
  },
  deleteModelTraining: {
    id: 'deleteModelTraining',
    defaultMessage: 'Remove environments',
  },
  deleteImageAnnotation: {
    id: 'deleteImageAnnotation',
    defaultMessage: 'Remove environments',
  },
  stopModelTraining: {
    id: 'stopModelTraining',
    defaultMessage: 'Stop environments',
  },
  stopImageAnnotation: {
    id: 'stopImageAnnotation',
    defaultMessage: 'Stop environments',
  },
  deleteTerminalAccess: {
    id: 'deleteTerminalAccess',
    defaultMessage: 'Delete Terminal Access',
  },
  deleteVideoViewer: {
    id: 'deleteVideoViewer',
    defaultMessage: 'Delete Video Viewer',
  },
  status2FA: {
    id: 'status2FA',
    defaultMessage: '2FA Status',
  },
  findAdditionBlocksAndApp: {
    id: 'findAdditionBlocksAndApp',
    defaultMessage: 'Find additional building blocks and app templates',
  },
  terminalAccess: {
    id: 'terminalAccess',
    defaultMessage: 'Terminal Access',
  },
  manageTerminal: {
    id: 'manageTerminal',
    defaultMessage: 'Manage remote terminal access to enrolled devices',
  },
  videoViewerAccess: {
    id: 'videoViewerAccess',
    defaultMessage: 'Video Viewer Access',
  },
  videoViewer: {
    id: 'videoViewer',
    defaultMessage: 'Video Viewer',
  },
  manageVideo: {
    id: 'manageVideo',
    defaultMessage: 'Manage access to view video output of applications',
  },
  security: {
    id: 'security',
    defaultMessage: 'Security',
  },
  disabled: {
    id: 'disabled',
    defaultMessage: 'Disabled',
  },
  unavailable: {
    id: 'unavailable',
    defaultMessage: 'Unavailable',
  },
  needImageAnnotation: {
    id: 'needImageAnnotation',
    defaultMessage: 'Need Image Annotation?',
  },
  scheduleTeamSales: {
    id: 'scheduleTeamSales',
    defaultMessage: 'Schedule a call with the Viso sales team to upgrade the plans and limits of your Viso Suite Workspace.',
  },
  startWithImageAnnotation: {
    id: 'startWithImageAnnotation',
    defaultMessage: 'Start with Image Annotation',
  },
  startWithTraining: {
    id: 'startWithTraining',
    defaultMessage: 'Start with Model Training',
  },
  setUpVA: {
    id: 'setUpVA',
    defaultMessage: 'Set up a Computer Vision Annotation environments to create and manage datasets for AI model training. Directly access powerful video annotation tools',
  },
  setUpJupyter: {
    id: 'setUpJupyter',
    defaultMessage: 'Set up a JupyterLab Environment to run AI model training within Viso Suite. Get direct access to one or multiple instances for AI model training.',
  },
  contactSales: {
    id: 'contactSales',
    defaultMessage: 'Contact Sales',
  },
  needModelTraining: {
    id: 'needModelTraining',
    defaultMessage: 'Need Model Training?',
  },
  resetNow: {
    id: 'resetNow',
    defaultMessage: 'Reset now',
  },
  sessionDuration: {
    id: 'sessionDuration',
    defaultMessage: 'Session Duration',
  },
  sessionDurationDesc: {
    id: 'sessionDurationDesc',
    defaultMessage: 'One logged in, users will remain signed in until they explicitly sign out. This setting allows you to force users to log in after a certain amount of time has elapsed since their last login.',
  },
  setDuration: {
    id: 'setDuration',
    defaultMessage: 'Set Duration',
  },
  setDurationDesc: {
    id: 'setDurationDesc',
    defaultMessage: 'Set the duration after which session expires and users are prompted for credentials. A new duration setting applies when the user signs out or when the current session expires.',
  },
  configurationManager: {
    id: 'configurationManager',
    defaultMessage: 'Configuration Manager',
  },
  configVersion: {
    id: 'configVersion',
    defaultMessage: 'Config Version',
  },
  lastDeployed: {
    id: 'lastDeployed',
    defaultMessage: 'Last Deployed',
  },
  configurationEditor: {
    id: 'configurationEditor',
    defaultMessage: 'Configuration Editor',
  },
  deployChanges: {
    id: 'deployChanges',
    defaultMessage: 'Deploy Changes',
  },
  configureNode: {
    id: 'configureNode',
    defaultMessage: 'Configure Node',
  },
  configurationVersions: {
    id: 'configurationVersions',
    defaultMessage: 'Configuration Versions',
  },
  editConfiguration: {
    id: 'editConfiguration',
    defaultMessage: 'Edit Configuration',
  },
  deployConfiguration: {
    id: 'deployConfiguration',
    defaultMessage: 'Deploy Configuration'
  },
  versionNote: {
    id: 'versionNote',
    defaultMessage: 'Version Note'
  },
  customize: {
    id: 'customize',
    defaultMessage: 'Customize'
  },
  workspaceLimitsAndUsage: {
    id: 'workspaceLimitsAndUsage',
    defaultMessage: 'Workspace limits and usage'
  },
  configurations: {
    id: 'configurations',
    defaultMessage: 'Configurations',
  },
  applicationTemplate: {
    id: 'applicationTemplate',
    defaultMessage: 'Application Template'
  },
  applicationTemplateDesc: {
    id: 'applicationTemplateDesc',
    defaultMessage: 'The application is available as a template in the workspace.'
  },
  imageVersion: {
    id: 'imageVersion',
    defaultMessage: 'Image Version',
  },
  dataset: {
    id: 'dataset',
    defaultMessage: "Dataset"
  },
  datasets: {
    id: 'datasets',
    defaultMessage: "Datasets"
  },
  project: {
    id: 'project',
    defaultMessage: "Project"
  },
  category: {
    id: 'category',
    defaultMessage: "Category"
  },
  creator: {
    id: 'creator',
    defaultMessage: "Creator"
  },
  datasetInformation: {
    id: 'datasetInformation',
    defaultMessage: 'Dataset Information'
  },
  images: {
    id: 'images',
    defaultMessage: 'Images'
  },
  createDataset: {
    id: 'createDataset',
    defaultMessage: 'Create Dataset'
  },
  mediaType: {
    id: 'mediaType',
    defaultMessage: 'Media type'
  },
  datasetSource: {
    id: 'datasetSource',
    defaultMessage: 'Dataset source'
  },
  creator: {
    id: 'creator',
    defaultMessage: 'Creator'
  },
  asset: {
    id: 'asset',
    defaultMessage: 'Asset'
  },
  source: {
    id: 'source',
    defaultMessage: 'Source'
  },
  converstionSettings: {
    id: 'converstionSettings',
    defaultMessage: "Conversion Settings"
  },
  frameInterval: {
    id: 'frameInterval',
    defaultMessage: 'Frame Interval'
  },
  frameWidth: {
    id: 'frameWidth',
    defaultMessage: 'Frame width'
  },
  frameHeight: {
    id: 'frameHeight',
    defaultMessage: 'Frame height'
  },
  createAnnotations: {
    id: 'createAnnotations',
    defaultMessage: "Create annotations"
  },
  imageAnnotationInstance: {
    id: 'imageAnnotationInstance',
    defaultMessage: 'Image Annotation Instance'
  },
  labelType: {
    id: 'labelType',
    defaultMessage: 'Label Type'
  },
  labelField: {
    id: 'labelField',
    defaultMessage: 'Label Field'
  },
  mergeAnnotation: {
    id: 'mergeAnnotation',
    defaultMessage: "Merge annotation"
  },
  viewDataset: {
    id: 'viewDataset',
    defaultMessage: "View dataset"
  },
  datasetName: {
    id: 'datasetName',
    defaultMessage: "Dataset name"
  },
  cloneDataset: {
    id: 'cloneDataset',
    defaultMessage: "Clone Dataset"
  },
  cloneDatasetSuccessfully: {
    id: 'cloneDatasetSuccessfully',
    defaultMessage: "Dataset cloned successfully"
  },
  shutingDownStart: {
    id: 'shutingDownStart',
    defaultMessage: "Shutting down the device"
  },
  shutDownFailed: {
    id: 'shutDownFailed',
    defaultMessage: "Failed to shut down the device"
  },
  shutDownSuccessfully: {
    id: 'shutingDownSuccessfully',
    defaultMessage: "Device shut down successfully"
  },
  shutDown: {
    id: 'shutDown',
    defaultMessage: "Shut down device"
  },
  details: {
    id: 'details',
    defaultMessage: "Details"
  },
  datasetIsLocked: {
    id: 'datasetIsLocked',
    defaultMessage: 'The Dataset viewer is locked',
  },
  currentlyEditingDataset: {
    id: 'currentlyEditingDataset',
    defaultMessage: 'is currently editing dataset. Do you want to take over? Unsaved changes will be lost.',
  },
  errorOpeningTheDatasetEditor: {
    id: 'errorOpeningTheDatasetEditor',
    defaultMessage: 'Dataset Viewer is not available now. Please try again later.',
  },
  seeDatasetLists: {
    id: 'seeDatasetLists',
    defaultMessage: 'See dataset lists',
  },
  seeDatasetPages: {
    id: 'seeDatasetPages',
    defaultMessage: 'See dataset pages',
  },
  updateDatasets: {
    id: 'updateDatasets',
    defaultMessage: 'Update dataset',
  },
  deleteDataset: {
    id: 'deleteDataset',
    defaultMessage: 'Delete Dataset',
  },
  addNewDatasets: {
    id: 'addNewDatasets',
    defaultMessage: 'Add new dataset',
  },
  saveConfiguration: {
    id: 'saveConfiguration',
    defaultMessage: 'Save Configuration',
  },
  loadConfiguration: {
    id: 'loadConfiguration',
    defaultMessage: 'Load Configuration',
  },
  configurationName: {
    id: 'configurationName',
    defaultMessage: 'Configuration Name'
  },
  configurationDescription: {
    id: 'configurationDescription',
    defaultMessage: 'Configuration Description'
  },
  sourceProfile: {
    id: 'sourceProfile',
    defaultMessage: 'Source Profile'
  },
  nodeType: {
    id: 'nodeType',
    defaultMessage: 'Node Type'
  },
  nodeVersion: {
    id: 'nodeVersion',
    defaultMessage: 'Node Version'
  },
  deleteConfiguration: {
    id: 'deleteConfiguration',
    defaultMessage: 'Delete Configuration'
  },
  videoUploadedSuccessfully: {
    id: 'videoUploadedSuccessfully',
    defaultMessage: 'Video uploaded successfully',
  },
  datasetDelete: {
    id: 'datasetDelete',
    defaultMessage: 'Deletion in progress',
  },
  limitReached: {
    id: 'limitReached',
    defaultMessage: 'Limit reached',
  },
  limitReachedBody: {
    id: 'limitReachedBody',
    defaultMessage: 'You’ve reached the limit of environments. To create more environments, upgrade your current plan.',
  },
  auditLog: {
    id: 'auditLog',
    defaultMessage: 'Audit Log',
  },
  event: {
    id: 'event',
    defaultMessage: 'Event',
  },
  ip: {
    id: 'ip',
    defaultMessage: 'IP',
  },
  time: {
    id: 'time',
    defaultMessage: 'Time',
  },
  approval: {
    id: 'approval',
    defaultMessage: 'Approval'
  },
  experiments: {
    id: 'experiments',
    defaultMessage: 'Experiments'
  },
  experiment: {
    id: 'experiment',
    defaultMessage: 'Experiment'
  },
  jobTraining: {
    id: 'jobTraining',
    defaultMessage: 'Job Training'
  },
  experimentName: {
    id: 'experimentName',
    defaultMessage: 'Experiment name'
  },
  confidenceThreshold: {
    id: 'confidenceThreshold',
    defaultMessage: 'Confidence Threshold'
  },
  overlapThreshold: {
    id: 'overlapThreshold',
    defaultMessage: 'Overlap Threshold'
  },
  modelArchitecture: {
    id: 'modelArchitecture',
    defaultMessage: 'Model Architecture'
  },
  numberOfFramesPerAnnotationJob: {
    id: 'numberOfFramesPerAnnotationJob',
    defaultMessage: 'Number of frames per annotation job'
  },
  annotationTask: {
    id: 'annotationTasks',
    defaultMessage: 'Annotation Tasks'
  },
  incident: {
    id: 'incident',
    defaultMessage: 'Incident'
  },
  incidents: {
    id: 'incidents',
    defaultMessage: 'Incidents'
  },
  operate: {
    id: 'operate',
    defaultMessage: 'Operate'
  },
  incidentManagement: {
    id: 'incidentManagement',
    defaultMessage: 'Incident Management'
  },
});
