import { createSelector } from 'reselect';

const profiles = state => state.deployment || null;

export const getProfilesList = () => createSelector(profiles, state => (state && state.get('profiles') ? state.get('profiles').toJS() : null));

export const getProfilesLoading = () => createSelector(profiles, state => state.get('profiles').toJS().loading);

export const getProfilesError = () => createSelector(profiles, state => state.get('profiles').toJS().error);

const devices = state => state.deployment;

export const getDevicesList = () => createSelector(devices, state => (state && state.get('devices') ? state.get('devices').toJS() : null));

export const getPendingDeviceList = () => createSelector(devices, state => (state && state.get('pendingDevices') ? state.get('pendingDevices').toJS() : null));

export const getDevicesLoading = () => createSelector(devices, state => state.get('devices').toJS().loading);

export const getDevicesError = () => createSelector(devices, state => (state && state.get('devices') ? state.get('devices').toJS().error : null));
