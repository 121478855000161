import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getPermission } from 'utils/permissions';
import { SETTING_VIEW } from './constants';
import SettingTypes from './type/Settings';

class Settings extends React.Component {
  state = {
    aiModel: false,
    application: false,
    billing: false,
    dashboard: false,
    device: false,
    module: false,
    profile: false,
    user: false,
    video: false,
    deviceOffline: false
  }

  componentDidMount() {
    this.setState({
      aiModel: this.props.settings.aiModel,
      application: this.props.settings.application,
      billing: this.props.settings.billing,
      dataset: this.props.settings.dataset,
      dashboard: this.props.settings.dashboard,
      device: this.props.settings.device,
      module: this.props.settings.module,
      profile: this.props.settings.profile,
      user: this.props.settings.user,
      video: this.props.settings.video,
      deviceOffline: this.props.settings.deviceOffline
    });
  }

  // eslint-disable-next-line
  componentWillReceiveProps(nextProps) {
    this.setState({
      aiModel: nextProps.settings.aiModel,
      application: nextProps.settings.application,
      dataset: nextProps.settings.dataset,
      billing: nextProps.settings.billing,
      dashboard: nextProps.settings.dashboard,
      device: nextProps.settings.device,
      module: nextProps.settings.module,
      profile: nextProps.settings.profile,
      user: nextProps.settings.user,
      video: nextProps.settings.video,
      deviceOffline: nextProps.settings.deviceOffline,
    });
  }

  onChange = (name, value) => {
    this.setState({ [name]: value }, () => {
      this.props.updateNotificationSetting({ [name]: value });
    });
  }

  render() {
    const { detailRole } = this.props;
    const settingsClass = classNames('notifications-bar__tab notifications-bar__settings', {
      'notifications-bar__tab_visible': this.props.activeState === SETTING_VIEW,
      card_loading: this.props.isLoading,
    });
    const permissionBilling = getPermission('permissionBilling', detailRole.role);
    const permissionDashboard = getPermission('permissionDashboard', detailRole.role);
    const permissionModules = getPermission('permissionModules', detailRole.role);
    const permissionApplication = getPermission('permissionApplication', detailRole.role);
    const permissionDataset= getPermission('permissionDataset', detailRole.role);
    const permissionUsers = getPermission('permissionUsers', detailRole.role);
    const permissionDevices = getPermission('permissionDevices', detailRole.role);
    const permissionProfiles = getPermission('permissionProfiles', detailRole.role);
    const permissionModel = getPermission('permissionAIModel', detailRole.role);
    const permissionDataCollection = getPermission('permissionDataCollection', detailRole.role);

    return (
      <div className={settingsClass}>
        {permissionModules.includes('read')
        && (
          <SettingTypes
            title="Modules"
            parentClass="toggle_accent3"
            value={this.state.module}
            onChange={this.onChange}
            name="module"
            desc="Get notified when new modules are installed, existing models are updated or uninstalled"
          />
        )
        }
        {permissionApplication.includes('read')
        && (
          <SettingTypes
            title="Applications"
            value={this.state.application}
            onChange={this.onChange}
            name="application"
            parentClass="toggle_accent3"
            desc="Get notified when new applications are created, imported, updated or removed."
          />
        )
        }
        {permissionDataset.includes('read')
        && (
          <SettingTypes
            title="Dataset"
            value={this.state.dataset}
            onChange={this.onChange}
            name="dataset"
            parentClass="toggle_accent3"
            desc="Get notified when new dataset are created or removed."
          />
        )
        }
        {permissionDataCollection.includes('read')
        && (
          <SettingTypes
            title="Videos"
            parentClass="toggle_accent3"
            value={this.state.video}
            onChange={this.onChange}
            name="video"
            desc="Get notified when new videos are added to the workspace library, or removed."
          />
        )
        }
        {permissionModel.includes('read')
        && (
          <SettingTypes
            title="AI Models"
            value={this.state.aiModel}
            onChange={this.onChange}
            name="aiModel"
            showUpdateChange
            parentClass="toggle_accent3"
            desc="Get notified when new AI models or versions are added, or models removed."
          />
        )
        }
        {permissionProfiles.includes('read')
        && (
          <SettingTypes
            title="Profiles"
            value={this.state.profile}
            onChange={this.onChange}
            name="profile"
            parentClass="toggle_accent3"
            desc="Receive notifications when new profiles are created, deployed to devices or removed."
          />
        )
        }
        {permissionDevices.includes('read')
        && (
          <SettingTypes
            title="Devices"
            value={this.state.device}
            onChange={this.onChange}
            name="device"
            showUpdateChange
            parentClass="toggle_accent3"
            desc="Get notified when device images are changed, devices are enrolled or removed."
          />
        )
        }
        {permissionDevices.includes('read')
        && (
          <SettingTypes
            title="Device offline email"
            value={this.state.deviceOffline}
            onChange={this.onChange}
            name="deviceOffline"
            showUpdateChange
            parentClass="toggle_accent3"
            desc="Get notified when device is offline"
          />
        )
        }
        {permissionUsers.includes('read')
        && (
          <SettingTypes
            title="Users"
            parentClass="toggle_accent3"
            value={this.state.user}
            onChange={this.onChange}
            name="user"
            desc="Get notified when new users join the workspace, are removed or updated."
          />
        )
        }
        {permissionDashboard.includes('read')
        && (
          <SettingTypes
            title="Dashboards"
            value={this.state.dashboard}
            onChange={this.onChange}
            name="dashboard"
            showUpdateChange
            parentClass="toggle_accent3"
            desc="Get notified when dashboards are created or removed from your workspace."
          />
        )
        }
        {permissionBilling.includes('read')
        && (
          <SettingTypes
            title="Billing"
            value={this.state.billing}
            onChange={this.onChange}
            name="billing"
            parentClass="toggle_accent3"
            desc="Get notified when new invoices are available, payments fail and more."
          />
        )
        }
      </div>
    );
  }
}

Settings.propTypes = {
  activeState: PropTypes.string,
};


export default Settings;
