import { jsonToGraphQLQuery } from 'json-to-graphql-query'
import axiosObj from './config'

const BASE_URL = `${process.env.API_URL}/auth/graphql`;

const ANNOUNCEMENT = {
  active: true,
  canClose: true,
  forAdmin: true,
  color: true,
  start: true,
  end: true,
  message: true
}

export function announcement() {
  const query = {
    query: {
      announcement: {
        ...ANNOUNCEMENT
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: "POST",
    url: BASE_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}
