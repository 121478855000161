import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import messages from 'utils/messages';
import { SearchIcon, CloseIcon } from 'images/common';
import { FILTER_TYPE } from '../constants';

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }

  state = {
    filterValue: '',
  }

  componentDidMount() {
    this.textInput.current.focus();
  }

  onApply = () => {
    const { filterValue } = this.state;
    const { name, onFilterApply } = this.props;

    onFilterApply(name, filterValue, FILTER_TYPE.SEARCH);
  }

  onClear = () => {
    this.textInput.current.value = '';

    this.setState({ filterValue: '' });
  }

  onChange = (e) => {
    const { value } = e.target;

    this.setState({ filterValue: value });
  }

  onEnter = (e) => {
    if (e.key === 'Enter' && this.state.filterValue) {
      e.stopPropagation();
      e.preventDefault();
      this.onApply();
    }

    return false;
  }

  render() {
    const { title, customClass } = this.props;
    const customInputClass = `text-field text-field_nolabel text-field_${customClass}`;

    const btnClass = classNames('filters-bar__apply', {
      [customClass]: customClass,
      'filters-bar__disabled': !this.state.filterValue,
    });

    const iconClass = classNames('cursor', {
      'text-field__closeIcon': this.state.filterValue,
    });

    return (
      <>
        <div className="filters-bar__heading">
          {title}
        </div>
        <div className={customInputClass}>
          <input
            onKeyDown={this.onEnter}
            type="text"
            placeholder="Search"
            className="text-field__input"
            onChange={this.onChange}
            ref={this.textInput}
          />
          <div className="text-field__underscore" />
          <div className="text-field__icon">{!this.state.filterValue && <SearchIcon />}</div>
        </div>
        {this.state.filterValue && <span className={iconClass} onClick={() => this.onClear()}><CloseIcon /></span>}
        <button type="button" className={btnClass} onClick={this.onApply}>
          <FormattedMessage {...messages.apply} />
        </button>
      </>
    );
  }
}

Search.propTypes = {
  name: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onFilterApply: PropTypes.func,
  customClass: PropTypes.string,
};

export default Search;
