import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getPermission } from 'utils/permissions';

import {FormattedMessage} from 'react-intl'
import messages from 'utils/messages'
import {
  KeyIcon,
  ActivateCheck,
  DeactivateIcon,
  ArchiveIcon,
  DeleteIcon
} from 'images/common';

export const SingleMenu = ({
  status,
  id,
  showMenu,
  closeMenu,
  onToogleMenu,
  detailRole,
  onArchive,
  hasPermissionToUpdate,
  isPrimaryOwner,
  hideParentClass,
  onAssignRole,
  loggedUserId,
  onDeactivate,
  onActivate,
  hasAccess,
  onDelete
}) => {
  const permission = getPermission('permissionUsers', detailRole);

  const deactivateClass = classNames('actions__option', {
    actions__option_disabled: status === 'inactive' || status === 'archived' || id === loggedUserId || !hasPermissionToUpdate || isPrimaryOwner || !hasAccess,
    cursor: !status
  });

  const assignUserClass = classNames('actions__option', {
    actions__option_disabled: isPrimaryOwner || !hasPermissionToUpdate || !hasAccess || id === loggedUserId,
    cursor: !isPrimaryOwner
  });

  const activeClass = classNames('actions__option', {
    actions__option_disabled: status === 'active' || id === loggedUserId || !hasPermissionToUpdate || !hasAccess,
    cursor: status !== 'active'
  });

  const archiveClass = classNames('actions__option', {
    actions__option_disabled: status !== 'inactive' || id === loggedUserId || !hasPermissionToUpdate || isPrimaryOwner || !hasAccess,
    cursor: status === 'inactive'
  });

  const deleteClass = classNames('actions__option', {
    actions__option_disabled: status !== 'archived' || id === loggedUserId || !hasPermissionToUpdate || isPrimaryOwner || !hasAccess,
    cursor: status === 'archived'
  });

  const unarchiveClass = classNames('actions__option', {
    actions__option_disabled: status === 'archived' || id === loggedUserId || !hasPermissionToUpdate || !hasAccess,
    cursor: status !== 'archived'
  });

  const onDeactivateMenu = (rowId, e) => {
    e.preventDefault();
    e.stopPropagation();
    onToogleMenu(e, rowId);

    if (status === 'active' && rowId !== loggedUserId && hasPermissionToUpdate && !isPrimaryOwner && hasAccess) {
      closeMenu();
      onDeactivate(rowId, e);
    }
  };

  const onAssignRoleMenu = (rowId, e) => {
    e.preventDefault();
    e.stopPropagation();
    onToogleMenu(e, rowId);

    if (!hasPermissionToUpdate) {
      return
    }

    if (isPrimaryOwner) {
      return
    }

    if (hasAccess && rowId !== loggedUserId) {
      closeMenu();
      onAssignRole(rowId, e);
    }
  };

  const onActivateMenu = (rowId, e) => {
    e.preventDefault();
    e.stopPropagation();
    onToogleMenu(e, rowId);

    if (status !== 'active' && rowId !== loggedUserId && hasPermissionToUpdate && hasAccess) {
      closeMenu();
      onActivate(rowId, e);
    }
  };

  const onArchiveMenu = (rowId, e) => {
    e.preventDefault();
    e.stopPropagation();
    onToogleMenu(e, rowId);

    if (status === 'inactive' && rowId !== loggedUserId && hasPermissionToUpdate && !isPrimaryOwner && hasAccess) {
      closeMenu();
      onArchive(rowId, e);
    }

    if (status === 'archived' && rowId !== loggedUserId) {
      return false
    }
  };


  const onDeleteMenu = (rowId, e) => {
    e.preventDefault();
    e.stopPropagation();
    onToogleMenu(e, rowId);

    if (status === 'archived' && rowId !== loggedUserId && hasPermissionToUpdate && !isPrimaryOwner && hasAccess) {
      closeMenu();
      onDelete(rowId, e);
    }
  };

  const parentClass = classNames({
    'actions__dropdown_visible': showMenu,
    'actions__dropdown': !hideParentClass
  });

  return (
    <>
    {(permission.includes('create') || permission.includes('delete')) &&
      <div className={parentClass}>
        {permission.includes('create') && (
          <>
            <a
              href="javascript:void(0)"
              className={assignUserClass}
              onClick={e => onAssignRoleMenu(id, e)}
            >
              <span className="action-icon">
                <div className="action_icon_item">
                  <KeyIcon/>
                </div>
                <div className="action-icon-text">
                  <FormattedMessage {...messages.assignRoleToUser}/>
                </div>
              </span>
            </a>
            <a
              href="javascript:void(0)"
              className={deactivateClass}
              onClick={e => onDeactivateMenu(id, e)}
            >
              <span className="action-icon">
                <div className="action_icon_item">
                  <DeactivateIcon />
                </div>
                <div className="action-icon-text">
                  <FormattedMessage {...messages.deactivateUser}/>
                </div>
              </span>
            </a>
            <a
              href="javascript:void(0)"
              className={activeClass}
              onClick={e => onActivateMenu(id, e)}
            >
              <span className="action-icon">
                <div className="action_icon_item">
                  <ActivateCheck />
                </div>
                <div className="action-icon-text">
                  <FormattedMessage {...messages.activateUser}/>
                </div>
              </span>
            </a>
          </>
        )}
        {permission.includes('delete') && (
          <a
            href="javascript:void(0)"
            className={status === 'archived' ? unarchiveClass : archiveClass}
            onClick={e => onArchiveMenu(id, e)}
          >
            <span className="action-icon">
              <div className="action_icon_item">
                <ArchiveIcon />
              </div>
              <div className="action-icon-text">
                <FormattedMessage {...messages.archiveUser}/>
              </div>
            </span>
          </a>
        )}
        {permission.includes('delete') && (
          <a
            href="javascript:void(0)"
            className={deleteClass}
            onClick={e => onDeleteMenu(id, e)}
          >
            <span className="action-icon">
              <div className="action_icon_item">
                <DeleteIcon />
              </div>
              <div className="action-icon-text">
                <FormattedMessage {...messages.deleteUsers}/>
              </div>
            </span>
          </a>
        )}
      </div>
    }
    </>
  );
};

SingleMenu.propTypes = {
  profile: PropTypes.any
};

export default SingleMenu;
