import {
  takeLatest,
  put,
} from 'redux-saga/effects';
import {
  SET_LOCALE_LOCALLY,
  SET_WORKSPACE_LOCALE_LOCALLY,
} from './constants';
import {
  changeLocale,
} from './actions';

const language = {
  english: 'en',
  german: 'de',
};

function* setLocaleLocallySaga(action) {
  const { payload } = action;

  localStorage.setItem('locale', payload);
  yield put(changeLocale(payload));
}

function* setWorkspaceLocaleLocallySaga(action) {
  const { payload } = action;
  const locale = localStorage.getItem('locale');

  localStorage.setItem('locale_workspace', language[payload] || 'en');

  if (!locale) {
    yield put(changeLocale(payload));
  }
}

export default function* loginRootSaga() {
  yield takeLatest(SET_LOCALE_LOCALLY, setLocaleLocallySaga);
  yield takeLatest(SET_WORKSPACE_LOCALE_LOCALLY, setWorkspaceLocaleLocallySaga);
}
