import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ProcessPage from 'components/ProcessPage';
import Button from 'components/Button';

import Verification from 'components/Verification';

class VerificationCode extends Component {
  state = {
    codes: [],
    activeIndex: 0,
    type: 'code',
  }

  onChange = (e, index) => {
    const value = e.key || e.currentTarget.value;

    this.state.codes[index] = value;
    this.setState({ activeIndex: parseInt(index, 10) + 1 });
    this.setState({ codes: this.state.codes });
  }


  onUpdate = value => this.setState({ codes: value })

  onUpdateOption = () => {
    const activeType = this.state.type;

    if (activeType === 'code') {
      return this.setState({ type: 'recoveryKey' });
    }
    return this.setState({ type: 'code' });
  }

  render() {
    const code = this.state.codes.length && this.state.codes.reduce((sum, item) => `${sum}${item}`);

    return (
      <ProcessPage
        onCancel={this.props.onClose}
      >
        {() => (
          <>
            <div className="headline">
              <h1 className="headline__title">{this.state.type === 'code' ? 'Verification Code' : 'Recovery Key'}</h1>
              <h4 className="headline__subtitle">
                Please enter the 6-digit security code from your mobile phone app.
              </h4>
            </div>
            <Verification
              onUpdate={this.onUpdate}
              codes={this.state.codes}
              activeIndex={this.state.activeIndex}
              onChange={this.onChange}
            />
            <div className="login__actions">
              <Button
                disabled={code.length !== 6}
                onClick={() => {
                  if (code.length === 6) {
                    this.props.onSubmit(this.state.codes, this.state.type);
                  }
                }}
                className="button"
              >
                Verify
              </Button>
              <div className="footer_link" onClick={this.onUpdateOption}>
                {this.state.type === 'code' ? 'Use the recovery key' : 'Use the verification code'}
              </div>
            </div>
          </>
        )}
      </ProcessPage>
    );
  }
}

VerificationCode.propTypes = {
  color: PropTypes.string,
  image: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default VerificationCode;
