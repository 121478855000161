import React from 'react';

export default () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 17.1C13.68 17.1 17.1 13.68 17.1 9.5C17.1 5.32 13.68 1.9 9.5 1.9C5.32 1.9 1.9 5.32 1.9 9.5C1.9 13.68 5.32 17.1 9.5 17.1ZM9.5 0C14.725 0 19 4.275 19 9.5C19 14.725 14.725 19 9.5 19C4.275 19 0 14.725 0 9.5C0 4.275 4.275 0 9.5 0ZM9.975 10.26L5.415 12.92L4.75 11.59L8.55 9.405V4.75H9.975V10.26Z"
      fill="currentColor"
    />
  </svg>
);
