import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames'

import { SOLUTION_ACCENT } from 'utils/constants';

class EstimateItem extends React.Component {
  render() {
    const { description, endpoints, unitPrice, amount, hasBorder, isBold, currency } = this.props;
    const tableborder = classNames('data-table__row data-table__height1', {
      'data-table__border': hasBorder
    })

    const boldClass = classNames('data-table__td pull-right data-table__td_dark', {
      'fw500': isBold
    })

    return (
      <tr className={tableborder}>
        <th className="data-table__td data-table__td_1" />
        <td className="data-table__td data-table__td_dark fw500">
          {description}
        </td>
        <td className={boldClass}>{endpoints}</td>
        <td className="data-table__td pull-right data-table__td_dark">{unitPrice}</td>
        <td className="data-table__td data-table__td_dark fw500 pull-right">{currency} {amount}</td>
        <th className="data-table__td data-table__td_1 pull-right data-table__td_dark" />
      </tr>
    );
  }
}

EstimateItem.propTypes = {
  color: PropTypes.string,
  name: PropTypes.string,
  newSubscriptions: PropTypes.string,
  totalSubscriptions: PropTypes.string,
  earningPeriod: PropTypes.string,
  amount: PropTypes.string
};

EstimateItem.defaultProps = {
  color: SOLUTION_ACCENT
};

export default EstimateItem;
