import { fromJS } from 'immutable';
import { LOGOUT } from 'containers/App/constants';
import {
  LIST_CATEGORY_REQUEST,
  LIST_CATEGORY_SUCCESS,
  LIST_CATEGORY_ERROR,
  LIST_SOLUTION_BY_CATEGORY_SUCCESS,
  LIST_SOLUTION_BY_CATEGORY_REQUEST,
  LIST_SOLUTION_BY_CATEGORY_ERROR,
  LIST_MARKETPLACE_SOLUTION_REQUEST,
  LIST_MARKETPLACE_SOLUTION_SUCCESS,
  LIST_MARKETPLACE_SOLUTION_ERROR,
  LIST_MARKETPLACE_SOLUTIONS_REQUEST,
  LIST_MARKETPLACE_SOLUTIONS_SUCCESS,
  LIST_MARKETPLACE_SOLUTIONS_ERROR,
} from './constants';

export const initialState = fromJS({
  marketCategory: [],
  solution: {},
  solutionByCategory: [],
  solutions: {
    count: 0,
    data: [],
  },
  isLoading: false,
});

function marketplaceReducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT:
      return initialState;
    case LIST_CATEGORY_REQUEST:
      return state.set('marketCategory', []).set('isLoading', true);
    case LIST_CATEGORY_SUCCESS:
      return state.set('marketCategory', action.payload).set('isLoading', false);
    case LIST_CATEGORY_ERROR:
      return state.set('marketCategory', []).set('isLoading', false);
    case LIST_SOLUTION_BY_CATEGORY_REQUEST:
      return state.set('solutionByCategory', []);
    case LIST_SOLUTION_BY_CATEGORY_SUCCESS:
      return state.set('solutionByCategory', action.payload);
    case LIST_SOLUTION_BY_CATEGORY_ERROR:
      return state.set('solutionByCategory', []);
    case LIST_MARKETPLACE_SOLUTIONS_REQUEST:
      return state.set('solutions', { count: 0, data: [] }).set('isLoading', true);
    case LIST_MARKETPLACE_SOLUTIONS_SUCCESS:
      return state.set('solutions', action.payload).set('isLoading', false);
    case LIST_MARKETPLACE_SOLUTIONS_ERROR:
      return state.set('isLoading', false);
    case LIST_MARKETPLACE_SOLUTION_REQUEST:
      return state.set('solution', null).set('isLoading', true);
    case LIST_MARKETPLACE_SOLUTION_SUCCESS:
      return state.set('solution', action.payload).set('isLoading', false);
    case LIST_MARKETPLACE_SOLUTION_ERROR:
      return state.set('isLoading', false);
    default:
      return state;
  }
}

export default marketplaceReducer;
