import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { setPaymentMethod } from "api/billing";
import Breadcrumb, { BreadcrumbItem } from "components/Breadcrumb";
import DetailScroll from "components/Detail/Scroll";
import Modal from "components/Modal";
import SecondaryModal from "components/SecondaryModal";
import VerticalButtonMenu from "components/VerticalButtonMenu";
import CardForm from "containers/Billing/components/CardForm";
import NotFound from "containers/NotFoundPage";
import { getDetailRole } from "containers/Settings/AccessRole/selectors";
import { makeSelectMe } from "containers/Settings/User/selectors";
import { fetchWorkspaceDetailRequest } from "containers/Settings/Workspace/actions";
import { makeSelectFetchWorkspace } from "containers/Settings/Workspace/selectors";
import TopNav from "containers/TopNav";
import { defaultNotifier } from "functions/notificationHandler";
import { PaymentIcon } from "images/common";
import Banner from "components/Banner";
import PermissionWrapper from "PermissionWrapper";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import slugify from "react-slugify";
import { compose } from "redux";
// import {Link} from 'react-router-dom'
import { createStructuredSelector } from "reselect";
import { routeMapping } from "routes";
import { makePlanData } from "containers/Login/selectors";
import { fetchPlansRequest } from "containers/Login/actions";
import { SOLUTION_ACCENT } from "utils/constants";
import injectReducer from "utils/injectReducer";
import injectSaga from "utils/injectSaga";
import messages from "utils/messages";
import {
  getBillingDetailsRequest,
  getInvoicesRequest,
  getSubscriptionDetailsRequest,
  getUpcomingInvoicesRequest,
  updateBillingRequest,
} from "./actions";
import Balance from "./components/details/Balance";
import SummaryBilling from "./components/details/BillingSummary";
import Contact from "./components/details/Contact";
import Estimate from "./components/details/Estimate";
import History from "./components/details/History";
import Information from "./components/details/Information";
import PlanLimitPage from "./components/details/PlanLimitPage";
import reducer from "./reducer";
import saga from "./saga";
import {
  getBillingDetails,
  getBillingLoadingState,
  getInvoices,
  getSubscriptionDetails,
  getSubscriptionLoadingState,
} from "./selectors";
import { getPermission } from "utils/permissions";
import PreLoaderDetail from "components/PreLoader/PreLoaderDetail";
import ProcessPage from "components/ProcessPage";
import Viso from "images/billing/viso.png";
import {withRouter} from 'withRouter';

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

class Billing extends React.Component {
  state = {
    summaryPage: false,
    paymentMethodId: "",
    showPaymentEditModal: false,
    showPaymentModal: false,
  };

  componentDidMount() {
    const { detailRole, type = "none" } = this.props;

    const permission = getPermission("permissionBilling", detailRole.role);

    if (!permission.includes(type)) {
      this.props.fetchPlansRequest();
      this.props.getBillingDetailsRequest();
      this.props.getInvoicesRequest();
      this.props.getSubscriptionDetailsRequest();
      this.props.fetchWorkspaceDetailRequest();
      const { workspace } = this.props;

      if (!workspace.hasPaymentSource) {
        this.setState({ showPaymentModal: true });
      }
    }
  }

  redirectToSubscription = () => {
    const { workspace } = this.props;

    const wsPath = workspace && slugify(workspace.name);

    this.props.router.navigate(
      `/${wsPath}${routeMapping.MANAGE_SUBSCRIPTION.path}`
    );
  };

  redirectToBillingPage = () => {
    const { workspace } = this.props;

    const wsPath = workspace && slugify(workspace.name);

    this.props.router.navigate(`/${wsPath}${routeMapping.BILLING.path}`);
  };

  onGetPaymentInfo = (data) => {
    if (data.paymentMethod) {
      return this.setState({ paymentMethodId: data.paymentMethod.id });
    }

    return this.setState({ paymentMethodId: "" });
  };

  onSubmitPayment = async () => {
    if (this.state.paymentMethodId) {
      this.setState({ isLoading: true });
      try {
        const { data } = await setPaymentMethod({
          paymentMethodId: this.state.paymentMethodId,
        });
        this.setState({ isLoading: false });

        if (data.errors) {
          return defaultNotifier(data.errors);
        }

        this.setState({ showModal: false });
        this.props.fetchWorkspaceDetailRequest();
        defaultNotifier("Payment settings updated.");
      } catch (err) {
        defaultNotifier(err);
      }
    }
  };

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  showSummaryPage = () => {
    this.setState({ isSummaryPage: true });
  };

  hideSummaryPage = () => {
    this.setState({ isSummaryPage: false });
  };

  onShowPage = () => {
    this.setState({ showPlanPageLimit: true });
  };

  onHidePage = () => {
    this.setState({ showPlanPageLimit: false });
  };

  onSkip = () => {
    this.setState({ showPaymentModal: false });
  };

  openPaymentModal = () => {
    this.setState({ showPaymentEditModal: true, showPaymentModal: false });
  };

  closePaymentModal = () => {
    this.setState({ showPaymentEditModal: false });
  };

  renderModal = () => {
    const { workspace } = this.props;

    const buttons = [
      <button
        className="modal__confirm button-success button button_xs button-no-shadow"
        key="confirm"
        disabled={!this.state.paymentMethodId || this.state.isLoading}
        onClick={this.onSubmitPayment}
      >
        <FormattedMessage {...messages.confirm} />
      </button>,
    ];

    return (
      <Modal
        classNames="modal__frame4"
        buttons={buttons}
        title={"Confirm payment method"}
        body={
          <>
            <div className="billing-modal-info billing-info-accent0 mt_125">
              <span className="billing-info-explore">
                Please add a valid payment method to your workspace to continue
                using Viso Suite.
              </span>
            </div>
            <div className="payment_stripe_input ">
              <div className="description" />
              <div className="billing_payment_icon">
                <PaymentIcon />
                Secured by Stripe
              </div>
              <Elements stripe={stripePromise}>
                <CardForm
                  theme={workspace && workspace.contrast}
                  color={"accent0"}
                  onGetPaymentInfo={this.onGetPaymentInfo}
                  isLoading={false}
                  interval={"monthly"}
                />
              </Elements>
            </div>
          </>
        }
        onClose={this.onCloseModal}
      />
    );
  };

  onOpenInfoModal = () => {
    this.setState({ isBillingOpen: true });
  };

  onCloseInfoModal = () => {
    this.setState({ isBillingOpen: false });
  };

  onOpenContactModal = () => {
    this.setState({ isContactOpen: true });
  };

  onCloseContactModal = () => {
    this.setState({ isContactOpen: false });
  };

  onOpenHistoryModal = () => {
    this.setState({ isHistoryOpen: true });
  };

  onCloseHistoryModal = () => {
    this.setState({ isHistoryOpen: false });
  };

  onOpenEstimateModal = () => {
    this.setState({ isEstimateOpen: true });
  };

  onCloseEstimateModal = () => {
    this.setState({ isEstimateOpen: false });
  };

  render() {
    const { isSummaryPage } = this.state;
    const {
      color,
      billing,
      invoices,
      contact,
      upcomingInvoices,
      isLoading,
      workspace,
      me,
      subscription,
    } = this.props;

    if (this.props.subscriptionLoading) {
      return <PreLoaderDetail />;
    }

    if (isSummaryPage) {
      return (
        <SummaryBilling
          onClose={this.hideSummaryPage}
          billing={billing}
          invoices={invoices}
          contact={contact}
          upcomingInvoices={upcomingInvoices}
        />
      );
    }

    if (this.state.showPlanPageLimit) {
      return <PlanLimitPage color={color} onClose={this.onHidePage} />;
    }

    return (
      <PermissionWrapper name="permissionBilling">

        <ProcessPage 
          onCancel={() => this.props.onClose()}
          banner={
          <Banner
          isPrimary
          desc={<span>Payment Failure. Please ensure <span className="bold">pament of due invoices</span> to keep acccess to your workspace.</span>}
        />
        }>
          {() => (
            <>
              <TopNav
                addSpacing
                header={<FormattedMessage {...messages.billing} />}
                hideNotification
                onBack={() =>
                  this.props.router.navigate(
                    `/${workspace && slugify(workspace.name)}${
                      routeMapping.SETTINGS.path
                    }`
                  )
                }
              >
                <VerticalButtonMenu color={color}>
                  <a
                    href="javascript:void(0)"
                    className="actions__option"
                    onClick={this.redirectToSubscription}
                  >
                    <FormattedMessage {...messages.manageSubscriptions} />
                  </a>
                </VerticalButtonMenu>
              </TopNav>
              <div>
                <>
                  <>
                    <div className="headline">
                      <div className={`headline__icon headline__icon_4`}>
                        <img src={Viso} width="52" height="52" alt="billing" />
                      </div>
                      <h1 className="headline__title textLeft">
                        <FormattedMessage {...messages.billing} />
                      </h1>
                    </div>
                    <Breadcrumb customClass={color}>
                      <BreadcrumbItem />
                      <BreadcrumbItem route="Your workspace has unpaid invoices. Please pay these invoices to reactivate your workspace." />
                    </Breadcrumb>

                    <div className="actions-nav">
                      <VerticalButtonMenu color={color}></VerticalButtonMenu>
                    </div>

                    {billing && (
                      <div className="grid-row mobile-page">
                        <div className="grid-col grid-col_sm-12">
                          <Information
                            color={color}
                            onUpdate={this.props.updateBillingRequest}
                            billing={billing}
                            isLoading={isLoading}
                            onOpen={this.onOpenInfoModal}
                            onClose={this.onCloseInfoModal}
                            isOpen={this.state.isBillingOpen}
                            workspaceName={
                              this.props.workspace && this.props.workspace.name
                            }
                          />
                          <Contact
                            color={color}
                            billing={billing}
                            isLoading={isLoading}
                            isOpen={this.state.isContactOpen}
                            onOpen={this.onOpenContactModal}
                            onClose={this.onCloseContactModal}
                            subDomain={
                              this.props.workspace &&
                              this.props.workspace.subDomain
                            }
                            workspaceName={
                              this.props.workspace && this.props.workspace.name
                            }
                          />
                          <History
                            color={color}
                            isLoading={isLoading}
                            plans={this.props.plans}
                            billing={billing}
                            isOpen={this.state.isHistoryOpen}
                            onOpen={this.onOpenHistoryModal}
                            onClose={this.onCloseHistoryModal}
                            getInvoicesRequest={this.props.getInvoicesRequest}
                            invoices={invoices && invoices.data}
                            workspace={workspace}
                            workspaceName={
                              this.props.workspace && this.props.workspace.name
                            }
                          />
                        </div>
                        <div className="grid-col grid-col_sm-12 min-width-300">
                          <Estimate
                            color={color}
                            isBillingPage
                            isOpen={this.state.isEstimateOpen}
                            onOpen={this.onOpenEstimateModal}
                            onClose={this.onCloseEstimateModal}
                            plans={this.props.plans}
                            isLoading={isLoading}
                            billing={billing}
                            subscriptionDetails={subscription}
                            subscriptionLoading={this.props.subscriptionLoading}
                            workspace={this.props.workspace}
                            upcomingInvoices={subscription.upcomingInvoices}
                          />
                          <Balance
                            color={color}
                            isLoading={isLoading}
                            theme={
                              this.props.workspace &&
                              this.props.workspace.contrast
                            }
                            billing={billing}
                          />
                        </div>
                      </div>
                    )}
                  </>
                </>
              </div>
            </>
          )}
        </ProcessPage>
      </PermissionWrapper>
    );
  }
}

Billing.defaultProps = {
  color: SOLUTION_ACCENT,
};

const withReducer = injectReducer({ key: "billing", reducer });
const withSaga = injectSaga({ key: "billing", saga });

const mapStateToProps = createStructuredSelector({
  billing: getBillingDetails(),
  invoices: getInvoices(),
  me: makeSelectMe(),
  plans: makePlanData(),
  subscriptionLoading: getSubscriptionLoadingState(),
  detailRole: getDetailRole(),
  subscription: getSubscriptionDetails(),
  isLoading: getBillingLoadingState(),
  workspace: makeSelectFetchWorkspace(),
});

const withConnect = connect(mapStateToProps, {
  getBillingDetailsRequest,
  updateBillingRequest,
  fetchPlansRequest,
  getInvoicesRequest,
  fetchWorkspaceDetailRequest,
  getSubscriptionDetailsRequest,
  getUpcomingInvoicesRequest,
});

export default compose(
  withReducer,
  withSaga,
  withConnect,
  withConnect,
  withRouter
)(Billing);
