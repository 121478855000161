import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {withRouter} from 'withRouter';
import {FormattedMessage} from 'react-intl'

import messages from 'utils/messages'
import SelectList from 'containers/SelectList'
import UserContainer from 'containers/Settings/User/UserContainer';

import {
  getBillingDetailsRequest,
} from '../actions'
import {updateBillingContact} from 'api/billing'
import {
  fetchWorkspaceRequest
} from 'containers/Settings/Workspace/actions'
import { defaultNotifier } from 'functions/notificationHandler';

class UpdateInformation extends Component {
  state = {
    selectedIds: '',
  }

  componentDidMount() {
    this.setState({selectedIds: this.props.selectedId})
  }

  onSelect = (id, e) => {
    e.stopPropagation()
    this.setState({ selectedIds: id })
  }

  onReset = () => {
    this.setState({selectedIds: ''})
  }

  onSubmit = async () => {
    const {data} = await updateBillingContact({userId: this.state.selectedIds})

    if (data.errors) {
      return defaultNotifier('Failed to update the contact.')
    }
    defaultNotifier('Billing contact updated.')

    this.props.getBillingDetailsRequest()
    this.props.onClose();
  }

  onReset = () => {
    this.setState({ selectedIds: [] })
  };

  render() {
    const {selectedIds} = this.state

    return (
      <SelectList
        onSubmit={this.onSubmit}
        confirmText={<FormattedMessage {...messages.confirm}/> }
        onClose={this.props.onClose}
        selectedIds={selectedIds ? [selectedIds] : []}
        onSelect={this.onSelect}
        onReset={this.onReset}
        customClass={this.props.color}
        onBack={this.props.onBack}
        title={<FormattedMessage {...messages.billingContact}/>}
        hideRenderActions
        subHeader={this.props.workspaceName}
      >
        <UserContainer
          isAssignedPage
          activeView={this.state.activeView}
          hideSingleMenu
          allowOnlySingleSelect
          onSelect={this.onSelect}
          color={this.props.color}
          preventRedirectOnClick
        />
      </SelectList>
    )
  }
}

UpdateInformation.propTypes = {
  user: PropTypes.object
}

const withConnect = connect(
  null,
  {
    getBillingDetailsRequest, fetchWorkspaceRequest
  }
);


export default withRouter(withConnect(UpdateInformation));
