import {
  CardElement, useElements, useStripe,
} from '@stripe/react-stripe-js';
import { defaultNotifier } from 'functions/notificationHandler';
import React from 'react';


const CardForm = ({
  name, onGetPaymentInfo, interval, theme,
}) => {
  const CARD_OPTIONS = {
    hidePostalCode: true,
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#c4f0ff',
        fontWeight: 'normal',
        color: theme === 'dark' ? '#FFFFFF' : '#444444',
        lineHeight: '28px',
        fontSize: '14px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#CED5DB',
        },
        '::placeholder': {
          color: '#8b959e',
          fontSize: '14px',
          lineHeight: '28px',
          fontWeight: 'normal',
          fontStyle: 'normal',
          fontFamily: '"Roboto",sans-serif',
        },
      },
      invalid: {
        iconColor: '#FF7A7A',
      },
    },
  };

  const stripe = useStripe();
  const elements = useElements();

  const onChange = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    try {
      const data = await stripe.createPaymentMethod({
        type: 'card',
        billing_details: {
          name,
        },
        metadata: {
          interval,
        },
        card: elements.getElement(CardElement),
      });

      if (data.error) {
        return false;
      }
      onGetPaymentInfo(data);
    } catch (err) {
      defaultNotifier(err);
    }
  };

  return (
    <CardElement
      options={CARD_OPTIONS}
      onChange={onChange}
    />
  );
};

export default CardForm;
