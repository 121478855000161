import React from 'react';
import classNames from 'classnames';
import DetailScroll from 'components/Detail/Scroll';
import Breadcrumb, { BreadcrumbItem } from 'components/Breadcrumb';
import ActionIcon from 'components/ActionIcon';

import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

class PreLoaderHome extends React.Component {
  render() {
    return (
      <>
      <div className="homepage loading">
        <DetailScroll>
          <div className="headline">
            <h1 className="headline__title loading">
              <span>Welcome</span>
            </h1>
          </div>
          <Breadcrumb>
            <BreadcrumbItem route="" />
            <BreadcrumbItem route="Welcome to the Viso Suite Workspace Overview." />
          </Breadcrumb>
          <div className="actions-nav">
            <ActionIcon text={' '} />
          </div>

          <div className="grid-row">
            <div className="grid-col grid-col_sm-12">
              <div className="">
                <div className="homepage-wrapper homepage-chart-wrapper">
                  <div className="grid-col grid-col_sm-12">
                    <div className="homepage-card">
                      <div className="homepage-title">Device Licenses</div>
                      <div className="homepage-chart">
                        <CircularProgressbarWithChildren
                          strokeWidth={6}
                          className='homepage-chart-data'
                          value={5}
                          maxValue={10}
                        >
                          <div>
                            <div className="homepage-count">
                            </div>
                            <div className="homepage-desc">
                            </div>
                          </div>
                        </CircularProgressbarWithChildren>
                      </div>
                    </div>
                  </div>
                  <div className="grid-col grid-col_sm-12">
                    <div className="homepage-card">
                      <div className="homepage-title">Applications</div>
                      <div className="homepage-chart">
                        <CircularProgressbarWithChildren
                          strokeWidth={6}
                          value={5}
                          className='homepage-chart-data'
                          maxValue={10}
                        >
                          <div>
                            <div className="homepage-count">
                            </div>
                            <div className="homepage-desc">
                            </div>
                          </div>
                        </CircularProgressbarWithChildren>
                      </div>
                    </div>
                  </div>
                  <div className="grid-col grid-col_sm-12">
                    <div className="homepage-card">
                      <div className="homepage-title">Users</div>
                      <div className="homepage-chart">
                        <CircularProgressbarWithChildren
                          strokeWidth={6}
                          className='homepage-chart-data'
                          value={5}
                          maxValue={10}
                        >
                          <div>
                            <div className="homepage-count">
                            </div>
                            <div className="homepage-desc">
                            </div>
                          </div>
                        </CircularProgressbarWithChildren>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-col grid-col_sm-12">
              <div
                className={classNames(
                  'homepage-card-main homepage-card-main-top',
                  {
                  }
                )}
              >
                <div className="homepage-title">System Health</div>
                <div className="homepage-subTitle">
                    The automated health scan detected issues.
                </div>
                <div className="homepage-item">
                  <table className="data-table__table">
                    <tbody className="data-table__body">
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* second block */}
          <div className="grid-row">
            <div className="grid-col grid-col_sm-12">
              <div className="">
                <div className="homepage-wrapper">
                  <div className="homepage-card-main homepage-card-main2 homepage-width">
                    <div className="homepage-title">Workspace Usage</div>
                    <span
                      className="homepage-title-action"
                    >
                    </span>
                    <div className="homepage-line"></div>
                    <div className="homepage-item-bar">
                      <div className="homepage-item-bar-wrapper">
                        <div className="homepage-item-bar-name">Video data</div>
                        <div className="vertical-center">
                          <div className="homepage-item-bar-count">
                            5
                          </div>
                          <div>
                            <div className="billing-limit-progress-container">
                              <div
                                className="billing-limit-progress"
                                style={{
                                  width: 100,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="homepage-item-bar-wrapper">
                        <div className="homepage-item-bar-name">Profiles</div>
                        <div className="vertical-center">
                          <div className="homepage-item-bar-count">
                            100
                          </div>
                          <div>
                            <div className="billing-limit-progress-container">
                              <div
                                className="billing-limit-progress"
                                style={{
                                  width: 100
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="homepage-item-bar">
                      <div className="homepage-item-bar-wrapper">
                        <div className="homepage-item-bar-name">AI models</div>
                        <div className="vertical-center">
                          <div className="homepage-item-bar-count">
                                100
                          </div>
                          <div>
                            <div className="billing-limit-progress-container">
                              <div
                                className="billing-limit-progress"
                                style={{
                                  width: 100
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="homepage-item-bar-wrapper">
                        <div className="homepage-item-bar-name">
                          Deployments
                        </div>
                        <div className="vertical-center">
                          <div className="homepage-item-bar-count">
                            10
                          </div>
                          <div>
                            <div className="billing-limit-progress-container">
                              <div
                                className="billing-limit-progress"
                                style={{
                                  width: 100
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="homepage-item-bar">
                      <div className="homepage-item-bar-wrapper">
                        <div className="homepage-item-bar-name">Modules</div>
                        <div className="vertical-center">
                          <div className="homepage-item-bar-count">
                            100
                          </div>
                          <div>
                            <div className="billing-limit-progress-container">
                              <div
                                className="billing-limit-progress"
                                style={{
                                  width: 100
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="homepage-item-bar-wrapper">
                        <div className="homepage-item-bar-name">
                          Device Types
                        </div>
                        <div className="vertical-center">
                          <div className="homepage-item-bar-count">
                            100
                          </div>
                          <div>
                            <div className="billing-limit-progress-container">
                              <div
                                className="billing-limit-progress"
                                style={{
                                  width: 100
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid-row homepage-subWrapper">
                <div className="grid-col grid-col_sm-12">
                  <div className="homepage-card-subMain">
                    <div className="homepage-title">Recent Applications</div>
                    <table className="data-table__table">
                      <tbody className="data-table__body">
                        <h4>Not available</h4>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="grid-col grid-col_sm-12">
                  <div className="homepage-card-subMain">
                    <div className="homepage-title">Recent Devices</div>
                    <table className="data-table__table">
                      <tbody className="data-table__body">
                        <h4>Not available</h4>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-col grid-col_sm-12">
              <div className="homepage-wrapper">
                <div className="homepage-card-main homepage-card-main2 homepage-width">
                  <div className="homepage-title">Workspace Usage</div>
                  <span
                    className="homepage-title-action"
                  >
                  </span>
                  <div className="homepage-line"></div>
                  <div className="homepage-item-bar">
                    <div className="homepage-item-bar-wrapper">
                      <div className="homepage-item-bar-name">Video data</div>
                      <div className="vertical-center">
                        <div className="homepage-item-bar-count">
                            5
                        </div>
                        <div>
                          <div className="billing-limit-progress-container">
                            <div
                              className="billing-limit-progress"
                              style={{
                                width: 100,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="homepage-item-bar-wrapper">
                      <div className="homepage-item-bar-name">Profiles</div>
                      <div className="vertical-center">
                        <div className="homepage-item-bar-count">
                            100
                        </div>
                        <div>
                          <div className="billing-limit-progress-container">
                            <div
                              className="billing-limit-progress"
                              style={{
                                width: 100
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="homepage-item-bar">
                    <div className="homepage-item-bar-wrapper">
                      <div className="homepage-item-bar-name">AI models</div>
                      <div className="vertical-center">
                        <div className="homepage-item-bar-count">
                                100
                        </div>
                        <div>
                          <div className="billing-limit-progress-container">
                            <div
                              className="billing-limit-progress"
                              style={{
                                width: 100
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="homepage-item-bar-wrapper">
                      <div className="homepage-item-bar-name">
                          Deployments
                      </div>
                      <div className="vertical-center">
                        <div className="homepage-item-bar-count">
                            10
                        </div>
                        <div>
                          <div className="billing-limit-progress-container">
                            <div
                              className="billing-limit-progress"
                              style={{
                                width: 100
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="homepage-item-bar">
                    <div className="homepage-item-bar-wrapper">
                      <div className="homepage-item-bar-name">Modules</div>
                      <div className="vertical-center">
                        <div className="homepage-item-bar-count">
                            100
                        </div>
                        <div>
                          <div className="billing-limit-progress-container">
                            <div
                              className="billing-limit-progress"
                              style={{
                                width: 100
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="homepage-item-bar-wrapper">
                      <div className="homepage-item-bar-name">
                          Device Types
                      </div>
                      <div className="vertical-center">
                        <div className="homepage-item-bar-count">
                            100
                        </div>
                        <div>
                          <div className="billing-limit-progress-container">
                            <div
                              className="billing-limit-progress"
                              style={{
                                width: 100
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </DetailScroll>
      </div>
      </>
    );
  }
}

export default (PreLoaderHome);
