import { fromJS } from 'immutable';
import { LOGOUT } from 'containers/App/constants';
import {
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  INVITE_USER_RESET_PASSWORD_ERROR,
  INVITE_USER_RESET_PASSWORD_REQUEST,
  INVITE_USER_RESET_PASSWORD_SUCCESS,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  LOGIN_REQUEST,
  GET_PLANS_PENDING,
  GET_PLANS_SUCCESS,
  GET_PLANS_REJECTED,
  GET_HOMEPAGE_ERROR,
  GET_HOMEPAGE_REQUEST,
  GET_HOMEPAGE_SUCCESS,
  GET_PLAN_INFO_ERROR,
  GET_PLAN_INFO_REQUEST,
  GET_PLAN_INFO_SUCCESS
} from './constants';

export const initialState = fromJS({
  loginSuccess: false,
  workspaceSetupInvited: false,
  loggedInEmail: '',
  loginError: true,
  loginData: null,
  workspaces: [],
  errors: null,
  loading: false,
  forgotData: null,
  forgotLoading: false,
  forgotError: null,
  resetData: null,
  plans: [],
  isHomePageLoading: false,
  isPlanSubscriptionLoading: false,
});

function loginReducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT:
      return initialState;
    case LOGIN_REQUEST:
    case REGISTER_REQUEST:
      return state.set('loading', true);
    case REGISTER_SUCCESS:
      return state.set('loading', false);
    case LOGIN_SUCCESS:
      return state
        .set('loginData', action.payload.user)
        .set('loading', false)
        .set('workspaceSetupInvited ', action.payload.workspaceSetupInvited)
        .set('loggedInEmail', action.payload.email);
    case LOGIN_ERROR:
    case REGISTER_ERROR:
      return state.set('errors', action.errors).set('loading', false);
    case FORGOT_PASSWORD_REQUEST:
      return state.set('forgotLoading', true).set('forgotData', null);
    case FORGOT_PASSWORD_ERROR:
      return state
        .set('forgotError', action.errors)
        .set('forgotLoading', false);
    case FORGOT_PASSWORD_SUCCESS:
      return state
        .set('forgotData', action.payload)
        .set('forgotLoading', false);
    case GET_PLANS_PENDING:
      return state
        .set('plans', [])
        .set('loading', true);
    case GET_PLANS_SUCCESS:
      return state
        .set('plans', action.payload)
        .set('loading', false);
    case GET_PLANS_REJECTED:
      return state
        .set('loading', false);
    case RESET_PASSWORD_REQUEST:
      return state.set('resetData', null).set('loading', true);
    case INVITE_USER_RESET_PASSWORD_REQUEST:
      return state.set('resetData', null).set('loading', true);
    case RESET_PASSWORD_SUCCESS:
      return state.set('resetData', action.payload).set('loading', false);
    case INVITE_USER_RESET_PASSWORD_SUCCESS:
      return state.set('resetData', action.payload).set('loading', false);
    case RESET_PASSWORD_ERROR:
      return state.set('errors', action.errors).set('loading', false);
    case INVITE_USER_RESET_PASSWORD_ERROR:
      return state.set('errors', action.errors).set('loading', false);
    case GET_HOMEPAGE_REQUEST:
      return state.set('isHomePageLoading', true);
    case GET_HOMEPAGE_SUCCESS:
      return state.set('isHomePageLoading', false);
    case GET_HOMEPAGE_ERROR:
      return state.set('isHomePageLoading', false);
    case GET_PLAN_INFO_REQUEST:
      return state.set('isPlanSubscriptionLoading', true);
    case GET_PLAN_INFO_SUCCESS:
      return state.set('isPlanSubscriptionLoading', false);
    case GET_PLAN_INFO_ERROR:
      return state.set('isPlanSubscriptionLoading', false);
    default:
      return state;
  }
}

export default loginReducer;
