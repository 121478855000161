import axios from 'axios';
import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import axiosObj from './config';
import { FLOW_VERSION_RETURN_VALUES, MODULE_VERSION_RETURN_VALUES } from './library';


export const SOLUTION_API_URL = `${process.env.API_URL}/solution/graphql`;

const MULTIPART_UPLOAD = {
  s3Key: true,
  uploadId: true,
  signedUrls: true
}

const SELECT_PROJECT = {
  locked: true,
  lockedBy: {
    id: true,
    name: true,
  },
};

const SOLUTION_RETURN_VALUES = {
  id: true,
  name: true,
  description: true,
  tags: true,
  type: true,
  moduleID: true,
  nodes: true,
  installStatus: true,
  createdBy: {
    id: true,
    name: true,
  },
  updatedBy: {
    id: true,
    name: true,
  },
  createdAt: true,
  updatedAt: true,
  version: true,
  dependents: {
    name: true,
    version: true,
  },
  prices: true,
};

export function getFileData(data, config) {
  const formData = new FormData();

  if(data.response && data.response.fields) {
    data.response.fields && Object.keys(data.response.fields).forEach((item) => {
      formData.append(item, data.response.fields[item]);
    });
  }
  formData.append('file', data.file);
  return axios.post(data.response.url, formData, config);
}

export async function getFileAwsData(data, config) {
  // const formData = new FormData();
  // formData.append('file', data.file);
  return await axios.put(data.response.url, data.file, config);
}

export function createFlow(params) {
  const query = {
    mutation: {
      createFlow: {
        __args: params,
        id: true,
        name: true,
        data: true,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function createModule(param) {
  const query = {
    mutation: {
      createModule: {
        __args: param,
        ...SOLUTION_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function createCustomModule(param) {
  const query = {
    mutation: {
      createCustomModule: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function upgradeCustomModule(param) {
  const query = {
    mutation: {
      upgradeCustomModule: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function upgradePublicModule(param) {
  const query = {
    mutation: {
      upgradePublicModule: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function cloneModule(param) {
  const query = {
    mutation: {
      createModule: {
        __args: param,
        ...SOLUTION_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function updateModule(param) {
  const query = {
    mutation: {
      updateModule: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function fetchLicenseList(param) {
  const query = {
    query: {
      licenses: {
        __args: param,
        count: true,
        data: {
          id: true,
          name: true,
          type: true,
          seats: true,
          expiry: true,
          active: true,
          serial: true,
          createdAt: true,
          updatedAt: true,
        },
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getModuleVersions(param) {
  const query = {
    query: {
      moduleHistories: {
        __args: param,
        count: true,
        data: MODULE_VERSION_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getFlowVersions(param) {
  const query = {
    query: {
      flowHistories: {
        __args: param,
        count: true,
        data: FLOW_VERSION_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function uploadZipFile(param) {
  const query = {
    mutation: {
      uploadCustomModule: {
        __args: { filename: param.file.path },
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function uploadApplicationZipFile(param) {
  const query = {
    mutation: {
      uploadFile: {
        __args: { filename: param.file.path },
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function deleteApplication(id) {
  const query = {
    mutation: {
      deleteApplication: {
        __args: id,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function selectProject(args) {
  const query = {
    mutation: {
      selectProject: {
        __args: args,
        ...SELECT_PROJECT,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function unlockBuilder(args) {
  const query = {
    mutation: {
      unlockBuilder: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}


export function deleteDockerImage(params) {
  const query = {
    mutation: {
      deleteDockerImage: {
        __args: params,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function createTemplate(params) {
  const query = {
    mutation: {
      createTemplate: {
        __args: params,
        id: true,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function createApplicationFromTemplate(params) {
  const query = {
    mutation: {
      createApplicationFromTemplate: {
        __args: params,
        id: true,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function fetchTemplateList() {
  const query = {
    query: {
      templates: {
        id: true,
        name: true,
        tags: true,
        type: true,
        isNewTemplate: true,
        createdAt: true,
        dependencies: true,
        createdBy: {
          id: true,
          name: true,
        },
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function deleteTemplate(params) {
  const query = {
    mutation: {
      deleteTemplate: {
        __args: params,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}


export function exportApplication(params) {
  const query = {
    mutation: {
      exportApplication: {
        __args: params,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function importApplication(params) {
  const query = {
    mutation: {
      importApplication: {
        __args: params,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function updateVideo(params) {
  const query = {
    mutation: {
      updateVideo: {
        __args: params,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function uploadModelFile(params) {
  const query = {
    mutation: {
      uploadFile: {
        __args: params,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function recordVideo(params) {
  const query = {
    mutation: {
      recordVideo: {
        __args: params,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function discardChanges(params) {
  const query = {
    mutation: {
      discardChanges: {
        __args: params,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function finalizeMultipartUpload(params) {
  const query = {
    mutation: {
      finalizeMultipartUpload: {
        __args: params,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function initMultipartUpload(params) {
  const query = {
    mutation: {
      initMultipartUpload: {
        __args: params,
        ...MULTIPART_UPLOAD
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}
