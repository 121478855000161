export const countries = [
  { label: 'Afghanistan', phone: '+93', value: 'AF' },
  { label: 'Albania', phone: '+355', value: 'AL' },
  { label: 'Algeria', phone: '+213', value: 'DZ' },
  { label: 'American Samoa', phone: '+684', value: 'AS' },
  { label: 'Andorra', phone: '+376', value: 'AD' },
  { label: 'Angola', phone: '+244', value: 'AO' },
  { label: 'Anguilla', phone: '+264', value: 'AI' },
  { label: 'Antarctica', phone: '+672', value: 'AQ' },
  { label: 'Antigua and Barbuda', phone: '+268', value: 'AG' },
  { label: 'Argentina', phone: '+54', value: 'AR' },
  { label: 'Armenia', phone: '+374', value: 'AM' },
  { label: 'Aruba', phone: '+297', value: 'AW' },
  { label: 'Australia', phone: '+61', value: 'AU' },
  { label: 'Austria', phone: '+43', value: 'AT' },
  { label: 'Azerbaijan', phone: '+994', value: 'AZ' },
  { label: 'Bahamas', phone: '+242', value: 'BS' },
  { label: 'Bahrain', phone: '+973', value: 'BH' },
  { label: 'Bangladesh', phone: '+880', value: 'BD' },
  { label: 'Barbados', phone: '+246', value: 'BB' },
  { label: 'Belarus', phone: '+375', value: 'BY' },
  { label: 'Belgium', phone: '+32', value: 'BE' },
  { label: 'Belize', phone: '+501', value: 'BZ' },
  { label: 'Benin', phone: '+229', value: 'BJ' },
  { label: 'Bermuda', phone: '+441', value: 'BM' },
  { label: 'Bhutan', phone: '+975', value: 'BT' },
  { label: 'Bolivia', phone: '+591', value: 'BO' },
  { label: 'Bosnia and Herzegovina', phone: '+387', value: 'BA' },
  { label: 'Botswana', phone: '+267', value: 'BW' },
  { label: 'Brazil', phone: '+55', value: 'BR' },
  { label: 'British Indian Ocean Territory', phone: '+246', value: 'IO' },
  { label: 'British Virgin Islands', phone: '+284', value: 'VG' },
  { label: 'Brunei', phone: '+673', value: 'BN' },
  { label: 'Bulgaria', phone: '+359', value: 'BG' },
  { label: 'Burkina Faso', phone: '+226', value: 'BF' },
  { label: 'Burundi', phone: '+257', value: 'BI' },
  { label: 'Cambodia', phone: '+855', value: 'KH' },
  { label: 'Cameroon', phone: '+237', value: 'CM' },
  { label: 'Canada', phone: '+1', value: 'CA' },
  { label: 'Cape Verde', phone: '+238', value: 'CV' },
  { label: 'Cayman Islands', phone: '+345', value: 'KY' },
  { label: 'Central African Republic', phone: '+236', value: 'CF' },
  { label: 'Chad', phone: '+235', value: 'TD' },
  { label: 'Chile', phone: '+56', value: 'CL' },
  { label: 'China', phone: '+86', value: 'CN' },
  { label: 'Christmas Island', phone: '+61', value: 'CX' },
  { label: 'Cocos Islands', phone: '+61', value: 'CC' },
  { label: 'Colombia', phone: '+57', value: 'CO' },
  { label: 'Comoros', phone: '+269', value: 'KM' },
  { label: 'Cook Islands', phone: '+682', value: 'CK' },
  { label: 'Costa Rica', phone: '+506', value: 'CR' },
  { label: 'Croatia', phone: '+385', value: 'HR' },
  { label: 'Cuba', phone: '+53', value: 'CU' },
  { label: 'Curacao', phone: '+599', value: 'CW' },
  { label: 'Cyprus', phone: '+357', value: 'CY' },
  { label: 'Czech Republic', phone: '+420', value: 'CZ' },
  { label: 'Democratic Republic of the Congo', phone: '+243', value: 'CD' },
  { label: 'Denmark', phone: '+45', value: 'DK' },
  { label: 'Djibouti', phone: '+253', value: 'DJ' },
  { label: 'Dominica', phone: '+767', value: 'DM' },
  { label: 'Dominican Republic', phone: '+849', value: 'DO' },
  { label: 'East Timor', phone: '+670', value: 'TL' },
  { label: 'Ecuador', phone: '+593', value: 'EC' },
  { label: 'Egypt', phone: '+20', value: 'EG' },
  { label: 'El Salvador', phone: '+503', value: 'SV' },
  { label: 'Equatorial Guinea', phone: '+240', value: 'GQ' },
  { label: 'Eritrea', phone: '+291', value: 'ER' },
  { label: 'Estonia', phone: '+372', value: 'EE' },
  { label: 'Ethiopia', phone: '+251', value: 'ET' },
  { label: 'Falkland Islands', phone: '+500', value: 'FK' },
  { label: 'Faroe Islands', phone: '+298', value: 'FO' },
  { label: 'Fiji', phone: '+679', value: 'FJ' },
  { label: 'Finland', phone: '+358', value: 'FI' },
  { label: 'France', phone: '+33', value: 'FR' },
  { label: 'French Polynesia', phone: '+689', value: 'PF' },
  { label: 'Gabon', phone: '+241', value: 'GA' },
  { label: 'Gambia', phone: '+220', value: 'GM' },
  { label: 'Georgia', phone: '+995', value: 'GE' },
  { label: 'Germany', phone: '+49', value: 'DE' },
  { label: 'Ghana', phone: '+233', value: 'GH' },
  { label: 'Gibraltar', phone: '+350', value: 'GI' },
  { label: 'Greece', phone: '+30', value: 'GR' },
  { label: 'Greenland', phone: '+299', value: 'GL' },
  { label: 'Grenada', phone: '+473', value: 'GD' },
  { label: 'Guam', phone: '+671', value: 'GU' },
  { label: 'Guatemala', phone: '+502', value: 'GT' },
  { label: 'Guernsey', phone: '+1481', value: 'GG' },
  { label: 'Guinea', phone: '+224', value: 'GN' },
  { label: 'Guinea-Bissau', phone: '+245', value: 'GW' },
  { label: 'Guyana', phone: '+592', value: 'GY' },
  { label: 'Haiti', phone: '+509', value: 'HT' },
  { label: 'Honduras', phone: '+504', value: 'HN' },
  { label: 'Hong Kong', phone: '+852', value: 'HK' },
  { label: 'Hungary', phone: '+36', value: 'HU' },
  { label: 'Iceland', phone: '+354', value: 'IS' },
  { label: 'India', phone: '+91', value: 'IN' },
  { label: 'Indonesia', phone: '+62', value: 'ID' },
  { label: 'Iran', phone: '+98', value: 'IR' },
  { label: 'Iraq', phone: '+964', value: 'IQ' },
  { label: 'Ireland', phone: '+353', value: 'IE' },
  { label: 'Isle of Man', phone: '+1624', value: 'IM' },
  { label: 'Israel', phone: '+972', value: 'IL' },
  { label: 'Italy', phone: '+39', value: 'IT' },
  { label: 'Ivory Coast', phone: '+225', value: 'CI' },
  { label: 'Jamaica', phone: '+876', value: 'JM' },
  { label: 'Japan', phone: '+81', value: 'JP' },
  { label: 'Jersey', phone: '+1534', value: 'JE' },
  { label: 'Jordan', phone: '+962', value: 'JO' },
  { label: 'Kazakhstan', phone: '+7', value: 'KZ' },
  { label: 'Kenya', phone: '+254', value: 'KE' },
  { label: 'Kiribati', phone: '+686', value: 'KI' },
  { label: 'Kosovo', phone: '+383', value: 'XK' },
  { label: 'Kuwait', phone: '+965', value: 'KW' },
  { label: 'Kyrgyzstan', phone: '+996', value: 'KG' },
  { label: 'Laos', phone: '+856', value: 'LA' },
  { label: 'Latvia', phone: '+371', value: 'LV' },
  { label: 'Lebanon', phone: '+961', value: 'LB' },
  { label: 'Lesotho', phone: '+266', value: 'LS' },
  { label: 'Liberia', phone: '+231', value: 'LR' },
  { label: 'Libya', phone: '+218', value: 'LY' },
  { label: 'Liechtenstein', phone: '+423', value: 'LI' },
  { label: 'Lithuania', phone: '+370', value: 'LT' },
  { label: 'Luxembourg', phone: '+352', value: 'LU' },
  { label: 'Macau', phone: '+853', value: 'MO' },
  { label: 'Macedonia', phone: '+389', value: 'MK' },
  { label: 'Madagascar', phone: '+261', value: 'MG' },
  { label: 'Malawi', phone: '+265', value: 'MW' },
  { label: 'Malaysia', phone: '+60', value: 'MY' },
  { label: 'Maldives', phone: '+960', value: 'MV' },
  { label: 'Mali', phone: '+223', value: 'ML' },
  { label: 'Malta', phone: '+356', value: 'MT' },
  { label: 'Marshall Islands', phone: '+692', value: 'MH' },
  { label: 'Mauritania', phone: '+222', value: 'MR' },
  { label: 'Mauritius', phone: '+230', value: 'MU' },
  { label: 'Mayotte', phone: '+262', value: 'YT' },
  { label: 'Mexico', phone: '+52', value: 'MX' },
  { label: 'Micronesia', phone: '+691', value: 'FM' },
  { label: 'Moldova', phone: '+373', value: 'MD' },
  { label: 'Monaco', phone: '+377', value: 'MC' },
  { label: 'Mongolia', phone: '+976', value: 'MN' },
  { label: 'Montenegro', phone: '+382', value: 'ME' },
  { label: 'Montserrat', phone: '+664', value: 'MS' },
  { label: 'Morocco', phone: '+212', value: 'MA' },
  { label: 'Mozambique', phone: '+258', value: 'MZ' },
  { label: 'Myanmar', phone: '+95', value: 'MM' },
  { label: 'Namibia', phone: '+264', value: 'NA' },
  { label: 'Nauru', phone: '+674', value: 'NR' },
  { label: 'Nepal', phone: '+977', value: 'NP' },
  { label: 'Netherlands', phone: '+31', value: 'NL' },
  { label: 'Netherlands Antilles', phone: '+599', value: 'AN' },
  { label: 'New Caledonia', phone: '+687', value: 'NC' },
  { label: 'New Zealand', phone: '+64', value: 'NZ' },
  { label: 'Nicaragua', phone: '+505', value: 'NI' },
  { label: 'Niger', phone: '+227', value: 'NE' },
  { label: 'Nigeria', phone: '+234', value: 'NG' },
  { label: 'Niue', phone: '+683', value: 'NU' },
  { label: 'North Korea', phone: '+850', value: 'KP' },
  { label: 'Northern Mariana Islands', phone: '+670', value: 'MP' },
  { label: 'Norway', phone: '+47', value: 'NO' },
  { label: 'Oman', phone: '+968', value: 'OM' },
  { label: 'Pakistan', phone: '+92', value: 'PK' },
  { label: 'Palau', phone: '+680', value: 'PW' },
  { label: 'Palestine', phone: '+970', value: 'PS' },
  { label: 'Panama', phone: '+507', value: 'PA' },
  { label: 'Papua New Guinea', phone: '+675', value: 'PG' },
  { label: 'Paraguay', phone: '+595', value: 'PY' },
  { label: 'Peru', phone: '+51', value: 'PE' },
  { label: 'Philippines', phone: '+63', value: 'PH' },
  { label: 'Pitcairn', phone: '+64', value: 'PN' },
  { label: 'Poland', phone: '+48', value: 'PL' },
  { label: 'Portugal', phone: '+351', value: 'PT' },
  { label: 'Puerto Rico', phone: '+939', value: 'PR' },
  { label: 'Qatar', phone: '+974', value: 'QA' },
  { label: 'Republic of the Congo', phone: '+242', value: 'CG' },
  { label: 'Reunion', phone: '+262', value: 'RE' },
  { label: 'Romania', phone: '+40', value: 'RO' },
  { label: 'Russia', phone: '+7', value: 'RU' },
  { label: 'Rwanda', phone: '+250', value: 'RW' },
  { label: 'Saint Barthelemy', phone: '+590', value: 'BL' },
  { label: 'Saint Helena', phone: '+290', value: 'SH' },
  { label: 'Saint Kitts and Nevis', phone: '+869', value: 'KN' },
  { label: 'Saint Lucia', phone: '+758', value: 'LC' },
  { label: 'Saint Martin', phone: '+590', value: 'MF' },
  { label: 'Saint Pierre and Miquelon', phone: '+508', value: 'PM' },
  { label: 'Saint Vincent and the Grenadines', phone: '+784', value: 'VC' },
  { label: 'Samoa', phone: '+685', value: 'WS' },
  { label: 'San Marino', phone: '+378', value: 'SM' },
  { label: 'Sao Tome and Principe', phone: '+239', value: 'ST' },
  { label: 'Saudi Arabia', phone: '+966', value: 'SA' },
  { label: 'Senegal', phone: '+221', value: 'SN' },
  { label: 'Serbia', phone: '+381', value: 'RS' },
  { label: 'Seychelles', phone: '+248', value: 'SC' },
  { label: 'Sierra Leone', phone: '+232', value: 'SL' },
  { label: 'Singapore', phone: '+65', value: 'SG' },
  { label: 'Sint Maarten', phone: '+721', value: 'SX' },
  { label: 'Slovakia', phone: '+421', value: 'SK' },
  { label: 'Slovenia', phone: '+386', value: 'SI' },
  { label: 'Solomon Islands', phone: '+677', value: 'SB' },
  { label: 'Somalia', phone: '+252', value: 'SO' },
  { label: 'South Africa', phone: '+27', value: 'ZA' },
  { label: 'South Korea', phone: '+82', value: 'KR' },
  { label: 'South Sudan', phone: '+211', value: 'SS' },
  { label: 'Spain', phone: '+34', value: 'ES' },
  { label: 'Sri Lanka', phone: '+94', value: 'LK' },
  { label: 'Sudan', phone: '+249', value: 'SD' },
  { label: 'Surilabel', phone: '+597', value: 'SR' },
  { label: 'Svalbard and Jan Mayen', phone: '+47', value: 'SJ' },
  { label: 'Swaziland', phone: '+268', value: 'SZ' },
  { label: 'Sweden', phone: '+46', value: 'SE' },
  { label: 'Switzerland', phone: '+41', value: 'CH' },
  { label: 'Syria', phone: '+963', value: 'SY' },
  { label: 'Taiwan', phone: '+886', value: 'TW' },
  { label: 'Tajikistan', phone: '+992', value: 'TJ' },
  { label: 'Tanzania', phone: '+255', value: 'TZ' },
  { label: 'Thailand', phone: '+66', value: 'TH' },
  { label: 'Togo', phone: '+228', value: 'TG' },
  { label: 'Tokelau', phone: '+690', value: 'TK' },
  { label: 'Tonga', phone: '+676', value: 'TO' },
  { label: 'Trinidad and Tobago', phone: '+868', value: 'TT' },
  { label: 'Tunisia', phone: '+216', value: 'TN' },
  { label: 'Turkey', phone: '+90', value: 'TR' },
  { label: 'Turkmenistan', phone: '+993', value: 'TM' },
  { label: 'Turks and Caicos Islands', phone: '+649', value: 'TC' },
  { label: 'Tuvalu', phone: '+688', value: 'TV' },
  { label: 'U.S. Virgin Islands', phone: '+340', value: 'VI' },
  { label: 'Uganda', phone: '+256', value: 'UG' },
  { label: 'Ukraine', phone: '+380', value: 'UA' },
  { label: 'United Arab Emirates', phone: '+971', value: 'AE' },
  { label: 'United Kingdom', phone: '+44', value: 'GB' },
  { label: 'United States', phone: '+1', value: 'US' },
  { label: 'Uruguay', phone: '+598', value: 'UY' },
  { label: 'Uzbekistan', phone: '+998', value: 'UZ' },
  { label: 'Vanuatu', phone: '+678', value: 'VU' },
  { label: 'Vatican', phone: '+379', value: 'VA' },
  { label: 'Venezuela', phone: '+58', value: 'VE' },
  { label: 'Vietnam', phone: '+84', value: 'VN' },
  { label: 'Wallis and Futuna', phone: '+681', value: 'WF' },
  { label: 'Western Sahara', phone: '+212', value: 'EH' },
  { label: 'Yemen', phone: '+967', value: 'YE' },
  { label: 'Zambia', phone: '+260', value: 'ZM' },
  { label: 'Zimbabwe', phone: '+263', value: 'ZW' },
];
