export const LIST_ROLE_REQUEST = 'ROLE/LIST_ROLE_REQUEST';
export const LIST_ROLE_SUCCESS = 'ROLE/LIST_ROLE_SUCCESS';
export const LIST_ROLE_ERROR = 'ROLE/LIST_ROLE_ERROR';
export const DETAIL_ROLE_REQUEST = 'ROLE/DETAIL_ROLE_REQUEST';
export const DETAIL_ROLE_SUCCESS = 'ROLE/DETAIL_ROLE_SUCCESS';
export const DETAIL_ROLE_ERROR = 'ROLE/DETAIL_ROLE_ERROR';
export const LIST_PERMISSION_REQUEST = 'ROLE/LIST_PERMISSION_REQUEST';
export const LIST_PERMISSION_SUCCESS = 'ROLE/LIST_PERMISSION_SUCCESS';
export const LIST_PERMISSION_ERROR = 'ROLE/LIST_PERMISSION_ERROR';
export const ADD_ROLE_REQUEST = 'ROLE/ADD_ROLE_REQUEST';
export const ADD_ROLE_SUCCESS = 'ROLE/ADD_ROLE_SUCCESS';
export const ADD_ROLE_ERROR = 'ROLE/ADD_ROLE_ERROR';
export const DELETE_ROLE_REQUEST = 'ROLE/DELETE_ROLE_REQUEST';
export const DELETE_ROLE_SUCCESS = 'ROLE/DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_ERROR = 'ROLE/DELETE_ROLE_ERROR';
export const UPDATE_ROLE_REQUEST = 'ROLE/UPDATE_ROLE_REQUEST';
export const UPDATE_ROLE_SUCCESS = 'ROLE/UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_ERROR = 'ROLE/UPDATE_ROLE_ERROR';
export const CARD_VIEW = 'card';
export const TABLE_VIEW = 'list';
export const ADD_ROLE_TO_USERS_REQUEST = 'ADD_ROLE_TO_USERS_REQUEST';
export const ADD_ROLE_TO_USERS_SUCCESS = 'ADD_ROLE_TO_USERS_SUCCESS';
export const ADD_ROLE_TO_USER_REQUEST = 'ADD_ROLE_TO_USER_REQUEST';
export const ADD_ROLE_TO_USER_SUCCESS = 'ADD_ROLE_TO_USER_SUCCESS';
export const INDIVIDUAL_ROLE_REQUEST = 'INDIVIDUAL_ROLE_REQUEST';
export const INDIVIDUAL_ROLE_SUCCESS = 'INDIVIDUAL_ROLE_SUCCESS';
export const INDIVIDUAL_ROLE_ERROR = 'INDIVIDUAL_ROLE_ERROR';
