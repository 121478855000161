import { fromJS } from 'immutable';
import { LOGOUT } from 'containers/App/constants';
import { SELECT_WORKSPACE_SUCCESS } from 'containers/Login/constants';
import {
  FETCH_USER_SUCCESS,
  FETCH_USER_DETAIL_SUCCESS,
  FETCH_USER_ERROR,
  FETCH_USER_DETAIL_ERROR,
  FETCH_ME_SUCCESS,
  FETCH_USER_REQUEST,
  FETCH_USER_DETAIL_REQUEST,
  FETCH_ME_ERROR,
  FETCH_INVITED_BY_USER_REQUEST,
  FETCH_INVITED_BY_USER_SUCCESS,
  FETCH_INVITED_BY_USER_ERROR,
  INVITE_USER_SUCCESS,
  INVITE_USER_ERROR,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_REQUEST,
  UPDATE_USER_ERROR,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  UPDATE_USER_STATUS_SUCCESS,
} from './constants';

export const initialState = fromJS({
  fetchUsersSuccess: false,
  fetchUsersError: true,
  fetchUsers: null,
  inviteUsersSuccess: false,
  inviteUsersError: true,
  inviteUsers: null,
  user: null,
  me: null,
  updateUserSuccess: false,
  updateUserError: true,
  updateUser: null,
  errors: null,
  loading: false,
  changePasswordData: null,
});

function userReducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT:
    case SELECT_WORKSPACE_SUCCESS:
      return initialState;
    case UPDATE_USER_STATUS_SUCCESS:
      return state.set('fetchUsers', null);
    case FETCH_USER_REQUEST:
      return state.set('fetchUsers', {
        ...action.payload,
        isLoading: true,
        data: [],
      });
    case FETCH_USER_SUCCESS:
      if (!state.toJS().fetchUsers || action.payload.offset === 0) {
        return state.set('fetchUsers', {
          isLoading: false,
          data: [],
          ...action.payload,
        });
      }
      return state.set('fetchUsers', {
        count: action.payload.count,
        data: [...state.toJS().fetchUsers.data, ...action.payload.data],
        isLoading: false,
      });
    case FETCH_USER_ERROR:
      return state.set('errors', action.errors).set('loading', false).set('fetchUsers', { data: [] });
    case FETCH_USER_DETAIL_REQUEST:
      return state.set('user', null).set('userLoading', true);
    case FETCH_USER_DETAIL_SUCCESS:
      return state.set('userLoading', false).set('user', action.payload);
    case FETCH_USER_DETAIL_ERROR:
      return state.set('errors', action.errors).set('userLoading', false);
    case FETCH_ME_SUCCESS:
      return state.set('me', action.payload);
    case FETCH_ME_ERROR:
      return state.set('errors', action.errors);
    case FETCH_INVITED_BY_USER_REQUEST:
      return state.set('invitedLoading', true).set('fetchInvitedByUser', null);
    case FETCH_INVITED_BY_USER_SUCCESS:
      return state
        .set('invitedLoading', false)
        .set('fetchInvitedByUser', action.payload);
    case FETCH_INVITED_BY_USER_ERROR:
      return state.set('invitedLoading', false).set('errors', action.errors);
    case INVITE_USER_SUCCESS:
      return state.set('inviteUsers', action.payload);
    case INVITE_USER_ERROR:
      return state.set('errors', action.errors);
    case UPDATE_USER_REQUEST:
      return state
        .set('loading', true);
    case UPDATE_USER_SUCCESS:
      return state
        .set('updateUser', action.payload)
        .set('user', action.payload)
        .set('loading', false);
    case UPDATE_USER_ERROR:
      return state
        .set('errors', action.errors)
        .set('loading', false);
    case CHANGE_PASSWORD_REQUEST:
      return state.set('changePasswordData', null).set('errors', null);
    case CHANGE_PASSWORD_SUCCESS:
      return state.set('changePasswordData', action.payload);
    case CHANGE_PASSWORD_ERROR:
      return state.set('errors', action.errors);
    default:
      return state;
  }
}

export default userReducer;
