import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withRouter } from 'withRouter'
import { FormattedMessage } from 'react-intl';

import messages from 'utils/messages';
import Gmail from 'images/common/gmail.png';
import Outlook from 'images/common/outlook.png';
import Yahoo from 'images/common/yahoo.png';
import ProcessPage from 'components/ProcessPage';
import Mail from 'images/mail-verify-viso.svg';

import { routeMapping } from 'routes';

class ConfirmationPage extends Component {
  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }

    this.props.router.navigate(routeMapping.LOGIN.path);
  };

  render() {
    const { email, isResetPassword } = this.props;

    return (
      <ProcessPage onCancel={() => this.onClose()}>
        {() => (
          <div className="payment-layout">
            <div className="headline">
              <h1 className="headline__title center headline_title__register">
                {isResetPassword
                  ? <FormattedMessage {...messages.pleaseCheckEmail} />
                  : <FormattedMessage {...messages.pleaseVerifyEmail} />
                }
              </h1>
              <h3 className="headline__subtitle">
                {isResetPassword
                  ? (
                    <>
                      <FormattedMessage {...messages.sentEmailTo} />
                      {' '}
                      {email}
                    </>
                  )
                  : <FormattedMessage {...messages.checkYourInbox} />}
              </h3>
            </div>
            <div className="login">
              <div className="login__actions register__actions">
                <div className="login__img">
                  <img src={Mail} height="123px" width="132px" alt="" />
                </div>
                <div className="login_btn__wrapper">
                  <a href="https://www.gmail.com" target="_blank" rel="noopener noreferrer" className="login__btn cursor">
                    <img src={Gmail} width="24" height="24" alt="gmail" />
                    <h3>
                      <FormattedMessage {...messages.openGmail} />
                    </h3>
                  </a>
                  <a href="https://outlook.live.com/owa/" target="_blank" rel="noopener noreferrer" className="login__btn cursor">
                    <img src={Outlook} width="24" height="24" alt="gmail" />
                    <h3>
                      <FormattedMessage {...messages.openOutlook} />
                    </h3>
                  </a>
                  <a href="https://mail.yahoo.com/" target="_blank" rel="noopener noreferrer" className="login__btn cursor">
                    <img src={Yahoo} width="24" height="24" alt="gmail" />
                    <h3>
                      <FormattedMessage {...messages.openYahoo} />
                    </h3>
                  </a>
                </div>

                <div className="login__switch">
                  <FormattedMessage {...messages.didntReceiveEmailFromUs} />
                  {' '}
                  <a href="mailto:info@viso.ai" className="text-link">
                    <FormattedMessage {...messages.contactUs} />
                  </a>
                </div>
                {!isResetPassword && (
                  <div className="login__switch">
                    <FormattedMessage {...messages.emailConfirmationToEnsureData} />
                  </div>
                )
                }
              </div>
            </div>
          </div>
        )}
      </ProcessPage>
    );
  }
}

ConfirmationPage.propTypes = {
  isLoading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  currentPage: PropTypes.string,
};

export default withRouter(ConfirmationPage);
