import React from "react";

export default () => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_29103_86179)">
      <path
        d="M0.198379 2.00938C2.09213 4.4375 5.589 8.9375 5.589 8.9375V14.5625C5.589 15.0781 6.01088 15.5 6.5265 15.5H8.4015C8.91713 15.5 9.339 15.0781 9.339 14.5625V8.9375C9.339 8.9375 12.8265 4.4375 14.7203 2.00938C15.1984 1.39063 14.7578 0.5 13.9796 0.5H0.939004C0.160879 0.5 -0.279746 1.39063 0.198379 2.00938Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_29103_86179">
        <rect
          width="15"
          height="15"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
