import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const SelectableTags = ({
  tags, color, onSelect, name, activeSelection,
}) => (
  <div className={classnames('tags', `tags_${color}`)}>
    {
      tags && tags.map(tag => (
        <div
          className={classnames('selectable-tag tags__item tags__item_selectable', {
            [`tags__item_selectable_${color}`]: activeSelection.includes(tag),
          })}
          onClick={() => onSelect(tag, name)}
          key={tag}
        >
          { tag }
        </div>
      ))
    }
  </div>
);

SelectableTags.propTypes = {
  color: PropTypes.string,
  tags: PropTypes.array,
};

SelectableTags.defaultProps = {
  color: 'accent0',
};

export default SelectableTags;
