import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import { debounce, sortBy } from 'lodash';

import { BellIcon } from 'images/common';
import { FormattedMessage } from 'react-intl';

import messages from 'utils/messages';
import NotificationType from './type/Notifications';
import { NOTIFICATION_VIEW } from './constants';
import {withRouter} from 'withRouter';

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.debouncedLoadMore = debounce(this.loadMore, 300);
  }

  state = {
    offset: 15,
  };

  getSnapshotBeforeUpdate(prevProps) {
    const { notifications } = this.props;

    if (
      notifications
      && prevProps.notifications
      && prevProps.notifications.notificationList[0]
      && notifications.notificationList[0]
      && prevProps.notifications.notificationList[0].id
        !== notifications.notificationList[0].id
    ) {
      return this.setState({ offset: 15 });
    }

    return false
  }

  componentDidUpdate() {
    return false
  }

  loadMore = () => {
    const { notifications } = this.props;

    this.setState({ offset: this.state.offset + 15 });
    return this.props.getNotificationList({
      after:
        notifications.notificationList[
          notifications.notificationList.length - 1
        ].id,
    });
  };

  isMore = () => {
    const { notifications } = this.props;

    if (!notifications) {
      return false;
    }

    return notifications.notificationList.length === this.state.offset;
  };

  render() {
    const notificationClass = classNames('notifications-bar__tab', {
      'notifications-bar__tab_visible':
        this.props.activeState === NOTIFICATION_VIEW,
      card_loading: this.props.isLoading,
    });

    const { notifications } = this.props;

    return (
      <>
        <InfiniteScroll
          loadMore={this.debouncedLoadMore}
          hasMore={this.isMore()}
          initialLoad={false}
          useWindow={false}
          getScrollParent={() => this.props.scrollRef}
        >
          <div className={notificationClass}>
            {notifications
              && notifications.notificationList
              && sortBy(notifications.notificationList, ['createdAt'])
                .reverse()
                .map(item => (
                  <NotificationType
                    message={item.message}
                    type={item.category}
                    id={item.id}
                    key={item.id}
                    item={item}
                    onClose={this.props.onClose}
                    history={this.props.router.navigate}
                    workspace={this.props.workspace}
                    deleteNotification={this.props.deleteNotification}
                    getNotificationList={this.props.getNotificationList}
                    readNotification={this.props.readNotification}
                    unreadNotification={this.props.unreadNotification}
                    unread={!item.readState}
                  />
                ))}
            {((notifications && !notifications.notificationList.length)
              || !notifications) && (
              <div className="notifications-bar__empty">
                <BellIcon />
                <span><FormattedMessage {...messages.noNewNotification} /></span>
              </div>
            )}
          </div>
        </InfiniteScroll>
      </>
    );
  }
}

Notifications.propTypes = {
  activeState: PropTypes.string,
};

export default withRouter(Notifications);
