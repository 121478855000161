import React, { Component } from 'react'
import PropTypes from 'prop-types'
import getSymbolFromCurrency from 'currency-symbol-map'
import NumberFormat from 'react-number-format';
import {FormattedMessage} from 'react-intl'

import Card from 'components/Detail/Card'
import ViewHistory from '../list/history'
import {HistoryIcon, DownloadIcon} from 'images/common'
import appMessges from 'utils/messages'
import {dateFormatter4, getInvoiceFormat} from 'utils/common'
import HistoryIndividual from './HistoryInvoice'

class BillingDetail extends Component {
  state = {
    showEditModal: false,
    showHistoryDetailPage: false,
    showHistoryPage: false
  }

  onAddTags = tags => {
    this.setState({ tags })
  }

  openInvoicePage = (id) => {
    this.setState({ showHistoryDetailPage: true, selectedInvoice: id })
  }

  closeEditModal = () => {
    this.setState({ showHistoryDetailPage: false })
  }

  onEditRole = data => {
    const { tags } = this.state
    const { user } = this.props

    const payload = {
      ...data,
      tags,
      id: user.id
    }

    this.props.onEdit(payload, this.closeEditModal)
  }

  renderContent = () => {
    const { color, invoices = [] } = this.props

    return (
      <div className="flow-modules__data-table data-table">
        <table className="data-table__table">
          <tbody className="data-table__body">
            {invoices &&
            invoices
              .filter((item, index) => index < 3)
              .map(invoice => (
                <tr className={`data-table__row card_${color}`} key={invoice.number} onClick={() => this.openInvoicePage(invoice.invoiceNo)}>
                  <td className="data-table__td data-table__td_1">
                    <div className={`checkbox checkbox_${color}`}>
                      <div className="checkbox__image">
                        <HistoryIcon/>
                      </div>
                    </div>
                  </td>
                  <td className={`data-table__td ${color}`}>{dateFormatter4(invoice.invoiceDate)}</td>
                  <td className="data-table__td ">{getInvoiceFormat(invoice.invoiceNumber)}</td>
                  <NumberFormat
                    value={(invoice.amount && parseFloat(invoice.amount).toFixed(2)) || '0.00'}
                    displayType={'text'}
                    thousandSeparator renderText={value =>
                      <td className="data-table__td">
                        USD {value}</td>
                    }/>
                  {/* <td className="data-table__td data-table__td_1">
                    <a download href={invoice.invoice_pdf} className="invoice_download" target='_blank' rel="noopener noreferrer">
                      <div className="data-table__icon">
                        <DownloadIcon/>
                      </div>
                    </a>
                  </td> */}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    )
  }

  render() {
    const { selectedInvoice } = this.state
    const { color, invoices = [], workspaceName, plans, workspace, billing, isOpen } = this.props

    if (this.state.showHistoryDetailPage) {
      const invoice = invoices && invoices.find(inv => inv.invoiceNo === selectedInvoice);

      return (
        <HistoryIndividual
          workspace={workspace}
          plans={this.props.plans}
          billing={billing}
          onClose={() => this.setState({showHistoryDetailPage: false})}
          upcomingInvoice={invoice}
        />
      )
    }

    if (isOpen) {
      return (
        <ViewHistory
          onClose={this.props.onClose}
          color={color}
          plans={plans}
          billing={billing}
          workspace={workspace}
          invoices={invoices}
          allInvoices={invoices}
          getInvoicesRequest={this.props.getInvoicesRequest}
          workspaceName={workspaceName}/>
      )
    }

    return (
      <Card
        noGrid
        isAccentAction
        action={this.props.onOpen}
        color={this.props.color}
        isLoading={this.props.isLoading}
        title={<FormattedMessage {...appMessges.billHistory}/>}
        className="flow-modules"
        customText={<FormattedMessage {...appMessges.view}/>}
        content={this.renderContent()}
      />
    )
  }
}

BillingDetail.propTypes = {
  user: PropTypes.object
}

export default BillingDetail
