import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import { compose } from 'redux';
import messages from 'utils/messages';
import ProcessPage from 'components/ProcessPage';
import { Field, reduxForm, Form } from 'redux-form';
import Input from 'components/Form/Input';
import { DEFAULT_ACCENT } from 'utils/constants';
import SingleSelect from 'components/Form/SingleSelect';
import { countries } from 'utils/countries';
import { routeMapping } from 'routes';
import asyncValidate from './billingValidate';
import {withRouter} from 'withRouter';

const required = value => (value ? undefined : 'Required');

class Billing extends Component {
  onClose = () => {
    this.props.router.navigate(routeMapping.LOGIN.path);
  }

  render() {
    const {
      color, valid, workspaceName, isLoading,
    } = this.props;

    return (
      <ProcessPage onCancel={this.props.onClose || this.onClose}>
        {() => (
          <div>
            <div className="headline">
              <h1 className="headline__title"><FormattedMessage {...messages.billingInformation} /></h1>
              <h3 className="headline__subtitle">{workspaceName}</h3>
            </div>

            <Form onSubmit={this.props.handleSubmit} className="popup__form">
              <div className={`text-field text-field_${color}`}>
                <FormattedMessage {...messages.bussinessName}>
                  {
                    msg => (
                      <Field
                        parentClass={`text-field__input text-field__input_filled text-field_${color}`}
                        name="name"
                        validate={[required]}
                        component={Input}
                        label={msg}
                      />
                    )
                  }
                </FormattedMessage>
              </div>
              <div className={`select-field select-field_${color}`}>
                <FormattedMessage {...messages.country}>
                  {
                    msg => (
                      <Field
                        parentClass={`select-field select-field_${color}`}
                        name="country"
                        disabled
                        editable
                        options={countries}
                        validate={[required]}
                        component={SingleSelect}
                        label={msg}
                      />
                    )}
                </FormattedMessage>
              </div>
              <div className={`text-field text-field_${color}`}>
                <FormattedMessage {...messages.address1}>
                  {
                    msg => (
                      <Field
                        parentClass={`text-field__input text-field__input_filled text-field_${color}`}
                        name="address1"
                        validate={[required]}
                        component={Input}
                        label={msg}
                      />
                    )}
                </FormattedMessage>
              </div>
              <div className={`text-field text-field_${color}`}>
                <FormattedMessage {...messages.address2}>
                  {
                    msg => (
                      <Field
                        parentClass={`text-field__input text-field__input_filled text-field_${color}`}
                        name="address2"
                        component={Input}
                        label={msg}
                      />
                    )}
                </FormattedMessage>
              </div>
              <div className="grid-row">
                <div className="grid-col grid-col_6">
                  <div className={`text-field text-field_${color}`}>
                    <FormattedMessage {...messages.city}>
                      {
                        msg => (
                          <Field
                            parentClass={`text-field__input text-field__input_filled text-field_${color}`}
                            name="city"
                            component={Input}
                            label={msg}
                          />
                        )}
                    </FormattedMessage>
                  </div>
                </div>

                <div className="grid-col grid-col_6">
                  <div className={`text-field text-field_${color}`}>
                    <FormattedMessage {...messages.postCode}>
                      {
                        msg => (
                          <Field
                            parentClass={`text-field__input text-field__input_filled text-field_${color}`}
                            name="postCode"
                            validate={[required]}
                            component={Input}
                            label={msg}
                          />
                        )}
                    </FormattedMessage>
                  </div>
                </div>
              </div>
              <div className="popup__actions">
                <Button type="submit" className={`button button_${color}`} disabled={!valid || isLoading}>
                  {isLoading ? <FormattedMessage {...messages.loading} /> : <FormattedMessage {...messages.continue} />}
                </Button>
              </div>
            </Form>
          </div>
        )}
      </ProcessPage>
    );
  }
}

Billing.propTypes = {
  color: PropTypes.string,
  user: PropTypes.object,
};


Billing.defaultProps = {
  color: DEFAULT_ACCENT,
};

export default compose(
  reduxForm({
  asyncValidate,
  asyncBlurFields: ['postCode'],
  form: 'BILLING_INFORMATION',
  }),
  withRouter
)(Billing);
