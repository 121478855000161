import React from 'react';

export default () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.67955 3.68671C10.9566 3.68671 13.1253 4.55418 14.7518 6.18067C18.1132 9.54208 18.1132 15.0722 14.7518 18.4336C12.8 20.4938 10.0892 21.2528 7.48679 20.9275L8.02895 18.7589C9.87231 18.9757 11.8241 18.3251 13.2337 16.9155C15.7277 14.4216 15.7277 10.3011 13.2337 7.69873C12.041 6.50597 10.306 5.85537 8.67955 5.85537V10.8433L3.25791 5.42164L8.67955 0V3.68671ZM2.49888 18.4336C-0.320369 15.6143 -0.754101 11.277 1.19769 7.91559L2.82418 9.54208C1.63142 11.9276 2.06515 14.9637 4.12537 16.9155C4.66754 17.4577 5.31813 17.8914 6.07716 18.2167L5.42657 20.3854C4.34224 19.9516 3.36634 19.301 2.49888 18.4336V18.4336Z"
      fill="currentColor"
    />
  </svg>
);
