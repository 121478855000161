export const GET_PROFILES_REQUEST = 'PROFILES/GET_PROFILES_REQUEST';
export const GET_PROFILES_SUCCESS = 'PROFILES/GET_PROFILES_SUCCESS';
export const GET_PROFILES_ERROR = 'PROFILES/GET_PROFILES_ERROR';

export const GET_PENDING_DEVICE_REQUEST = 'GET_PENDING_DEVICE_REQUEST';
export const GET_PENDING_DEVICE_SUCCESS = 'GET_PENDING_DEVICE_SUCCESS';
export const GET_PENDING_DEVICE_ERROR = 'GET_PENDING_DEVICE_ERROR';

export const GET_DEVICES_REQUEST = 'DEVICES/GET_DEVICES_REQUEST';
export const GET_DEVICES_SUCCESS = 'DEVICES/GET_DEVICES_SUCCESS';
export const GET_DEVICES_ERROR = 'DEVICES/GET_DEVICES_ERROR';

export const ASSIGN_DEVICES_REQUEST = 'DEVICES/ASSIGN_DEVICES_REQUEST';
export const ASSIGN_DEVICES_SUCCESS = 'DEVICES/ASSIGN_DEVICES_SUCCESS';
export const ASSIGN_DEVICES_ERROR = 'DEVICES/ASSIGN_DEVICES_ERROR';

export const ASSIGN_DEVICE_REQUEST = 'DEVICES/ASSIGN_DEVICE_REQUEST';
export const ASSIGN_DEVICE_SUCCESS = 'DEVICES/ASSIGN_DEVICE_SUCCESS';
export const ASSIGN_DEVICE_ERROR = 'DEVICES/ASSIGN_DEVICE_ERROR';

export const GET_CURRENT_DEPLOYMENT_COUNT_REQUEST = 'PROFILES/GET_CURRENT_DEPLOYMENT_COUNT_REQUEST';
export const GET_CURRENT_DEPLOYMENT_COUNT_SUCCESS = 'PROFILES/GET_CURRENT_DEPLOYMENT_COUNT_SUCCESS';
export const GET_CURRENT_DEPLOYMENT_COUNT_ERROR = 'PROFILES/GET_CURRENT_DEPLOYMENT_COUNT_ERROR';

export const CARD_VIEW = 'card';
export const TABLE_VIEW = 'table';
