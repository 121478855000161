import React from 'react';

export default () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.0011 7.16667H13.3345V0.5H20.0011V7.16667ZM15.0011 5.5H18.3345V2.16667H15.0011V5.5Z"
      fill="currentColor"
    />
    <path
      d="M10.8322 9.66699V4.66699H4.16553V16.3337H15.8322V9.66699H10.8322ZM5.83219 6.33366H9.16553V9.66699H5.83219V6.33366ZM9.16553 14.667H5.83219V11.3337H9.16553V14.667ZM14.1655 14.667H10.8322V11.3337H14.1655V14.667Z"
      fill="currentColor"
    />
    <path
      d="M18.3333 20.5H1.66667C1.22477 20.4996 0.801108 20.3238 0.488643 20.0114C0.176178 19.6989 0.000441231 19.2752 0 18.8333V2.16667C0.000441231 1.72477 0.176178 1.30111 0.488643 0.988643C0.801108 0.676178 1.22477 0.500441 1.66667 0.5H10V2.16667H1.66667V18.8333H18.3333V10.5H20V18.8333C19.9996 19.2752 19.8238 19.6989 19.5114 20.0114C19.1989 20.3238 18.7752 20.4996 18.3333 20.5Z"
      fill="currentColor"
    />
  </svg>
);
