import {
  GET_INVOICES_REQUEST,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_ERROR,
  GET_BILLING_DETAILS_REQUEST,
  GET_BILLING_DETAILS_ERROR,
  GET_BILLING_DETAILS_SUCCESS,
  UPDATE_BILLING_ERROR,
  UPDATE_BILLING_REQUEST,
  UPDATE_BILLING_SUCCESS,
  CHANGE_SUBSCRIPTION_REQUEST,
  GET_SUBSCRIPTION_DETAILS_REQUEST,
  GET_SUBSCRIPTION_DETAILS_SUCCESS,
  GET_SUBSCRIPTION_DETAILS_ERROR,
  GET_UPCOMING_INVOICES_REQUEST,
  GET_UPCOMING_INVOICES_SUCCESS,
  GET_UPCOMING_INVOICES_ERROR,
  ADD_PLAN_DETAILS_SUBSCRIPTION,
  CLEAR_UPCOMING_INVOICES_REQUEST,
  ADD_PLAN_DETAILS_SUBSCRIPTION_PENDING,
} from './constants';

export function changeSubscriptionRequest(payload, onCallback) {
  return {
    type: CHANGE_SUBSCRIPTION_REQUEST,
    payload,
    meta: onCallback,
  };
}

export function updateBillingRequest(payload) {
  return {
    type: UPDATE_BILLING_REQUEST,
    payload,
  };
}

export function updateBillingError(payload) {
  return {
    type: UPDATE_BILLING_ERROR,
    payload,
  };
}

export function updateBillingSuccess(payload) {
  return {
    type: UPDATE_BILLING_SUCCESS,
    payload,
  };
}

export function getInvoicesRequest(payload) {
  return {
    type: GET_INVOICES_REQUEST,
    payload,
  };
}

export function getInvoicesError(payload) {
  return {
    type: GET_INVOICES_ERROR,
    payload,
  };
}

export function getInvoicesSuccess(payload) {
  return {
    type: GET_INVOICES_SUCCESS,
    payload,
  };
}

export function getBillingDetailsRequest(payload) {
  return {
    type: GET_BILLING_DETAILS_REQUEST,
    payload,
  };
}

export function getBillingDetailsError(payload) {
  return {
    type: GET_BILLING_DETAILS_ERROR,
    payload,
  };
}

export function getBillingDetailsSuccess(payload) {
  return {
    type: GET_BILLING_DETAILS_SUCCESS,
    payload,
  };
}

export function getUpcomingInvoicesRequest(payload) {
  return {
    type: GET_UPCOMING_INVOICES_REQUEST,
    payload,
  };
}

export function clearUpcomingInvoicesRequest(payload) {
  return {
    type: CLEAR_UPCOMING_INVOICES_REQUEST,
    payload,
  };
}

export function getUpcomingInvoicesError(payload) {
  return {
    type: GET_UPCOMING_INVOICES_ERROR,
    payload,
  };
}

export function getUpcomingInvoicesSuccess(payload) {
  return {
    type: GET_UPCOMING_INVOICES_SUCCESS,
    payload,
  };
}

export function getSubscriptionDetailsRequest(payload) {
  return {
    type: GET_SUBSCRIPTION_DETAILS_REQUEST,
    payload,
  };
}

export function getSubscriptionDetailsError(payload) {
  return {
    type: GET_SUBSCRIPTION_DETAILS_ERROR,
    payload,
  };
}

export function getSubscriptionDetailsSuccess(payload) {
  return {
    type: GET_SUBSCRIPTION_DETAILS_SUCCESS,
    payload,
  };
}


export function addPlanDetailInSubscription(payload) {
  return {
    type: ADD_PLAN_DETAILS_SUBSCRIPTION_PENDING,
    payload,
  };
}

export function addPlanDetailInSubscriptionSuccess(payload) {
  return {
    type: ADD_PLAN_DETAILS_SUBSCRIPTION,
    payload,
  };
}
