export const LIST_SOLUTION_REQUEST = 'PARTNER/LIST_SOLUTION_REQUEST';
export const LIST_SOLUTION_SUCCESS = 'PARTNER/LIST_SOLUTION_SUCCESS';
export const LIST_SOLUTION_ERROR = 'PARTNER/LIST_SOLUTION_ERROR';

export const CLEAR_VENDOR_REQUEST = 'PARTNER/CLEAR_VENDOR_REQUEST';

export const GET_SOLUTION_DETAILS_REQUEST = 'PARTNER/GET_SOLUTION_DETAILS_REQUEST';
export const GET_SOLUTION_DETAILS_SUCCESS = 'PARTNER/GET_SOLUTION_DETAILS_SUCCESS';
export const GET_SOLUTION_DETAILS_ERROR = 'PARTNER/GET_SOLUTION_DETAILS_ERROR';

export const GET_SOLUTION_BASIC_INFO_REQUEST = 'PARTNER/GET_SOLUTION_BASIC_INFO_REQUEST';
export const GET_SOLUTION_BASIC_INFO_SUCCESS = 'PARTNER/GET_SOLUTION_BASIC_INFO_SUCCESS';
export const GET_SOLUTION_BASIC_INFO_ERROR = 'PARTNER/GET_SOLUTION_BASIC_INFO_ERROR';

export const ADD_SOLUTION_REQUEST = 'PARTNER/ADD_SOLUTION_REQUEST';
export const ADD_SOLUTION_SUCCESS = 'PARTNER/ADD_SOLUTION_SUCCESS';
export const ADD_SOLUTION_ERROR = 'PARTNER/ADD_SOLUTION_ERROR';

export const UPDATE_SOLUTION_REQUEST = 'PARTNER/UPDATE_SOLUTION_REQUEST';
export const UPDATE_SOLUTION_SUCCESS = 'PARTNER/UPDATE_SOLUTION_SUCCESS';
export const UPDATE_SOLUTION_ERROR = 'PARTNER/UPDATE_SOLUTION_ERROR';

export const UPDATE_SOLUTION_INFO_REQUEST = 'PARTNER/UPDATE_SOLUTION_INFO_REQUEST';
export const UPDATE_SOLUTION_INFO_SUCCESS = 'PARTNER/UPDATE_SOLUTION_INFO_SUCCESS';
export const UPDATE_SOLUTION_INFO_ERROR = 'PARTNER/UPDATE_SOLUTION_INFO_ERROR';

export const ADD_SOLUTION_FILE_REQUEST = 'PARTNER/ADD_SOLUTION_FILE_REQUEST';
export const ADD_SOLUTION_FILE_SUCCESS = 'PARTNER/ADD_SOLUTION_FILE_SUCCESS';
export const ADD_SOLUTION_FILE_ERROR = 'PARTNER/ADD_SOLUTION_FILE_ERROR';

export const ADD_SOLUTION_FILES_REQUEST = 'PARTNER/ADD_SOLUTION_FILES_REQUEST';
export const ADD_SOLUTION_FILES_SUCCESS = 'PARTNER/ADD_SOLUTION_FILES_SUCCESS';
export const ADD_SOLUTION_FILES_ERROR = 'PARTNER/ADD_SOLUTION_FILES_ERROR';

export const ON_SUBMIT_SOLUTION_REQUEST = 'PARTNER/ON_SUBMIT_SOLUTION_REQUEST';
export const ON_SUBMIT_SOLUTION_SUCCESS = 'PARTNER/ON_SUBMIT_SOLUTION_SUCCESS';
export const ON_SUBMIT_SOLUTION_ERROR = 'PARTNER/ON_SUBMIT_SOLUTION_ERROR';

export const REMOVE_SOLUTION_FILE = 'PARTNER/REMOVE_SOLUTION_FILE';
export const REMOVE_SOLUTION_IMAGES = 'PARTNER/REMOVE_SOLUTION_IMAGES';

// vendor
export const GET_VENDOR_REQUEST = 'PARTNER/GET_VENDOR_REQUEST';
export const GET_VENDOR_SUCCESS = 'PARTNER/GET_VENDOR_SUCCESS';
export const GET_VENDOR_ERROR = 'PARTNER/GET_VENDOR_ERROR';

export const UPDATE_VENDOR_REQUEST = 'PARTNER/UPDATE_VENDOR_REQUEST';
export const UPDATE_VENDOR_SUCCESS = 'PARTNER/UPDATE_VENDOR_SUCCESS';
export const UPDATE_VENDOR_ERROR = 'PARTNER/UPDATE_VENDOR_ERROR';

export const SUBMIT_PARTNER_APPLICATION_REQUEST = 'PARTNER/SUBMIT_PARTNER_APPLICATION_REQUEST';
export const SUBMIT_PARTNER_APPLICATION_SUCCESS = 'PARTNER/SUBMIT_PARTNER_APPLICATION_SUCCESS';
export const SUBMIT_PARTNER_APPLICATION_ERROR = 'PARTNER/SUBMIT_PARTNER_APPLICATION_ERROR';


export const SET_DEFAULT_PRICE_REQUEST = 'PARTNER/SET_DEFAULT_PRICE_REQUEST';
export const SET_DEFAULT_PRICE_SUCCESS = 'PARTNER/SET_DEFAULT_PRICE_SUCCESS';
export const SET_DEFAULT_PRICE_ERROR = 'PARTNER/SET_DEFAULT_PRICE_ERROR';

export const SOLUTION_DELETE_REQUEST = 'PARTNER/SOLUTION_DELETE_REQUEST';
export const SOLUTION_DELETE_SUCCESS = 'PARTNER/SOLUTION_DELETE_SUCCESS';
export const SOLUTION_DELETE_ERROR = 'PARTNER/SOLUTION_DELETE_ERROR';

export const SOLUTION_CANCEL_REQUEST = 'PARTNER/SOLUTION_CANCEL_REQUEST';
export const SOLUTION_CANCEL_SUCCESS = 'PARTNER/SOLUTION_CANCEL_SUCCESS';
export const SOLUTION_CANCEL_ERROR = 'PARTNER/SOLUTION_CANCEL_ERROR';

export const LIST_DASHBOARD_REQUEST = 'PARTNER/LIST_DASHBOARD_REQUEST';
export const LIST_DASHBOARD_SUCCESS = 'PARTNER/LIST_DASHBOARD_SUCCESS';
export const LIST_DASHBOARD_ERROR = 'PARTNER/LIST_DASHBOARD_ERROR';
