import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import appMessages from 'utils/messages';
import {
  CirclePlusIcon,
  WorkspaceIcon,
  PlusXsIcon,
} from 'images/common';

import { SOLUTION_ACCENT } from 'utils/constants';
import DetailScroll from 'components/Detail/Scroll';
import Breadcrumb, { BreadcrumbItem } from 'components/Breadcrumb';
import TopNav from 'containers/TopNav';

class ManageWorkspace extends React.Component {
  state = {
  };


  render() {
    return (
      <>
        <TopNav
          addSpacing
          header={<FormattedMessage {...appMessages.myWorkspaces} />}
          hideNotification
          onBack={() => this.props.router.navigate(-1)}
        >
          <div
            className={'actions-nav__versions'}
          >
            <CirclePlusIcon />
          </div>
        </TopNav>
        <DetailScroll>
          <div className="headline">
            <h1 className="headline__title heading__loading">
              <FormattedMessage {...appMessages.myWorkspaces} />
            </h1>
          </div>

          <Breadcrumb className="breadcrumbs_loading">
            <BreadcrumbItem route="Settings" />
            <BreadcrumbItem route="My Workspaces" />
          </Breadcrumb>

          <h3 className="manage-workspace__title heading__loading">
            <FormattedMessage {...appMessages.yourWorkspaces} />
          </h3>
          <div className="">
            <div className="">
              <p className="manage-workspace__description heading__loading">
                <FormattedMessage {...appMessages.manageWorkspace} />
              </p>
            </div>
          </div>

          <div className="data-cards">
            <div className="grid-row">
              {[1, 2, 3, 4, 5, 6].map(ws => (
                <div
                  className="data-card loading"
                  key={ws.subDomain}
                >
                  <div
                    className={classNames('data-cards__item', {
                    })}
                  >
                    <div
                      className="grid-row grid-row_aic cursor"
                    >
                      <div className="grid-col grid-col_auto">
                        <div className="checkbox">
                          <div
                            className={classNames('checkbox__image', {
                              'no-border-only': ws && ws.logo,
                            })}
                          >
                            <WorkspaceIcon />
                          </div>
                        </div>
                      </div>
                      <div className="grid-col">
                        <div className="data-cards__heading h4">
                          Current ws
                        </div>
                        <div className="data-cards__detail">
                          Current role
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </DetailScroll>
      </>
    );
  }
}

ManageWorkspace.defaultProps = {
  color: SOLUTION_ACCENT,
};

export default ManageWorkspace;
