import BillingCard from 'components/BillingCard';
import PaymentCard from 'components/PaymentCard';
import PolicyLink from 'components/PolicyLink';
import ProcessPage from 'components/ProcessPage';
import SubscriptionCard from 'components/SubscriptionCard';
import TermsLink from 'components/TermsLink';
import Dxc from 'images/billing/dxc.png';
import Hpe from 'images/billing/hpe.png';
import Intel from 'images/billing/intel.png';
import Nvidia from 'images/billing/nvidia.png';
import Samsumg from 'images/billing/samsung.png';
import { RocketIcon } from 'images/common';
import React, { Component } from 'react';
import { Info } from 'react-feather';
import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';
import { isFreePlan } from 'utils/common';
import { DEFAULT_ACCENT } from 'utils/constants';
import messages from '../../../utils/messages';
import {fetchPlansRequest} from 'containers/Login/actions'
import {makePlanData} from 'containers/Login/selectors'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

const Title = ({isFreeTrial, isCreatePage}) => {
  if (isFreeTrial) {
    return <span>Start your free trial</span>;
  }

  if (isCreatePage) {
    return <span>Start your developer workspace</span>
  }

  return <FormattedMessage {...messages.workspaceSubscription} />;
};

const Description = ({isFreeTrial, isCreatePage}) => {
  if (isFreeTrial) {
    return <span>Get started with your Viso Suite Workspace.</span>;
  }

  if (isCreatePage) {
    return <span>Exclusive developer access to use Viso Suite infrastructure for free.</span>
  }

  return <FormattedMessage {...messages.getStartedWithWs} />;
};

class PaymentInfo extends Component {
  componentDidMount() {
    const {plans} = this.props;

    if (!plans) {
      this.props.fetchPlansRequest()
    }
  }

  render() {
    const {
      showCard,
      isLoading,
      color,
      subscription,
      workspace,
      interval,
      isUpdatePlan,
      billing,
      billingInfo,
      isCreatePage
    } = this.props;
    let trialPeriods = subscription.ype === 'TRIAL' && (subscription.ype === 'TRIAL').duration.number;

    if (isUpdatePlan) {
      trialPeriods = '';
    }

    let availableCredits = '';

    if (billingInfo) {
      availableCredits =
        billingInfo.total < 0 && billingInfo.total + billingInfo.amount_due < 0
          ? billingInfo.total + billingInfo.amount_due
          : '';
    }

    const isFree = isFreePlan(subscription, interval);
    const trialFee =
    subscription.trialFee;

    const isFreeTrial = trialPeriods && !trialFee;

    let currentEndpoint = '';

    if (isUpdatePlan) {
      const currentEndpointWithoutProration =
        billingInfo &&
        billingInfo.lines &&
        billingInfo.lines.data
          .filter((line) => !line.proration)
          .map((line) => line.quantity).length &&
        billingInfo.lines.data
          .filter((line) => !line.proration)
          .map((line) => line.quantity)
          .reduce((sum, line) => sum + line);

      const currentEndpointWithProration =
        billingInfo &&
        billingInfo.lines &&
        billingInfo.lines.data
          .filter((line) => line.proration)
          .filter((line) => line.amount > 0)
          .map((line) => line.quantity).length &&
        billingInfo.lines.data
          .filter((line) => line.proration)
          .filter((line) => line.amount > 0)
          .map((line) => line.quantity)
          .reduce((sum, line) => sum + line);

      currentEndpoint =
        currentEndpointWithProration + currentEndpointWithoutProration;
    }

    return (
      <ProcessPage
        onCancel={() => this.props.onClose()}
        customClass="popup_checkout"
      >
        {() => (
          <>
            <div className="headline">
              <h1 className="headline__title">
                <Title isFreeTrial={isFreeTrial} isCreatePage={isCreatePage} />
              </h1>
              <h3 className="headline__subtitle">
                <Description isFreeTrial={isFreeTrial} isCreatePage={isCreatePage} />
              </h3>
            </div>
            <div className="grid-row grid-row_jcc">
              <div className="billing-card-wrapper">
                {isFreeTrial && !isCreatePage && (
                  <div className="billing-info payment-info free-info">
                    <RocketIcon />
                    <span className="billing-info-explore">
                      <span className="payment-info-text">
                        Please provide a credit card and verify your identify.{' '}
                        <span className="bold">
                          You won’t be charged today and can cancel any time.
                        </span>{' '}
                        If you have any questions, please contact us.
                      </span>
                    </span>
                  </div>
                )}
                {!isFreeTrial && isCreatePage && (
                  <div className="billing-info payment-info free-info">
                    <RocketIcon />
                    <span className="billing-info-explore">
                      <span className="payment-info-text">
                        To complete your Free Partner Workspace, you must
                        provide a credit card and verify your identity. It’s
                        required since you get access to the marketplace, cloud
                        resources and software.{' '}
                        <span className="bold">You won’t be charged</span> until
                        you change to a paid plan.
                      </span>
                    </span>
                  </div>
                )}
                {this.props.isCreatePage && !isFreeTrial && !isCreatePage && (
                  <div className="billing-info payment-info">
                    <RocketIcon />
                    <span className="billing-info-explore">
                      <span className="payment-info-text">
                        <FormattedMessage
                          {...messages.developerAccessBilling}
                        />
                      </span>
                    </span>
                  </div>
                )}
                {!isUpdatePlan && !isCreatePage && !isFreeTrial && (
                  <div className="billing-info payment-info">
                    <RocketIcon />
                    <span className="billing-info-explore">
                      {isFree ? (
                        <span className="payment-info-text">
                          <FormattedMessage {...messages.chooseTheFreePlan} />
                        </span>
                      ) : (
                        <span className="payment-info-text">
                          {trialPeriods ? (
                            <FormattedMessage
                              {...messages.pickAPlanToTrail}
                              values={{
                                trialPeriods,
                              }}
                            />
                          ) : (
                            <FormattedMessage {...messages.chooseToPay} />
                          )}
                        </span>
                      )}
                    </span>
                  </div>
                )}
                {isUpdatePlan && !isCreatePage && (
                  <div className="billing-info payment-change">
                    <Info />
                    <span className="billing-info-explore">
                      <span className="payment-change-text">
                        <FormattedMessage
                          {...messages.proceedToChange}
                          values={{
                            current: (
                              <span className="billing-change-info">
                                {workspace &&
                                  workspace.plan &&
                                  workspace.plan.name}
                              </span>
                            ),
                            next: (
                              <span className="billing-change-info">
                                {subscription.name}
                              </span>
                            ),
                          }}
                        />
                      </span>
                    </span>
                  </div>
                )}
                {isUpdatePlan && availableCredits && (
                  <div className="billing-info billing-info-accent0">
                    <Info />
                    <span className="billing-info-explore">
                      <span className="payment-change-text">
                        <NumberFormat
                          value={Math.abs(availableCredits / 100).toFixed(2)}
                          displayType="text"
                          thousandSeparator
                          renderText={(value) => (
                            <FormattedMessage
                              {...messages.forThePaidPeriod}
                              values={{
                                amount: (
                                  <span className="billing-change-info">
                                    ${value}
                                  </span>
                                ),
                              }}
                            />
                          )}
                        />
                      </span>
                    </span>
                  </div>
                )}

                <div className="subscriptions">
                  <SubscriptionCard
                    subscriptionDetails={subscription}
                    item={subscription}
                    plans={this.props.plans}
                    color={color}
                    isFreeTrial={isFreeTrial}
                    hideToggle={this.props.isCreatePage || isFreeTrial}
                    isCreatePage={isCreatePage}
                    trialPeriods={trialPeriods}
                    minEndpoints={currentEndpoint || subscription.minEndpoints}
                    onBack={this.props.onBack}
                    interval={this.props.interval}
                    onRemove={this.onRemove}
                    onShowPlanPage={this.onShowPlanPage}
                    onShowConfirmationPage={this.onShowConfirmationPage}
                    onUpdateInterval={this.props.onUpdateInterval}
                    workspace={workspace}
                  />
                </div>
                {!this.props.recoverWs && (
                  <PaymentCard
                    {...this.props}
                    billing={billing}
                    showCreditCard={this.props.showCreditCard}
                    isFreePrice={this.props.isFreePrice}
                    isUpdatePlan={isUpdatePlan}
                    updateCreditCard={this.props.updateCreditCard}
                    onUpdateCreditToggle={this.props.onUpdateCreditToggle}
                    onChangeCard={this.props.onChangeCard}
                    showCard={showCard}
                    isFreeTrial={isFreeTrial}
                    isCreatePage={isCreatePage}
                    trialPeriods={trialPeriods}
                    isValidZipcode={this.props.isValidZipcode}
                    onUpdateInterval={this.props.onUpdateInterval}
                    onGetPaymentInfo={this.props.onGetPaymentInfo}
                    onChangePayment={this.props.onChangePayment}
                  />
                )}
              </div>
              <div className="billing-bill-wrapper">
                <BillingCard
                  color={color}
                  isUpdatePlan={isUpdatePlan}
                  isFreeTrial={isFreeTrial}
                  plans={this.props.plans}
                  isFreePrice={this.props.isFreePrice}
                  isCreatePage={isCreatePage}
                  selectedCountry={this.props.selectedCountry}
                  organizationSize={this.props.organizationSize}
                  billingLoading={this.props.billingLoading}
                  trialPeriods={trialPeriods}
                  isDisabled={this.props.isDisabled || isLoading}
                  interval={this.props.interval}
                  subscriptionDetails={subscription}
                  billingInfo={billingInfo}
                  onSubmit={this.props.onSubmit}
                  onSubmitWithoutCard={this.props.onSubmitWithoutCard}
                  workspaceName={
                    this.props.workspace && this.props.workspace.name
                  }
                />
                {isFreeTrial && (
                  <div className="estimated-revenue__footer pd-s-25">
                    By clicking “Start your free {trialPeriods} day trial”, you
                    agree to viso.ai’s
                    <TermsLink /> and <PolicyLink />. Your credit card will be
                    charged monthly and your fees may vary based on how we bill.
                  </div>
                )}
                {isCreatePage && (
                  <div className="estimated-revenue__footer pd-s-25">
                    By clicking “Get developer access now”, you agree to
                    viso.ai’s <TermsLink /> and <PolicyLink />. On selecting a
                    paid plan, the credit card will be charged monthly and your
                    fees may vary based on how we bill.
                  </div>
                )}
              </div>
            </div>
            {(isFreeTrial || isCreatePage) && (
              <div className="billing-footer-wrapper">
                <h2 className="billing-footer-title">
                  Trusted by leading organizations
                </h2>

                <div className="grid-row grid-row_jcb grid-row_aic">
                  <div className="billing-footer-item">
                    <img width="59px" height="25px" src={Intel} alt="intel" />
                  </div>
                  <div className="billing-footer-item">
                    <img width="96px" height="59px" src={Nvidia} alt="nvidia" />
                  </div>
                  <div className="billing-footer-item">
                    <img
                      width="88px"
                      height="59px"
                      src={Samsumg}
                      alt="samsumg"
                    />
                  </div>
                  <div className="billing-footer-item">
                    <img width="158px" height="27px" src={Dxc} alt="dxc" />
                  </div>
                  <div className="billing-footer-item">
                    <img width="101px" height="42px" src={Hpe} alt="hpe" />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </ProcessPage>
    );
  }
}

PaymentInfo.propTypes = {};

PaymentInfo.defaultProps = {
  color: DEFAULT_ACCENT,
};

const mapStateToProps = createStructuredSelector({
  plans: makePlanData()
});

const withConnect = connect(
  mapStateToProps,
  {
    fetchPlansRequest
  }
);

export default compose(
  withConnect,
  withConnect
)(PaymentInfo);
