import { createSelector } from 'reselect';

const selectLogin = state => state.login;

export const makeSelectLoginData = () => createSelector(selectLogin, loginState => loginState.get('loginData'));

export const makeLoggedInEmail = () => createSelector(selectLogin, loginState => loginState.get('loggedInEmail'));

export const makeworkspaceSetupInvited  = () =>
  createSelector(selectLogin, loginState => loginState.get('workspaceSetupInvited '))

export const makePlanData = () => createSelector(selectLogin, loginState => loginState.get('plans'));

export const makeSelectError = () => createSelector(selectLogin, loginState => loginState.get('errors'));

export const makeSelectLoading = () => createSelector(selectLogin, loginState => loginState.get('loading'));

export const makeSelectFPLoading = () => createSelector(selectLogin, loginState => loginState.get('forgotLoading'));

export const makeSelectFPError = () => createSelector(selectLogin, loginState => loginState.get('forgotError'));

export const makeSelectFPData = () => createSelector(selectLogin, loginState => loginState.get('forgotData'));

export const makeSelectResetPassword = () => createSelector(selectLogin, state => state.get('resetData'));

export const makeSelectResetPasswordError = () => createSelector(selectLogin, state => state.get('errors'));

export const makeHomePageLoader = () => createSelector(selectLogin, loginState => loginState.get('isHomePageLoading'));

export const makePlanSubscriptionLoader = () => createSelector(selectLogin, loginState => loginState.get('isPlanSubscriptionLoading'));

export { selectLogin };
