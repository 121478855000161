import React, { Component } from 'react';
import localForage from 'localforage';
import { connect } from 'react-redux';
import {withRouter } from 'withRouter'

import { createStructuredSelector } from 'reselect';

import Workspace from 'containers/Auth/Register/index';
import { makeSelectMe } from 'containers/Settings/User/selectors';
import {getWorkspacePending} from 'api/workspace'
import LanguageProvider from 'containers/LanguageProvider';
import { translationMessages } from '../../../i18n';

class CreateWorkspace extends Component {
  state = {
    isCreatePage: false
  }

  async componentDidMount() {
    localForage.setItem('activePartnerId', null);
  }

  render() {
    const query = new URLSearchParams(this.props.router.location.search);
    const isCreatePage = query.get('isCreatePage');

    return (
      <LanguageProvider messages={translationMessages}>
        <Workspace isCreateWorkspace isCreatePage={isCreatePage}/>
      </LanguageProvider>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectMe(),
});

const withConnect = connect(
  mapStateToProps,
);

export default withRouter(withConnect(CreateWorkspace));
