import postalCodes from 'postal-codes-js';

const billingValidate = async (values/* , dispatch */) => {
  const message = postalCodes.validate(values.country, values.postCode);
  if (typeof message === 'boolean') {
    return;
  }

  if (message) {
    const errorMessage = { code: 403, postCode: message };

    throw errorMessage;
  }
};

export default billingValidate;
