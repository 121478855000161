export const EXTERNAL_URL = {
  support: 'https://support.viso.ai/',
  earlyAccessTerms: 'https://viso.ai/legal/early-access-terms-of-use/',
  pricing: 'https://viso.ai/pricing/',
  partner: 'https://viso.ai/legal/marketplace-terms-of-use/',
  partnerAgreement: 'https://viso.ai/legal/marketplace-partner-agreement/',
  terms: 'https://viso.ai/legal/terms-of-service/',
  policy: 'https://viso.ai/legal/privacy-policy/',
  contact: 'http://viso.ai/contact ',
};

export const installedStatus = {
  INSTALLING: 0,
  INSTALLED: 1,
  NOT_INSTALLED: 2,
};

export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const COLOR_BASE = '#2184F9';
export const COLOR_ACC1 = '#845EC2';
export const COLOR_ACC2 = '#D65DB1';
export const COLOR_ACC3 = '#FF6F91';
export const COLOR_ACC4 = '#FF9671';
export const COLOR_C2_TEXT = '#8B959E';
export const COLOR_C1_TEXT = '#444444';

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_SHORTENED_DESCRIPTION_COUNT = 100;
export const AUTOSAVE_WAIT_INTERVAL = 500;
export const DEFAULT_SHORTENED_TEXT_COUNT = 35;
export const SNACKBAR_TIMEOUT = 6000;
export const REFETCH_IMAGE_LIST = 300000;
export const APP_AUTO_RELOAD = 30000;
export const APP_SESSION_LOGOUT = 3600000;

export const LANGUAGES = [
  { value: 'english', label: 'English' },
  { value: 'german', label: 'Deutsch' },
  // {value: 'italy', label: 'Italy'},
  // {value: 'french', label: 'French'}
];
export const STATUS = [{ value: 'active', label: 'Active' }, { value: 'invited', label: 'Invited' }, { value: 'inactive', label: 'Inactive' }];

export const CARD_VIEW = 'card';
export const TABLE_VIEW = 'list';

export const DEFAULT_ACCENT = 'accent0';
export const PRIMARY_ACCENT = 'primary';
export const LIBRARY_ACCENT = 'accent3';
export const SOLUTION_ACCENT = 'accent4';
export const DASHBOARD_ACCENT_COLOR = 'accent2';

export const roleLevel = {
  OWNER_ROLE: 10,
  ADMIN_ROLE: 20,
  MEMBER_ROLE: 30,
};

export const INTERVAL = [
  {
    label: 'Monthly',
    value: 'month',
  },
  {
    label: 'Yearly',
    value: 'year',
  },
];

export const CURRENCY = [
  {
    label: 'USD - US Dollar',
    value: 'usd',
  },
];

export const TIME_ZONE = [
  {
    value: 'utc',
    label: '(UTC+0000) UTC',
  },
  {
    value: 'est',
    label: '(UTC-5) EST',
  },
];

export const IMPORT_TYPE = [
  {
    label: 'Upload video file',
    value: 'uploadFile',
  },
  {
    label: 'Link to external video',
    value: 'externalVideo',
  },
];

export const UUID_LENGTH = 37;

export const AVAILABLE_COUNTRIES = [
  { label: 'Australia', value: 'AU' },
  { label: 'Austria', value: 'AT' },
  { label: 'Belarus', value: 'BY' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Canada', value: 'CA' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'Germany', value: 'DE' },
  { label: 'Greece', value: 'GR' },
  { label: 'Greenland', value: 'GL' },
  { label: 'Iceland', value: 'IS' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Israel', value: 'IL' },
  { label: 'Italy', value: 'IT' },
  { label: 'Japan', value: 'JP' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'North Korea', value: 'KP' },
  { label: 'Norway', value: 'NO' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Qatar', value: 'QA' },
  { label: 'Russia', value: 'RU' },
  { label: 'Saudi Arabia', value: 'SA' },
  { label: 'Singapore', value: 'SG' },
  { label: 'South Africa', value: 'ZA' },
  { label: 'Spain', value: 'ES' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Taiwan', value: 'TW' },
  { label: 'United Arab Emirates', value: 'AE' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'United States', value: 'US' },
];
