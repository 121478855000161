export const LIST_LIBRARY_APPLICATIONS_REQUEST = 'LIBRARY/LIST_FLOWS_REQUEST';
export const LIST_LIBRARY_APPLICATIONS_SUCCESS = 'LIBRARY/LIST_FLOWS_SUCCESS';
export const LIST_LIBRARY_APPLICATIONS_ERROR = 'LIBRARY/LIST_FLOWS_ERROR';

export const LIST_LIBRARY_MODELS_REQUEST = 'LIBRARY/LIST_MODELS_REQUEST';
export const LIST_LIBRARY_MODELS_SUCCESS = 'LIBRARY/LIST_MODELS_SUCCESS';
export const LIST_LIBRARY_MODELS_ERROR = 'LIBRARY/LIST_MODELS_ERROR';

export const LIST_LIBRARY_RELATED_MODELS_REQUEST = 'LIBRARY/LIST_RELATED_MODELS_REQUEST';
export const LIST_LIBRARY_RELATED_MODELS_SUCCESS = 'LIBRARY/LIST_RELATED_MODELS_SUCCESS';
export const LIST_LIBRARY_RELATED_MODELS_ERROR = 'LIBRARY/LIST_RELATED_MODELS_ERROR';

export const LIST_LIBRARY_MODULES_REQUEST = 'LIBRARY/LIST_MODULES_REQUEST';
export const LIST_LIBRARY_MODULES_SUCCESS = 'LIBRARY/LIST_MODULES_SUCCESS';
export const LIST_LIBRARY_MODULES_ERROR = 'LIBRARY/LIST_MODULES_ERROR';
export const GET_MODULE_REQUEST = 'MODULE/GET_MODULE_REQUEST';

export const GET_MODULE_VERSION_REQUEST = 'MODULE/GET_MODULE_VERSION_REQUEST';
export const GET_MODULE_VERSION_SUCCESS = 'MODULE/GET_MODULE_VERSION_SUCCESS';

export const GET_MODULE_SUCCESS = 'MODULE/GET_MODULE_SUCCESS';
export const GET_MODULE_ERROR = 'MODULE/GET_MODULE_ERROR';
export const UPDATE_MODULE_REQUEST = 'MODULE/UPDATE_MODULE_REQUEST';
export const UPDATE_MODULE_SUCCESS = 'MODULE/UPDATE_MODULE_SUCCESS';

export const NAVIGATE_FLOWS = 'flows';
export const NAVIGATE_MODULES = 'modules';
export const CARD_VIEW = 'card';
export const TABLE_VIEW = 'list';
export const LOADING_PARAMS = 'loading_params';
export const EDIT_MODULE_POPUP = 'module/EDIT_MODULE_POPUP';
export const GIT_REPO_POPUP = 'module/GIT_REPO_POPUP';

export const GET_SSH_KEY_REQUEST = 'SSH_LIST/GET_SSH_KEY_REQUEST';
export const GET_SSH_KEY_SUCCESS = 'SSH_LIST/GET_SSH_KEY_SUCCESS';
export const GET_SSH_KEY_ERROR = 'SSH_LIST/GET_SSH_KEY_ERROR';

export const GET_EXPERIMENTS_REQUEST = 'GET_EXPERIMENTS_REQUEST';
export const GET_EXPERIMENTS_SUCCESS = 'GET_EXPERIMENTS_SUCCESS';
export const GET_EXPERIMENTS_ERROR = 'GET_EXPERIMENTS_ERROR';

export const GET_APPLICATION_DETAIL_REQUEST = 'APPLICATION_DETAIL/GET_APPLICATION_DETAIL_REQUEST';
export const GET_APPLICATION_DETAIL_SUCCESS = 'APPLICATION_DETAIL/GET_APPLICATION_DETAIL_SUCCESS';
export const GET_APPLICATION_DETAIL_ERROR = 'APPLICATION_DETAIL/GET_APPLICATION_DETAIL_ERROR';
export const GET_FLOW_VERSION_REQUEST = 'APPLICATION_DETAIL/GET_FLOW_VERSION_REQUEST';

export const UPDATE_APPLICATION_REQUEST = 'APPLICATION_UPDATE/UPDATE_APPLICATION_REQUEST';
export const UPDATE_APPLICATION_SUCCESS = 'APPLICATION_UPDATE/UPDATE_APPLICATION_SUCCESS';
export const UPDATE_APPLICATION_ERROR = 'APPLICATION_UPDATE/UPDATE_APPLICATION_ERROR';

export const CREATE_APPLICATION_REQUEST = 'APPLICATION/CREATE_APPLICATION_REQUEST';
export const CREATE_APPLICATION_SUCCESS = 'APPLICATION/CREATE_APPLICATION_SUCCESS';
export const CREATE_APPLICATION_ERROR = 'APPLICATION/CREATE_APPLICATION_ERROR';

export const CREATE_TEMPLATE_REQUEST = 'APPLICATION/CREATE_TEMPLATE_REQUEST';
export const CREATE_TEMPLATE_SUCCESS = 'APPLICATION/CREATE_TEMPLATE_SUCCESS';
export const CREATE_TEMPLATE_ERROR = 'APPLICATION/CREATE_TEMPLATE_ERROR';

export const LIST_TEMPLATE_REQUEST = 'APPLICATION/LIST_TEMPLATE_REQUEST';
export const LIST_TEMPLATE_SUCCESS = 'APPLICATION/LIST_TEMPLATE_SUCCESS';
export const LIST_TEMPLATE_ERROR = 'APPLICATION/LIST_TEMPLATE_ERROR';


export const ADD_APPLICATION_FROM_TEMPLATE_REQUEST = 'APPLICATION/ADD_APPLICATION_FROM_TEMPLATE_REQUEST';
export const ADD_APPLICATION_FROM_TEMPLATE_SUCCESS = 'APPLICATION/ADD_APPLICATION_FROM_TEMPLATE_SUCCESS';
export const ADD_APPLICATION_FROM_TEMPLATE_ERROR = 'APPLICATION/ADD_APPLICATION_FROM_TEMPLATE_ERROR';

export const DELETE_TEMPLATE_REQUEST = 'APPLICATION/DELETE_TEMPLATE_REQUEST';
export const DELETE_TEMPLATE_SUCCESS = 'APPLICATION/DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_ERROR = 'APPLICATION/DELETE_TEMPLATE_ERROR';

export const IMPORT_APPLICATION_REQUEST = 'APPLICATION/IMPORT_APPLICATION_REQUEST';
export const IMPORT_APPLICATION_SUCCESS = 'APPLICATION/IMPORT_APPLICATION_SUCCESS';
export const IMPORT_APPLICATION_ERROR = 'APPLICATION/IMPORT_APPLICATION_ERROR';

export const ADD_FLOW_DATA = 'APPLICATION_ADD/ADD_FLOW_DATA';

export const LIST_COMMITS_REQUEST = 'LIST_COMMITS_REQUEST';
export const LIST_COMMITS_SUCCESS = 'LIST_COMMITS_SUCCESS';
export const LIST_COMMITS_ERROR = 'LIST_COMMITS_REQUEST';

export const NODE_RED_MODULES_REQUEST = 'NODE_RED_MODULES_REQUEST';
export const NODE_RED_MODULES_SUCCESS = 'NODE_RED_MODULES_SUCCESS';
export const NODE_RED_MODULES_ERROR = 'NODE_RED_MODULES_REQUEST';

export const NODE_RED_MODULE_REQUEST = 'NODE_RED_MODULE_REQUEST';
export const NODE_RED_MODULE_SUCCESS = 'NODE_RED_MODULE_SUCCESS';
export const NODE_RED_MODULE_ERROR = 'NODE_RED_MODULE_REQUEST';

export const SYNC_NODE_REQUEST = 'SYNC_NODE_REQUEST';
export const SYNC_NODE_SUCCESS = 'SYNC_NODE_SUCCESS';
export const SYNC_NODE_ERROR = 'SYNC_NODE_REQUEST';

export const GET_DOCKER_IMAGES_REQUEST = 'GET_DOCKER_IMAGES_REQUEST';
export const GET_DOCKER_IMAGES_SUCCESS = 'GET_DOCKER_IMAGES_SUCCESS';
export const GET_DOCKER_IMAGES_ERROR = 'GET_DOCKER_IMAGES_REQUEST';

export const CREATE_PUBLIC_IMAGE_DOCKER_REQUEST = 'CREATE_PUBLIC_IMAGE_DOCKER_REQUEST';
export const CREATE_PUBLIC_IMAGE_DOCKER_SUCCESS = 'CREATE_PUBLIC_IMAGE_DOCKER_SUCCESS';
export const CREATE_PUBLIC_IMAGE_DOCKER_ERROR = 'CREATE_PUBLIC_IMAGE_DOCKER_REQUEST';

export const CREATE_PRIVATE_IMAGE_DOCKER_REQUEST = 'CREATE_PRIVATE_IMAGE_DOCKER_REQUEST';
export const CREATE_PRIVATE_IMAGE_DOCKER_SUCCESS = 'CREATE_PRIVATE_IMAGE_DOCKER_SUCCESS';
export const CREATE_PRIVATE_IMAGE_DOCKER_ERROR = 'CREATE_PRIVATE_IMAGE_DOCKER_REQUEST';

export const PUSH_CHANGES_REQUEST = 'PUSH_CHANGES_REQUEST';
export const PUSH_CHANGES_SUCCESS = 'PUSH_CHANGES_SUCCESS';
export const PUSH_CHANGES_ERROR = 'PUSH_CHANGES_ERROR';

export const GET_AWS_ACCESS_KEY_REQUEST = 'GET_AWS_ACCESS_KEY_REQUEST';
export const GET_AWS_ACCESS_KEY_SUCCESS = 'GET_AWS_ACCESS_KEY_SUCCESS';
export const GET_AWS_ACCESS_KEY_ERROR = 'GET_AWS_ACCESS_KEY_REQUEST';

export const INSTALL_DEPENDENCIES_REQUEST = 'INSTALL_DEPENDENCIES_REQUEST';
export const INSTALL_DEPENDENCIES_SUCCESS = 'INSTALL_DEPENDENCIES_SUCCESS';
export const INSTALL_DEPENDENCIES_ERROR = 'INSTALL_DEPENDENCIES_REQUEST';

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_REQUEST';

export const IMPORT_FILE_APPLICATION_REQUEST = 'IMPORT_FILE_APPLICATION_REQUEST';
export const IMPORT_FILE_APPLICATION_SUCCESS = 'IMPORT_FILE_APPLICATION_SUCCESS';
export const IMPORT_FILE_APPLICATION_ERROR = 'IMPORT_FILE_APPLICATION_REQUEST';

export const CLEAR_FILE = 'CLEAR_FILE';

export const REMOVE_UPLOAD_MODULE_FILE = 'REMOVE_UPLOAD_MODULE_FILE';

export const UPGRADE_PUBLIC_MODULE = 'UPGRADE_PUBLIC_MODULE';

export const ADD_VIDEO_REQUEST = 'APPLICATION/ADD_VIDEO_REQUEST';
export const ADD_VIDEO_SUCCESS = 'APPLICATION/ADD_VIDEO_SUCCESS';
export const ADD_VIDEO_ERROR = 'APPLICATION/ADD_VIDEO_ERROR';

export const ADD_VIDEO_FILE_REQUEST = 'APPLICATION/ADD_VIDEO_FILE_REQUEST';
export const ADD_VIDEO_FILE_SUCCESS = 'APPLICATION/ADD_VIDEO_FILE_SUCCESS';
export const ADD_VIDEO_FILE_ERROR = 'APPLICATION/ADD_VIDEO_FILE_ERROR';

export const LIST_VIDEOS_REQUEST = 'LIST_VIDEOS_REQUEST';
export const LIST_VIDEOS_SUCCESS = 'LIST_VIDEOS_SUCCESS';
export const LIST_VIDOES_ERROR = 'LIST_VIDEOS_REQUEST';

export const LIST_VIDEO_REQUEST = 'LIST_VIDEO_REQUEST';
export const LIST_VIDEO_SUCCESS = 'LIST_VIDEO_SUCCESS';
export const LIST_VIDOE_ERROR = 'LIST_VIDEO_REQUEST';

export const UPDATE_VIDEO_REQUEST = 'UPDATE_VIDEO_REQUEST';
export const UPDATE_VIDEO_SUCCESS = 'UPDATE_VIDEO_SUCCESS';
export const UPDATE_VIDOE_ERROR = 'UPDATE_VIDEO_REQUEST';

export const ADD_MODEL_FILE_REQUEST = 'ADD_MODEL_FILE_REQUEST';
export const ADD_MODEL_FILE_SUCCESS = 'ADD_MODEL_FILE_SUCCESS';

export const LIST_MODEL_REQUEST = 'LIST_MODEL_REQUEST';
export const LIST_MODEL_SUCCESS = 'LIST_MODEL_SUCCESS';

export const LIST_ENVIRONMENT_REQUEST = 'LIST_ENVIRONMENT_REQUEST';
export const LIST_ENVIRONMENT_SUCCESS = 'LIST_ENVIRONMENT_SUCCESS';

export const LIST_LIBRARY_ENVIRONMENTS_REQUEST = 'LIBRARY/LIST_ENVIRONMENTS_REQUEST';
export const LIST_LIBRARY_ENVIRONMENTS_SUCCESS = 'LIBRARY/LIST_ENVIRONMENTS_SUCCESS';
export const LIST_LIBRARY_ENVIRONMENTS_ERROR = 'LIBRARY/LIST_ENVIRONMENTS_ERROR';

export const LIST_LIBRARY_MODEL_TRAINING_REQUEST = 'LIBRARY/LIST_MODEL_TRAINING_REQUEST';
export const LIST_LIBRARY_MODEL_TRAINING_SUCCESS = 'LIBRARY/LIST_MODEL_TRAINING_SUCCESS';
export const LIST_LIBRARY_MODEL_TRAINING_ERROR = 'LIBRARY/LIST_MODEL_TRAINING_ERROR';

export const LIST_LIBRARY_JOB_TRAINING_REQUEST = 'LIBRARY/LIST_JOB_TRAINING_REQUEST';
export const LIST_LIBRARY_JOB_TRAINING_SUCCESS = 'LIBRARY/LIST_JOB_TRAINING_SUCCESS';
export const LIST_LIBRARY_JOB_TRAINING_ERROR = 'LIBRARY/LIST_JOB_TRAINING_ERROR';

export const LIST_LIBRARY_IMAGE_ANNOTATION_REQUEST = 'LIBRARY/LIST_IMAGE_ANNOTATION_REQUEST';
export const LIST_LIBRARY_IMAGE_ANNOTATION_SUCCESS = 'LIBRARY/LIST_IMAGE_ANNOTATION_SUCCESS';
export const LIST_LIBRARY_IMAGE_ANNOTATION_ERROR = 'LIBRARY/LIST_IMAGE_ANNOTATION_ERROR';

export const LIST_DATASET_REQUEST = 'LIBRARY/LIST_DATASET_REQUEST';
export const LIST_DATASET_SUCCESS = 'LIBRARY/LIST_DATASET_SUCCESS';
export const LIST_DATASET_ERROR = 'LIBRARY/LIST_DATASET_ERROR';

export const DETAIL_DATASET_REQUEST = 'LIBRARY/DETAIL_DATASET_REQUEST';
export const DETAIL_DATASET_SUCCESS = 'LIBRARY/DETAIL_DATASET_SUCCESS';
export const DETAIL_DATASET_ERROR = 'LIBRARY/DETAIL_DATASET_ERROR';

export const UPLOAD_FILES_REQUEST = 'UPLOAD_FILES_REQUEST';
export const UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS';
export const UPLOAD_FILES_ERROR = 'UPLOAD_FILES_REQUEST';

export const DETAILS_LIBRARY_JOB_TRAINING_REQUEST = 'LIBRARY/DETAILS_JOB_TRAINING_REQUEST';
export const DETAILS_LIBRARY_JOB_TRAINING_SUCCESS = 'LIBRARY/DETAILS_JOB_TRAINING_SUCCESS';
export const DETAILS_LIBRARY_JOB_TRAINING_ERROR = 'LIBRARY/DETAILS_JOB_TRAINING_ERROR';

export const LIST_LIBRARY_ANNOTATION_TASK_REQUEST = 'LIBRARY/LIST_LIBRARY_ANNOTATION_TASK_REQUEST';
export const LIST_LIBRARY_ANNOTATION_TASK_SUCCESS = 'LIBRARY/LIST_LIBRARY_ANNOTATION_TASK_SUCCESS';
export const LIST_LIBRARY_ANNOTATION_TASK_ERROR = 'LIBRARY/LIST_LIBRARY_ANNOTATION_TASK_ERROR';

export const LIST_LIBRARY_ANNOTATION_JOB_REQUEST = 'LIBRARY/LIST_LIBRARY_ANNOTATION_JOB_REQUEST';
export const LIST_LIBRARY_ANNOTATION_JOB_SUCCESS = 'LIBRARY/LIST_LIBRARY_ANNOTATION_JOB_SUCCESS';
export const LIST_LIBRARY_ANNOTATION_JOB_ERROR = 'LIBRARY/LIST_LIBRARY_ANNOTATION_JOB_ERROR';


export const DETAILS_LIBRARY_ANOTATION_TASK_REQUEST = 'LIBRARY/DETAILS_ANOTATION_TASK_REQUEST';
export const DETAILS_LIBRARY_ANOTATION_TASK_SUCCESS = 'LIBRARY/DETAILS_ANOTATION_TASK_SUCCESS';
export const DETAILS_LIBRARY_ANOTATION_TASK_ERROR = 'LIBRARY/DETAILS_ANOTATION_TASK_ERROR';

export const DATASET_CLASS = [
    { label: 'Any', value: 'any', id: 'any' },
    { label: 'Rectangle', value: 'rectangle', id: 'rectangle' },
    { label: 'Polygon', value: 'polygon', id: 'polygon' },
    { label: 'Polyline', value: 'polyline', id: 'polyline' },
    { label: 'Points', value: 'points' , id: 'points'},
    { label: 'Ellipse', value: 'ellipse', id: 'ellipse'},
    { label: 'Cuboid', value: 'cuboid', id: 'cuboid' },
    { label: 'Mask', value: 'mask', id: 'mask'},
    { label: 'Tag', value: 'tag', id: 'tag' }
]

export const FORMAT = [
    {
      label: 'COCO 1.0', 
      value: 'COCO 1.0'
    },
    {
      label: 'COCO Keypoints 1.0', 
      value: 'COCO Keypoints 1.0'
    },
    {
      label: 'CVAT 1.1', 
      value: 'CVAT 1.1'
    },
    {
      label: 'Datumaro 1.0', 
      value: 'Datumaro 1.0'
    },
    {
      label: 'Datumaro 3D 1.0', 
      value: 'Datumaro 3D 1.0'
    },
    {
      label: 'LabelMe 3.0', 
      value: 'LabelMe 3.0'
    },
    {
      label: 'Segmentation mask 1.1', 
      value: 'Segmentation mask 1.1'
    },
    {
      label: 'MOT 1.1', 
      value: 'MOT 1.1'
    },
    {
      label: 'MOTS PNG 1.0', 
      value: 'MOTS PNG 1.0'
    },
    {
      label: 'PASCAL VOC 1.1', 
      value: 'PASCAL VOC 1.1'
    },
    {
      label: 'TFRecord 1.0', 
      value: 'TFRecord 1.0'
    },
    {
      label: 'YOLO 1.1', 
      value: 'YOLO 1.1'
    },
    {
      label: 'ImageNet 1.0', 
      value: 'ImageNet 1.0'
    },
    {
      label: 'CamVid 1.0', 
      value: 'CamVid 1.0'
    },
    {
      label: 'WiderFace 1.0', 
      value: 'WiderFace 1.0'
    },
    {
      label: 'VGGFace2 1.0', 
      value: 'VGGFace2 1.0'
    },
    {
      label: 'Market-1501 1.0', 
      value: 'Market-1501 1.0'
    },
    {
      label: 'ICDAR Recognition 1.0', 
      value: 'ICDAR Recognition 1.0'
    },
    {
      label: 'ICDAR Localization 1.0', 
      value: 'ICDAR Localization 1.0'
    },
    {
      label: 'ICDAR Segmentation 1.0', 
      value: 'ICDAR Segmentation 1.0'
    },
    {
      label: 'Kitti Raw Format 1.0', 
      value: 'Kitti Raw Format 1.0'
    },
    {
      label: 'Sly Point Cloud Format 1.0', 
      value: 'Sly Point Cloud Format 1.0'
    },
    {
      label: 'KITTI 1.0', 
      value: 'KITTI 1.0'
    },
    {
      label: 'LFW 1.0', 
      value: 'LFW 1.0'
    },
    {
      label: 'Cityscapes 1.0', 
      value: 'Cityscapes 1.0'
    },
    {
      label: 'Open Images V6 1.0', 
      value: 'Open Images V6 1.0'
    }
  ]
