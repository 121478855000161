import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from 'react-intl'
import NumberFormat from 'react-number-format';

import messages from 'utils/messages'
import Card from 'components/Detail/Card'

class Payment extends Component {
  renderContent = () => {
    const { billing } = this.props

    return (
      <>
        <div className="grid-col grid-col_5">
          <div className="overview__label">
            Account Credit
          </div>
          <div className="overview__value text-capitalize">
            <NumberFormat
              value={(billing.balance && (billing.balance).toFixed(2)) || '0.00'}
              displayType={'text'}
              thousandSeparator
              renderText={value => (
                <div>
                  USD {value}</div>
              )}
            />
          </div>
        </div>
      </>
    )
  }

  render() {
    return (
      <Card
        color={this.props.color}
        title={<FormattedMessage {...messages.creditBalance}/>}
        isLoading={this.props.isLoading}
        subTitle={'Balance will decrease the amount due on the next invoices.'}
        className="overview"
        content={this.renderContent()}
      />
    )
  }
}

Payment.propTypes = {
  user: PropTypes.object
}

export default Payment
