import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';

import { makeSelectLoading } from 'containers/App/selectors';
import LoadingIndicator from 'components/LoadingIndicator';

export function Loader(props) {
  const { loading } = props;

  return loading && <LoadingIndicator />;
}

Loader.propTypes = {
  loading: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
});

export default connect(
  mapStateToProps,
  null,
)(Loader);
