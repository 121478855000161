import React, { Component } from "react";
import PropTypes from "prop-types";
import OutsideClickHandler from "react-outside-click-handler";
import { FormattedMessage } from "react-intl";

import messages from "utils/messages";

class Modal extends Component {
  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  escFunction = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.keyCode === 27) {
      this.props.onClose();
    }
  };

  render() {
    const { closeBtnLabel } = this.props;

    return (
      <>
        <div className="overlay overlay_visible" />
        <div className="modal modal_visible">
          <OutsideClickHandler onOutsideClick={this.props.onClose}>
          <div className="modal__frame2">
                {this.props.children}
                <button
                  type="button"
                  className="modal__close"
                  onClick={this.props.onClose}
                >
                  <h3>
                    {closeBtnLabel || <FormattedMessage {...messages.skip} />}
                  </h3>
                </button>
            </div>
          </OutsideClickHandler>
        </div>
      </>
    );
  }
}

Modal.propTypes = {
  body: PropTypes.node,
  onClose: PropTypes.func,
};

export default Modal;
