import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'components/Button';

export const NotFound = ({
  title,
  description,
  buttonText,
  logo,
  parentClass,
  onAction,
  color,
  secondaryText,
}) => (
  <main className={`not-found__wrapper ${parentClass}`}>
    <div className="not-found__circle">{logo}</div>
    <div>
      <div className="not-found__text">{title}</div>
      <p className="not-found__description">{description}</p>
    </div>
    {buttonText && onAction
      && (
        <div className="not-found__button">
          <Button className={`button_${color} button button_xm`} onClick={onAction}>
            {buttonText}
          </Button>
        </div>
      )
    }
    {secondaryText && (
      <div className="not-found__link">
        <Link to="/">{secondaryText}</Link>
      </div>
    )}
  </main>
);

export default NotFound;
