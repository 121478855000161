import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { MoreVertical, X } from 'react-feather';
import { Draggable } from 'react-beautiful-dnd';

import Lock from 'images/common/lock';

class ColumnRow extends Component {
  renderIcon = () => {
    const { isLocked, sortKey, onRemove } = this.props;

    if (isLocked) {
      return <Lock />;
    }

    return (
      <>
        <X
          onClick={() => onRemove(sortKey)}
          className="manage-columns__remove"
        />
        <MoreVertical className="manage-columns__rearange" />
      </>
    );
  }

  render() {
    return (
      <Draggable
        key={this.props.sortKey}
        draggableId={this.props.sortKey}
        index={this.props.index}
      >
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{ ...provided.draggableProps.style }}
            className={classNames(
              'manage-columns__option',
              { 'manage-columns__option_locked': this.props.isLocked },
            )}
          >
            { this.props.label }
            {' '}
            { this.renderIcon() }
          </div>
        )}
      </Draggable>
    );
  }
}

ColumnRow.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  isLocked: PropTypes.bool,
  onRemove: PropTypes.func.isRequired,
  sortKey: PropTypes.string.isRequired,
  index: PropTypes.number,
};

export default ColumnRow;
