import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Card from 'components/Detail/Card';
import CustomInput from 'components/Input/CustomInput';
import Switch from 'components/Input/Switch';
import CardForm from 'containers/Billing/components/CardForm';
import {
  CreditCardIcon,
  JcbIcon, MasterCardIcon, PaymentIcon,
  VisaIcon,
} from 'images/common';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import appMessages from 'utils/messages';
import messages from '../utils/messages';


const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

class PaymentCard extends Component {
  state = {
    openModal: false,
  };

  componentDidMount() {
    const { billing } = this.props;

    if (billing && billing.paymentMethod) {
      this.props.onChangeCard(true);
    }
  }

  onAddTags = (tags) => {
    this.setState({ tags });
  };

  openEditModal = () => {
    this.setState({ openModal: true });
  };

  closeEditModal = () => {
    this.setState({ openModal: false });
  };

  renderContent = () => {
    const {
      color,
      interval,
      billing,
      isUpdatePlan,
      onUpdateCreditToggle,
      updateCreditCard,
      isFreeTrial,
      isCreatePage,
      showCreditCard
    } = this.props;

    if (this.props.showCard) {
      const expiryData = billing
        && billing.paymentMethod
        && billing.paymentMethod.card
        && `${billing.paymentMethod.card.exp_month}/${
          billing.paymentMethod.card.exp_year
        }`;
      const lastCard = billing.paymentMethod
        && billing.paymentMethod.card
        && billing.paymentMethod.card.last4;
      const billingName = billing.paymentMethod
        && billing.paymentMethod.billing_details
        && billing.paymentMethod.billing_details.name;

      return (
        <div>
          <hr className="subscriptions__hr" />
          <div className="grid-row">
            <div className="grid-col">
              <div className="overview__label">
                <FormattedMessage {...appMessages.paymentMethod} />
              </div>
              <div className="overview__value">Credit Card</div>
            </div>
            <div className="grid-col">
              <div className="h4 overview__label">
                <div className="grid-row grid-row_aic">
                  <div className="grid-col grid-col_auto">
                    <CreditCardIcon />
                  </div>
                  <div className="grid-col">
                    <div className="overview__card_item">
                      •••• •••• ••••
                      {' '}
                      {lastCard}
                    </div>
                    <div className="overview__card_item c-label">
                      {billingName}
                    </div>
                    <div className="overview__card_item c-label">
                      <FormattedMessage {...appMessages.expires} />
                      {' '}
                      {expiryData}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-col">
              <div
                className="overview__action"
                onClick={() => this.props.onChangeCard(false)}
              >
                <FormattedMessage {...appMessages.change} />
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (isFreeTrial || isCreatePage) {
      return (
        <>
          <>
            {isUpdatePlan && (
              <div className="payment-selector-switch">
                <Switch
                  color={color}
                  name="updateCreditCard"
                  value={updateCreditCard}
                  text="Update credit card info"
                  onChange={onUpdateCreditToggle}
                />
              </div>
            )}
            {(!isUpdatePlan || updateCreditCard) && (
              <Elements stripe={stripePromise}>
                <CardForm
                  color={color}
                  theme={this.props.theme}
                  isLoading={this.props.isLoading}
                  interval={interval}
                  onClose={this.props.onClose}
                  onGetPaymentInfo={this.props.onGetPaymentInfo}
                />
              </Elements>
            )}
          </>
        </>
      );
    }
    return (
      <>
        <div className="payment-selector">
          <div className="radio radio_blocked radio_accent0 radio_billing">
            <input type="radio" className="radio__input" checked />
            <div className="radio__check" />
            <label className="radio__label payment_icons">
              <VisaIcon />
              <MasterCardIcon />
              <CreditCardIcon />
              <JcbIcon />
              {' '}
            </label>
          </div>
        </div>
        <>
          {isUpdatePlan && !showCreditCard && (
            <div className="payment-selector-switch">
              <Switch
                color={color}
                name="updateCreditCard"
                value={updateCreditCard}
                text="Update credit card info"
                onChange={onUpdateCreditToggle}
              />
            </div>
          )}
          {((!isUpdatePlan || updateCreditCard) || showCreditCard) && (
            <Elements stripe={stripePromise}>
              <CardForm
                color={color}
                theme={this.props.theme}
                isLoading={this.props.isLoading}
                interval={interval}
                onClose={this.props.onClose}
                onGetPaymentInfo={this.props.onGetPaymentInfo}
              />
            </Elements>
          )}
        </>
        <h3 className="payment-title">
          <FormattedMessage {...messages.billingInfo} />
        </h3>
        <div>
          <div className="grid-row">
            <div className="grid-col grid-col_6">
              <div className={`text-field text-field_${color} mb_30`}>
                <CustomInput
                  parentClass="text-field"
                  value={this.props.firstName}
                  onChange={e => this.props.onChangePayment('firstName', e.target.value)
                  }
                  label={<FormattedMessage {...appMessages.firstName} />}
                  isInvalid={!this.props.firstName}
                  hintText={
                    this.props.firstName ? (
                      ''
                    ) : (
                      <FormattedMessage {...appMessages.required} />
                    )
                  }
                />
              </div>
            </div>

            <div className="grid-col grid-col_6">
              <div className={`text-field text-field_${color} mb_30`}>
                <CustomInput
                  parentClass="text-field"
                  value={this.props.lastName}
                  onChange={e => this.props.onChangePayment('lastName', e.target.value)
                  }
                  label={<FormattedMessage {...appMessages.lastName} />}
                  isInvalid={!this.props.lastName}
                  hintText={
                    this.props.lastName ? (
                      ''
                    ) : (
                      <FormattedMessage {...appMessages.required} />
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="grid-row">
            <div className="grid-col">
              <CustomInput
                parentClass="text-field mb_30"
                value={this.props.address1}
                onChange={e => this.props.onChangePayment('address1', e.target.value)
                }
                label={<FormattedMessage {...appMessages.address1} />}
              />
            </div>
          </div>
          <div className="grid-row">
            <div className="grid-col">
              <CustomInput
                value={this.props.address2}
                parentClass="text-field mb_30"
                onChange={e => this.props.onChangePayment('address2', e.target.value)
                }
                label={<FormattedMessage {...appMessages.address2} />}
              />
            </div>
          </div>
          <div className="grid-row">
            <div className="grid-col">
              <CustomInput
                parentClass="text-field mb_30"
                value={this.props.city}
                onChange={e => this.props.onChangePayment('city', e.target.value)
                }
                label={<FormattedMessage {...appMessages.city} />}
              />
            </div>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col grid-col_6">
            <div className={`text-field text-field_${color} mb_30`}>
              <CustomInput
                parentClass="text-field"
                value={this.props.postCode}
                onChange={e => this.props.onChangePayment('postCode', e.target.value)
                }
                type="number"
                label={<FormattedMessage {...appMessages.zipCode} />}
              />
            </div>
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col grid-col_6">
            <div className={`text-field text-field_${color} mb_30`}>
              <CustomInput
                parentClass="text-field"
                value={this.props.company}
                onChange={e => this.props.onChangePayment('company', e.target.value)
                }
                label={<FormattedMessage {...appMessages.companyOptional} />}
              />
            </div>
          </div>

          <div className="grid-col grid-col_6">
            <div className={`text-field text-field_${color} mb_30`}>
              <CustomInput
                value={this.props.vat}
                onChange={e => this.props.onChangePayment('vat', e.target.value)
                }
                parentClass="text-field"
                label={<FormattedMessage {...appMessages.vatOptional} />}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  render() {
    return (
      <Card
        noGrid
        action={this.openEditModal}
        color={this.props.color}
        title={<FormattedMessage {...appMessages.paymentDetails} />}
        subTitle={<FormattedMessage {...appMessages.ifYouDecidePlan} />}
        className="estimated-revenue estimated-revenue-payment"
        customText={(
          <div className="accent0 wt-500">
            <PaymentIcon />
            Secured by Stripe
          </div>
        )}
        content={this.renderContent()}
      />
    );
  }
}

PaymentCard.propTypes = {};

export default PaymentCard;
