import { jsonToGraphQLQuery } from 'json-to-graphql-query';

import axiosObj from './config';
import axios from 'axios';
import { metrics } from '@sentry/browser';

const SOLUTION_API_URL = `${process.env.API_URL}/solution/graphql`;
const MODEL_API_URL = `${process.env.API_URL}/model/graphql`;
// const MODEL_API_URL = `http://localhost:3002/graphql`;

const APPLICATION_DETAILS_RETURN_VALUES = {
  id: true,
  name: true,
  description: true,
  tags: true,
  type: true,
  nrProjectName: true,
  version: true,
  versions: {
    version: true,
    commitId: true,
    createdAt: true,
    message: true,
    createdBy: {
      id: true,
      name: true,
    },
  },
  dependencies: true,
  profiles: {
    id: true,
    name: true,
    deviceCount: true,
    applicationVersion: true,
    createdBy: {
      id: true,
      name: true,
    },
    updatedAt: true,
    createdAt: true,
  },
  createdBy: {
    id: true,
    name: true,
  },
  updatedBy: {
    id: true,
    name: true,
  },
  createdAt: true,
  updatedAt: true,
};

const MODEL_DETAILS_RETURN_VALUES = {
  id: true,
  name: true,
  description: true,
  tags: true,
  modelType: true,
  categories: true,
  framework: true,
  deviceTypes: true,
  dataset: true,
  license: true,
  modelSize: true,
  imageSize: true,
  labels: true,
  version: true,
  modelArchitecture: true,
  associatedNodes: {
    node: true,
    module: true,
  },
  createdBy: {
    id: true,
    name: true,
  },
  updatedBy: {
    id: true,
    name: true,
  },
  source: true,
  url: true,
  createdAt: true,
  updatedAt: true,
};

const MODEL_LIST_RETURN_VALUES = {
  id: true,
  name: true,
  description: true,
  tags: true,
  source: true,
  modelType: true,
  categories: true,
  framework: true,
  deviceTypes: true,
  dataset: true,
  url: true,
  createdAt: true,
  updatedAt: true,
  modelSize: true,
  imageSize: true,
  labels: true,
  demoData: true
};

const RELATED_MODEL_LIST_RETURN_VALUES = {
  id: true,
  name: true,
  description: true,
  tags: true,
  source: true,
  modelType: true,
  categories: true,
  framework: true,
  deviceTypes: true,
  dataset: true,
  url: true,
  createdAt: true,
  updatedAt: true,
  modelSize: true,
  imageSize: true,
  labels: true,
};

const CV_LIST_RETURN_VALUES = {
  id: true,
  name: true,
  tags: true,
  template: true,
  nodePool: true,
  status: true,
  startedAt: true,
  createdAt: true,
  updatedAt: true,
  isDefault: true
};

const CV_JOB_RETURN_VALUES = {
  _id: true,
  name: true,
  status: true,
  dataset: {
    _id: true,
    name: true,
    version: true
  },
  progress: true,
  createdAt: true,
  frameCount: true,
  assignedTo: {
    _id: true,
    firstName: true,
    lastName: true
  },
};


const LABEL = {
  name: true,
  type: true,
  attributes: true,
}

const ANNOTATION_TASK = {
  id: true,
  workspaceId: true,
  datasetId: true,
  createdBy: true,
  batchSize: true,
  description: true,
  cvatJobs: true,
  recentJob: true,
  name: true,
  assignedTo: true,
  labels: LABEL,
  createdAt: true,
  updatedAt: true,
  status: true,
  demoData: true
};

const ANNOTATION_VALUES = {
  annotationLimit: true,
  envs: [CV_LIST_RETURN_VALUES]
}

const TRAINING_VALUES = {
  trainingLimit: true,
  envs: [CV_LIST_RETURN_VALUES]
}

const ONLY_ID_RETURN = {
  id: true,
};

const APPLICATION_RETURN_VALUES = {
  id: true,
  name: true,
  type: true,
  tags: true,
  createdBy: {
    id: true,
    name: true,
  },
  versions: {
    version: true,
    commitId: true,
    message: true,
    createdAt: true,
    createdBy: {
      id: true,
      name: true,
    },
  },
  version: true,
  createdAt: true,
  updatedAt: true,
  nrProjectName: true,
  hasProfile: true,
  demoData: true
};

const PROFILE_FLOW_RETURN_VALUES = {
  id: true,
  name: true,
  type: true,
  data: true,
  version: true,
  modules: {
    id: true,
    name: true,
    version: true,
  },
};

const VIDEO_RETURN_VALUES = {
  id: true,
  name: true,
  description: true,
  tags: true,
  url: true,
  isExternalFileUrl: true,
  type: true,
  size: true,
  workspaceId: true,
  isRecorded: true,
  createdBy: {
    id: true,
    name: true,
  },
  updatedBy: {
    id: true,
    name: true,
  },
  createdAt: true,
  updatedAt: true,
  demoData: true,
};

const JOB_TRAINING_CHECK = {
  _id: true,
  name: true,
  status: true,
  createdAt: true,
  isRunning: true,
  estimatedCompletion: true,
};

export const DATASET_ANNOTATION_CHECK = {
  allImagesAnnotated: true,
  message: true
};

export const FLOW_VERSION_RETURN_VALUES = {
  id: true,
  createdBy: {
    id: true,
    name: true,
  },
  name: true,
  updatedAt: true,
  description: true,
  version: true,
  updatedBy: {
    id: true,
    name: true,
  },
};

export const MODULE_VERSION_RETURN_VALUES = {
  id: true,
  name: true,
  createdAt: true,
  description: true,
  updatedAt: true,
  updatedBy: {
    id: true,
    name: true,
  },
  createdBy: {
    id: true,
    name: true,
  },
  version: true,
};

const MODULE_RETURN_VALUES = {
  id: true,
  name: true,
  description: true,
  tags: true,
  type: true,
  moduleID: true,
  nodes: true,
  installStatus: true,
  createdBy: {
    id: true,
    name: true,
  },
  updatedBy: {
    id: true,
    name: true,
  },
  createdAt: true,
  updatedAt: true,
  version: true,
  dependents: {
    id: true,
    createdBy: {
      id: true,
      name: true,
    },
    type: true,
    createdAt: true,
    name: true,
    updatedAt: true,
    description: true,
    version: true,
    updatedBy: {
      id: true,
      name: true,
    },
    tags: true,
  },
};

const MODULES_RETURN_VALUES = {
  id: true,
  name: true,
  tags: true,
  nodes: true,
  type: true,
  moduleID: true,
  installStatus: true,
  description: true,
  createdAt: true,
  updatedAt: true,
  version: true,
  hasDependents: true,
  demoData: true
};

const DOCKER_IMAGE_RETURN_VALUES = {
  id: true,
  nodeType: true,
  repositoryUri: true,
  username: true,
  password: true,
  customName: true,
  module: true,
  isPrivateECR: true,
  workspaceId: true,
};

const NODE_MODULE_RETURN_VALUE = {
  id: true,
  enabled: true,
  local: true,
  module: true,
  name: true,
  types: true,
  version: true,
};

const METADATA_RETURN_VALUE = {
  name: true,
  namespace: true,
  uid: true,
  creationTimestamp: true,
  labels: {
    workflows_argoproj_io_completed: true,
    workflows_argoproj_io_creator: true,
    workflows_argoproj_io_phase: true,
    workflows_argoproj_io_workflow_event_binding: true,
    workflows_argoproj_io_workflow_template: true
  },
  annotations: {
    workflows_argoproj_io_pod_name_format: true
  }
}

const STATUS_RETURN_VALUE = {
  phase: true,
  startedAt: true,
  finishedAt: true,
  estimatedDuration: true,
  progress: true,
  message: true,
}

const SPEC_RETURN_VALUE = {
  arguments: true
}

const MODEL_TRAINING_STATUS_RETURN_VALUE = {
  metadata: METADATA_RETURN_VALUE,
  spec: SPEC_RETURN_VALUE,
  status: STATUS_RETURN_VALUE
};

const JOB_TRAINING = {
  _id: true,
  name: true,
  mAP: true,
  duration: true,
  status: true,
  baseModel: {
    _id: true,
    name: true,
    modelArchitecture: true,
    modelType: true
  },
  dataset: {
    _id: true,
    name: true,
  },
  experiment: {
    _id: true,
    name: true,
  },
  createdBy: {
    _id: true,
    firstName: true,
    lastName: true,
  },
  createdAt: true,
  estimatedCompletion: true
};

const JOB_TRAINING_DETAILS = {
  id: true,
  name: true,
  duration: true,
  baseModel: {
    _id: true,
    name: true,
    modelArchitecture: true,
    modelType: true,
    framework: true,
    imageSize: true,
    license: true,
  },
  status: true,
  dataset: {
    _id: true,
    name: true,
    info: {
      sampleCount: true,
      imageUrls: true
    },
    version: true

  },
  experiment: {
    _id: true,
    name: true,
    description: true,
  },
  createdBy: {
    _id: true,
    firstName: true,
    lastName: true,
  },
  createdAt: true,
  metrics: {
    precision: true,
    recall: true,
    map0To5: true,
    map0To95: true,
    trainTotalLoss: true,
    epochs: true
  },
  model: {
    _id: true,
    name: true,
    trainedModel: true,
    version: true
  },
  pastJobs: {
    _id: true,
    name: true,
    status: true,
    createdAt: true
  },
  promoteStatus: true,
  promotedModelId: true,
  estimatedCompletion: true
};

const EXPERIMENTS = {
  id: true,
  name: true,
  demoData: true,
  description: true,
  tags: true,
  trainingJobs: true,
  recentTrainingJob: true,
  createdAt: true,
}

export function getLibraryApplications(params) {
  const query = {
    query: {
      applications: {
        __args: params,
        ...APPLICATION_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getJobTrainingList(args) {
  const query = {
    query: {
      trainingJobs: {
        __args: args,
        count: true,
        data: JOB_TRAINING,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getJobTrainingDetails(args) {
  const query = {
    query: {
      trainingJob: {
        __args: args,
        ...JOB_TRAINING_DETAILS,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getExperiments(args) {
  const query = {
    query: {
      experiments: {
        __args: args,
        count: true,
        data: EXPERIMENTS,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getLibraryApplicationsId() {
  const query = {
    query: {
      applications: {
        ...ONLY_ID_RETURN,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getFlow(id) {
  const query = {
    query: {
      flows: {
        __args: {
          id,
        },
        count: true,
        data: APPLICATION_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getProfileFlow(id) {
  const query = {
    query: {
      flows: {
        __args: {
          id,
        },
        count: true,
        data: PROFILE_FLOW_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getFlowVersion(data) {
  const query = {
    query: {
      flowHistories: {
        __args: data,
        count: true,
        data: FLOW_VERSION_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}


export function getLibraryModules(param) {
  const query = {
    query: {
      modules: {
        __args: param,
        ...MODULES_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getLibraryModulesId() {
  const query = {
    query: {
      modules: {
        ...ONLY_ID_RETURN,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getNoderedModules(param) {
  const query = {
    query: {
      noderedModules: {
        __args: param,
        ...NODE_MODULE_RETURN_VALUE,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getNoderedModule(param) {
  const query = {
    query: {
      noderedModule: {
        __args: param,
        ...NODE_MODULE_RETURN_VALUE,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getModule(id) {
  const query = {
    query: {
      moduleDetail: {
        __args: {
          id,
        },
        ...MODULE_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getModuleVersion(data) {
  const query = {
    query: {
      moduleHistories: {
        __args: data,
        ...MODULE_VERSION_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function createFlow(args) {
  const query = {
    mutation: {
      createFlow: {
        __args: args,
        ...APPLICATION_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function importVideo(args) {
  const query = {
    mutation: {
      importVideo: {
        __args: args,
        id: true,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function updateVideo(args) {
  const query = {
    mutation: {
      updateVideo: {
        __args: args,
        id: true,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function deleteVideo(args) {
  const query = {
    mutation: {
      deleteVideo: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function updateFlow(args) {
  const query = {
    mutation: {
      updateFlow: {
        __args: args,
        ...APPLICATION_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function syncNodes(args) {
  const query = {
    mutation: {
      syncNodes: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getDockerImage(args) {
  const query = {
    mutation: {
      dockerImage: {
        __args: args,
        ...DOCKER_IMAGE_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getDockerImages(args) {
  const query = {
    query: {
      dockerImages: {
        __args: args,
        ...DOCKER_IMAGE_RETURN_VALUES,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function createPublicDockerImage(args) {
  const query = {
    mutation: {
      createPublicDockerImage: {
        __args: args,
        ...DOCKER_IMAGE_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function createPrivateDockerImage(args) {
  const query = {
    mutation: {
      createPrivateDockerImage: {
        __args: args,
        ...DOCKER_IMAGE_RETURN_VALUES,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function updateDockerImage(args) {
  const query = {
    mutation: {
      updateDockerImage: {
        __args: args,
        ...DOCKER_IMAGE_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getAwsAccessKey(args) {
  const query = {
    query: {
      awsAccessKey: {
        __args: args,
        accessKeyId: true,
        secretAccessKey: true,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function createPublicModule(args) {
  const query = {
    mutation: {
      createPublicModule: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getApplication(args) {
  const query = {
    query: {
      application: {
        __args: args,
        ...APPLICATION_DETAILS_RETURN_VALUES,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getCommitList(args) {
  const query = {
    query: {
      applicationCommits: {
        __args: args,
        id: true,
        title: true,
        created_at: true,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function updateApplication(args) {
  const query = {
    mutation: {
      updateApplication: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function createApplication(args) {
  const query = {
    mutation: {
      createApplication: {
        __args: args,
        id: true,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function pushChanges(args) {
  const query = {
    mutation: {
      pushChanges: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function deleteModule(id) {
  const query = {
    mutation: {
      deleteModule: {
        __args: id,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function uploadFile(args) {
  const query = {
    mutation: {
      uploadFile: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getVideos(params) {
  const query = {
    query: {
      videos: {
        ...VIDEO_RETURN_VALUES,
        __args: params
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function videoDetail(args) {
  const query = {
    query: {
      video: {
        __args: args,
        ...VIDEO_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function setAppVersionAsLatest(args) {
  const query = {
    mutation: {
      setAppVersionAsLatest: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function deleteApplicationVersion(args) {
  const query = {
    mutation: {
      deleteApplicationVersion: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getLibraryModels(params) {
  const query = {
    query: {
      models: {
        __args: params,
        ...MODEL_LIST_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getLibraryModelId(params) {
  const query = {
    query: {
      model: {
        __args: params,
        ...MODEL_DETAILS_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function createModel(args) {
  const query = {
    mutation: {
      createModel: {
        __args: args,
        ...MODEL_LIST_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function deleteModel(args) {
  const query = {
    mutation: {
      deleteModel: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function assignNodesToModel(args) {
  const query = {
    mutation: {
      assignNodesToModel: {
        __args: args,
        ...MODEL_DETAILS_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function unassignNodesFromModel(args) {
  const query = {
    mutation: {
      unassignNodesFromModel: {
        __args: args,
        ...MODEL_DETAILS_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function uploadModelFile(param) {
  const query = {
    mutation: {
      uploadFile: {
        __args: {
          filename: param.file.path, limitSize: false, dirName: param.dirName || ""
        },
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {
      },
      query: gqlQuery,
    },
  });
}

export function updateModel(args) {
  const query = {
    mutation: {
      updateModel: {
        __args: args,
        ...MODEL_DETAILS_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function createCVEnv(args) {
  const query = {
    mutation: {
      createCVEnv: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getLibraryEnvironments() {
  const query = {
    query: {
      annotationEnvs: {
        ...ANNOTATION_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getModelTraining(args) {
  const query = {
    query: {
      trainingEnvs: {
        ...TRAINING_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function pauseCVEnv(args) {
  const query = {
    mutation: {
      pauseCVEnv: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function resumeCVEnv(args) {
  const query = {
    mutation: {
      resumeCVEnv: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function deleteCVEnv(args) {
  const query = {
    mutation: {
      deleteCVEnv: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function cvEnvURL(params) {
  const query = {
    query: {
      cvEnvURL: {
        __args: params,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}


export function getLibraryAllEnvironments() {
  const query = {
    query: {
      cvEnvs: {
        ...CV_LIST_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function copyApplication(args) {
  const query = {
    mutation: {
      copyApplication: {
        __args: args,
        id: true,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function createExperiment(args) {
  const query = {
    mutation: {
      createExperiment: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function deleteExperiment(args) {
  const query = {
    mutation: {
      deleteExperiment: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}


export function trainModel(args) {
  const query = {
    mutation: {
      trainModel: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export async function getVersion(module_name) {
  const result = await axios.get(`https://registry.npmjs.org/${module_name}`);

  return result;
}

const MODEL_FRAMEWORK = {
  key: true,
  name: true,
}

const METRIC_RETURN_VALUES = {
  step: true,
  value: true,
  timestamp: true,
  metric: true
}

const METRIC_DATA_VALUES = {
  step: true,
  value: true,
  timestamp: true,
}

const METRICS_RETURN_VALUES = {
  metric: true,
  min: METRIC_DATA_VALUES,
  max: METRIC_DATA_VALUES,
  last: METRIC_DATA_VALUES,
}

export function getModelFramework() {
  const query = {
    query: {
      modelFrameworks: {
        ...MODEL_FRAMEWORK,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SOLUTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function abortTrainingJob(args) {
  const query = {
    mutation: {
      abortTrainingJob: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function metricHistory(args) {
  const query = {
    query: {
      metricHistory: {
        __args: args,
        ...METRIC_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function metricsData(args) {
  const query = {
    query: {
      metrics: {
        __args: args,
        ...METRICS_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function validateDatasetSplit(args) {
  const query = {
    query: {
      validateDatasetSplit: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function validateDatasetAnnotation(args) {
  const query = {
    query: {
      validateDatasetAnnotation: {
        __args: args,
        ...DATASET_ANNOTATION_CHECK
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function promoteModel(args) {
  const query = {
    mutation: {
      promoteModel: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getAnnotationTask(args) {
  const query = {
    query: {
      cvatTasks: {
        __args: args,
        count: true,
        data: ANNOTATION_TASK
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function createCvatTask(args) {
  const query = {
    mutation: {
      createCvatTask: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getRelatedModels(params) {
  const query = {
    query: {
      relatedModels: {
        __args: params,
        ...RELATED_MODEL_LIST_RETURN_VALUES,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}
export function getJobs(params) {
  const query = {
    query: {
      cvatJobs: {
        __args: params,
        count: true,
        data: CV_JOB_RETURN_VALUES
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function cvatJobURL(params) {
  const query = {
    query: {
      cvatJobURL: {
        __args: params,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function updateCvatJob(args) {
  const query = {
    mutation: {
      updateCvatJob: {
        __args: args,
        ...CV_JOB_RETURN_VALUES
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function deleteCvatTask(args) {
  const query = {
    mutation: {
      deleteCvatTask: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function deleteTrainingJob(args) {
  const query = {
    mutation: {
      deleteTrainingJob: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function updateCVEnv(args) {
  const query = {
    mutation: {
      updateCVEnv: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function importAnnotation(args) {
  const query = {
    mutation: {
      importAnnotation: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function testModel(args) {
  const query = {
    mutation: {
      testModel: {
        __args: args,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });
  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function runningJob() {
  const query = {
    query: {
      runningJob: {
        ...JOB_TRAINING_CHECK
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: MODEL_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}
