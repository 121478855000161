import React, { Component } from 'react';
import { installDemoApp } from 'api/workspace';

import ProcessPage from 'components/ProcessPage';
import { defaultNotifier } from 'functions/notificationHandler';

import { preInstallableSolutions } from 'api/marketplace';
import PreApplicationSelection from '../Auth/Register/PreApplicationSelection';

class PreApplicationWrapper extends Component {
  state = {
    activeSelection: '',
    applications: [],
  };

  async componentDidMount() {
    const { data } = await preInstallableSolutions();

    if (data.errors) {
      return false;
    }

    this.setState({ applications: data.data.preInstallableSolutions });
  }

  onSubmitApplication = async () => {
    const { data } = await installDemoApp({ id: this.state.activeSelection });

    if (data.errors) {
      return defaultNotifier(data.errors);
    }

    defaultNotifier('Demo application is installing');
    this.props.getStartedTrackerRequest();

    this.props.onClose();
  }


  onSelectApp = (id) => {
    if (this.state.activeSelection === id) {
      return this.setState({ activeSelection: '' });
    }

    this.setState({ activeSelection: id });
  };

  render() {
    return (
      <ProcessPage onCancel={() => this.props.onClose()}>
        {() => (
          <>
            <div className="headline">
              <>
                <h1 className="headline__title center headline_title__register">
                  Get a head start!
                </h1>
                <h3 className="headline__subtitle">
                  Select a template below and we install to your Workspace.
                </h3>
              </>
            </div>
            <PreApplicationSelection
              hideSkip
              applications={this.state.applications}
              onSubmit={this.onSubmitApplication}
              activeSelection={this.state.activeSelection}
              onSelect={this.onSelectApp}
            />
          </>
        )}
      </ProcessPage>
    );
  }
}

export default PreApplicationWrapper;
