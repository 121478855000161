import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withRouter } from 'withRouter'
import { FormattedMessage } from 'react-intl';
import slugify from 'react-slugify';

import Card from 'components/Detail/Card';
import { getInitials } from 'utils/common';

import { routeMapping } from 'routes';
import appMessages from 'utils/messages';
import ManageBillingContacts from '../../edit/ManageBilling';

class ProfileInformation extends Component {
  state = {
    showEditModal: false,
  }

  redirectToUser = (id) => {
    const { workspaceName } = this.props;
    const wsPath = slugify(workspaceName);

    this.props.router.navigate(`/${wsPath}${routeMapping.SETTINGS_USER.path}/${id}`);
  }

  openEditModal = () => {
    this.setState({ showEditModal: true });
  }

  closeEditModal = () => {
    this.setState({ showEditModal: false });
  }

  renderContent = () => {
    const { showEditModal } = this.state
    const { color, workspaceName, billing: {billingContact} } = this.props

    return (
      <>
      <div className="flow-modules__data-table data-table">
        <table className="data-table__table">
          <tbody className="data-table__body">
            {billingContact && billingContact.name &&
            <tr className="data-table__row" onClick={() => this.redirectToUser(billingContact.id)}>
              <td className="data-table__td data-table__td_1">
                <div className={`checkbox checkbox_${color}`}>
                  <div className="checkbox__image">
                    {getInitials(billingContact.name)}
                  </div>
                </div>
              </td>
              <td className={`data-table__td ${color}`}>{billingContact.name}</td>
              <td className="data-table__td">{billingContact.email}</td>
            </tr>
            }
          </tbody>
        </table>
      </div>
      </>
    )
  }

  render() {
    const { color, workspaceName, billing: {billingContact}, isOpen } = this.props

    if (isOpen && billingContact) {
      return <ManageBillingContacts
        onClose={this.props.onClose}
        color={color}
        subDomain={this.props.subDomain}
        workspaceName={workspaceName}
        selectedId={billingContact.id}/>
    }

    return (
      <Card
        noGrid
        isLoading={this.props.isLoading}
        color={this.props.color}
        title={<FormattedMessage {...appMessages.billingContact} />}
        subTitle={<FormattedMessage {...appMessages.contactInInvoice} />}
        className="flow-modules"
        customText={<FormattedMessage {...appMessages.manage} />}
        action={this.props.onOpen}
        content={this.renderContent()}
      />
    );
  }
}

ProfileInformation.propTypes = {
  user: PropTypes.object,
};

export default withRouter(ProfileInformation);
