import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Scrollbars } from 'react-custom-scrollbars';
import { uniqBy } from 'lodash';
import classNames from 'classnames';

import messages from 'utils/messages';
import { truncate, snakeCaseToTitleCase } from 'utils/common';
import { FILTER_TYPE } from '../constants';

const DEFAULT_SHORTENED_TEXT_COUNT = 35;

class Checkbox extends React.Component {
  state = {
    filterValue: [],
    filterId: '',
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onEnter, false);
  }

  onEnter = (event) => {
    if (event.key === 'Enter') {
      // on Esc button click it should close popup
      this.onApply();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEnter, false);
  }

  onApply = () => {
    const { filterValue, filterId } = this.state;
    const { name, onFilterApply } = this.props;

    onFilterApply(name, filterValue, FILTER_TYPE.CHECKBOX, filterId);
  }

  onChange = (value) => {
    const selectedValue = this.state.filterValue;
    const selectedId = this.state.filterId;
    const indexValue = this.state.filterValue.indexOf(value.label);

    if (indexValue > -1) {
      selectedValue.splice(indexValue, 1);
      selectedId.splice(indexValue, 1);

      return this.setState({
        filterValue: selectedValue,
        filterId: selectedId,
      });
    }

    return this.setState({
      filterValue: [...this.state.filterValue, value.label],
      filterId: [...this.state.filterId, value.name],

    });
  }

  renderView=props => (
    <div {...props} style={{ ...props.style, overflowX: 'hidden' }} />
  )

  render() {
    const { title, columns, customClass } = this.props;

    const checkBoxInputClass = `checkbox checkbox_blocked checkbox_${customClass}`;

    const btnClass = classNames('filters-bar__apply', {
      [customClass]: customClass,
      'filters-bar__disabled': !this.state.filterValue.length,
    });

    let newColumn = columns.map(incident => incident.label).flat()

    newColumn = newColumn
    .filter(item => item)
    .map(item => {
      if(item) {
        return {
          ...item,
          group: `${item.firstName} ${item.lastName}`,
          label: `${item.firstName} ${item.lastName}`,
          id: item.id
        }
      }

    })

    return (
      <>
        <div className="filters-bar__heading">{title}</div>
        <Scrollbars
          autoHeightMin={120}
          autoHeight
          autoHide
          autoHideDuration={1}
          autoHeightMax={columns.length > 0 ? 250 : 0}
          renderView={props => <div {...props} className="view" />}
        >
          {uniqBy(newColumn, 'label')
            .filter(column => column.label)
            .map(column => (
              <div
                className={checkBoxInputClass}
                key={`${column.label}-${title}`}
              >
                <input type="checkbox" className="checkbox__input" onClick={() => this.onChange(column)} />
                <div className="checkbox__check" />
                <label className="checkbox__label checkbox-filter__label">{truncate(snakeCaseToTitleCase(column.label), DEFAULT_SHORTENED_TEXT_COUNT)}</label>
              </div>
            ))}
        </Scrollbars>
        <button type="button" className={btnClass} onClick={this.onApply}>
          <FormattedMessage {...messages.apply} />
        </button>
      </>
    );
  }
}

Checkbox.propTypes = {
  name: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  columns: PropTypes.array,
  customClass: PropTypes.string,
  onFilterApply: PropTypes.func,
};

export default Checkbox;
