import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import {withRouter } from 'withRouter'
import { createStructuredSelector } from 'reselect';
import getSymbolFromCurrency from 'currency-symbol-map';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import NumberFormat from 'react-number-format';

import Viso from 'images/billing/viso.jpg';
import messages from 'utils/messages';
import {
  dateFormatter2,
  getMonthlyMRRStripe,
  getYearlyMRRStripe,
  getYearlyMRRPerSeatStripe,
  getMonthlyMRRPerSeatStripe,
  isFreePlan,
} from 'utils/common';
import { defaultNotifier } from 'functions/notificationHandler';
import ButtonInfo from 'components/ButtonInfo';

import {getPlan} from 'api/billing';
import { changePlan } from 'api/plans';
import { makeSelectFetchWorkspace } from 'containers/Settings/Workspace/selectors';
import {
  changeSubscriptionRequest,
  addPlanDetailInSubscription
} from 'containers/Billing/actions';

class SubscriptionCard extends React.Component {
  state = {
    showPlanPage: false,
    planId: null,
    subscription: {},
    endpoint: 0,
    price: 0,
    loading: true,
    solution: null,
    selectedSolution: null,
    showConfirmOrderPage: false
  };

  componentDidMount() {
    const {subscriptionDetails, interval, plans} = this.props;
    const isApplication = subscriptionDetails.type === 'application';

    const isFree = subscriptionDetails &&
    (subscriptionDetails[interval === 'year' ? 'yearlyPrice' : 'monthlyPrice'])
    && !isApplication
     && isFreePlan(subscriptionDetails, interval)

    const selectedYearlyPlan = subscriptionDetails.yearlyPrice;
    const selectedMonthlyPlan = subscriptionDetails.monthlyPrice;

    const yearlyPriceId = selectedYearlyPlan && selectedYearlyPlan.id;

    if (interval === 'month') {
      return this.props.onUpdateInterval(selectedMonthlyPlan && selectedMonthlyPlan.id, 'month');
    }

    this.props.onUpdateInterval(yearlyPriceId, (isApplication || isFree) ? 'month' : 'year');
  }

  getSnapshotBeforeUpdate(prevProps) {
    const {subscriptionDetails, interval} = this.props;

    if (prevProps.subscriptionDetails &&
      subscriptionDetails &&
      (prevProps.subscriptionDetails.id !== subscriptionDetails.id)) {
      const isApplication = subscriptionDetails.type === 'application';

      const isFree = subscriptionDetails &&
      (subscriptionDetails[interval === 'year' ? 'yearlyPrice' : 'monthlyPrice'])
      && !isApplication
       && isFreePlan(subscriptionDetails, interval)

      const selectedYearlyPlan = subscriptionDetails.yearlyPrice

      const selectedMonthlyPlan = subscriptionDetails.monthlyPrice

      const yearlyPriceId = selectedYearlyPlan && selectedYearlyPlan.id;

      if (interval === 'month') {
        return prevProps.onUpdateInterval(selectedMonthlyPlan && selectedMonthlyPlan.id, 'month');
      }

      prevProps.onUpdateInterval(yearlyPriceId, (isApplication || isFree) ? 'month' : 'year');
    }

    return false
  }

  onClose = () => {
    return this.setState({ showConfirmOrderPage: false, showPlanPage: false });
  };

  onShowConfirmationPage = () => {
    this.setState({ showConfirmOrderPage: true });
  };

  onHideSelectPlan = () => {
    this.setState({ showPlanPage: false });
  };

  onHideConfirmationPage = () => {
    this.setState({ showConfirmOrderPage: false });
  };

  onSubmit = async () => {
    const { data } = await changePlan({
      planId: this.props.planId,
      priceId: this.props.priceId
    });

    if (data.errors) {
      return defaultNotifier(data.errors);
    }

    this.props.getSubscriptionDetailsRequest();
    this.onClose();
  };

  render() {
    const {
      color,
      subscriptionDetails,
      interval,
      logo,
      isFreeTrial,
      isCreatePage,
      isApplicationType,
      minEndpoints, provider, trialPeriods, plans } = this.props;
    const { item, isMPPage } = this.props;
    const quantity = minEndpoints;
    const selectedYearlyPlan = subscriptionDetails.yearlyPrice;
    const selectedMonthlyPlan = subscriptionDetails.monthlyPrice;

    const estimatedYearlyPrice = selectedYearlyPlan && getYearlyMRRStripe(quantity, subscriptionDetails)

    const totalYearlyPrice = selectedYearlyPlan && parseFloat(
      getYearlyMRRStripe(quantity, subscriptionDetails) / 12,
      10
    ).toFixed(2);
    const totalYearlyPricePerSeat = selectedYearlyPlan && parseFloat(
      getYearlyMRRPerSeatStripe(quantity, subscriptionDetails),
      10
    ).toFixed(2);

    const totalMonthlyPrice = subscriptionDetails && parseFloat(
      getMonthlyMRRStripe(quantity, subscriptionDetails),
      10
    ).toFixed(2);

    const totalMonthlyPricePerSeat = selectedMonthlyPlan && parseFloat(
      getMonthlyMRRPerSeatStripe(quantity, subscriptionDetails),
      10
    ).toFixed(2);

    let monthlyPriceId = '';
    let yearlyPriceId = '';

    if (totalMonthlyPrice) {
      monthlyPriceId = selectedMonthlyPlan && selectedMonthlyPlan.id;
    }

    if (totalYearlyPrice) {
      yearlyPriceId = selectedYearlyPlan && selectedYearlyPlan.id;
    }

    let unitAmount = isApplicationType ? subscriptionDetails.monthlyPrice.unit_amount : '';
    unitAmount = unitAmount && parseFloat((unitAmount / 100).toFixed(2));

    const saveCount =
      100 - parseInt((totalYearlyPrice / totalMonthlyPrice) * 100, 10) || 0;

    const estimatedPrice = this.props.interval === 'year' ? estimatedYearlyPrice : totalMonthlyPrice;
    let nextBillingDate = moment(new Date()).add(
      1,
      interval
    );

    if (trialPeriods) {
      nextBillingDate = moment(new Date()).add(
        trialPeriods,
        'day'
      );
    }

    const isFree = subscriptionDetails &&
    (subscriptionDetails[interval === 'year' ? 'yearlyPrice' : 'monthlyPrice'])
    && !isApplicationType
     && isFreePlan(subscriptionDetails, interval)

    return (
      <>
        <div className="content-box subscriptions__item" key={item.id}>
          <div className="grid-row grid-row_aic">
            <div className="grid-col">
              {!isMPPage &&
              <div>
                <a
                  href="javascript:void(0)"
                  onClick={this.props.onBack}
                  className="subscriptions__link"
                >
                  <FormattedMessage {...messages.changePlan} />
                </a>
              </div>
              }
              <div className={classNames('billing_price__card', {
                'billing_price__app': isApplicationType,
                'billing-price__free': isFreeTrial || isCreatePage || isFreePlan(subscriptionDetails, interval)
              })}>
                {(logo) ?
                  <div className="billing-workspace__icon no-border-only">
                    <img
                      src={logo || ''}
                      alt="Img"
                      width="42px"
                      height="42px"
                    />
                  </div>
                  :
                  <div className="no-border">
                    <img
                      src={Viso}
                      alt="Img"
                      width="42px"
                      height="42px"
                    />
                  </div>
                }
                <div className="billing_header">
                  <div className="title">
                    {subscriptionDetails.name}
                    {isFree && (
                      <span className={`badge_tag badge_tag_${color}`}>
                        <FormattedMessage {...messages.free}/>
                      </span>
                    )}
                    {isFree && trialPeriods && (
                      <span className={`badge_tag badge_tag_${color}`}>
                        <FormattedMessage {...messages.freeTrail}/>
                      </span>
                    )}
                  </div>
                  <h4 className="description">by {provider || 'viso.ai'}</h4>
                </div>
              </div>
            </div>
          </div>
          {!isApplicationType && !isFree && !this.props.hideToggle &&
          <div className="billing-switch">
            <div className="radio radio_blocked radio_accent0 radio_billing">
              <input
                type="radio"
                name={`interval-${item.id}`}
                className="radio__input accent4"
                id={`interval-${item.id}`}
                checked={this.props.interval === 'year'}
                onChange={() => {
                  this.props.onUpdateInterval(yearlyPriceId, 'year');
                }}
              />
              <div className="radio__check" />
              <label className="radio__label">
                <FormattedMessage {...messages.paidAnnually} /> – US${' '}
                {totalYearlyPricePerSeat}/mo <FormattedMessage {...messages.perSeat}/>
              </label>
              {saveCount > 0 && <ButtonInfo color="accent0" className="button_info_xm">save {saveCount}%</ButtonInfo>}
            </div>
            <div className="radio radio_blocked radio_accent0 radio_billing">
              <input
                type="radio"
                name={`interval-${item.id}`}
                className="radio__input"
                checked={this.props.interval === 'month'}
                id="2"
                onChange={() => {
                  this.props.onUpdateInterval(monthlyPriceId, 'month');
                }}
              />
              <div className="radio__check" />
              <label className="radio__label">
                <FormattedMessage {...messages.paidMonthly} /> – US${' '}
                {totalMonthlyPricePerSeat}/mo <FormattedMessage {...messages.perSeat}/>
              </label>
            </div>
          </div>
          }
          <hr className="subscriptions__hr" />

          {!isApplicationType ?
            <div className="grid-row">
              <div className="grid-col grid-col_4">
                <div className="h5 subscriptions__label">
                  <FormattedMessage {...messages.seats} />
                </div>
                <div className={'h4 subscriptions__value'}>
                  {quantity} <FormattedMessage {...messages.devices} />
                </div>
              </div>

              <div className="grid-col grid-col_4">
                <div className="h5 subscriptions__label">
                  <FormattedMessage {...messages.nextBillingDate} />
                </div>
                <div className="h4 subscriptions__value">
                  {isCreatePage ? 'n/a' : nextBillingDate && dateFormatter2(nextBillingDate)}
                </div>
              </div>

              <div className="grid-col grid-col_4">
                <div className="h5 subscriptions__label">
                  {trialPeriods
                    ?
                    <FormattedMessage {...messages.priceAfterTrial} />
                    : <FormattedMessage {...messages.priceEstimate}/>}
                </div>
                <div className="h4 subscriptions__value">
                  {estimatedPrice &&
                  <NumberFormat
                    value={parseFloat(estimatedPrice, 10).toFixed(2)}
                    displayType={'text'}
                    thousandSeparator
                    renderText={value => (
                      <span>
                        {`${getSymbolFromCurrency('usd')}${(value)}` ||
                  '$0.00'}
                      </span>
                    )}
                  />
                  }
                  {estimatedPrice && (estimatedPrice !== '0.00') &&
                  <>
                  /{this.props.interval === 'year' ? 'yr' : 'mo'}
                  </>
                  }
                </div>
              </div>
            </div>
            :
            <div className="grid-row">
              <div className="grid-col">
                <div className="overview__label">
                  <FormattedMessage {...messages.type} />
                </div>
                <div className="overview__value">Application</div>
              </div>
              <div className="grid-col">
                <div className="overview__label">
                  <FormattedMessage {...messages.version} />
                </div>
                <div className="overview__value">
                  {subscriptionDetails.activeVersion}
                </div>
              </div>

              <div className="grid-col">
                <div className="overview__label">
                  <FormattedMessage {...messages.unitPrice} />
                </div>
                <div className="overview__value">
                  {getSymbolFromCurrency('usd')} {unitAmount}
                </div>
              </div>
            </div>
          }
        </div>
      </>
    );
  }
}

SubscriptionCard.propTypes = {
  color: PropTypes.string
};

const mapStateToProps = createStructuredSelector({
  workspace: makeSelectFetchWorkspace()
});

const withConnect = connect(
  mapStateToProps,
  { changeSubscriptionRequest, addPlanDetailInSubscription }
);

export default compose(
  withConnect,
  withRouter
)(SubscriptionCard);
