import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import classNames from 'classnames';
import CustomInput from 'components/Input/CustomInput';
import ProcessPage from 'components/ProcessPage';
import { PaymentIcon } from 'images/common';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { SOLUTION_ACCENT } from 'utils/constants';
import messages from 'utils/messages';
import CardForm from '../CardForm';

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

class Billing extends Component {
  state = {
    name: this.props.name,
  };

  onChangePayment = (name) => {
    this.setState({ name });
  };

  render() {
    const { color, interval } = this.props;

    return (
      <ProcessPage onCancel={this.props.onClose}>
        {() => (
          <div>
            <div className="headline">
              <h1 className="headline__title">
                <FormattedMessage {...messages.paymentSettings} />
              </h1>
              <h3 className="headline__subtitle">{this.props.workspaceName}</h3>
            </div>

            <div className="popup__form">
              <div className={`text-field text-field_${color}`}>
                <CustomInput
                  parentClass="text-field"
                  value={this.state.name}
                  onChange={(e) => this.onChangePayment(e.target.value)}
                  label={<FormattedMessage {...messages.nameOnCard} />}
                />
              </div>
              <div className="billing_stripe_input">
                <div className="description">
                  <FormattedMessage {...messages.updateCreditCard} />
                </div>
                <div className="billing_payment_icon">
                  <PaymentIcon />
                  Secured by Stripe
                </div>
                <Elements stripe={stripePromise}>
                  <CardForm
                    color={color}
                    onGetPaymentInfo={this.props.onGetPaymentInfo}
                    name={this.state.name}
                    theme={this.props.theme}
                    isLoading={this.props.isLoading}
                    interval={interval}
                    onClose={this.props.onClose}
                  />
                </Elements>
              </div>
              <div className="login__actions register__actions">
                <button
                  type="submit"
                  onClick={this.props.onSubmit}
                  className={classNames('login__submit button', {
                    'round-button_disabled':
                      this.props.disabled || this.props.isLoading,
                    [`button_${color}`]: color,
                  })}
                  disabled={this.props.disabled || this.props.isLoading}
                >
                  {this.props.isLoading ? 'Loading' : 'Update'}
                </button>
              </div>
              <div className="form-footer">
                Your billing statement will display viso.ai AG, located in
                Schaffhausen, Switzerland. Your credit card issuer may charge
                foreign transaction or cross-border fees in addition to the
                total price.{' '}
              </div>
            </div>
          </div>
        )}
      </ProcessPage>
    );
  }
}

Billing.propTypes = {
  color: PropTypes.string,
  user: PropTypes.object,
};

Billing.defaultProps = {
  color: SOLUTION_ACCENT,
};

export default reduxForm({
  form: 'PAYMENT_INFORMATION',
  destroyOnUnmount: false,
})(Billing);
