import axios from 'axios'
import { jsonToGraphQLQuery } from 'json-to-graphql-query';

import {DEFAULT_SUBSCRIPTIONS_VALUE} from './plans';
import {MARKETPLACE_API_URL} from './marketplace'
import axiosObj from './config';

const BILLING_API_URL =  `${process.env.API_URL}/billing/graphql`;
const SUBCRIPTION_API_URL = `${process.env.API_URL}/subscription/graphql`;

const DEFAULT_INVOICES = {
  hasMore: true,
  data: {
    id: true,
    number: true,
    amount_due: true,
    amount_paid: true,
    amount_remaining: true,
    invoice_pdf: true,
    status: true,
    currency: true,
    created: true
  }
}

const DEFAULT_BILLING_DETAIL = {
  billingInformation: {
    firstName: true,
    lastName: true,
    country: true,
    address1: true,
    address2: true,
    city: true,
    postCode: true,
  },
  billingContact: {
    id: true,
    email: true,
    name: true,
  },
  paymentMethod: true,
  invoices: {
    id: true,
    number: true,
    amount_due: true,
    amount_paid: true,
    amount_remaining: true,
    invoice_pdf: true,
    status: true,
    currency: true,
    created: true
  },
  currency: true,
  balance: true
  // upcomingInvoice: true
}

const DEFAULT_BILLING_INFO = {
  firstName: true,
  lastName: true,
  country: true,
  address1: true,
  address2: true,
  city: true,
  postCode: true,
}

const DEFAULT_PRODUCT_INFO = {
  id: true,
  name: true,
  uniqueName: true,
  description: true,
  isRecurring: true,
  monthlyPrice: true,
  yearlyPrice: true,
}

const DEFAULT_SUBSCRIPTION_VALUE = {
  id: true,
  name: true,
  country: true,
  address1: true,
  address2: true,
  city: true,
  postCode: true,
  billingContactId: {
    id: true,
    email: true
  }
}

export const DEFAULT_PLANS_VALUE = {
  id: true,
  name: true,
  product: true,
  description: true,
  billingPeriod: true,
  phases: true,
  trialFee: true,
  tags: true,
  quantity: true,
  unit: true,
  category: true,
  prettyName: true,
  features: {
    applicationLimit: true,
    applicationVersionLimit: true,
    dashboardLimit: true,
    deploymentLimit: true,
    deviceLimit: true,
    moduleLimit: true,
    profileLimit: true,
    videoLimit: true,
    userLimit: true,
    influxStorageLimit: true,
    logStorageLimit: true,
    auditLogStatus: true,
    multiFactorAuthenticationStatus: true,
    appDataRetentionDays: true,
    deviceMetricsRetentionDays: true,
    terminalAccessManagementStatus: true,
    videoViewerAccessManagementStatus: true,
  }
}

const DEFAULT_WORKSPACE_SUBSCRIPTION = {
  planId: true,
  plan: DEFAULT_PLANS_VALUE,
  subscriptions: true
}

export function getPlans() {
  const query = {
    query: {
      plans: {
        ...DEFAULT_PLANS_VALUE
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BILLING_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function getPlan() {
  const query = {
    query: {
      plan: {
        ...DEFAULT_PLANS_VALUE
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BILLING_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function getBillingInformation() {
  const query = {
    query: {
      billingInformation: {
        ...DEFAULT_SUBSCRIPTION_VALUE
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BILLING_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function getSubscriptionDetails() {
  const query = {
    query: {
      subscriptions: {
        ...DEFAULT_WORKSPACE_SUBSCRIPTION
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BILLING_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function upcomingInvoice(arg) {
  const query = {
    query: {
      upcomingInvoice: {
        __args: {
          // subscriptionId: arg
        },
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BILLING_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function invoices(arg) {
  const query = {
    query: {
      invoices: {
        __args: arg,
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BILLING_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function getBillingDetail() {
  const query = {
    query: {
      billingDetail: {
        ...DEFAULT_BILLING_DETAIL
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BILLING_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function getPayment() {
  const query = {
    query: {
      payment: {
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  const token = localStorage.getItem('newAccessToken')
  const accessToken = localStorage.getItem('accessToken')

  return axios({
    method: 'post',
    url: BILLING_API_URL,
    headers: {
      'Content-Type': 'application/json',
      'authorization': token ? `Bearer ${token}` : `Bearer ${accessToken}`
    },
    data: {
      variables: {},
      query: gqlQuery
    }
  });
}

export function updateBillingInformation(payload) {
  const query = {
    mutation: {
      updateBillingInformation: {
        __args: {
          ...payload
        },
        ...DEFAULT_BILLING_INFO
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })
  const token = localStorage.getItem('newAccessToken')
  const accessToken = localStorage.getItem('accessToken')

  return axios({
    method: 'post',
    url: BILLING_API_URL,
    headers: {
      'Content-Type': 'application/json',
      'authorization': token ? `Bearer ${token}` : `Bearer ${accessToken}`
    },
    data: {
      variables: {},
      query: gqlQuery
    }
  });
}

export function createBillingInformation(payload) {
  const query = {
    mutation: {
      createBillingInformation: {
        __args: {
          ...payload
        },
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'post',
    url: BILLING_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  });
}

export function setPaymentMethod(payload) {
  const query = {
    mutation: {
      setPaymentMethod: {
        __args: {
          ...payload
        }
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  const token = localStorage.getItem('newAccessToken')
  const accessToken = localStorage.getItem('accessToken')

  return axios({
    method: 'post',
    url: BILLING_API_URL,
    headers: {
      'Content-Type': 'application/json',
      'authorization': token ? `Bearer ${token}` : `Bearer ${accessToken}`
    },
    data: {
      variables: {},
      query: gqlQuery
    }
  });
}

export function subscribe() {
  const query = {
    mutation: {
      subscribe: {
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  const token = localStorage.getItem('newAccessToken')
  const accessToken = localStorage.getItem('accessToken')

  return axios({
    method: 'post',
    url: BILLING_API_URL,
    headers: {
      'Content-Type': 'application/json',
      'authorization': token ? `Bearer ${token}` : `Bearer ${accessToken}`
    },
    data: {
      variables: {},
      query: gqlQuery
    }
  });
}

export function getInvoices() {
  const query = {
    query: {
      invoices: {
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BILLING_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function getUpcomingInvoices(arg) {
  const query = {
    query: {
      upcomingInvoice: {
        subscriptionId: arg
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BILLING_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function updateBillingContact(payload) {
  const query = {
    mutation: {
      updateBillingContact: {
        __args: {
          ...payload
        },
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BILLING_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function createBillingContact(payload) {
  const query = {
    mutation: {
      setBillingContact: {
        __args: {
          ...payload
        },
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  const token = localStorage.getItem('newAccessToken')
  const accessToken = localStorage.getItem('accessToken')

  return axios({
    method: 'post',
    url: BILLING_API_URL,
    headers: {
      'Content-Type': 'application/json',
      'authorization': token ? `Bearer ${token}` : `Bearer ${accessToken}`
    },
    data: {
      variables: {},
      query: gqlQuery
    }
  });
}

export function changeSubscription(payload) {
  const query = {
    mutation: {
      changeSubscription: {
        __args: payload
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BILLING_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function getSubscriptionSolution(arg) {
  const query = {
    query: {
      solution: {
        __args: arg,
        monthlyPrice: true,
        yearlyPrice: true,
        logo: true
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: MARKETPLACE_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function changeSubscriptionPrice(arg) {
  const query = {
    mutation: {
      changeSubscriptionPrice: {
        __args: arg
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: MARKETPLACE_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function previewUpcomingInvoice(arg) {
  const query = {
    query: {
      previewUpcomingInvoice: {
        __args: arg
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BILLING_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function products() {
  const query = {
    query: {
      products: {
        ...DEFAULT_PRODUCT_INFO
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: SUBCRIPTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function purchaseDevHours(arg) {
  const query = {
    mutation: {
      purchaseDevHours: {
        __args: arg
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: SUBCRIPTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function cancelProductSubscription(arg) {
  const query = {
    mutation: {
      cancelProductSubscription: {
        __args: arg
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: SUBCRIPTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function cancelPlan(arg) {
  const query = {
    mutation: {
      cancelPlan: {
        __args: arg
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: BILLING_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function reactivateWorkspace(param) {
  const query = {
    mutation: {
      recoverWorkspace: {
        __args: param
      }
    }
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: BILLING_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  });
}