import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
  FETCH_USER_DETAIL_REQUEST,
  FETCH_USER_DETAIL_SUCCESS,
  FETCH_USER_DETAIL_ERROR,
  FETCH_ME_REQUEST,
  FETCH_ME_SUCCESS,
  FETCH_ME_ERROR,
  FETCH_INVITED_BY_USER_REQUEST,
  FETCH_INVITED_BY_USER_SUCCESS,
  FETCH_INVITED_BY_USER_ERROR,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  INVITE_USER_REQUEST,
  INVITE_USER_SUCCESS,
  INVITE_USER_ERROR,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  ARCHIVE_USER_REQUEST,
  ARCHIVE_USER_SUCCESS,
  ARCHIVE_USER_ERROR,
  UPDATE_USER_STATUS_REQUEST,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_STATUS_ERROR,
  CANCEL_INVITATION_REQUEST,
  CANCEL_INVITATIONS_REQUEST,
  RESEND_INVITATION_REQUEST,
  RESEND_INVITATIONS_REQUEST,
  DEACTIVATE_USER_REQUEST,
  DEACTIVATE_USER_SUCCESS,
  ACTIVATE_USER_REQUEST,
  ACTIVATE_USER_SUCCESS,
  DEACTIVATE_USERS_REQUEST,
  DEACTIVATE_USERS_SUCCESS,
  ACTIVATE_USERS_REQUEST,
  ACTIVATE_USERS_SUCCESS,
  UNARCHIVE_USER_REQUEST,
  UNARCHIVE_USER_SUCCESS,
  UNARCHIVE_USER_ERROR,
  ARCHIVE_USERS_REQUEST,
} from './constants';

export function fetchUserDetailRequest(payload) {
  return {
    type: FETCH_USER_DETAIL_REQUEST,
    payload,
  };
}

export function fetchUserDetailSuccess(payload) {
  return {
    type: FETCH_USER_DETAIL_SUCCESS,
    payload,
  };
}

export function fetchUserDetailError(errors) {
  return {
    type: FETCH_USER_DETAIL_ERROR,
    errors,
  };
}

export function cancelInvitationRequest(payload, onCallback) {
  return {
    type: CANCEL_INVITATION_REQUEST,
    payload,
    meta: { onCallback },
  };
}

export function resendInvitationRequest(payload, onCallback) {
  return {
    type: RESEND_INVITATION_REQUEST,
    payload,
    meta: { onCallback },
  };
}

export function cancelInvitationsRequest(payload, onCallback) {
  return {
    type: CANCEL_INVITATIONS_REQUEST,
    payload,
    meta: { onCallback },
  };
}

export function resendInvitationsRequest(payload, onCallback) {
  return {
    type: RESEND_INVITATIONS_REQUEST,
    payload,
    meta: { onCallback },
  };
}

export function fetchUserRequest(payload) {
  return {
    type: FETCH_USER_REQUEST,
    payload,
  };
}

export function fetchUserSuccess(payload) {
  return {
    type: FETCH_USER_SUCCESS,
    payload,
  };
}

export function fetchUserError(errors) {
  return {
    type: FETCH_USER_ERROR,
    errors,
  };
}

export function fetchMeRequest(payload, onFetch) {
  return {
    type: FETCH_ME_REQUEST,
    payload: { onCallback: payload, onFetch: onFetch },
  };
}

export function fetchMeSuccess(payload) {
  return {
    type: FETCH_ME_SUCCESS,
    payload,
  };
}

export function fetchMeError(errors) {
  return {
    type: FETCH_ME_ERROR,
    errors,
  };
}

export function fetchInvitedByUserRequest(payload) {
  return {
    type: FETCH_INVITED_BY_USER_REQUEST,
    payload,
  };
}

export function fetchInvitedByUserSuccess(payload) {
  return {
    type: FETCH_INVITED_BY_USER_SUCCESS,
    payload,
  };
}

export function fetchInvitedByUserError(errors) {
  return {
    type: FETCH_INVITED_BY_USER_ERROR,
    errors,
  };
}

export function inviteUserRequest(payload, onCallback) {
  return {
    type: INVITE_USER_REQUEST,
    payload,
    meta: { onCallback },
  };
}

export function inviteUserSuccess(payload) {
  return {
    type: INVITE_USER_SUCCESS,
    payload,
  };
}

export function inviteUserError(errors) {
  return {
    type: INVITE_USER_ERROR,
    errors,
  };
}

export function updateUserRequest(payload, onCallback) {
  return {
    type: UPDATE_USER_REQUEST,
    payload,
    meta: { onCallback },
  };
}

export function updateUserSuccess(payload) {
  return {
    type: UPDATE_USER_SUCCESS,
    payload,
  };
}

export function updateUserError(errors) {
  return {
    type: UPDATE_USER_ERROR,
    errors,
  };
}

export function changePasswordRequest(params) {
  return {
    type: CHANGE_PASSWORD_REQUEST,
    payload: params,
  };
}

export function changePasswordSuccess(params) {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: params,
  };
}

export function changePasswordError(errors) {
  return {
    type: CHANGE_PASSWORD_ERROR,
    errors,
  };
}

export function onArchiveUserRequest(params) {
  return {
    type: ARCHIVE_USER_REQUEST,
    payload: params,
  };
}

export function onArchiveUsersRequest(params, onCallback) {
  return {
    type: ARCHIVE_USERS_REQUEST,
    payload: params,
    meta: { onCallback },
  };
}

export function onArchiveUserSuccess(params) {
  return {
    type: ARCHIVE_USER_SUCCESS,
    payload: params,
  };
}

export function onArchiveUserError(errors) {
  return {
    type: ARCHIVE_USER_ERROR,
    errors,
  };
}

export function onUnarchiveUserRequest(params) {
  return {
    type: UNARCHIVE_USER_REQUEST,
    payload: params,
  };
}

export function onUnarchiveUserSuccess(params) {
  return {
    type: UNARCHIVE_USER_SUCCESS,
    payload: params,
  };
}

export function onUnarchiveUserError(errors) {
  return {
    type: UNARCHIVE_USER_ERROR,
    errors,
  };
}

export function onUpdateUserStatusRequest(params, onCallback) {
  return {
    type: UPDATE_USER_STATUS_REQUEST,
    payload: params,
    meta: { onCallback },
  };
}

export function onUpdateUserStatuSuccess(params) {
  return {
    type: UPDATE_USER_STATUS_SUCCESS,
    payload: params,
  };
}

export function onDeactivateUserRequest(params, onCallback) {
  return {
    type: DEACTIVATE_USER_REQUEST,
    payload: params,
    meta: { onCallback },
  };
}

export function onDeactivateUserSuccess(params) {
  return {
    type: DEACTIVATE_USER_SUCCESS,
    payload: params,
  };
}

export function activateUserRequest(params, onCallback) {
  return {
    type: ACTIVATE_USER_REQUEST,
    payload: params,
    meta: { onCallback },
  };
}

export function activateUserSuccess(params) {
  return {
    type: ACTIVATE_USER_SUCCESS,
    payload: params,
  };
}

export function onDeactivateUsersRequest(params, onCallback) {
  return {
    type: DEACTIVATE_USERS_REQUEST,
    payload: params,
    meta: { onCallback },
  };
}

export function onDeactivateUsersSuccess(params) {
  return {
    type: DEACTIVATE_USERS_SUCCESS,
    payload: params,
  };
}

export function activateUsersRequest(params, onCallback) {
  return {
    type: ACTIVATE_USERS_REQUEST,
    payload: params,
    meta: { onCallback },
  };
}

export function activateUsersSuccess(params) {
  return {
    type: ACTIVATE_USERS_SUCCESS,
    payload: params,
  };
}
export function onUpdateUserStatusError(errors) {
  return {
    type: UPDATE_USER_STATUS_ERROR,
    errors,
  };
}
