import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import slugify from 'react-slugify';

import {withRouter} from 'withRouter';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import appMessages from 'utils/messages';

import { makeSelectFetchWorkspace } from 'containers/Settings/Workspace/selectors';
import { routeMapping } from 'routes';
import LibraryIcon from './LeftPanel/icons/library';
import SettingsIcon from './LeftPanel/icons/settings';
import BuildingBlocksIcon from './LeftPanel/icons/buildingBlocks';
import DataCollectionIcon from './LeftPanel/icons/videoData';


class MobileNav extends React.Component {
  getActiveClass = (link, activeClass) => {
    const hash = window.location.pathname;

    if (hash === '/' && link === '/') {
      return 'mobile-nav-item nav-title';
    }

    if (link !== '/' && hash.includes(link)) {
      return `mobile-nav-item mobile-nav__link_active_${activeClass} nav-title`;
    }

    return 'mobile-nav-item nav-title';
  };

  render() {
    const { workspace } = this.props;
    const wsPath = workspace && slugify(workspace.name);

    return (
      <div className="mobile-nav">
        <Link
          to={`/${wsPath}${routeMapping.LIBRARY_VIDEOS.path}`}
          className={this.getActiveClass(
            routeMapping.LIBRARY.path,
            'accent3',
          )}
        >
          <DataCollectionIcon />
          <h5><FormattedMessage {...appMessages.dataCollection} /></h5>
        </Link>
        <Link
          to={`/${wsPath}${routeMapping.LIBRARY_MODULES.path}`}
          className={this.getActiveClass(
            routeMapping.DEPLOYMENT.path,
            'accent3',
          )}
        >
          <BuildingBlocksIcon />
          <h5><FormattedMessage {...appMessages.buildingBlocks} /></h5>
        </Link>
        <Link
          to={`/${wsPath}${routeMapping.DEPLOYMENT_PROFILES.path}`}
          className={this.getActiveClass(
            routeMapping.DEPLOYMENT_PROFILES.path,
            'accent1',
          )}
        >
          <LibraryIcon />
          <h5><FormattedMessage {...appMessages.profiles} /></h5>
        </Link>
        <Link
          to={`/${wsPath}${routeMapping.SETTINGS.path}`}
          className={this.getActiveClass(
            routeMapping.SETTINGS.path,
            'accent4',
          )}
        >
          <SettingsIcon />
          <h5><FormattedMessage {...appMessages.settings} /></h5>
        </Link>
      </div>
    );
  }
}


const mapStateToProps = createStructuredSelector({
  workspace: makeSelectFetchWorkspace(),
});

const withConnect = connect(
  mapStateToProps, null,
);


export default withConnect(withRouter(MobileNav));
