import React from 'react';

export default () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.06202 8.6514C12.431 8.6514 15.9889 7.71056 16.2214 6.52099V3.4119C15.9889 4.60146 12.4364 5.5423 8.06202 5.5423C5.9309 5.58349 3.80566 5.30462 1.75732 4.71501V3.63359C3.80622 4.21954 5.93145 4.49477 8.06202 4.45006C12.4364 4.46088 15.9618 3.52004 16.2214 2.34128C16.1132 0.497455 11.0954 0 8.11069 0C5.12595 0 0 0.502863 0 2.39536V14.6046C0 16.4917 5.09892 17 8.11069 17C11.1225 17 16.2214 16.4971 16.2214 14.6046V11.7929C15.9889 12.9771 12.4364 13.9071 8.06202 13.9071C5.93226 13.9571 3.80705 13.6873 1.75732 13.1069V12.0254C3.80705 12.6059 5.93226 12.8757 8.06202 12.8257C12.4364 12.8257 15.9889 11.8957 16.2214 10.7115V7.60242C15.9889 8.79199 12.4364 9.73282 8.06202 9.73282C5.93145 9.77753 3.80622 9.50231 1.75732 8.91635V7.83492C3.80622 8.42088 5.93145 8.6961 8.06202 8.6514V8.6514Z"
      fill="currentColor"
    />
  </svg>
);
