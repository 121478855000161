import {
  FETCH_WORKSPACE_ERROR,
  FETCH_WORKSPACE_SUCCESS,
  FETCH_WORKSPACE_REQUEST,
  FETCH_WORKSPACE_DETAIL_REQUEST,
  UPDATE_WORKSPACE_ERROR,
  UPLOAD_WORKSPACE_REQUEST,
  UPLOAD_WORKSPACE_SUCCESS,
  UPLOAD_WORKSPACE_ERROR,
  UPDATE_WORKSPACE_SUCCESS,
  UPDATE_WORKSPACE_REQUEST,
  MANAGE_WORKSPACE_REQUEST,
  MANAGE_WORKSPACE_SUCCESS,
  CREATE_WORKSPACE_REQUEST,
  CREATE_WORKSPACE_SUCCESS,
  REMOVE_WORKSPACE_LOGO_REQUEST,
  REMOVE_WORKSPACE_LOGO_SUCCESS,
  FETCH_USER_WORKSPACE_REQUEST,
  FETCH_USER_WORKSPACE_SUCCESS,
  SCHEDULE_WORKSPACE_REQUEST,
  SCHEDULE_WORKSPACE_SUCCESS,
  RECOVER_WORKSPACE_SUCCESS,
  RECOVER_WORKSPACE_REQUEST,
  GET_SETUP_TRACKER_REQUEST,
  GET_SETUP_TRACKER_SUCCESS,
  UPDATE_SETUP_TRACKER_REQUEST,
  UPDATE_SETUP_TRACKER,
  GET_SUBSCRIPTION_DETAILS_WS_REQUEST,
  GET_SUBSCRIPTION_DETAILS_WS_SUCCESS,
} from './constants';

export function fetchWorkspaceRequest(payload, onCallback) {
  return {
    type: FETCH_WORKSPACE_REQUEST,
    payload,
    meta: onCallback,
  };
}

export function fetchWorkspaceDetailRequest(payload) {
  return {
    type: FETCH_WORKSPACE_DETAIL_REQUEST,
    payload,
  };
}

export function fetchUserWorkspaceRequest(payload) {
  return {
    type: FETCH_USER_WORKSPACE_REQUEST,
    payload,
  };
}

export function removeWorkspaceLogoRequest(payload) {
  return {
    type: REMOVE_WORKSPACE_LOGO_REQUEST,
    payload,
  };
}

export function removeWorkspaceLogoSuccess(payload) {
  return {
    type: REMOVE_WORKSPACE_LOGO_SUCCESS,
    payload,
  };
}

export function scheduleWorkspaceRequest(payload, onCallback) {
  return {
    type: SCHEDULE_WORKSPACE_REQUEST,
    payload,
    meta: onCallback,
  };
}

export function recoverWorkspaceSuccess(payload) {
  return {
    type: RECOVER_WORKSPACE_SUCCESS,
    payload,
  };
}

export function recoverWorkspaceRequest(payload, onCallback) {
  return {
    type: RECOVER_WORKSPACE_REQUEST,
    payload,
    meta: { onCallback },
  };
}

export function scheduleWorkspaceSuccess(payload) {
  return {
    type: SCHEDULE_WORKSPACE_SUCCESS,
    payload,
  };
}

export function createWorkspaceRequest(payload, onSuccess, onFailure) {
  return {
    type: CREATE_WORKSPACE_REQUEST,
    payload,
    meta: { onSuccess, onFailure },
  };
}

export function fetchWorkspaceSuccess(payload) {
  return {
    type: FETCH_WORKSPACE_SUCCESS,
    payload,
  };
}

export function fetchUserWorkspaceSuccess(payload) {
  return {
    type: FETCH_USER_WORKSPACE_SUCCESS,
    payload,
  };
}

export function fetchWorkspaceError(errors) {
  return {
    type: FETCH_WORKSPACE_ERROR,
    errors,
  };
}

export function updateWorkspaceRequest(payload) {
  return {
    type: UPDATE_WORKSPACE_REQUEST,
    payload,
  };
}

export function updateWorkspaceSuccess(payload) {
  return {
    type: UPDATE_WORKSPACE_SUCCESS,
    payload,
  };
}

export function manageWorkspaceRequest(payload) {
  return {
    type: MANAGE_WORKSPACE_REQUEST,
    payload,
  };
}

export function manageWorkspaceSuccess(payload) {
  return {
    type: MANAGE_WORKSPACE_SUCCESS,
    payload,
  };
}

export function createWorkspaceSuccess(payload) {
  return {
    type: CREATE_WORKSPACE_SUCCESS,
    payload,
  };
}

export function updateWorkspaceError(errors) {
  return {
    type: UPDATE_WORKSPACE_ERROR,
    errors,
  };
}

export function uploadWorkspaceRequest(payload, meta) {
  return {
    type: UPLOAD_WORKSPACE_REQUEST,
    payload,
    meta,
  };
}

export function uploadWorkspaceSuccess(payload) {
  return {
    type: UPLOAD_WORKSPACE_SUCCESS,
    payload,
  };
}

export function uploadWorkspaceError() {
  return {
    type: UPLOAD_WORKSPACE_ERROR,
  };
}

export function getStartedTrackerRequest(payload) {
  return {
    type: GET_SETUP_TRACKER_REQUEST,
    payload,
  };
}

export function updateGetStartedTrackerRequest(payload) {
  return {
    type: UPDATE_SETUP_TRACKER,
    payload,
  };
}

export function getStartedTrackerSuccess(payload) {
  return {
    type: GET_SETUP_TRACKER_SUCCESS,
    payload,
  };
}

export function updateStartedTrackerRequest(payload) {
  return {
    type: UPDATE_SETUP_TRACKER_REQUEST,
    payload,
  };
}

export function getSubscriptionBillingPending(payload) {
  return {
    type: GET_SUBSCRIPTION_DETAILS_WS_REQUEST,
    payload,
  };
}

export function getSubscriptionBillingSuccess(payload) {
  return {
    type: GET_SUBSCRIPTION_DETAILS_WS_SUCCESS,
    payload,
  };
}
