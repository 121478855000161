import React, { Component } from 'react';

import { EXTERNAL_URL } from 'utils/constants';
import { FormattedMessage } from 'react-intl';
import messages from 'utils/messages';
import AHref from 'components/AHref';

class TermsLink extends Component {
  render() {
    return (
      <AHref href={EXTERNAL_URL.terms} className="text-link" rel="noreferrer" target="_blank">
        <FormattedMessage {...messages.termsOfService} />
      </AHref>
    );
  }
}

export default TermsLink;
