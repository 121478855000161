import ListScroll from 'components/List/ListScroll';
import LoadingIndicator from 'components/LoadingIndicator';
import { getLibraryModulesList } from 'containers/Library/selectors';
import Loader from 'containers/Loader';
import NotFound from 'containers/NotFoundPage';
import Status404Page from 'containers/NotFoundPage/404page';
import Status403Page from 'containers/NotFoundPage/403page';
import {
  makeSelectLoading, makeSelectMe,
} from 'containers/Settings/User/selectors';
import { getSubscriptionBillingPending } from 'containers/Settings/Workspace/actions';
import ConfirmMarketplaceOrder from 'containers/Settings/Workspace/ConfirmMarketplaceOrder';
import CreateWorkspace from 'containers/Settings/Workspace/Create';
import DeactivatedWorkspace from 'containers/Settings/Workspace/DeactivatedWorkspace';
import RemoveWorkspace from 'containers/Settings/Workspace/RemoveWorkspace';
import RequiredModules from 'containers/Settings/Workspace/RequiredModules';
import SelectWorkspace from 'containers/Settings/Workspace/Select';
import { makeSelectFetchSubscription, makeSelectUserWorkspace } from 'containers/Settings/Workspace/selectors';
import localForage from 'localforage';
import PropTypes from 'prop-types';
import React from 'react';
import { Slash } from 'react-feather';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
  Routes, Route, Switch, Navigate
} from 'react-router-dom';
import {withRouter} from 'withRouter';

import slugify from 'react-slugify';
import { createStructuredSelector } from 'reselect';
import { routes } from 'routes';
import { SOLUTION_ACCENT } from 'utils/constants';
import messages from 'utils/messages';
import zohoAdd from 'utils/zohoAdd';
import zohoRemove from 'utils/zohoRemove';
// import Gleap from 'gleap';
import Authentication from 'containers/Settings/User/Detail/components/Authentication';
import { makeSelectUser, getUploadTrackerInfo } from 'containers/App/selectors';
import { uploadTracker } from 'containers/App/actions';
import { routeMapping } from '../../api/constants';
import PreLoaderHome from '../../components/PreLoader/PreLoaderHome';
import PreLoaderList from '../../components/PreLoader/PreLoaderList';
import PreLoader from '../../components/PreLoader/PreLoaderDetail';
import PreLoaderSettings from '../../components/PreLoader/PreLoaderSettings';
import PreLoaderWs from '../../components/PreLoader/PreLoaderWs';
import PreLoaderStats from '../../components/PreLoader/PreLoaderStats';
import BillingFull from 'containers/Billing/Billingfull'
// eslint-disable-next-line react/prefer-stateless-function
import ImageProgressBar from '../../components/ImageProgressBar';

class CentralPanel extends React.Component {
  state = {
    showPurchasePage: false,
    requiredModules: [],
    showUploaderModal: false
  };

  onClose = () => {
    this.props.router.navigate(routeMapping.LOGIN.path);
  };

  onCancel = () => {
    this.props.uploadTracker([])
    this.setState({showUploaderModal: false})
  }


  async componentDidMount() {
    const isNew = await localForage.getItem('isNewVersion');

    // Gleap.initialize("tklt9mAiLkdeUhTvzqMDV6HzNjOFwNqG");

    if (isNew === null) {
      await localForage.setItem('isNewVersion', true);
    }

    // if (isNew === true) {
    //   await localForage.setItem('isNewVersion', false);
    // }

    const result = await localForage.getItem('activePartnerId');

    if (result && result.id) {
      // if (result.solution && result.solution.type === 'application') {
        return this.setState({
          showRequiredModulesPage: true,
          solution: result.solution,
          requiredModules: result.solution.requiredModules,
        });

      // this.setState({ showPurchasePage: true });
    }
  }

  // eslint-disable-next-line
  async componentWillReceiveProps(nextProps) {
    const { me, workspace } = this.props;

    const result = await localForage.getItem('activePartnerId');

    if (!result) {
      this.setState({ showPurchasePage: false });
    }
  }

  onInstallAll = () => {
    // const { modules } = this.props;

    // const moduleIds = modules.data.map(item => item.moduleID);

    // const restModules = this.state.requiredModules
    //   .filter(item => item.type === 'marketplace')
    //   .filter(item => !moduleIds.includes(item.moduleID));

    this.setState({
      showPurchasePage: false,
      showRequiredModulesPage: false,
      // uninstalledModules: restModules,
    });
  };

  pageType = {
    '/videos': 'video',
  }

  singleType = [
    '/settings/profile',
    '/settings/workspace',
    '/settings/partner',
    '/settings/billing'
  ]

  listType = [
    '/videos',
    '/image-annotation',
    '/model-training',
    '/models',
    '/modules',
    '/applications',
    '/application/no-code',
    '/device-images',
    '/devices',
    '/profiles',
    '/device-manager',
    '/settings/user',
    '/settings/role',
    '/settings/video-access',
    '/settings/terminal',
    '/solutions'
  ]

  render() {
    const {
      user: { activeWorkspace, workspaces = [] },
      workspace,
      me,
      loading,
      updateTrackerData
    } = this.props;

    const hasWorkspace = activeWorkspace || workspace;
    const hasWorkspaces = !!workspaces.length;

    const { pathname } = window.location;

    if (pathname.startsWith('/pricing') || pathname.startsWith('/marketplace')) {
      zohoRemove();
    } else {
      zohoAdd();
    }

    if (hasWorkspaces && !hasWorkspace) {
      return <SelectWorkspace />;
    }

    if (!me || loading || !workspace || !activeWorkspace) {
      if (this.props.router.location.pathname.endsWith('settings/quota')) {
        return <PreLoaderStats isProcessPage/>;
      }

      if (this.props.router.location.pathname.endsWith('/settings')) {
        return <PreLoaderSettings isProcessPage/>;
      }

      if (pathname.endsWith('/manage/workspaces')) {
        return <PreLoaderWs/>
      }

      if (this.listType.find(item => pathname.endsWith(item))) {
        return <PreLoaderList isProcessPage/>;
      }

      if (this.singleType.find(item => pathname.endsWith(item))) {
        return <PreLoader isProcessPage/>;
      }

      if (pathname.split('/').length <= 2) {
        return <PreLoaderHome isProcessPage/>;
      }

      return <PreLoader isProcessPage/>;

    }

    if (!me) {
      return null;
    }

    if (me && workspace && workspace.twoFactorSettingEnabled && !me.twoFactorEnabled) {
      return (
        <Authentication
          user={me}
          onClose={this.onClose}
          activeState
        />
      );
    }

    if (this.state.showRequiredModulesPage) {
      return (
        <RequiredModules
          solution={this.state.solution}
          requiredModules={this.state.requiredModules}
          onClose={() => this.setState({ showRequiredModulesPage: false })}
          onSubmit={this.onInstallAll}
        />
      );
    }

    // if (this.state.showPurchasePage) {
    //   return <ConfirmMarketplaceOrder />;
    // }

    if (this.state.showPurchasePage) {
      return (
        <ConfirmMarketplaceOrder modules={this.state.uninstalledModules} />
      );
    }

    if (!hasWorkspace && !hasWorkspaces) {
      return <Route component={CreateWorkspace} />;
    }

    if (me && (workspace && workspace.status) === 'locked' && me.isOwner) {
      return (
        <BillingFull
          workspace={workspace}
          onClose={this.onClose}
        />
      );
    }

    if (me && (workspace && workspace.status) === 'inactive' && me.isOwner) {
      return (
        <BillingFull
          workspace={workspace}
          onClose={this.onClose}
        />
      );
    }

    if (me && (workspace && workspace.status) === 'locked' && (workspace && workspace.userRole !== 'Owner')) {
      return (
        <Route
          render={props => (
            <DeactivatedWorkspace
              {...props}
              workspace={workspace}
              onClose={this.onClose}
            />
          )}
        />
      );
    }

    if (me && (workspace && workspace.status) === 'inactive' && (workspace && workspace.userRole !== 'Owner')) {
      return (
        <Route
          render={props => (
            <DeactivatedWorkspace
              {...props}
              workspace={workspace}
              onClose={this.onClose}
            />
          )}
        />
      );
    }

    if (me && (workspace && workspace.status) === 'deleted') {
      return (
        <Route
          render={props => (
            <RemoveWorkspace
              {...props}
              color={SOLUTION_ACCENT}
              workspace={workspace}
              onClose={this.onClose}
            />
          )}
        />
      );
    }

    return (
      <>

        <div className="central-wrapper" />
        {updateTrackerData && !!updateTrackerData.length &&
         <ImageProgressBar 
         onClose={this.onCancel}
         files={this.props.updateTrackerData}/>}
        <Loader />
        <Routes>
          <Route
            path="/403-page"
            exact
            element={<ListScroll>
                {() => (
                  <Status403Page />
                )}
              </ListScroll>
            }
          />
          {routes.map(route => (route.component ? (
            <Route
              key={route.name}
              path={route.path}
              exact={route.exact}
              sidebarStatus={this.props.sidebarStatus}
              name={route.name}
              element={route.component}
            />
          ) : null))}
          <Route
            path="*"
            exact
            element={<ListScroll>
                {() => (
                  <Status404Page />
                )}
              </ListScroll>
            }
          />
          <Route
            element={<ListScroll>
                {() => (
                  <NotFound
                    strokeWidth="1"
                    color="label"
                    logo={<Slash size={42} />}
                    title={<FormattedMessage {...messages.pageNotFound} />}
                    description={
                      <FormattedMessage {...messages.outdatedLink} />
                    }
                    buttonText={<FormattedMessage {...messages.backToHome} />}
                    onAction={() => this.props.router.navigate('/')}
                  />
                )}
              </ListScroll>
            }
          />
        <Route
            path="/"
            element={<Navigate to={`/${workspace && slugify(workspace.name)}`} replace />}
        />
        </Routes>
      </>
    );

  }
}

const mapStateToProps = createStructuredSelector({
  me: makeSelectMe(),
  loading: makeSelectLoading(),
  modules: getLibraryModulesList(),
  subscription: makeSelectFetchSubscription(),
  workspaces: makeSelectUserWorkspace(),
  userData: makeSelectUser(),
  updateTrackerData: getUploadTrackerInfo()
});

const withConnect = connect(mapStateToProps, { getSubscriptionBillingPending, uploadTracker });

CentralPanel.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
  workspace: PropTypes.object,
};

export default withConnect(withRouter(CentralPanel));
