import React from 'react';
import PropTypes from 'prop-types';
import {withRouter } from 'withRouter'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import {FormattedMessage} from 'react-intl'
import {Users} from 'react-feather'

import SmallCard from 'components/List/SmallCard';
import { getInitials, isRoleHigherThan } from 'utils/common';
import Menu from './SingleMenu';
import {getDetailRole} from 'containers/Settings/AccessRole/selectors'
import classNames from 'classnames';

import messages from 'utils/messages'
import NotFound from 'containers/NotFoundPage';
import PreLoaderCard from 'components/PreLoader/PreLoaderCard';

class Card extends React.Component {
  getStatus = active => {
    const className = classNames('data-cards__status', {
      success: active,
      failure: !active
    });

    return <i className={className}>•</i>;
  };

  getSubheading = (status, role = {}) => {
    return (
      <>
        {this.getStatus(status)} {status && `${status.toLowerCase()}`} • {role && role.name}
      </>
    );
  };

  renderEmptyComponent = () => {
    return (
      <NotFound
        parentClass="no-result__container"
        color={this.props.colorClassName}
        strokeWidth="1"
        logo={<Users size={42}/>}
        title={<FormattedMessage {...messages.noResultsFound}/>}
        description={<FormattedMessage {...messages.tryAdjustingSearch}/>}
      />
    );
  };

  render() {
    const { data, onSelect, selectedItems, color, workspace, detailRole } = this.props;

    if (this.props.isLoading) {
      return <PreLoaderCard/>
    }

    return (
      <>
        <div className="data-cards">
          <div className="grid-row">
            {!data.length &&
          !this.props.isLoading &&
          this.renderEmptyComponent()}
            {data &&
              data.map(user => {
                const { firstName, lastName, status, id, demoData, role = {} } = user;
                const isPrimaryOwner = workspace && workspace.createdBy && (id === workspace.createdBy.id)
                const hasAccess = detailRole && role && isRoleHigherThan(detailRole.role, role);

                const Header = () => {
                  return (
                    <span>
                      {firstName || ''} {lastName || ''}
                      {isPrimaryOwner && <span className="workspace_owner_tag">
                        <FormattedMessage {...messages.cPrimaryOwner}/></span>}
                    </span>
                  )
                }

                return (
                  <SmallCard
                    allowOnlySingleSelect={this.props.allowOnlySingleSelect}
                    key={id}
                    heading={<Header/>}
                    title={`${firstName} ${lastName}`}
                    id={id}
                    demoData={demoData}
                    multipleSelect
                    hasPermissionToUpdate
                    preventRedirectOnClick={this.props.preventRedirectOnClick}
                    disabledIds={this.props.disabledIds}
                    hideSingleMenu={this.props.hideSingleMenu}
                    initials={getInitials(firstName, lastName)}
                    onCardClick={this.props.onRowClick}
                    colorClassName={color}
                    subheading={this.getSubheading(status, role)}
                    selectedItems={selectedItems}
                    onSelect={onSelect}
                  >
                    <Menu
                      id={id}
                      hasAccess={hasAccess}
                      onDelete={this.props.onDelete}
                      isPrimaryOwner={isPrimaryOwner}
                      loggedUserId={this.props.loggedUserId}
                      onAssignRole={this.props.onAssignRole}
                      status={status}
                      hasPermissionToUpdate
                      detailRole={this.props.detailRole.role}
                      onUpdate={this.props.onUpdate}
                      onArchive={this.props.onArchive}
                      onDeactivate={this.props.onDeactivate}
                      onActivate={this.props.onActivate}
                    />
                  </SmallCard>
                );
              })}
          </div>
        </div>
      </>
    );
  }
}

Card.propTypes = {
  data: PropTypes.array,
  onSelect: PropTypes.func,
  history: PropTypes.object,
  selectedIds: PropTypes.array
};

const mapStateToProps = createStructuredSelector({
  detailRole: getDetailRole()
})

const withConnect = connect(
  mapStateToProps, null
)

export default withConnect(withRouter(Card))
