const addLocaleData = require('react-intl').addLocaleData //eslint-disable-line
const enLocaleData = require('react-intl/locale-data/en');
const deLocaleData = require('react-intl/locale-data/de');
const frLocaleData = require('react-intl/locale-data/fr');
const itLocaleData = require('react-intl/locale-data/it');

const enFlag = require('images/flags/en.jpg');
const deFlag = require('images/flags/de.jpg');
const enTranslationMessages = require('./translations/en.json');
const deTranslationMessages = require('./translations/de.json');

// images

addLocaleData(enLocaleData);
addLocaleData(deLocaleData);
addLocaleData(frLocaleData);
addLocaleData(itLocaleData);

const appLanguages = {
  en: {
    name: 'English',
    imagePath: enFlag,
    code: 'en',
    defaultLanguage: 'english',
  },
  de: {
    name: 'German',
    imagePath: deFlag,
    code: 'de',
    defaultLanguage: 'german',
  },
};

const appLocales = Object.keys(appLanguages);

const DEFAULT_LOCALE = localStorage.getItem('locale') || 'en';

const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages = locale !== DEFAULT_LOCALE
    ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
    : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage = !messages[key] && locale !== DEFAULT_LOCALE
      ? defaultFormattedMessages[key]
      : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

const translationMessages = {
  en: formatTranslationMessages('en', enTranslationMessages),
  de: formatTranslationMessages('de', deTranslationMessages),
};

exports.appLocales = appLocales;
exports.appLanguages = appLanguages;
exports.formatTranslationMessages = formatTranslationMessages;
exports.translationMessages = translationMessages;
exports.DEFAULT_LOCALE = DEFAULT_LOCALE;
