import classNames from 'classnames';
import Accordion from 'components/Accordion';
import {
  NotificationNew,
  Home,
  ArrowLeft,
  CarbonForIbmProduct,
  ChartLineData,
  Settings,
  Development,
  ObjectStorage,
  InstanceClassic,
  AreaCustom,
  MachineLearningModel,
  DataVis_2,
  UserAvatar,
  UserMultiple,
  Password,
  Security,
  Terminal,
  MediaLibrary,
  CollapseAll,
  ChartColumn,
  Portfolio,
  Store,
  Layers,
  Cube,
  WorkflowAutomation,
  LoadBalancerGlobal,
} from '@carbon/react/icons';
import { ChevronsDown, ChevronsUp, Check, ChevronDown } from 'react-feather';
import { RightCevronIcon, WorkspaceIcon } from 'images/common';
import PropTypes from 'prop-types';
import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Scrollbars } from 'react-custom-scrollbars';
import { isMobile } from 'react-device-detect';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { FormattedMessage } from 'react-intl';
import OutsideClickHandler from 'react-outside-click-handler';
import { Link } from 'react-router-dom';
import slugify from 'react-slugify';
import { routeMapping } from 'routes';
import {
  DASHBOARD_ACCENT_COLOR,
  DEFAULT_ACCENT,
  LIBRARY_ACCENT,
  SOLUTION_ACCENT,
} from 'utils/constants';
import appMessages from 'utils/messages';
import { isPermitted } from 'utils/permissions';
import { connect } from 'react-redux';
import injectReducer from 'utils/injectReducer';

import { updateDeviceType, updateSidebarDisplay } from 'containers/App/actions';
import reducer from 'containers/App/reducer';
import { getSidebarStatus } from 'containers/App/selectors';

class LeftPanel extends React.Component {
  constructor(props) {
    super(props);
    this.navHead = React.createRef();
    this.navTopMenu = React.createRef();
    this.navBottomMenu = React.createRef();
    this.navHeight = React.createRef();
    this.navStaticHeight = React.createRef();
    this.navDynamicHeight = React.createRef();
  }

  state = {
    isActive: false,
    showWarning: false,
    scrollTop: 0,
    showSupportModal: false,
    showGetStartedModal: false,
    openAll: true,
    isChanged: false,
    showButtonBox: true,
  };

  hideNav = () => {
    document.getElementById('sidebar').classList.remove('sidebar_visible');
    document.getElementById('overlay').classList.remove('overlay_visible');
  };

  getActiveClass = (link, activeClass) => {
    const hash = window.location.pathname;

    if (hash.split('/').length - 1 === 1 && link === '/') {
      return `primary-nav__link primary-nav__link_active ${activeClass}`;
    }

    if (link !== '/' && hash.includes(link)) {
      return `primary-nav__link primary-nav__link_active ${activeClass}`;
    }

    return `primary-nav__link ${activeClass}`;
  };

  getChildActiveClass = (link, activeClass) => {
    const hash = window.location.pathname;
    const { workspace } = this.props;

    // Activate sub header when header is selected.
    const DEFAULT_ACTIVE_SUB_HEADERS = {
      [`/${workspace && slugify(workspace.name)}${
        routeMapping.DEPLOYMENT.path
      }`]: routeMapping.DEPLOYMENT_PROFILES.path,
      [`/${workspace && slugify(workspace.name)}${routeMapping.LIBRARY.path}`]:
        routeMapping.LIBRARY_APPLICATIONS.path,
    };

    if (
      DEFAULT_ACTIVE_SUB_HEADERS[hash] &&
      DEFAULT_ACTIVE_SUB_HEADERS[hash] === link
    ) {
      return `primary-nav__link primary-nav__link_active ${activeClass}`;
    }

    if (link !== '/' && hash.includes(link)) {
      return `primary-nav__link primary-nav__link_active ${activeClass}`;
    }

    return `primary-nav__link ${activeClass}`;
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {};

  handleWindowSizeChangeTimeset = () => {
    setTimeout(() => {
      this.handleWindowSizeChange();
    }, 1);
  };

  componentDidMount() {
    this.props.updateDeviceType({
      width: window.innerWidth,
      isMobile: isMobile,
    });
    window.addEventListener('resize', this.handleWindowSizeChange);

    this.handleWindowSizeChange();

    if (this.props.deviceSizeType === 'mobile') {
      return this.props.updateSidebarDisplay(true);
    }
  }

  handleScrollStart = () => {
    this.setState({ isScrolling: true });
  };

  handleScrollStop = () => {
    this.setState({ isScrolling: false });
  };

  // Routes displayed in the left panel.
  navLinks = [
    {
      icon: <DataVis_2 />,
      id: 'videos',
      link: routeMapping.LIBRARY_VIDEOS.path,
      class: `primary-nav__link_${LIBRARY_ACCENT}`,
      name: <FormattedMessage {...appMessages.dataCollection} />,
    },
    {
      icon: <AreaCustom />,
      id: 'imageAnnotation',
      link: routeMapping.LIBRARY_IMAGE_ANNOTATION.path,
      class: `primary-nav__link_${LIBRARY_ACCENT}`,
      name: <FormattedMessage {...appMessages.imageAnnotation} />,
    },
    {
      icon: <CarbonForIbmProduct />,
      id: 'modelTraining',
      link: routeMapping.LIBRARY_MODEL_TRAINING.path,
      class: `primary-nav__link_${LIBRARY_ACCENT}`,
      name: <FormattedMessage {...appMessages.modelTraining} />,
    },
    {
      icon: <MachineLearningModel />,
      id: 'models',
      class: `primary-nav__link_${LIBRARY_ACCENT}`,
      link: routeMapping.LIBRARY_MODELS.path,
      name: <FormattedMessage {...appMessages.aiModels} />,
    },
  ];

  navLinks1 = [
    {
      icon: <Cube />,
      id: 'modules',
      class: `primary-nav__link_${LIBRARY_ACCENT}`,
      link: routeMapping.LIBRARY_MODULES.path,
      name: <FormattedMessage {...appMessages.buildingBlocks} />,
    },
    {
      icon: <WorkflowAutomation />,
      id: 'application-edit',
      class: `primary-nav__link_${LIBRARY_ACCENT}`,
      link: routeMapping.APPLICATIONS_NO_CODE_PAGE.path,
      name: <FormattedMessage {...appMessages.noCodeEditor} />,
    },
    {
      icon: <Development />,
      id: 'applications',
      class: `primary-nav__link_${LIBRARY_ACCENT}`,
      link: routeMapping.LIBRARY_APPLICATIONS.path,
      name: <FormattedMessage {...appMessages.applications} />,
    },
  ];

  navLinks2 = [
    {
      icon: <InstanceClassic />,
      id: 'devices',
      class: `primary-nav__link_${DEFAULT_ACCENT}`,
      link: routeMapping.SETTING_DEVICE_TYPE_LIST.path,
      name: <FormattedMessage {...appMessages.deviceEnrollment} />,
    },
    {
      icon: <ObjectStorage />,
      id: 'devices',
      class: `primary-nav__link_${DEFAULT_ACCENT}`,
      link: routeMapping.DEPLOYMENT_DEVICES.path,
      name: <FormattedMessage {...appMessages.deviceManagement} />,
    },
    {
      icon: <LoadBalancerGlobal />,
      id: 'profiles',
      class: `primary-nav__link_${DEFAULT_ACCENT}`,
      link: routeMapping.DEPLOYMENT_PROFILES.path,
      name: <FormattedMessage {...appMessages.deploymentCenter} />,
    },
    {
      icon: <WorkflowAutomation />,
      id: 'device-configuration',
      class: `primary-nav__link_${DEFAULT_ACCENT}`,
      link: routeMapping.DEPLOYMENT_DEVICES_MANAGER.path,
      name: <FormattedMessage {...appMessages.configurations} />,
    },
  ];

  navLinks3 = [
    {
      icon: <ChartLineData />,
      id: 'dashboard',
      class: `primary-nav__link_${DASHBOARD_ACCENT_COLOR}`,
      link: routeMapping.DASHBOARD.path,
      name: <FormattedMessage {...appMessages.monitoring} />,
    },
    {
      icon: <Settings />,
      id: 'settings',
      link: routeMapping.SETTINGS.path,
      class: `primary-nav__link_${SOLUTION_ACCENT}`,
      name: <FormattedMessage {...appMessages.settings} />,
    },
  ];

  navLinksSetting = [
    {
      icon: <UserAvatar />,
      id: 'settings',
      link: routeMapping.PROFILE.path,
      class: `primary-nav__link_${SOLUTION_ACCENT}`,
      name: <FormattedMessage {...appMessages.myProfile} />,
    },
    {
      icon: <Layers />,
      id: 'myWorkspaces',
      dataCy: 'my-workspace',
      link: routeMapping.MANAGE_WORKSPACE.path,
      class: `primary-nav__link_${SOLUTION_ACCENT}`,
      name: <FormattedMessage {...appMessages.myWorkspaces} />,
    },
  ];

  navLinksSetting1 = [
    {
      icon: <UserMultiple />,
      id: 'users',
      dataCy: 'my-user',
      class: `primary-nav__link_${SOLUTION_ACCENT}`,
      link: routeMapping.SETTINGS_USER.path,
      name: <FormattedMessage {...appMessages.userManagement} />,
    },
    {
      icon: <Password />,
      id: 'role',
      dataCy: 'my-role',
      class: `primary-nav__link_${SOLUTION_ACCENT}`,
      link: routeMapping.SETTINGS_ROLE.path,
      name: <FormattedMessage {...appMessages.rolesAndAccess} />,
    },
    {
      icon: <Security />,
      id: 'authentication',
      dataCy: 'authentication',
      class: `primary-nav__link_${SOLUTION_ACCENT}`,
      link: routeMapping.AUTHENTICATION.path,
      name: <FormattedMessage {...appMessages.authentication} />,
    },
    {
      icon: <Terminal />,
      id: 'terminalAccess',
      dataCy: 'terminal-access',
      class: `primary-nav__link_${SOLUTION_ACCENT}`,
      link: `${routeMapping.SETTINGS.path}/terminal`,
      name: <FormattedMessage {...appMessages.terminalAccess} />,
    },
    {
      icon: <MediaLibrary />,
      id: 'videoViewer',
      dataCy: 'video-viewer',
      class: `primary-nav__link_${SOLUTION_ACCENT}`,
      link: `${routeMapping.SETTINGS.path}/video-access`,
      name: <FormattedMessage {...appMessages.videoViewerAccess} />,
    },
  ];

  navLinksSetting2 = [
    {
      icon: <Settings />,
      id: 'workspace',
      dataCy: 'workspace-settings',
      class: `primary-nav__link_${SOLUTION_ACCENT}`,
      link: routeMapping.WORKSPACE.path,
      name: <FormattedMessage {...appMessages.workspaceSettings} />,
    },
    {
      icon: <CollapseAll />,
      id: 'billing',
      class: `primary-nav__link_${SOLUTION_ACCENT}`,
      link: routeMapping.BILLING.path,
      name: <FormattedMessage {...appMessages.billingAndSubscriptions} />,
    },
    {
      icon: <ChartColumn />,
      id: 'planLimitsUsage',
      class: `primary-nav__link_${SOLUTION_ACCENT}`,
      link: routeMapping.BILLING_QUOTA.path,
      name: <FormattedMessage {...appMessages.planLimitsUsage} />,
    },
  ];

  navLinksSetting3 = [
    {
      icon: <Portfolio />,
      id: 'partner',
      dataCy: 'partner-settings',
      class: `primary-nav__link_${DEFAULT_ACCENT}`,
      link: routeMapping.PARTNER.path,
      name: <FormattedMessage {...appMessages.partnerPortal} />,
    },
    {
      icon: <Store />,
      id: 'marketplace',
      class: `primary-nav__link_${DASHBOARD_ACCENT_COLOR}`,
      link: routeMapping.MARKETPLACE.path,
      name: <FormattedMessage {...appMessages.marketplace} />,
    },
  ];

  handleScroll = (payload) => {
    this.setState({ scrollHeight: payload.top });
    if (payload.top < 0.99) {
      this.setState({ showButtonBox: true });
    } else {
      this.setState({ showButtonBox: false });
    }

    if (payload.scrollTop !== 0) {
      this.setState({ isScrolling: true });
      return this.setState({ scrollTop: payload.scrollTop });
    }

    this.setState({ isScrolling: false });
    this.setState({ scrollTop: payload.scrollTop });
  };

  render() {
    const {
      sidebarStatus,
      workspace,
      me,
      notifications,
      tracker = {},
    } = this.props;

    const { navbarHeight, navbarTop, navbarHeader } = this.state;

    const navStaticHeight =
      this.navStaticHeight &&
      this.navStaticHeight.current &&
      this.navStaticHeight.current.offsetHeight;

    const isSettingPage = false;

    const displayName = 'Display';

    const sidebarClass = classNames('sidebar', {
      collapsed: sidebarStatus,
      loading: true,
    });

    const activeWorkspace = classNames('workspace-nav__toggle h4', {
      active: this.state.isActive,
    });

    const navIconClass = classNames('left-panel-started', {
      active: this.state.showGetStartedModal,
    });

    const navBottomClass = classNames('primary-nav primary-nav_bottom', {
      'primary-nav_bottom_wrapper':
        navbarHeight > navbarTop + navbarHeader + 180,
    });

    return (
      <>
        <div
          id="overlay"
          className="overlay mobile-only"
          onClick={this.hideNav}
        />
        <aside id="sidebar" className={sidebarClass} ref={this.navHeight}>
          <div className={classNames('sidebar__header', {})} ref={this.navHead}>
            <div className="sidebar__head">
              {sidebarStatus ? (
                <div className="sidebar__title h1 c-label">
                  viso
                  <br />
                  <span className="sidebar__title_sec">suite</span>
                </div>
              ) : (
                <div className="sidebar__title h1 c-label">
                  viso<span className="sidebar__title_sec">suite</span>
                </div>
              )}
            </div>
            <OutsideClickHandler onOutsideClick={() => null}>
              <nav
                className={classNames('workspace-nav', {
                  'workspace-nav__active': this.state.isActive,
                })}
                data-cy="navbar-settings"
              >
                <button type="button" className={activeWorkspace}>
                  {!sidebarStatus && (
                    <div className="header mx-b-3 w-84">Testing</div>
                  )}
                  {!sidebarStatus && (
                    <div
                      className="h5 c-label sub-header w-84"
                      // onClick={this.redirectToUserPage}
                    >
                      {displayName}
                    </div>
                  )}
                  <div
                    className={`${
                      workspace && workspace.logo && 'no-border'
                    } workspace-nav__icon`}
                  >
                    <WorkspaceIcon />
                  </div>
                  {!sidebarStatus && (
                    <span className="workspace-icon__cevron">
                      <ChevronDown size={18} />
                    </span>
                  )}
                </button>
              </nav>
            </OutsideClickHandler>
          </div>
          <div
            ref={this.navStaticHeight}
            className={classNames('primary-nav-wrapper', {
              'primary-nav-wrapper_scroll': this.state.isScrolling,
            })}
          >
            <Scrollbars
              universal
              style={{ width: '100%' }}
              autoHide
              autoHideDuration={1}
              onScrollFrame={this.handleScroll}
              renderThumbVertical={(props) => <div {...props} className="" />}
            >
              <nav className="primary-nav" ref={this.navDynamicHeight}>
                {
                  <>
                    <div className="nav-item nav-sidepanel-loader">
                      {sidebarStatus && !this.state.isActive && (
                        <div
                          className="nav-item__tooltip"
                          style={{ marginTop: -this.state.scrollTop }}
                        >
                          <p className="app-tooltip">
                            <FormattedMessage {...appMessages.dashboard} />
                          </p>
                        </div>
                      )}
                      <Link
                        to="/"
                        className={this.getActiveClass(
                          '/',
                          `primary-nav__link primary-nav__link_${DEFAULT_ACCENT}`
                        )}
                      >
                        <Home />
                        <FormattedMessage {...appMessages.dashboard} />
                      </Link>
                    </div>
                    <div className="nav-item nav-item-last nav-sidepanel-loader">
                      {sidebarStatus && !this.state.isActive && (
                        <div
                          className="nav-item__tooltip"
                          style={{ marginTop: -this.state.scrollTop }}
                        >
                          <p className="app-tooltip">
                            <FormattedMessage {...appMessages.notifications} />
                          </p>
                        </div>
                      )}
                      <a
                        href="javascript:void(0)"
                        onClick={this.openNotification}
                        className={`primary-nav__link primary-nav__link_${DEFAULT_ACCENT} 
                        primary-nav__link_notification`}
                      >
                        <NotificationNew />
                        {!sidebarStatus &&
                          notifications &&
                          !!notifications.unreadCount && (
                          <span
                            className={classNames('primary-nav__badge', {
                              'primary-nav__badge_plus':
                                  notifications.unreadCount > 9,
                            })}
                          >
                            {notifications.unreadCount > 99
                              ? '99'
                              : notifications.unreadCount}
                          </span>
                        )}
                        <FormattedMessage {...appMessages.notifications} />
                      </a>
                    </div>
                  </>
                }
                {/* <hr className="mt_100" /> */}
                <div
                  id="nav-fixed-height"
                  ref={this.navTopMenu}
                  onClick={this.handleWindowSizeChangeTimeset()}
                  style={{
                    minHeight: navStaticHeight / 2 + navStaticHeight / 25,
                  }}
                >
                  {
                    <>
                      <Accordion
                        noLine
                        // alwaysOpen={sidebarStatus}
                        isOpen={this.state.openAll}
                        isChanged={this.state.isChanged}
                        title="TRAIN"
                        description={
                          <>
                            {this.navLinks
                              .map((row, index) => {
                                if (row.shape === 'line') {
                                  return <hr className="mt_100" />;
                                }

                                return (
                                  <div key={index} className="nav-item">
                                    <div>
                                      {sidebarStatus && !this.state.isActive && (
                                        <div
                                          className="nav-item__tooltip"
                                          style={{
                                            marginTop: -this.state.scrollTop,
                                          }}
                                        >
                                          <p className="app-tooltip">
                                            {row.name}
                                          </p>
                                        </div>
                                      )}
                                      <Link
                                        to={`/${
                                          workspace && slugify(workspace.name)
                                        }${row.link}`}
                                        onClick={
                                          this.handleWindowSizeChangeTimeset
                                        }
                                        key={index}
                                        className={this.getActiveClass(
                                          row.link,
                                          row.class
                                        )}
                                      >
                                        {row.icon}
                                        {row.name}
                                      </Link>
                                    </div>
                                  </div>
                                );
                              })}
                          </>
                        }
                      />
                      <Accordion
                        // alwaysOpen={sidebarStatus}
                        noLine
                        isOpen={this.state.openAll}
                        isChanged={this.state.isChanged}
                        title="DEVELOP"
                        description={
                          <>
                            {this.navLinks1
                              .map((row, index) => {
                                if (row.shape === 'line') {
                                  return <hr className="mt_100" />;
                                }

                                return (
                                  <div key={index} className="nav-item">
                                    <div>
                                      {sidebarStatus && !this.state.isActive && (
                                        <div
                                          className="nav-item__tooltip"
                                          style={{
                                            marginTop: -this.state.scrollTop,
                                          }}
                                        >
                                          <p className="app-tooltip">
                                            {row.name}
                                          </p>
                                        </div>
                                      )}
                                      <Link
                                        to={`/${
                                          workspace && slugify(workspace.name)
                                        }${row.link}`}
                                        onClick={
                                          this.handleWindowSizeChangeTimeset
                                        }
                                        key={index}
                                        className={this.getActiveClass(
                                          row.link,
                                          row.class
                                        )}
                                      >
                                        {row.icon}
                                        {row.name}
                                      </Link>
                                    </div>
                                  </div>
                                );
                              })}
                          </>
                        }
                      />
                      <Accordion
                        // alwaysOpen={sidebarStatus}
                        noLine
                        isOpen={this.state.openAll}
                        isChanged={this.state.isChanged}
                        title="DEPLOY"
                        description={
                          <>
                            {this.navLinks2
                              .map((row, index) => {
                                if (row.shape === 'line') {
                                  return <hr className="mt_100" />;
                                }

                                return (
                                  <div key={index} className="nav-item">
                                    <div>
                                      {sidebarStatus && !this.state.isActive && (
                                        <div
                                          className="nav-item__tooltip"
                                          style={{
                                            marginTop: -this.state.scrollTop,
                                          }}
                                        >
                                          <p className="app-tooltip">
                                            {row.name}
                                          </p>
                                        </div>
                                      )}
                                      <Link
                                        to={`/${
                                          workspace && slugify(workspace.name)
                                        }${row.link}`}
                                        onClick={
                                          this.handleWindowSizeChangeTimeset
                                        }
                                        key={index}
                                        className={this.getActiveClass(
                                          row.link,
                                          row.class
                                        )}
                                      >
                                        {row.icon}
                                        {row.name}
                                      </Link>
                                    </div>
                                  </div>
                                );
                              })}
                          </>
                        }
                      />
                      <Accordion
                        // alwaysOpen={sidebarStatus}
                        noLine
                        isOpen={this.state.openAll}
                        isChanged={this.state.isChanged}
                        title="OPERATE"
                        description={
                          <>
                            {this.navLinks3
                              .map((row, index) => {
                                if (row.shape === 'line') {
                                  return <hr className="mt_100" />;
                                }

                                return (
                                  <div key={index} className="nav-item">
                                    <div>
                                      {sidebarStatus && !this.state.isActive && (
                                        <div
                                          className="nav-item__tooltip"
                                          style={{
                                            marginTop: -this.state.scrollTop,
                                          }}
                                        >
                                          <p className="app-tooltip">
                                            {row.name}
                                          </p>
                                        </div>
                                      )}
                                      <Link
                                        to={`/${
                                          workspace && slugify(workspace.name)
                                        }${row.link}`}
                                        onClick={
                                          this.handleWindowSizeChangeTimeset
                                        }
                                        key={index}
                                        className={this.getActiveClass(
                                          row.link,
                                          row.class
                                        )}
                                      >
                                        {row.icon}
                                        {row.name}
                                      </Link>
                                    </div>
                                  </div>
                                );
                              })}
                          </>
                        }
                      />
                    </>
                  }
                  {isSettingPage && (
                    <>
                      <nav className="primary-nav">
                        <div className="nav-item mx-b-20">
                          <Link
                            to="/"
                            className={`primary-nav__link primary-nav__link_${DEFAULT_ACCENT}`}
                          >
                            <ArrowLeft />
                            <FormattedMessage {...appMessages.back} />
                          </Link>
                        </div>
                      </nav>
                      <Accordion
                        noLine
                        // alwaysOpen={sidebarStatus}
                        isOpen={this.state.openAll}
                        title="PERSONAL"
                        description={
                          <>
                            {this.navLinksSetting
                              .map((row, index) => {
                                if (row.shape === 'line') {
                                  return <hr className="mt_100" />;
                                }

                                if (row.type === 'ahref') {
                                  return (
                                    <div key={index} className="nav-item">
                                      {sidebarStatus && !this.state.isActive && (
                                        <div
                                          className="nav-item__tooltip"
                                          style={{
                                            marginTop: -this.state.scrollTop,
                                          }}
                                        >
                                          <p className="app-tooltip">
                                            {row.name}
                                          </p>
                                        </div>
                                      )}
                                      <ahref
                                        onClick={() => {
                                          this.handleWindowSizeChangeTimeset();
                                          row.link();
                                        }}
                                        key={index}
                                        className={this.getActiveClass(
                                          row.link,
                                          row.class
                                        )}
                                      >
                                        {row.icon}
                                        {row.name}
                                      </ahref>
                                    </div>
                                  );
                                }

                                return (
                                  <>
                                    <div key={index} className="nav-item">
                                      {sidebarStatus && !this.state.isActive && (
                                        <div
                                          className="nav-item__tooltip"
                                          style={{
                                            marginTop: -this.state.scrollTop,
                                          }}
                                        >
                                          <p className="app-tooltip">
                                            {row.name}
                                          </p>
                                        </div>
                                      )}
                                      <Link
                                        to={`/${
                                          workspace && slugify(workspace.name)
                                        }${row.link}`}
                                        onClick={
                                          this.handleWindowSizeChangeTimeset
                                        }
                                        key={index}
                                        className={this.getActiveClass(
                                          row.link,
                                          row.class
                                        )}
                                      >
                                        {row.icon}
                                        {row.name}
                                      </Link>
                                    </div>
                                  </>
                                );
                              })}
                          </>
                        }
                      />
                      <Accordion
                        // alwaysOpen={sidebarStatus}
                        noLine
                        isOpen={this.state.openAll}
                        isChanged={this.state.isChanged}
                        title="SECURITY"
                        description={
                          <>
                            {this.navLinksSetting1
                              .map((row, index) => {
                                if (row.shape === 'line') {
                                  return <hr className="mt_100" />;
                                }
                                if (row.type === 'ahref') {
                                  return (
                                    <div key={index} className="nav-item">
                                      {sidebarStatus && !this.state.isActive && (
                                        <div
                                          className="nav-item__tooltip"
                                          style={{
                                            marginTop: -this.state.scrollTop,
                                          }}
                                        >
                                          <p className="app-tooltip">
                                            {row.name}
                                          </p>
                                        </div>
                                      )}
                                      <ahref
                                        onClick={() => {
                                          this.handleWindowSizeChangeTimeset();
                                          row.link();
                                        }}
                                        key={index}
                                        className={this.getActiveClass(
                                          row.link,
                                          row.class
                                        )}
                                      >
                                        {row.icon}
                                        {row.name}
                                      </ahref>
                                    </div>
                                  );
                                }

                                return (
                                  <>
                                    <div key={index} className="nav-item">
                                      {sidebarStatus && !this.state.isActive && (
                                        <div
                                          className="nav-item__tooltip"
                                          style={{
                                            marginTop: -this.state.scrollTop,
                                          }}
                                        >
                                          <p className="app-tooltip">
                                            {row.name}
                                          </p>
                                        </div>
                                      )}
                                      <Link
                                        to={`/${
                                          workspace && slugify(workspace.name)
                                        }${row.link}`}
                                        onClick={
                                          this.handleWindowSizeChangeTimeset
                                        }
                                        key={index}
                                        className={this.getActiveClass(
                                          row.link,
                                          row.class
                                        )}
                                      >
                                        {row.icon}
                                        {row.name}
                                      </Link>
                                    </div>
                                  </>
                                );
                              })}
                          </>
                        }
                      />
                      <Accordion
                        // alwaysOpen={sidebarStatus}
                        noLine
                        isOpen={this.state.openAll}
                        isChanged={this.state.isChanged}
                        title="ACCOUNT"
                        description={
                          <>
                            {this.navLinksSetting2
                              .map((row, index) => {
                                if (row.shape === 'line') {
                                  return <hr className="mt_100" />;
                                }

                                if (row.type === 'ahref') {
                                  return (
                                    <div key={index} className="nav-item">
                                      {sidebarStatus && !this.state.isActive && (
                                        <div
                                          className="nav-item__tooltip"
                                          style={{
                                            marginTop: -this.state.scrollTop,
                                          }}
                                        >
                                          <p className="app-tooltip">
                                            {row.name}
                                          </p>
                                        </div>
                                      )}
                                      <ahref
                                        onClick={() => {
                                          this.handleWindowSizeChangeTimeset();
                                          row.link();
                                        }}
                                        key={index}
                                        className={this.getActiveClass(
                                          row.link,
                                          row.class
                                        )}
                                      >
                                        {row.icon}
                                        {row.name}
                                      </ahref>
                                    </div>
                                  );
                                }

                                return (
                                  <>
                                    <div key={index} className="nav-item">
                                      {sidebarStatus && !this.state.isActive && (
                                        <div
                                          className="nav-item__tooltip"
                                          style={{
                                            marginTop: -this.state.scrollTop,
                                          }}
                                        >
                                          <p className="app-tooltip">
                                            {row.name}
                                          </p>
                                        </div>
                                      )}
                                      <Link
                                        to={`/${
                                          workspace && slugify(workspace.name)
                                        }${row.link}`}
                                        onClick={
                                          this.handleWindowSizeChangeTimeset
                                        }
                                        key={index}
                                        className={this.getActiveClass(
                                          row.link,
                                          row.class
                                        )}
                                      >
                                        {row.icon}
                                        {row.name}
                                      </Link>
                                    </div>
                                  </>
                                );
                              })}
                          </>
                        }
                      />
                      <Accordion
                        noLine
                        // alwaysOpen={sidebarStatus}
                        title="MORE"
                        isOpen={this.state.openAll}
                        isChanged={this.state.isChanged}
                        description={
                          <>
                            {this.navLinksSetting3
                              .map((row, index) => {
                                if (row.shape === 'line') {
                                  return <hr className="mt_100" />;
                                }

                                if (row.type === 'ahref') {
                                  return (
                                    <div key={index} className="nav-item">
                                      {sidebarStatus && !this.state.isActive && (
                                        <div
                                          className="nav-item__tooltip"
                                          style={{
                                            marginTop: -this.state.scrollTop,
                                          }}
                                        >
                                          <p className="app-tooltip">
                                            {row.name}
                                          </p>
                                        </div>
                                      )}
                                      <ahref
                                        onClick={() => {
                                          this.handleWindowSizeChangeTimeset();
                                          row.link();
                                        }}
                                        key={index}
                                        className={this.getActiveClass(
                                          row.link,
                                          row.class
                                        )}
                                      >
                                        {row.icon}
                                        {row.name}
                                      </ahref>
                                    </div>
                                  );
                                }

                                return (
                                  <>
                                    <div key={index} className="nav-item">
                                      {sidebarStatus && !this.state.isActive && (
                                        <div
                                          className="nav-item__tooltip"
                                          style={{
                                            marginTop: -this.state.scrollTop,
                                          }}
                                        >
                                          <p className="app-tooltip">
                                            {row.name}
                                          </p>
                                        </div>
                                      )}
                                      <Link
                                        to={`/${
                                          workspace && slugify(workspace.name)
                                        }${row.link}`}
                                        onClick={
                                          this.handleWindowSizeChangeTimeset
                                        }
                                        key={index}
                                        className={this.getActiveClass(
                                          row.link,
                                          row.class
                                        )}
                                      >
                                        {row.icon}
                                        {row.name}
                                      </Link>
                                    </div>
                                  </>
                                );
                              })}
                          </>
                        }
                      />
                    </>
                  )}
                </div>
                <div className="primary-nav_hr mobile-only">
                  <hr className="mt_100" />
                </div>
                {this.props.detailRole && me && (
                  <nav className={navBottomClass} ref={this.navBottomMenu}>
                    {me &&
                      workspace &&
                      workspace.showGetStartedPanel &&
                      (me.role.name === 'Owner' ||
                        me.role.name === 'Admin') && (
                      <div
                        className={navIconClass}
                        onClick={() =>
                          this.updateShowGetStarted(
                            !this.state.showGetStartedModal
                          )
                        }
                      >
                        <div className="left-panel-icon">
                          <CircularProgressbar
                            strokeWidth={13}
                            value={isCompleted ? 0 : value}
                            maxValue={tracker && tracker.taskCount}
                            // text={tracker &&
                            //   tracker.taskCount - tracker.completedCount}
                          />
                        </div>
                        {(tracker && tracker.taskCount) !==
                            (tracker && tracker.completedCount) && (
                          <div className="get-started-text">
                            {tracker &&
                                tracker.taskCount - tracker.completedCount}
                          </div>
                        )}
                        {(tracker && tracker.taskCount) ===
                            (tracker && tracker.completedCount) && (
                          <div className="get-started-success">
                            <Check />
                          </div>
                        )}
                        <a
                          href="javascript:void(0)"
                          className={`primary-nav__link_${DEFAULT_ACCENT} left-panel-text`}
                        >
                          <div>
                            <div className="left-panel-title mx-b-3">
                              <FormattedMessage {...appMessages.setup} />
                                &nbsp;Viso Suite
                            </div>
                            <h5 className="left-panel-desc">
                              {(tracker &&
                                  tracker.taskCount - tracker.completedCount) ||
                                  '0'}{' '}
                              <FormattedMessage
                                {...appMessages.remainingTasks}
                              />
                            </h5>
                          </div>
                        </a>
                      </div>
                    )}
                  </nav>
                )}
                <div className="nav-footer">
                  <button
                    type="button"
                    className="sidebar__toggle not-mobile h4"
                    onClick={() =>
                      this.props.updateSidebarDisplay(!sidebarStatus)
                    }
                  >
                    <RightCevronIcon />
                    <FormattedMessage {...appMessages.collapse} />
                  </button>

                  {!sidebarStatus && (
                    <div className="nav-footer-menu">
                      <div type="button" onClick={this.onExpandAll}>
                        <ChevronsDown />
                      </div>
                      <div onClick={this.onCollapseAll} className="">
                        <ChevronsUp />
                      </div>
                    </div>
                  )}
                </div>
              </nav>
            </Scrollbars>
            <div
              className={classNames('', {
                'primary-nav-scroll': this.state.showButtonBox,
              })}
            />
          </div>
        </aside>
      </>
    );
  }
}

LeftPanel.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  workspaces: PropTypes.any,
  workspace: PropTypes.object,
  sidebarStatus: PropTypes.bool,
  selectWorkspaceRequest: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  sidebarStatus: getSidebarStatus(),
});

const withConnect = connect(mapStateToProps, {
  updateDeviceType,
  updateSidebarDisplay,
});

const withReducer = injectReducer({ key: 'global', reducer });

export default compose(withReducer, withConnect)(LeftPanel);
