import React from 'react';
import PropTypes from 'prop-types';
import {withRouter } from 'withRouter'
import {FormattedMessage} from 'react-intl'
import {dateFormatter4} from 'utils/common'

import SmallCard from 'components/List/SmallCard';

import messages from 'utils/messages'
import NotFound from 'containers/NotFoundPage';
import {HistoryIcon} from 'images/common'
import NumberFormat from 'react-number-format';

class Card extends React.Component {
  renderEmptyComponent = () => {
    return (
      <NotFound
        parentClass="no-result__container"
        color={this.props.colorClassName}
        strokeWidth="1"
        logo={<HistoryIcon size={42}/>}
        title={<FormattedMessage {...messages.noResultsFound}/>}
        description={<FormattedMessage {...messages.tryAdjustingSearch}/>}
      />
    );
  };

  render() {
    const { data, onSelect, selectedItems, color } = this.props;

    return (
      <>
        <div className="data-cards">
          <div className="grid-row">
            {!data.length &&
          !this.props.isLoading &&
          this.renderEmptyComponent()}
            {data &&
              data
                .map(item => ({...item, id: item.invoiceId}))
                .map(user => {
                  const { amount, currency, id, invoiceDate, demoData } = user;

                  return (
                    <SmallCard
                      key={id}
                      heading={dateFormatter4(invoiceDate, true)}
                      CheckboxImage={<HistoryIcon/>}
                      id={id}
                      demoData={demoData}
                      multipleSelect
                      allowOnlySingleSelect
                      hasPermissionToUpdate
                      preventRedirectOnClick={this.props.preventRedirectOnClick}
                      disabledIds={this.props.disabledIds}
                      hideSingleMenu={this.props.hideSingleMenu}
                      onCardClick={this.props.onRowClick}
                      colorClassName={color}
                      subheading={
                        <div>
                          <NumberFormat
                            value={(amount && parseFloat(amount).toFixed(2)) || '0.00'}
                            displayType={'text'}
                            thousandSeparator renderText={value =>
                              <span>
                                {currency && currency.toUpperCase()} {value}
                              </span>
                            }/>
                        &nbsp;•&nbsp;
                          {/* <a download href={invoice_pdf} target='_blank' rel="noopener noreferrer" className={`${color}`}>
                          <FormattedMessage {...messages.download}/>
                        </a> */}
                        </div>
                      }
                      selectedItems={selectedItems}
                      onSelect={onSelect}
                    >
                    </SmallCard>
                  );
                })}
          </div>
        </div>
      </>
    );
  }
}

Card.propTypes = {
  data: PropTypes.array,
  onSelect: PropTypes.func,
  history: PropTypes.object,
  selectedIds: PropTypes.array
};

export default withRouter(Card)
