import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

let pasted = false;

export default class CustomInput extends React.Component {
  state = {
    showError: false,
  }

  allowAlphaNumericSpace = (e) => {
    const regexp = new RegExp(/^[a-zA-Z0-9 ]*$/);
    let keynum;
    if (window.event) {
      keynum = e.keyCode;
    } else if (e.which) {
      keynum = e.which;
    }
    const test = regexp.test(String.fromCharCode(keynum));

    if (e.keyCode === 96) {
      return true;
    }

    return test;
  }

  onKeyUp = (e, index) => {
    if (!pasted) {
      let nextfield = '';
      nextfield = document.querySelector(
        `input[name=field-${index + 2}]`,
      );

      if (e.keyCode === 8) {
        nextfield = document.querySelector(
          `input[name=field-${index}]`,
        );

        if (nextfield !== null) {
          return nextfield.focus();
        }
      }

      if (this.allowAlphaNumericSpace(e)) {
        this.props.onChange(e, index);
        if (nextfield !== null) {
          nextfield.focus();
        }
      }
    }
  }

  onChange = (e, index) => {
    pasted = false;
    this.setState({ value: e.currentTarget.value });
    return this.props.onChange(e, index);
  }

  onPaste = (e) => {
    const data = [];
    let clipboardData; let
      pastedData;

    // Stop data actually being pasted into div
    e.stopPropagation();
    e.preventDefault();

    // Get pasted data via clipboard API
    clipboardData = e.clipboardData || window.clipboardData;
    pastedData = clipboardData.getData('Text');

    [0, 0, 0, 0, 0, 0].map((item, index) => data.push(pastedData[index]));

    pasted = true;
    return this.props.onUpdate(data);
  }

  render() {
    const { activeIndex } = this.props;

    return (
      <div className="barcode-digits-wrapper">
        <input
          {...this.props.input}
          name="field-1"
          autoFocus={activeIndex === 0}
          value={this.props.codes[0]}
          type="text"
          onPaste={this.onPaste}
          className={
            classNames({
              'text-field-digits': true,
              'text-field-digits_active': this.props.codes[0],
            })
          }
          maxLength="1"
          max={1}
          readOnly={this.props.readOnly}
          onChange={e => this.onChange(e, 0)}
          onKeyUp={e => this.onKeyUp(e, 0)}
        />
        <input
          {...this.props.input}
          name="field-2"
          autoFocus={activeIndex === 1}
          type="text"
          onPaste={this.onPaste}
          value={this.props.codes[1]}
          className={
            classNames({
              'text-field-digits': true,
              'text-field-digits_active': this.props.codes[1],
            })
          }
          maxLength="1"
          max={1}
          readOnly={this.props.readOnly}
          onChange={e => this.onChange(e, 1)}
          onKeyUp={e => this.onKeyUp(e, 1)}
        />
        <input
          {...this.props.input}
          name="field-3"
          value={this.props.codes[2]}
          autoFocus={activeIndex === 2}
          type="text"
          onPaste={this.onPaste}
          className={
            classNames({
              'text-field-digits': true,
              'text-field-digits_active': this.props.codes[2],
            })
          }
          maxLength="1"
          max={1}
          readOnly={this.props.readOnly}
          onChange={e => this.onChange(e, 2)}
          onKeyUp={e => this.onKeyUp(e, 2)}
        />
        <input
          {...this.props.input}
          name="field-4"
          type="text"
          onPaste={this.onPaste}
          value={this.props.codes[3]}
          autoFocus={activeIndex === 3}
          className={
            classNames({
              'text-field-digits': true,
              'text-field-digits_active': this.props.codes[3],
            })
          }
          maxLength="1"
          max={1}
          readOnly={this.props.readOnly}
          onChange={e => this.onChange(e, 3)}
          onKeyUp={e => this.onKeyUp(e, 3)}
        />
        <input
          {...this.props.input}
          name="field-5"
          type="text"
          value={this.props.codes[4]}
          autoFocus={activeIndex === 4}
          onPaste={this.onPaste}
          className={
            classNames({
              'text-field-digits': true,
              'text-field-digits_active': this.props.codes[4],
            })
          }
          maxLength="1"
          max={1}
          readOnly={this.props.readOnly}
          onChange={e => this.onChange(e, 4)}
          onKeyUp={e => this.onKeyUp(e, 4)}
        />
        <input
          {...this.props.input}
          name="field-6"
          autoFocus={activeIndex === 5}
          type="text"
          value={this.props.codes[5]}
          onPaste={this.onPaste}
          className={
            classNames({
              'text-field-digits': true,
              'text-field-digits_active': this.props.codes[5],
            })
          }
          maxLength="1"
          max={1}
          readOnly={this.props.readOnly}
          onChange={e => this.onChange(e, 5)}
          onKeyUp={e => this.onKeyUp(e, 5)}
        />
      </div>
    );
  }
}

CustomInput.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.any,
  parentClass: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  hintText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isInvalid: PropTypes.any,
};
