import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getDetailRole, getDetailLoading } from 'containers/Settings/AccessRole/selectors';
import { makeSelectLoading } from 'containers/Settings/Workspace/selectors';
import { getPermission } from 'utils/permissions';
import { FormattedMessage } from 'react-intl';
import ListScroll from 'components/List/ListScroll';
import messages from 'utils/messages';
import Status403 from './containers/NotFoundPage/403page';
import { makeSelectFetchWorkspace } from 'containers/Settings/Workspace/selectors';

class PermissionWrapper extends Component {
  render() {
    const {
      name, detailRole, loading, wsLoading, type = 'none',
    } = this.props;

    const permission = getPermission(name, detailRole.role, this.props.workspace);

    if (loading || wsLoading) {
      return (
        <div>
          <FormattedMessage {...messages.loading} />
          ...
        </div>
      );
    }

    if (permission.includes(type)) {
      return (
        <ListScroll>
          {() => <Status403 />
          }
        </ListScroll>
      );
    }

    // when permission is valid render the children
    return this.props.children;
  }
}

const mapStateToProps = createStructuredSelector({
  detailRole: getDetailRole(),
  loading: getDetailLoading(),
  wsLoading: makeSelectLoading(),
  workspace: makeSelectFetchWorkspace(),
});

const withConnect = connect(
  mapStateToProps, null,
);

PermissionWrapper.propTypes = {
  detailRole: PropTypes.object,
  name: PropTypes.string,
  loading: PropTypes.bool,
  wsLoading: PropTypes.bool,
  type: PropTypes.string,
  children: PropTypes.node,
};

export default withConnect(PermissionWrapper);
