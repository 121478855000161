import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import {
  updateUser,
  getUsers,
  getUser,
  me,
  updateProfile,
  activateUsers,
  archiveUsers,
  inviteUser,
  cancelInvitation,
  cancelInvitations,
  resendInvitation,
  resendInvitations,
  getInvitedUsers,
  activateUser,
  deactivateUser,
  archiveUser,
  deactivateUsers,
} from 'api/users';
import { intl } from 'containers/LanguageProvider/intlProvider';

import { isOwner } from 'utils/common';
import messages from 'utils/messages';
import {
  loggedInUserDataRequest,
} from 'containers/App/actions';
import {
  defaultNotifier,
} from 'functions/notificationHandler';
import {
  FETCH_USER_REQUEST,
  FETCH_ME_REQUEST,
  UPDATE_USER_STATUS_REQUEST,
  ARCHIVE_USER_REQUEST,
  ARCHIVE_USERS_REQUEST,
  FETCH_USER_DETAIL_REQUEST,
  FETCH_INVITED_BY_USER_REQUEST,
  INVITE_USER_REQUEST,
  UPDATE_USER_REQUEST,
  CANCEL_INVITATION_REQUEST,
  RESEND_INVITATION_REQUEST,
  DEACTIVATE_USER_REQUEST,
  ACTIVATE_USER_REQUEST,
  DEACTIVATE_USERS_REQUEST,
  ACTIVATE_USERS_REQUEST,
  RESEND_INVITATIONS_REQUEST,
  CANCEL_INVITATIONS_REQUEST,
} from './constants';
import {
  fetchUserSuccess,
  fetchUserError,
  fetchMeSuccess,
  fetchMeError,
  fetchUserDetailSuccess,
  fetchInvitedByUserSuccess,
  fetchInvitedByUserError,
  inviteUserSuccess,
  inviteUserError,
  updateUserSuccess,
  updateUserError,
  onUpdateUserStatuSuccess,
} from './actions';

function* fetchUserSaga(action) {
  try {
    const { args } = action.payload;

    const response = yield call(getUsers, args);

    if (response.data.errors) {
      yield put(fetchUserError(response.data.errors));
      defaultNotifier(response.data.errors);
    }
    yield put(fetchUserSuccess({ ...response.data.data.users, ...args }));
  } catch (err) {
    yield put(fetchUserError(err));
    defaultNotifier(err);
  }
}

function* fetchUserDetailSaga(action) {
  try {
    const { args } = action.payload;
    const response = yield call(getUser, args);

    if (response.data.errors) {
      yield put(fetchUserError(response.data.errors));
      defaultNotifier(response.data.errors);
    } else {
      yield put(fetchUserDetailSuccess(response.data.data.user));
    }
  } catch (err) {
    yield put(fetchUserError(err));
    defaultNotifier(err);
  }
}


function* fetchMeSaga(action) {
  try {
    const { payload } = action;
    const response = yield call(me);

    if (localStorage.getItem('userData')) {
      const { workspaceList, activeWorkspace } = JSON.parse(localStorage.getItem('userData'));

      if (response.data.errors) {
        yield put(fetchMeError(response.data.errors));
        defaultNotifier(response.data.errors);
        payload.onCallback();
      }

      const isOwnerType = isOwner(response.data.data.me.role);
      yield put(fetchMeSuccess({ ...response.data.data.me, isOwner: isOwnerType }));
      localStorage.setItem('userData', JSON.stringify({ ...response.data.data.me, activeWorkspace, workspaces: workspaceList }));
      if (payload.onFetch) {
        payload.onFetch();
      }

      yield put(loggedInUserDataRequest());
    }
  } catch (err) {
    const { payload } = action;

    yield put(fetchMeError(err));
    defaultNotifier(`${err}. ${intl.formatMessage({ ...messages.redirectingToLoginScreen })}`);
    if (payload.onCallback) {
      payload.onCallback();
    }
  }
}

function* fetchInvitedByUserSaga(action) {
  try {
    const { payload } = action;
    const response = yield call(getInvitedUsers, payload);

    if (response.data.errors) {
      yield put(fetchInvitedByUserError(response.data.errors));
      defaultNotifier(response.data.errors);
    } else {
      yield put(fetchInvitedByUserSuccess(response.data.data.invitations));
    }
  } catch (err) {
    yield put(fetchInvitedByUserError(err));
    defaultNotifier(err);
  }
}

function* inviteUserSaga(action) {
  try {
    const { payload } = action;
    const response = yield call(inviteUser, payload);

    if (response.data.errors) {
      yield put(inviteUserError(response.data.errors));
      defaultNotifier(response.data.errors);
    } else {
      yield put(inviteUserSuccess(response.data.data.inviteUser));

      if (action.meta) {
        action.meta.onCallback();
      }

      defaultNotifier(intl.formatMessage({ ...messages.invitationSentSuccessfully }));
    }
  } catch (err) {
    yield put(inviteUserError(err));
    defaultNotifier(err);
  }
}

function* cancelInvitationSaga(action) {
  try {
    const { payload, meta } = action;
    const response = yield call(cancelInvitation, payload);

    if (response.data.errors) {
      yield put(inviteUserError(response.data.errors));
      defaultNotifier(response.data.errors);
    } else {
      yield put(inviteUserSuccess(response.data.data.cancelInvitation));
      defaultNotifier(intl.formatMessage({ ...messages.invitationCancelledSuccessfully }));
      if (meta.onCallback) {
        meta.onCallback();
      }
    }
  } catch (err) {
    yield put(inviteUserError(err));
    defaultNotifier(err);
  }
}

function* cancelInvitationsSaga(action) {
  try {
    const { payload, meta } = action;
    const response = yield call(cancelInvitations, payload);

    if (response.data.errors) {
      yield put(inviteUserError(response.data.errors));
      defaultNotifier(response.data.errors);
    } else {
      yield put(inviteUserSuccess(response.data.data.cancelInvitations));

      if (response.data.data.cancelInvitations.skippedCount) {
        return defaultNotifier(`${intl.formatMessage({ ...messages.invitationsCancelledSuccessfully })}. 
        ${response.data.data.cancelInvitations.skippedCount} ${intl.formatMessage({ ...messages.usersWereSkipped })}`);
      }

      defaultNotifier(intl.formatMessage({ ...messages.invitationsCancelledSuccessfully }));

      if (meta.onCallback) {
        meta.onCallback();
      }
    }
  } catch (err) {
    yield put(inviteUserError(err));
    defaultNotifier(err);
  }
}

function* resendInvitationSaga(action) {
  try {
    const { payload, meta } = action;
    const response = yield call(resendInvitation, payload);

    if (response.data.errors) {
      yield put(inviteUserError(response.data.errors));
      defaultNotifier(response.data.errors);
    } else {
      yield put(inviteUserSuccess(response.data.data.resendInvitation));
      defaultNotifier(intl.formatMessage({ ...messages.invitationResendSuccessfully }));
      if (meta.onCallback) {
        meta.onCallback();
      }
    }
  } catch (err) {
    yield put(inviteUserError(err));
    defaultNotifier(err);
  }
}

function* resendInvitationsSaga(action) {
  try {
    const { payload, meta } = action;
    const response = yield call(resendInvitations, payload);

    if (response.data.errors) {
      yield put(inviteUserError(response.data.errors));
      defaultNotifier(response.data.errors);
    } else {
      yield put(inviteUserSuccess(response.data.data.resendInvitations));

      if (response.data.data.resendInvitations.skippedCount) {
        return defaultNotifier(`${intl.formatMessage({ ...messages.invitationsResendSuccessfully })}. 
        ${response.data.data.resendInvitations.skippedCount} ${intl.formatMessage({ ...messages.usersWereSkipped })}`);
      }

      defaultNotifier(intl.formatMessage({ ...messages.invitationsResendSuccessfully }));

      if (meta.onCallback) {
        meta.onCallback();
      }
    }
  } catch (err) {
    yield put(inviteUserError(err));
    defaultNotifier(err);
  }
}

function* updateUserSaga(action) {
  try {
    const { payload, meta } = action;
    const selectedUser = payload.me;
    const payloadId = payload.id;
    delete payload.me;
    let apiName = 'updateUser';

    let response;

    if ((selectedUser && selectedUser.id) === payload.id) {
      delete payload.id;
      apiName = 'updateProfile';
      response = yield call(updateProfile, payload);
    } else {
      response = yield call(updateUser, payload);
    }
    const { workspaceList, activeWorkspace } = JSON.parse(localStorage.getItem('userData'));

    if (response.data.errors) {
      yield put(updateUserError(response.data.errors));
      defaultNotifier(response.data.errors);
    } else {
      yield put(updateUserSuccess(response.data.data[apiName]));
      if ((selectedUser && selectedUser.id) === payloadId) {
        const data = {
          ...selectedUser,
          id: response.data.data.updateProfile.id,
          firstName: response.data.data.updateProfile.firstName,
          lastName: response.data.data.updateProfile.lastName,
          phoneNumber: response.data.data.updateProfile.phoneNumber,
          address1: response.data.data.updateProfile.address1,
          address2: response.data.data.updateProfile.address2,
          country: response.data.data.updateProfile.country,
          city: response.data.data.updateProfile.city,
          postCode: response.data.data.updateProfile.postCode,
          language: response.data.data.updateProfile.language,
          tags: response.data.data.updateProfile.tags,
          role: selectedUser.role,
        };

        const isOwnerType = isOwner(selectedUser.role);
        yield put(fetchMeSuccess({ ...data, isOwner: isOwnerType }));

        localStorage.setItem('userData', JSON.stringify({ ...data, activeWorkspace, workspaces: workspaceList }));

        yield put(loggedInUserDataRequest());
      }

      defaultNotifier(intl.formatMessage({ ...messages.profileChangesSaved }));

      meta.onCallback();
    }
  } catch (err) {
    yield put(updateUserError(err));
    defaultNotifier(err);
  }
}

function* updateUserStatusSaga(action) {
  try {
    const { payload, meta } = action;
    const response = yield call(updateUser, payload);

    if (response.data.errors) {
      return defaultNotifier(response.data.errors);
    }
    yield put(onUpdateUserStatuSuccess());
    if (meta.onCallback) {
      meta.onCallback();
    }

    yield put(inviteUserSuccess(response.data.data.resendInvitation));

    defaultNotifier(intl.formatMessage({ ...messages.userStatusUpdated }));
  } catch (err) {
    defaultNotifier(err);
  }
}

function* deactivateUserStatusSaga(action) {
  try {
    const { payload, meta } = action;
    const response = yield call(deactivateUser, payload);

    if (response.data.errors) {
      return defaultNotifier(response.data.errors);
    }

    if (response.data.data.deactivateUser.email) {
      yield put(fetchUserDetailSuccess(response.data.data.deactivateUser));
    }

    if (meta.onCallback) {
      meta.onCallback();
    }
    defaultNotifier(intl.formatMessage({ ...messages.userDeactivatedSuccessfully }));
  } catch (err) {
    defaultNotifier(err);
  }
}

function* deactivateUsersStatusSaga(action) {
  try {
    const { payload, meta } = action;
    const response = yield call(deactivateUsers, payload);

    if (response.data.errors) {
      return defaultNotifier(response.data.errors);
    }

    if (meta.onCallback) {
      meta.onCallback();
    }
    if (response.data.data.deactivateUsers.skippedCount) {
      return defaultNotifier(`${intl.formatMessage({ ...messages.usersDeactivatedSuccessfully })}. 
      ${response.data.data.deactivateUsers.skippedCount} ${intl.formatMessage({ ...messages.usersWereSkipped })}`);
    }

    defaultNotifier(intl.formatMessage({ ...messages.usersDeactivatedSuccessfully }));
  } catch (err) {
    defaultNotifier(err);
  }
}

function* activateUserStatusSaga(action) {
  try {
    const { payload, meta } = action;
    const response = yield call(activateUser, payload);

    if (response.data.errors) {
      return defaultNotifier(response.data.errors);
    }

    if (response.data.data.deactivateUser.email) {
      yield put(fetchUserDetailSuccess(response.data.data.activateUser));
    }

    if (meta.onCallback) {
      meta.onCallback();
    }

    defaultNotifier(intl.formatMessage({ ...messages.userActivatedSuccessfully }));
  } catch (err) {
    defaultNotifier(err);
  }
}

function* activateUsersStatusSaga(action) {
  try {
    const { payload, meta } = action;
    const response = yield call(activateUsers, payload);

    if (response.data.errors) {
      return defaultNotifier(response.data.errors);
    }

    if (meta.onCallback) {
      meta.onCallback();
    }

    if (response.data.data.activateUsers.skippedCount) {
      return defaultNotifier(`${intl.formatMessage({ ...messages.usersActivatedSuccessfully })}. 
      ${response.data.data.activateUsers.skippedCount} ${intl.formatMessage({ ...messages.usersWereSkipped })}`);
    }

    defaultNotifier(intl.formatMessage({ ...messages.usersActivatedSuccessfully }));
  } catch (err) {
    defaultNotifier(err);
  }
}

function* onArchiveUserSaga(action) {
  try {
    const { payload } = action;
    const response = yield call(archiveUser, payload);

    if (response.data.errors) {
      defaultNotifier(response.data.errors);
    } else {
      yield put(fetchUserDetailSuccess(response.data.data.archiveUser));
      defaultNotifier(intl.formatMessage({ ...messages.userArchivedSuccessfully }));
    }
  } catch (err) {
    defaultNotifier(err);
  }
}

function* onArchiveUsersSaga(action) {
  try {
    const { payload, meta } = action;
    const response = yield call(archiveUsers, payload);

    if (response.data.errors) {
      defaultNotifier(response.data.errors);
    } else {
      if (meta.onCallback) {
        meta.onCallback();
      }

      if (response.data.data.archiveUsers.skippedCount) {
        return defaultNotifier(`${intl.formatMessage({ ...messages.usersArchivedSuccessfully })}. 
        ${response.data.data.archiveUsers.skippedCount} ${intl.formatMessage({ ...messages.usersWereSkipped })}`);
      }
      defaultNotifier(intl.formatMessage({ ...messages.usersArchivedSuccessfully }));
    }
  } catch (err) {
    defaultNotifier(err);
  }
}

export default function* userRootSaga() {
  yield takeLatest(ARCHIVE_USER_REQUEST, onArchiveUserSaga);
  yield takeLatest(UPDATE_USER_STATUS_REQUEST, updateUserStatusSaga);
  yield takeLatest(FETCH_USER_REQUEST, fetchUserSaga);
  yield takeLatest(FETCH_USER_DETAIL_REQUEST, fetchUserDetailSaga);
  yield takeLatest(FETCH_ME_REQUEST, fetchMeSaga);
  yield takeLatest(FETCH_INVITED_BY_USER_REQUEST, fetchInvitedByUserSaga);
  yield takeLatest(INVITE_USER_REQUEST, inviteUserSaga);
  yield takeLatest(UPDATE_USER_REQUEST, updateUserSaga);
  yield takeLatest(DEACTIVATE_USER_REQUEST, deactivateUserStatusSaga);
  yield takeLatest(DEACTIVATE_USERS_REQUEST, deactivateUsersStatusSaga);
  yield takeLatest(ACTIVATE_USER_REQUEST, activateUserStatusSaga);
  yield takeLatest(ACTIVATE_USERS_REQUEST, activateUsersStatusSaga);
  yield takeLatest(ARCHIVE_USERS_REQUEST, onArchiveUsersSaga);
  yield takeLatest(CANCEL_INVITATION_REQUEST, cancelInvitationSaga);
  yield takeLatest(CANCEL_INVITATIONS_REQUEST, cancelInvitationsSaga);
  yield takeLatest(RESEND_INVITATION_REQUEST, resendInvitationSaga);
  yield takeLatest(RESEND_INVITATIONS_REQUEST, resendInvitationsSaga);
}
