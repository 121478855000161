import React from 'react';

export default () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 15 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2574 0H5.54785L0 9.5H5.54785L0.797852 19L13.4633 7.125H7.12871L14.2574 0Z"
      fill="currentColor"
    />
  </svg>
);
