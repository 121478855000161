import {
  invitationSignup, obtainToken, register, sendResetPassword, workspaceLogin,
  workspaceSelect,
} from 'api/auth';
import { getPlans, getSubscriptionDetails } from 'api/billing';
import { loggedInUserDataRequest } from 'containers/App/actions';
import { defaultNotifier } from 'functions/notificationHandler';
import localForage from 'localforage';
import { call, put, takeLatest } from 'redux-saga/effects';
import { routeMapping } from 'routes';
import { getShortLang } from 'utils/common';
import { setLocaleLocally } from '../LanguageProvider/actions';
import {
  fetchPlanReject, fetchPlanSuccess, loginError,
  selectWorkspaceSuccess,
  getHomepageDataSuccess,
  getPlanInfoDataSuccess,
  loginSuccess, registerError, registerSuccess, resetPasswordError, resetPasswordSuccess, selectWorkspaceRequest,
} from './actions';
import {
  GET_PLANS_PENDING,
  INVITATION_SIGNUP_REQUEST,
  GET_HOMEPAGE_REQUEST,
  GET_PLAN_INFO_REQUEST,
  LOGIN_REQUEST, REGISTER_REQUEST, RESET_PASSWORD_REQUEST, SELECT_WORKSPACE_REQUEST,
} from './constants';
import { sortBy } from 'lodash';

import { getDevicesSuccess } from 'containers/Deployment/actions';
import { libraryEnvironmentListSuccess, libraryFlowsListSuccess } from 'containers/Library/actions';
import {getLibraryAllEnvironments, getLibraryApplications} from 'api/library';
import {fetchWorkspaceSuccess} from 'containers/Settings/Workspace/actions'
import {getWorkspaceDetail} from 'api/workspace';
import {getAllDevices} from 'api/devices';
import {
  getSubscriptionDetailsSuccess
} from 'containers/Billing/actions'
let apiName = '';

export class LocalDataService {
  getData = () => localForage
    .getItem('activePartnerId')
    .then(result => result)
    .catch(reason => reason);
}

function* loginSaga(action) {
  try {
    const { payload, meta } = action;
    let tokenResponse = '';

    if (payload.subDomain) {
      apiName = 'workspaceLogin';
      tokenResponse = yield call(workspaceLogin, payload);
    } else {
      delete payload.subDomain;
      apiName = 'login';

      tokenResponse = yield call(obtainToken, payload);
    }

    if (tokenResponse.data.errors) {
      yield put(loginError());
      return defaultNotifier(tokenResponse.data.errors);
    }

    if (!tokenResponse.data.data.login.isEmailConfirmed) {
      const { token } = tokenResponse.data.data[apiName];

      localStorage.setItem('accessToken', token);

      return meta.onCallback(`${routeMapping.RESEND_EMAIL.path}`);
    }

    const {
      token,
      user,
      workspaces,
      email,
      workspaceSetupInvited,
      passwordResetRequired
    } = tokenResponse.data.data[apiName];

    localStorage.setItem('accessToken', token);

    if (!payload.subDomain) {
      localStorage.setItem('userData', JSON.stringify({ ...user, workspaces, workspaceSetupInvited, passwordResetRequired }));

      const api = new LocalDataService();
      const activeMPData = yield call(api.getData);

      yield put(loginSuccess({ user, workspaces, email, workspaceSetupInvited, passwordResetRequired }));
      // if (user.language) {
      //   yield put(setLocaleLocally(getShortLang[user.language]))
      // }

      if (passwordResetRequired) {
        return meta.onCallback('/change-password');
      }

      if (workspaces.length === 1 && activeMPData && activeMPData.id) {
        return meta.onCallback('/workspace/select');
      }

      if (workspaces.length === 1 && !workspaceSetupInvited) {
        if (workspaces[0].twoFactorEnabled) {
          return meta.onCallbackSingleWs({ subDomain: workspaces[0].subDomain });
        }
        return yield put(
          selectWorkspaceRequest(
            { subDomain: workspaces[0].subDomain },
            { push: () => meta.onCallback('/') },
          ),
        );
      }

      if (workspaces.length === 0 && workspaceSetupInvited) {
        return meta.onCallback('/settings/workspace/new?isCreatePage=true');
      }

      if (workspaces.length === 0 && !workspaceSetupInvited) {
        return meta.validateModal(true);
      }

      if (workspaces.length >= 1) {
        return meta.onCallback('/workspace/select');
      }
    }

    if (payload.subDomain) {
      const workspaceList = JSON.parse(localStorage.getItem('userData'))
        .workspaces;

      localStorage.setItem(
        'userData',
        JSON.stringify({
          ...user,
          activeWorkspace: payload.subDomain,
          workspaces: workspaceList,
        }),
      );
      // yield put(loginSuccess({user}))
      if (user.language) {
        yield put(setLocaleLocally(getShortLang[user.language]));
      }

      yield meta.onCallback('/');
    }
  } catch (err) {
    yield put(loginError(err));
    return defaultNotifier(err);
  }
}

function* registerSaga(action) {
  try {
    const { userArgs, onCallback } = action.payload;
    const registerResponse = yield call(register, userArgs);

    if (registerResponse.data.errors) {
      yield put(registerError());
      return defaultNotifier(registerResponse.data.errors);
    }

    yield put(registerSuccess());
    // localStorage.setItem('accessToken', token)
    // localStorage.setItem('userData', JSON.stringify(user))
    onCallback();
  } catch (err) {
    return defaultNotifier(err);
  }
}

function* fetchPlansSaga() {
  try {
    const plansResponse = yield call(getPlans);

    if (plansResponse.data.errors) {
      yield put(fetchPlanReject());
      return defaultNotifier(plansResponse.data.errors);
    }

    const { plans } = plansResponse.data.data;

    const newPayload = plans.sort(
      (planA, planB) => (planA.monthlyPrice
          && planA.monthlyPrice.tiers[0]
          && planA.monthlyPrice.tiers[0].unit_amount * planA.monthlyPrice.tiers[0].up_to)
        - (planB.monthlyPrice
          && planB.monthlyPrice.tiers[0]
          && planB.monthlyPrice.tiers[0].unit_amount * planB.monthlyPrice.tiers[0].up_to),
    );
    yield put(fetchPlanSuccess(newPayload));
  } catch (err) {
    yield put(fetchPlanReject());

    return defaultNotifier(err);
  }
}

function* invitationSignUpSaga(action) {
  try {
    const { userArgs, workspace } = action.payload;

    const registerResponse = yield call(invitationSignup, userArgs);
    const DEFAULT_LOCALE = localStorage.getItem('locale') || 'en';

    if (registerResponse.data.errors) {
      // yield put(registerError())
      return defaultNotifier(registerResponse.data.errors);
    }

    const { token, user } = registerResponse.data.data.invitationSignup;

    localStorage.setItem('accessToken', token);
    localStorage.setItem('userData', JSON.stringify(user));
    yield put(loggedInUserDataRequest());

    localStorage.setItem(
      'userData',
      JSON.stringify({
        ...user,
        activeWorkspace: workspace && workspace.subDomain,
        workspaces: [workspace],
      }),
    );
    yield put(loginSuccess({ user }));

    if (!workspace) {
      return action.meta('/settings/workspace/new?isCreatePage=true');
    }

    if (user && user.language) {
      yield put(setLocaleLocally(getShortLang[user.language]));
    } else {
      yield put(setLocaleLocally(DEFAULT_LOCALE));
    }

    action.meta('/');
  } catch (err) {
    return defaultNotifier(err);
  }
}

function* selectWorkspaceSaga(action) {
  try {
    const { payload, meta = {} } = action;

    const tokenResponse = yield call(workspaceSelect, payload);

    if (tokenResponse.data.errors) {
      // yield put(loginError(tokenResponse.data.errors));
      defaultNotifier(tokenResponse.data.errors);
    } else {
      const { token, user, refreshToken } = tokenResponse.data.data.selectWorkspace;
      yield put(selectWorkspaceSuccess());

      if (meta.stayLoggedIn) {
        meta.onCallback && meta.onCallback();
        localStorage.setItem('newAccessToken', token);
        return yield put(loggedInUserDataRequest());
      }

      localStorage.setItem('accessToken', token);
      localStorage.setItem('refreshToken', refreshToken);
      const workspaceList = JSON.parse(localStorage.getItem('userData'))
        .workspaces;

      localStorage.setItem(
        'userData',
        JSON.stringify({
          ...user,
          activeWorkspace: payload.subDomain,
          workspaces: workspaceList,
        }),
      );
      yield put(loginSuccess({ user }));
      if (user.language) {
        yield put(setLocaleLocally(getShortLang[user.language]));
      }
      yield put(loggedInUserDataRequest());

      meta.onCallback && meta.onCallback();
      if (meta.redirectTo) {
        return action.meta.push(meta.redirectTo);
      }

      return yield action.meta.push('/');
    }
  } catch (err) {
    yield put(loginError(err));
    // return defaultNotifier(err)
  }
}

function* resetPasswordSaga(action) {
  try {
    const { payload } = action;
    const tokenResponse = yield call(sendResetPassword, payload);
    yield put(resetPasswordSuccess(tokenResponse.data.data.sendResetPassword));

    if (action.meta && action.meta.onCallback) {
      action.meta.onCallback();
    }
  } catch (err) {
    yield put(resetPasswordError(err));
    return defaultNotifier(err);
  }
}

function* getHomepageSaga(action) {
  try {

    if (action.payload.env) {
      const response = yield call(getLibraryAllEnvironments);

      if (response.data.errors) {
        defaultNotifier(response.data.errors);
      }
      const list = sortBy(response.data.data.cvEnvs, ['createdAt']).reverse();

      yield put(libraryEnvironmentListSuccess(list));
    }

    if (action.payload.workspace) {
      const workspaceResponse = yield call(getWorkspaceDetail);
      if (workspaceResponse.data.errors) {
        defaultNotifier(workspaceResponse.data.errors);
      } else {
        yield put(fetchWorkspaceSuccess(workspaceResponse.data.data.workspaceDetail));
      }
    }

    if (action.payload.application) {
      const appResponses = yield call(getLibraryApplications);

      if (appResponses.data.errors) {
        defaultNotifier(appResponses.data.errors);
      }
      const appList = sortBy(appResponses.data.data.applications, [
        'createdAt',
      ]).reverse();


      yield put(libraryFlowsListSuccess(appList));
    }

    if (action.payload.device) {
      const deviceResponse = yield call(getAllDevices);

      if (deviceResponse.errors) {
        defaultNotifier(deviceResponse.errors);
      } else {
        yield put(getDevicesSuccess({ ...action.payload, ...deviceResponse.data.data.devices }));
      }
    }

    yield put(getHomepageDataSuccess())
  } catch (err) {
    defaultNotifier(err);
  }
}

function* getPlanInfoSaga() {
  try {

    const plansResponse = yield call(getPlans);

    if (plansResponse.data.errors) {
      yield put(fetchPlanReject());
      return defaultNotifier(plansResponse.data.errors);
    }

    const { plans } = plansResponse.data.data;

    const newPayload = plans.sort(
      (planA, planB) => (planA.monthlyPrice
          && planA.monthlyPrice.tiers[0]
          && planA.monthlyPrice.tiers[0].unit_amount * planA.monthlyPrice.tiers[0].up_to)
        - (planB.monthlyPrice
          && planB.monthlyPrice.tiers[0]
          && planB.monthlyPrice.tiers[0].unit_amount * planB.monthlyPrice.tiers[0].up_to),
    );
    yield put(fetchPlanSuccess(newPayload));

    const response = yield call(getSubscriptionDetails);


    if (response.data.errors) {
      defaultNotifier(response.errors)
    } else {
      yield put(getSubscriptionDetailsSuccess({
        planSubscription: {
          ...response.data.data.subscriptions,
        },
      }));
    }

    yield put(getPlanInfoDataSuccess())
  } catch (err) {
    defaultNotifier(err);
  }
}

export default function* loginRootSaga() {
  yield takeLatest(LOGIN_REQUEST, loginSaga);
  yield takeLatest(SELECT_WORKSPACE_REQUEST, selectWorkspaceSaga);
  yield takeLatest(GET_HOMEPAGE_REQUEST, getHomepageSaga);
  yield takeLatest(GET_PLAN_INFO_REQUEST, getPlanInfoSaga);
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPasswordSaga);
  yield takeLatest(REGISTER_REQUEST, registerSaga);
  yield takeLatest(INVITATION_SIGNUP_REQUEST, invitationSignUpSaga);
  yield takeLatest(GET_PLANS_PENDING, fetchPlansSaga);
}
