import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import { isMobile } from 'react-device-detect';
import {withRouter } from 'withRouter'
import slugify from 'react-slugify';
import AHref from 'components/AHref';
import ProcessPage from 'components/ProcessPage';
import Button from 'components/Button';
import { enableTwoFactor } from 'api/auth';
import VerificationCode from 'components/Verification';
import { defaultNotifier } from 'functions/notificationHandler';
import RecoveryKey from 'containers/Settings/User/Detail/components/RecoveryKey';
import { routeMapping } from 'routes';

import Modal from 'components/Modal';
import { FormattedMessage } from 'react-intl';
import appMessages from 'utils/messages';

class BarcodeReader extends Component {
  state = {
    codes: [],
    activeIndex: 0,
  }

  redirectToHome = () => {
    const { workspace } = this.props;
    const wsPath = slugify(workspace.name);
    this.props.onCloseAllPage();

    this.props.fetchMe(() => this.props.router.navigate(`/${wsPath}${routeMapping.SETTINGS.path}`));
  }

  onChange = (e, index) => {
    const value = e.key || e.currentTarget.value;

    this.state.codes[index] = value;
    this.setState({ activeIndex: parseInt(index, 10) + 1 });
    this.setState({ codes: this.state.codes });
  }

  onUpdate = value => this.setState({ codes: value })

  onModalClose = () => {
    this.setState({ renderModal: false });
  }

  onShowModal = () => {
    this.setState({ renderModal: true });
  }

  onSubmit = async () => {
    const code = this.state.codes.reduce((sum, item) => `${sum}${item}`);

    if (code.length === 6) {
      const { data } = await enableTwoFactor({ code });

      if (data.errors) {
        return defaultNotifier(data.errors);
      }

      this.setState({ showRecoveryPage: true });
      defaultNotifier('Two factor authentication enabled.');
    }
  }

  renderManualModal = () => {
    const { qrCodeString, me, workspace } = this.props;

    const buttons = [
      <button className="modal__cancel" key="cancel" onClick={this.onModalClose}>
        <FormattedMessage {...appMessages.cancel} />
      </button>,
    ];

    return (
      <Modal
        buttons={buttons}
        title={'Can\'t scan the code?'}
        body={(
          <p className="text-color">
            To add the entry manually, provide the following details to the application on your phone.
            <div className="mx-t-10 label-color">
              <div>
                <span>Issuer</span>
                : viso.ai
              </div>
              <div>
                <span>Account</span>
                :
                {' '}
                {workspace.name}
                :
                {me && me.email}
              </div>
              <div>
                <span>Secrets</span>
                :
                {' '}
                {qrCodeString && qrCodeString.secret}
              </div>
            </div>
          </p>
        )}
        onClose={this.onModalClose}
      />
    );
  }

  render() {
    const { workspace, me } = this.props;
    const code = this.state.codes.length && this.state.codes.reduce((sum, item) => `${sum}${item}`);

    const theme = workspace.contrast;
    if (this.state.showRecoveryPage) {
      return (
        <RecoveryKey
          disabled
          downloadInfo={`${workspace && workspace.name}:${me && me.email}`}
          fetchMe={this.props.fetchMeRequest}
          onClose={this.redirectToHome}
        />
      );
    }

    return (
      <ProcessPage
        onCancel={this.props.onClose}
      >
        {() => (
          <>
            {this.state.renderModal && this.renderManualModal()}
            <div className="headline">
              <h1 className="headline__title">Scan the barcode with your app</h1>
              <h4 className="headline__subtitle">
                Scan the barcode with the two-factor authentication app on your phone. If you can’t use a barcode,
                {' '}
                <AHref onClick={this.onShowModal}>enter this text code</AHref>
                <br />
                instead. After scanning, the app will display a six-digit code which you can enter below.
              </h4>
            </div>
            <div className="barcode-image-wrapper">
              <QRCode
                bgColor={theme === 'light' ? 'white' : '#232323'}
                fgColor={theme === 'light' ? 'black' : 'white'}
                size={isMobile ? '100' : '180'}
                value={this.props.value}
              />
            </div>

            <VerificationCode
              codes={this.state.codes}
              onUpdate={this.onUpdate}
              activeIndex={this.state.activeIndex}
              onChange={this.onChange}
            />
            <div className="login__actions">
              <Button
                onClick={this.onSubmit}
                disabled={code.length !== 6}
                className="button button_sm"
              >
                Verify
              </Button>
            </div>
          </>
        )}
      </ProcessPage>
    );
  }
}

BarcodeReader.propTypes = {
  color: PropTypes.string,
  image: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withRouter(BarcodeReader);
