export const CARD_VIEW = 'card';
export const TABLE_VIEW = 'list';

export const FETCH_USER_REQUEST = 'USER/FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'USER/FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'USER/FETCH_USER_ERROR';

export const FETCH_USER_DETAIL_REQUEST = 'USER/FETCH_USER_DETAIL_REQUEST';
export const FETCH_USER_DETAIL_SUCCESS = 'USER/FETCH_USER_DETAIL_SUCCESS';
export const FETCH_USER_DETAIL_ERROR = 'USER/FETCH_USER_DETAIL_ERROR';

export const FETCH_INVITED_BY_USER_REQUEST = 'USER/FETCH_INVITED_BY_USER_REQUEST';
export const FETCH_INVITED_BY_USER_SUCCESS = 'USER/FETCH_INVITED_BY_USER_SUCCESS';
export const FETCH_INVITED_BY_USER_ERROR = 'USER/FETCH_INVITED_BY_USER_ERROR';

export const FETCH_ME_REQUEST = 'USER/FETCH_ME_REQUEST';
export const FETCH_ME_SUCCESS = 'USER/FETCH_ME_SUCCESS';
export const FETCH_ME_ERROR = 'USER/FETCH_ME_ERROR';

export const INVITE_USER_REQUEST = 'USER/INVITE_USER_REQUEST';
export const INVITE_USER_SUCCESS = 'USER/INVITE_USER_SUCCESS';
export const INVITE_USER_ERROR = 'USER/INVITE_USER_ERROR';

export const CANCEL_INVITATION_REQUEST = 'USER/CANCEL_INVITATION_REQUEST';
export const CANCEL_INVITATION_SUCCESS = 'USER/CANCEL_INVITATION_SUCCESS';
export const CANCEL_INVITATION_ERROR = 'USER/CANCEL_INVITATION_ERROR';

export const CANCEL_INVITATIONS_REQUEST = 'USER/CANCEL_INVITATIONS_REQUEST';

export const RESEND_INVITATION_REQUEST = 'USER/RESEND_INVITATION_REQUEST';
export const RESEND_INVITATION_SUCCESS = 'USER/RESEND_INVITATION_SUCCESS';
export const RESEND_INVITATION_ERROR = 'USER/RESEND_INVITATION_ERROR';

export const RESEND_INVITATIONS_REQUEST = 'USER/RESEND_INVITATIONS_REQUEST';

export const UPDATE_USER_REQUEST = 'USER/UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'USER/UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'USER/UPDATE_USER_ERROR';
export const CHANGE_PASSWORD_REQUEST = 'USER/CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'USER/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'USER/CHANGE_PASSWORD_ERROR';

export const UPDATE_USER_STATUS_REQUEST = 'USER/UPDATE_USER_STATUS_REQUEST';
export const UPDATE_USER_STATUS_SUCCESS = 'USER/UPDATE_USER_STATUS_SUCCESS';
export const UPDATE_USER_STATUS_ERROR = 'USER/UPDATE_USER_STATUS_ERROR';

export const ARCHIVE_USER_REQUEST = 'USER/ARCHIVE_USER_REQUEST';
export const ARCHIVE_USER_SUCCESS = 'USER/ARCHIVE_USER_SUCCESS';
export const ARCHIVE_USER_ERROR = 'USER/ARCHIVE_USER_ERROR';

export const ARCHIVE_USERS_REQUEST = 'USER/ARCHIVE_USERS_REQUEST';
export const ARCHIVE_USERS_SUCCESS = 'USER/ARCHIVE_USERS_SUCCESS';
export const ARCHIVE_USERS_ERROR = 'USER/ARCHIVE_USERS_ERROR';

export const UNARCHIVE_USER_REQUEST = 'USER/UNARCHIVE_USER_REQUEST';
export const UNARCHIVE_USER_SUCCESS = 'USER/UNARCHIVE_USER_SUCCESS';
export const UNARCHIVE_USER_ERROR = 'USER/UNARCHIVE_USER_ERROR';

export const DEACTIVATE_USER_REQUEST = 'USER/DEACTIVATE_USER_REQUEST';
export const DEACTIVATE_USER_SUCCESS = 'USER/DEACTIVATE_USER_SUCCESS';
export const DEACTIVATE_USER_ERROR = 'USER/DEACTIVATE_USER_ERROR';


export const ACTIVATE_USER_REQUEST = 'USER/ACTIVATE_USER_REQUEST';
export const ACTIVATE_USER_SUCCESS = 'USER/ACTIVATE_USER_SUCCESS';
export const ACTIVATE_USER_ERROR = 'USER/ACTIVATE_USER_ERROR';

export const DEACTIVATE_USERS_REQUEST = 'USER/DEACTIVATE_USERS_REQUEST';
export const DEACTIVATE_USERS_SUCCESS = 'USER/DEACTIVATE_USERS_SUCCESS';
export const DEACTIVATE_USERS_ERROR = 'USER/DEACTIVATE_USERS_ERROR';

export const ACTIVATE_USERS_REQUEST = 'USER/ACTIVATE_USERS_REQUEST';
export const ACTIVATE_USERS_SUCCESS = 'USER/ACTIVATE_USERS_SUCCESS';
export const ACTIVATE_USERS_ERROR = 'USER/ACTIVATE_USERS_ERROR';

export const FETCH_WORKSPACE_HISTORY_REQUEST = 'USER/FETCH_WORKSPACE_HISTORY_REQUEST';
export const FETCH_WORKSPACE_HISTORY_SUCCESS = 'USER/FETCH_WORKSPACE_HISTORY_SUCCESS';
export const FETCH_WORKSPACE_HISTORY_ERROR = 'USER/FETCH_WORKSPACE_HISTORY_ERROR';

export const userStatusList = [
  { label: 'Invited', value: 'INVITED', disabled: true },
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Inactive', value: 'INACTIVE' },
];
