import React from 'react';

export default () => (
  <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_23035_22608)">
      <path d="M21.9244 8.88889L17.48 1.11113C17.2842 0.772006 17.0023 0.490642 16.6628 0.295524C16.3233 0.100406 15.9382 -0.00153707 15.5467 1.75175e-05H6.65778C6.27186 0.0042863 5.89371 0.108989 5.56056 0.303816C5.2274 0.498644 4.95073 0.776879 4.75778 1.11113L0.313346 8.88889C0.118306 9.22671 0.015625 9.60992 0.015625 10C0.015625 10.3901 0.118306 10.7733 0.313346 11.1111L4.75778 18.8889C4.95073 19.2231 5.2274 19.5014 5.56056 19.6962C5.89371 19.891 6.27186 19.9957 6.65778 20H15.5467C15.9382 20.0015 16.3233 19.8996 16.6628 19.7045C17.0023 19.5094 17.2842 19.228 17.48 18.8889L21.9244 11.1111C22.1195 10.7733 22.2221 10.3901 22.2221 10C22.2221 9.60992 22.1195 9.22671 21.9244 8.88889V8.88889ZM15.5555 17.7778H6.66667L2.22223 10L6.66667 2.22224H15.5555L20 10L15.5555 17.7778Z" fill="currentColor" />
      <path d="M11.1109 12.2218C12.3382 12.2218 13.3331 11.2269 13.3331 9.99956C13.3331 8.77226 12.3382 7.77734 11.1109 7.77734C9.88359 7.77734 8.88867 8.77226 8.88867 9.99956C8.88867 11.2269 9.88359 12.2218 11.1109 12.2218Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_23035_22608">
        <rect width="22.2222" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>

);
