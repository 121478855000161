import { fromJS } from "immutable";
import { LOGOUT } from "containers/App/constants";
import {
  DETAILS_DASHBOARDS_ERROR,
  DETAILS_DASHBOARDS_REQUEST,
  DETAILS_DASHBOARDS_SUCCESS,
  LIST_DASHBOARDS_ERROR,
  LIST_DASHBOARDS_REQUEST,
  LIST_DASHBOARDS_SUCCESS,
  LIST_INCIDENT_ERROR,
  LIST_INCIDENT_REQUEST,
  LIST_INCIDENT_SUCCESS
} from "./constants";

export const initialState = fromJS({
  dashboards: {
    errors: null,
    loading: false,
    details: null,
    count: 0,
    data: [],
  },
  incidents: {
    errors: null,
    loading: false,
    details: null,
    count: 0,
    data: [],
  },
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT:
      return initialState;

    case LIST_DASHBOARDS_REQUEST:
      return state.merge({
        dashboards: {
          errors: null,
          loading: true,
          data: [],
          details: "",
          count: 0,
        }
      });
    case LIST_DASHBOARDS_SUCCESS:
      return state.merge({
        dashboards: {
          ...state.toJS().dashboards,
          loading: false,
          data: action.payload.data,
          count: action.payload.count
        },
      });
    case LIST_DASHBOARDS_ERROR:
      return state.merge({
        dashboards: {
          errors: null,
          loading: false,
          data: [],
          details: "",
          count: 0,
        }
      });

    case DETAILS_DASHBOARDS_REQUEST:
      return state.merge({
        dashboards: {
          ...state.toJS().dashboards,
          errors: null,
          details: {},
          loading: true,
        },
      });
    case DETAILS_DASHBOARDS_SUCCESS:
      return state.merge({
        dashboards: {
          ...state.toJS().dashboards,
          details: action.payload,
          loading: false,
          errors: null,
        },
      });
    case DETAILS_DASHBOARDS_ERROR:
      return state.merge({
        dashboards: {
          loading: false,
          errors: action.payload,
        },
      });


    case LIST_INCIDENT_REQUEST:
      return state.merge({
        incidents: {
          ...state.toJS().incidents,
          errors: null,
          loading: true,
          details: "",
          count: 0,
        }
      });
    case LIST_INCIDENT_SUCCESS:
      return state.merge({
        incidents: {
          ...state.toJS().incidents,
          loading: false,
          data: action.payload.data,
          count: action.payload.count
        },
      });
    case LIST_INCIDENT_ERROR:
      return state.merge({
        incidents: {
          errors: null,
          loading: false,
          data: [],
          details: "",
          count: 0,
        }
      });


    default:
      return state;
  }
}

export default reducer;
