import React from 'react';

export default () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.61285 1.88892H2.83507C2.31347 1.88892 1.89062 2.31176 1.89062 2.83336V6.61114C1.89062 7.13274 2.31347 7.55558 2.83507 7.55558H6.61285C7.13445 7.55558 7.55729 7.13274 7.55729 6.61114V2.83336C7.55729 2.31176 7.13445 1.88892 6.61285 1.88892Z" fill="currentColor"/>
  <path d="M14.1675 1.88892H10.3898C9.86815 1.88892 9.44531 2.31176 9.44531 2.83336V6.61114C9.44531 7.13274 9.86815 7.55558 10.3898 7.55558H14.1675C14.6891 7.55558 15.112 7.13274 15.112 6.61114V2.83336C15.112 2.31176 14.6891 1.88892 14.1675 1.88892Z" fill="currentColor"/>
  <path d="M6.61285 9.44434H2.83507C2.31347 9.44434 1.89062 9.86718 1.89062 10.3888V14.1666C1.89062 14.6882 2.31347 15.111 2.83507 15.111H6.61285C7.13445 15.111 7.55729 14.6882 7.55729 14.1666V10.3888C7.55729 9.86718 7.13445 9.44434 6.61285 9.44434Z" fill="currentColor"/>
  <path d="M14.1675 9.44434H10.3898C9.86815 9.44434 9.44531 9.86718 9.44531 10.3888V14.1666C9.44531 14.6882 9.86815 15.111 10.3898 15.111H14.1675C14.6891 15.111 15.112 14.6882 15.112 14.1666V10.3888C15.112 9.86718 14.6891 9.44434 14.1675 9.44434Z" fill="currentColor"/>
  </svg>
);
