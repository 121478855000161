import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import axiosObj from './config';


const NOTIFICATION_API_URL = `${process.env.API_URL}/notification/graphql`;

const DEFAULT_ACTOR_FIELDS = {
  id: true,
  firstName: true,
  lastName: true,
};

const DEFAULT_NOTIFICATIONS_FIELDS = {
  unreadCount: true,
  notificationList: {
    id: true,
    category: true,
    message: true,
    readState: true,
    actor: DEFAULT_ACTOR_FIELDS,
    resourceId: true,
    createdAt: true,
  },
};

const DEFAULT_NOTIFICATIONS_SETTINGS_FIELDS = {
  aiModel: true,
  application: true,
  billing: true,
  dashboard: true,
  device: true,
  module: true,
  profile: true,
  user: true,
  video: true,
  dataset: true,
  deviceOffline: true
};

export function getNotifications(param) {
  const query = {
    query: {
      notifications: {
        __args: param,
        ...DEFAULT_NOTIFICATIONS_FIELDS,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: NOTIFICATION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function getNotificationSettings() {
  const query = {
    query: {
      notificationSettings: {
        ...DEFAULT_NOTIFICATIONS_SETTINGS_FIELDS,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: NOTIFICATION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function updateNotificationSettings(param) {
  const query = {
    mutation: {
      updateNotificationSettings: {
        __args: param,
        ...DEFAULT_NOTIFICATIONS_SETTINGS_FIELDS,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: NOTIFICATION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function deleteAllNotifications(param) {
  const query = {
    mutation: {
      deleteAllNotifications: {
        __args: param,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: NOTIFICATION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}


export function deleteNotification(param) {
  const query = {
    mutation: {
      deleteNotification: {
        __args: param,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: NOTIFICATION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}


export function readAllNotifications(param) {
  const query = {
    mutation: {
      readAllNotifications: {
        __args: param,
        ...DEFAULT_NOTIFICATIONS_FIELDS,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: NOTIFICATION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function readNotification(param) {
  const query = {
    mutation: {
      readNotification: {
        __args: param,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: NOTIFICATION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}


export function unreadAllNotifications(param) {
  const query = {
    mutation: {
      unreadAllNotifications: {
        __args: param,
        ...DEFAULT_NOTIFICATIONS_FIELDS,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: NOTIFICATION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function unreadNotification(param) {
  const query = {
    mutation: {
      unreadNotification: {
        __args: param,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: NOTIFICATION_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}
