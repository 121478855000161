import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    let currentUsername = '';
    let currentEmail = '';
    let currentId = '';
    let currentRole = '';

    if (localStorage.getItem('userData')) {
      const {email, firstName, lastName, id, role } = JSON.parse(localStorage.getItem('userData'));

      currentUsername = firstName + lastName;
      currentEmail = email;
      currentId = id;
      currentRole = role
    }

    Sentry.withScope((scope) => {
      scope.setUser({username: currentUsername, id: currentId, email: currentEmail, roleName: currentRole});
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      // render fallback UI
      return (
        <></>
      );
    }

    // when there's not an error, render children untouched
    return this.props.children;
  }
}

export default ErrorBoundary;
