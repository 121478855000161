import Modal from 'components/Modal';
import { makeSelectFetchWorkspace } from 'containers/Settings/Workspace/selectors';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {withRouter } from 'withRouter'
import slugify from 'react-slugify';
import { createStructuredSelector } from 'reselect';
import messages from 'utils/messages';
import { intl } from 'containers/LanguageProvider/intlProvider';
import BookSchedule from 'components/Calendar';
import ListScroll from 'components/List/ListScroll';

class QuotaLimitModal extends Component {
  state = {
    showScheduleModal: false
  }

  render() {
    const {
      title, body, disabled,
    } = this.props;

    const { onCancel } = this.props;
    const buttons = [
      <button
        disabled={this.props.disabled}
        className="button button-success button_xm"
        key="confirm"
        onClick={() => {
          if (!this.props.disabled) {
            this.setState({showScheduleModal: true});
            // this.props.router.navigate(`/${wsPath}/change-plan`);
          }
        }}
      >
        Upgrade Plan
      </button>,
    ];

    const buttonsDisabled = [
      <button
        className="modal__cancel"
        key="confirm"
        onClick={() => {
          onCancel();
        }}
      >
        <FormattedMessage {...messages.close} />
      </button>,
    ];


    if (this.state.showScheduleModal) {
      return (
        <div className="customize-calendly-wrapper">
          <BookSchedule
            workspace={this.props.workspace}
            title={'Upgrade Plans'}
            desc={intl.formatMessage({ ...messages.scheduleTeamSales })}
            onClose={() => {
              this.setState({showScheduleModal: false});
              this.props.onCancel();
            }
            }/>
        </div>
      )
    }


    return (
      <Modal
        buttons={disabled ? buttonsDisabled : buttons}
        title={disabled ? intl.formatMessage({ ...messages.pleaseUpgradeWsPlan }) : title}
        body={disabled ? 'This feature is not available in your current workspace plan. Please contact a Workspace Administrator to change the plan.' : body}
        onClose={onCancel}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  workspace: makeSelectFetchWorkspace(),
});

const withConnect = connect(
  mapStateToProps,
  null,
);

export default withRouter(withConnect((QuotaLimitModal)));
