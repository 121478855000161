export const LIST_CATEGORY_REQUEST = 'PARTNER/LIST_CATEGORY_REQUEST';
export const LIST_CATEGORY_SUCCESS = 'PARTNER/LIST_CATEGORY_SUCCESS';
export const LIST_CATEGORY_ERROR = 'PARTNER/LIST_CATEGORY_ERROR';

export const LIST_SOLUTION_BY_CATEGORY_REQUEST = 'PARTNER/LIST_SOLUTION_BY_CATEGORY_REQUEST';
export const LIST_SOLUTION_BY_CATEGORY_SUCCESS = 'PARTNER/LIST_SOLUTION_BY_CATEGORY_SUCCESS';
export const LIST_SOLUTION_BY_CATEGORY_ERROR = 'PARTNER/LIST_SOLUTION_BY_CATEGORY_ERROR';

export const LIST_MARKETPLACE_SOLUTION_REQUEST = 'PARTNER/LIST_MARKETPLACE_SOLUTION_REQUEST';
export const LIST_MARKETPLACE_SOLUTION_SUCCESS = 'PARTNER/LIST_MARKETPLACE_SOLUTION_SUCCESS';
export const LIST_MARKETPLACE_SOLUTION_ERROR = 'PARTNER/LIST_MARKETPLACE_SOLUTION_ERROR';

export const LIST_MARKETPLACE_SOLUTIONS_REQUEST = 'PARTNER/LIST_MARKETPLACE_SOLUTIONS_REQUEST';
export const LIST_MARKETPLACE_SOLUTIONS_SUCCESS = 'PARTNER/LIST_MARKETPLACE_SOLUTIONS_SUCCESS';
export const LIST_MARKETPLACE_SOLUTIONS_ERROR = 'PARTNER/LIST_MARKETPLACE_SOLUTIONS_ERROR';
