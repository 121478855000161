import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';

import PlanPrice from './PlanPrice';
import FixedPlanPrice from './FixedPlanPrice';
import PlanHeader from './PlanHeader';
import LoadingIndicator from 'components/LoadingIndicator';

const getSelectedPlan = (selectedPlan, item, endpoints, plans) => {
  const plan = plans.find(
    planItem =>
      planItem.features.deviceLimit >= endpoints &&
      endpoints <= 100 &&
      plans[0].id !== planItem.id
  );

  if (item.name === 'Professional' && endpoints > 100 && endpoints <= 500) {
    return item;
  }

  if (item.name === 'Enterprise' && endpoints > 500) {
    return item;
  }

  // if (planIndex + 1 <= plans.length && selectedPlan && endpoints < enterprisePlan.monthlyPrice.minEndpoints) {
  //   return plans[planIndex + 1].id
  // }

  if (plan) {
    return plan;
  }

  // if (item.name === 'Professional' && endpoints < 500) {
  //   return item.id
  // }
};

const PlanInfo = ({
  onUpdateIndex,
  activeIndex,
  endpoints,
  plans,
  standalonePage,
  isLoading,
  isUpdated,
  setActivePlan,
  activatePriceTab,
  showAllFeatures,
  onSelectPlan,
  showFeature,
  intervalType,
  selectedPlan
}) => {
  const currentPlan = selectedPlan;

  if (isLoading) {
    return <LoadingIndicator isProcessPage />;
  }

  return (
    <>
      <div
        className={classNames('plan-mbl-header-fixed', {
          'plan-mbl-header-active-fixed': true
        })}
      >
        <PlanHeader
          showAllFeatures={showAllFeatures}
          onUpdateIndex={onUpdateIndex}
          activeIndex={activeIndex}
          showFeature={showFeature}
        />
      </div>
      <Scrollbars
        autoHeight
        universal
        autoHide
        autoHideDuration={1}
        className="plan-scrollbars"
        autoHeightMax={3612}
        renderTrackVertical={props => (
          <div
            {...props}
            className="track-vertical"
            style={{ display: 'none' }}
          />
        )}
        renderThumbVertical={props => (
          <div
            {...props}
            className="thumb-vertical"
            style={{ display: 'none' }}
          />
        )}
      >
        <div className="plan-info-main">
          <PlanHeader
            customName="plan-header"
            showAllFeatures={showAllFeatures}
            onUpdateIndex={onUpdateIndex}
            activeIndex={activeIndex}
            showFeature={showFeature}
          />
          <div
            className={classNames('plan-header-fixed', {
              'plan-header-fixed_active':
                activatePriceTab && showAllFeatures && !standalonePage
            })}
          >
            <div className="plan-info-wrapper">
              <div className="plan-info-header" />
              <div className="plan-info-container" />
            </div>
            {plans &&
              plans
                .filter(item => item.billingInterval !== 'Enterprise')
                .filter(item => item.billingPeriod === (intervalType === 'year' ? 'ANNUAL' : 'MONTHLY'))
                .filter(item => item.billingPeriod)
                .map(item => {
                  const activePlan = getSelectedPlan(
                    selectedPlan,
                    item,
                    endpoints,
                    plans,
                    isUpdated,
                    setActivePlan
                  );

                  return (
                    <FixedPlanPrice
                      plan={item}
                      key={item.id}
                      isValidForCustomer={
                        endpoints <= item.features.deviceLimit ||
                      item.features.deviceLimit === null
                      }
                      isActive={item.name === 'Enterprise'}
                      isEnterprise={item.name === 'Enterprise'}
                      selectedPlan={activePlan && activePlan.id}
                      currentPlan={currentPlan}
                      intervalType={intervalType}
                      showFeature={showFeature}
                      onSelectPlan={onSelectPlan}
                      onUpdateIndex={onUpdateIndex}
                      activeIndex={activeIndex}
                      endpoints={endpoints}
                    />
                  );
                })}
            {plans &&
              plans
                .filter(item => item.name === 'Enterprise')
                .filter(item => item.billingPeriod === (intervalType === 'year' ? 'ANNUAL' : 'MONTHLY'))
                .map(item => {
                  const activePlan = getSelectedPlan(
                    selectedPlan,
                    item,
                    endpoints,
                    plans,
                    isUpdated,
                    setActivePlan
                  );

                  return (
                    <FixedPlanPrice
                      plan={item}
                      key={item.id}
                      isValidForCustomer={
                        endpoints <= item.features.deviceLimit ||
                      item.features.deviceLimit === null
                      }
                      isActive={item.name === 'Enterprise'}
                      isEnterprise={item.name === 'Enterprise'}
                      selectedPlan={activePlan && activePlan.id}
                      currentPlan={currentPlan}
                      interval={intervalType === 'yearly' ? 'YEARLY' : 'MONTHLY' }
                      intervalType={intervalType}
                      showFeature={showFeature}
                      onSelectPlan={onSelectPlan}
                      onUpdateIndex={onUpdateIndex}
                      activeIndex={activeIndex}
                      endpoints={endpoints}
                    />
                  );
                })}
          </div>
          {plans &&
            plans
              .filter(item => item.name !== 'Enterprise')
              .filter(item => item.billingPeriod === (intervalType === 'year' ? 'ANNUAL' : 'MONTHLY'))
              .map(item => {
                const activePlan = getSelectedPlan(
                  selectedPlan,
                  item,
                  endpoints,
                  plans,
                  isUpdated,
                  setActivePlan
                );

                return (
                  <PlanPrice
                    plan={item}
                    key={item.id}
                    isValidForCustomer={
                      endpoints <= item.features.deviceLimit ||
                    item.features.deviceLimit === null
                    }
                    showAllFeatures={showAllFeatures}
                    isActive={item.name === 'Enterprise'}
                    isEnterprise={item.name === 'Enterprise'}
                    selectedPlan={activePlan && activePlan.id}
                    currentPlan={currentPlan}
                    intervalType={intervalType}
                    showFeature={showFeature}
                    onSelectPlan={onSelectPlan}
                    onUpdateIndex={onUpdateIndex}
                    activeIndex={activeIndex}
                    endpoints={endpoints}
                  />
                );
              })}
          {plans &&
            plans
              .filter(item => item.name === 'Enterprise')
              .map(item => {
                const activePlan = getSelectedPlan(
                  selectedPlan,
                  item,
                  endpoints,
                  plans,
                  isUpdated,
                  setActivePlan
                );

                return (
                  <PlanPrice
                    plan={item}
                    key={item.id}
                    isValidForCustomer={
                      endpoints <= item.features.deviceLimit ||
                    item.features.deviceLimit === null
                    }
                    showAllFeatures={showAllFeatures}
                    isActive={item.name === 'Enterprise'}
                    isEnterprise={item.name === 'Enterprise'}
                    selectedPlan={activePlan && activePlan.id}
                    currentPlan={currentPlan}
                    intervalType={intervalType}
                    showFeature={showFeature}
                    onSelectPlan={onSelectPlan}
                    onUpdateIndex={onUpdateIndex}
                    activeIndex={activeIndex}
                    endpoints={endpoints}
                  />
                );
              })}
        </div>
      </Scrollbars>
    </>
  );
};

PlanInfo.propTypes = {
  loginRequest: PropTypes.func
};

export default PlanInfo;
